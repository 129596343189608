var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.tag.IsHidden
    ? _c(
        "div",
        { class: "d-inline-block " + (_vm.dense ? "" : "my-2") },
        [
          _c(
            "cc-tooltip",
            {
              attrs: {
                err: _vm.tag.err,
                title: _vm.tag.GetName(_vm.bonus),
                content: _vm.tag.GetDescription(_vm.bonus)
              }
            },
            [
              _c(
                "v-chip",
                {
                  staticClass: "px-2 py-2 mx-1",
                  attrs: {
                    color: _vm.tag.err
                      ? "error"
                      : _vm.tag.IsExotic
                      ? "exotic"
                      : _vm.color,
                    dark: "",
                    label: "",
                    small: _vm.small,
                    outlined: _vm.outlined
                  }
                },
                [
                  _c(
                    "v-avatar",
                    [
                      _vm.tag.err
                        ? _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("label_off")
                          ])
                        : _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("label")
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.tag.err
                    ? _c("span", [_vm._v("MISSING DATA")])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.tag.GetName(_vm.bonus).toUpperCase()))
                      ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }