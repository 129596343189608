var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "", top: "", "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "mx-1",
                    attrs: {
                      small: "",
                      dark: "",
                      fab: _vm.$vuetify.breakpoint.lgAndUp,
                      elevation: "0",
                      color:
                        _vm.state.Move < 1 ? "grey darken-1" : "action--move"
                    }
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                    _vm._v("mdi-arrow-right-bold-hexagon-outline")
                  ]),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.mdAndDown
                    ? _c("span", {
                        staticClass: "pl-2",
                        domProps: { innerHTML: _vm._s("MOVEMENT") }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "px-4 py-2", attrs: { dense: "", color: "panel" } },
            [
              _c(
                "cc-tick-bar",
                {
                  key: _vm.state.Move,
                  attrs: {
                    current: _vm.state.Move,
                    max: _vm.state.MaxMove,
                    large: "",
                    color: "action--move",
                    "bg-color": "panel lighten-1",
                    "full-icon": "mdi-arrow-right-bold-hexagon-outline",
                    "empty-icon": "mdi-hexagon-outline"
                  },
                  on: {
                    update: function($event) {
                      return _vm.mech.Pilot.State.SetMove($event)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "heading h3" }, [
                    _vm._v("Movement")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-fade-transition",
                [
                  _vm.mech.Boost
                    ? _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.Boost,
                          attrs: {
                            current: _vm.mech.Boost,
                            max: _vm.mech.MaxMove,
                            large: "",
                            color: "secondary",
                            "full-icon": "mdi-arrow-right-bold-hexagon-outline",
                            "number-only": _vm.$vuetify.breakpoint.mdAndDown,
                            clearable: "",
                            "no-input": ""
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetBoost($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("\n            Boost\n          ")
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }