







































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { MissionStore } from '@/store'

export default Vue.extend({
  name: 'mission-debriefing',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    resolutions: ['Decisive Victory', 'Victory', 'Loss', 'Catastrophic Loss', 'Stalemate'],
  }),
  computed: {
    mission() {
      const store = getModule(MissionStore, this.$store)
      return store.ActiveMissions.find(x => x.ID === this.id)
    },
  },
})
