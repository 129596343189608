import { render, staticRenderFns } from "./_ActionDialogBase.vue?vue&type=template&id=05e83f29&"
import script from "./_ActionDialogBase.vue?vue&type=script&lang=ts&"
export * from "./_ActionDialogBase.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('05e83f29')) {
      api.createRecord('05e83f29', component.options)
    } else {
      api.reload('05e83f29', component.options)
    }
    module.hot.accept("./_ActionDialogBase.vue?vue&type=template&id=05e83f29&", function () {
      api.rerender('05e83f29', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/active_loadout/dialogs/action/_ActionDialogBase.vue"
export default component.exports