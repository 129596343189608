var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "span",
        [
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "", top: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var menu = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ml-n3 mt-n1 mr-2 fadeSelect",
                              attrs: { dark: "", small: "", icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                }
                              }
                            },
                            menu
                          ),
                          [_c("v-icon", [_vm._v("settings")])],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3806062793
              )
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                { attrs: { dense: "" } },
                [
                  !_vm.item.IsIntegrated && !_vm.readonly
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("swap")
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "ma-0 mr-2 mt-2" },
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-swap-vertical-variant")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Change Item")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.item.Destroyed && !_vm.item.Used
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.item.Used = true
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-3" },
                            [
                              _c("v-icon", { attrs: { color: "success" } }, [
                                _vm._v("mdi-check")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Mark as Used")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.item.Used
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.item.Used = false
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-3" },
                            [_c("v-icon", [_vm._v("mdi-redo")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Mark as Unused")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.CanSetDamage
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$refs.damageTypeDialog.show()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-2" },
                            [_c("v-icon", [_vm._v("cci-variable")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Select Damage Type")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.CanSetUses
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$refs.maxUseDialog.show()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-2" },
                            [_c("v-icon", [_vm._v("mdi-dice-6")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Set Max Uses")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.item.Destroyed && !_vm.item.IsIndestructible
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.item.Destroy()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-3" },
                            [
                              _c("v-icon", { attrs: { color: "error" } }, [
                                _vm._v("mdi-image-broken-variant")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Mark as Destroyed")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : !_vm.item.IsIndestructible
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.item.Repair()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-3" },
                            [_c("v-icon", [_vm._v("mdi-wrench")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Mark as Repaired")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.IsLoading
                    ? _c(
                        "div",
                        [
                          _vm.item.Loaded
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.item.Loaded = false
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "ma-0 mr-2 mt-3" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-progress-download")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Mark as Unloaded")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.item.Loaded = true
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "ma-0 mr-2 mt-3" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-progress-upload")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Mark as Loaded")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.IsAI
                    ? _c(
                        "div",
                        [
                          !_vm.item.IsCascading
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.item.Unshackle()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "ma-0 mr-2 mt-3" },
                                    [
                                      _c("v-icon", [_vm._v("mdi-link-variant")])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Mark as Cascading")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.item.Shackle()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "ma-0 mr-2 mt-3" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-link-variant-off")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Clear Cascading Status")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.active ? _c("v-divider") : _vm._e(),
                  _vm._v(" "),
                  !_vm.active
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$refs.cName.show()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-2" },
                            [_c("v-icon", [_vm._v("mdi-circle-edit-outline")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Set Custom Name")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.active
                    ? _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              return _vm.$refs.cDesc.show()
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-2" },
                            [_c("v-icon", [_vm._v("mdi-circle-edit-outline")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Set Custom Description")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.item.IsIntegrated && !_vm.readonly
                    ? _c(
                        "div",
                        [
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$emit("remove")
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "ma-0 mr-2 mt-2" },
                                [
                                  _c("v-icon", { attrs: { color: "error" } }, [
                                    _vm._v("mdi-delete")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Remove Item")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.item
            ? _c("cc-string-edit-dialog", {
                ref: "cName",
                attrs: {
                  placeholder: _vm.item.Name,
                  label: "Custom Item Name"
                },
                on: {
                  save: function($event) {
                    _vm.item.Name = $event
                  },
                  reset: function($event) {
                    _vm.item.Name = ""
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item
            ? _c("cc-string-edit-dialog", {
                ref: "cDesc",
                attrs: {
                  placeholder: _vm.item.Description,
                  label: "Custom Item Description"
                },
                on: {
                  save: function($event) {
                    _vm.item.Description = $event
                  },
                  reset: function($event) {
                    _vm.item.Description = ""
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item
            ? _c("cc-damage-type-picker", {
                ref: "damageTypeDialog",
                attrs: { "allowed-types": ["Explosive", "Energy", "Kinetic"] },
                on: {
                  select: function($event) {
                    _vm.item.DamageTypeOverride = $event
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item
            ? _c("cc-string-edit-dialog", {
                ref: "maxUseDialog",
                attrs: { number: "", label: "Set Maximum Uses" },
                on: {
                  save: function($event) {
                    _vm.item.MaxUseOverride = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }