var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-titled-panel",
    { attrs: { title: _vm.bonus.Name, dense: "" } },
    [
      _c("p", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$vuetify.breakpoint.mdAndUp,
            expression: "$vuetify.breakpoint.mdAndUp"
          },
          {
            name: "html-safe",
            rawName: "v-html-safe",
            value: _vm.bonus.Description,
            expression: "bonus.Description"
          }
        ],
        staticClass: "flavor-text pb-0 mb-2 mt-0"
      }),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c("p", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.bonus.Effect,
                expression: "bonus.Effect"
              }
            ],
            staticClass: "effect-text pa-2 mx-2 mb-2 light-panel clipped"
          })
        : _c("div", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.bonus.Effect,
                expression: "bonus.Effect"
              }
            ],
            staticClass: "body-text mt-n1 pb-1"
          }),
      _vm._v(" "),
      _vm.bonus.Actions.length
        ? _c(
            "div",
            [
              _c("div", { staticClass: "overline ml-n2 mb-n2 subtle--text" }, [
                _vm._v("CORE BONUS ACTIONS")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                _vm._l(_vm.bonus.Actions, function(a, i) {
                  return _c(
                    "v-col",
                    {
                      key: _vm.bonus.Name + "_action_" + i,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-action", {
                        staticClass: "ma-2",
                        attrs: {
                          action: a,
                          panel: _vm.$vuetify.breakpoint.lgAndUp
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.bonus.Deployables.length
        ? _c(
            "div",
            [
              _c("div", { staticClass: "overline ml-n2 mb-n2 subtle--text" }, [
                _vm._v("CORE BONUS DEPLOYABLES")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                _vm._l(_vm.bonus.Deployables, function(d, i) {
                  return _c(
                    "v-col",
                    {
                      key: _vm.bonus.Name + "_deployable_" + i,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-deployable-info", {
                        staticClass: "ma-2",
                        attrs: {
                          deployable: d,
                          panel: _vm.$vuetify.breakpoint.lgAndUp,
                          "name-override": _vm.bonus.Name
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.bonus.IntegratedEquipment.length
        ? _c(
            "div",
            [
              _c("div", { staticClass: "overline ml-n2 mb-n2 subtle--text" }, [
                _vm._v("CORE BONUS INTEGRATED EQUIPMENT")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                _vm._l(_vm.bonus.IntegratedEquipment, function(x, i) {
                  return _c(
                    "v-col",
                    {
                      key: _vm.bonus.Name + "_integrated_" + i,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-integrated-info", {
                        attrs: {
                          item: x,
                          panel: _vm.$vuetify.breakpoint.lgAndUp
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.bonus.SpecialEquipment.length
        ? _c(
            "div",
            [
              _c("div", { staticClass: "overline ml-n2 mb-n2 subtle--text" }, [
                _vm._v("CORE BONUS ADDITIONAL EQUIPMENT")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                _vm._l(_vm.bonus.SpecialEquipment, function(x, i) {
                  return _c(
                    "v-col",
                    {
                      key: _vm.bonus.Name + "_special_" + i,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-integrated-info", {
                        attrs: {
                          item: x,
                          panel: _vm.$vuetify.breakpoint.lgAndUp
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }