var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    [
      _c(
        "v-row",
        { staticClass: "mt-2 mx-8", attrs: { justify: "center", dense: "" } },
        [
          _vm.deployable.size
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon:
                    "cci-size-" +
                    (_vm.deployable.size === 0.5
                      ? "half"
                      : _vm.deployable.size),
                  name: "Size",
                  value:
                    "" +
                    (_vm.deployable.size === 0.5 ? "½" : _vm.deployable.size)
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.armor
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon: "$vuetify.icons.armor",
                  name: "Armor",
                  value: _vm.deployable.armor
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.hp || _vm.deployable.size
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon: "$vuetify.icons.hp",
                  name: "HP",
                  value: _vm.deployable.hp
                    ? _vm.deployable.hp.toString().replace(/[{}]/gim, "")
                    : parseFloat(_vm.deployable.size || 0.5) * 10
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.size
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  icon: "$vuetify.icons.evasion",
                  inline: "",
                  name: "Evasion",
                  value: _vm.deployable.evasion || 5
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.edef
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon: "$vuetify.icons.edef",
                  name: "E-Defense",
                  value: _vm.deployable.edef
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.heatcap
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon: "$vuetify.icons.heat",
                  name: "Heat Capacity",
                  value: _vm.deployable.heatcap
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.sensor
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon: "$vuetify.icons.sensor",
                  name: "Sensor Range",
                  value: _vm.deployable.sensor
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.techattack
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon: "$vuetify.icons.tech",
                  name: "Tech Attack",
                  value: _vm.deployable.techattack
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.repcap
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon: "$vuetify.icons.repair",
                  name: "Repair Capacity",
                  value: _vm.deployable.repcap
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.save
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon: "$vuetify.icons.save",
                  name: "Save Target",
                  value: _vm.deployable.save
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.deployable.speed
            ? _c("cc-statblock-panel", {
                staticClass: "mx-1",
                attrs: {
                  inline: "",
                  icon: "$vuetify.icons.speed",
                  name: "Speed",
                  value: _vm.deployable.speed
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center", dense: "" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("p", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.deployable.detail,
                  expression: "deployable.detail"
                }
              ],
              staticClass: "light-panel mb-0 clipped body-text px-4 py-2"
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.actions.length
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                _vm._l(_vm.actions, function(a, i) {
                  return _c(
                    "v-col",
                    {
                      key: _vm.deployable.name + "_action_" + i,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-action", {
                        staticClass: "ma-2",
                        attrs: {
                          action: a,
                          panel: _vm.$vuetify.breakpoint.lgAndUp
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }