var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pa-2 mt-2", staticStyle: { height: "100%" } },
    [
      _c(
        "v-card",
        {
          staticClass: "clipped-large light-panel",
          staticStyle: { height: "calc(100% - 20px)", "min-height": "100px" },
          attrs: { flat: "", tile: "" }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "text-center",
              staticStyle: { height: "100%" },
              attrs: { justify: "center", align: "center" }
            },
            [
              _c("v-col", { staticClass: "panel" }, [
                _c(
                  "div",
                  { staticClass: "heading h1 subtle--text text--darken-1" },
                  [_vm._v("\n          MOUNT LOCKED\n        ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "mt-2 mb-n4" }, [
                  _vm._v("SUPERHEAVY WEAPON BRACING")
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }