var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.damage, function(d, i) {
      return _c(
        "div",
        {
          key: "dmg_" + i,
          class:
            "text-center ml-auto mr-auto " +
            (i > 0 && !_vm.small ? "pl-4" : ""),
          staticStyle: { display: "inline-block" }
        },
        [
          _c(
            "cc-tooltip",
            {
              attrs: {
                title: d.Value + " " + (_vm.typeOverride || d.Type) + " Damage",
                content: _vm.Help(d)
              }
            },
            [
              _vm.small
                ? _c(
                    "span",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n2 ml-n1",
                          attrs: {
                            color: _vm.typeOverride
                              ? "damage--" + _vm.typeOverride.toLowerCase()
                              : d.Color
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.typeOverride
                                  ? "cci-" + _vm.typeOverride.toLowerCase()
                                  : d.Icon
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      d.Override
                        ? _c("v-icon", [_vm._v("mdi-information-outline")])
                        : _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s("" + (_vm.added ? "+" : "") + d.Value) +
                                "\n        "
                            )
                          ])
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mt-n4 mr-n3",
                          attrs: {
                            "x-large": "",
                            color: _vm.typeOverride
                              ? "damage--" + _vm.typeOverride.toLowerCase()
                              : d.Color
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.typeOverride
                                  ? "cci-" + _vm.typeOverride.toLowerCase()
                                  : d.Icon
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "heading text--text",
                          staticStyle: { "font-size": "24pt" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s("" + (_vm.added ? "+" : "") + d.Value) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
            ]
          ),
          _vm._v(" "),
          !_vm.small
            ? _c("span", [
                _c("div", { staticClass: "overline mt-n1" }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.typeOverride ? _vm.typeOverride : d.Type))
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }