

































































































































































































































































































import Vue from 'vue'
export default Vue.extend({
  name: 'pip-layout',
  props: {
    mech: {
      type: Object,
      required: true,
    },
    structRollover: { type: Boolean },
    stressRollover: { type: Boolean },
    hpResistance: { type: Boolean },
  },
  computed: {
    state() {
      return this.mech.Pilot.State
    },
  },
})
