var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "70vw" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "ma-1",
                    attrs: {
                      color: _vm.color ? _vm.color : _vm.npc.Class.Color,
                      outlined: "",
                      tile: "",
                      dark: ""
                    }
                  },
                  on
                ),
                [
                  _vm.reinforce
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-n2",
                          attrs: { icon: "", color: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("move")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-double-left")])],
                        1
                      )
                    : _vm._e(),
                  _vm._v("\n      " + _vm._s(_vm.npc.Name) + "\n      "),
                  !_vm.readonly && !_vm.reinforce
                    ? _c(
                        "span",
                        { staticClass: "pl-2" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "fadeSelect",
                              attrs: { right: "", small: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("clone")
                                }
                              }
                            },
                            [_vm._v("\n          mdi-content-copy\n        ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "fadeSelect",
                              attrs: { right: "", small: "", color: "error" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("remove")
                                }
                              }
                            },
                            [_vm._v("\n          mdi-delete\n        ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "npc-panel",
        { attrs: { npc: _vm.npc, "side-select": !_vm.readonly } },
        [
          !_vm.readonly
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-2",
                  attrs: { block: "", outlined: "", color: "error" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("remove")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-delete")]),
                  _vm._v("\n      remove npc\n    ")
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.reinforce
            ? _c(
                "v-btn",
                {
                  staticClass: "mt-2",
                  attrs: { block: "", outlined: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("move")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-chevron-double-left")
                  ]),
                  _vm._v("\n      add npc to current encounter\n    ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }