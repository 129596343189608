var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        { attrs: { text: "", color: "white", tile: "", to: "/gm" } },
        [_vm._v("Encounter Toolkit")]
      ),
      _vm._v(" "),
      _c("v-divider", {
        staticClass: "ml-2 mr-2",
        attrs: { vertical: "", dark: "" }
      }),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { text: "", color: "white", tile: "", to: "/pilot_management" }
        },
        [_vm._v("Pilot Roster")]
      ),
      _vm._v(" "),
      _c("v-divider", {
        staticClass: "ml-2 mr-2",
        attrs: { vertical: "", dark: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }