var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticStyle: { "z-index": "9999" },
      attrs: { color: _vm.color, tile: "", right: "" },
      model: {
        value: _vm.model,
        callback: function($$v) {
          _vm.model = $$v
        },
        expression: "model"
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { color: "white", text: "" },
          on: {
            click: function($event) {
              _vm.model = false
            }
          }
        },
        [_c("v-icon", { attrs: { large: "" } }, [_vm._v("close")])],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }