





















































import Vue from 'vue'
import SystemSlotCard from './_SystemSlotCard.vue'
import ModEquippedCard from './_ModEquippedCard.vue'

export default Vue.extend({
  name: 'systems-block',
  components: { SystemSlotCard, ModEquippedCard },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
    },
  },
  computed: {
    moddedWeapons() {
      return this.mech.ActiveLoadout.Weapons.filter(x => x.Mod)
    },
  },
})
