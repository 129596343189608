var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "my-1",
      attrs: { flat: "", tile: "", color: "transparent" }
    },
    [
      _c("v-card-title", { class: _vm.item.Feature.Color + " sliced pa-0" }, [
        _c(
          "div",
          { staticClass: "heading h3 flavor-text white--text pa-0 ml-2" },
          [
            !_vm.readonly && !_vm.active
              ? _c("item-menu", {
                  attrs: { item: _vm.item, active: _vm.active },
                  on: {
                    "remove-feature": function($event) {
                      return _vm.$emit("remove-feature", $event)
                    },
                    "add-reaction": function($event) {
                      return _vm.$emit("add-reaction", $event)
                    },
                    recalc: function($event) {
                      return _vm.$emit("recalc")
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.readonly
              ? _c("span", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.item.Feature.Name) + "\n      "
                  )
                ])
              : _c(
                  "span",
                  {
                    style: _vm.item.Destroyed
                      ? "text-decoration: line-through"
                      : ""
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.item.Name) +
                        " (T" +
                        _vm._s(_vm.item.Tier) +
                        ")\n      "
                    )
                  ]
                ),
            _vm._v(" "),
            !_vm.item.Destroyed && !_vm.active
              ? _c("span", { staticClass: "caption" }, [
                  _vm._v("//" + _vm._s(_vm.item.Feature.Origin))
                ])
              : _vm._e(),
            _vm._v("\n       \n    ")
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-card-text",
        {
          class:
            "py-1 mt-n1 px-2 text--text " +
            (_vm.item.Destroyed ? "error lighten-1" : "stark-panel"),
          style:
            "border: 1px solid var(--v-" +
            _vm.item.Feature.Color +
            "-base)!important"
        },
        [
          _vm.item.Description
            ? _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.item.Description,
                    expression: "item.Description"
                  }
                ],
                staticClass: "flavor-text px-2 light-panel stark--text mb-1"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.active
            ? _c(
                "div",
                [
                  _c("v-divider", { staticClass: "my-1" }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _vm._t("extra-action"),
                      _vm._v(" "),
                      _vm.item.Feature.IsRecharging
                        ? _c(
                            "v-col",
                            { staticClass: "ml-2 mt-n1" },
                            [
                              _c("v-switch", {
                                staticClass: "mt-0 flavor-text",
                                attrs: {
                                  label: _vm.item.IsCharged
                                    ? "Charged"
                                    : "Recharges on " +
                                      _vm.item.Feature.ChargeRoll +
                                      "+",
                                  inset: "",
                                  dense: "",
                                  "hide-details": "",
                                  color: "primary"
                                },
                                model: {
                                  value: _vm.item.IsCharged,
                                  callback: function($$v) {
                                    _vm.$set(_vm.item, "IsCharged", $$v)
                                  },
                                  expression: "item.IsCharged"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item.MaxUses
                        ? _c(
                            "v-col",
                            { staticClass: "ml-2 mt-n1" },
                            [
                              _c("span", { staticClass: "caption" }, [
                                _vm._v("USES:")
                              ]),
                              _vm._v(" "),
                              _c("cc-item-uses", {
                                staticClass: "d-inline",
                                attrs: { item: _vm.item }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.destructable
                        ? _c(
                            "v-col",
                            {
                              staticClass: "ml-auto mr-2 mt-n2",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "cc-tooltip",
                                {
                                  attrs: {
                                    simple: "",
                                    content:
                                      "Mark " +
                                      (_vm.item.Destroyed
                                        ? "Repaired"
                                        : "Destroyed")
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "fadeSelect",
                                      attrs: {
                                        icon: "",
                                        color: _vm.item.Destroyed
                                          ? "secondary"
                                          : "error"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.item.Destroyed = !_vm.item
                                            .Destroyed
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.item.Destroyed
                                                ? "cci-repair"
                                                : "mdi-image-broken-variant"
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm._t("active-actions")
                ],
                2
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }