var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style:
        "height: " + (_vm.$vuetify.breakpoint.mdAndUp ? "155px" : "45px") + ";"
    },
    [
      _c(
        "div",
        { attrs: { id: "header-container" } },
        [
          _c(
            "v-row",
            {
              staticClass: "pt-9 ml-2",
              staticStyle: { width: "97vw" },
              attrs: { dense: "" }
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    {
                      style:
                        "height: " +
                        (_vm.$vuetify.breakpoint.mdAndUp ? "60px" : "45px") +
                        ";",
                      attrs: { dense: "", align: "end", "align-md": "start" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c(
                          "div",
                          {
                            class:
                              "heading " +
                              (_vm.$vuetify.breakpoint.lgAndUp
                                ? "h1"
                                : _vm.$vuetify.breakpoint.mdAndUp
                                ? "h2"
                                : "h3"),
                            staticStyle: {
                              "letter-spacing": "10px",
                              overflow: "hidden",
                              "white-space": "nowrap",
                              "text-overflow": "ellipsis"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.pilot.Callsign) +
                                "\n            "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.$vuetify.breakpoint.smAndDown
                        ? _c(
                            "v-col",
                            { staticClass: "ml-auto", attrs: { cols: "auto" } },
                            [
                              !_vm.isLevelingUp
                                ? _c(
                                    "cc-tooltip",
                                    {
                                      attrs: {
                                        delayed: "",
                                        simple: "",
                                        inline: "",
                                        bottom: "",
                                        content: "Edit License Level"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "fadeSelect",
                                          attrs: { small: "", dark: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.levelEdit.show()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                mdi-circle-edit-outline\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", { staticClass: "caption" }, [
                                _vm._v("LL")
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "heading h3 px-2" }, [
                                _vm._v(_vm._s(_vm.pilot.Level))
                              ]),
                              _vm._v(" "),
                              !_vm.isLevelingUp && _vm.pilot.Level < 12
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        tile: "",
                                        outlined: "",
                                        "x-small": "",
                                        right: "",
                                        dark: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push({
                                            name: "level-up",
                                            params: { id: _vm.pilot.ID }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Level Up\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _c(
                            "v-col",
                            {
                              staticClass: "ml-auto text-center mt-2",
                              style: _vm.$vuetify.breakpoint.lgAndUp
                                ? "margin-right:200px"
                                : "",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "overline mb-n9" },
                                [
                                  _vm._v(
                                    "\n              license level\n              "
                                  ),
                                  !_vm.isLevelingUp
                                    ? _c(
                                        "cc-tooltip",
                                        {
                                          attrs: {
                                            delayed: "",
                                            simple: "",
                                            inline: "",
                                            bottom: "",
                                            content: "Edit License Level"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "fadeSelect",
                                              attrs: { small: "", dark: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$refs.levelEdit.show()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-circle-edit-outline\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "heading h1 mt-n6 mb-n2",
                                  staticStyle: { "font-size": "80px" }
                                },
                                [_vm._v(_vm._s(_vm.pilot.Level))]
                              ),
                              _vm._v(" "),
                              !_vm.isLevelingUp && _vm.pilot.Level < 12
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "fadeSelect mt-n4",
                                      attrs: {
                                        tile: "",
                                        outlined: "",
                                        small: "",
                                        color: "grey lighten-3"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push({
                                            name: "level-up",
                                            params: { id: _vm.pilot.ID }
                                          })
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Level Up\n              "
                                      ),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(
                                          "\n                mdi-arrow-up-bold-hexagon-outline\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$vuetify.breakpoint.lgAndUp,
                              expression: "$vuetify.breakpoint.lgAndUp"
                            }
                          ],
                          attrs: { cols: "auto" }
                        },
                        [
                          _c("div", { attrs: { id: "image-bg" } }),
                          _vm._v(" "),
                          _c("v-hover", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var hover = ref.hover
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "border",
                                        attrs: { id: "image" }
                                      },
                                      [
                                        _vm.pilot.Portrait
                                          ? _c("v-img", {
                                              key: _vm.pilot.Image,
                                              attrs: {
                                                src: _vm.pilot.Portrait,
                                                "aspect-ratio": "1",
                                                position: "top center"
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "v-fade-transition",
                                          [
                                            hover
                                              ? _c(
                                                  "v-overlay",
                                                  {
                                                    attrs: {
                                                      absolute: "",
                                                      color: "secondary"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "cc-btn",
                                                      {
                                                        attrs: {
                                                          color: "secondary"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.$refs.imageSelector.open()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        Set Pilot Portrait\n                      "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$vuetify.breakpoint.mdAndUp,
                          expression: "$vuetify.breakpoint.mdAndUp"
                        }
                      ],
                      attrs: { dense: "" }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mr-5", attrs: { cols: "auto" } },
                        [
                          _c(
                            "div",
                            { staticClass: "overline mb-n2 subtle--text" },
                            [_vm._v("name")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stat-text white--text mt-n3" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.pilot.Name) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.pilot.Background
                        ? _c(
                            "v-col",
                            { staticClass: "mr-5", attrs: { cols: "auto" } },
                            [
                              _c(
                                "div",
                                { staticClass: "overline mb-n2 subtle--text" },
                                [_vm._v("background")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "stat-text white--text mt-n3" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.pilot.Background) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pilot.PlayerName
                        ? _c(
                            "v-col",
                            { staticClass: "mr-5", attrs: { cols: "auto" } },
                            [
                              _c(
                                "div",
                                { staticClass: "overline mb-n2 subtle--text" },
                                [_vm._v("player")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "stat-text white--text mt-n3" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.pilot.PlayerName) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "mr-5", attrs: { cols: "auto" } },
                        [
                          _c(
                            "div",
                            { staticClass: "overline mb-n2 subtle--text" },
                            [_vm._v("rm-4://(IDENT)")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stat-text white--text mt-n3" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { "max-width": "1200px" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "fadeSelect",
                                                attrs: { dark: "" }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "mdi-card-bulleted-outline"
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-sheet",
                                    { staticClass: "transparent" },
                                    [
                                      _c("pilot-registration-card", {
                                        attrs: {
                                          pilot: _vm.pilot,
                                          "pilot-ready": ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.mdAndUp && _vm.mech
            ? _c(
                "v-row",
                { attrs: { id: "stat-row", dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "unskew",
                      attrs: { cols: "2", offset: "1" }
                    },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content:
                              "Structure: " +
                              _vm.mech.CurrentStructure +
                              "/" +
                              _vm.mech.MaxStructure
                          }
                        },
                        [_c("v-icon", [_vm._v("cci-structure")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.mech.CurrentStructure))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "flavor-text subtle--text",
                          staticStyle: { "font-size": "14px" }
                        },
                        [
                          _vm._v(
                            "\n          /" +
                              _vm._s(_vm.mech.MaxStructure) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "unskew", attrs: { cols: "2" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content:
                              "HP: " + _vm.mech.CurrentHP + "/" + _vm.mech.MaxHP
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-heart-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.mech.CurrentHP))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "flavor-text subtle--text",
                          staticStyle: { "font-size": "14px" }
                        },
                        [_vm._v("/" + _vm._s(_vm.mech.MaxHP))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "unskew", attrs: { cols: "2" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content:
                              "Reactor Stress: " +
                              _vm.mech.CurrentStress +
                              "/" +
                              _vm.mech.MaxStress
                          }
                        },
                        [_c("v-icon", [_vm._v("cci-reactor")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.mech.CurrentStress))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "flavor-text subtle--text",
                          staticStyle: { "font-size": "14px" }
                        },
                        [_vm._v("/" + _vm._s(_vm.mech.MaxStress))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "unskew", attrs: { cols: "2" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content:
                              "Heat: " +
                              _vm.mech.CurrentHeat +
                              "/" +
                              _vm.mech.HeatCapacity
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-fire")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.mech.CurrentHeat))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "flavor-text subtle--text",
                          staticStyle: { "font-size": "14px" }
                        },
                        [
                          _vm._v(
                            "\n          /" +
                              _vm._s(_vm.mech.HeatCapacity) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "unskew", attrs: { cols: "2" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content:
                              "Repair Capacity: " +
                              _vm.mech.CurrentRepairs +
                              "/" +
                              _vm.mech.RepairCapacity
                          }
                        },
                        [_c("v-icon", [_vm._v("cci-repair")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.mech.CurrentRepairs))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "flavor-text subtle--text",
                          staticStyle: { "font-size": "14px" }
                        },
                        [
                          _vm._v(
                            "\n          /" +
                              _vm._s(_vm.mech.RepairCapacity) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-row",
                { attrs: { id: "stat-row", dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "unskew",
                      attrs: { cols: "2", offset: "1" }
                    },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content:
                              "HP: " +
                              _vm.pilot.CurrentHP +
                              "/" +
                              _vm.pilot.MaxHP
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-heart-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.pilot.CurrentHP))
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "flavor-text subtle--text",
                          staticStyle: { "font-size": "14px" }
                        },
                        [_vm._v("/" + _vm._s(_vm.pilot.MaxHP))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "unskew", attrs: { cols: "2" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content: "Armor: " + _vm.pilot.Armor
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-shield-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.pilot.Armor))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "unskew", attrs: { cols: "2" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content: "Electronic Defense: " + _vm.pilot.EDefense
                          }
                        },
                        [_c("v-icon", [_vm._v("cci-edef")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.pilot.EDefense))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "unskew", attrs: { cols: "2" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content: "Evasion: " + _vm.pilot.Evasion
                          }
                        },
                        [_c("v-icon", [_vm._v("cci-evasion")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.pilot.Evasion))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "unskew", attrs: { cols: "2" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            delay: "",
                            content: "Speed: " + _vm.pilot.Speed
                          }
                        },
                        [_c("v-icon", [_vm._v("$vuetify.icons.move")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.pilot.Speed))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-image-selector-web", {
        ref: "imageSelector",
        attrs: { item: _vm.pilot, type: "pilot" }
      }),
      _vm._v(" "),
      _c("level-edit-dialog", { ref: "levelEdit", attrs: { pilot: _vm.pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }