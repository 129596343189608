var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "mt-1 mb-n1 mx-2",
      attrs: { dark: "", prominent: "", dense: "", color: "pilot" }
    },
    [
      _c(
        "v-icon",
        {
          staticClass: "mr-3",
          attrs: { slot: "prepend", size: "40" },
          slot: "prepend"
        },
        [_vm._v("cci-corebonus")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "heading h3" }, [
        _vm._v(_vm._s(_vm.bonus.Name))
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "flavor-text white--text" }, [
        _vm._v(_vm._s(_vm.bonus.MountedEffect))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }