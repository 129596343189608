var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.item || (_vm.item && !_vm.item.NoSynergies),
          expression: "!item || (item && !item.NoSynergies)"
        }
      ],
      class: _vm.inline ? "d-inline-block" : ""
    },
    [
      !_vm.synergies.length && _vm.showNone
        ? _c(
            "div",
            { staticClass: "text-center", staticStyle: { opacity: "0.5" } },
            [_c("i", [_vm._v("None")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.synergies, function(s, i) {
        return _c(
          "cc-tooltip",
          {
            key: (_vm.item ? _vm.item.ID : _vm.location) + "_synergy_" + i,
            attrs: { inline: "", title: s.Origin, content: s.Detail }
          },
          [
            _c(
              "v-icon",
              {
                attrs: { small: _vm.small, large: _vm.large, color: "accent" }
              },
              [_vm._v("\n      cci-talent\n    ")]
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }