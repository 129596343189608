
























































































import Vue from 'vue'
import PanelView from '../../components/PanelView.vue'
import ClassCard from './ClassCard.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore, NpcStore } from '@/store'
import { Npc } from '@/class'

export default Vue.extend({
  name: 'npc-manager',
  components: { PanelView, ClassCard },
  data: () => ({
    search: '',
    selectedClass: null,
    grouping: null,
    headers: [{ text: 'Name', value: 'Name', align: 'left' }],
    classes: [],
  }),
  watch: {
    selectedClass() {
      this.$refs.view.resetScroll()
    },
  },
  created() {
    const store = getModule(CompendiumStore, this.$store)
    this.classes = store.NpcClasses
  },
  methods: {
    AddNpc() {
      const store = getModule(NpcStore, this.$store)
      store.addNpc(new Npc(this.selectedClass, this.$refs.card.tierPreview))
      this.$store.dispatch('cloudSync', { callback: null, condition: 'npcCreate' })
      this.$router.push('./npc-roster')
    },
  },
})
