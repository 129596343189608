var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-solo-dialog",
    {
      ref: "dialog",
      attrs: {
        icon: "mdi-printer",
        large: "",
        "no-confirm": "",
        title: "Print Character Sheets"
      }
    },
    [
      _c(
        "v-card-text",
        [
          _c("v-select", {
            attrs: {
              items: _vm.pilot.Mechs,
              "item-text": "Name",
              "item-value": "ID",
              label: "Include Mech (optional)",
              outlined: "",
              clearable: ""
            },
            model: {
              value: _vm.selectedMechID,
              callback: function($$v) {
                _vm.selectedMechID = $$v
              },
              expression: "selectedMechID"
            }
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "my-2",
              attrs: { color: "primary", large: "", block: "", tile: "" },
              on: {
                click: function($event) {
                  return _vm.print()
                }
              }
            },
            [_vm._v("Print")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "my-2",
              attrs: {
                small: "",
                color: "accent",
                outlined: "",
                block: "",
                tile: ""
              },
              on: {
                click: function($event) {
                  return _vm.printBlank()
                }
              }
            },
            [_vm._v("\n      Print Blank Character Sheets\n    ")]
          ),
          _vm._v(" "),
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Feature In Development" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-2",
                  attrs: {
                    small: "",
                    text: "",
                    block: "",
                    tile: "",
                    disabled: ""
                  }
                },
                [
                  _vm._v(
                    "\n        Print Character Campaign Portfolio\n        "
                  ),
                  _c("v-icon", { attrs: { right: "", small: "" } }, [
                    _vm._v("mdi-information-outline")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }