var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.item.NoBonus,
          expression: "!item.NoBonus"
        }
      ]
    },
    _vm._l(_vm.item.Bonuses, function(b, i) {
      return _c(
        "cc-tooltip",
        {
          key: i + "_" + b.ID,
          attrs: { inline: "", title: "Bonus: " + b.Title, content: b.Detail }
        },
        [
          _c("v-icon", { attrs: { large: "", color: "accent" } }, [
            _vm._v("\n      cci-accuracy\n    ")
          ])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }