var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", "offset-x": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var menu = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "ml-2",
                        attrs: { icon: "", dark: !_vm.light }
                      },
                      menu
                    ),
                    [_c("v-icon", [_vm._v("mdi-settings")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { "two-line": "", subheader: "", color: "panel" } },
            [
              !_vm.dense
                ? _c(
                    "v-subheader",
                    { staticClass: "heading h2 white--text primary py-0 px-2" },
                    [_vm._v("\n        Pilot Options\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.printDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [_c("v-icon", [_vm._v("mdi-printer")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Print")]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          "\n            Print tabletop-ready character and mech sheets\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.cloneDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [_c("v-icon", [_vm._v("mdi-dna")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Clone")]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          "\n            Duplicate or Flash Clone this character\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.statblockDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [_c("v-icon", [_vm._v("mdi-file-document-box")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Generate Statblock")]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          "\n            Get a plaintext representation of this character's build\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.cloudDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [_c("v-icon", [_vm._v("mdi-cloud")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Cloud Management")]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          "\n            Save or update this pilot's cloud record (old method)\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.exportDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [_c("v-icon", [_vm._v("mdi-export-variant")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Export Pilot")]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _vm._v("Open the pilot export menu")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.deleteDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [
                      _c("v-icon", { attrs: { color: "error" } }, [
                        _vm._v("mdi-delete")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "error--text" }, [
                        _vm._v("Delete Pilot")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "error--text" },
                        [
                          _vm._v(
                            "\n            Remove this pilot from the roster\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("print-dialog", { ref: "printDialog", attrs: { pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("export-dialog", { ref: "exportDialog", attrs: { pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("statblock-dialog", {
        ref: "statblockDialog",
        attrs: { pilot: _vm.pilot }
      }),
      _vm._v(" "),
      _c("roll20-dialog", { ref: "roll20Dialog", attrs: { pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("delete-dialog", {
        ref: "deleteDialog",
        attrs: { pilot: _vm.pilot },
        on: {
          delete: function($event) {
            return _vm.deletePilot()
          }
        }
      }),
      _vm._v(" "),
      _c("clone-dialog", { ref: "cloneDialog", attrs: { pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("cloud-dialog", { ref: "cloudDialog", attrs: { pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("cloud-manager", { ref: "cloud", attrs: { pilot: _vm.pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }