var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sidebar-array-view", {
    attrs: {
      title: "PILOT SKILL TRIGGERS",
      array: _vm.skills,
      icon: "cci-accuracy",
      "name-key": "Trigger",
      "description-key": "Detail"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }