var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-sidebar-view",
    { attrs: { cols: "3" } },
    [
      _c(
        "div",
        { attrs: { slot: "sidebar" }, slot: "sidebar" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("roster-group", {
                    on: {
                      set: function($event) {
                        _vm.grouping = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2 " }),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "transparent",
            staticStyle: { "min-width": "100%" },
            attrs: {
              dense: "",
              items: _vm.npcs,
              headers: _vm.headers,
              "group-by": _vm.grouping,
              search: _vm.search,
              "no-results-text": "No NPCs Found",
              "no-data-text": "No Saved NPCs",
              "disable-pagination": "",
              "hide-default-footer": "",
              "hide-default-header": "",
              "calculate-widths": ""
            },
            scopedSlots: _vm._u([
              {
                key: "group.header",
                fn: function(h) {
                  return [
                    _c(
                      "div",
                      { staticClass: "primary sliced" },
                      [
                        _c("v-icon", { attrs: { dark: "", left: "" } }, [
                          _vm._v("mdi-chevron-right")
                        ]),
                        _vm._v(" "),
                        h.group && h.group !== "null"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "heading white--text text-uppercase"
                              },
                              [
                                Array.isArray(h.group)
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(h.group.join(", "))
                                      }
                                    })
                                  : _c("span", {
                                      domProps: { innerHTML: _vm._s(h.group) }
                                    })
                              ]
                            )
                          : _c("span", [_vm._v("NONE")])
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.Name",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "accent--text heading clickable ml-n2",
                        on: {
                          click: function($event) {
                            _vm.$vuetify.goTo(
                              "#" + _vm.generateNpcElementId(item),
                              {
                                duration: 150,
                                easing: "easeInOutQuad",
                                offset: 25,
                                container: _vm.$el.closest(".v-dialog--active")
                              }
                            )
                          }
                        }
                      },
                      [
                        _c("v-icon", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: item.Class.RoleIcon,
                              expression: "item.Class.RoleIcon"
                            }
                          ]
                        }),
                        _vm._v(" "),
                        _c("v-icon", {
                          attrs: { left: "" },
                          domProps: {
                            innerHTML: _vm._s("cci-rank-" + item.Tier)
                          }
                        }),
                        _vm._v(
                          "\n          " + _vm._s(item.Name) + "\n        "
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      !_vm.npcs.length
        ? _c("div", { staticClass: "subtle--text heading h2 text-center" }, [
            _vm._v("\n    // NO NPCS AVAILABLE //\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.npcs, function(npc, i) {
        return _c(
          "v-row",
          {
            key: npc.ID + "_" + i,
            attrs: { id: _vm.generateNpcElementId(npc) }
          },
          [
            _c(
              "v-col",
              { staticClass: "pl-0 mb-2" },
              [
                _c("npc-panel", { attrs: { npc: npc } }, [
                  _c(
                    "div",
                    [
                      _c("v-divider", { staticClass: "my-2" }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { align: "center", dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mt-n6", attrs: { cols: "9" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    block: "",
                                    "x-large": "",
                                    tile: "",
                                    outlined: "",
                                    color: _vm.side.toLowerCase(),
                                    dark: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("select", {
                                        npc: npc,
                                        side: _vm.side
                                      })
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { large: "", left: "" } },
                                    [_vm._v("mdi-plus")]
                                  ),
                                  _vm._v(
                                    "\n                Add NPC\n              "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "As...",
                                  outlined: "",
                                  dense: "",
                                  items: ["Enemy", "Ally", "Neutral"]
                                },
                                model: {
                                  value: _vm.side,
                                  callback: function($$v) {
                                    _vm.side = $$v
                                  },
                                  expression: "side"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }