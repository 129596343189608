var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [_c("cc-title", [_vm._v("Select Frame")])], 1),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-switch",
                {
                  attrs: {
                    dense: "",
                    inset: "",
                    "hide-details": "",
                    color: "warning"
                  },
                  model: {
                    value: _vm.showAll,
                    callback: function($$v) {
                      _vm.showAll = $$v
                    },
                    expression: "showAll"
                  }
                },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        slot: "label",
                        simple: "",
                        inline: "",
                        content: _vm.showAll
                          ? "Unlicensed frames: SHOWN"
                          : "Unlicensed frames: HIDDEN"
                      },
                      slot: "label"
                    },
                    [
                      _c("v-icon", {
                        attrs: { color: _vm.showAll ? "warning" : "success" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.showAll ? "mdi-lock-open" : "mdi-lock"
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("cc-filter-panel", {
                attrs: { "item-type": "Frame" },
                on: { "set-filters": _vm.setFilters }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-expansion-panels",
            {
              attrs: {
                accordion: "",
                focusable: "",
                "active-class": "border-primary"
              },
              model: {
                value: _vm.selectedFrame,
                callback: function($$v) {
                  _vm.selectedFrame = $$v
                },
                expression: "selectedFrame"
              }
            },
            _vm._l(_vm.frames, function(f) {
              return _c(
                "v-expansion-panel",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectIncl(f.ID),
                      expression: "selectIncl(f.ID)"
                    }
                  ],
                  key: f.ID,
                  staticClass: "border-highlight"
                },
                [
                  _c(
                    "v-expansion-panel-header",
                    { attrs: { id: "hover-parent", "hide-actions": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "fade-select",
                          attrs: {
                            fab: "",
                            small: "",
                            absolute: "",
                            right: "",
                            elevation: "0",
                            color: "panel"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.$refs["modal_" + f.ID][0].show()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "accent", large: "" } },
                            [_vm._v("mdi-information-outline")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("cc-search-result-modal", {
                        ref: "modal_" + f.ID,
                        refInFor: true,
                        attrs: { item: f }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("span", [
                            _c("span", { staticClass: "caption" }, [
                              _vm._v(_vm._s(f.Source))
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "heading h2 font-weight-bold" },
                              [_vm._v(_vm._s(f.Name))]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(f.Mechtype, function(mt) {
                            return _c(
                              "v-chip",
                              {
                                key: mt,
                                staticClass: "mr-2",
                                attrs: {
                                  small: "",
                                  dark: "",
                                  outlined: "",
                                  color: "accent"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(mt) +
                                    "\n            "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("v-img", {
                        staticStyle: {
                          position: "absolute",
                          top: "0",
                          right: "0"
                        },
                        attrs: {
                          id: "img-hover",
                          src: f.DefaultImage,
                          "max-height": "100%",
                          position: "top " + f.YPosition + "% left 80px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-5" }),
      _vm._v(" "),
      _c("v-expand-transition", [
        _vm.frames[_vm.selectedFrame]
          ? _c(
              "div",
              [
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "8" } },
                      [
                        _c("span", { staticClass: "overline" }, [
                          _vm._v("XK-4-01 // REGISTER MECH NAME")
                        ]),
                        _vm._v(" "),
                        _c("v-text-field", {
                          attrs: {
                            outlined: "",
                            label: "Name",
                            "hide-details": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prepend",
                                fn: function() {
                                  return [
                                    _c(
                                      "cc-tooltip",
                                      {
                                        attrs: {
                                          simple: "",
                                          content: "Generate Random Name"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: { color: "secondary" },
                                            on: {
                                              click: function($event) {
                                                return _vm.randomName()
                                              }
                                            }
                                          },
                                          [_vm._v("mdi-dice-multiple")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "append-outer",
                                fn: function() {
                                  return [
                                    !_vm.mechName
                                      ? _c(
                                          "v-icon",
                                          { attrs: { color: "error" } },
                                          [_vm._v("mdi-alert")]
                                        )
                                      : _c(
                                          "v-icon",
                                          { attrs: { color: "success" } },
                                          [_vm._v("mdi-check-circle-outline")]
                                        )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1795401331
                          ),
                          model: {
                            value: _vm.mechName,
                            callback: function($$v) {
                              _vm.mechName = $$v
                            },
                            expression: "mechName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  { attrs: { justify: "center" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              tile: "",
                              "x-large": "",
                              block: "",
                              color: "secondary",
                              disabled: !_vm.mechName
                            },
                            on: {
                              click: function($event) {
                                return _vm.addMech()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            Register New Mech\n          "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }