var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      !_vm.mission
        ? _c(
            "v-row",
            {
              staticStyle: { width: "100%", height: "100%" },
              attrs: { align: "center", justify: "center" }
            },
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c(
                  "span",
                  { staticClass: "heading h1 subtle--text text--lighten-2" },
                  [_vm._v("no mission selected")]
                )
              ])
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "mt-n6", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "fadeSelect",
                      attrs: { cols: "auto", "align-self": "center" }
                    },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: { simple: "", content: "Generate Random Name" }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { large: "", color: "secondary" },
                              on: {
                                click: function($event) {
                                  return _vm.randomName()
                                }
                              }
                            },
                            [_vm._v("mdi-dice-multiple")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "11" } }, [
                    _c(
                      "span",
                      { staticClass: "heading mech" },
                      [
                        _c(
                          "cc-short-string-editor",
                          {
                            attrs: { large: "" },
                            on: {
                              set: function($event) {
                                _vm.mission.Name = $event
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "max-width": "90%",
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.mission.Name) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-combobox", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          multiple: "",
                          label: "User Labels",
                          items: _vm.labels
                        },
                        model: {
                          value: _vm.mission.Labels,
                          callback: function($$v) {
                            _vm.$set(_vm.mission, "Labels", $$v)
                          },
                          expression: "mission.Labels"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-combobox", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Campaign",
                          items: _vm.campaigns
                        },
                        model: {
                          value: _vm.mission.Campaign,
                          callback: function($$v) {
                            _vm.$set(_vm.mission, "Campaign", $$v)
                          },
                          expression: "mission.Campaign"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "cc-title",
                { staticClass: "mb-3", attrs: { small: "" } },
                [
                  _vm._v("\n      Notes\n      "),
                  _c("cc-text-editor", {
                    attrs: {
                      label: "Edit Mission Notes",
                      original: _vm.mission.Note
                    },
                    on: {
                      save: function($event) {
                        _vm.mission.Note = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { domProps: { innerHTML: _vm._s(_vm.mission.Note) } }),
              _vm._v(" "),
              _c("cc-title", { staticClass: "mt-3", attrs: { small: "" } }, [
                _vm._v("\n      ENCOUNTER STRUCTURE\n    ")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-fade-transition",
                        [
                          _vm.mission.Steps.length
                            ? _c(
                                "v-timeline",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-slide-x-reverse-transition",
                                    {
                                      attrs: { group: "", "hide-on-leave": "" }
                                    },
                                    _vm._l(_vm.mission.Steps, function(
                                      step,
                                      idx
                                    ) {
                                      return _c(
                                        "v-timeline-item",
                                        {
                                          key: idx,
                                          attrs: {
                                            color:
                                              step.StepType === "Encounter"
                                                ? "primary"
                                                : "secondary",
                                            icon:
                                              step.StepType === "Encounter"
                                                ? "mdi-sword-cross"
                                                : "mdi-restore",
                                            "fill-dot": ""
                                          }
                                        },
                                        [
                                          step
                                            ? _c(
                                                "v-card",
                                                [
                                                  step.StepType === "Encounter"
                                                    ? _c(
                                                        "mission-step-element",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            icon:
                                                              "mdi-sword-cross",
                                                            index: idx,
                                                            step: step,
                                                            "mission-length":
                                                              _vm.mission.Steps
                                                                .length
                                                          },
                                                          on: {
                                                            "move-up": function(
                                                              $event
                                                            ) {
                                                              return _vm.mission.MoveStepUp(
                                                                idx
                                                              )
                                                            },
                                                            "move-down": function(
                                                              $event
                                                            ) {
                                                              return _vm.mission.MoveStepDown(
                                                                idx
                                                              )
                                                            },
                                                            remove: function(
                                                              $event
                                                            ) {
                                                              return _vm.mission.RemoveStep(
                                                                idx
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "accent--text",
                                                              attrs: {
                                                                slot: "title"
                                                              },
                                                              slot: "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    step.Name
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                              _c("cc-slashes"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "caption text--text"
                                                                },
                                                                [_vm._v("PR")]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "heading h3 accent--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        step.Power.toString().padStart(
                                                                          4,
                                                                          "0"
                                                                        )
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "items"
                                                              },
                                                              slot: "items"
                                                            },
                                                            [
                                                              _c(
                                                                "v-dialog",
                                                                {
                                                                  attrs: {
                                                                    width:
                                                                      "70vw"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              _vm._g(
                                                                                {
                                                                                  attrs: {
                                                                                    icon:
                                                                                      "",
                                                                                    color:
                                                                                      "primary"
                                                                                  }
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                              mdi-information-outline\n                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                },
                                                                [
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "encounter-panel",
                                                                    {
                                                                      attrs: {
                                                                        encounter: step,
                                                                        readonly:
                                                                          ""
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                dense: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-col", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "overline accent--text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "SITREP"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("br"),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "flavor-text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        step
                                                                          .Sitrep
                                                                          .name
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("v-col", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "overline accent--text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "ENVIRONMENT"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("br"),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "flavor-text"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        step.Environment
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  step.Labels
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "overline accent--text"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "LABELS"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _c("br"),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    step.Labels,
                                                                    function(
                                                                      l
                                                                    ) {
                                                                      return _c(
                                                                        "v-chip",
                                                                        {
                                                                          key: l,
                                                                          staticClass:
                                                                            "mx-1",
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          " +
                                                                              _vm._s(
                                                                                l
                                                                              ) +
                                                                              "\n                        "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "mission-step-element",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            icon: "mdi-refresh",
                                                            index: idx,
                                                            step: step,
                                                            "mission-length":
                                                              _vm.mission.Steps
                                                                .length
                                                          },
                                                          on: {
                                                            "move-up": function(
                                                              $event
                                                            ) {
                                                              return _vm.mission.MoveStepUp(
                                                                idx
                                                              )
                                                            },
                                                            "move-down": function(
                                                              $event
                                                            ) {
                                                              return _vm.mission.MoveStepDown(
                                                                idx
                                                              )
                                                            },
                                                            remove: function(
                                                              $event
                                                            ) {
                                                              return _vm.mission.RemoveStep(
                                                                idx
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "secondary--text",
                                                              attrs: {
                                                                slot: "title"
                                                              },
                                                              slot: "title"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                      " +
                                                                  _vm._s(
                                                                    step.IsLong
                                                                      ? "Full"
                                                                      : "Short"
                                                                  ) +
                                                                  " Rest\n                    "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "items"
                                                              },
                                                              slot: "items"
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn-toggle",
                                                                {
                                                                  staticClass:
                                                                    "mt-n1",
                                                                  attrs: {
                                                                    tile: "",
                                                                    color:
                                                                      "primary",
                                                                    mandatory:
                                                                      "",
                                                                    dense: "",
                                                                    borderless:
                                                                      ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      step.IsLong,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        step,
                                                                        "IsLong",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "step.IsLong"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        text:
                                                                          "",
                                                                        value: false
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          Short\n                        "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        text:
                                                                          "",
                                                                        value: true
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          Full\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", color: "accent", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.selectDialog.show()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus")
                          ]),
                          _vm._v("\n          Add Encounter\n        ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            block: "",
                            color: "secondary",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.mission.AddRest()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus")
                          ]),
                          _vm._v("\n          Add Rest\n        ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "cc-solo-dialog",
                {
                  ref: "selectDialog",
                  attrs: {
                    "no-confirm": "",
                    title: "ADD ENCOUNTER",
                    fullscreen: "",
                    "no-pad": ""
                  }
                },
                [
                  _c("encounter-selector", {
                    on: {
                      select: function($event) {
                        return _vm.addEncounter($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }