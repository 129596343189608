var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "text-center flavor-text", attrs: { cols: "4", md: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "ma-1 clipped",
          attrs: { flat: "", tile: "", color: "panel darken-1" }
        },
        [
          _c(
            "v-card-text",
            {
              staticClass: "white--text heading h3 pa-0",
              style: "letter-spacing: 3px; background-color: " + _vm.color
            },
            [
              _c("contributor", {
                attrs: {
                  label: _vm.attr,
                  value: _vm.signed
                    ? (_vm.val > -1 ? "+" : "") + _vm.val
                    : _vm.val,
                  contributors: _vm.contributors
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "pt-2 pb-0" }, [
            _c(
              "span",
              {
                staticClass: "text--text font-weight-black",
                staticStyle: { "font-size": "32px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      "" +
                        (_vm.signed ? (_vm.val > -1 ? "+" : "-") : "") +
                        Math.abs(_vm.val)
                    ) +
                    "\n      "
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }