var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-solo-dialog",
    {
      ref: "dialog",
      attrs: {
        icon: "mdi-account-arrow-right",
        large: "",
        "no-confirm": "",
        title: "Export Pilot Data"
      }
    },
    [
      _c(
        "v-card-text",
        [
          _vm.pilot.CloudID
            ? _c("div", { staticClass: "flavor-text" }, [
                _c("span", { staticClass: "font-weight-bold accent--text" }, [
                  _vm._v("Pilot Share Code: ")
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.pilot.ShareCode) + "\n        "
                    ),
                    _c(
                      "cc-tooltip",
                      {
                        attrs: {
                          simple: "",
                          inline: "",
                          content: "Copy Share Code to clipboard"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              color: _vm.copyConfirm ? "success" : "grey"
                            },
                            on: {
                              click: function($event) {
                                return _vm.copyCode()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.copyConfirm
                                    ? "mdi-check-outline"
                                    : "mdi-clipboard-text-outline"
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-fade-transition", [
                      _vm.copyConfirm
                        ? _c("span", { staticClass: "subtle--text" }, [
                            _vm._v("Copied!")
                          ])
                        : _vm._e()
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        block: "",
                        tile: "",
                        outlined: "",
                        color: "accent"
                      },
                      on: {
                        click: function($event) {
                          return _vm.exportPilot()
                        }
                      }
                    },
                    [_vm._v("\n          Export Pilot Data File\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ml-n1", attrs: { cols: "auto" } },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        simple: "",
                        inline: "",
                        content:
                          "This will create a pilot data file on your system that can then be imported into another COMP/CON user's Pilot Roster via the 'Add New Pilot' > 'Import from File' option. If this pilot has a cloud save record, that connection will be preserved. "
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mt-2 ml-n3" }, [
                        _vm._v("mdi-information-outline")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        block: "",
                        tile: "",
                        outlined: "",
                        color: "accent"
                      },
                      on: {
                        click: function($event) {
                          return _vm.copyPilot()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          Copy Pilot Data to Clipboard (Roll20 Import)\n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ml-n1", attrs: { cols: "auto" } },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        simple: "",
                        inline: "",
                        content:
                          "This will copy your pilot's data into your computer's clipboard, suitable for importing into the LANCER Character Sheet on Roll20"
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mt-2 ml-n3" }, [
                        _vm._v("mdi-information-outline")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }