var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "mx-2", attrs: { cols: "auto" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "70vw" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          large: "",
                          outlined: "",
                          color: "stark",
                          block: ""
                        }
                      },
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        { attrs: { small: "", left: "", color: "stark" } },
                        [_vm._v("mdi-account-multiple")]
                      ),
                      _vm._v("\n        " + _vm._s(_vm.org.Name) + "\n      ")
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm._v(" "),
          _c(
            "cc-titled-panel",
            {
              staticStyle: { height: "100%" },
              attrs: {
                title: _vm.org.Name,
                icon: "mdi-account-multiple",
                color: "primary"
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "items" }, slot: "items" },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        simple: "",
                        inline: "",
                        content: "Delete Organization"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.remove()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Organization Name",
                          outlined: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.org.Name,
                          callback: function($$v) {
                            _vm.$set(_vm.org, "Name", $$v)
                          },
                          expression: "org.Name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Organization Type",
                          items: _vm.orgTypes,
                          outlined: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.org.Purpose,
                          callback: function($$v) {
                            _vm.$set(_vm.org, "Purpose", $$v)
                          },
                          expression: "org.Purpose"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-textarea", {
                attrs: {
                  label: "Purpose, goal, and organization details",
                  "auto-grow": "",
                  rows: "2",
                  filled: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.org.Description,
                  callback: function($$v) {
                    _vm.$set(_vm.org, "Description", $$v)
                  },
                  expression: "org.Description"
                }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "mx-12",
                  attrs: { dense: "", justify: "center" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.org.Efficiency === 0
                          },
                          on: {
                            click: function($event) {
                              _vm.org.Efficiency -= 2
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "accent" } },
                            [_vm._v("remove")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c("div", [
                        _c("span", { staticClass: "heading h2 accent--text" }, [
                          _vm._v("+ " + _vm._s(_vm.org.Efficiency))
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _vm._v(
                              "\n              Organization Efficiency\n              "
                            ),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  simple: "",
                                  inline: "",
                                  content:
                                    "How directly effective your organization is at what it does (a military\n              organization with high efficiency would be good at combat, for example).\n              <br />Efficiency can be used to perform activities related to your organization’s\n              purpose (science, military, etc). You can use these advantages as\n              <strong>reserves.</strong>"
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", right: "" } },
                                  [_vm._v("mdi-help-circle-outline")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.org.Efficiency === 6
                          },
                          on: {
                            click: function($event) {
                              _vm.org.Efficiency += 2
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "accent" } },
                            [_vm._v("add")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.org.Influence === 0
                          },
                          on: {
                            click: function($event) {
                              _vm.org.Influence -= 2
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "accent" } },
                            [_vm._v("remove")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c("div", [
                        _c("span", { staticClass: "heading h2 accent--text" }, [
                          _vm._v("+ " + _vm._s(_vm.org.Influence))
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _vm._v(
                              "\n              Organization Influence\n              "
                            ),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  simple: "",
                                  inline: "",
                                  content:
                                    "Influence is your organization’s size, reach, wealth, and reputation.\n              Influence be used to acquire assets, create opportunities, or sway public\n              opinion."
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", right: "" } },
                                  [_vm._v("mdi-help-circle-outline")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.org.Influence === 6
                          },
                          on: {
                            click: function($event) {
                              _vm.org.Influence += 2
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { large: "", color: "accent" } },
                            [_vm._v("add")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("Dismiss")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }