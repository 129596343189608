var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-title",
        {
          staticClass: "pl-3",
          staticStyle: { "margin-left": "-50px!important" },
          attrs: { small: "", color: "pilot" }
        },
        [
          _c("section-edit-chip", {
            attrs: {
              highlight: !_vm.pilot.HasCBs,
              current: _vm.pilot.CurrentCBPoints,
              max: _vm.pilot.MaxCBPoints,
              label:
                "Edit Pilot CORE Bonuses (" +
                _vm.pilot.CurrentCBPoints +
                "/" +
                _vm.pilot.MaxCBPoints +
                ")"
            },
            on: {
              "open-selector": function($event) {
                return _vm.$refs.bonusSelector.show()
              }
            }
          }),
          _vm._v("\n    CORE Bonuses\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "bonusSelector",
          attrs: {
            icon: "cci-corebonus",
            "no-confirm": "",
            title: "Set Pilot CORE Bonuses",
            fullscreen: ""
          }
        },
        [_c("cc-core-bonus-selector", { attrs: { pilot: _vm.pilot } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "px-0" },
        [
          !_vm.pilot.CoreBonuses.length
            ? _c("no-data-block")
            : _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                _vm._l(_vm.pilot.CoreBonuses, function(b, i) {
                  return _c(
                    "v-col",
                    {
                      key: "b_" + i,
                      style: _vm.$vuetify.breakpoint.mdAndUp
                        ? "min-width: 500px;"
                        : "",
                      attrs: { cols: "12", md: "" }
                    },
                    [_c("cc-core-bonus-item", { attrs: { bonus: b } })],
                    1
                  )
                }),
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }