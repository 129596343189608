


























































import Vue from 'vue'
import MountBlock from './components/mount/_MountBlock.vue'
import SystemsBlock from './components/system/_SystemsBlock.vue'

export default Vue.extend({
  name: 'mech-loadout-block',
  components: { SystemsBlock, MountBlock },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
  },
  computed: {
    color() {
      return this.mech.Frame.Manufacturer.GetColor(this.$vuetify.theme.dark)
    },
  },
})
