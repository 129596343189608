











































































































































import Vue from 'vue'
import EditableAttribute from '../components/EditableAttribute.vue'

export default Vue.extend({
  name: 'npc-class-card',
  components: { EditableAttribute },
  props: {
    npcc: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tierPreview: 1,
  }),
})
