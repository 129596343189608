var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "75vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c("cc-confirmation", {
        attrs: {
          content:
            "Lancer, please confirm intention to create a duplicate of Mech Configuration:\n        <span class='accent--text'>\n          " +
            _vm.mech.Name +
            " (" +
            _vm.mech.Frame.Source +
            ", " +
            _vm.mech.Frame.Name +
            ")\n        </span>"
        },
        on: {
          confirm: function($event) {
            return _vm.dupe()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }