var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "py-0" },
    [
      _c("span", { staticClass: "text-uppercase stat-text" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "flavor-text subtle--text" }, [
        _vm._v("(" + _vm._s(_vm.skillPoints || 0) + ")")
      ]),
      _vm._v(" "),
      _c(
        "cc-tooltip",
        { attrs: { simple: "", content: _vm.tooltip() } },
        [
          _c("cc-rating", {
            attrs: {
              model: _vm.skillPoints,
              max: 6,
              dense: "",
              color: "secondary"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }