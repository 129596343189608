












































































































































































import Vue from 'vue'
import CloudManager from './CloudManager.vue'

export default Vue.extend({
  name: 'cloud-dialog',
  components: { CloudManager },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    syncing: false,
    copyConfirm: false,
  }),
  methods: {
    show() {
      this.$refs.dialog.show()
    },
    hide() {
      this.$refs.dialog.hide()
    },
    async copyCode() {
      this.copyConfirm = true

      navigator.clipboard.writeText(this.pilot.GistCode).then(
        function() {
          Vue.prototype.$notify('Cloud ID copied to clipboard.', 'confirmation')
        },
        function() {
          Vue.prototype.$notifyError('Unable to copy Cloud ID')
        }
      )
      setTimeout(() => {
        this.copyConfirm = false
      }, 1200)
    },
  },
})
