var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    { class: _vm.$vuetify.breakpoint.smAndDown ? "px-0" : "" },
    [
      _c("div", [
        _vm.action.Damage || _vm.action.Range
          ? _c(
              "div",
              { staticClass: "my-2" },
              [
                _c(
                  "v-row",
                  {
                    attrs: {
                      dense: "",
                      justify: "space-around",
                      align: "center"
                    }
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("cc-range-element", {
                          attrs: { range: _vm.action.Range }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("cc-damage-element", {
                          attrs: { damage: _vm.action.Damage }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider", { staticClass: "my-1" })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.action.Init
          ? _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.action.Init,
                  expression: "action.Init"
                }
              ],
              staticClass: "body-text stark--text mt-2"
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.action.Trigger
          ? _c("div", [
              _c("div", { staticClass: "subtle--text overline mb-n2" }, [
                _vm._v("Trigger")
              ]),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.action.Trigger,
                    expression: "action.Trigger"
                  }
                ],
                staticClass: "body-text stark--text"
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.action.Detail
          ? _c("div", [
              _c("div", { staticClass: "subtle--text overline mb-n2" }, [
                _vm._v("Effect")
              ]),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.action.Detail,
                    expression: "action.Detail"
                  }
                ],
                staticClass: "body-text stark--text"
              })
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }