var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-dialog",
    { attrs: { "no-dismiss": "", color: _vm.item.Color } },
    [
      _c(
        "span",
        {
          staticClass: "white--text",
          staticStyle: { width: "100%" },
          attrs: { slot: "button" },
          slot: "button"
        },
        [
          _c("v-icon", { attrs: { left: "", dark: "" } }, [
            _vm._v(_vm._s(_vm.item.Icon))
          ]),
          _vm._v("\n    " + _vm._s(_vm.item.Name) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("v-icon", { attrs: { left: "", large: "", dark: "" } }, [
            _vm._v(_vm._s(_vm.item.Icon))
          ]),
          _vm._v("\n    " + _vm._s(_vm.item.Name) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c("integrated-info-base", { attrs: { item: _vm.item } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }