var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "pb-0 ma-1",
      attrs: { outlined: "", dense: "", color: "primary" }
    },
    [
      _c(
        "div",
        { staticClass: "text--text" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mt-n2",
                      attrs: { left: "", large: "", color: "stark" }
                    },
                    [_vm._v("cci-drone")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "heading h2" }, [
                    _vm._v(_vm._s(_vm.deployable.name))
                  ]),
                  _vm._v(" "),
                  _vm.deployable.type
                    ? _c(
                        "span",
                        { staticClass: "heading h3 subtle--text" },
                        [
                          _c("cc-slashes"),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.deployable.type) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-chip",
                    {
                      staticClass: "stat-text mr-2 pr-0",
                      attrs: {
                        slot: "title-items",
                        color: "stark",
                        small: "",
                        outlined: "",
                        label: ""
                      },
                      slot: "title-items"
                    },
                    [
                      _vm._v("\n          DEPLOY \n          "),
                      _c(
                        "v-chip",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            small: "",
                            label: "",
                            dark: "",
                            color: "action--" + _vm.activation
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.activation) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("deployable-info-base", {
            staticClass: "mt-n6 mb-n2",
            attrs: { deployable: _vm.deployable }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }