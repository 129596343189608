var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("action-detail-expander", { attrs: { action: _vm.action } }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticStyle: { "max-width": "800px" } },
        [
          _c("div", { staticClass: "text-center heading h3 text--text my-3" }, [
            _vm._v("\n      Select two weapons or one Superheavy weapon\n    ")
          ]),
          _vm._v(" "),
          _vm._l(
            _vm.mech.ActiveLoadout.Mounts.filter(function(x) {
              return !x.IsLocked
            }),
            function(m, i) {
              return _c(
                "div",
                { key: "bar_" + i },
                [
                  _vm._l(
                    m.Weapons.filter(function(x) {
                      return (
                        x.Size !== "Superheavy" && !x.Destroyed && !x.NoAttack
                      )
                    }),
                    function(w, j) {
                      return _c("item-selector-row", {
                        key: "weap_" + j,
                        attrs: {
                          item: w,
                          selected: _vm.barrageToggle(w),
                          disabled: _vm.disableBarrage(w),
                          color: "action--quick"
                        },
                        on: {
                          click: function($event) {
                            return _vm.setBarrage(w, m)
                          }
                        }
                      })
                    }
                  ),
                  _vm._v(" "),
                  m.Weapons.some(function(x) {
                    return x.Size === "Superheavy" && !x.Destroyed
                  })
                    ? _c("v-divider", { staticClass: "my-2" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(
                    m.Weapons.filter(function(x) {
                      return (
                        x.Size === "Superheavy" && !x.Destroyed && !x.NoAttack
                      )
                    }),
                    function(w, j) {
                      return _c("item-selector-row", {
                        key: "weap_" + j,
                        attrs: {
                          item: w,
                          color: "action--full",
                          disabled: _vm.disableShBarrage(w),
                          selected: _vm.barrageToggle(w)
                        },
                        on: {
                          click: function($event) {
                            return _vm.setSHBarrage(w, m)
                          }
                        }
                      })
                    }
                  )
                ],
                2
              )
            }
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("w-barrage-dialog", {
        ref: "b_dialog",
        attrs: { mech: _vm.mech },
        on: {
          confirm: function($event) {
            return _vm.completeBarrage()
          }
        }
      }),
      _vm._v(" "),
      _c("sh-barrage-dialog", {
        ref: "sh_b_dialog",
        attrs: { mech: _vm.mech },
        on: {
          confirm: function($event) {
            return _vm.completeBarrage()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }