var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    _vm._l(_vm.sources, function(s) {
      return _c(
        "div",
        { key: s.ID },
        [
          s.Name
            ? _c(
                "v-row",
                {
                  attrs: {
                    "no-gutters": "",
                    align: "center",
                    justify: "center"
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("cc-logo", {
                        staticClass: "mb-n2",
                        attrs: {
                          size: _vm.$vuetify.breakpoint.mdAndUp
                            ? "xLarge"
                            : "large",
                          source: s
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : !!s
            ? _c(
                "v-row",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "div",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.breakpoint.mdAndDown ? "h3" : "h1")
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(s.match(/[A-Z]*[^A-Z]+/g).join(" ")) +
                            "\n        "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "mt-0 mb-3",
              attrs: { align: "center", justify: "center" }
            },
            _vm._l(_vm.itemsBySource(s.ID), function(item) {
              return _c("compendium-card", {
                key: "card_" + item.ID,
                attrs: {
                  item: item,
                  small: _vm.$vuetify.breakpoint.smAndDown,
                  "equipment-add": _vm.equipmentAdd
                },
                on: {
                  equip: function($event) {
                    return _vm.$emit("equip", $event)
                  }
                }
              })
            }),
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }