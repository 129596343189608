var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-5", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c("v-text-field", {
                ref: "input",
                staticClass: "search-field",
                attrs: {
                  value: _vm.searchText,
                  "prepend-icon": "search",
                  solo: "",
                  "hide-details": "",
                  "single-line": "",
                  placeholder: "Search"
                },
                on: {
                  input: function($event) {
                    return _vm.setSearch($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-3" },
        [
          _c(
            "v-col",
            [
              _c("v-subheader", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.searchResults.length) +
                    " result" +
                    _vm._s(_vm.searchResults.length === 1 ? "" : "s") +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-slide-y-reverse-transition",
                { attrs: { mode: "out-in" } },
                [
                  _c(
                    "v-row",
                    { key: _vm.searchText, attrs: { "fill-height": "" } },
                    [
                      _vm._l(_vm.searchResults, function(item, index) {
                        return _c(
                          "v-col",
                          { key: index },
                          [
                            _c(
                              "cc-titled-panel",
                              {
                                attrs: {
                                  title:
                                    (item.ItemType === "Frame"
                                      ? item.Source + " "
                                      : "") + item.Name,
                                  icon:
                                    "cci-" + _vm.$_.kebabCase(item.ItemType),
                                  color: _vm.$_.kebabCase(item.ItemType),
                                  clickable: ""
                                },
                                on: {
                                  click: function($event) {
                                    _vm.$refs["modal_" + item.ID][0].show()
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  directives: [
                                    {
                                      name: "html-safe",
                                      rawName: "v-html-safe",
                                      value:
                                        item.Description ||
                                        item.Effect ||
                                        item.Source + " " + item.ItemType,
                                      expression:
                                        "item.Description || item.Effect || `${item.Source} ${item.ItemType}`"
                                    }
                                  ],
                                  staticClass: "item-description"
                                })
                              ]
                            ),
                            _vm._v(" "),
                            _c("cc-search-result-modal", {
                              ref: "modal_" + item.ID,
                              refInFor: true,
                              attrs: { item: item }
                            })
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c("v-col", { attrs: { cols: "12" } }, [_c("br")])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }