var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-4 mx-2", attrs: { fluid: "" } },
    [
      _c("div", { staticStyle: { height: "40px" } }),
      _vm._v(" "),
      _vm.pilot && _vm.pilot.State
        ? _c(
            "div",
            [
              _c(
                "v-fade-transition",
                { attrs: { group: "", "leave-absolute": "" } },
                [
                  _vm.pilot.State.Stage === "Narrative"
                    ? _c("active-narrative", { key: "act-ft-tr-narrative" })
                    : _vm.pilot.State.Stage === "Combat"
                    ? _c("active-combat", { key: "act-ft-tr-combat" })
                    : _vm.pilot.State.Stage === "Rest"
                    ? _c("active-rest", { key: "act-ft-tr-rest" })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "overline my-2" },
            [
              _vm._v("\n      PLAYER NOTES\n      "),
              _c(
                "v-btn",
                {
                  staticClass: "fadeSelect",
                  attrs: { small: "", right: "", icon: "" },
                  on: {
                    click: function($event) {
                      _vm.showNotes = !_vm.showNotes
                    }
                  }
                },
                [
                  _c("v-icon", {
                    attrs: { small: "" },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.showNotes
                          ? "mdi-eye-outline"
                          : "mdi-eye-off-outline"
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showNotes
            ? _c("v-textarea", {
                attrs: { outlined: "", "auto-grow": "", color: "accent" },
                model: {
                  value: _vm.pilot.Notes,
                  callback: function($$v) {
                    _vm.$set(_vm.pilot, "Notes", $$v)
                  },
                  expression: "pilot.Notes"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { height: "60px" } }),
      _vm._v(" "),
      _c("turn-footer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }