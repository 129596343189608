var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: { complete: _vm.canContinue, exit: "pilot_management", back: "" },
      on: {
        back: function($event) {
          return _vm.$emit("back")
        },
        complete: function($event) {
          return _vm.$emit("next")
        }
      }
    },
    [
      _c("cc-title", { attrs: { large: "" } }, [_vm._v("Pilot Talents ")]),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "heading" },
        [
          _vm._v("\n    UAD IDENT Service\n    "),
          _c("cc-slashes"),
          _vm._v("\n     RM-4c Pilot Self Assessment (2/3)\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "absolute", right: "16px", top: "16px" } },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Feature In Development" } },
            [
              _c(
                "v-btn",
                { attrs: { small: "", outlined: "", disabled: "" } },
                [_vm._v("Suggest Talents")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text", staticStyle: { "font-size": "14px" } },
        [
          _c("div", { staticClass: "mt-n2" }, [
            _vm._v(
              "\n      The RM-4b PILOT SELF ASSESSMENT (Talents) audit catalogs an individual pilot's self-reported\n      combat operation aptitudes and confers requisition privileges for simulator time, holofield\n      and cortical sensorium training materials, battlefield telemetry data at classifications\n      below GREY SIERRA SHARP, and (in specific cases) GMS MM-pattern materiel licenses.\n      Additional resources may be made available at your request in the course of field\n      operations.\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "mt-2",
              attrs: {
                type: "warning",
                color: "accent",
                outlined: "",
                dense: "",
                prominent: ""
              }
            },
            [
              _c("b", [_vm._v("Select three (3) Pilot Talents.")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "overline",
                  staticStyle: { "line-height": "13px" }
                },
                [
                  _vm._v(
                    "\n        By submitting this form you attest that your responses are truthful and accurate to the\n        best of your knowledge. Knowingly providing false or or incomplete information is\n        punishable under DoJ/HR AR 303-J.\n      "
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-talent-selector", { attrs: { pilot: _vm.pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }