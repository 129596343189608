var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-title",
        {
          staticStyle: { "margin-left": "-40px!important" },
          attrs: { small: "", color: "pilot" }
        },
        [
          _c("section-edit-chip", {
            attrs: {
              highlight: !_vm.pilot.HasFullSkills,
              current: _vm.pilot.CurrentSkillPoints,
              max: _vm.pilot.MaxSkillPoints,
              label:
                "Edit Pilot Skill Triggers (" +
                _vm.pilot.CurrentSkillPoints +
                "/" +
                _vm.pilot.MaxSkillPoints +
                ")"
            },
            on: {
              "open-selector": function($event) {
                return _vm.$refs.skillSelector.show()
              }
            }
          }),
          _vm._v("\n    Skill Triggers\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "skillSelector",
          attrs: {
            icon: "cci-skill",
            "no-confirm": "",
            title: "Set Pilot Skill Triggers",
            fullscreen: ""
          }
        },
        [_c("cc-skill-selector", { attrs: { pilot: _vm.pilot } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-2",
          style: _vm.$vuetify.breakpoint.lgAndUp
            ? "width: calc(100vw - 250px)"
            : "",
          attrs: { dense: "" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "mr-2 text-center",
              attrs: { cols: "12", md: "auto" }
            },
            [
              _c("div", { staticClass: "stat-text" }, [_vm._v("PILOT GRIT")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "heading h1 secondary--text",
                  style: _vm.$vuetify.breakpoint.lgAndUp
                    ? "font-size: 80px; line-height: 50px"
                    : "line-height: 30px"
                },
                [_vm._v("\n        +" + _vm._s(_vm.pilot.Grit) + "\n      ")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              !_vm.pilot.Skills.length
                ? _c("no-data-block")
                : _vm._l(_vm.pilot.Skills, function(s, i) {
                    return _c("cc-skill-item", {
                      key: "s_" + i,
                      attrs: { bonus: s.Bonus, skill: s.Skill, pilot: "" }
                    })
                  })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }