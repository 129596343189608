import { render, staticRenderFns } from "./ImageBlock.vue?vue&type=template&id=2b0db2a2&"
import script from "./ImageBlock.vue?vue&type=script&lang=ts&"
export * from "./ImageBlock.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VIcon,VImg})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b0db2a2')) {
      api.createRecord('2b0db2a2', component.options)
    } else {
      api.reload('2b0db2a2', component.options)
    }
    module.hot.accept("./ImageBlock.vue?vue&type=template&id=2b0db2a2&", function () {
      api.rerender('2b0db2a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/pilot_management/PilotSheet/sections/info/components/ImageBlock.vue"
export default component.exports