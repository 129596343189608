import { render, staticRenderFns } from "./_WeaponModCard.vue?vue&type=template&id=7838207a&"
import script from "./_WeaponModCard.vue?vue&type=script&lang=ts&"
export * from "./_WeaponModCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VChip,VChipGroup,VCol,VDivider,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7838207a')) {
      api.createRecord('7838207a', component.options)
    } else {
      api.reload('7838207a', component.options)
    }
    module.hot.accept("./_WeaponModCard.vue?vue&type=template&id=7838207a&", function () {
      api.rerender('7838207a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/cards/_WeaponModCard.vue"
export default component.exports