var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-icon",
                _vm._g({ staticClass: "fadeSelect", attrs: { small: "" } }, on),
                [_vm._v("mdi-circle-edit-outline")]
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { dense: "" } },
        _vm._l(_vm.items, function(item, i) {
          return _c(
            "v-list-item",
            {
              key: "mi_" + i,
              on: {
                click: function($event) {
                  return _vm.$emit("set", item.value)
                }
              }
            },
            [_c("v-list-item-title", [_vm._v(_vm._s(item.text))])],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }