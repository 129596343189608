var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.extended && _vm.$vuetify.breakpoint.mdAndUp
    ? _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        _vm._l(_vm.tags, function(t, i) {
          return _c(
            "v-col",
            { key: t.ID + "_" + i, attrs: { cols: "12" } },
            [
              _c("cc-extended-tag", {
                attrs: { tag: t, color: t.IsExotic ? "exotic" : _vm.color }
              })
            ],
            1
          )
        }),
        1
      )
    : _vm.print
    ? _c(
        "div",
        _vm._l(_vm.tags, function(t, i) {
          return _c(
            "v-chip",
            {
              key: t.ID + "_" + i,
              staticClass: "mx-1 mt-n1 my-0",
              attrs: { outlined: "", "x-small": "", label: "" }
            },
            [_vm._v("\n    " + _vm._s(t.GetName(_vm.bonus)) + "\n  ")]
          )
        }),
        1
      )
    : _c(
        "div",
        _vm._l(_vm.tags, function(t, i) {
          return _c("cc-tag", {
            key: t.ID + "_" + i,
            attrs: {
              tag: t,
              small: _vm.small,
              dense: _vm.dense,
              outlined: _vm.outlined,
              color: _vm.color,
              bonus: _vm.bonus
            }
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }