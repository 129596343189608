var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-col", { attrs: { cols: _vm.cols } }, [
    _c("fieldset", { staticClass: "mx-1" }, [
      _c(
        "legend",
        {
          staticClass: "flavor-text black--text ml-1",
          staticStyle: { "font-size": "13px" }
        },
        [_vm._v("\n       " + _vm._s(_vm.label) + " \n    ")]
      ),
      _vm._v(" "),
      _c("div", { style: "min-height: " + _vm.height + "px" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }