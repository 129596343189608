var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    { attrs: { label: "", color: _vm.item.Feature.Color, dark: "" } },
    [
      !_vm.readonly && !_vm.active
        ? _c("item-menu", {
            attrs: { item: _vm.item, active: _vm.active },
            on: {
              "remove-feature": function($event) {
                return _vm.$emit("remove-feature", $event)
              },
              "add-reaction": function($event) {
                return _vm.$emit("add-reaction", $event)
              },
              recalc: function($event) {
                return _vm.$emit("recalc")
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            "open-on-hover": "",
            "open-delay": "500ms",
            "offset-y": "",
            top: "",
            "close-on-click": "",
            "close-on-content-click": ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c("v-icon", [
                        _vm._v(
                          "cci-" + _vm._s(_vm.$_.kebabCase(_vm.item.ItemType))
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3 px-2 mt-n1" }, [
                        _vm._v(_vm._s(_vm.item.Name))
                      ]),
                      _vm._v(" "),
                      _c("v-icon", {
                        directives: [
                          {
                            name: "html-safe",
                            rawName: "v-html-safe",
                            value:
                              _vm.item.Tier === "custom"
                                ? "mdi-star-circle-outline"
                                : "cci-rank-" + _vm.item.Tier,
                            expression:
                              "\n            item.Tier === 'custom' ? 'mdi-star-circle-outline' : 'cci-rank-' + item.Tier\n          "
                          }
                        ]
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("cc-npc-item-card", {
            attrs: { item: _vm.item, readonly: "", active: "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }