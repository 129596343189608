var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "compendium-browser",
        {
          attrs: {
            headers: _vm.headers,
            items: _vm.features,
            "no-filter": "",
            "lock-view": ""
          }
        },
        [_vm._v("\n    NPC Features\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }