import { render, staticRenderFns } from "./CCTitle.vue?vue&type=template&id=381f979e&scoped=true&"
import script from "./CCTitle.vue?vue&type=script&lang=ts&"
export * from "./CCTitle.vue?vue&type=script&lang=ts&"
import style0 from "./CCTitle.vue?vue&type=style&index=0&id=381f979e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "381f979e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('381f979e')) {
      api.createRecord('381f979e', component.options)
    } else {
      api.reload('381f979e', component.options)
    }
    module.hot.accept("./CCTitle.vue?vue&type=template&id=381f979e&scoped=true&", function () {
      api.rerender('381f979e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/CCTitle.vue"
export default component.exports