var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-slide-item", {
    staticClass: "mt-3",
    staticStyle: { height: "100px" },
    attrs: { value: _vm.actor.ID },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var active = ref.active
          var toggle = ref.toggle
          return [
            _c(
              "v-card",
              {
                staticClass: "mb-2 mx-1",
                style: active
                  ? "margin-top: 2px!important; transition: margin-top 0.2s ease-in-out"
                  : "",
                attrs: {
                  color: _vm.defeated
                    ? "pilot"
                    : _vm.complete
                    ? "grey"
                    : _vm.color,
                  height: "100%",
                  tile: ""
                },
                on: { click: toggle }
              },
              [
                _c(
                  "div",
                  { staticClass: "text-center white--text flavor-text" },
                  [
                    _c(
                      "div",
                      {
                        class: active ? "success darken-1 px-1" : "px-1",
                        staticStyle: {
                          overflow: "hidden",
                          "white-space": "nowrap",
                          "text-overflow": "ellipsis",
                          transition: "all 0.35s ease-in-out"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "ml-n1", attrs: { dark: "" } },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.defeated
                                    ? "mdi-skull"
                                    : _vm.complete
                                    ? "mdi-check"
                                    : _vm.actor.Icon
                                ) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b",
                          {
                            style: _vm.defeated
                              ? "text-decoration: linethrough"
                              : ""
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.actor.EncounterName) +
                                "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      {
                        attrs: {
                          dense: "",
                          "no-gutters": "",
                          justify: "space-around"
                        }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _vm.actor.MaxStructure > 1
                              ? _c(
                                  "cc-tooltip",
                                  {
                                    attrs: {
                                      inline: "",
                                      title: "Structure",
                                      content:
                                        _vm.actor.CurrentStructure +
                                        " / " +
                                        _vm.actor.MaxStructure
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-n2",
                                        attrs: { dark: "" }
                                      },
                                      [_vm._v("cci-structure")]
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.actor.CurrentStructure) +
                                        "\n          "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  inline: "",
                                  title: "HP",
                                  content:
                                    _vm.actor.CurrentHP +
                                    " / " +
                                    _vm.actor.MaxHP
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-n2",
                                    staticStyle: { "margin-top": "-2px" },
                                    attrs: { dark: "", size: "16" }
                                  },
                                  [_vm._v("mdi-heart")]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.actor.CurrentHP) +
                                    "\n          "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _vm.actor.MaxStress > 1
                              ? _c(
                                  "cc-tooltip",
                                  {
                                    attrs: {
                                      inline: "",
                                      title: "Reactor Stress",
                                      content:
                                        _vm.actor.CurrentStress +
                                        " / " +
                                        _vm.actor.MaxStress
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-n2 ml-n2",
                                        attrs: { dark: "" }
                                      },
                                      [_vm._v("cci-reactor")]
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.actor.CurrentStress) +
                                        "\n          "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  inline: "",
                                  title: "Heat",
                                  content:
                                    _vm.actor.CurrentHeat +
                                    " / " +
                                    _vm.actor.HeatCapacity
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-n2 ml-n1",
                                    staticStyle: { "margin-top": "-2px" },
                                    attrs: { dark: "", size: "19" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              cci-heat\n            "
                                    )
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.actor.CurrentHeat) +
                                    "\n          "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  inline: "",
                                  title: "Movement",
                                  content:
                                    _vm.actor.CurrentMove +
                                    " / " +
                                    _vm.actor.MaxMove
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-n2", attrs: { dark: "" } },
                                  [
                                    _vm._v(
                                      "mdi-arrow-right-bold-hexagon-outline"
                                    )
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.actor.CurrentMove) +
                                    "\n          "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  inline: "",
                                  title: "Activations",
                                  content:
                                    _vm.actor.Activations +
                                    " Activation remaining this Round"
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-n2", attrs: { dark: "" } },
                                  [_vm._v("mdi-hexagon-slice-3")]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.actor.Activations) +
                                    "\n          "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  inline: "",
                                  title: "Evasion",
                                  content: "" + _vm.actor.Evasion
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-n2", attrs: { dark: "" } },
                                  [_vm._v("cci-evasion")]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.actor.Evasion) +
                                    "\n          "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  inline: "",
                                  title: "E-Defense",
                                  content: "" + _vm.actor.EDefense
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "mr-n2", attrs: { dark: "" } },
                                  [_vm._v("cci-marker")]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.actor.EDefense) +
                                    "\n          "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      {
                        staticClass: "text-center",
                        attrs: {
                          justify: "space-around",
                          dense: "",
                          "no-gutters": ""
                        }
                      },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "open-on-hover": "",
                                  top: "",
                                  "offset-y": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                class:
                                                  !_vm.actor.Statuses.length &&
                                                  !_vm.actor.Conditions.length
                                                    ? "fadeSelect"
                                                    : "",
                                                attrs: { dark: "" }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                mdi-alert\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "heading h3 accent--text"
                                          },
                                          [_vm._v("Statuses/Conditions")]
                                        ),
                                        _vm._v(" "),
                                        _c("v-divider"),
                                        _vm._v(" "),
                                        !_vm.actor.Statuses.length &&
                                        !_vm.actor.Conditions.length
                                          ? _c(
                                              "div",
                                              { staticClass: "flavor-text" },
                                              [
                                                _vm._v(
                                                  "\n                  None\n                "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "flavor-text" },
                                              [
                                                _vm._l(
                                                  _vm.actor.Statuses,
                                                  function(s) {
                                                    return _c("div", {
                                                      key: s,
                                                      domProps: {
                                                        innerHTML: _vm._s(s)
                                                      }
                                                    })
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.actor.Conditions,
                                                  function(c) {
                                                    return _c("div", {
                                                      key: c,
                                                      domProps: {
                                                        innerHTML: _vm._s(c)
                                                      }
                                                    })
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "open-on-hover": "",
                                  top: "",
                                  "offset-y": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                class: !_vm.actor.Resistances
                                                  .length
                                                  ? "fadeSelect"
                                                  : "",
                                                attrs: { dark: "" }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                mdi-shield\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "heading h3 accent--text"
                                          },
                                          [_vm._v("Resistances")]
                                        ),
                                        _vm._v(" "),
                                        _c("v-divider"),
                                        _vm._v(" "),
                                        !_vm.actor.Resistances.length
                                          ? _c(
                                              "div",
                                              { staticClass: "flavor-text" },
                                              [
                                                _vm._v(
                                                  "\n                  None\n                "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "flavor-text" },
                                              _vm._l(
                                                _vm.actor.Resistances,
                                                function(r) {
                                                  return _c("div", {
                                                    key: r,
                                                    domProps: {
                                                      innerHTML: _vm._s(r)
                                                    }
                                                  })
                                                }
                                              ),
                                              0
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "open-on-hover": "",
                                  top: "",
                                  "offset-y": ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                class: !_vm.actor.Reactions
                                                  .length
                                                  ? "fadeSelect"
                                                  : "",
                                                attrs: { dark: "" }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                cci-reaction\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "heading h3 accent--text"
                                          },
                                          [_vm._v("Prepared Reactions")]
                                        ),
                                        _vm._v(" "),
                                        _c("v-divider"),
                                        _vm._v(" "),
                                        !_vm.actor.Reactions.length
                                          ? _c(
                                              "div",
                                              { staticClass: "flavor-text" },
                                              [
                                                _vm._v(
                                                  "\n                  None\n                "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "div",
                                              { staticClass: "flavor-text" },
                                              _vm._l(
                                                _vm.actor.Reactions,
                                                function(r) {
                                                  return _c("div", {
                                                    key: r,
                                                    domProps: {
                                                      innerHTML: _vm._s(r)
                                                    }
                                                  })
                                                }
                                              ),
                                              0
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }