var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      "hide-default-footer": "",
      "disable-pagination": "",
      "no-data-text": "No content packs available.",
      headers: _vm.tableHeaders,
      items: _vm.items,
      "show-expand": "",
      "item-key": "name",
      loading: _vm.loading,
      "loading-text": "Loading Content Pack Data..."
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item.website",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "cc-tooltip",
                { attrs: { content: "Download" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        target: "_blank",
                        href: item.link,
                        fab: "",
                        small: "",
                        color: "secondary"
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "anti" } }, [
                        _vm._v("open_in_new")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        },
        {
          key: "item.name",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("span", { staticClass: "title" }, [
                _vm._v("\n      " + _vm._s(item.title) + "\n    ")
              ])
            ]
          }
        },
        {
          key: "item.version",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("span", { staticClass: "packVersion" }, [
                _vm._v("\n      " + _vm._s(item.version) + "\n      "),
                _vm.packInstalled(item)
                  ? _c(
                      "span",
                      [
                        _vm.packOutdated(item)
                          ? _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  inline: "",
                                  title: "Pack Outdated",
                                  content:
                                    "This content pack is installed but out of date, and may cause errors with the latest version of COMP/CON. Click this pack's Download button to get the latest version."
                                }
                              },
                              [
                                _c("v-icon", { attrs: { color: "accent" } }, [
                                  _vm._v("mdi-alert")
                                ])
                              ],
                              1
                            )
                          : _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  inline: "",
                                  content:
                                    "This content pack is installed and up-to-date"
                                }
                              },
                              [
                                _c("v-icon", { attrs: { color: "success" } }, [
                                  _vm._v("mdi-check")
                                ])
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          }
        },
        {
          key: "item.cost",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("span", { staticClass: "cost" }, [
                _vm._v("\n      " + _vm._s(item.cost) + "\n    ")
              ])
            ]
          }
        },
        {
          key: "expanded-item",
          fn: function(ref) {
            var item = ref.item
            var headers = ref.headers
            return [
              _c(
                "td",
                {
                  staticClass: "py-4 px-6 w-100 light-panel",
                  attrs: { colspan: headers.length }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "p",
                          { staticClass: "body-text text--text pa-2 mb-1" },
                          [
                            item.description
                              ? _c("span", {
                                  directives: [
                                    {
                                      name: "html-safe",
                                      rawName: "v-html-safe",
                                      value: item.description,
                                      expression: "item.description"
                                    }
                                  ]
                                })
                              : _c("span", [
                                  _vm._v(
                                    "\n              No description given.\n            "
                                  )
                                ])
                          ]
                        ),
                        _vm._v(" "),
                        item.website
                          ? _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                _c("v-divider", { staticClass: "ma-1" }),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      href: item.website,
                                      text: "",
                                      color: "secondary"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { prepend: "", left: "" } },
                                      [_vm._v("open_in_new")]
                                    ),
                                    _vm._v(
                                      "\n              Author's Website\n            "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      item.img
                        ? _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-img", {
                                attrs: { src: item.img, alt: "Pack image" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }