var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "cc-tooltip",
        { attrs: { inline: "", simple: "", content: _vm.label } },
        [
          _c(
            "v-chip",
            {
              class: { fadeSelect: !_vm.highlight },
              staticStyle: { "margin-bottom": "1px" },
              attrs: {
                small: "",
                label: "",
                dark: "",
                color: _vm.highlight ? "warning" : "pilot"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("open-selector")
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.current) +
                  "/" +
                  _vm._s(_vm.max) +
                  " \n      "
              ),
              _c("v-icon", [_vm._v("mdi-circle-edit-outline")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }