var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("action-detail-expander", { attrs: { action: _vm.action } }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mt-3" }),
      _vm._v(" "),
      Object.keys(_vm.actions).length
        ? _c(
            "v-container",
            { staticStyle: { "max-width": "800px" } },
            _vm._l(Object.keys(_vm.actions), function(k, i) {
              return _c(
                "div",
                { key: "sys_act_" + i },
                [
                  _c("div", { staticClass: "flavor-text mb-n2 mt-1" }, [
                    _vm._v(_vm._s(k))
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.actions[k], function(a, j) {
                    return _c("item-selector-row", {
                      key: "action_" + j,
                      attrs: { item: a },
                      on: {
                        click: function($event) {
                          return _vm.activate(a)
                        }
                      }
                    })
                  })
                ],
                2
              )
            }),
            0
          )
        : _c(
            "v-card",
            { staticClass: "panel clipped", attrs: { flat: "", tile: "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "heading h3",
                      staticStyle: { opacity: "0.3" },
                      attrs: { cols: "auto" }
                    },
                    [_vm._v("\n        / / NO ACTIONS AVAILABLE / /\n      ")]
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _vm.selected
        ? _c("cc-combat-dialog", {
            ref: "i_dialog",
            attrs: { mech: _vm.mech, action: _vm.selected },
            on: {
              close: function($event) {
                return _vm.hide()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }