var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "heading mb-3 ml-5" }, [_vm._v("RESERVES")]),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          attrs: {
            "background-color": "primary",
            "slider-size": 6,
            "slider-color": "active",
            "fixed-tabs": ""
          },
          model: {
            value: _vm.tabModel,
            callback: function($$v) {
              _vm.tabModel = $$v
            },
            expression: "tabModel"
          }
        },
        [
          _vm._l(Object.keys(_vm.reserves), function(k, i) {
            return _c("v-tab", { key: "tab_" + k + i, attrs: { ripple: "" } }, [
              _vm._v("\n      " + _vm._s(k) + "\n    ")
            ])
          }),
          _vm._v(" "),
          _vm._l(Object.keys(_vm.reserves), function(k, i) {
            return _c(
              "v-tab-item",
              { key: "titem_" + k + i + "desc" },
              [
                _c(
                  "v-container",
                  [
                    _c(
                      "v-row",
                      { attrs: { justify: "center" } },
                      _vm._l(_vm.reserves[k], function(reserve) {
                        return _c(
                          "v-col",
                          {
                            key: reserve.ID,
                            attrs: { lg: "4", md: "6", sm: "12" }
                          },
                          [
                            _c(
                              "cc-titled-panel",
                              {
                                attrs: {
                                  title: reserve.Name,
                                  icon: reserve.Icon,
                                  color: reserve.Color
                                }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "text-xs-left mt-0 pt-0",
                                    staticStyle: { height: "115px" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "overline subtle--text mt-n2 mb-n1"
                                      },
                                      [_vm._v(_vm._s(reserve.Type))]
                                    ),
                                    _vm._v(" "),
                                    _c("p", {
                                      directives: [
                                        {
                                          name: "html-safe",
                                          rawName: "v-html-safe",
                                          value: reserve.Description,
                                          expression: "reserve.Description"
                                        }
                                      ],
                                      staticClass: "flavor-text mb-0"
                                    })
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }