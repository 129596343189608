var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      style:
        _vm.fullscreen || _vm.$vuetify.breakpoint.mdAndDown
          ? "x-overflow: hidden"
          : "",
      attrs: {
        fullscreen: _vm.fullscreen || _vm.$vuetify.breakpoint.mdAndDown,
        width: _vm.small ? "30vw" : _vm.large ? "85vw" : "50vw"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { tile: "" } },
        [
          _c(
            "cc-titlebar",
            {
              attrs: {
                clipped: !_vm.noTitleClip,
                large: "",
                icon: _vm.icon,
                color: _vm.color,
                fixed: _vm.fullscreen
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
              _c(
                "v-btn",
                {
                  attrs: { slot: "items", dark: "", icon: "" },
                  on: { click: _vm.hide },
                  slot: "items"
                },
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("close")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.fullscreen
            ? _c("v-spacer", { staticClass: "titlebar-margin" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card-text",
            {
              style:
                _vm.noPad || _vm.$vuetify.breakpoint.mdAndDown
                  ? "padding: 0!important"
                  : ""
            },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          !_vm.noActions
            ? _c(
                "div",
                [
                  _c("v-divider"),
                  _vm._v(" "),
                  _vm.noConfirm
                    ? _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            { attrs: { text: "" }, on: { click: _vm.hide } },
                            [_vm._v("dismiss")]
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            { attrs: { text: "" }, on: { click: _vm.hide } },
                            [_vm._v("cancel")]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c("cc-btn", { on: { click: _vm.confirm } }, [
                            _vm._v("confirm")
                          ])
                        ],
                        1
                      )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }