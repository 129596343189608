







































































































































import Vue from 'vue'
import InfoModal from './InfoModal.vue'
import ReinforcementsModal from './ReinforcementsModal.vue'

export default Vue.extend({
  name: 'encounter-nav',
  components: { InfoModal, ReinforcementsModal },
  props: {
    encounter: {
      type: Object,
      required: true,
    },
    mission: {
      type: Object,
      required: true,
    },
    actor: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    reaction: '',
    reason: '',
    remove: ['Destroyed', 'Routed', 'Escaped', 'Retreated'],
    reactionDialog: false,
    removeDialog: false,
    repairDialog: false,
    deleteDialog: false,
    reinforcementDialog: false,
    noteSheet: false,
  }),
  methods: {
    react() {
      this.actor.AddReaction(this.reaction)
      this.reaction = ''
      this.close()
    },
    removeActor() {
      this.actor.Defeat = this.reason
      this.reason = ''
      this.close()
    },
    repair() {
      this.actor.FullRepair()
      this.close()
    },
    deleteActor() {
      this.$emit('delete-actor')
      this.close()
    },
    close() {
      this.reactionDialog = false
      this.removeDialog = false
      this.repairDialog = false
      this.deleteDialog = false
    },
  },
})
