var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    [
      _c(
        "div",
        { staticClass: "ml-n3" },
        [
          _c(
            "cc-title",
            {
              staticClass: "pl-3 mb-2 ml-n6",
              attrs: { small: "", color: _vm.color }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.mech.Frame.Source) +
                  " " +
                  _vm._s(_vm.mech.Frame.Name) +
                  " Frame Traits\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.mech.Frame.Traits, function(t, index) {
        return _c("cc-trait-item", {
          key: "trait_" + index + t.name,
          attrs: { trait: t, color: _vm.color }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }