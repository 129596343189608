var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        [
          _c("p", {
            staticClass: "text-center body-text",
            domProps: {
              innerHTML: _vm._s(
                "Name what you want. You can <strong>always</strong> get it, but the GM chooses <strong>one or two</strong> complications, depending on how outlandish the request is"
              )
            }
          }),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mb-2" }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mx-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("span", { staticClass: "heading h3 accent--text" }, [
                    _vm._v("Resource Gained")
                  ]),
                  _vm._v(" "),
                  _c("reserve-selector", { ref: "rs" })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("span", { staticClass: "heading h3 accent--text" }, [
                    _vm._v("Complication")
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: {
                      label: "Complication",
                      items: _vm.complications,
                      outlined: "",
                      dense: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.complication1,
                      callback: function($$v) {
                        _vm.complication1 = $$v
                      },
                      expression: "complication1"
                    }
                  }),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "heading h3 accent--text" }, [
                    _vm._v("Additional Complication")
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: {
                      label: "Complication",
                      items: _vm.complications,
                      outlined: "",
                      dense: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.complication2,
                      callback: function($$v) {
                        _vm.complication2 = $$v
                      },
                      expression: "complication2"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("cancel")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                tile: "",
                color: "primary",
                disabled:
                  _vm.complication1 === "None" || !_vm.$refs.rs.reserveComplete
              },
              on: { click: _vm.addReserve }
            },
            [_vm._v("\n      add reserve\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }