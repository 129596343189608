var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-2" },
    [
      _c("action-detail-expander", { attrs: { action: _vm.action } }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticStyle: { "max-width": "800px" } },
        _vm._l(Object.keys(_vm.quickActions), function(k, i) {
          return _c(
            "div",
            { key: "sys_act_" + i },
            [
              _c("div", { staticClass: "flavor-text mb-n2 mt-1" }, [
                _vm._v(_vm._s(k))
              ]),
              _vm._v(" "),
              _vm._l(_vm.quickActions[k], function(a, j) {
                return _c("item-selector-row", {
                  key: "action_" + j,
                  attrs: { item: a, disabled: _vm.quick.length === 2 },
                  on: {
                    click: function($event) {
                      return _vm.addQuick(a)
                    }
                  }
                })
              }),
              _vm._v(" "),
              _vm._l(_vm.quickActions[k], function(a, j) {
                return a.IsTechAttack
                  ? _c("cc-combat-dialog", {
                      key: "action_dialog_" + j,
                      ref: "dialog_" + a.ID,
                      refInFor: true,
                      attrs: { fulltech: "", action: a, mech: _vm.mech },
                      on: {
                        "add-invade": function($event) {
                          return _vm.quick.push($event)
                        },
                        "add-fail": function($event) {
                          return _vm.quick.push("attack-fail-" + $event)
                        }
                      }
                    })
                  : _vm._e()
              }),
              _vm._v(" "),
              i === 0
                ? _c("item-selector-row", {
                    attrs: {
                      item: _vm.invadeAction,
                      disabled: _vm.quick.length === 2
                    },
                    on: {
                      click: function($event) {
                        return _vm.openInvade()
                      }
                    }
                  })
                : _vm._e()
            ],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      Object.keys(_vm.fullActions).length
        ? _c("v-divider", { staticClass: "my-3" })
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.fullActions).length
        ? _c(
            "v-container",
            { staticStyle: { "max-width": "800px" } },
            _vm._l(Object.keys(_vm.fullActions), function(k, i) {
              return _c(
                "div",
                { key: "sys_act_" + i },
                [
                  _c("div", { staticClass: "flavor-text mb-n2 mt-1" }, [
                    _vm._v(_vm._s(k))
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.fullActions[k], function(a, j) {
                    return _c("item-selector-row", {
                      key: "action_" + j,
                      attrs: { item: a, disabled: _vm.quick.length > 0 },
                      on: {
                        click: function($event) {
                          return _vm.fulltech(a)
                        }
                      }
                    })
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.fullActions[k], function(a, j) {
                    return _c("cc-combat-dialog", {
                      key: "action_dialog_" + j,
                      ref: "dialog_" + a.ID,
                      refInFor: true,
                      attrs: { action: a, mech: _vm.mech }
                    })
                  })
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-slide-x-reverse-transition",
            { attrs: { group: "" } },
            _vm._l(_vm.quick, function(q, i) {
              return _c(
                "v-row",
                {
                  key: "quick_sel_" + i,
                  attrs: { dense: "", justify: "center", align: "center" }
                },
                [
                  _c(
                    "v-col",
                    [
                      q === "invade-fail"
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "text-center",
                              attrs: { dense: "", outlined: "", color: "white" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "heading h3 text-disabled" },
                                [_vm._v("INVASION ATTEMPT FAILED")]
                              )
                            ]
                          )
                        : typeof q === "string" && q.startsWith("attack-fail-")
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "text-center",
                              attrs: { dense: "", outlined: "", color: "white" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "heading h3 text-disabled" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.systemFromFailure(q)) + " FAILED"
                                  )
                                ]
                              )
                            ]
                          )
                        : _c("cc-action", { attrs: { panel: "", action: q } })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { "x-large": "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.removeQuick(i)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { "x-large": "" } }, [
                            _vm._v("mdi-close")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-slide-x-reverse-transition",
            [
              _vm.quick.length === 2
                ? _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "6", md: "10", xs: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                "x-large": "",
                                color: "primary",
                                disabled: _vm.used
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("use")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    !_vm.used ? "CONFIRM" : "ACTION CONFIRMED"
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("invade-dialog", {
        ref: "inv_dialog",
        attrs: { fulltech: "", mech: _vm.mech, action: _vm.invadeAction },
        on: {
          "add-invade": function($event) {
            return _vm.quick.push($event)
          },
          "add-fail": function($event) {
            return _vm.quick.push("invade-fail")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }