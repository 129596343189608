var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "span",
        [
          _c(
            "cc-tooltip",
            { attrs: { inline: "", simple: "", content: "Edit" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "fadeSelect",
                  attrs: { dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.show()
                    }
                  }
                },
                [_vm._v("mdi-circle-edit-outline")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            icon: "mdi-circle-edit-outline",
            color: "primary",
            large: "",
            title: _vm.label
          },
          on: {
            confirm: function($event) {
              return _vm.$emit("save", _vm.text)
            }
          }
        },
        [
          _c("tiptap-vuetify", {
            staticClass: "mt-4",
            attrs: {
              extensions: _vm.extensions,
              "card-props": { flat: true, tile: true, elevation: 0 },
              "toolbar-attributes": _vm.$vuetify.theme.dark
                ? { color: "black", dark: true }
                : {}
            },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }