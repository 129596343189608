















import { Synergy } from '@/classes/Synergy'
import Vue from 'vue'
export default Vue.extend({
  name: 'cc-active-synergy',
  props: {
    locations: {
      type: [String, Array],
      required: true,
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
    mech: {
      type: Object,
      required: true,
    },
  },
  computed: {
    synergies() {
      if (!this.locations) return []
      if (Array.isArray(this.locations))
        return this.locations.flatMap(l => Synergy.Collect(l, this.mech, this.item))
      return Synergy.Collect(this.locations, this.mech, this.item)
    },
  },
})
