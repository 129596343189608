var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "light-panel pb-1",
      attrs: { outlined: "", dense: "", color: _vm.item.Color }
    },
    [
      _vm.item.ItemType === "MechWeapon"
        ? _c(
            "v-row",
            {
              staticClass: "stark--text mb-n3",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-icon",
                    { staticClass: "mt-n2", attrs: { large: "", left: "" } },
                    [_vm._v("cci-weapon")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "heading h3" }, [
                    _vm._v(_vm._s(_vm.item.Name))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", { staticClass: "ml-auto", attrs: { cols: "auto" } }, [
                _c("span", { staticClass: "overline" }, [
                  _vm._v("INTEGRATED MECH WEAPON")
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("integrated-info-base", { attrs: { item: _vm.item } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }