var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-x": "", left: "", "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      small: "",
                      outlined: "",
                      disabled: !_vm.chargables.length
                    }
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-dice-6")]),
                  _vm._v("\n      Recharge\n    ")
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "text-center text--text" },
            [
              _c(
                "div",
                { staticClass: "body-text" },
                [
                  _vm._v(
                    "\n        At the start of this NPC's turn,\n        "
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", text: "", color: "primary" },
                      on: { click: _vm.rollDie }
                    },
                    [_vm._v("roll 1d6")]
                  ),
                  _vm._v(
                    "\n        to determine which features are recharged\n      "
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(6, function(n) {
                return _c(
                  "v-btn",
                  {
                    key: "rb" + n,
                    staticClass: "mt-0 mb-4",
                    attrs: {
                      ripple: false,
                      "x-large": "",
                      color: _vm.roll && _vm.roll !== n ? "panel" : "primary",
                      icon: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.roll = n
                      }
                    }
                  },
                  [
                    _c("v-icon", {
                      staticClass: "die-hover",
                      attrs: { size: "55px" },
                      domProps: { innerHTML: _vm._s("mdi-dice-" + n) }
                    })
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.recharged.length
                ? _c(
                    "div",
                    { staticClass: "light-panel body-text" },
                    [
                      _vm._v(
                        "\n        The following features will be recharged:\n        "
                      ),
                      _vm._l(_vm.recharged, function(r) {
                        return _c(
                          "div",
                          { key: r.ID, staticClass: "heading h3" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(r.Name) +
                                " (" +
                                _vm._s(r.ChargeRoll) +
                                "+)\n        "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { tile: "", color: "primary" },
                  on: { click: _vm.commit }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }