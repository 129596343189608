































































































































































































































































import { ActivationType } from '@/classes/enums'
import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'deployed-item',
  props: {
    deployable: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    nameFocus: false,
    recallState: false,
    removeMenu: false,
  }),
  watch: {
    isRecalled: {
      immediate: true,
      deep: true,
      handler: function (newval) {
        this.recallState = newval
      },
    },
  },
  computed: {
    isRecalled() {
      return this.deployable.IsRecalled
    },
    canRecall() {
      if (!this.deployable.Recall) return false
      let cost = 0
      if (this.deployable.Recall === ActivationType.Quick) cost = 1
      else if (this.deployable.Recall === ActivationType.Full) cost = 2
      return cost <= this.pilot.State.Actions
    },
    canRedeploy() {
      if (!this.deployable.Redeploy) return false
      let cost = 0
      if (this.deployable.Redeploy === ActivationType.Quick) cost = 1
      else if (this.deployable.Redeploy === ActivationType.Full) cost = 2
      return cost <= this.pilot.State.Actions
    },
    recallIcon() {
      if (!this.deployable.Recall) return ''
      else if (this.deployable.Recall === ActivationType.Quick) return 'mdi-hexagon-slice-3'
      else if (this.deployable.Recall === ActivationType.Full) return 'mdi-hexagon-slice-6'
      else return 'cci-free'
    },
    redeployIcon() {
      if (!this.deployable.Redeploy) return ''
      else if (this.deployable.Redeploy === ActivationType.Quick) return 'mdi-hexagon-slice-3'
      else if (this.deployable.Redeploy === ActivationType.Full) return 'mdi-hexagon-slice-6'
      else return 'cci-free'
    },
  },
  methods: {
    recall() {
      if (this.pilot.State.RecallDeployable(this.deployable)) {
        this.$nextTick().then(() => (this.deployable.IsRecalled = true))
        this.recallState = true
      }
    },
    freeRecall() {
      this.$nextTick().then(() => (this.deployable.IsRecalled = false))
      this.$nextTick().then(() => (this.recallState = false))
    },
    redeploy() {
      if (this.pilot.State.RedeployDeployable(this.deployable)) {
        this.$nextTick().then(() => (this.deployable.IsRecalled = false))
        this.recallState = false
      }
    },
  },
})
