var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center", align: "center" } },
    [
      _vm.action.Detail
        ? _c(
            "v-col",
            [_c("action-detail-expander", { attrs: { action: _vm.action } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("action-activation-buttons", {
        attrs: {
          fulltech: _vm.fulltech,
          used: _vm.used,
          action: _vm.action,
          mech: _vm.mech
        },
        on: {
          use: function($event) {
            return _vm.$emit("use", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }