var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "mt-n6 mb-3", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "ml-auto mr-auto", attrs: { cols: "12", md: "5" } },
            [
              _c(
                "v-alert",
                {
                  staticClass: "stat-text mt-6",
                  attrs: {
                    value: true,
                    type: _vm.pilot.IsMissingHASE ? "info" : "success",
                    outlined: ""
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.pilot.CurrentHASEPoints) +
                      "/" +
                      _vm._s(_vm.pilot.MaxHASEPoints) +
                      " Mech Skills selected\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center mt-n2" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "fadeSelect",
                      attrs: { small: "", color: "info", outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.pilot.resetHASE()
                        }
                      }
                    },
                    [_vm._v("\n          Reset Mech Skills\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "cc-title",
                { attrs: { small: _vm.$vuetify.breakpoint.smAndDown } },
                [_vm._v("HULL")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "flavor-text" }, [
                _vm._v(
                  "\n        Your HULL skill describes your ability to build and pilot durable, heavy mechs that can\n        take punches and keep going\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-auto text-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-inline elevation-0",
                          attrs: {
                            color: "secondary",
                            disabled: !_vm.pilot.MechSkills.Hull,
                            fab: "",
                            "x-small": "",
                            left: "",
                            bottom: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.remove("Hull")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("remove")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("cc-rating", {
                        attrs: {
                          dense: _vm.$vuetify.breakpoint.mdAndDown,
                          model: _vm.pilot.MechSkills.Hull
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-inline elevation-0",
                          attrs: {
                            disabled: !_vm.pilot.IsMissingHASE,
                            color: "secondary",
                            fab: "",
                            "x-small": "",
                            right: "",
                            bottom: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.add("Hull")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n3 mb-6", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-auto text-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("span", { staticClass: "heading h3" }, [
                        _vm._v("\n            MECH HP\n            "),
                        _c("span", { staticClass: "accent--text" }, [
                          _vm._v("+" + _vm._s(_vm.pilot.MechSkills.Hull * 2))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("cc-slashes", { staticClass: "ml-1 mr-1" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3" }, [
                        _vm._v("\n            REPAIR CAPACITY\n            "),
                        _c("span", { staticClass: "accent--text" }, [
                          _vm._v(
                            "+" +
                              _vm._s(Math.floor(_vm.pilot.MechSkills.Hull / 2))
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "cc-title",
                { attrs: { small: _vm.$vuetify.breakpoint.smAndDown } },
                [_vm._v("AGILITY")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "flavor-text" }, [
                _vm._v(
                  "\n        Your AGILITY skill describes your ability to build and pilot fast, evasive mechs\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-auto text-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-inline elevation-0",
                          attrs: {
                            color: "secondary",
                            disabled: !_vm.pilot.MechSkills.Agi,
                            fab: "",
                            "x-small": "",
                            left: "",
                            bottom: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.remove("Agi")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("remove")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("cc-rating", {
                        attrs: {
                          dense: _vm.$vuetify.breakpoint.mdAndDown,
                          model: _vm.pilot.MechSkills.Agi
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-inline elevation-0",
                          attrs: {
                            disabled: !_vm.pilot.IsMissingHASE,
                            color: "secondary",
                            fab: "",
                            "x-small": "",
                            right: "",
                            bottom: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.add("Agi")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n3 mb-6", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-auto text-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("span", { staticClass: "heading h3" }, [
                        _vm._v("EVASION")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("+" + _vm._s(_vm.pilot.MechSkills.Agi))
                      ]),
                      _vm._v(" "),
                      _c("cc-slashes", { staticClass: "ml-1 mr-1" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3" }, [
                        _vm._v("SPEED")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v(
                          "+" + _vm._s(Math.floor(_vm.pilot.MechSkills.Agi / 2))
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "cc-title",
                { attrs: { small: _vm.$vuetify.breakpoint.smAndDown } },
                [_vm._v("SYSTEMS")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "flavor-text" }, [
                _vm._v(
                  "\n        Your SYSTEMS skill describes your ability to build and pilot technical mechs with powerful\n        electronic warfare tools\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-auto text-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-inline elevation-0",
                          attrs: {
                            color: "secondary",
                            disabled: !_vm.pilot.MechSkills.Sys,
                            fab: "",
                            "x-small": "",
                            left: "",
                            bottom: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.remove("Sys")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("remove")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("cc-rating", {
                        attrs: {
                          dense: _vm.$vuetify.breakpoint.mdAndDown,
                          model: _vm.pilot.MechSkills.Sys
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-inline elevation-0",
                          attrs: {
                            color: "secondary",
                            disabled: !_vm.pilot.IsMissingHASE,
                            fab: "",
                            "x-small": "",
                            right: "",
                            bottom: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.add("Sys")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n3 mb-6", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-auto text-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("span", { staticClass: "heading h3" }, [
                        _vm._v("ELECTRONIC DEFENSE")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("+" + _vm._s(_vm.pilot.MechSkills.Sys))
                      ]),
                      _vm._v(" "),
                      _c("cc-slashes", { staticClass: "ml-1 mr-1" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3" }, [
                        _vm._v("TECH ATTACK")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("+" + _vm._s(_vm.pilot.MechSkills.Sys))
                      ]),
                      _vm._v(" "),
                      _c("cc-slashes", { staticClass: "ml-1 mr-1" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3" }, [_vm._v("SP")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v(
                          "+" + _vm._s(Math.floor(_vm.pilot.MechSkills.Sys / 2))
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "cc-title",
                { attrs: { small: _vm.$vuetify.breakpoint.smAndDown } },
                [_vm._v("Engineering")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "flavor-text" }, [
                _vm._v(
                  "\n        Your ENGINEERING skill describes your ability to build and pilot mechs with powerful\n        reactors, supplies and support systems\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-auto text-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-inline elevation-0",
                          attrs: {
                            color: "secondary",
                            disabled: !_vm.pilot.MechSkills.Eng,
                            fab: "",
                            "x-small": "",
                            left: "",
                            bottom: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.remove("Eng")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("remove")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("cc-rating", {
                        attrs: {
                          dense: _vm.$vuetify.breakpoint.mdAndDown,
                          model: _vm.pilot.MechSkills.Eng
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-inline elevation-0",
                          attrs: {
                            color: "secondary",
                            disabled: !_vm.pilot.IsMissingHASE,
                            fab: "",
                            "x-small": "",
                            right: "",
                            bottom: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.add("Eng")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n3 mb-6", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-auto text-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("span", { staticClass: "heading h3" }, [
                        _vm._v("HEAT CAPACITY")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("+" + _vm._s(_vm.pilot.MechSkills.Eng))
                      ]),
                      _vm._v(" "),
                      _c("cc-slashes", { staticClass: "ml-1 mr-1" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3" }, [
                        _vm._v("LIMITED SYSTEMS BONUS")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v(
                          "+" + _vm._s(Math.floor(_vm.pilot.MechSkills.Eng / 2))
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }