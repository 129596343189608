var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: { complete: "", exit: "/pilot/" + _vm.pilot.ID },
      on: {
        complete: function($event) {
          return _vm.$emit("next")
        }
      }
    },
    [
      _c("cc-title", { attrs: { large: "" } }, [
        _vm._v("Level Up (Overview) ")
      ]),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "heading" },
        [
          _vm._v("\n    MV-2 License Acquisition Request\n    "),
          _c("cc-slashes"),
          _vm._v("\n     Overview\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "absolute", right: "16px", top: "16px" } },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Feature In Development" } },
            [
              _c(
                "v-btn",
                { attrs: { small: "", outlined: "", disabled: "" } },
                [_vm._v("Suggest Level Upgrades")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text", staticStyle: { "font-size": "14px" } },
        [
          _c("span", [
            _vm._v(
              "\n      The UAD IDENT MV-2 License Acquisition Request has been designed to allow active Union\n      personnel to register license updates while field-deployed and receive immediate UN and GMS\n      field support resources. Union personnel should confirm the correctness of their submitted\n      form with their local Union officer, or, in the event an officer is unavailable, request an\n      NHP-directed review at secure Omninet address\n      "
            ),
            _c("code", [_vm._v("un_omni-18364.andes.cerrobonete.node:9")])
          ]),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "mt-2",
              attrs: {
                color: "accent",
                outlined: "",
                dense: "",
                border: "left"
              }
            },
            [
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  "\n        Submitting unauthorized licensing updates and/or submitting applications for unauthorized\n        or previously-witheld license rights without prior MV-8a/j authorization by a Union\n        Administrator will result in the automatic rejection of the licensing application and may\n        result in a licensing audit under DoJ/HR AR 716-J\n      "
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c("v-col", { staticClass: "text-center" }, [
                _vm._v(
                  "\n        The UAD IDENT Service has determined that \n        "
                ),
                _c(
                  "div",
                  {
                    staticClass: "accent--text stat-text d-inline-block",
                    staticStyle: {
                      position: "relative",
                      top: "10px",
                      "line-height": "10px"
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.pilot.Name) + "\n          "
                    ),
                    _c("cc-slashes"),
                    _vm._v(
                      '\n          "' +
                        _vm._s(_vm.pilot.Callsign) +
                        '"\n          '
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "overline subtle--text" }, [
                      _vm._v(_vm._s(_vm.pilot.ID))
                    ])
                  ],
                  1
                ),
                _vm._v(
                  "\n         is eligible for the following license update:\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-card",
                { attrs: { color: "primary", outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-3" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "overline white--text" },
                                [_vm._v("LICENSE LEVEL")]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "white--text display-4 font-weight-black"
                                },
                                [_vm._v(_vm._s(_vm.pilot.Level))]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "flavor-text white--text px-4",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("ul", [
                                _c("li", [
                                  _vm._v("PILOT SKILL TRIGGER IMPROVEMENT")
                                ]),
                                _vm._v(" "),
                                _c("li", [_vm._v("PILOT TALENT UPGRADE")]),
                                _vm._v(" "),
                                _c("li", [_vm._v("MECH SKILL UPGRADE")]),
                                _vm._v(" "),
                                _c("li", [_vm._v("GRIT IMPROVEMENT")]),
                                _vm._v(" "),
                                _c("li", [_vm._v("NEW LICENSE UNLOCK")]),
                                _vm._v(" "),
                                _vm.cbEligible
                                  ? _c(
                                      "li",
                                      { staticClass: "font-weight-bolder" },
                                      [
                                        _vm._v(
                                          "\n                  CORE BONUS INSTALLATION\n                "
                                        )
                                      ]
                                    )
                                  : _c("li", { staticClass: "subtle--text" }, [
                                      _vm._v("INELIGIBLE FOR CORE BONUS")
                                    ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }