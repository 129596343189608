var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "75vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "title-bg clipped-large", dark: "", flat: "" } },
            [
              _c("v-toolbar-title", { staticClass: "heading h1" }, [
                _vm._v("STRUCTURE DAMAGE")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "11" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Roll")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Result")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Effect")
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-5")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-minus")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-6")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Glancing Blow")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Emergency systems kick in and stabilize your mech. However, your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("impaired")]),
                                    _vm._v(
                                      "\n                  until the end of your next turn.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-2")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-minus")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-4")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("System Trauma")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Parts of your mech are torn off by the damage. Roll a d6. On a 1-3, all the\n                  weapons on one mount are destroyed. On a 4-6, a system is destroyed. You choose\n                  what’s destroyed, but systems or weapons with the limited tag and no charges\n                  left are not valid. If there’s nothing left of one result, it becomes the other.\n                  If there’s absolutely nothing left to destroy, this result becomes DIRECT HIT\n                  instead.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Direct Hit")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  This result has different outcomes depending on how much structure your mech has\n                  remaining.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  3+ - Your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("stunned")]),
                                    _vm._v(
                                      "\n                  until the end of your next turn.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  2 - Your mech must pass a\n                  "
                                    ),
                                    _c("b", [_vm._v("hull")]),
                                    _vm._v(
                                      "\n                  check or be\n                  "
                                    ),
                                    _c("b", [_vm._v("destroyed.")]),
                                    _vm._v(
                                      "\n                  Even on a successful check, your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("stunned")]),
                                    _vm._v(
                                      "\n                  until the end of your next turn.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  1 or lower - Your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("destroyed")]),
                                    _vm._v(
                                      "\n                  .\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Crushing Hit")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Your mech is damaged beyond repair and is\n                  "
                                    ),
                                    _c("b", [_vm._v("destroyed")]),
                                    _vm._v(
                                      "\n                  . You can still exit it as normal.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("Dismiss")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }