import { render, staticRenderFns } from "./_cCardBase.vue?vue&type=template&id=6389143b&scoped=true&"
import script from "./_cCardBase.vue?vue&type=script&lang=ts&"
export * from "./_cCardBase.vue?vue&type=script&lang=ts&"
import style0 from "./_cCardBase.vue?vue&type=style&index=0&id=6389143b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6389143b",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCol,VIcon,VRow,VSpacer,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6389143b')) {
      api.createRecord('6389143b', component.options)
    } else {
      api.reload('6389143b', component.options)
    }
    module.hot.accept("./_cCardBase.vue?vue&type=template&id=6389143b&scoped=true&", function () {
      api.rerender('6389143b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/selectors/views/components/_cCardBase.vue"
export default component.exports