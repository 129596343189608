var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { "margin-top": "50px" }, attrs: { fluid: "" } },
    [
      _c(
        "v-stepper",
        {
          staticClass: "elevation-0",
          staticStyle: { "background-color": "var(--v-background-base)" },
          attrs: { "non-linear": "" },
          model: {
            value: _vm.step,
            callback: function($$v) {
              _vm.step = $$v
            },
            expression: "step"
          }
        },
        [
          _c(
            "v-stepper-header",
            { staticClass: "elevation-0", staticStyle: { height: "40px" } },
            [
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: "",
                    complete: _vm.pilot.HasIdent,
                    color: _vm.pilot.HasIdent ? "success" : "primary",
                    "edit-icon": "mdi-check",
                    step: "1"
                  }
                },
                [_c("span", [_vm._v("Identification")])]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: "",
                    complete: _vm.pilot.HasFullSkills,
                    color: _vm.pilot.HasFullSkills ? "success" : "primary",
                    "edit-icon": "mdi-check",
                    step: "2"
                  }
                },
                [_c("span", [_vm._v("Skills")])]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: "",
                    complete: _vm.pilot.HasFullTalents,
                    color: _vm.pilot.HasFullTalents ? "success" : "primary",
                    "edit-icon": "mdi-check",
                    step: "3"
                  }
                },
                [_c("span", [_vm._v("Talents")])]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: "",
                    complete: _vm.pilot.HasFullHASE,
                    color: _vm.pilot.HasFullHASE ? "success" : "primary",
                    "edit-icon": "mdi-check",
                    step: "4"
                  }
                },
                [_c("span", [_vm._v("Mech Skills")])]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c("v-stepper-step", { attrs: { editable: "", step: "5" } }, [
                _vm._v("Confirm")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c("identification-page", {
                    attrs: { pilot: _vm.pilot },
                    on: {
                      next: function($event) {
                        _vm.step++
                      },
                      templates: function($event) {
                        _vm.step = 6
                      },
                      set: function($event) {
                        _vm.pilot[$event.attr] = $event.val
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c("skills-page", {
                    attrs: { pilot: _vm.pilot },
                    on: {
                      next: function($event) {
                        _vm.step++
                      },
                      back: function($event) {
                        _vm.step--
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c("talents-page", {
                    attrs: { pilot: _vm.pilot },
                    on: {
                      next: function($event) {
                        _vm.step++
                      },
                      back: function($event) {
                        _vm.step--
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "4" } },
                [
                  _c("mech-skills-page", {
                    attrs: { pilot: _vm.pilot },
                    on: {
                      next: function($event) {
                        _vm.step++
                      },
                      back: function($event) {
                        _vm.step--
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "5" } },
                [
                  _c("confirm-page", {
                    attrs: { pilot: _vm.pilot },
                    on: {
                      next: function($event) {
                        _vm.step++
                      },
                      back: function($event) {
                        _vm.step--
                      },
                      done: _vm.onDone
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "6" } },
                [
                  _c("templates-page", {
                    attrs: { pilot: _vm.pilot },
                    on: {
                      next: function($event) {
                        _vm.step = 5
                      },
                      back: function($event) {
                        _vm.step = 1
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }