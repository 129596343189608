var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-slide-y-reverse-transition",
        { attrs: { mode: "out-in" } },
        [
          _c("router-view", {
            staticStyle: { width: "100vw", "padding-top": "50px" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }