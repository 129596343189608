



















import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import { NavStore } from '@/store'
import uuid from 'uuid/v4'

import NotificationSnackbar from './NotificationSnackbar.vue'

@Component({
  components: { NotificationSnackbar },
})
export default class GlobalNotifier extends Vue {
  // TODO: move this to the store
  public notifications: INotification[] = []

  // notifications currently being shown to the user
  private shownNotifications: INotification[] = []

  // public method to create a notification, will be assigned to the global Vue when app starts
  public notify(text: string, variant: string, onClick?: () => void): void {
    const notification = { id: uuid(), variant, text, onClick }

    this.notifications = [...this.notifications, notification]
    this.shownNotifications = [...this.shownNotifications, notification]
    this.$forceUpdate()
  }

  public notifyError(error: Error): void {
    if (!error || !error.message) return
    console.error(error.message)
    const vm = this
    const nm = getModule(NavStore, this.$store)
    nm.logError({
      time: new Date(),
      message: error.message,
      component: vm?.$options?.name ?? undefined,
      stack: error.stack,
    })
    this.notify(error.message, 'error')
  }

  private hideNotification(id: string): void {
    this.shownNotifications = this.shownNotifications.filter(notif => notif.id !== id)
  }
}
