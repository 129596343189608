var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "pa-0", attrs: { dense: "", align: "center" } },
    [
      _c(
        "v-col",
        { staticClass: "pa-0" },
        [
          _c("cc-skill-item", { attrs: { bonus: _vm.bonus, skill: _vm.skill } })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-col", { attrs: { cols: "auto" } }, [
        _c(
          "div",
          { class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-auto mr-auto" : "" },
          [
            _c(
              "cc-tooltip",
              {
                attrs: {
                  simple: "",
                  content: "Increase Skill Bonus",
                  inline: ""
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      large: _vm.$vuetify.breakpoint.mdAndUp,
                      small: _vm.$vuetify.breakpoint.smAndDown,
                      color: "secondary",
                      icon: "",
                      disabled: !_vm.canAdd
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("add")
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { "x-large": "" } }, [
                      _vm._v("cci-accuracy")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "cc-tooltip",
              {
                attrs: {
                  simple: "",
                  content: "Decrease Skill Bonus",
                  inline: ""
                }
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      large: _vm.$vuetify.breakpoint.mdAndUp,
                      small: _vm.$vuetify.breakpoint.smAndDown,
                      color: "error",
                      icon: "",
                      disabled: !_vm.canRemove
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("remove")
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { "x-large": "" } }, [
                      _vm._v("cci-difficulty")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }