var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      style:
        "opacity: 0.5; " + (_vm.size ? "font-size:" + _vm.size + "pt;" : "")
    },
    [_vm._v("//")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }