var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "4" } },
    [
      _c(
        "v-card",
        {
          staticClass: "ma-1",
          attrs: {
            color: _vm.item.Destroyed ? "rgba(200, 80, 80, 0.3)" : "",
            outlined: ""
          }
        },
        [
          _c(
            "v-card-text",
            { staticClass: "py-2 px-3" },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-2", attrs: { lg: "12", xl: "auto" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "heading h3",
                          style: _vm.item.Destroyed
                            ? "text-decoration: line-through;"
                            : ""
                        },
                        [
                          _vm.item.FlavorName
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.item.Name) +
                                    "\n              "
                                ),
                                _c("span", { staticClass: "caption" }, [
                                  _vm._v(_vm._s(_vm.item.TrueName))
                                ])
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.item.Name) +
                                    "\n            "
                                )
                              ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.item.Damage
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("cc-damage-element", {
                            staticClass: "d-inline",
                            attrs: {
                              damage: _vm.item.Damage,
                              "type-override": _vm.item.DamageTypeOverride,
                              small: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.Range
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("cc-range-element", {
                            staticClass: "d-inline",
                            attrs: { range: _vm.item.Range, small: "" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.MaxUses
                    ? _c(
                        "v-col",
                        { staticClass: "mb-n1", attrs: { cols: "auto" } },
                        [
                          _c("cc-item-uses", {
                            attrs: { item: _vm.item, bonus: _vm.limitedBonus }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "75vw" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        staticClass: "fadeSelect",
                                        attrs: { slot: "button" },
                                        slot: "button"
                                      },
                                      on
                                    ),
                                    [_vm._v("mdi-information-outline")]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "cc-titled-panel",
                            {
                              attrs: {
                                title: _vm.item.Name,
                                color: _vm.$_.kebabCase(_vm.item.ItemType)
                              }
                            },
                            [_c("cc-item-card", { attrs: { item: _vm.item } })],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            content:
                              "Mark " +
                              (_vm.item.Destroyed ? "Repaired" : "Destroyed")
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "fadeSelect",
                              attrs: {
                                color: _vm.item.Destroyed
                                  ? "secondary"
                                  : "error"
                              },
                              on: {
                                click: function($event) {
                                  _vm.item.Destroyed = !_vm.item.Destroyed
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.item.Destroyed
                                      ? "cci-repair"
                                      : "mdi-image-broken-variant"
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }