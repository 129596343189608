



















































































































import Vue from 'vue'
import _ from 'lodash'
import Selector from './components/_SelectorBase.vue'
import MissingItem from './components/_MissingItem.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { Rules, Pilot, Talent } from '@/class'
import { accentInclude } from '@/classes/utility/accent_fold'

export default Vue.extend({
  name: 'talent-selector',
  components: { Selector, MissingItem },
  props: {
    pilot: Pilot,
    levelUp: Boolean,
  },
  data: () => ({
    search: '',
    ctype: 'full',
  }),
  computed: {
    newPilot(): boolean {
      return this.pilot.Level === 0
    },
    selectedMin(): number {
      return Rules.MinimumPilotTalents
    },
    enoughSelections(): boolean {
      // we should only care about the minimum pilot talents in non-levelup (creation)
      return this.pilot.Level === 0 || !(this.pilot.Talents.length < this.selectedMin)
    },
    selectionComplete(): boolean {
      return (this.newPilot || this.levelUp) && !this.pilot.IsMissingTalents
    },
    talents(): Talent[] {
      const compendium = getModule(CompendiumStore, this.$store)
      const talents = compendium.Talents.filter(x => !x.IsHidden)
      if (this.search) return talents.filter(x => accentInclude(x.Name, this.search))

      return talents
    },
  },
  methods: {
    canAdd(id) {
      if (this.newPilot) {
        return this.pilot.getTalentRank(id) === 0 && this.pilot.IsMissingTalents
      }
      return this.pilot.IsMissingTalents
    },
    scroll(id) {
      if (this.levelUp)
        this.$vuetify.goTo(`#e_${id}`, {
          duration: 150,
          easing: 'easeInOutQuad',
          offset: 25,
        })
      else
        this.$vuetify.goTo(`#e_${id}`, {
          duration: 150,
          easing: 'easeInOutQuad',
          offset: 25,
          container: '.v-dialog--active',
        })
    },
  },
  watch: {
    selectionComplete(bool) {
      if (bool) window.scrollTo(0, document.body.scrollHeight)
    },
  },
})
