var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "mb-2 " + (_vm.clickable ? "clickable" : ""),
      attrs: { id: "panel-wrapper" },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "v-toolbar",
        {
          class:
            (_vm.dense ? "clipped-invert" : "clipped-large-invert") +
            " " +
            (_vm.clickable ? "titlebar" : ""),
          style: _vm.dense ? "height: 28px" : "",
          attrs: {
            color: _vm.color ? _vm.color : "primary",
            flat: "",
            dense: "",
            dark: ""
          }
        },
        [
          _c(
            "v-toolbar-title",
            { class: _vm.dense ? "mt-n6" : "" },
            [
              _vm.icon
                ? _c("v-icon", { attrs: { "x-large": !_vm.dense, left: "" } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  class:
                    "heading h3 pr-3 " +
                    (_vm.clickable ? "underline-slide" : "")
                },
                [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-toolbar-items", { staticClass: "mr-4" }, [_vm._t("items")], 2)
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        {
          style:
            "background-color: var(--v-panel-base); border-color: " +
            (_vm.color ? _vm.color : "var(--v-primary-base)") +
            " !important; margin-top: -2px",
          attrs: { flat: "", outlined: "" }
        },
        [
          _c(
            "v-card-text",
            { staticClass: "pt-2 pb-0 px-4" },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }