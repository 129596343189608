var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticStyle: {
            height: "calc(100vh - 185px)",
            "overflow-y": "scroll"
          },
          attrs: { id: "scroll", dense: "" }
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { flat: "", outlined: "", height: "100%" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.selected
                        ? _c("player-card", {
                            attrs: { mech: _vm.selected, rest: "" },
                            on: {
                              "delete-actor": function($event) {
                                return _vm.activeMission.RemovePilot($event)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selected
                        ? _c("encounter-nav", {
                            attrs: {
                              mission: _vm.activeMission,
                              encounter: _vm.encounter,
                              actor: _vm.selected
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "v-row",
        { staticStyle: { "min-height": "100px" }, attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "", outlined: "", height: "100%" } },
                [
                  _c(
                    "v-slide-group",
                    {
                      staticStyle: { height: "calc(100% - 8px)" },
                      attrs: { mandatory: "", "center-active": "" },
                      model: {
                        value: _vm.selectedPilot,
                        callback: function($$v) {
                          _vm.selectedPilot = $$v
                        },
                        expression: "selectedPilot"
                      }
                    },
                    _vm._l(_vm.pilots, function(a) {
                      return _c("slide-item", {
                        key: a.ID,
                        attrs: { actor: a }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-center", attrs: { cols: "1" } },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-x": "", left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mr-1",
                                staticStyle: { height: "100%" },
                                attrs: {
                                  block: "",
                                  color: "secondary",
                                  outlined: ""
                                }
                              },
                              on
                            ),
                            [
                              _vm._v("\n            Complete\n            "),
                              _c("br"),
                              _vm._v("\n            Rest\n          ")
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center font-weight-bold" },
                        [
                          _vm._v(
                            "\n            This will conclude the rest and progress the mission. This can not be undone. Are you\n            sure you want to continue?\n            "
                          ),
                          _c("v-divider", { staticClass: "my-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-btn", { attrs: { small: "", text: "" } }, [
                                _vm._v("CANCEL")
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-auto",
                                  attrs: { small: "", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("finish")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                CONFIRM\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }