var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: { complete: _vm.canContinue, exit: "pilot_management", back: "" },
      on: {
        back: function($event) {
          return _vm.$emit("back")
        },
        complete: function($event) {
          return _vm.$emit("next")
        }
      }
    },
    [
      _c("cc-title", { attrs: { large: "" } }, [_vm._v("Pilot Mech Skills ")]),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "heading" },
        [
          _vm._v("\n    UAD IDENT Service\n    "),
          _c("cc-slashes"),
          _vm._v("\n     RM-4d Pilot Self Assessment (3/3)\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "absolute", right: "16px", top: "16px" } },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Feature In Development" } },
            [
              _c(
                "v-btn",
                { attrs: { small: "", outlined: "", disabled: "" } },
                [_vm._v("Suggest Mech Skills")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text", staticStyle: { "font-size": "14px" } },
        [
          _c("div", { staticClass: "mt-n2" }, [
            _vm._v(
              "\n      The RM-4b PILOT SELF ASSESSMENT (Mech Skills) will be transmitted to print staff to finalize\n      the configuration of your issued GMS-SP1 EVEREST. This configuration data will be remitted\n      to your personal Omninet vault on the validation and acceptance of the RM-4 form along with\n      the issuance of your IDENT fingerprint, which then may be applied to any future Frame(s) at\n      your discretion.\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "mt-2",
              attrs: {
                type: "warning",
                color: "accent",
                outlined: "",
                dense: "",
                prominent: ""
              }
            },
            [
              _c("b", [_vm._v("Add two (2) Mech Skill Points.")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "overline",
                  staticStyle: { "line-height": "13px" }
                },
                [
                  _vm._v(
                    "\n        By submitting this form you attest that your responses are truthful and accurate to the\n        best of your knowledge. Knowingly providing false or or incomplete information is\n        punishable under DoJ/HR AR 303-J.\n      "
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-mech-skills-selector", { attrs: { pilot: _vm.pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }