var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "output-container" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "mr-2", attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "sidebar" }),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                attrs: { src: require("../../../assets/ui/sb_l.png") }
              })
            ])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("p", {
              ref: "completed",
              staticClass: "flavor-text subtle--text text--darken-1 py-0 my-0",
              attrs: { id: "completed" }
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                ref: "output",
                staticClass:
                  "flavor-text subtle--text text--darken-1 py-0 my-0",
                attrs: { id: "output" }
              },
              [_c("br"), _vm._v(" "), _c("br"), _vm._v(" "), _c("br")]
            )
          ]),
          _vm._v(" "),
          _c("v-col", { staticClass: "ml-2", attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "sidebar" }),
            _vm._v(" "),
            _c("div", [
              _c("img", {
                staticClass: "ml-n2",
                attrs: { src: require("../../../assets/ui/sb_r.png") }
              })
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }