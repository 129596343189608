var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "pb-0",
      attrs: { dense: "", color: _vm.color, border: "left", outlined: "" }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("h3", { staticClass: "heading", style: "color: " + _vm.color }, [
              _vm._v(_vm._s(_vm.trait.Name))
            ])
          ]),
          _vm._v(" "),
          _vm.trait.Use !== "Mission"
            ? _c("v-col", { staticClass: "ml-auto", attrs: { cols: "auto" } }, [
                _c("div", { staticClass: "subtle--text overline mt-n2" }, [
                  _vm._v(_vm._s(_vm.trait.Use))
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", align: "center" } },
        [
          _c("v-col", [
            _c("div", {
              staticClass: "body-text text--text",
              domProps: { innerHTML: _vm._s(_vm.trait.Description) }
            })
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _vm._l(_vm.trait.Actions, function(a, i) {
                return _c("cc-action", {
                  key: _vm.trait.ID + "_action_" + i,
                  attrs: { action: a, panel: _vm.$vuetify.breakpoint.lgAndUp }
                })
              }),
              _vm._v(" "),
              _vm._l(_vm.trait.Deployables, function(d, i) {
                return _c("cc-deployable-info", {
                  key: _vm.trait.ID + "_deployable_" + i,
                  attrs: {
                    deployable: d,
                    panel: _vm.$vuetify.breakpoint.lgAndUp
                  }
                })
              }),
              _vm._v(" "),
              _vm._l(_vm.trait.Integrated, function(x, i) {
                return _c("cc-integrated-info", {
                  key: _vm.trait.ID + "_integrated_" + i,
                  attrs: { item: x, panel: _vm.$vuetify.breakpoint.lgAndUp }
                })
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }