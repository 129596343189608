var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-titlebar",
    {
      attrs: { large: "", color: _vm.unusable ? "#616161" : _vm.action.Color }
    },
    [
      !_vm.noAction
        ? _c("v-icon", {
            attrs: { dark: "", "x-large": "" },
            domProps: {
              innerHTML: _vm._s(_vm.unusable ? "mdi-cancel" : _vm.action.Icon)
            }
          })
        : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.action.Name) + "\n  "),
      _vm.unusable
        ? _c(
            "span",
            { staticClass: "flavor-text" },
            [_c("cc-slashes"), _vm._v("\n    Action Unavailable\n  ")],
            1
          )
        : _vm.action.Origin
        ? _c(
            "span",
            { staticClass: "flavor-text" },
            [
              _c("cc-slashes"),
              _vm._v("\n    " + _vm._s(_vm.action.Origin) + "\n  ")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { slot: "items", dark: "", icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("hide")
            }
          },
          slot: "items"
        },
        [_c("v-icon", { attrs: { large: "", left: "" } }, [_vm._v("close")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }