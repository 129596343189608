var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          label: "Reserve Type",
          items: _vm.reserveTypes,
          outlined: "",
          dense: "",
          "hide-details": ""
        },
        on: {
          change: function($event) {
            _vm.reserve = ""
          }
        },
        model: {
          value: _vm.type,
          callback: function($$v) {
            _vm.type = $$v
          },
          expression: "type"
        }
      }),
      _vm._v(" "),
      _vm.type && _vm.type !== "Custom"
        ? _c("v-select", {
            staticClass: "ma-2",
            attrs: {
              label: "Reserve",
              items: _vm.reserves,
              "item-text": "name",
              "item-value": "id",
              outlined: "",
              dense: "",
              "hide-details": ""
            },
            model: {
              value: _vm.reserve,
              callback: function($$v) {
                _vm.reserve = $$v
              },
              expression: "reserve"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-slide-y-transition",
        [
          _vm.type === "Custom" || _vm.reserve
            ? _c(
                "v-card",
                { staticClass: "mx-3 mt-1", attrs: { color: "light-panel" } },
                [
                  _c("v-card-text", { staticClass: "flavor-text pt-1 pb-0" }, [
                    _vm.type !== "Custom" && _vm.reserve
                      ? _c("p", {
                          staticClass: "pa-1 ma-1",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.reserveByID(_vm.reserve).description
                            )
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type !== "Mech" &&
                    _vm.reserve !== "reserve_extendedharness" &&
                    _vm.reserve !== "reserve_bombardment"
                      ? _c(
                          "div",
                          [
                            _c("v-text-field", {
                              staticStyle: { width: "500px" },
                              attrs: {
                                color: "accent",
                                label: "Resource Name"
                              },
                              model: {
                                value: _vm.custom_name,
                                callback: function($$v) {
                                  _vm.custom_name = $$v
                                },
                                expression: "custom_name"
                              }
                            }),
                            _vm._v(" "),
                            _c("v-textarea", {
                              attrs: {
                                color: "accent",
                                "auto-grow": "",
                                rows: "1",
                                label: "Details",
                                filled: ""
                              },
                              model: {
                                value: _vm.details,
                                callback: function($$v) {
                                  _vm.details = $$v
                                },
                                expression: "details"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }