var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.profile && _vm.profile.GetView
    ? _c(
        "div",
        [
          _vm.profile.GetView("pilotSheet") === "tabbed"
            ? _c("tabbed-layout", {
                attrs: { page: _vm.tab, pilot: _vm.pilot }
              })
            : _vm.profile.GetView("pilotSheet") === "classic"
            ? _c("classic-layout", {
                attrs: { page: _vm.tab, pilot: _vm.pilot }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("pilot-nav", {
            attrs: { pilot: _vm.pilot, selected: _vm.tab },
            on: {
              set: function($event) {
                return _vm.profile.SetView("pilotSheet", $event)
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }