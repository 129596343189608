var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.AiSystems.length,
          expression: "AiSystems.length"
        }
      ]
    },
    [
      _c("cc-synergy-display", {
        attrs: { location: "cascade", mech: _vm.mech }
      }),
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { tile: "", elevation: "0", outlined: "" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "text-center white--text heading h3",
              attrs: { dense: "", elevation: "0", color: "error" }
            },
            [
              _vm._v("\n        CASCADE ALERT\n        "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "cc-tooltip",
                {
                  attrs: {
                    title: "CASCADE",
                    content:
                      "<p>Except for some limited systems &ndash; like comp/cons &ndash; any time a mech with the AI tag makes a structure damage or overheating check, roll 1d20. On a 1, the NHP&rsquo;s casket has suffered a traumatic impact or code incursion and the NHP enters cascade.&nbsp;</p>\n<p>When an NHP begins to cascade, they immediately take over the mech and become an NPC under the control of the GM. They plan their own agenda, and adopt one of several behavioral patterns &ndash; ignoring their pilot, overruling their pilot, trying to get their pilot out of the way, or simply acting outside the constraints of human logic and desire. In cascade, NHPs&rsquo; actions do not always conform to human logic &ndash; though they are not yet unshackled, their actions may seem odd, contrapositive, or threatening to humans.&nbsp;</p>\n<p>NHPs in cascade can be stabilized by a pilot choosing to <strong>SHUT DOWN</strong> their mech, returning them to their base state. This is the only action a pilot in a cascading mech can take. </p>"
                  }
                },
                [_c("v-icon", [_vm._v("mdi-information-outline")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.AiSystems, function(a, i) {
            return _c(
              "v-row",
              {
                key: a.ID + "_" + i,
                staticClass: "heading h3 py-2",
                attrs: { align: "center" }
              },
              [
                _c("v-col", [
                  _vm._v("\n          " + _vm._s(a.Name) + "\n        ")
                ]),
                _vm._v(" "),
                _c("v-col", [
                  _vm._v("\n          STATUS:\n          "),
                  !_vm.checked.includes(i)
                    ? _c("span", { staticStyle: { opacity: "0.4" } }, [
                        _vm._v("PENDING")
                      ])
                    : _c(
                        "span",
                        {
                          class: a.IsCascading ? "red--text" : "success--text"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(a.IsCascading ? "IN CASCADE" : "NOMINAL") +
                              "\n          "
                          )
                        ]
                      )
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "mr-3", attrs: { cols: "auto" } },
                  [
                    _c("cc-dice-menu", {
                      attrs: { preset: "1d20" },
                      on: {
                        commit: function($event) {
                          return _vm.checkCascade($event.total, i)
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }