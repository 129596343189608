var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { overflow: "hidden" }, attrs: { fluid: "" } },
    [
      _vm.$vuetify.breakpoint.mdAndDown
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "cc-title",
                    {
                      attrs: {
                        small: "",
                        color: _vm.success ? "success" : "primary"
                      }
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  ),
                  _vm._v(" "),
                  _vm._t("left-column")
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { ref: "content", attrs: { cols: "12" } },
                [_vm._t("right-column")],
                2
              )
            ],
            1
          )
        : _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pr-4", attrs: { cols: "3" } }, [
                _c(
                  "div",
                  {
                    ref: "float",
                    class:
                      (_vm.floating ? "fixed-float" : "") +
                      " " +
                      (_vm.success ? "bordered-success" : "bordered-primary"),
                    staticStyle: { width: "23vw" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "ml-3", attrs: { width: "90%" } },
                      [
                        _c(
                          "cc-title",
                          {
                            attrs: {
                              small: "",
                              color: _vm.success ? "success" : "primary"
                            }
                          },
                          [_vm._v(_vm._s(_vm.title))]
                        ),
                        _vm._v(" "),
                        _vm._t("left-column")
                      ],
                      2
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { ref: "content", attrs: { cols: "9" } },
                [_vm._t("right-column")],
                2
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }