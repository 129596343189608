var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "35vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "text-center", attrs: { tile: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("span", { staticClass: "heading h2" }, [
                _vm._v("Select Damage Type")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                _vm._l(_vm.availableTypes, function(t) {
                  return _c("v-col", { key: t + " Damage" }, [
                    _c(
                      "div",
                      { staticClass: "clip-icon" },
                      [
                        _c(
                          "cc-tooltip",
                          { attrs: { simple: "", inline: "", content: t } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  "x-large": "",
                                  icon: "",
                                  color: "damage--" + t.toLowerCase()
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.select(t)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { size: "60px" } }, [
                                  _vm._v("cci-" + _vm._s(t.toLowerCase()))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }