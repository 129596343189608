var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "equipment-card-base",
    { attrs: { item: _vm.item } },
    [
      _c(
        "v-col",
        { staticClass: "ml-auto text-right", attrs: { cols: "auto" } },
        [
          _c("span", { staticClass: "flavor-text subtle--text" }, [
            _vm._v("// " + _vm._s(_vm.item.Origin))
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "statblock" }, slot: "statblock" }, [
        _c("span", { staticClass: "overline ml-n2" }, [_vm._v("TRIGGER")]),
        _vm._v(" "),
        _c("p", {
          directives: [
            {
              name: "html-safe",
              rawName: "v-html-safe",
              value: "<b>Trigger:&nbsp;</b>" + _vm.item.Trigger,
              expression: "`<b>Trigger:&nbsp;</b>${item.Trigger}`"
            }
          ],
          staticClass: "text--text",
          staticStyle: { "font-size": "20px" }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }