var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-6" },
    [
      _c(
        "v-row",
        { attrs: { dense: "", align: "center" } },
        [
          _c("v-col", [
            _vm.mech.Pilot.PlayerName
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("span", { staticClass: "heading h3 stark--text" }, [
                    _vm._v(_vm._s(_vm.mech.Pilot.PlayerName))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "flavor-text" }, [_vm._v("as")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "heading mech",
                staticStyle: { "line-height": "15px" }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.mech.Pilot.Callsign) + "\n        "
                ),
                _c(
                  "span",
                  { staticClass: "light-text--text" },
                  [
                    _c("cc-slashes"),
                    _vm._v(
                      "\n          " + _vm._s(_vm.mech.Name) + "\n        "
                    )
                  ],
                  1
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "heading h3" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.mech.Frame.Source) +
                    " " +
                    _vm._s(_vm.mech.Frame.Name) +
                    "\n        "
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { width: "75vw" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-icon",
                              _vm._g({ staticClass: "fadeSelect" }, on),
                              [_vm._v("mdi-information-outline")]
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "cc-titled-panel",
                      {
                        attrs: {
                          title:
                            _vm.mech.Frame.Source + " " + _vm.mech.Frame.Name
                        }
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              _vm._l(_vm.mech.Frame.Traits, function(trait, i) {
                                return _c(
                                  "cc-active-card",
                                  {
                                    key: "tr_" + i,
                                    attrs: {
                                      color: "frame",
                                      header: trait.Name,
                                      subheader: "FRAME TRAIT"
                                    }
                                  },
                                  [
                                    _c("span", {
                                      directives: [
                                        {
                                          name: "html-safe",
                                          rawName: "v-html-safe",
                                          value: trait.Description,
                                          expression: "trait.Description"
                                        }
                                      ]
                                    })
                                  ]
                                )
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              { attrs: { cols: "8" } },
                              [
                                _c(
                                  "cc-active-card",
                                  {
                                    staticStyle: { height: "100%" },
                                    attrs: {
                                      color: "corepower",
                                      header: _vm.mech.Frame.CoreSystem.Name,
                                      subheader: "CORE SYSTEM"
                                    }
                                  },
                                  [
                                    _vm.mech.Frame.CoreSystem.Passive
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "heading h2" },
                                            [_vm._v("Passive")]
                                          ),
                                          _vm._v(" "),
                                          _c("p", {
                                            directives: [
                                              {
                                                name: "html-safe",
                                                rawName: "v-html-safe",
                                                value:
                                                  _vm.mech.Frame.CoreSystem
                                                    .Passive,
                                                expression:
                                                  "mech.Frame.CoreSystem.Passive"
                                              }
                                            ],
                                            staticClass: "mb-1"
                                          })
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "heading h2" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.mech.Frame.CoreSystem.ActiveName
                                          ) +
                                          "\n                    "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "pt-2 ml-2 caption subtle--text"
                                        },
                                        [_vm._v("(ACTIVE)")]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("p", {
                                      directives: [
                                        {
                                          name: "html-safe",
                                          rawName: "v-html-safe",
                                          value:
                                            _vm.mech.Frame.CoreSystem
                                              .ActiveEffect,
                                          expression:
                                            "mech.Frame.CoreSystem.ActiveEffect"
                                        }
                                      ],
                                      staticClass: "mb-1"
                                    }),
                                    _vm._v(" "),
                                    _c("cc-tags", {
                                      attrs: {
                                        tags: _vm.mech.Frame.CoreSystem.Tags,
                                        color: "corepower"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _vm.mech.Activations === 0
                ? _c(
                    "v-btn",
                    {
                      attrs: { large: "", color: "secondary" },
                      on: {
                        click: function($event) {
                          _vm.mech.Activations += 1
                        }
                      }
                    },
                    [_vm._v("\n        Reactivate\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.mech.Activations === 0 && !_vm.mech.Defeat
        ? _c(
            "v-alert",
            {
              attrs: {
                dark: "",
                dense: "",
                border: "left",
                icon: "mdi-check",
                color: "panel"
              }
            },
            [
              _c("span", { staticClass: "heading h2" }, [
                _vm._v("Turn Complete")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.mech.StatusString, function(s) {
        return _c("cc-mech-status-alert", {
          key: "status-" + s,
          attrs: { type: s, "critical-only": "", "hide-clear": "" },
          on: {
            reprint: function($event) {
              return _vm.mech.FullRepair()
            }
          }
        })
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-around", dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("cc-status-select", {
                        attrs: {
                          label: "Statuses",
                          items: _vm.statuses,
                          model: _vm.mech.Statuses,
                          dark: "",
                          color: "deep-orange darken-1"
                        },
                        on: {
                          set: function($event) {
                            _vm.mech.Statuses = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("cc-status-select", {
                        attrs: {
                          label: "Conditions",
                          items: _vm.conditions,
                          model: _vm.mech.Conditions,
                          dark: "",
                          color: "red darken-2"
                        },
                        on: {
                          set: function($event) {
                            _vm.mech.Conditions = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("cc-status-select", {
                        attrs: {
                          label: "Resistances",
                          items: _vm.resistances,
                          model: _vm.mech.Resistances,
                          dark: "",
                          color: "blue darken-2"
                        },
                        on: {
                          set: function($event) {
                            _vm.mech.Resistances = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto mr-3", attrs: { cols: "auto" } },
                    [
                      _c("v-text-field", {
                        staticClass: "hide-input-spinners",
                        staticStyle: { width: "115px" },
                        attrs: {
                          type: "number",
                          "append-outer-icon": "mdi-plus-circle-outline",
                          "append-icon": "mdi-fire",
                          "prepend-icon": "mdi-minus-circle-outline",
                          hint: "BURN",
                          "persistent-hint": "",
                          dense: ""
                        },
                        on: {
                          "click:append-outer": function($event) {
                            _vm.mech.Burn += 1
                          },
                          "click:prepend": function($event) {
                            _vm.mech.Burn -= 1
                          },
                          change: function($event) {
                            _vm.mech.Burn = parseInt($event)
                          }
                        },
                        model: {
                          value: _vm.mech.Burn,
                          callback: function($$v) {
                            _vm.$set(_vm.mech, "Burn", $$v)
                          },
                          expression: "mech.Burn"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "mt-n3",
                  attrs: {
                    dense: "",
                    align: "center",
                    justify: "space-between"
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentStructure,
                          class: { rolledOver: _vm.structRolledOver },
                          attrs: {
                            current: _vm.mech.CurrentStructure,
                            max: _vm.mech.MaxStructure,
                            large: "",
                            color: "structure",
                            "full-icon": "cci-structure"
                          },
                          on: {
                            update: function($event) {
                              _vm.mech.CurrentStructure = $event
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("\n              Struct\n            ")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.mech.Armor
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tick-bar",
                            {
                              key: _vm.mech.Armor,
                              attrs: {
                                current: _vm.mech.Armor,
                                max: _vm.mech.Armor,
                                large: "",
                                color: "armor",
                                "full-icon": "mdi-shield",
                                readonly: "",
                                "number-only": "",
                                "hide-max": ""
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v("Armor")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentHP,
                          attrs: {
                            current: _vm.mech.CurrentHP,
                            max: _vm.mech.MaxHP,
                            large: "",
                            color: "hp",
                            rollover: "",
                            "max-length": "20"
                          },
                          on: {
                            update: function($event) {
                              _vm.mech.CurrentHP = $event
                            },
                            rollover: _vm.onHpRollover
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("HP")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.Overshield,
                          attrs: {
                            current: _vm.mech.Overshield,
                            max: _vm.mech.Overshield,
                            large: "",
                            color: "stark",
                            "full-icon": "mdi-octagram",
                            "max-length": "6",
                            "hide-max": "",
                            clearable: ""
                          },
                          on: {
                            update: function($event) {
                              _vm.mech.Overshield = $event
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("OVERSHIELD")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  attrs: {
                    dense: "",
                    align: "center",
                    justify: "space-between"
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentStress,
                          class: { rolledOver: _vm.stressRolledOver },
                          attrs: {
                            current: _vm.mech.CurrentStress,
                            max: _vm.mech.MaxStress,
                            large: "",
                            color: "stress",
                            "full-icon": "cci-reactor"
                          },
                          on: {
                            update: function($event) {
                              _vm.mech.CurrentStress = $event
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("Stress")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentHeat,
                          attrs: {
                            current: _vm.mech.CurrentHeat,
                            max: _vm.mech.HeatCapacity,
                            large: "",
                            color: _vm.mech.IsInDangerZone
                              ? "dangerzone"
                              : "heatcap",
                            "full-icon": _vm.mech.IsInDangerZone
                              ? "mdi-fire"
                              : "mdi-circle",
                            "rollover-negative": "",
                            clearable: ""
                          },
                          on: {
                            update: function($event) {
                              _vm.mech.CurrentHeat = $event
                            },
                            rollover: _vm.onHeatRollover
                          }
                        },
                        [
                          _vm.mech.IsInDangerZone
                            ? _c(
                                "span",
                                { staticClass: "dangerzone--text heading h3" },
                                [_vm._v("\n              HEAT\n            ")]
                              )
                            : _c("span", { staticClass: "heading h3" }, [
                                _vm._v("\n              HEAT\n            ")
                              ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentRepairs,
                          attrs: {
                            current: _vm.mech.CurrentRepairs,
                            max: _vm.mech.RepairCapacity,
                            large: "",
                            color: "repcap",
                            "full-icon": "control_point"
                          },
                          on: {
                            update: function($event) {
                              _vm.mech.CurrentRepairs = $event
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("\n              REPAIR CAP\n            ")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentCoreEnergy,
                          staticClass: "text-center",
                          attrs: {
                            current: _vm.mech.CurrentCoreEnergy,
                            max: 1,
                            large: "",
                            "no-input": "",
                            clearable: "",
                            color: "corepower",
                            "empty-icon": "mdi-battery-10",
                            "full-icon": "mdi-battery",
                            "hide-values": ""
                          },
                          on: {
                            update: function($event) {
                              _vm.mech.CurrentCoreEnergy = $event
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("CORE")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentOvercharge,
                          staticClass: "text-center",
                          attrs: {
                            current: _vm.mech.CurrentOvercharge,
                            max: 3,
                            large: "",
                            "no-input": "",
                            clearable: "",
                            color: "overcharge",
                            "full-icon": "mdi-alert-decagram",
                            "hide-values": ""
                          },
                          on: {
                            update: function($event) {
                              _vm.mech.CurrentOvercharge = $event
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v(
                              "\n              Overcharge \n              "
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-center overcharge--text font-weight-bold"
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.mech.OverchargeTrack[
                                        _vm.mech.CurrentOvercharge
                                      ]
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-2", attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "flavor-text mr-2 ml-2 mt-n2",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "heading h2" }, [
                            _vm._v(_vm._s(_vm.mech.Hull))
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("//HULL")]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "my-1" })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "heading h2" }, [
                            _vm._v(_vm._s(_vm.mech.Agi))
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("//AGI")]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "my-1" })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "heading h2" }, [
                            _vm._v(_vm._s(_vm.mech.Sys))
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("//SYS")]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "my-1" })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "heading h2" }, [
                            _vm._v(_vm._s(_vm.mech.Eng))
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("//ENG")]),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "my-1" })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        [
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: "pilot",
                              header: "Speed",
                              content: _vm.mech.Speed
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: "pilot",
                              header: "Atk Bonus",
                              content: "+" + _vm.mech.AttackBonus
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: "pilot",
                              header: "Tech Atk",
                              content:
                                "" +
                                (_vm.mech.TechAttack > 0 ? "+" : "") +
                                _vm.mech.TechAttack
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: "pilot",
                              header: "Evasion",
                              content: _vm.mech.IsStunned ? 5 : _vm.mech.Evasion
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: "pilot",
                              header: "E-Def",
                              content: _vm.mech.EDefense
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: "pilot",
                              header: "Save",
                              content: _vm.mech.SaveTarget
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: "pilot",
                              header: "Sensors",
                              content: _vm.mech.SensorRange
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { size: "120", color: "accent" } },
                        [_vm._v(_vm._s(_vm.mech.SizeIcon))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "", outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-1 ml-2" },
                    [
                      _vm.mech.Image
                        ? _c("v-img", {
                            key: _vm.mech.Image,
                            attrs: { src: _vm.mech.Image, "aspect-ratio": "1" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-n2 mb-n1", attrs: { dense: "" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "overline" }, [_vm._v("TALENTS")])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "fadeSelect",
                  attrs: { "x-small": "", outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.expandAll(
                        _vm.mech.Pilot.Talents.length,
                        "tal_",
                        true
                      )
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "", left: "" } }, [
                    _vm._v("mdi-chevron-up")
                  ]),
                  _vm._v("\n        All\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "fadeSelect",
                  attrs: { "x-small": "", outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.expandAll(
                        _vm.mech.Pilot.Talents.length,
                        "tal_",
                        false
                      )
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { small: "", left: "" } }, [
                    _vm._v("mdi-chevron-down")
                  ]),
                  _vm._v("\n        All\n      ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        _vm._l(_vm.mech.Pilot.Talents, function(t, i) {
          return _c(
            "cc-active-card",
            {
              key: "tal_" + i,
              ref: "tal_" + i,
              refInFor: true,
              attrs: {
                collapsible: "",
                "start-closed": "",
                color: "primary",
                cols: _vm.$vuetify.breakpoint.lgAndUp ? 4 : 6,
                header: t.Talent.Name + " " + "I".repeat(t.Rank),
                subheader: "PILOT TALENT"
              }
            },
            _vm._l(3, function(n) {
              return _c("ul", { key: "t_" + n }, [
                t.Rank >= n
                  ? _c("li", [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(t.Talent.Ranks[n - 1].Description)
                        }
                      })
                    ])
                  : _vm._e()
              ])
            }),
            0
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "overline mt-2" }, [_vm._v("COUNTERS")]),
      _vm._v(" "),
      _c("cc-counter-set", { attrs: { actor: [_vm.mech.Pilot] } }),
      _vm._v(" "),
      _c("div", { staticClass: "overline mb-n1 mt-2" }, [_vm._v("LOADOUT")]),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        _vm._l(_vm.mech.ActiveLoadout.Equipment, function(i, idx) {
          return _c("player-equipment-item", {
            key: i.ID + idx,
            attrs: { "limited-bonus": _vm.mech.Pilot.LimitedBonus, item: i }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-2" }),
      _vm._v(" "),
      _c(
        "cc-title",
        { attrs: { small: "", color: "pilot" } },
        [
          _vm._v("\n    GM's Notes\n    "),
          _c("cc-text-editor", {
            attrs: { label: "Edit Player Notes", original: _vm.mech.GmNote },
            on: {
              save: function($event) {
                _vm.mech.GmNote = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.mech.GmNote) } }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-2" }),
      _vm._v(" "),
      _vm.mech.Reactions.length && !_vm.rest
        ? _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c("div", { staticClass: "overline" }, [
                    _vm._v("STAGED REACTIONS")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.mech.Reactions, function(r, i) {
                    return _c(
                      "v-chip",
                      {
                        key: r + i,
                        staticClass: "mx-1",
                        attrs: {
                          dark: "",
                          color: "action--reaction",
                          close: "",
                          "close-icon": "mdi-close"
                        },
                        on: {
                          "click:close": function($event) {
                            return _vm.mech.RemoveReaction(r)
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { left: "", dark: "" } }, [
                          _vm._v("cci-reaction")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "heading h3" }, [
                          _vm._v(_vm._s(r))
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.rest ? _c("div", { staticStyle: { height: "30px" } }) : _vm._e(),
      _vm._v(" "),
      _c("cc-stress-table", { ref: "stressTable", attrs: { mech: _vm.mech } }),
      _vm._v(" "),
      _c("cc-structure-table", {
        ref: "structureTable",
        attrs: { mech: _vm.mech }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }