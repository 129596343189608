var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    {
      style: _vm.$vuetify.breakpoint.lgAndUp ? "min-width: 40vw" : "",
      attrs: { cols: _vm.$vuetify.breakpoint.mdAndDown ? "12" : "" }
    },
    [
      _c(
        "fieldset",
        { staticClass: "ma-0 py-0", staticStyle: { height: "100%" } },
        [
          _c(
            "legend",
            { staticClass: "heading h3", style: "color: " + _vm.color },
            [
              _vm._v("\n      " + _vm._s(_vm.mount.Name) + "\n      "),
              _vm.impArm
                ? _c("span", [_vm._v("(IMPROVED ARMAMENT)")])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          !_vm.intWeapon && !_vm.integrated && !_vm.readonly
            ? _c("cb-mount-menu", {
                key: _vm.mech.AvailableBonuses.length,
                attrs: { mech: _vm.mech, mount: _vm.mount }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.mount.Bonuses, function(b) {
            return _c("cb-card", {
              key: _vm.mount.ID + "_bonus-" + b.ID,
              attrs: { bonus: b }
            })
          }),
          _vm._v(" "),
          _vm.mount.IsLocked
            ? _c("sh-lock-card")
            : _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    _vm._l(_vm.mount.Slots, function(s, i) {
                      return _c("weapon-slot-card", {
                        key: "slot_" + _vm.mount.ID + "-" + i,
                        attrs: {
                          "weapon-slot": s,
                          mech: _vm.mech,
                          mount: _vm.mount,
                          readonly: _vm.integrated || _vm.readonly,
                          "int-weapon": _vm.intWeapon
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }