// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/ui/scale_1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n#output-container[data-v-90806df2] {\n  position: absolute;\n  height: 90vh;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  width: calc(100vw - 665px);\n  top: 0;\n  right: 0;\n  margin-right: 8px;\n  z-index: 1;\n}\n.sidebar[data-v-90806df2] {\n  align-self: stretch;\n  display: inline-flex;\n  height: inherit;\n  min-height: 95%;\n  max-height: 95%;\n  max-width: 16px;\n  width: 16px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  vertical-align: text-bottom;\n}\n", ""]);
// Exports
module.exports = exports;
