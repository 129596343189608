var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.skill.err
        ? _c(
            "v-card",
            { attrs: { flat: "", outlined: "" } },
            [
              _c("v-card-text", { staticClass: "text-center pa-1" }, [
                _c("span", { staticClass: "flavor-text subtle--text" }, [
                  _vm._v("// MISSING DATA //")
                ])
              ])
            ],
            1
          )
        : _c(
            "v-expansion-panels",
            {
              staticStyle: { "border-radius": "0px" },
              attrs: { focusable: "", accordion: "" }
            },
            [
              _c(
                "v-expansion-panel",
                {
                  class:
                    "text-center no-shadow " +
                    (_vm.noBorder ? "" : "border-thin-primary "),
                  style: _vm.pilot ? "border-color: var(--v-pilot-base)" : ""
                },
                [
                  _c(
                    "v-expansion-panel-header",
                    {
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "px-2"
                        : "py-1 px-2"
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-col", { attrs: { cols: "12", md: "3" } }, [
                            _c(
                              "div",
                              { staticClass: "centered text-left pl-2" },
                              [
                                _c("span", { staticClass: "stat-text" }, [
                                  _vm._v(_vm._s(_vm.skill.Trigger))
                                ]),
                                _vm._v(" "),
                                _vm.bonus && _vm.$vuetify.breakpoint.mdAndUp
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(_vm.bonus, function(n) {
                                          return _c(
                                            "v-icon",
                                            {
                                              key: _vm.skill.ID + n,
                                              attrs: {
                                                color: "secondary",
                                                small: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  mdi-hexagon\n                "
                                              )
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "flavor-text subtle--text"
                                          },
                                          [
                                            _vm._v(
                                              "(+" + _vm._s(_vm.bonus) + ")"
                                            )
                                          ]
                                        )
                                      ],
                                      2
                                    )
                                  : _vm.bonus
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-weight-bold accent--text pl-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n                +" +
                                            _vm._s(_vm.bonus) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                md: "9",
                                "align-self": "center"
                              }
                            },
                            [
                              _c("span", { staticClass: "body-text pl-2" }, [
                                _vm._v(_vm._s(_vm.skill.Description))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.skill.Detail
                    ? _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", [
                                _c(
                                  "p",
                                  { staticClass: "text-left flavor-text mb-0" },
                                  [_vm._v(_vm._s(_vm.skill.Detail))]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }