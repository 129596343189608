









import Vue from 'vue'
import SidebarArrayView from '../components/SidebarArrayView.vue'
import { glossary } from 'lancer-data'

export default Vue.extend({
  name: 'glossary',
  components: { SidebarArrayView },
  data: () => ({
    glossary: [],
  }),
  created() {
    this.glossary = this.$_.sortBy(glossary, [x => x.name])
  },
})
