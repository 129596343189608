var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pa-2" },
    [
      _vm.item
        ? _c(
            "div",
            { staticStyle: { height: "100%" } },
            [
              _c(
                "v-card",
                {
                  class: _vm.hide ? "panel" : "clipped-large panel",
                  style: !_vm.hide ? "height: 100%" : "",
                  attrs: { flat: "", tile: "" }
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "white--text py-0 heading h3 hover-item",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.detailDialog.show()
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { display: "flex", width: "100%" } },
                        [
                          _vm.item
                            ? _c(
                                "span",
                                [
                                  _c("equipment-options", {
                                    attrs: {
                                      item: _vm.item,
                                      readonly: "",
                                      active: ""
                                    }
                                  }),
                                  _vm._v(" "),
                                  !_vm.item.Destroyed
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "ml-n2",
                                          style: _vm.item.Used
                                            ? "opacity: 0.6"
                                            : ""
                                        },
                                        [
                                          _vm.item.Used
                                            ? _c(
                                                "cc-tooltip",
                                                {
                                                  attrs: {
                                                    inline: "",
                                                    content:
                                                      "Equipment has been marked as 'Used'"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "success"
                                                      }
                                                    },
                                                    [_vm._v("mdi-check")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.item.Name) +
                                              "\n              "
                                          ),
                                          _vm.item.FlavorName
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "caption ml-2 my-n1"
                                                },
                                                [
                                                  _vm._v(
                                                    "//" +
                                                      _vm._s(_vm.item.TrueName)
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "caption subtle--text ml-1"
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(_vm._s(_vm.item.Size))
                                              ]),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.item.WeaponType) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        {
                                          staticClass: "py-1 error",
                                          staticStyle: {
                                            "letter-spacing": "3px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n               //\n              "
                                          ),
                                          _c("strike", [
                                            _vm._v(_vm._s(_vm.item.Name))
                                          ]),
                                          _vm._v(
                                            "\n              // \n            "
                                          )
                                        ],
                                        1
                                      )
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(_vm.weaponSlot.Size) + " Weapon")
                              ]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _vm.item.Range
                                ? _c("cc-range-element", {
                                    staticClass: "d-inline",
                                    attrs: {
                                      small: "",
                                      range: _vm.getRange,
                                      dark: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.item.Range && _vm.item.Damage
                                ? _c("cc-slashes", { staticClass: "px-2" })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.item.Damage
                                ? _c("cc-damage-element", {
                                    staticClass: "d-inline",
                                    attrs: {
                                      small: "",
                                      damage: _vm.getDamage,
                                      "type-override":
                                        _vm.item.DamageTypeOverride
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.rest
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "fadeSelect",
                                  attrs: { right: "", icon: "", dark: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.hide = !_vm.hide
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { small: "" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.hide
                                          ? "mdi-eye-outline"
                                          : "mdi-eye-off-outline"
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            : _c("div", { staticClass: "mr-4" })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-slide-y-transition",
                    [
                      !_vm.rest && !_vm.hide
                        ? _c(
                            "v-card-text",
                            { staticClass: "underline-parent px-2 py-0 mt-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "underline-slide" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _vm.item.Profiles &&
                                      _vm.item.Profiles.length > 1
                                        ? _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "overline" },
                                                [_vm._v("WEAPON PROFILES")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tabs",
                                                {
                                                  attrs: {
                                                    grow: "",
                                                    height: "30px"
                                                  },
                                                  model: {
                                                    value: _vm.tab,
                                                    callback: function($$v) {
                                                      _vm.tab = $$v
                                                    },
                                                    expression: "tab"
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.item.Profiles,
                                                  function(p) {
                                                    return _c(
                                                      "v-tab",
                                                      { key: p.ID },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "accent--text font-weight-bold"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(p.Name)
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          !_vm.item.Destroyed
                                            ? _c("weapon-activators", {
                                                attrs: {
                                                  item: _vm.item,
                                                  mech: _vm.mech,
                                                  mount: _vm.mount
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("equipment-header", {
                                    attrs: {
                                      item: _vm.item,
                                      readonly: "",
                                      color: _vm.color,
                                      "use-bonus": _vm.mech.LimitedBonus
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.item.ProfileActions.length
                                    ? _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            dense: "",
                                            justify: "center"
                                          }
                                        },
                                        _vm._l(
                                          _vm.item.ProfileActions,
                                          function(a, i) {
                                            return _c(
                                              "v-col",
                                              {
                                                key:
                                                  _vm.item.Name +
                                                  "_action_" +
                                                  i,
                                                staticClass: "mb-n1",
                                                staticStyle: {
                                                  "min-width": "40%"
                                                }
                                              },
                                              [
                                                _c("cc-action", {
                                                  attrs: {
                                                    action: a,
                                                    active: "",
                                                    activations:
                                                      _vm.mech.Pilot.State
                                                        .Actions,
                                                    disabled:
                                                      _vm.item.Destroyed ||
                                                      _vm.mech.IsStunned,
                                                    unusable:
                                                      a.Activation ===
                                                        "Protocol" &&
                                                      !_vm.mech.Pilot.State
                                                        .IsProtocolAvailable
                                                  },
                                                  on: {
                                                    use: function($event) {
                                                      return _vm.item.Use(
                                                        a.Cost,
                                                        $event
                                                      )
                                                    },
                                                    undo: function($event) {
                                                      return _vm.item.Undo(
                                                        a.Cost
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _vm.item.ProfileEffect
                                        ? _c("div", { staticClass: "py-1" }, [
                                            _c(
                                              "div",
                                              { staticClass: "mb-n2" },
                                              [
                                                _c("p", {
                                                  directives: [
                                                    {
                                                      name: "html-safe",
                                                      rawName: "v-html-safe",
                                                      value:
                                                        _vm.item.ProfileEffect,
                                                      expression:
                                                        "item.ProfileEffect"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text--text body-text mb-1 mx-3"
                                                })
                                              ]
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.item.ProfileOnAttack
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "mb-n2 mt-1" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mt-n1" },
                                                  [_vm._v("cci-weapon")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "overline stark--text"
                                                  },
                                                  [_vm._v("ON ATTACK")]
                                                ),
                                                _vm._v(" "),
                                                _c("p", {
                                                  directives: [
                                                    {
                                                      name: "html-safe",
                                                      rawName: "v-html-safe",
                                                      value:
                                                        _vm.item
                                                          .ProfileOnAttack,
                                                      expression:
                                                        "item.ProfileOnAttack"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text--text body-text mb-1 mr-2 ml-6 mt-n2"
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.item.ProfileOnHit
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "mb-n2 mt-1" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mt-n1" },
                                                  [_vm._v("cci-weapon")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "overline stark--text"
                                                  },
                                                  [_vm._v("ON HIT")]
                                                ),
                                                _vm._v(" "),
                                                _c("p", {
                                                  directives: [
                                                    {
                                                      name: "html-safe",
                                                      rawName: "v-html-safe",
                                                      value:
                                                        _vm.item.ProfileOnHit,
                                                      expression:
                                                        "item.ProfileOnHit"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text--text body-text mb-1 mr-2 ml-6 mt-n2"
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.item.ProfileOnCrit
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "mb-n2 mt-1" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mt-n1" },
                                                  [_vm._v("cci-weapon")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "overline stark--text"
                                                  },
                                                  [_vm._v("ON CRITICAL HIT")]
                                                ),
                                                _vm._v(" "),
                                                _c("p", {
                                                  directives: [
                                                    {
                                                      name: "html-safe",
                                                      rawName: "v-html-safe",
                                                      value:
                                                        _vm.item.ProfileOnCrit,
                                                      expression:
                                                        "item.ProfileOnCrit"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text--text body-text mb-1 mr-2 ml-6 mt-n2"
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.item.Mod
                                        ? _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                dense: "",
                                                justify: "center"
                                              }
                                            },
                                            [
                                              _c("active-mod-inset", {
                                                attrs: {
                                                  mod: _vm.item.Mod,
                                                  mech: _vm.mech,
                                                  color: _vm.color
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.item
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "text-left",
                                          attrs: { dense: "", align: "end" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  attrs: {
                                                    justify: "space-around",
                                                    dense: ""
                                                  }
                                                },
                                                [
                                                  _vm.item.Deployables.length
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters":
                                                                  "",
                                                                justify:
                                                                  "center"
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.item
                                                                .Deployables,
                                                              function(d, i) {
                                                                return _c(
                                                                  "v-col",
                                                                  {
                                                                    key:
                                                                      _vm.item
                                                                        .Name +
                                                                      "_deployable_" +
                                                                      i,
                                                                    attrs: {
                                                                      cols:
                                                                        "auto"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "cc-deployable-info",
                                                                      {
                                                                        staticClass:
                                                                          "ma-2",
                                                                        attrs: {
                                                                          deployable: d,
                                                                          panel: false,
                                                                          "name-override":
                                                                            _vm
                                                                              .item
                                                                              .Name
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mr-3 mt-n2",
                                          attrs: {
                                            "no-gutters": "",
                                            align: "start"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c("cc-tags", {
                                                attrs: {
                                                  small: "",
                                                  tags: _vm.item.ProfileTags,
                                                  color: _vm.color,
                                                  bonus:
                                                    _vm.mech.Pilot.LimitedBonus
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.item.Tags &&
                                              !_vm.item.ProfileTags
                                                ? _c("cc-tags", {
                                                    attrs: {
                                                      small: "",
                                                      tags: _vm.item.Tags,
                                                      color: _vm.color,
                                                      bonus:
                                                        _vm.mech.Pilot
                                                          .LimitedBonus
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.item.Mod
                                                ? _c("cc-tags", {
                                                    attrs: {
                                                      small: "",
                                                      tags:
                                                        _vm.item.Mod.AddedTags,
                                                      color: "mod darken-2",
                                                      bonus:
                                                        _vm.mech.Pilot
                                                          .LimitedBonus
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c("cc-bonus-display", {
                                                attrs: { item: _vm.item }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c("cc-synergy-display", {
                                                attrs: {
                                                  item: _vm.item,
                                                  location: "weapon",
                                                  mech: _vm.mech,
                                                  large: ""
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "detailDialog",
          attrs: {
            "no-confirm": "",
            title: _vm.item ? _vm.item.Name : "",
            large: ""
          }
        },
        [
          _c("cc-item-card", { attrs: { item: _vm.item } }),
          _vm._v(" "),
          _vm._t("detail")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }