













































import Vue from 'vue'

export default Vue.extend({
  name: 'skills-page',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  computed: {
    canContinue(): boolean {
      return !this.pilot.IsMissingSkills
    },
  },
})
