var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "heading accent--text" }, [
        _vm._v("User Options")
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "mr-3", attrs: { md: "12", lg: "6" } },
            [
              _c("div", { staticClass: "flavor-text" }, [
                _c("b", [_vm._v("USER ID:")]),
                _vm._v(" "),
                _c("span", { staticClass: "accent--text" }, [
                  _vm._v("\n          " + _vm._s(_vm.userID) + "\n        ")
                ])
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-1",
                          attrs: { large: "", block: "", color: "info" },
                          on: { click: _vm.reload }
                        },
                        [
                          _vm._v(
                            "\n            Download Updates and Reload\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-1",
                          attrs: { block: "", color: "accent" },
                          on: {
                            click: function($event) {
                              return _vm.showMessage()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            Show Latest Update Message\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "my-2" })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center my-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { block: "", large: "", color: "primary" },
                          on: { click: _vm.bulkExport }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-database")
                          ]),
                          _vm._v(
                            "\n            Create Data Backup\n            "
                          ),
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                inline: "",
                                content:
                                  "COMP/CON relies on your browser to save and load its data. Settings, utilities, and other applications can erase your browser's localStorage cache, resulting in the loss of your COMP/CON data. IT is <b>strongly</b> recommended to back up your data often."
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "fadeSelect",
                                  attrs: { right: "" }
                                },
                                [_vm._v("mdi-help-circle-outline")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-center my-2" },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "50%" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          block: "",
                                          large: "",
                                          color: "primary"
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-database-refresh")
                                      ]),
                                      _vm._v(
                                        "\n                Load Data Backup\n                "
                                      ),
                                      _c(
                                        "cc-tooltip",
                                        {
                                          attrs: {
                                            inline: "",
                                            content:
                                              "COMP/CON relies on your browser to save and load its data. Settings, utilities, and other applications can erase your browser's localStorage cache, resulting in the loss of your COMP/CON data. IT is <b>strongly</b> recommended to back up your data often."
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "fadeSelect",
                                              attrs: { right: "" }
                                            },
                                            [_vm._v("mdi-help-circle-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.importDialog,
                            callback: function($$v) {
                              _vm.importDialog = $$v
                            },
                            expression: "importDialog"
                          }
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                { staticClass: "pa-6" },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-center heading h3 text--text"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  This will OVERWRITE\n                  "
                                      ),
                                      _c("b", { staticClass: "accent--text" }, [
                                        _vm._v("ALL")
                                      ]),
                                      _vm._v(
                                        "\n                  local COMP/CON data.\n                  "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                  This\n                  "
                                      ),
                                      _c("b", { staticClass: "accent--text" }, [
                                        _vm._v("cannot")
                                      ]),
                                      _vm._v(
                                        "\n                  be undone.\n                "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-file-input", {
                                    attrs: {
                                      accept: ".compcon",
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      autofocus: "",
                                      placeholder:
                                        "Select COMP/CON Bulk Export File",
                                      "prepend-icon": "mdi-paperclip"
                                    },
                                    on: { change: _vm.bulkImport },
                                    model: {
                                      value: _vm.fileValue,
                                      callback: function($$v) {
                                        _vm.fileValue = $$v
                                      },
                                      expression: "fileValue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "my-3" }),
                  _vm._v(" "),
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "80%" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "my-1",
                                    attrs: {
                                      small: "",
                                      block: "",
                                      color: "error"
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", {
                                    attrs: { left: "" },
                                    domProps: { innerHTML: _vm._s("mdi-alert") }
                                  }),
                                  _vm._v(
                                    "\n              Delete All User Data\n              "
                                  ),
                                  _c("v-icon", {
                                    attrs: { right: "" },
                                    domProps: { innerHTML: _vm._s("mdi-alert") }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.deleteDialog,
                        callback: function($$v) {
                          _vm.deleteDialog = $$v
                        },
                        expression: "deleteDialog"
                      }
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { attrs: { flat: "", tile: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "my-3",
                                  attrs: {
                                    prominent: "",
                                    dark: "",
                                    color: "error",
                                    icon: "mdi-alert-circle",
                                    border: "bottom"
                                  }
                                },
                                [
                                  _c("span", { staticClass: "heading h2" }, [
                                    _vm._v("WARNING // WARNING // WARNING")
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-center heading h2 text--text"
                                },
                                [
                                  _vm._v(
                                    "\n                This will delete\n                "
                                  ),
                                  _c("b", { staticClass: "accent--text" }, [
                                    _vm._v("ALL")
                                  ]),
                                  _vm._v(
                                    "\n                local COMP/CON data.\n                "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                This\n                "
                                  ),
                                  _c("b", { staticClass: "accent--text" }, [
                                    _vm._v("cannot")
                                  ]),
                                  _vm._v(
                                    "\n                be undone.\n                "
                                  ),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("b", { staticClass: "accent--text" }, [
                                    _vm._v("Are you sure you want to continue?")
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "secondary",
                                    text: "",
                                    large: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.deleteDialog = false
                                    }
                                  }
                                },
                                [_vm._v("Dismiss")]
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "error", text: "" },
                                  on: { click: _vm.deleteAll }
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { left: "" },
                                    domProps: { innerHTML: _vm._s("mdi-alert") }
                                  }),
                                  _vm._v(
                                    "\n                Delete All User Data\n                "
                                  ),
                                  _c("v-icon", {
                                    attrs: { right: "" },
                                    domProps: { innerHTML: _vm._s("mdi-alert") }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("h3", { staticClass: "heading accent--text mb-n2" }, [
                _vm._v("Compendium")
              ]),
              _vm._v(" "),
              _c(
                "v-switch",
                {
                  attrs: {
                    color: "exotic",
                    inset: "",
                    dense: "",
                    "hide-details": ""
                  },
                  model: {
                    value: _vm.userViewExotics,
                    callback: function($$v) {
                      _vm.userViewExotics = $$v
                    },
                    expression: "userViewExotics"
                  }
                },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          Show Exotic Items\n          "),
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            title: "SPOILER ALERT",
                            content:
                              "Enabling this option may reveal campaign spoilers and it is recommended to leave this setting DISABLED\n            if you are not the GM",
                            inline: ""
                          }
                        },
                        [
                          _c("v-icon", { attrs: { color: "warning" } }, [
                            _vm._v("mdi-alert")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "heading accent--text mt-2" }, [
                _vm._v("Theme")
              ]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  dense: "",
                  outlined: "",
                  items: _vm.themes,
                  "item-text": "name"
                },
                on: { change: _vm.setTheme },
                model: {
                  value: _vm.theme,
                  callback: function($$v) {
                    _vm.theme = $$v
                  },
                  expression: "theme"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-4" }),
      _vm._v(" "),
      _c("h3", { staticClass: "heading accent--text" }, [
        _vm._v("Achievements")
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "panel py-3 text-center subtle--text" },
        [
          _c("v-icon", { attrs: { color: "grey" } }, [_vm._v("mdi-lock")]),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n    // FEATURE IN DEVELOPMENT //\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c("v-btn", { attrs: { small: "", text: "", to: "./ui-test" } }, [
            _vm._v("UI Test")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }