var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "pc-wrapper" },
      on: {
        click: function($event) {
          return _vm.$emit("go", _vm.mech)
        }
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "overlay clipped-square-invert",
          staticStyle: { position: "absolute", "z-index": "5" },
          attrs: {
            tile: "",
            color: _vm.mech.IsActive
              ? "success"
              : _vm.mech.Frame.Manufacturer.GetColor(_vm.$vuetify.theme.dark),
            "min-width": "138px",
            "min-height": "100%"
          }
        },
        [
          _c(
            "div",
            { staticClass: "clipped-square-invert", attrs: { id: "interior" } },
            [
              _c("v-img", {
                attrs: {
                  src: _vm.mech.Portrait,
                  position: "top center",
                  height: "100%"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { width: "100%" }, attrs: { id: "banner" } }, [
        _c(
          "div",
          {
            staticClass: "overlay",
            staticStyle: { width: "100%" },
            style:
              "background-color: " +
              (_vm.mech.IsActive
                ? "var(--v-success-base)"
                : _vm.mech.Frame.Manufacturer.GetColor(_vm.$vuetify.theme.dark))
          },
          [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c(
                  "span",
                  {
                    staticClass: "heading h2 callsign",
                    staticStyle: { "margin-left": "138px" }
                  },
                  [_vm._v(_vm._s(_vm.mech.Name))]
                ),
                _vm._v(" "),
                _c("v-fade-transition", [
                  _vm.mech.IsActive
                    ? _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$vuetify.breakpoint.mdAndUp,
                              expression: "$vuetify.breakpoint.mdAndUp"
                            }
                          ],
                          staticClass: "heading h2 callsign ml-auto pr-10"
                        },
                        [_vm._v("\n            / / ACTIVE / / \n          ")]
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "light-panel clipped",
            staticStyle: { "border-top": "0!important" }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "138px",
                  "padding-left": "8px",
                  "min-height": "100px"
                }
              },
              [
                _c(
                  "p",
                  { staticClass: "flavor-text mb-0" },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "auto" } }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.mech.Frame.Source) +
                                " " +
                                _vm._s(_vm.mech.Frame.Name)
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "ml-auto mr-4",
                            attrs: { cols: "auto" }
                          },
                          [
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  simple: "",
                                  inline: "",
                                  content: "Delete Mech"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "fadeSelect",
                                    attrs: {
                                      small: "",
                                      icon: "",
                                      color: "error"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.$refs.delete.show()
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("delete")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  simple: "",
                                  inline: "",
                                  content: "Duplicate Mech"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "fadeSelect",
                                    attrs: { small: "", icon: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.$refs.copy.show()
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-content-copy")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  simple: "",
                                  inline: "",
                                  content: "Print Mech Sheet"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "fadeSelect",
                                    attrs: { small: "", icon: "" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.$refs.print.show()
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("mdi-printer")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  simple: "",
                                  inline: "",
                                  content: "Set As Active Mech"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "fadeSelect",
                                    attrs: {
                                      small: "",
                                      icon: "",
                                      disabled:
                                        _vm.mech.Pilot.ActiveMech === _vm.mech
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        _vm.mech.Pilot.ActiveMech = _vm.mech
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("cci-activate")])],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "mt-n2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("fieldset", { staticClass: "px-3" }, [
                              _c("legend", { staticClass: "px-2" }, [
                                _vm._v(
                                  "\n                  Loadout//" +
                                    _vm._s(
                                      _vm.mech.ActiveLoadout
                                        ? _vm.mech.ActiveLoadout.Name
                                        : "ERR"
                                    ) +
                                    "\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.mech.ActiveLoadout
                                ? _c(
                                    "div",
                                    [
                                      _vm._l(_vm.loadoutWeapons, function(
                                        item,
                                        i
                                      ) {
                                        return _c(
                                          "span",
                                          { key: _vm.mech.ID + "_lw_" + i },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(item) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _vm._l(_vm.loadoutSystems, function(
                                        item,
                                        i
                                      ) {
                                        return _c(
                                          "span",
                                          { key: _vm.mech.ID + "_ls_" + i },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(i > 0 ? " - " : "") +
                                                _vm._s(item) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.$vuetify.breakpoint.mdAndUp,
                                    expression: "$vuetify.breakpoint.mdAndUp"
                                  }
                                ],
                                attrs: {
                                  "no-gutters": "",
                                  justify: "space-around"
                                }
                              },
                              [
                                _c("v-col", { attrs: { cols: "auto" } }, [
                                  _c("span", { staticClass: "overline" }, [
                                    _vm._v(
                                      "\n                    STR\n                    "
                                    ),
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.mech.MaxStructure))
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "auto" } }, [
                                  _c("span", { staticClass: "overline" }, [
                                    _vm._v(
                                      "\n                    HP\n                    "
                                    ),
                                    _c("b", [_vm._v(_vm._s(_vm.mech.MaxHP))])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "auto" } }, [
                                  _c("span", { staticClass: "overline" }, [
                                    _vm._v(
                                      "\n                    Stress\n                    "
                                    ),
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.mech.MaxStress))
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "auto" } }, [
                                  _c("span", { staticClass: "overline" }, [
                                    _vm._v(
                                      "\n                    Heat\n                    "
                                    ),
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.mech.HeatCapacity))
                                    ])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "auto" } }, [
                                  _c("span", { staticClass: "overline" }, [
                                    _vm._v(
                                      "\n                    RepCap\n                    "
                                    ),
                                    _c("b", [
                                      _vm._v(_vm._s(_vm.mech.RepairCapacity))
                                    ])
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "white--text flavor-text",
                            attrs: { cols: "auto" }
                          },
                          [
                            _vm.mech.Destroyed
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "text-center",
                                    attrs: {
                                      color: "error",
                                      dense: "",
                                      tile: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "letter-spacing": "5px" }
                                      },
                                      [_vm._v("// DESTROYED //")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.mech.MeltdownImminent
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "text-center",
                                    attrs: {
                                      color: "orange",
                                      dense: "",
                                      tile: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "letter-spacing": "5px" }
                                      },
                                      [_vm._v("// REACTOR CRITICAL //")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.mech.ReactorDestroyed
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass: "text-center",
                                    attrs: {
                                      color: "accent",
                                      dense: "",
                                      tile: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "letter-spacing": "5px" }
                                      },
                                      [_vm._v("// REACTOR DESTROYED //")]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("copy-mech-dialog", {
        ref: "copy",
        attrs: { mech: _vm.mech },
        on: {
          copy: function($event) {
            return _vm.$emit("copy", _vm.mech)
          }
        }
      }),
      _vm._v(" "),
      _c("delete-mech-dialog", {
        ref: "delete",
        attrs: { mech: _vm.mech },
        on: {
          delete: function($event) {
            return _vm.$emit("delete", _vm.mech)
          }
        }
      }),
      _vm._v(" "),
      _c("print-dialog", { ref: "print", attrs: { pilot: _vm.mech.Pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }