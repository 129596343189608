

























































import Vue from 'vue'
import ItemFilter from '@/classes/utility/ItemFilter'
import { accentInclude } from '@/classes/utility/accent_fold'
import CompendiumMobileView from './views/CompendiumMobileView.vue'
import CompendiumSplitView from './views/CompendiumSplitView.vue'
import CompendiumCardsView from './views/CompendiumCardsView.vue'
import CompendiumTableView from './views/CompendiumTableView.vue'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'
import { UserProfile } from '@/user'

export default Vue.extend({
  name: 'compendium-browser',
  components: {
    CompendiumMobileView,
    CompendiumTableView,
    CompendiumSplitView,
    CompendiumCardsView,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    noFilter: {
      type: Boolean,
      required: false,
    },
    lockView: {
      type: Boolean,
      required: false,
    },
  },
  data: () => ({
    search: '',
    filters: {},
    itemType: '',
  }),
  computed: {
    profile(): UserProfile {
      const store = getModule(UserStore, this.$store)
      return store.UserProfile
    },
    fItems() {
      const vm = this as any
      let i = vm.items

      if (vm.search) i = i.filter(x => accentInclude(x.Name, vm.search))

      if (Object.keys(vm.filters).length) {
        i = ItemFilter.Filter(i, vm.filters)
      }

      return i
    },
  },
  created() {
    this.itemType = this.items && this.items.length ? this.items[0].ItemType : ''
  },
  methods: {
    setFilters(newFilter) {
      this.filters = newFilter
    },
  },
})
