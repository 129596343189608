var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.range, function(r, i) {
      return _c(
        "div",
        {
          key: "rng_" + i,
          staticClass: "text-center px-1",
          staticStyle: { display: "inline-block" }
        },
        [
          _c(
            "cc-tooltip",
            { attrs: { title: r.Text, content: _vm.Help(r.Type) } },
            [
              _vm.small
                ? _c(
                    "span",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-n1", attrs: { dark: _vm.dark } },
                        [_vm._v(_vm._s(r.Icon))]
                      ),
                      _vm._v(" "),
                      r.Override
                        ? _c("v-icon", [_vm._v("mdi-information-outline")])
                        : _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s("" + (_vm.added ? "+" : "") + r.Value) +
                                "\n        "
                            )
                          ])
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mt-n4 mr-n1",
                          attrs: { "x-large": "", dark: _vm.dark }
                        },
                        [_vm._v("\n          " + _vm._s(r.Icon) + "\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "heading text--text",
                          staticStyle: { "font-size": "24pt" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s("" + (_vm.added ? "+" : "") + r.Value) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
            ]
          ),
          _vm._v(" "),
          !_vm.small
            ? _c("span", [
                _c("div", { staticClass: "overline mt-n1" }, [
                  _c("b", [_vm._v(_vm._s(r.Type))])
                ])
              ])
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }