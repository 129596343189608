import { render, staticRenderFns } from "./_WeaponSlotCard.vue?vue&type=template&id=2b71b09c&"
import script from "./_WeaponSlotCard.vue?vue&type=script&lang=ts&"
export * from "./_WeaponSlotCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2b71b09c')) {
      api.createRecord('2b71b09c', component.options)
    } else {
      api.reload('2b71b09c', component.options)
    }
    module.hot.accept("./_WeaponSlotCard.vue?vue&type=template&id=2b71b09c&", function () {
      api.rerender('2b71b09c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/mech_loadout/components/mount/weapon/_WeaponSlotCard.vue"
export default component.exports