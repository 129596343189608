var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-list-item",
        {
          staticClass: "primary",
          staticStyle: { "flex-grow": "0", "flex-basis": "auto" }
        },
        [
          _c(
            "v-list-item-icon",
            [
              _c("v-icon", { attrs: { dark: "", "x-large": "" } }, [
                _vm._v("mdi-package")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                { staticClass: "headline white--text" },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.pack.manifest.name) + "\n        "
                  ),
                  _c(
                    "v-chip",
                    { attrs: { outlined: "", dark: "", label: "" } },
                    [_vm._v(_vm._s(_vm.pack.manifest.version))]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item-subtitle",
                { staticClass: "white--text text--darken-1" },
                [
                  _vm._v(
                    "\n        by " +
                      _vm._s(_vm.pack.manifest.author) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "p",
                  { staticClass: "body-text text--text light-panel pa-2 mb-1" },
                  [
                    _vm.pack.manifest.description
                      ? _c("span", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: _vm.pack.manifest.description,
                              expression: "pack.manifest.description"
                            }
                          ]
                        })
                      : _c("span", [
                          _vm._v(
                            "\n            No description given.\n          "
                          )
                        ])
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c("h4", [_vm._v("Content")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    _vm._l(_vm.packContents, function(item) {
                      return _c(
                        "v-chip",
                        {
                          key: item.name,
                          staticClass: "mr-2 mb-1 caption",
                          attrs: { outlined: "", color: "secondary" }
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "secondary white--text",
                              attrs: { left: "" }
                            },
                            [_c("b", [_vm._v(_vm._s(item.count))])]
                          ),
                          _vm._v(
                            "\n              " +
                              _vm._s(item.name) +
                              "\n            "
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.pack.manifest.website
                  ? _c(
                      "div",
                      { staticClass: "mt-2" },
                      [
                        _c("v-divider", { staticClass: "ma-1" }),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              target: "_blank",
                              href: _vm.pack.manifest.website,
                              text: "",
                              color: "secondary"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { prepend: "" } },
                              [_vm._v("open_in_new")]
                            ),
                            _vm._v("\n             Website\n          ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4" } },
                [
                  _c("v-img", {
                    attrs: {
                      src: _vm.pack.manifest.image_url,
                      alt: "Pack image",
                      "max-width": "200px",
                      "max-height": "300px"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }