var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.small ? "mt-n3" : "" },
    [
      _c("mech-nav", {
        attrs: { selected: 0, pilot: _vm.pilot, mech: _vm.mech },
        on: {
          delete: function($event) {
            return _vm.$refs.deleteDialog.show()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "cc-short-string-editor",
                {
                  attrs: { large: "", before: "" },
                  on: {
                    set: function($event) {
                      _vm.mech.Name = $event
                    }
                  }
                },
                [
                  _c(
                    "cc-title",
                    {
                      staticClass: "px-3 ml-n6",
                      attrs: {
                        small: _vm.small,
                        large: !_vm.small && _vm.mech.Name.length < 31,
                        color: _vm.color
                      }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.mech.Name) + " \n        "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: "mt-n" + (_vm.small ? "3" : "6") },
                [
                  _c("cc-logo", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.small,
                        expression: "!small"
                      }
                    ],
                    attrs: {
                      size: "large",
                      source: _vm.mech.Frame.Manufacturer
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      class: _vm.small ? "heading h3" : "heading h2",
                      staticStyle: { position: "relative", top: "-11px" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "pt-n3", style: "color: " + _vm.color },
                        [_vm._v(_vm._s(_vm.mech.Frame.Manufacturer.Name))]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "text--text" }, [
                        _vm._v(_vm._s(_vm.mech.Frame.Name))
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "fadeSelect mt-n1",
                          attrs: { right: "" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.frameInfoDialog.show()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            mdi-information-outline\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "cc-solo-dialog",
                        {
                          ref: "frameInfoDialog",
                          attrs: {
                            icon: "cci-frame",
                            color: _vm.color,
                            "no-actions": "",
                            large: "",
                            title:
                              _vm.mech.Frame.Manufacturer.Name +
                              " " +
                              _vm.mech.Frame.Name
                          }
                        },
                        [
                          _c("p", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: _vm.mech.Frame.Description,
                                expression: "mech.Frame.Description"
                              }
                            ],
                            staticClass: "flavor-text mt-3 mb-1 px-1 text--text"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.mech.StatusString.length
        ? _c(
            "v-row",
            {
              staticClass: "mt-n3 mb-1",
              attrs: { dense: "", justify: "center" }
            },
            _vm._l(_vm.mech.StatusString, function(s) {
              return _c(
                "v-col",
                { key: "status-" + s, attrs: { cols: "auto" } },
                [
                  _c("cc-mech-status-alert", {
                    attrs: { type: s },
                    on: {
                      "clear-ejected": function($event) {
                        _vm.mech.Ejected = false
                      },
                      "clear-status": function($event) {
                        return _vm.mech.Repair()
                      },
                      reprint: function($event) {
                        return _vm.mech.FullRepair()
                      }
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.small
            ? _c(
                "div",
                { staticClass: "text-center mb-2" },
                [
                  _c("img", {
                    key: _vm.mech.Image,
                    style:
                      "object-fit: contain; max-height: 200px; width: 100%; image-rendering: " +
                      (_vm.isPixel ? "pixelated" : "crisp-edges") +
                      ";",
                    attrs: { src: _vm.mech.Portrait, position: "top center" }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "fadeSelect",
                      attrs: {
                        outlined: "",
                        color: "secondary",
                        small: "",
                        block: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.imageSelector.open()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-circle-edit-outline")
                      ]),
                      _vm._v("\n        Set Mech Image\n      ")
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("cc-image-selector-web", {
            ref: "imageSelector",
            attrs: { item: _vm.mech, type: "mech" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { align: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-row",
                { staticClass: "px-3 mt-n4" },
                [
                  _c(
                    "v-col",
                    [
                      _c("operator-notes", {
                        attrs: { mech: _vm.mech, color: _vm.color }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "px-3 mt-n6" },
                [
                  _c("license-requirement-block", {
                    attrs: { mech: _vm.mech, color: _vm.color }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "px-3 mt-n6" },
                [
                  _c("trait-block", {
                    attrs: { mech: _vm.mech, color: _vm.color }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.small
            ? _c(
                "v-col",
                {
                  staticStyle: { "max-height": "inherit" },
                  attrs: { cols: "4" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-center mt-n10",
                      staticStyle: { "max-height": "inherit" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "relative",
                            "max-height": "inherit"
                          }
                        },
                        [
                          _c("img", {
                            key: _vm.mech.Image,
                            style:
                              "object-fit: contain; max-width: 20vw; width: 100%; image-rendering: " +
                              (_vm.isPixel ? "pixelated" : "crisp-edges") +
                              ";",
                            attrs: {
                              src: _vm.mech.Portrait,
                              position: "top center"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              staticStyle: {
                                position: "absolute",
                                bottom: "0",
                                right: "0"
                              },
                              attrs: {
                                outlined: "",
                                color: "secondary",
                                small: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.imageSelector.open()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-circle-edit-outline")
                              ]),
                              _vm._v("\n            Set Mech Image\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("cc-image-selector-web", {
                        ref: "imageSelector",
                        attrs: { item: _vm.mech, type: "mech" }
                      })
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { class: "mt-n6 mb-2 " + (_vm.small ? "" : "px-3") },
        [
          _c("attributes-block", {
            attrs: { color: _vm.color, mech: _vm.mech, pilot: _vm.pilot }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-title",
        { staticClass: "ml-n6", attrs: { small: "", color: _vm.color } },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.mech.Frame.Source) +
              " " +
              _vm._s(_vm.mech.Frame.Name) +
              " CORE System\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c("core-item", {
        attrs: { "core-system": _vm.mech.Frame.CoreSystem, color: _vm.color }
      }),
      _vm._v(" "),
      _c(
        "cc-title",
        { staticClass: "mb-2 ml-n6", attrs: { small: "", color: _vm.color } },
        [_vm._v("Equipment Loadout")]
      ),
      _vm._v(" "),
      _c("cc-mech-loadout", { staticClass: "px-3", attrs: { mech: _vm.mech } }),
      _vm._v(" "),
      _c("delete-mech-dialog", {
        ref: "deleteDialog",
        attrs: { mech: _vm.mech },
        on: {
          delete: function($event) {
            return _vm.deleteMech()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }