var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-solo-dialog",
    {
      ref: "dialog",
      attrs: {
        icon: "mdi-dna",
        large: "",
        "no-confirm": "",
        title: "Clone Pilot"
      }
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "", justify: "space-around" } },
            [
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "5" } },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        title: "Duplication",
                        content:
                          "This will create a copy of this pilot data, registered to your pilot roster."
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { large: "", block: "", color: "primary" },
                          on: { click: _vm.copyPilot }
                        },
                        [_vm._v("Duplicate Pilot")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "5" } },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        title: "Clone",
                        content:
                          "This will clone the selected pilot. Cloned characters can’t join a mission in progress, and cloned characters receive a random quirk. Additional cloning and subjectivity imprinting adds further quirks."
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            block: "",
                            color: "primary",
                            disabled: !!_vm.quirk
                          },
                          on: { click: _vm.rollQuirk }
                        },
                        [_vm._v("\n            Flash Clone Pilot\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-scale-transition", [
            _vm.quirk
              ? _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      { attrs: { align: "center" } },
                      [
                        _c(
                          "v-col",
                          [
                            _c("div", { staticClass: "overline" }, [
                              _vm._v("CLONE QUIRK")
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  dense: "",
                                  color: "primary",
                                  outlined: "",
                                  left: ""
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "body-text stark--text font-weight-bold"
                                  },
                                  [_vm._v(_vm._s(_vm.quirk))]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "cc-tooltip",
                              {
                                attrs: { simple: "", content: "Reroll quirk" }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "", large: "" },
                                    on: { click: _vm.rollQuirk }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "secondary", large: "" }
                                      },
                                      [_vm._v("mdi-dice-6")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.quirk
                      ? _c(
                          "v-row",
                          { attrs: { align: "center", justify: "center" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      large: "",
                                      block: "",
                                      tile: "",
                                      color: "secondary darken-2"
                                    },
                                    on: { click: _vm.clonePilot }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Decant Flash Clone\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }