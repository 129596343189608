var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-titled-panel",
    { attrs: { title: _vm.reserve.Name, icon: _vm.icon, color: _vm.color } },
    [
      _c(
        "v-row",
        { staticStyle: { "min-height": "70px" }, attrs: { dense: "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pr-0",
              attrs: { cols: "12", md: "auto", dense: "" }
            },
            [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: _vm.color, dark: "", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("click")
                        }
                      }
                    },
                    [_vm._v("\n        Add Resource\n      ")]
                  )
                : _c(
                    "cc-tooltip",
                    {
                      attrs: { simple: "", inline: "", content: "Add Reserve" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 mr-6 fadeSelect",
                          attrs: {
                            block: "",
                            icon: "",
                            "min-height": "calc(100% - 8px)",
                            color: _vm.color
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("click")
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { size: "80" } }, [
                            _vm._v("cci-accuracy")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { staticClass: "pl-0 ml-2" }, [
            _c("p", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.reserve.Description,
                  expression: "reserve.Description"
                }
              ],
              staticClass: "flavor-text text--text"
            })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }