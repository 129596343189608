






























































































import Vue from 'vue'
import _ from 'lodash'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { MechSystem } from '@/class'
import { flavorID } from '@/io/Generators'
import { Bonus } from '@/classes/Bonus'

export default Vue.extend({
  name: 'system-selector',
  props: {
    equipped: {
      type: Object,
      required: false,
      default: null,
    },
    mech: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    headers: [
      { text: 'Source', align: 'left', value: 'Source' },
      { text: 'System', align: 'left', value: 'Name' },
      { text: 'License', align: 'left', value: 'LicenseString' },
      { text: 'SP Cost', align: 'left', value: 'SP' },
      { text: '', align: 'center', value: 'Equip' },
    ],
    systems: [],
    showUnlicensed: false,
    showOverSP: false,
  }),
  computed: {
    freeSP(): number {
      return this.equipped ? this.mech.FreeSP + this.equipped.SP : this.mech.FreeSP
    },
    availableSystems(): MechSystem[] {
      // filter unique
      let i = this.systems.filter(x => !x.IsHidden && !x.IsExotic)

      // filter ai
      if (this.mech.ActiveLoadout.AICount >= 1 + Bonus.get('ai_cap', this.mech)) {
        i = i.filter(x => !x.IsAI)
      }

      if (!this.showUnlicensed) {
        i = i.filter(
          x => !x.LicenseLevel || this.mech.Pilot.has('License', x.License, x.LicenseLevel)
        )
      }

      // if (!this.showOverSP) {
      //   i = i.filter(x => x.SP <= this.freeSP)
      // }

      i = i
        .concat(this.mech.Pilot.SpecialEquipment.filter(x => x.ItemType === 'MechSystem'))
        .filter(x => !this.mech.ActiveLoadout.UniqueSystems.map(y => y.ID).includes(x.ID))

      return _.sortBy(i, ['Source', 'Name'])
    },
  },
  created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.systems = compendium.MechSystems.filter(x => x.Source)
  },
  methods: {
    fID(template: string): string {
      return flavorID(template)
    },
  },
})
