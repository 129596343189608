





















































import Vue from 'vue'
import gistApi from '@/io/apis/gist'
import { Pilot } from '@/class'
import { getModule } from 'vuex-module-decorators'
import { PilotManagementStore, CompendiumStore } from '@/store'

import ImportDialog from './ImportDialog.vue'

export default Vue.extend({
  name: 'cloud-import',
  components: { ImportDialog },
  data: () => ({
    dialog: false,
    importID: '',
    importPilot: null,
    cloudLoading: false,
    error: null,
    missingContentWarning: false,
    missingContent: '',
  }),
  watch: {
    dialog(open) {
      if (!open) this.reset()
    },
  },
  methods: {
    reset() {
      this.importPilot = null
      this.error = null
      this.cloudLoading = false
      this.missingContentWarning = false
    },
    async cloudImport() {
      this.reset()
      this.cloudLoading = true
      try {
        const pilotData = await gistApi.loadPilot(this.importID)
        if (!pilotData.brews) pilotData.brews = []
        const installedPacks = getModule(CompendiumStore, this.$store).ContentPacks.map(
          x => `${x.Name} @ ${x.Version}`
        )
        const missingPacks = this.$_.pullAll(pilotData.brews, installedPacks)
        if (missingPacks.length) {
          this.missingContent = missingPacks.join('<br />')
          this.missingContentWarning = true
        }
        this.importPilot = Pilot.Deserialize(pilotData)
        this.importPilot.brews = pilotData.brews
        this.importPilot.RenewID()
      } catch (e) {
        this.error = e.message
      }
      this.cloudLoading = false
    },
    confirmImport() {
      const importPilot = this.importPilot as Pilot
      if (!importPilot.GistCode) {
        importPilot.GistCode = this.importID
      }
      getModule(PilotManagementStore, this.$store).addPilot({ pilot: importPilot, update: true })
      this.reset()
      this.dialog = false
      this.importID = ''
      this.$emit('done')
    },
    cancelImport() {
      this.reset()
      this.importID = ''
      this.dialog = false
    },
  },
})
