import { render, staticRenderFns } from "./MechSkillsPage.vue?vue&type=template&id=be270c58&"
import script from "./MechSkillsPage.vue?vue&type=script&lang=ts&"
export * from "./MechSkillsPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('be270c58')) {
      api.createRecord('be270c58', component.options)
    } else {
      api.reload('be270c58', component.options)
    }
    module.hot.accept("./MechSkillsPage.vue?vue&type=template&id=be270c58&", function () {
      api.rerender('be270c58', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/pilot_management/New/pages/MechSkillsPage.vue"
export default component.exports