var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "", justify: "center" } },
    _vm._l(_vm.synergies, function(s, i) {
      return _c(
        "v-col",
        { key: "syn_" + i, staticStyle: { "min-width": "33%" } },
        [
          _c(
            "v-alert",
            {
              staticClass: "py-1 ma-1",
              attrs: { dense: "", outlined: "", color: "primary" }
            },
            [
              _c(
                "div",
                { staticClass: "overline mt-n2 subtle--text" },
                [
                  _vm._v("\n        ACTIVE SYNERGY\n        "),
                  _c("cc-slashes"),
                  _vm._v(" "),
                  _c("span", { staticClass: "text--text" }, [
                    _vm._v(_vm._s(s.Origin))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: s.Detail,
                    expression: "s.Detail"
                  }
                ],
                staticClass: "body-text text--text"
              })
            ]
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }