import { render, staticRenderFns } from "./_ActionActivationButtons.vue?vue&type=template&id=f029b462&"
import script from "./_ActionActivationButtons.vue?vue&type=script&lang=ts&"
export * from "./_ActionActivationButtons.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCol,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f029b462')) {
      api.createRecord('f029b462', component.options)
    } else {
      api.reload('f029b462', component.options)
    }
    module.hot.accept("./_ActionActivationButtons.vue?vue&type=template&id=f029b462&", function () {
      api.rerender('f029b462', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/active_loadout/components/_ActionActivationButtons.vue"
export default component.exports