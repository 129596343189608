import { render, staticRenderFns } from "./_PilotWeaponCard.vue?vue&type=template&id=0aa729b0&"
import script from "./_PilotWeaponCard.vue?vue&type=script&lang=ts&"
export * from "./_PilotWeaponCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VCol,VDivider})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0aa729b0')) {
      api.createRecord('0aa729b0', component.options)
    } else {
      api.reload('0aa729b0', component.options)
    }
    module.hot.accept("./_PilotWeaponCard.vue?vue&type=template&id=0aa729b0&", function () {
      api.rerender('0aa729b0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/cards/_PilotWeaponCard.vue"
export default component.exports