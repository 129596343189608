var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pa-2", attrs: { cols: "12", lg: "6" } },
    [
      _c(
        "div",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "v-card",
            {
              class: _vm.hide ? "panel" : "clipped-large panel",
              style:
                !_vm.hide && !_vm.rest ? "height: 100%; min-height: 100px" : "",
              attrs: { flat: "", tile: "" }
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "white--text py-0 heading h3 hover-item",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.detailDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticStyle: { display: "flex", width: "100%" } },
                    [
                      _vm.item
                        ? _c(
                            "span",
                            [
                              _c("equipment-options", {
                                attrs: {
                                  item: _vm.item,
                                  readonly: "",
                                  active: ""
                                }
                              }),
                              _vm._v(" "),
                              !_vm.item.Destroyed
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "ml-n2",
                                      style: _vm.item.Used ? "opacity: 0.6" : ""
                                    },
                                    [
                                      _vm.item.Used
                                        ? _c(
                                            "cc-tooltip",
                                            {
                                              attrs: {
                                                inline: "",
                                                content:
                                                  "Equipment has been marked as 'Used'"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "success" } },
                                                [_vm._v("mdi-check")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.item.Name) +
                                          "\n              "
                                      ),
                                      _vm.item.FlavorName
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "caption ml-2 my-n1"
                                            },
                                            [
                                              _vm._v(
                                                "//" + _vm._s(_vm.item.TrueName)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption subtle--text ml-1"
                                        },
                                        [
                                          _c("b", [
                                            _vm._v(_vm._s(_vm.item.Size))
                                          ]),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.item.Type) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass: "py-1 error",
                                      staticStyle: { "letter-spacing": "3px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n               //\n              "
                                      ),
                                      _c("strike", [
                                        _vm._v(_vm._s(_vm.item.Name))
                                      ]),
                                      _vm._v(
                                        "\n              // \n            "
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("div", { staticStyle: { display: "inline-block" } }, [
                        _c("span", { staticClass: "heading h2" }, [
                          _vm._v(_vm._s(_vm.item.SP))
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "heading h3" }, [
                          _vm._v("SP")
                        ])
                      ]),
                      _vm._v(" "),
                      !_vm.rest
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              attrs: { right: "", dark: "", icon: "" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.hide = !_vm.hide
                                }
                              }
                            },
                            [
                              _c("v-icon", {
                                attrs: { small: "" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.hide
                                      ? "mdi-eye-outline"
                                      : "mdi-eye-off-outline"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        : _c("div", { staticClass: "mr-4" })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-slide-y-transition",
                [
                  !_vm.rest && !_vm.hide
                    ? _c(
                        "v-card-text",
                        { staticClass: "underline-parent px-2 py-0 mt-0" },
                        [
                          _c(
                            "div",
                            { staticClass: "underline-slide" },
                            [
                              _vm.item.Actions.length
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "my-1",
                                      attrs: { dense: "", justify: "center" }
                                    },
                                    _vm._l(_vm.item.Actions, function(a, i) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: _vm.item.Name + "_action_" + i,
                                          staticClass: "mb-n1",
                                          staticStyle: { "min-width": "40%" },
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("cc-action", {
                                            attrs: {
                                              action: a,
                                              active: "",
                                              activations:
                                                _vm.mech.Pilot.State.Actions,
                                              disabled: _vm.actionDisabled(a),
                                              unusable:
                                                a.Activation === "Protocol" &&
                                                !_vm.mech.Pilot.State
                                                  .IsProtocolAvailable
                                            },
                                            on: {
                                              use: function($event) {
                                                return _vm.item.Use(
                                                  a.Cost,
                                                  $event
                                                )
                                              },
                                              undo: function($event) {
                                                return _vm.item.Undo(a.Cost)
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("equipment-header", {
                                attrs: {
                                  item: _vm.item,
                                  readonly: "",
                                  color: _vm.color,
                                  "use-bonus": _vm.mech.LimitedBonus
                                }
                              }),
                              _vm._v(" "),
                              _vm.item.IsCascading
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass:
                                        "text-center white--text stat-text",
                                      staticStyle: { "letter-spacing": "3px" },
                                      attrs: {
                                        dense: "",
                                        tile: "",
                                        color: "error"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              / / AI IN CASCADE / /\n            "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.item && _vm.item.Effect
                                ? _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "overline mt-2" },
                                      [
                                        _c("v-icon", [_vm._v("cci-system")]),
                                        _vm._v(
                                          "\n                EQUIPMENT EFFECT\n              "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("p", {
                                      directives: [
                                        {
                                          name: "html-safe",
                                          rawName: "v-html-safe",
                                          value: _vm.item.Effect,
                                          expression: "item.Effect"
                                        }
                                      ],
                                      staticClass:
                                        "text--text body-text mb-1 mr-3 ml-7"
                                    })
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.item
                            ? _c(
                                "div",
                                [
                                  _vm.item &&
                                  _vm.item.Ammo &&
                                  _vm.item.Ammo.length
                                    ? _c(
                                        "div",
                                        _vm._l(_vm.item.Ammo, function(a, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: _vm.item.Name + "_ammo_" + i,
                                              staticClass: "body-text"
                                            },
                                            [
                                              _c("b", [_vm._v(_vm._s(a.name))]),
                                              _vm._v(
                                                "\n                :\n                "
                                              ),
                                              _c("span", {
                                                directives: [
                                                  {
                                                    name: "html-safe",
                                                    rawName: "v-html-safe",
                                                    value: a.detail,
                                                    expression: "a.detail"
                                                  }
                                                ]
                                              })
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mr-3 mt-n1",
                                      attrs: {
                                        "no-gutters": "",
                                        align: "start"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("cc-tags", {
                                            attrs: {
                                              small: "",
                                              tags: _vm.item.Tags,
                                              color: _vm.color,
                                              bonus: _vm.mech.Pilot.LimitedBonus
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.item.Mod
                                            ? _c("cc-tags", {
                                                attrs: {
                                                  small: "",
                                                  tags: _vm.item.Mod.AddedTags,
                                                  color: "mod darken-2",
                                                  bonus:
                                                    _vm.mech.Pilot.LimitedBonus
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("cc-bonus-display", {
                                            attrs: { item: _vm.item }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("cc-synergy-display", {
                                            attrs: {
                                              item: _vm.item,
                                              location: "system",
                                              mech: _vm.mech,
                                              large: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "detailDialog",
          attrs: {
            "no-confirm": "",
            title: _vm.item ? _vm.item.Name : "",
            large: ""
          }
        },
        [
          _c("cc-item-card", { attrs: { item: _vm.item } }),
          _vm._v(" "),
          _vm._t("detail")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }