































































import Vue from 'vue'
import Component from 'vue-class-component'
import _ from 'lodash'

@Component({
  props: {
    pack: { type: Object, required: true },
  },
})
export default class PackInfo extends Vue {
  private humanReadableMap = {
    manufacturers: ['manufacturer', 'manufacturers'],
    coreBonuses: ['core bonus', 'core bonuses'],
    frames: ['frame', 'frames'],
    weapons: ['weapon', 'weapons'],
    systems: ['system', 'systems'],
    mods: ['weapon mod', 'weapon mods'],
    pilotGear: ['pilot gear item', 'pilot gear items'],
    talents: ['pilot talent', 'pilot talents'],
    tags: ['equipment tag', 'equipment tags'],
    npcClasses: ['NPC class', 'NPC classes'],
    npcFeatures: ['NPC feature', 'NPC features'],
    npcTemplates: ['NPC template', 'NPC templates'],
    actions: ['Player action', 'Player actions'],
    statuses: ['Status/Condition', 'Statuses/Conditions'],
    environments: ['Combat Environment', 'Combat Environments'],
    sitreps: ['SITREP', 'SITREPs'],
    tables: ['Data Table', 'Data Tables'],
  }

  get packContents() {
    return _.toPairs(this.$props.pack.data)
      .map(([key, value]: [string, object[]]) => {
        const count = value.length
        return [key, count]
      })
      .filter(([, count]) => count > 0)
      .map(([key, count]) => {
        const pair = this.humanReadableMap[key]
        if (!pair) return { count, name: `${key}--NOT--HUMANIZED` }
        const [singular, plural]: [string, string] = pair
        return { count, name: count > 1 ? plural : singular }
      })
  }
}
