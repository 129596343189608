var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "7" } },
        [
          _c(
            "cc-titled-panel",
            {
              attrs: {
                title: "New Project",
                icon: "mdi-atom-variant",
                color: "reserve--project"
              }
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          color: "accent",
                          label: "Project Name",
                          outlined: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.projectName,
                          callback: function($$v) {
                            _vm.projectName = $$v
                          },
                          expression: "projectName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "auto" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", align: "start" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "v-switch",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    dense: "",
                                    inset: "",
                                    "hide-details": "",
                                    color: "secondary"
                                  },
                                  model: {
                                    value: _vm.complicated,
                                    callback: function($$v) {
                                      _vm.complicated = $$v
                                    },
                                    expression: "complicated"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "stat-text text--text",
                                      attrs: { slot: "label" },
                                      slot: "label"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Complicated\n                  "
                                      ),
                                      _c(
                                        "cc-tooltip",
                                        {
                                          attrs: {
                                            simple: "",
                                            inline: "",
                                            content:
                                              "This project is complex, resource-intensive, or generally difficult to complete"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-help-circle-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "v-switch",
                                {
                                  attrs: {
                                    dense: "",
                                    inset: "",
                                    "hide-details": "",
                                    color: "secondary"
                                  },
                                  model: {
                                    value: _vm.finished,
                                    callback: function($$v) {
                                      _vm.finished = $$v
                                    },
                                    expression: "finished"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "stat-text text--text",
                                      attrs: { slot: "label" },
                                      slot: "label"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Finished\n                  "
                                      ),
                                      _c(
                                        "cc-tooltip",
                                        {
                                          attrs: {
                                            simple: "",
                                            inline: "",
                                            content:
                                              "This project is complete and available to use as a <strong>reserve</strong>"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-help-circle-outline")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-textarea", {
                staticClass: "my-3",
                attrs: {
                  "auto-grow": "",
                  rows: "2",
                  label: "Details",
                  filled: "",
                  "hide-details": "",
                  color: "accent"
                },
                model: {
                  value: _vm.details,
                  callback: function($$v) {
                    _vm.details = $$v
                  },
                  expression: "details"
                }
              }),
              _vm._v(" "),
              _c("v-combobox", {
                staticClass: "mr-5 ml-5 mt-5",
                attrs: {
                  label: "Requirements",
                  items: _vm.projectCosts,
                  chips: "",
                  multiple: "",
                  outlined: "",
                  color: "accent",
                  dense: "",
                  disabled: _vm.finished
                },
                model: {
                  value: _vm.costs,
                  callback: function($$v) {
                    _vm.costs = $$v
                  },
                  expression: "costs"
                }
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2 mt-n2",
                  attrs: {
                    block: "",
                    tile: "",
                    large: "",
                    color: "primary",
                    disabled: !_vm.projectName
                  },
                  on: {
                    click: function($event) {
                      return _vm.add()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("cci-accuracy")
                  ]),
                  _vm._v("\n        Add Project\n      ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }