var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols, lg: _vm.lg, md: _vm.md, sm: _vm.sm } },
    [
      _c(
        "div",
        {
          staticClass: "mx-2",
          on: {
            click: function($event) {
              return _vm.$emit("clicked")
            }
          }
        },
        [
          _c(
            "v-alert",
            {
              attrs: {
                outlined: "",
                color: _vm.color,
                prominent: "",
                dense: "",
                border: "left"
              }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "icn",
                  attrs: {
                    slot: "prepend",
                    "x-large": "",
                    color: _vm.color + " lighten-1",
                    left: ""
                  },
                  slot: "prepend"
                },
                [_vm._v("\n        " + _vm._s(_vm.icon) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-inline mt-n1 heading h2 stark--text" },
                [_vm._v(_vm._s(_vm.name))]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }