import { render, staticRenderFns } from "./CompendiumTableView.vue?vue&type=template&id=50f6ad76&scoped=true&"
import script from "./CompendiumTableView.vue?vue&type=script&lang=ts&"
export * from "./CompendiumTableView.vue?vue&type=script&lang=ts&"
import style0 from "./CompendiumTableView.vue?vue&type=style&index=0&id=50f6ad76&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f6ad76",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VDataTable,VHover,VIcon})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50f6ad76')) {
      api.createRecord('50f6ad76', component.options)
    } else {
      api.reload('50f6ad76', component.options)
    }
    module.hot.accept("./CompendiumTableView.vue?vue&type=template&id=50f6ad76&scoped=true&", function () {
      api.rerender('50f6ad76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/compendium/components/views/CompendiumTableView.vue"
export default component.exports