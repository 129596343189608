var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c("global-confirm", { ref: "confirm" }),
      _vm._v(" "),
      _c("global-notifier", { ref: "notifier" }),
      _vm._v(" "),
      _c("navbar"),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }