









































import Vue from 'vue'
export default Vue.extend({
  name: 'action-detail-expander',
  props: {
    action: { type: Object, required: true },
  },
  data: () => ({
    expanded: false,
  }),
})
