








































































import Vue from 'vue'
import { Rules } from '@/class'

export default Vue.extend({
  name: 'cloud-dialog',
  props: {
    pilot: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    alert: true,
    newLevel: 0,
  }),
  methods: {
    show() {
      this.$refs.dialog.show()
      this.alert = true
      this.newLevel = this.pilot.Level
    },
    hide() {
      this.$refs.dialog.hide()
    },
    checkNewLevel() {
      if (this.newLevel.length > 2) this.newLevel = this.newLevel.substring(0, 2)
      const lvl = parseInt(this.newLevel) || 0
      if (lvl < 0) this.newLevel = 0
      if (lvl > Rules.MaxPilotLevel) this.newLevel = Rules.MaxPilotLevel
    },
    setLevel() {
      let lvl = parseInt(this.newLevel) || 0
      if (lvl > Rules.MaxPilotLevel) lvl = Rules.MaxPilotLevel
      this.pilot.Level = lvl
      this.hide()
    },
  },
})
