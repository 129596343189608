var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-sidebar-view",
    [
      _vm._l(Object.keys(_vm.bonuses), function(m) {
        return _c(
          "div",
          {
            key: "list_block_" + m,
            attrs: { slot: "sidebar" },
            slot: "sidebar"
          },
          [
            _c(
              "v-list-item",
              [
                _c(
                  "v-list-item-title",
                  { staticClass: "ml-n5 mb-n2" },
                  [
                    _c("cc-logo", {
                      staticStyle: { "margin-bottom": "-6px" },
                      attrs: { source: _vm.manufacturer(m) }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "heading sub",
                        style:
                          "color: " +
                          _vm.manufacturer(m).GetColor(_vm.$vuetify.theme.dark)
                      },
                      [_vm._v("\n          " + _vm._s(m) + "\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.bonuses[m], function(cb) {
              return _c(
                "v-list-item",
                {
                  key: cb.ID + "_data'",
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      return _vm.$vuetify.goTo("#e_" + cb.ID, {
                        duration: 150,
                        easing: "easeInOutQuad",
                        offset: 25
                      })
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-title",
                    { staticClass: "heading h3 text--text" },
                    [_vm._v(_vm._s(cb.Name))]
                  )
                ],
                1
              )
            })
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "", align: "center" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "heading h2" }, [_vm._v("CORE BONUSES")])
          ]),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-col",
                {
                  staticClass: "ml-auto",
                  staticStyle: { "max-width": "30%" },
                  attrs: { cols: "auto" }
                },
                [
                  _c("v-select", {
                    attrs: {
                      value: "ALL",
                      items: ["ALL"].concat(_vm.sources),
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      height: "2px"
                    },
                    model: {
                      value: _vm.manFilter,
                      callback: function($$v) {
                        _vm.manFilter = $$v
                      },
                      expression: "manFilter"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-2" }),
      _vm._v(" "),
      _vm._l(Object.keys(_vm.bonuses), function(m) {
        return _c(
          "div",
          { key: "summary_block_m" + m },
          [
            _c(
              "v-row",
              { attrs: { dense: "", align: "center" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c("cc-logo", {
                      staticClass: "mb-n2",
                      attrs: {
                        size: _vm.$vuetify.breakpoint.mdAndUp
                          ? "xLarge"
                          : "large",
                        source: _vm.manufacturer(m)
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-col", [
                  _c(
                    "div",
                    {
                      staticClass: "heading h2",
                      style:
                        "color: " +
                        _vm.manufacturer(m).GetColor(_vm.$vuetify.theme.dark)
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.manufacturer(m).Name) +
                          "\n        "
                      )
                    ]
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.bonuses[m], function(b, i) {
              return _c("cc-core-bonus-item", {
                key: b.ID + "_" + i,
                staticClass: "mx-3 my-5",
                attrs: { id: "e_" + b.ID, bonus: b }
              })
            })
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }