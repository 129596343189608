var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-12", attrs: { fluid: "" } },
    [
      _vm._l(_vm.pilots, function(p) {
        return _c(
          "v-card",
          {
            key: p.ID,
            staticClass: "my-1",
            attrs: { cols: "12", outlined: "" }
          },
          [
            _c(
              "v-card-text",
              { staticClass: "pa-1" },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "", align: "center" } },
                  [
                    _c(
                      "v-col",
                      { staticClass: "mr-3", attrs: { cols: "auto" } },
                      [
                        _c("span", { staticClass: "heading h3 accent--text" }, [
                          _vm._v(_vm._s(p.Callsign))
                        ]),
                        _vm._v(" "),
                        _c("cc-slashes"),
                        _vm._v(" "),
                        _c("span", { staticClass: "flavor-text" }, [
                          _vm._v(_vm._s(p.Name) + " // LL " + _vm._s(p.Level))
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("v-divider", {
                      staticClass: "mx-2",
                      attrs: { vertical: "" }
                    }),
                    _vm._v(" "),
                    p.ActiveMech
                      ? _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "span",
                              { staticClass: "heading h3 accent--text" },
                              [_vm._v(_vm._s(p.ActiveMech.Name))]
                            ),
                            _vm._v(" "),
                            _c("cc-slashes"),
                            _vm._v(" "),
                            _c("span", { staticClass: "flavor-text" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(p.ActiveMech.Frame.Source) +
                                  " " +
                                  _vm._s(p.ActiveMech.Frame.Name) +
                                  "\n          "
                              )
                            ])
                          ],
                          1
                        )
                      : _c("v-col", { attrs: { cols: "auto" } }, [
                          _c(
                            "span",
                            { staticClass: "heading h3 subtle--text" },
                            [_vm._v("NO ACTIVE MECH")]
                          )
                        ]),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "fadeSelect",
                                            attrs: { outlined: "", small: "" }
                                          },
                                          on
                                        ),
                                        [_vm._v("Change Active Mech")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "v-list",
                              { attrs: { "two-line": "", subheader: "" } },
                              [
                                _c(
                                  "v-subheader",
                                  {
                                    staticClass:
                                      "heading h2 white--text primary py-0 px-2"
                                  },
                                  [
                                    _vm._v(
                                      "\n                Available Mechs\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(p.Mechs, function(mech) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: "mech-select-" + mech.ID,
                                      on: {
                                        click: function($event) {
                                          p.State.ActiveMech = mech
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        { staticClass: "ma-0 mr-2 mt-3" },
                                        [
                                          _c("cc-logo", {
                                            attrs: {
                                              size: "large",
                                              source: mech.Frame.Manufacturer
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _c(
                                              "span",
                                              {
                                                class: mech.Destroyed
                                                  ? "text-decoration-line-through text--disabled"
                                                  : ""
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(mech.Name) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("v-list-item-subtitle", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(mech.Frame.Source) +
                                                " " +
                                                _vm._s(mech.Frame.Name) +
                                                "\n                  "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "ml-auto", attrs: { cols: "auto" } },
                      [
                        _c("span", { staticClass: "flavor-text" }, [
                          _vm._v("PR//" + _vm._s(p.Power))
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "ml-2 mr-2", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              disabled: !p.ActiveMech
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("select", p)
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("mdi-plus")
                            ]),
                            _vm._v("\n            Assign\n          ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-3" }),
      _vm._v(" "),
      _vm.cloudLoading
        ? _c("v-progress-linear", { attrs: { indeterminate: "", size: "22" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          attrs: {
            text: "",
            prominent: "",
            dismissible: "",
            type: "error",
            icon: "mdi-cloud-alert"
          },
          model: {
            value: _vm.error,
            callback: function($$v) {
              _vm.error = $$v
            },
            expression: "error"
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.errorText) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "mx-2" },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Pilot Cloud Share ID",
                  outlined: "",
                  "hide-details": "",
                  color: "primary",
                  "append-outer-icon": "mdi-cloud-search"
                },
                on: {
                  "click:append-outer": function($event) {
                    return _vm.cloudImport()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.cloudImport()
                  }
                },
                model: {
                  value: _vm.importID,
                  callback: function($$v) {
                    _vm.importID = $$v
                  },
                  expression: "importID"
                }
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "my-1",
                  attrs: {
                    "x-large": "",
                    block: "",
                    tile: "",
                    color: "primary",
                    disabled: !_vm.importPilot || !_vm.importPilot.ActiveMech
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectImport()
                    }
                  }
                },
                [
                  _vm.importPilot
                    ? _c("span", [
                        _vm._v("\n          Import\n          "),
                        _c("b", [_vm._v(_vm._s(_vm.importPilot.Name))])
                      ])
                    : _c("span", [_vm._v("No Cloud Save Loaded")])
                ]
              ),
              _vm._v(" "),
              _vm.importPilot && !_vm.importPilot.ActiveMech
                ? _c("span", [
                    _vm._v(
                      "\n        Imported Pilot has no active mech selected. Unable to assign to mission.\n      "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mx-2" },
            [
              _c("v-file-input", {
                attrs: {
                  counter: "",
                  label: "Pilot Save File",
                  outlined: "",
                  "hide-details": ""
                },
                on: { change: _vm.fileImport }
              }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "my-1",
                  attrs: {
                    "x-large": "",
                    block: "",
                    tile: "",
                    color: "primary",
                    disabled: !_vm.filePilot || !_vm.filePilot.ActiveMech
                  },
                  on: {
                    click: function($event) {
                      return _vm.selectImport()
                    }
                  }
                },
                [
                  _vm.filePilot
                    ? _c("span", [
                        _vm._v("\n          Import\n          "),
                        _c("b", [_vm._v(_vm._s(_vm.filePilot.Name))])
                      ])
                    : _c("span", [_vm._v("No File Save Loaded")])
                ]
              ),
              _vm._v(" "),
              _vm.filePilot && !_vm.filePilot.ActiveMech
                ? _c("span", [
                    _vm._v(
                      "\n        Imported Pilot has no active mech selected. Unable to assign to mission.\n      "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }