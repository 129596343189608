var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "div",
            {
              staticClass: "font-weight-light text-center my-n2",
              style: _vm.$vuetify.breakpoint.mdAndDown
                ? "letter-spacing: 1vw!important; font-size: 7vw!important;"
                : "letter-spacing: 2vw!important; font-size: 4vw!important;"
            },
            [_vm._v("\n      COMPENDIUM\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { lg: "8", xs: "12" } },
                [_c("search-bar")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "v-container",
            {
              staticClass: "mt-0 pt-0",
              attrs: { fluid: "", "grid-list-lg": "" }
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    dense: "",
                    justify: _vm.$vuetify.breakpoint.smAndDown
                      ? "space-between"
                      : "space-around",
                    align: "center"
                  }
                },
                [
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      icon: "cci-manufacturer",
                      name: "Manufacturers"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/manufacturers")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      color: "primary",
                      icon: "cci-license",
                      name: "Licenses"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/licenses")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      icon: "cci-corebonus",
                      name: "CORE Bonuses"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/corebonuses")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      color: "frame",
                      icon: "cci-frame",
                      name: "Frames"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/frames")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      color: "weapon",
                      icon: "cci-melee",
                      name: "Mech Weapons"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/weapons")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      color: "system",
                      icon: "mdi-chip",
                      name: "Mech Systems"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/systems")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "3",
                      md: "6",
                      sm: "12",
                      color: "secondary",
                      icon: "cci-pilot",
                      name: "Pilot Gear"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/pilot_gear")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "3",
                      md: "6",
                      sm: "12",
                      color: "secondary",
                      icon: "public",
                      name: "Backgrounds"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/backgrounds")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "3",
                      md: "6",
                      sm: "12",
                      color: "secondary",
                      icon: "cci-accuracy",
                      name: "Skill Triggers"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/skills")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "3",
                      md: "6",
                      sm: "12",
                      color: "secondary",
                      icon: "cci-rank-3",
                      name: "Talents"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/talents")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      color: "primary",
                      icon: "cci-npc-class",
                      name: "NPC Classes"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/npc_classes")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      color: "primary",
                      icon: "cci-npc-feature",
                      name: "NPC Features"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/npc_features")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      color: "primary",
                      icon: "cci-npc-template",
                      name: "NPC Templates"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/npc_templates")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      icon: "cci-status-exposed",
                      name: "Statuses & Conditions"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/statuses")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      icon: "cci-reserve-mech",
                      name: "Reserves"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/reserves")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      icon: "cci-activate",
                      name: "Action Economy"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/actions")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      icon: "mdi-tag",
                      name: "Equipment Tags"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/tags")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      icon: "cci-accuracy",
                      name: "Reference"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/reference")
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("compendium-page-button", {
                    attrs: {
                      lg: "4",
                      md: "6",
                      sm: "12",
                      icon: "cci-compendium",
                      name: "Glossary"
                    },
                    on: {
                      clicked: function($event) {
                        return _vm.$router.push("compendium/glossary")
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }