














































































































































































import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'
import ActionMenuItem from './ActionMenuItem.vue'
import CombatLogRenderer from './CombatLogRenderer.vue'

export default vueMixins(activePilot).extend({
  name: 'active-action-menu',
  components: { ActionMenuItem, CombatLogRenderer },
  props: {
    tab: { type: Number, required: true, default: 1 },
  },
  data: () => ({
    selected: 0,
  }),
  watch: {
    tab(newVal) {
      this.selected = newVal
    },
  },
})
