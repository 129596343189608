var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-solo-dialog",
    {
      ref: "dialog",
      attrs: { icon: "cci-pilot", "no-confirm": "", title: "Edit Pilot level" }
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-alert",
            {
              attrs: {
                border: "left",
                "close-text": "Close",
                type: "warning",
                color: "stark",
                dismissible: "",
                outlined: "",
                prominent: ""
              },
              model: {
                value: _vm.alert,
                callback: function($$v) {
                  _vm.alert = $$v
                },
                expression: "alert"
              }
            },
            [
              _vm._v(
                "\n      This tool skips the level up wizard. Pilot attributes gained through levelling up, such as\n      skill triggers, licenses, talents, mech skills, and CORE bonuses will have to be updated\n      manually\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "text-center",
              attrs: { justify: "center", align: "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("span", { staticClass: "overline" }, [
                    _vm._v("Current Level:")
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass: "level-input",
                    attrs: {
                      readonly: "",
                      type: "number",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "background-color": "panel"
                    },
                    model: {
                      value: _vm.pilot.Level,
                      callback: function($$v) {
                        _vm.$set(_vm.pilot, "Level", $$v)
                      },
                      expression: "pilot.Level"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "mx-3", attrs: { cols: "auto" } },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("arrow_forward")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("span", { staticClass: "overline" }, [
                    _vm._v("New Level:")
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass: "level-input",
                    attrs: {
                      type: "number",
                      maxlength: "2",
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      "background-color": "panel"
                    },
                    on: {
                      change: function($event) {
                        return _vm.checkNewLevel()
                      }
                    },
                    model: {
                      value: _vm.newLevel,
                      callback: function($$v) {
                        _vm.newLevel = $$v
                      },
                      expression: "newLevel"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.newLevel !== null
            ? _c(
                "v-row",
                { staticClass: "ma-4" },
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-5 mr-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            block: "",
                            color: "primary",
                            "x-large": "",
                            disabled:
                              _vm.newLevel === "" ||
                              _vm.newLevel === _vm.pilot.Level
                          },
                          on: { click: _vm.setLevel }
                        },
                        [_vm._v("\n          Set Pilot Level\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }