var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      style: _vm.$vuetify.breakpoint.mdAndDown ? "x-overflow: hidden" : "",
      attrs: { fullscreen: _vm.$vuetify.breakpoint.mdAndDown, width: "90vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { tile: "" } },
        [
          _c(
            "cc-titlebar",
            { attrs: { large: "", color: "action--full" } },
            [
              _c("v-icon", { attrs: { "x-large": "", color: "white" } }, [
                _vm._v("mdi-hexagon-slice-6")
              ]),
              _vm._v("\n      Barrage\n      "),
              _c(
                "v-btn",
                {
                  attrs: { slot: "items", dark: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.hide()
                    }
                  },
                  slot: "items"
                },
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("close")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.mdAndDown
            ? _c("v-spacer", { staticClass: "titlebar-margin" })
            : _vm._e(),
          _vm._v(" "),
          _vm.items.length
            ? _c(
                "v-card-text",
                { staticClass: "mb-0 pb-2" },
                _vm._l(_vm.items, function(item, i) {
                  return _c(
                    "div",
                    { key: "barrage_item_" + item.ID + "_" + i },
                    [
                      _c(
                        "weapon-attack",
                        {
                          ref: "main_" + i,
                          refInFor: true,
                          attrs: {
                            item: item,
                            mech: _vm.mech,
                            mount: _vm.mounts[i]
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.$emit("confirm")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "heading h2 mt-3 mb-n3" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-n2 mr-n1",
                                  attrs: { "x-large": "" }
                                },
                                [_vm._v("cci-mech-weapon")]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.Name) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.hasAux(_vm.mounts[i], item)
                        ? _c("v-container", [
                            _c(
                              "div",
                              { staticClass: "my-3" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "body-text text-center font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      "\n              You may make an additional attack with the following mounted Auxiliary weapon:\n              "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center overline my-n2"
                                      },
                                      [
                                        _vm._v(
                                          "\n                This weapon cannot deal bonus damage.\n              "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-alert",
                                  {
                                    staticClass: "my-1",
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "colored-border": "",
                                      color: "primary"
                                    }
                                  },
                                  [
                                    _c(
                                      "weapon-attack",
                                      {
                                        ref: "aux_" + i,
                                        refInFor: true,
                                        staticClass: "mt-n3",
                                        attrs: {
                                          item: _vm.hasAux(_vm.mounts[i], item),
                                          mech: _vm.mech,
                                          mount: _vm.mounts[i],
                                          aux: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "heading h3 mt-3 mb-n3"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "mt-n2 mr-n1",
                                                attrs: { large: "" }
                                              },
                                              [_vm._v("cci-mech-weapon")]
                                            ),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.hasAux(
                                                    _vm.mounts[i],
                                                    item
                                                  ).Name
                                                ) +
                                                "\n                "
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }