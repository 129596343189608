var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "equipment-card-base",
    { attrs: { item: _vm.item } },
    [
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [_c("cc-range-element", { attrs: { range: _vm.item.Range } })],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c("cc-damage-element", {
            attrs: {
              damage: _vm.item.Damage,
              "type-override": _vm.item.DamageTypeOverride
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }