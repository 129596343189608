var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-title",
        {
          staticClass: "pl-3",
          staticStyle: { "margin-left": "-50px!important" },
          attrs: { small: "", color: "pilot" }
        },
        [
          _c("section-edit-chip", {
            attrs: {
              highlight: !_vm.pilot.HasLicenses,
              current: _vm.pilot.CurrentLicensePoints,
              max: _vm.pilot.MaxLicensePoints,
              label:
                "Edit Pilot Licenses (" +
                _vm.pilot.CurrentLicensePoints +
                "/" +
                _vm.pilot.MaxLicensePoints +
                ")"
            },
            on: {
              "open-selector": function($event) {
                return _vm.$refs.licenseSelector.show()
              }
            }
          }),
          _vm._v("\n    Licenses\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "licenseSelector",
          attrs: {
            icon: "cci-frame",
            "no-confirm": "",
            title: "Set Pilot Licenses",
            fullscreen: ""
          }
        },
        [_c("cc-license-selector", { attrs: { pilot: _vm.pilot } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          !_vm.pilot.Licenses.length
            ? _c("no-data-block")
            : _c(
                "v-row",
                {
                  style: _vm.$vuetify.breakpoint.lgAndUp
                    ? "width: calc(100vw - 250px)"
                    : "",
                  attrs: { dense: "" }
                },
                _vm._l(_vm.pilot.Licenses, function(l, i) {
                  return _c(
                    "v-col",
                    { key: "l_" + i, attrs: { cols: "12", md: "4" } },
                    [
                      _c("cc-pilot-license-item", {
                        attrs: { "pilot-license": l }
                      })
                    ],
                    1
                  )
                }),
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }