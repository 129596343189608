var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    { attrs: { label: "", outlined: "", color: _vm.item.Color, dark: "" } },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "open-on-hover": "",
            "open-delay": "500ms",
            "offset-y": "",
            top: "",
            "close-on-click": "",
            "close-on-content-click": ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g({}, on),
                    [
                      _c("v-icon", [
                        _vm._v(
                          "cci-" + _vm._s(_vm.$_.kebabCase(_vm.item.ItemType))
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3 px-2 mt-n1" }, [
                        _vm._v(_vm._s(_vm.item.Name))
                      ])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("cc-item-card", {
            attrs: { item: _vm.item, readonly: "", active: "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }