






































import Vue from 'vue'
import Component from 'vue-class-component'
import DirectoryTable from './components/DirectoryTable.vue'

@Component({
  components: { DirectoryTable },
})
export default class PacksDirectory extends Vue {
  private massifPacksUrl = 'https://compcon-text-assets.s3.amazonaws.com/massifpacks.json'
  private communityPacksUrl = 'https://compcon-text-assets.s3.amazonaws.com/communitypacks.json'
  private massifPacks = []
  private massifLoading = true
  private communityPacks = []
  private communityLoading = true

  mounted(): void {
    fetch(this.massifPacksUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(res => res.json())
      .then(list => (this.massifPacks = list))
      .catch(err => {
        console.error('There was an issue downloading the Massif content packs.', err)
      })
      .finally(() => (this.massifLoading = false))

    fetch(this.communityPacksUrl, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(res => res.json())
      .then(list => (this.communityPacks = list))
      .catch(err => {
        console.error('There was an issue downloading the community content packs.', err)
      })
      .finally(() => (this.communityLoading = false))
  }
}
