var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("weapon-attack", {
    ref: "main",
    attrs: { item: _vm.item, mech: _vm.mech, improv: "" },
    on: {
      confirm: function($event) {
        return _vm.attackConfirm($event)
      },
      reset: function($event) {
        return _vm.attackUndo()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }