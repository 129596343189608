import { render, staticRenderFns } from "./_FrameCoreSystemPanel.vue?vue&type=template&id=0e3c0c13&"
import script from "./_FrameCoreSystemPanel.vue?vue&type=script&lang=ts&"
export * from "./_FrameCoreSystemPanel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e3c0c13')) {
      api.createRecord('0e3c0c13', component.options)
    } else {
      api.reload('0e3c0c13', component.options)
    }
    module.hot.accept("./_FrameCoreSystemPanel.vue?vue&type=template&id=0e3c0c13&", function () {
      api.rerender('0e3c0c13', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/cards/frame/_FrameCoreSystemPanel.vue"
export default component.exports