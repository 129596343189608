var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card-base",
    { attrs: { item: _vm.item, small: _vm.small, hover: _vm.hover } },
    [
      _vm.item.DefaultImage
        ? _c("v-img", {
            staticClass: "py-2",
            attrs: {
              slot: "top",
              src: _vm.item.DefaultImage,
              "max-height": _vm.small ? "175px" : "225px",
              contain: ""
            },
            slot: "top"
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { attrs: { slot: "overline" }, slot: "overline" }, [
        _vm._v(_vm._s(_vm.item.MechTypeString) + " Frame")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }