var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { fullscreen: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      { attrs: { small: "", outlined: "", block: "" } },
                      on
                    ),
                    [_vm._v("View " + _vm._s(_vm.frame.Name) + " Gallery")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            { attrs: { color: "background" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", fixed: "", top: "", right: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("mdi-close")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                { staticClass: "px-5", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ml-auto mr-auto",
                          attrs: { cols: "auto" }
                        },
                        [
                          _c("v-img", {
                            style:
                              "max-height: 1080px; image-rendering: " +
                              (_vm.isPixel ? "pixelated" : "crisp-edges") +
                              ";",
                            attrs: {
                              src: _vm.selected,
                              "max-width": "60vw",
                              "min-width": "30vw",
                              contain: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "ml-auto pr-6", attrs: { cols: "4" } },
                        [
                          _c(
                            "div",
                            { staticClass: "heading h1 accent--text" },
                            [_vm._v(_vm._s(_vm.frame.Name))]
                          ),
                          _vm._v(" "),
                          _vm.selected && _vm.artist
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "my-2",
                                  attrs: {
                                    outlined: "",
                                    dense: "",
                                    color: "primary"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", [
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "heading h3 accent--text"
                                            },
                                            [_vm._v(_vm._s(_vm.artist.imgName))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flavor-text ml-3" },
                                            [
                                              _vm._v(
                                                "by " + _vm._s(_vm.artist.name)
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _vm.artist.website
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "ml-3",
                                                staticStyle: {
                                                  "text-decoration": "none"
                                                },
                                                attrs: {
                                                  target: "_blank",
                                                  href: "" + _vm.artist.website
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" }
                                                  },
                                                  [_vm._v("mdi-web")]
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Website")])
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.artist.website && _vm.artist.twitter
                                          ? _c(
                                              "span",
                                              { staticClass: "ml-4 mr-2" },
                                              [_vm._v("|")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.artist.twitter
                                          ? _c(
                                              "a",
                                              {
                                                key: _vm.artist.twitter,
                                                staticClass: "ml-3",
                                                staticStyle: {
                                                  "text-decoration": "none"
                                                },
                                                attrs: {
                                                  target: "_blank",
                                                  href:
                                                    "https://twitter.com/" +
                                                    _vm.artist.twitter
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: { color: "primary" }
                                                  },
                                                  [_vm._v("mdi-twitter")]
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "@" +
                                                      _vm._s(_vm.artist.twitter)
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "", justify: "space-around" } },
                            [
                              _vm.frame.ID !== "mf_standard_pattern_i_everest"
                                ? _c(
                                    "v-col",
                                    {
                                      staticStyle: { "max-width": "200px" },
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class:
                                            _vm.selected ===
                                            _vm.frame.DefaultImage
                                              ? "selected-img"
                                              : "unselected-img",
                                          on: {
                                            click: function($event) {
                                              _vm.selected =
                                                _vm.frame.DefaultImage
                                            }
                                          }
                                        },
                                        [
                                          _c("v-img", {
                                            style:
                                              "max-height: 200px; image-rendering: " +
                                              (_vm.isPixel
                                                ? "pixelated"
                                                : "crisp-edges") +
                                              ";",
                                            attrs: {
                                              src: _vm.frame.DefaultImage,
                                              contain: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.frame.OtherArt, function(a) {
                                return _c(
                                  "v-col",
                                  {
                                    key: a.src,
                                    staticStyle: { "max-width": "200px" },
                                    attrs: { cols: "auto" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          _vm.selected ===
                                          _vm.imgPath(a.tag, a.src)
                                            ? "selected-img"
                                            : "unselected-img",
                                        on: {
                                          click: function($event) {
                                            return _vm.selectImg(a)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-img", {
                                          style:
                                            "max-height: 200px; image-rendering: " +
                                            (_vm.isPixel
                                              ? "pixelated"
                                              : "crisp-edges") +
                                            ";",
                                          attrs: {
                                            src: _vm.imgPath(a.tag, a.src),
                                            contain: ""
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }