var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-dialog",
        {
          ref: "dialog",
          attrs: {
            "no-confirm": "",
            color: _vm.item.Color,
            large: _vm.$vuetify.breakpoint.mdAndUp,
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            "small-btn": _vm.smallBtn
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "white--text",
              staticStyle: { width: "100%" },
              attrs: { slot: "button" },
              slot: "button"
            },
            [
              _c("v-icon", { attrs: { left: !_vm.smallBtn } }, [
                _vm._v(_vm._s(_vm.item.Icon))
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.truncate(_vm.item.Name)) +
                  " " +
                  _vm._s(_vm.item.ItemType === "Frame" ? "FRAME" : "") +
                  "\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("v-icon", { attrs: { left: "", large: "", dark: "" } }, [
                _vm._v(_vm._s(_vm.item.Icon))
              ]),
              _vm._v("\n      " + _vm._s(_vm.item.Name) + "\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-btn",
                {
                  attrs: { slot: "title-items", dark: "", icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.dialog.confirm()
                    }
                  },
                  slot: "title-items"
                },
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("close")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.lgAndUp
            ? _c(
                "v-chip",
                {
                  staticClass: "stat-text mt-4 mr-6",
                  attrs: {
                    slot: "title-items",
                    color: "white",
                    outlined: "",
                    label: ""
                  },
                  slot: "title-items"
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.item.Source || "") +
                      " " +
                      _vm._s(_vm.$_.startCase(_vm.item.ItemType)) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("cc-item-card", { attrs: { item: _vm.item } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }