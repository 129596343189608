var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("cc-stress-table", { ref: "stressTable", attrs: { mech: _vm.mech } }),
      _vm._v(" "),
      _c("cc-structure-table", {
        ref: "structureTable",
        attrs: { mech: _vm.mech }
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { staticClass: "mt-n5" }, [
                _c("div", { staticClass: "overline subtle--text" }, [
                  _vm._v("MOUNTED::")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "heading h2 mt-n4" },
                  [
                    _c("span", { staticClass: "accent--text" }, [
                      _vm._v(
                        _vm._s(_vm.mech.Frame.Source) +
                          " " +
                          _vm._s(_vm.mech.Frame.Name)
                      )
                    ]),
                    _vm._v(" "),
                    _c("cc-slashes"),
                    _vm._v(" "),
                    _c("span", { staticClass: "stark--text" }, [
                      _vm._v(_vm._s(_vm.mech.Name))
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ml-auto mr-2 mt-n3", attrs: { cols: "auto" } },
                [
                  _c("div", { staticClass: "overline subtle--text mt-n2" }, [
                    _vm._v("PILOT::")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "heading h2 mt-n3 subtle--text" }, [
                    _vm._v(_vm._s(_vm.pilot.Callsign))
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.state.SelfDestructCounter > 0
            ? _c(
                "v-row",
                {
                  staticClass: "text-center",
                  attrs: { dense: "", justify: "center" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            dense: "",
                            outlined: "",
                            color: "error",
                            prominent: ""
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                slot: "prepend",
                                color: "error",
                                size: "90"
                              },
                              slot: "prepend"
                            },
                            [_vm._v("\n            cci-reactor\n          ")]
                          ),
                          _vm._v(" "),
                          _vm.state.SelfDestructCounter > 1
                            ? _c("span", { staticClass: "heading h1" }, [
                                _vm._v(
                                  "\n            SELF DESTRUCT IN " +
                                    _vm._s(_vm.state.SelfDestructCounter) +
                                    " ROUNDS\n          "
                                )
                              ])
                            : _c("span", { staticClass: "heading h1" }, [
                                _vm._v("SELF DESTRUCT IMMINENT")
                              ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "heading mt-n4 subtle--text" },
                            [
                              _vm._v(
                                "\n            FRAME.PRIORITY.ALERT::REACTOR CRITICALITY EVENT\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "px-5 my-1" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    block: "",
                                    color: "error"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.state.SelfDestruct()
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-skull")
                                  ]),
                                  _vm._v(
                                    "\n              DETONATE REACTOR\n              "
                                  ),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-skull")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-right mt-1" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "fadeSelect",
                                  attrs: { "x-small": "", color: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.state.CancelSelfDestruct()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", left: "" } },
                                    [_vm._v("mdi-reload")]
                                  ),
                                  _vm._v("\n              UNDO\n            ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mech.Pilot.Status === "KIA" || _vm.mech.Pilot.IsDead
            ? _c(
                "v-alert",
                {
                  attrs: {
                    prominent: "",
                    dense: "",
                    outlined: "",
                    color: "error"
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { slot: "prepend", size: "80" },
                      slot: "prepend"
                    },
                    [_vm._v("mdi-skull")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "heading h1 pb-2 text-center" }, [
                    _vm._v("KILLED IN ACTION")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", "offset-x": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "fadeSelect",
                                          staticStyle: {
                                            bottom: "0",
                                            right: "0"
                                          },
                                          attrs: {
                                            color: "secondary",
                                            absolute: "",
                                            right: "",
                                            small: "",
                                            outlined: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n              Revert\n            "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3527931808
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("cc-confirmation", {
                            attrs: {
                              content:
                                "This will restore the selected pilot and clear the KIA and Down and Out statuses."
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.mech.Pilot.Restore()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mech.Destroyed
            ? _c("destroyed-alert", {
                attrs: { mech: _vm.mech },
                on: {
                  restore: function($event) {
                    return _vm.mech.BasicRepair($event)
                  }
                }
              })
            : _vm.mech.IsShutDown
            ? _c(
                "v-row",
                {
                  staticClass: "text-center",
                  attrs: { dense: "", justify: "center" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            dense: "",
                            outlined: "",
                            color: "warning",
                            prominent: ""
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-3",
                              attrs: {
                                slot: "prepend",
                                color: "warning",
                                size: "70"
                              },
                              slot: "prepend"
                            },
                            [
                              _vm._v(
                                "\n            cci-status-shut-down\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "heading h1" }, [
                            _vm._v("MECH SHUT DOWN")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "heading mt-n4 subtle--text" },
                            [
                              _vm._v(
                                "\n            FRAME.STATUS//ERR: NO RESPONSE\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.mech.ReactorDestroyed
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-n3",
                      attrs: {
                        justify: "space-between",
                        align: "center",
                        dense: ""
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          style: _vm.mech.Destroyed ? "opacity: 0.5" : "",
                          attrs: { cols: "3" }
                        },
                        [
                          _c("cc-status-select", {
                            attrs: {
                              label: "Statuses",
                              items: _vm.statuses,
                              model: _vm.mech.Statuses,
                              dark: "",
                              color: "deep-orange darken-1"
                            },
                            on: {
                              set: function($event) {
                                return _vm.state.SetStatusCondition(
                                  $event,
                                  true
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("cc-status-select", {
                            attrs: {
                              label: "Conditions",
                              items: _vm.conditions,
                              model: _vm.mech.Conditions,
                              dark: "",
                              color: "red darken-2"
                            },
                            on: {
                              set: function($event) {
                                return _vm.state.SetStatusCondition($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "3" } },
                        [
                          _c("cc-status-select", {
                            attrs: {
                              label: "Resistances",
                              items: _vm.resistances,
                              model: _vm.mech.Resistances,
                              dark: "",
                              color: "blue darken-2"
                            },
                            on: {
                              set: function($event) {
                                return _vm.state.SetResistance($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "ml-auto mr-auto",
                          style: _vm.mech.Destroyed ? "opacity: 0.5" : "",
                          attrs: { cols: "auto" }
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "hide-input-spinners",
                            staticStyle: { width: "115px" },
                            attrs: {
                              type: "number",
                              "append-outer-icon": "mdi-plus-circle-outline",
                              "append-icon": "mdi-fire",
                              "prepend-icon": "mdi-minus-circle-outline",
                              hint: "BURN",
                              "persistent-hint": "",
                              dense: ""
                            },
                            on: {
                              "click:append-outer": function($event) {
                                return _vm.state.SetBurn(_vm.mech.Burn + 1)
                              },
                              "click:prepend": function($event) {
                                return _vm.state.SetBurn(_vm.mech.Burn - 1)
                              },
                              change: function($event) {
                                _vm.state.SetBurn(parseInt($event))
                              }
                            },
                            model: {
                              value: _vm.mech.Burn,
                              callback: function($$v) {
                                _vm.$set(_vm.mech, "Burn", $$v)
                              },
                              expression: "mech.Burn"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", {
                        class:
                          "mx-3 " +
                          (_vm.$vuetify.breakpoint.lgAndDown ? "ml-auto" : ""),
                        attrs: { cols: "auto" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { style: _vm.mech.Destroyed ? "opacity: 0.5" : "" },
                    [
                      _c(
                        "pip-layout",
                        {
                          attrs: {
                            mech: _vm.mech,
                            "struct-rollover": _vm.structRolledOver,
                            "stress-rollover": _vm.stressRolledOver,
                            "hp-resistance": _vm.hpResistance
                          }
                        },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                slot: "repair",
                                simple: "",
                                inline: "",
                                content: "Full Repair"
                              },
                              slot: "repair"
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "offset-y": "",
                                    "offset-x": "",
                                    bottom: "",
                                    left: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            !_vm.mech.Destroyed
                                              ? _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "fadeSelect",
                                                      attrs: { icon: "" }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { "x-large": "" }
                                                      },
                                                      [_vm._v("cci-repair")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        "x-large": "",
                                                        color: "secondary",
                                                        icon: ""
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { size: "50" } },
                                                      [_vm._v("cci-repair")]
                                                    )
                                                  ],
                                                  1
                                                )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    4186472413
                                  ),
                                  model: {
                                    value: _vm.repairMenu,
                                    callback: function($$v) {
                                      _vm.repairMenu = $$v
                                    },
                                    expression: "repairMenu"
                                  }
                                },
                                [
                                  _vm._v(" "),
                                  _c("cc-confirmation", {
                                    attrs: {
                                      content:
                                        "Lancer, this will <span class='accent--text'>fully repair and recharge this mech.</span> Do you want to continue?"
                                    },
                                    on: {
                                      confirm: function($event) {
                                        _vm.repairMenu = false
                                        _vm.state.CommitFullRepair()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-n3",
                          attrs: { dense: "", align: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-2 mt-n2 mr-2",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", { staticClass: "mb-n2" }, [
                                _c(
                                  "span",
                                  { staticClass: "heading h2 accent--text" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.pilot.MechSkills.Hull) +
                                        "\n                "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "flavor-text subtle--text"
                                      },
                                      [_vm._v("//HULL")]
                                    ),
                                    _vm._v(" "),
                                    _c("cc-synergy-display", {
                                      staticClass: "d-inline",
                                      attrs: {
                                        location: "hull",
                                        mech: _vm.mech
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "mb-n2" }, [
                                _c(
                                  "span",
                                  { staticClass: "heading h2 accent--text" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.pilot.MechSkills.Agi) +
                                        "\n                "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "flavor-text subtle--text"
                                      },
                                      [_vm._v("//AGI")]
                                    ),
                                    _vm._v(" "),
                                    _c("cc-synergy-display", {
                                      staticClass: "d-inline",
                                      attrs: {
                                        location: "agility",
                                        mech: _vm.mech
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "mb-n2" }, [
                                _c(
                                  "span",
                                  { staticClass: "heading h2 accent--text" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.pilot.MechSkills.Sys) +
                                        "\n                "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "flavor-text subtle--text"
                                      },
                                      [_vm._v("//SYS")]
                                    ),
                                    _vm._v(" "),
                                    _c("cc-synergy-display", {
                                      staticClass: "d-inline",
                                      attrs: {
                                        location: "systems",
                                        mech: _vm.mech
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "mb-n2" }, [
                                _c(
                                  "span",
                                  { staticClass: "heading h2 accent--text" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.pilot.MechSkills.Eng) +
                                        "\n                "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "flavor-text subtle--text"
                                      },
                                      [_vm._v("//ENG")]
                                    ),
                                    _vm._v(" "),
                                    _c("cc-synergy-display", {
                                      staticClass: "d-inline",
                                      attrs: {
                                        location: "engineering",
                                        mech: _vm.mech
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("cc-active-card", {
                                    staticClass: "my-1",
                                    attrs: {
                                      prominent: "",
                                      color: "frame",
                                      header: "Speed",
                                      content: _vm.mech.Speed
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("cc-active-card", {
                                    staticClass: "my-1",
                                    attrs: {
                                      prominent: "",
                                      color: "frame",
                                      header: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "Attack Bonus"
                                        : "Atk Bonus",
                                      content:
                                        "" +
                                        (_vm.mech.AttackBonus > 0 ? "+" : "") +
                                        _vm.mech.AttackBonus
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("cc-active-card", {
                                    staticClass: "my-1",
                                    attrs: {
                                      prominent: "",
                                      color: "frame",
                                      header: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "Tech Attack"
                                        : "Tech Atk",
                                      content:
                                        "" +
                                        (_vm.mech.TechAttack > 0 ? "+" : "") +
                                        _vm.mech.TechAttack
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c("cc-active-card", {
                                    key: _vm.mech.IsStunned,
                                    staticClass: "my-1",
                                    attrs: {
                                      prominent: "",
                                      color: "frame",
                                      header: "Evasion",
                                      content: _vm.mech.Evasion
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("cc-active-card", {
                                    staticClass: "my-1",
                                    attrs: {
                                      prominent: "",
                                      color: "frame",
                                      header: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "E-Defense"
                                        : "E-Def",
                                      content: _vm.mech.EDefense
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("cc-active-card", {
                                    staticClass: "my-1",
                                    attrs: {
                                      prominent: "",
                                      color: "frame",
                                      header: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "Save Target"
                                        : "Save",
                                      content: _vm.mech.SaveTarget
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("cc-active-card", {
                                    staticClass: "my-1",
                                    attrs: {
                                      prominent: "",
                                      color: "frame",
                                      header: _vm.$vuetify.breakpoint.lgAndUp
                                        ? "Sensor Range"
                                        : "Sensors",
                                      content: _vm.mech.SensorRange
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c(
                              "div",
                              { staticStyle: { position: "relative" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: { "z-index": "2" },
                                    attrs: { size: "120", color: "frame" }
                                  },
                                  [_vm._v(_vm._s(_vm.mech.SizeIcon))]
                                ),
                                _vm._v(" "),
                                _vm.mech.Size > 0.5
                                  ? _c("div", {
                                      staticStyle: {
                                        "background-color": "white",
                                        position: "absolute",
                                        bottom: "21px",
                                        top: "21px",
                                        left: "21px",
                                        right: "21px",
                                        "border-radius": "50%",
                                        "z-index": "1"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            _vm._l(_vm.mech.Frame.Traits, function(trait, i) {
                              return _c(
                                "cc-active-card",
                                {
                                  key: "tr_" + i,
                                  attrs: {
                                    color: "frame",
                                    header: trait.Name,
                                    subheader: "FRAME TRAIT"
                                  }
                                },
                                [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "html-safe",
                                        rawName: "v-html-safe",
                                        value: trait.Description,
                                        expression: "trait.Description"
                                      }
                                    ]
                                  }),
                                  _vm._v(" "),
                                  trait.Actions.length
                                    ? _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        _vm._l(trait.Actions, function(a, j) {
                                          return _c(
                                            "v-col",
                                            {
                                              key: trait.Name + "_action_" + j,
                                              staticClass: "mb-n1",
                                              staticStyle: {
                                                "min-width": "40%"
                                              }
                                            },
                                            [
                                              _c("cc-action", {
                                                attrs: {
                                                  action: a,
                                                  active: "",
                                                  activations:
                                                    _vm.pilot.State.Actions,
                                                  disabled: _vm.mech.IsStunned,
                                                  unusable:
                                                    a.Activation ===
                                                      "Protocol" &&
                                                    !_vm.pilot.State
                                                      .IsProtocolAvailable
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "8", "align-self": "center" } },
                            [
                              _c(
                                "cc-active-card",
                                {
                                  staticStyle: { height: "100%" },
                                  attrs: {
                                    color: "corepower",
                                    header: _vm.mech.Frame.CoreSystem.Name,
                                    subheader: "CORE SYSTEM"
                                  }
                                },
                                [
                                  _vm.mech.Frame.CoreSystem.PassiveName
                                    ? _c(
                                        "div",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "heading h2" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.mech.Frame.CoreSystem
                                                      .PassiveName
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "pt-2 ml-2 caption subtle--text"
                                                },
                                                [_vm._v("(PASSIVE)")]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("p", {
                                            directives: [
                                              {
                                                name: "html-safe",
                                                rawName: "v-html-safe",
                                                value:
                                                  _vm.mech.Frame.CoreSystem
                                                    .PassiveEffect,
                                                expression:
                                                  "mech.Frame.CoreSystem.PassiveEffect"
                                              }
                                            ],
                                            staticClass: "mb-1"
                                          }),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.mech.Frame.CoreSystem
                                              .PassiveActions,
                                            function(a, i) {
                                              return _c("cc-action", {
                                                key: "core_passive_action_" + i,
                                                staticClass: "mx-8",
                                                attrs: {
                                                  action: a,
                                                  active: "",
                                                  activations:
                                                    _vm.mech.Pilot.State
                                                      .Actions,
                                                  disabled:
                                                    _vm.mech.Destroyed ||
                                                    _vm.mech.IsStunned,
                                                  unusable:
                                                    a.Activation ===
                                                      "Protocol" &&
                                                    !_vm.mech.Pilot.State
                                                      .IsProtocolAvailable
                                                }
                                              })
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { dense: "" }
                                    },
                                    _vm._l(
                                      _vm.mech.Frame.CoreSystem.DeployActions,
                                      function(a, i) {
                                        return _c(
                                          "v-col",
                                          {
                                            key:
                                              _vm.mech.Frame.CoreSystem.Name +
                                              "_deployable_action_" +
                                              i,
                                            staticClass: "mb-n1",
                                            staticStyle: { "min-width": "40%" }
                                          },
                                          [
                                            _c("cc-action", {
                                              attrs: {
                                                action: a,
                                                active: "",
                                                "no-action": "",
                                                activations:
                                                  _vm.mech.Pilot.State.Actions
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "heading h2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.mech.Frame.CoreSystem.ActiveName
                                        ) +
                                        "\n                "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "pt-2 ml-2 caption subtle--text"
                                      },
                                      [_vm._v("(ACTIVE)")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("p", {
                                    directives: [
                                      {
                                        name: "html-safe",
                                        rawName: "v-html-safe",
                                        value:
                                          _vm.mech.Frame.CoreSystem
                                            .ActiveEffect,
                                        expression:
                                          "mech.Frame.CoreSystem.ActiveEffect"
                                      }
                                    ],
                                    staticClass: "mb-1 text--text body-text"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "my-1 px-6" },
                                    [
                                      _vm.mech.CurrentCoreEnergy > 0
                                        ? _c("cc-action", {
                                            attrs: {
                                              active: "",
                                              action: _vm.coreActivator
                                            },
                                            on: {
                                              use: function($event) {
                                                _vm.mech.CurrentCoreEnergy--
                                              }
                                            }
                                          })
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "heading h3 text-center",
                                              staticStyle: {
                                                "letter-spacing": "5px",
                                                opacity: "0.4"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  CORE ENERGY EXHAUSTED\n                "
                                              )
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.mech.Frame.CoreSystem.ActiveActions,
                                        function(a, i) {
                                          return _c("cc-action", {
                                            key: "core_active_action_" + i,
                                            staticClass: "mx-8",
                                            attrs: {
                                              action: a,
                                              active: "",
                                              activations:
                                                _vm.mech.Pilot.State.Actions,
                                              disabled:
                                                _vm.mech.Destroyed ||
                                                _vm.mech.IsStunned,
                                              unusable:
                                                a.Activation === "Protocol" &&
                                                !_vm.mech.Pilot.State
                                                  .IsProtocolAvailable
                                            }
                                          })
                                        }
                                      )
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("cc-tags", {
                                    attrs: {
                                      tags: _vm.mech.Frame.CoreSystem.Tags,
                                      color: "corepower"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.pilot.CoreBonuses
                    ? _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mb-n2", attrs: { cols: "auto" } },
                            [
                              _c("span", { staticClass: "overline" }, [
                                _vm._v("CORE BONUSES")
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "fadeSelect",
                                  attrs: { small: "", right: "", icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showCBs = !_vm.showCBs
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { small: "" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.showCBs
                                          ? "mdi-eye-outline"
                                          : "mdi-eye-off-outline"
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-scroll-x-transition",
                            [
                              _vm.showCBs
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "ml-auto",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "fadeSelect",
                                          attrs: {
                                            "x-small": "",
                                            outlined: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.expandAll(
                                                _vm.pilot.CoreBonuses.length,
                                                "cb_",
                                                true
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "", left: "" } },
                                            [_vm._v("mdi-chevron-up")]
                                          ),
                                          _vm._v(
                                            "\n              All\n            "
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "fadeSelect",
                                          attrs: {
                                            "x-small": "",
                                            outlined: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.expandAll(
                                                _vm.pilot.CoreBonuses.length,
                                                "cb_",
                                                false
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "", left: "" } },
                                            [_vm._v("mdi-chevron-down")]
                                          ),
                                          _vm._v(
                                            "\n              All\n            "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-scroll-y-reverse-transition",
                    { attrs: { mode: "out-in", "leave-absolute": "" } },
                    [
                      _vm.pilot.CoreBonuses && _vm.showCBs
                        ? _c(
                            "v-row",
                            _vm._l(_vm.pilot.CoreBonuses, function(bonus, i) {
                              return _c(
                                "cc-active-card",
                                {
                                  key: "cb_" + i,
                                  ref: "cb_" + i,
                                  refInFor: true,
                                  staticStyle: { "min-width": "400px" },
                                  attrs: {
                                    cols: 12 / _vm.pilot.CoreBonuses.length,
                                    color: "corepower",
                                    collapsible: "",
                                    header: bonus.Name
                                  }
                                },
                                [
                                  _c("p", {
                                    directives: [
                                      {
                                        name: "html-safe",
                                        rawName: "v-html-safe",
                                        value: bonus.Effect,
                                        expression: "bonus.Effect"
                                      }
                                    ],
                                    staticClass: "pa-1 ma-0"
                                  })
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "mb-n2", attrs: { cols: "auto" } },
                        [
                          _c("span", { staticClass: "overline" }, [
                            _vm._v("PILOT TALENTS")
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              attrs: { small: "", right: "", icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.showTalents = !_vm.showTalents
                                }
                              }
                            },
                            [
                              _c("v-icon", {
                                attrs: { small: "" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.showTalents
                                      ? "mdi-eye-outline"
                                      : "mdi-eye-off-outline"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-scroll-x-transition",
                        [
                          _vm.showTalents
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "ml-auto",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "fadeSelect",
                                      attrs: { "x-small": "", outlined: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.expandAll(
                                            _vm.pilot.Talents.length,
                                            "tal_",
                                            true
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", left: "" } },
                                        [_vm._v("mdi-chevron-up")]
                                      ),
                                      _vm._v(
                                        "\n              All\n            "
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "fadeSelect",
                                      attrs: { "x-small": "", outlined: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.expandAll(
                                            _vm.pilot.Talents.length,
                                            "tal_",
                                            false
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", left: "" } },
                                        [_vm._v("mdi-chevron-down")]
                                      ),
                                      _vm._v(
                                        "\n              All\n            "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-scroll-y-reverse-transition",
                    { attrs: { mode: "out-in", "leave-absolute": "" } },
                    [
                      _vm.showTalents
                        ? _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            _vm._l(_vm.pilot.Talents, function(t, i) {
                              return _c(
                                "cc-active-card",
                                {
                                  key: "tal_" + i,
                                  ref: "tal_" + i,
                                  refInFor: true,
                                  attrs: {
                                    collapsible: "",
                                    "start-closed": "",
                                    color: "primary",
                                    cols: _vm.$vuetify.breakpoint.lgAndUp
                                      ? 4
                                      : 6,
                                    header:
                                      t.Talent.Name + " " + "I".repeat(t.Rank)
                                  }
                                },
                                _vm._l(3, function(n) {
                                  return _c("ul", { key: "t_" + n }, [
                                    t.Rank >= n
                                      ? _c("li", [
                                          _c("span", {
                                            directives: [
                                              {
                                                name: "html-safe",
                                                rawName: "v-html-safe",
                                                value:
                                                  t.Talent.Ranks[n - 1]
                                                    .Description,
                                                expression:
                                                  "t.Talent.Ranks[n - 1].Description"
                                              }
                                            ]
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "text-center" },
                                            _vm._l(
                                              t.Talent.Ranks[n - 1].Actions,
                                              function(a) {
                                                return _c("cc-action", {
                                                  key:
                                                    "t_a_" + n + "_" + a.Name,
                                                  attrs: {
                                                    action: a,
                                                    active:
                                                      a.Activation !== "Invade",
                                                    activations:
                                                      _vm.pilot.State.Actions,
                                                    disabled:
                                                      _vm.mech.IsStunned,
                                                    unusable:
                                                      a.Activation ===
                                                        "Protocol" &&
                                                      !_vm.pilot.State
                                                        .IsProtocolAvailable
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                }),
                                0
                              )
                            }),
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("span", { staticClass: "overline" }, [
                            _vm._v("\n            COUNTERS\n          ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              attrs: { small: "", right: "", icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.showCounters = !_vm.showCounters
                                }
                              }
                            },
                            [
                              _c("v-icon", {
                                attrs: { small: "" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.showCounters
                                      ? "mdi-eye-outline"
                                      : "mdi-eye-off-outline"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-scroll-y-reverse-transition",
                        { attrs: { mode: "out-in" } },
                        [
                          _vm.showCounters
                            ? _c(
                                "div",
                                [
                                  _c("cc-counter-set", {
                                    attrs: { actor: [_vm.pilot] }
                                  })
                                ],
                                1
                              )
                            : _c("div", {
                                staticStyle: { "min-height": "24px" }
                              })
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { style: _vm.mech.Destroyed ? "opacity: 0.5" : "" },
                    [
                      _c("active-mode-loadout", {
                        attrs: { mech: _vm.mech, rest: _vm.mech.Destroyed }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }