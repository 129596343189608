var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: _vm.selected ? "primary--text" : "white--text",
      attrs: {
        tile: "",
        depressed: "",
        color: _vm.selected ? "white" : "primary",
        to: _vm.to,
        disabled: _vm.disabled
      },
      on: {
        click: function($event) {
          _vm.to ? "" : _vm.$emit("clicked")
        }
      }
    },
    [_c("span", { staticClass: "unskew" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }