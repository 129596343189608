import { render, staticRenderFns } from "./_ActiveSystemCard.vue?vue&type=template&id=590df1e4&scoped=true&"
import script from "./_ActiveSystemCard.vue?vue&type=script&lang=ts&"
export * from "./_ActiveSystemCard.vue?vue&type=script&lang=ts&"
import style0 from "./_ActiveSystemCard.vue?vue&type=style&index=0&id=590df1e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "590df1e4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCard,VCardText,VCardTitle,VCol,VIcon,VRow,VSlideYTransition,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('590df1e4')) {
      api.createRecord('590df1e4', component.options)
    } else {
      api.reload('590df1e4', component.options)
    }
    module.hot.accept("./_ActiveSystemCard.vue?vue&type=template&id=590df1e4&scoped=true&", function () {
      api.rerender('590df1e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/active_loadout/_ActiveSystemCard.vue"
export default component.exports