var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bordered-primary" },
    [
      _c(
        "v-row",
        { staticClass: "primary white--text mt-n3 px-2", attrs: { dense: "" } },
        [
          _c("v-col", [
            _c("span", { staticClass: "heading h1" }, [
              _vm._v(_vm._s(_vm.pilot.Callsign || "ERR CALLSIGN NOT FOUND"))
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "heading h2 mt-7" }, [
              _vm._v(
                " (" + _vm._s(_vm.pilot.Name || "ERR NAME NOT FOUND") + ")"
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto mt-4", attrs: { cols: "auto" } },
            [
              _c("v-icon", { attrs: { size: "70", dark: "" } }, [
                _vm._v("cci-orbital")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-row", { staticClass: "primary mt-n6", attrs: { dense: "" } }, [
        _c("span", { staticClass: "flavor-text white--text ml-6" }, [
          _vm._v(
            "\n      Union Administrative RM-4 Pilot Identification Protocol (IDENT) Record " +
              _vm._s(_vm.pilot.ID) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ml-2 py-1 px-2" },
        [
          _c("v-row", { attrs: { dense: "" } }, [
            !_vm.pilot.Name
              ? _c("span", { staticClass: "flavor-text" }, [
                  _vm._v(
                    "\n        ERR NAME NOT FOUND UNABLE TO GENERATE UUID\n      "
                  )
                ])
              : _c("span", { staticClass: "flavor-text" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.flipName(_vm.pilot.Name)) +
                      ":" +
                      _vm._s(_vm.pilot.ID) +
                      "//NDL-C-" +
                      _vm._s(_vm.missionName()) +
                      "\n      "
                  )
                ])
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "8" } }, [
                        _c("span", { staticClass: "flavor-text" }, [
                          _vm._v("\n              Callsign:\n              "),
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.pilot.Callsign || "ERR CALLSIGN NOT FOUND"
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n              Name (or legal alias):\n              "
                          ),
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v(
                              _vm._s(_vm.pilot.Name || "ERR NAME NOT FOUND")
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v("\n              Background:\n              "),
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.pilot.Background ||
                                    "PILOT HISTORY NOT REGISTERED"
                                ) +
                                "\n              "
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("v-col", [
                        _c(
                          "span",
                          { staticClass: "flavor-text subtle--text" },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.pilot.Callsign
                                    ? "CALLSIGN AVAILABLE"
                                    : "--"
                                ) +
                                "\n              "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.pilot.Name ? "IDENTITY VERIFIED" : "--"
                                ) +
                                "\n              "
                            ),
                            _c("br"),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.pilot.Background
                                    ? "PH/HR DATA REGISTERED"
                                    : "--"
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "flavor-text mt-2" }, [
                    _vm._v(
                      "\n          FRAME CONFIGURATION OPTIONS\n          "
                    ),
                    _c("span", { staticClass: "subtle--text" }, [
                      _vm._v('("H.A.S.E" OMNINET VAULT REMIT)')
                    ])
                  ]),
                  _vm._v(" "),
                  _c("v-row", { staticClass: "my-1" }, [
                    _c(
                      "span",
                      {
                        staticClass: "flavor-text ml-3",
                        staticStyle: {
                          "font-size": "22px",
                          "line-height": "15px"
                        }
                      },
                      [
                        _vm._v("\n            [ HULL:\n            "),
                        _c(
                          "span",
                          {
                            staticClass: "stat-text accent--text",
                            staticStyle: { "font-size": "24px" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.pilot.MechSkills.Hull) +
                                " \n            "
                            )
                          ]
                        ),
                        _vm._v("\n            AGI:\n            "),
                        _c(
                          "span",
                          {
                            staticClass: "stat-text accent--text",
                            staticStyle: { "font-size": "24px" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.pilot.MechSkills.Agi) +
                                " \n            "
                            )
                          ]
                        ),
                        _vm._v("\n            SYS:\n            "),
                        _c(
                          "span",
                          {
                            staticClass: "stat-text accent--text",
                            staticStyle: { "font-size": "24px" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.pilot.MechSkills.Sys) +
                                " \n            "
                            )
                          ]
                        ),
                        _vm._v("\n            ENG:\n            "),
                        _c(
                          "span",
                          {
                            staticClass: "stat-text accent--text",
                            staticStyle: { "font-size": "24px" }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.pilot.MechSkills.Eng) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v("\n            ]\n          ")
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("span", { staticClass: "flavor-text" }, [
                            _vm._v("PILOT SKILL TRIGGER AUDIT")
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          !_vm.pilot.Skills.length
                            ? _c(
                                "span",
                                { staticClass: "stat-text accent--text" },
                                [
                                  _vm._v(
                                    "\n               ERR SKILL AUDIT INCOMPLETE\n            "
                                  )
                                ]
                              )
                            : _vm._l(_vm.pilot.Skills, function(s) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: s.Skill.ID,
                                    staticClass: "ma-1",
                                    attrs: {
                                      color: "accent",
                                      outlined: "",
                                      label: "",
                                      small: ""
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("cci-skill")
                                    ]),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(s.Skill.Trigger) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                )
                              })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("span", { staticClass: "flavor-text" }, [
                            _vm._v("PILOT TALENT AUDIT")
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          !_vm.pilot.Talents.length
                            ? _c(
                                "span",
                                { staticClass: "stat-text accent--text" },
                                [
                                  _vm._v(
                                    "\n               ERR TALENT AUDIT INCOMPLETE\n            "
                                  )
                                ]
                              )
                            : _vm._l(_vm.pilot.Talents, function(t) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: t.Talent.ID,
                                    staticClass: "ma-1",
                                    attrs: {
                                      color: "accent",
                                      outlined: "",
                                      label: "",
                                      small: ""
                                    }
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("cci-talent")
                                    ]),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(t.Talent.Name) +
                                        " " +
                                        _vm._s("I".repeat(t.Rank)) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                )
                              })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-row", { attrs: { dense: "" } }, [
                    _vm.pilotReady
                      ? _c(
                          "span",
                          { staticClass: "flavor-text subtle--text" },
                          [
                            _c(
                              "v-icon",
                              { attrs: { large: "", color: "grey darken-2" } },
                              [_vm._v("mdi-fingerprint")]
                            ),
                            _vm._v(
                              "\n            BIOMETRIC RECORD VALID [[" +
                                _vm._s(_vm.randomNumber(13, 22)) +
                                "PB]] :: OHM C//" +
                                _vm._s(_vm.futuredate()) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", [
                _c(
                  "div",
                  {
                    staticClass: "border ml-auto mr-auto",
                    staticStyle: { width: "300px", height: "300px" }
                  },
                  [
                    _vm.pilot.Portrait
                      ? _c("v-img", {
                          attrs: {
                            src: _vm.pilot.Portrait,
                            "aspect-ratio": "1",
                            position: "top center"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "ma-2" }),
          _vm._v(" "),
          _c("v-row", { attrs: { dense: "" } }, [
            _c(
              "span",
              {
                staticClass: "overline",
                staticStyle: {
                  "line-height": "13px !important",
                  opacity: "0.4"
                }
              },
              [
                _vm._v(
                  "\n        Improper use of this IDENT record and/or its constituent data by the record holder or any\n        other persons is punishable under the DoJ/HR A-645-c. This record is the property of the\n        Union Administrative Office and the information herein must be transmitted on request\n        under NDL-C-DISCORDANT-BREATH encryption protocols. This RM-4 record must be updated every\n        five (5) Cradle Standard Years of objective time to retain GMS licensing rights. Far-field\n        operatives that anticipate deployments lasting longer than five Cradle Standard Years that\n        have not been issued a man-portable Omninet Hook should apply for the RM-11-B IDENT\n        Supplemental (b) Extension. Contact your local Union Adminstrative Officer for any other\n        matters regarding this record.  V-CDL//M-265-114-831 (A)\n      "
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }