import { render, staticRenderFns } from "./DtResourcesBlock.vue?vue&type=template&id=cff8a942&"
import script from "./DtResourcesBlock.vue?vue&type=script&lang=ts&"
export * from "./DtResourcesBlock.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cff8a942')) {
      api.createRecord('cff8a942', component.options)
    } else {
      api.reload('cff8a942', component.options)
    }
    module.hot.accept("./DtResourcesBlock.vue?vue&type=template&id=cff8a942&", function () {
      api.rerender('cff8a942', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/pilot_management/PilotSheet/sections/narrative/components/DtResourcesBlock.vue"
export default component.exports