var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    [
      _c("p", {
        staticClass: "text-center body-text pt-2",
        domProps: {
          innerHTML: _vm._s(
            "You tweak something or attempt to make something new, either a physical project, or a piece of software. It doesn’t have to be something on the a gear list, but it generally can’t be something as impactful as a piece of mech gear. Once finished, you can use it as <strong>reserves.</strong>"
          )
        }
      }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mb-3" }),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: {
                color: "white",
                "background-color": "primary",
                "slider-color": "white",
                grow: ""
              },
              model: {
                value: _vm.tabs,
                callback: function($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs"
              }
            },
            [
              _c("v-tab", [_vm._v("Start New Project")]),
              _vm._v(" "),
              _c("v-tab", { attrs: { disabled: !_vm.projects.length } }, [
                _vm._v("Continue Project")
              ]),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  _c(
                    "v-card",
                    { staticClass: "ma-3", attrs: { flat: "", tile: "" } },
                    [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            dark: "",
                            flat: "",
                            tile: "",
                            dense: "",
                            color: "action--downtime"
                          }
                        },
                        [
                          _c("v-toolbar-title", { staticClass: "heading h2" }, [
                            _vm._v("New Project")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "pa-4" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "7" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: "Project Name"
                                    },
                                    model: {
                                      value: _vm.project_name,
                                      callback: function($$v) {
                                        _vm.project_name = $$v
                                      },
                                      expression: "project_name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ml-auto mt-n4 mr-3",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c(
                                    "cc-tooltip",
                                    {
                                      attrs: {
                                        simple: "",
                                        inline: "",
                                        content:
                                          "This project is complex, resource-intensive, or generally difficult to complete"
                                      }
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          dense: "",
                                          inset: "",
                                          label: "Complicated"
                                        },
                                        model: {
                                          value: _vm.complicated,
                                          callback: function($$v) {
                                            _vm.complicated = $$v
                                          },
                                          expression: "complicated"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mx-6" },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      "auto-grow": "",
                                      rows: "1",
                                      label: "Details",
                                      filled: ""
                                    },
                                    model: {
                                      value: _vm.details,
                                      callback: function($$v) {
                                        _vm.details = $$v
                                      },
                                      expression: "details"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "number",
                                      label: "Initial Roll Result",
                                      outlined: "",
                                      dense: "",
                                      "append-outer-icon":
                                        "mdi-plus-circle-outline",
                                      "prepend-icon":
                                        "mdi-minus-circle-outline",
                                      "hide-details": ""
                                    },
                                    on: {
                                      "click:append-outer": function($event) {
                                        _vm.initialRoll++
                                      },
                                      "click:prepend": function($event) {
                                        _vm.initialRoll > 1
                                          ? _vm.initialRoll--
                                          : ""
                                      }
                                    },
                                    model: {
                                      value: _vm.initialRoll,
                                      callback: function($$v) {
                                        _vm.initialRoll = $$v
                                      },
                                      expression: "initialRoll"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-slide-y-transition",
                            [
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.initialRoll,
                                      expression: "initialRoll"
                                    }
                                  ],
                                  staticClass: "text-center flavor-text",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c("v-col", { attrs: { cols: "10" } }, [
                                    _vm.initialRoll < 10
                                      ? _c("p", {
                                          staticClass: "font-weight-bold px-3",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              "You don’t make any progress on this project for now"
                                            )
                                          }
                                        })
                                      : _vm.initialRoll < 20
                                      ? _c(
                                          "p",
                                          {
                                            staticClass: "font-weight-bold px-3"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    You can make progress on your project, but can’t finish it. You can finish it\n                    next time you have downtime without a roll if you get some things before then:\n                    "
                                            ),
                                            _c("v-combobox", {
                                              staticClass: "mr-5 ml-5",
                                              attrs: {
                                                items: _vm.costs,
                                                chips: "",
                                                multiple: "",
                                                label:
                                                  "Add at least two requirements:",
                                                error: _vm.cost.length < 2
                                              },
                                              model: {
                                                value: _vm.cost,
                                                callback: function($$v) {
                                                  _vm.cost = $$v
                                                },
                                                expression: "cost"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _c(
                                          "p",
                                          {
                                            staticClass: "font-weight-bold px-3"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.complicated
                                                    ? "You can make progress on your project, but can’t finish it. You can finish it next time you have downtime without a roll if you get some things before then:"
                                                    : "Project Complete"
                                                ) +
                                                "\n                    "
                                            ),
                                            _vm.complicated
                                              ? _c("v-combobox", {
                                                  staticClass: "mr-5 ml-5",
                                                  attrs: {
                                                    items: _vm.costs,
                                                    chips: "",
                                                    multiple: "",
                                                    label:
                                                      "Add at least one requirement:",
                                                    error: !_vm.cost.length
                                                  },
                                                  model: {
                                                    value: _vm.cost,
                                                    callback: function($$v) {
                                                      _vm.cost = $$v
                                                    },
                                                    expression: "cost"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                [
                  !_vm.improveSelection
                    ? _c("v-card-text", [
                        _c(
                          "div",
                          { staticClass: "mx-3" },
                          _vm._l(_vm.projects, function(p, i) {
                            return _c(
                              "v-btn",
                              {
                                key: p.ResourceName + i,
                                staticClass: "my-3",
                                attrs: {
                                  tile: "",
                                  block: "",
                                  dark: "",
                                  color: "action--downtime"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.improveSelection = p
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(p.ResourceName) +
                                    "\n            "
                                )
                              ]
                            )
                          }),
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-slide-x-transition", [
                    _vm.improveSelection
                      ? _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { small: "", text: "" },
                                on: {
                                  click: function($event) {
                                    _vm.improveSelection = null
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("mdi-chevron-left")
                                ]),
                                _vm._v(
                                  "\n              Select another project\n            "
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "text-center heading h2" },
                              [
                                _vm._v(
                                  "\n              Working on " +
                                    _vm._s(_vm.improveSelection.ResourceName) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { attrs: { dense: "", justify: "center" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "8" } },
                                  [
                                    _vm.improveSelection.ResourceCost
                                      ? _c(
                                          "v-alert",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              color: "primary",
                                              dense: "",
                                              outlined: "",
                                              border: "left"
                                            }
                                          },
                                          [
                                            _c(
                                              "b",
                                              { staticClass: "heading h2" },
                                              [_vm._v("COMPLETE IMMEDIATELY")]
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              { staticClass: "pt-2 pb-0 mb-0" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.improveSelection
                                                      .ResourceCost
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "text-right" },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      tile: "",
                                                      color: "success"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.completeProject()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Complete\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.improveSelection.ResourceCost
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center heading h2 accent--text"
                                  },
                                  [_vm._v("\n              OR\n            ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              { attrs: { justify: "center" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "number",
                                        label: "Progress Roll Result",
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        "append-outer-icon":
                                          "mdi-plus-circle-outline",
                                        "prepend-icon":
                                          "mdi-minus-circle-outline"
                                      },
                                      on: {
                                        "click:append-outer": function($event) {
                                          _vm.improveRoll++
                                        },
                                        "click:prepend": function($event) {
                                          _vm.improveRoll > 1
                                            ? _vm.improveRoll--
                                            : ""
                                        }
                                      },
                                      model: {
                                        value: _vm.improveRoll,
                                        callback: function($$v) {
                                          _vm.improveRoll = $$v
                                        },
                                        expression: "improveRoll"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-slide-y-transition",
                              [
                                _c(
                                  "v-row",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.improveRoll,
                                        expression: "improveRoll"
                                      }
                                    ],
                                    staticClass: "text-center flavor-text",
                                    attrs: { justify: "center" }
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "10" } }, [
                                      _vm.improveRoll < 10
                                        ? _c("p", {
                                            staticClass:
                                              "font-weight-bold px-3",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                "You don’t make any progress on this project for now"
                                              )
                                            }
                                          })
                                        : _vm.improveRoll < 20
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-weight-bold px-3"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    You can make progress on your project, but can’t finish it. You can finish it\n                    next time you have downtime without a roll if you get some things before then:\n                    "
                                              ),
                                              _c("v-combobox", {
                                                staticClass: "mr-5 ml-5",
                                                attrs: {
                                                  items: _vm.costs,
                                                  chips: "",
                                                  multiple: "",
                                                  label:
                                                    "Add at least two requirements:",
                                                  error: _vm.cost.length < 2
                                                },
                                                model: {
                                                  value: _vm.cost,
                                                  callback: function($$v) {
                                                    _vm.cost = $$v
                                                  },
                                                  expression: "cost"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "p",
                                            {
                                              staticClass:
                                                "font-weight-bold px-3"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.complicated
                                                      ? "You can make progress on your project, but can’t finish it. You can finish it next time you have downtime without a roll if you get some things before then:"
                                                      : "Project Complete"
                                                  ) +
                                                  "\n                    "
                                              ),
                                              _vm.complicated
                                                ? _c("v-combobox", {
                                                    staticClass: "mr-5 ml-5",
                                                    attrs: {
                                                      items: _vm.costs,
                                                      chips: "",
                                                      multiple: "",
                                                      label:
                                                        "Add at least one requirement:",
                                                      error: !_vm.cost.length
                                                    },
                                                    model: {
                                                      value: _vm.cost,
                                                      callback: function($$v) {
                                                        _vm.cost = $$v
                                                      },
                                                      expression: "cost"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("cancel")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.tabs === 0
            ? _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    tile: "",
                    color: "primary",
                    disabled: !_vm.initialRoll || !_vm.project_name
                  },
                  on: {
                    click: function($event) {
                      return _vm.addProject()
                    }
                  }
                },
                [_vm._v("\n      Add Project\n    ")]
              )
            : _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    tile: "",
                    color: "primary",
                    disabled: !_vm.improveRoll
                  },
                  on: {
                    click: function($event) {
                      !_vm.improveSelection.IsComplicated &&
                      _vm.improveRoll >= 20
                        ? _vm.completeProject()
                        : _vm.improveProject()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        !_vm.improveSelection.IsComplicated &&
                          _vm.improveRoll >= 20
                          ? "Complete Project"
                          : "Update Project"
                      ) +
                      "\n    "
                  )
                ]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }