var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.flat
        ? _c(
            "v-btn",
            {
              attrs: {
                small: _vm.smallBtn || _vm.$vuetify.breakpoint.smAndDown,
                color: _vm.color,
                text: "",
                dark: _vm.dark
              },
              on: {
                click: function($event) {
                  _vm.dialog = true
                }
              }
            },
            [_vm._t("button")],
            2
          )
        : _c(
            "v-btn",
            {
              attrs: {
                tile: "",
                small: _vm.smallBtn || _vm.$vuetify.breakpoint.smAndDown,
                color: _vm.color
              },
              on: {
                click: function($event) {
                  _vm.dialog = true
                }
              }
            },
            [_vm._t("button")],
            2
          ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.fullscreen || _vm.$vuetify.breakpoint.mdAndDown,
            width: _vm.small ? "30vw" : _vm.large ? "80vw" : "50vw"
          },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "background", attrs: { tile: "" } },
            [
              _c(
                "cc-titlebar",
                { attrs: { color: _vm.color } },
                [
                  _vm._t("title"),
                  _vm._v(" "),
                  _vm._t("title-items", null, { slot: "items" })
                ],
                2
              ),
              _vm._v(" "),
              _c("v-card-text", [_vm._t("default")], 2),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _vm.fullscreen ||
              _vm.$vuetify.breakpoint.mdAndDown ||
              (_vm.noConfirm && !_vm.noDismiss)
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v("dismiss")]
                      )
                    ],
                    1
                  )
                : !_vm.noDismiss
                ? _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v("cancel")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("cc-btn", { on: { click: _vm.confirm } }, [
                        _vm._v("confirm")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }