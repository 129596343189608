var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "slot-card-base",
        {
          ref: "base",
          attrs: {
            item: _vm.item,
            mech: _vm.mech,
            readonly: _vm.readonly,
            color: _vm.color,
            empty: !_vm.item
          }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm.item
              ? _c(
                  "span",
                  [
                    _c("equipment-options", {
                      attrs: { item: _vm.item, readonly: _vm.readonly },
                      on: {
                        swap: function($event) {
                          return _vm.$refs.base.$refs.selectorDialog.show()
                        },
                        remove: function($event) {
                          return _vm.remove()
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.item.Destroyed
                      ? _c(
                          "span",
                          { staticClass: "ml-n2" },
                          [
                            _vm.item.Mod
                              ? _c(
                                  "cc-tooltip",
                                  {
                                    attrs: {
                                      inline: "",
                                      content: "Weapon Modification Equipped"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticStyle: { "margin-top": "-2px" },
                                        attrs: { dark: "" }
                                      },
                                      [_vm._v("cci-weaponmod")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.item.Name) +
                                "\n          "
                            ),
                            _vm.item.FlavorName
                              ? _c(
                                  "span",
                                  { staticClass: "caption ml-2 my-n1" },
                                  [_vm._v("//" + _vm._s(_vm.item.TrueName))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "caption subtle--text ml-1" },
                              [
                                _c("b", [_vm._v(_vm._s(_vm.item.Size))]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.item.WeaponType) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c(
                          "span",
                          {
                            staticClass: "py-1 error",
                            staticStyle: { "letter-spacing": "3px" }
                          },
                          [
                            _vm._v("\n           //\n          "),
                            _c("strike", [_vm._v(_vm._s(_vm.item.Name))]),
                            _vm._v("\n          // \n        ")
                          ],
                          1
                        )
                  ],
                  1
                )
              : _c("span", [_vm._v(_vm._s(_vm.weaponSlot.Size) + " Weapon")])
          ]),
          _vm._v(" "),
          _vm.item
            ? _c(
                "div",
                {
                  staticClass: "text-right",
                  attrs: { slot: "header-items" },
                  slot: "header-items"
                },
                [
                  _vm.item.Range
                    ? _c("cc-range-element", {
                        staticClass: "d-inline",
                        attrs: { small: "", range: _vm.getRange, dark: "" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.Range && _vm.item.Damage
                    ? _c("cc-slashes", { staticClass: "px-2" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.Damage
                    ? _c("cc-damage-element", {
                        staticClass: "d-inline",
                        attrs: {
                          small: "",
                          damage: _vm.getDamage,
                          "type-override": _vm.item.DamageTypeOverride
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item && _vm.item.SP
                    ? _c(
                        "div",
                        {
                          staticClass: "d-inline pl-3 ml-3",
                          staticStyle: { "border-left": "1px solid #616161" }
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.item.SP) + "SP")])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.intWeapon && !_vm.readonly
                    ? _c(
                        "div",
                        {
                          staticClass: "d-inline pl-3 ml-3",
                          staticStyle: { "border-left": "1px solid #616161" }
                        },
                        [
                          _vm.item
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "fadeSelect mt-n1",
                                  attrs: { dark: "" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.remove()
                                    }
                                  }
                                },
                                [_vm._v("\n          delete\n        ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-icon", {
                            staticClass: "fadeSelect mt-n1",
                            attrs: { dark: "" },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.item
                                  ? "mdi-swap-vertical-variant"
                                  : "mdi-add"
                              )
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.$refs.base.$refs.selectorDialog.show()
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c(
                    "equipment-header",
                    {
                      attrs: {
                        item: _vm.item,
                        readonly: _vm.readonly,
                        color: _vm.color,
                        "use-bonus": _vm.mech.LimitedBonus
                      }
                    },
                    [
                      !_vm.intWeapon && !_vm.readonly
                        ? _c(
                            "div",
                            { attrs: { slot: "left" }, slot: "left" },
                            [
                              !_vm.item.Mod && !_vm.item.NoMods
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "mb-1",
                                      attrs: {
                                        outlined: "",
                                        small: "",
                                        color: _vm.color
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.$refs.modDialog.show()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: _vm.color, left: "" }
                                        },
                                        [_vm._v("cci-weaponmod")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("NO MOD INSTALLED")])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-n1" },
                    [
                      _vm.item.ProfileEffect
                        ? _c("div", [
                            _c("div", { staticClass: "mb-n2" }, [
                              _c("p", {
                                directives: [
                                  {
                                    name: "html-safe",
                                    rawName: "v-html-safe",
                                    value: _vm.item.ProfileEffect,
                                    expression: "item.ProfileEffect"
                                  }
                                ],
                                staticClass: "text--text body-text mb-1 mx-3"
                              })
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item.ProfileOnAttack
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "mb-n2 mt-1" },
                              [
                                _c("v-icon", { staticClass: "mt-n1" }, [
                                  _vm._v("cci-weapon")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "overline stark--text" },
                                  [_vm._v("ON ATTACK")]
                                ),
                                _vm._v(" "),
                                _c("p", {
                                  directives: [
                                    {
                                      name: "html-safe",
                                      rawName: "v-html-safe",
                                      value: _vm.item.ProfileOnAttack,
                                      expression: "item.ProfileOnAttack"
                                    }
                                  ],
                                  staticClass:
                                    "text--text body-text mb-1 mr-2 ml-6 mt-n2"
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item.ProfileOnHit
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "mb-n2 mt-1" },
                              [
                                _c("v-icon", { staticClass: "mt-n1" }, [
                                  _vm._v("cci-weapon")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "overline stark--text" },
                                  [_vm._v("ON HIT")]
                                ),
                                _vm._v(" "),
                                _c("p", {
                                  directives: [
                                    {
                                      name: "html-safe",
                                      rawName: "v-html-safe",
                                      value: _vm.item.ProfileOnHit,
                                      expression: "item.ProfileOnHit"
                                    }
                                  ],
                                  staticClass:
                                    "text--text body-text mb-1 mr-2 ml-6 mt-n2"
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item.ProfileOnCrit
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "mb-n2 mt-1" },
                              [
                                _c("v-icon", { staticClass: "mt-n1" }, [
                                  _vm._v("cci-weapon")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "overline stark--text" },
                                  [_vm._v("ON CRITICAL HIT")]
                                ),
                                _vm._v(" "),
                                _c("p", {
                                  directives: [
                                    {
                                      name: "html-safe",
                                      rawName: "v-html-safe",
                                      value: _vm.item.ProfileOnCrit,
                                      expression: "item.ProfileOnCrit"
                                    }
                                  ],
                                  staticClass:
                                    "text--text body-text mb-1 mr-2 ml-6 mt-n2"
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item.Mod
                        ? _c(
                            "v-row",
                            { attrs: { dense: "", justify: "center" } },
                            [
                              _c("mod-inset", {
                                attrs: {
                                  mod: _vm.item.Mod,
                                  mech: _vm.mech,
                                  color: _vm.color
                                },
                                on: {
                                  "remove-mod": function($event) {
                                    _vm.item.Mod = null
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("weapon-selector", {
            attrs: {
              slot: "selector",
              "weapon-slot": _vm.weaponSlot,
              mech: _vm.mech
            },
            on: {
              equip: function($event) {
                return _vm.equip($event)
              }
            },
            slot: "selector"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("sh-lock-dialog", {
        ref: "lockDialog",
        attrs: { mount: _vm.mount, mech: _vm.mech },
        on: {
          select: function($event) {
            return _vm.finalizeSuperheavy($event)
          }
        }
      }),
      _vm._v(" "),
      _vm.item
        ? _c(
            "cc-solo-dialog",
            {
              ref: "modDialog",
              attrs: {
                "no-confirm": "",
                title:
                  (_vm.item.Mod ? "Modify" : "Install") +
                  " " +
                  _vm.item.Name +
                  " Modification",
                fullscreen: "",
                "no-pad": ""
              }
            },
            [
              _c("mod-selector", {
                attrs: { weapon: _vm.item, mech: _vm.mech },
                on: {
                  install: function($event) {
                    return _vm.install($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }