var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "my-2" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    target: "_blank",
                    href: "https://forms.gle/pj6o8BzxCe4xGQ5M7",
                    large: "",
                    block: "",
                    color: "error"
                  }
                },
                [_vm._v("\n        report a bug\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "1" } }),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    target: "_blank",
                    href: "https://forms.gle/Gxsu3vaPX1Zs9CX29",
                    large: "",
                    block: "",
                    color: "secondary"
                  }
                },
                [_vm._v("\n        request a feature\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", { staticClass: "heading accent--text" }, [
        _vm._v("Tutorial Mode")
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "panel py-3 text-center subtle--text" },
        [
          _c("v-icon", { attrs: { color: "grey" } }, [_vm._v("mdi-lock")]),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n    // FEATURE IN DEVELOPMENT //\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c("h3", { staticClass: "heading accent--text" }, [_vm._v("FAQ")]),
      _vm._v(" "),
      _c("div", { staticClass: "body-text text--text" }, [
        _c("b", [_vm._v("\n      Where are the NPCs?\n    ")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Now that LANCER is offically released, COMP/CON mirrors the Core Book structure in terms of\n      paid/free content. If you bought the LANCER Core Book, you'll find a downloadble data\n      package that contains all Core NPC data on the LANCER Core Book's itch.io page, which you\n      can access by going to the Content Manager in the main page or in the nav bar options menu\n      and selecting the Core Book item from the LCP Directory tab. You can use the Content\n      Manager's Install LCP tab to import the package and start building and running encounters.\n    "
          )
        ]),
        _vm._v(" "),
        _c("b", [_vm._v("Will COMP/CON get [feature]?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      You can check out what's next for COMP/CON on our\n      "
          ),
          _c("a", {
            attrs: {
              target: "_blank",
              href:
                "https://github.com/massif-press/compcon/issues?q=is%3Aopen+is%3Aissue+label%3Afeature"
            },
            domProps: { innerHTML: _vm._s("GitHub issue tracker") }
          }),
          _vm._v(
            "\n      . If you don't see the feature you're interested in, you can suggest it by clicking the\n      \"Request a Feature\" button above.\n    "
          )
        ]),
        _vm._v(" "),
        _c("b", [_vm._v("Is COMP/CON available on mobile devices?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      That's something we're working on currently. Although it's largely untested, you should be\n      able to use compcon.app in landscape mode on most modern tablets. Expect a more robust\n      tablet mode -- as well as a phone mode -- in the future. Also, COMP/CON can be saved as a\n      Progressive Web App to most mobile devices.\n    "
          )
        ]),
        _vm._v(" "),
        _c("b", [
          _vm._v(
            "Is there any way to get the Wallflower/KTB/other preview mechs in COMP/CON?"
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      Additional content, such as the Wallflower previews are available in the pins of the\n      #comp-con channel of the LANCER Discord. These will be officially released along with their\n      respective books, and downloadable through the LCP Directory.\n    "
          )
        ]),
        _vm._v(" "),
        _c("b", [_vm._v("I see a Roll20 import function, how do I use it?")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      The importer on Roll20's side is not available yet.\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "heading accent--text" }, [
        _vm._v("Additional Help")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "body-text" }, [
        _vm._v(
          "\n    We're currently working on more help resources for COMP/CON, especially for new players and\n    GMs. In the meantime, please stop by\n    "
        ),
        _c("a", {
          attrs: { target: "_blank", href: "https://discord.gg/rwcpzsU" },
          domProps: { innerHTML: _vm._s("the LANCER discord") }
        }),
        _vm._v(
          "\n    (and specifically the #comp-con channel) if you have a question or comment for the developers.\n  "
        )
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "heading accent--text" }, [
        _vm._v("Video Guide")
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "panel py-3 text-center subtle--text" },
        [
          _c("v-icon", { attrs: { color: "grey" } }, [_vm._v("mdi-lock")]),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n    // FEATURE IN DEVELOPMENT //\n  ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }