
















































































































import Vue from 'vue'
import PilotSelector from './PilotSelector.vue'
import { getModule } from 'vuex-module-decorators'
import { MissionStore } from '@/store'
import { Pilot, ActiveMission } from '@/class'

export default Vue.extend({
  name: 'mission-briefing',
  components: { PilotSelector },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    pilots: [],
  }),
  computed: {
    mission() {
      const store = getModule(MissionStore, this.$store)
      return store.Missions.find(x => x.ID === this.id)
    },
    pilotPower() {
      return this.pilots
        .reduce((a, b) => +a + +b.Power, 0)
        .toString()
        .padStart(4, '0')
    },
    avgPower() {
      return this.mission.averagePower.toString().padStart(4, '0')
    },
    maxPower() {
      return this.mission.maxPower.toString().padStart(4, '0')
    },
    difficulty() {
      const diff = this.maxPower - this.pilotPower
      if (diff < -600) return 'Trivial'
      if (diff < -300) return 'Easy'
      if (diff > -300 && diff < 300) return 'Balanced'
      if (diff < 600) return 'Difficult'
      return 'Impossible'
    },
    diffColor() {
      const diff = this.maxPower - this.pilotPower
      if (diff < -600) return 'green'
      if (diff < -300) return 'teal'
      if (diff > -300 && diff < 300) return 'indigo'
      if (diff < 600) return 'deep-orange'
      return 'error'
    },
  },
  methods: {
    addPilot(pilot: Pilot) {
      this.pilots.push(pilot)
      this.$refs.pilotDialog.hide()
    },
    removePilot(pilot: Pilot) {
      const idx = this.pilots.findIndex(x => x.ID === pilot.ID)
      if (idx > -1) this.pilots.splice(idx, 1)
    },
    startMission() {
      const m = new ActiveMission(this.mission, this.pilots)
      const store = getModule(MissionStore, this.$store)
      store.addActiveMission(m)
      this.$store.dispatch('cloudSync', { callback: null, condition: 'missionStart' })
      this.$router.push({ name: 'mission-runner', params: { id: m.ID } })
    },
  },
})
