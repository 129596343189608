var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "pb-0",
      staticStyle: { "margin-top": "40px", "overflow-y": "hidden" },
      attrs: { fluid: "" }
    },
    [
      _c("div", { staticClass: "overline my-n2" }, [
        _c("b", [_vm._v(_vm._s(_vm.mission.Name))]),
        _vm._v(
          "\n    //" +
            _vm._s(_vm.activeMission.Step.toString().padStart(2, "0")) +
            ":" +
            _vm._s(
              _vm.activeMission.Encounter.StepType === "Encounter"
                ? _vm.activeMission.Encounter.Name
                : "Rest"
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _vm.activeMission.Encounter.StepType === "Encounter"
        ? _c("encounter-view", {
            attrs: { "active-mission": _vm.activeMission },
            on: {
              finish: function($event) {
                return _vm.next()
              }
            }
          })
        : _c("rest-view", {
            attrs: {
              "active-mission": _vm.activeMission,
              rest: _vm.activeMission.Encounter
            },
            on: {
              finish: function($event) {
                return _vm.next()
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }