var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-view",
    { ref: "view" },
    [
      _c("cc-gm-header", {
        attrs: {
          slot: "title",
          flip: "",
          color: "secondary",
          title: "NEW NPC // SELECT CLASS"
        },
        slot: "title"
      }),
      _vm._v(" "),
      _c(
        "template",
        { slot: "left" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2 " }),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticStyle: {
                "max-height": "calc(100% - 145px)",
                "overflow-y": "scroll"
              },
              attrs: { dense: "" }
            },
            [
              _c("v-data-table", {
                staticClass: "transparent",
                staticStyle: { "min-width": "100%" },
                attrs: {
                  dense: "",
                  items: _vm.classes,
                  headers: _vm.headers,
                  search: _vm.search,
                  "group-by": "Role",
                  "hide-default-footer": "",
                  "hide-default-header": "",
                  "no-results-text": "No NPC Classes Found",
                  "disable-pagination": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "group.header",
                    fn: function(h) {
                      return [
                        _c("div", { staticClass: "primary sliced" }, [
                          h.group
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "heading white--text ml-2 text-uppercase"
                                },
                                [
                                  h.group.toLowerCase() === "biological"
                                    ? _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v("mdi-heart-pulse")
                                      ])
                                    : _c("v-icon", { attrs: { dark: "" } }, [
                                        _vm._v("cci-role-" + _vm._s(h.group))
                                      ]),
                                  _vm._v(" "),
                                  Array.isArray(h.group)
                                    ? _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(h.group.join(", "))
                                        }
                                      })
                                    : _c("span", {
                                        domProps: { innerHTML: _vm._s(h.group) }
                                      })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.Name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "my-1",
                            attrs: {
                              block: "",
                              outlined: "",
                              tile: "",
                              small: "",
                              color: item.RoleColor
                            },
                            on: {
                              click: function($event) {
                                _vm.selectedClass = item
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.Name) +
                                "\n            "
                            ),
                            _c(
                              "v-scroll-x-transition",
                              { attrs: { "leave-absolute": "" } },
                              [
                                _vm.selectedClass === item
                                  ? _c(
                                      "v-icon",
                                      { attrs: { right: "", color: "accent" } },
                                      [
                                        _vm._v(
                                          "\n                mdi-chevron-triple-right\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mt-2" }),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mb-n10", attrs: { justify: "center", dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        block: "",
                        color: "primary",
                        disabled: !_vm.selectedClass
                      },
                      on: {
                        click: function($event) {
                          return _vm.AddNpc()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v(" "),
                      _vm.selectedClass
                        ? _c("span", [
                            _vm._v("Add New " + _vm._s(_vm.selectedClass.Name))
                          ])
                        : _c("span", [_vm._v("Select NPC Class")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-1",
                      attrs: {
                        outlined: "",
                        block: "",
                        small: "",
                        to: "/gm/npc-roster"
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-chevron-left")
                      ]),
                      _vm._v("\n          Return to NPC Roster\n        ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "right" },
        [
          _c("router-view"),
          _vm._v(" "),
          _vm.selectedClass
            ? _c("class-card", {
                ref: "card",
                attrs: { npcc: _vm.selectedClass }
              })
            : _c(
                "v-row",
                {
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { align: "center", justify: "center" }
                },
                [
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "heading h1 subtle--text text--lighten-2"
                      },
                      [_vm._v("select npc class")]
                    )
                  ])
                ],
                1
              )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }