var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-sidebar-view",
    [
      _vm._l(_vm.statuses, function(e, i) {
        return _c(
          "v-list-item",
          {
            key: i + "_sidebar'",
            attrs: { slot: "sidebar", link: "" },
            on: {
              click: function($event) {
                _vm.$vuetify.goTo("#e_" + e.name.replace(/\W/g, ""), {
                  duration: 150,
                  easing: "easeInOutQuad",
                  offset: 25
                })
              }
            },
            slot: "sidebar"
          },
          [
            _c("v-list-item-title", { staticClass: "heading h3 ml-2" }, [
              _vm._v(_vm._s(e.name))
            ])
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("h1", { staticClass: "heading mt-3 mb-n3" }, [
        _vm._v("STATUSES & EFFECTS")
      ]),
      _vm._v(" "),
      _c(
        "v-container",
        _vm._l(_vm.statuses, function(e, i) {
          return _c(
            "cc-titled-panel",
            {
              key: e.name.replace(/\W/g, "") + "_" + i,
              staticClass: "my-4",
              attrs: {
                id: "e_" + e.name.replace(/\W/g, ""),
                title: e.name,
                dense: ""
              }
            },
            [
              _c("span", {
                staticClass: "flavor-text stark--text",
                attrs: { slot: "items" },
                domProps: { innerHTML: _vm._s(e.type) },
                slot: "items"
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n3", attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-n2", attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { attrs: { size: "75", color: "accent" } }, [
                        _vm._v(
                          "\n            cci-" +
                            _vm._s(e.type.toLowerCase()) +
                            "-" +
                            _vm._s(e.icon.toLowerCase()) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("p", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: e.effects,
                          expression: "e.effects"
                        }
                      ],
                      staticClass: "mb-0 stark--text body-text"
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }