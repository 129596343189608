var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("main-title", {
        on: {
          logupdate: function($event) {
            return _vm.ccLog("update")
          }
        }
      }),
      _vm._v(" "),
      _c("c-c-log", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$vuetify.breakpoint.mdAndUp,
            expression: "$vuetify.breakpoint.mdAndUp"
          }
        ],
        ref: "log"
      }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticStyle: { height: "calc(100vh - 135px)" } },
        [
          _c(
            "v-row",
            {
              staticStyle: { height: "100%" },
              attrs: { justify: "space-between" }
            },
            [
              _c(
                "main-btn",
                {
                  attrs: {
                    icon: "cci-compendium",
                    to: "/compendium",
                    help: "Equipment Database"
                  },
                  on: {
                    hover: function($event) {
                      return _vm.ccLog("compendium")
                    }
                  }
                },
                [_vm._v("\n        Compendium\n      ")]
              ),
              _vm._v(" "),
              _c(
                "main-btn",
                {
                  attrs: {
                    icon: "cci-pilot",
                    to: "/pilot_management",
                    help: "Manage Pilots"
                  },
                  on: {
                    hover: function($event) {
                      return _vm.ccLog("pilot")
                    }
                  }
                },
                [_vm._v("\n        Pilot Roster\n      ")]
              ),
              _vm._v(" "),
              _c(
                "main-btn",
                {
                  attrs: {
                    icon: "cci-encounter",
                    to: "/gm",
                    help: "Manage NPCs/Encounters/Missions"
                  },
                  on: {
                    hover: function($event) {
                      return _vm.ccLog("gm")
                    }
                  }
                },
                [_vm._v("\n        Encounter Toolkit\n      ")]
              ),
              _vm._v(" "),
              _c(
                "main-btn",
                {
                  attrs: {
                    icon: "cci-content-manager",
                    help: "Import Content Packs"
                  },
                  on: {
                    hover: function($event) {
                      return _vm.ccLog("content")
                    },
                    clicked: function($event) {
                      return _vm.$refs.contentModal.show()
                    }
                  }
                },
                [_vm._v("\n        Content Manager\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-footer",
        { attrs: { color: "primary", fixed: "" } },
        [
          _c(
            "v-row",
            {
              attrs: {
                "no-gutters": "",
                justify: "space-around",
                align: "center"
              }
            },
            [
              _c(
                "v-col",
                { staticClass: "text-center mr-3", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pulse",
                      attrs: { small: "", light: "", elevation: "0" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.loginModal.show()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.userstore.IsLoggedIn
                                ? "cci-pilot"
                                : "mdi-account-off-outline"
                            ) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.userstore.IsLoggedIn ? "Connected" : "Log In"
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ml-1 mr-3", attrs: { cols: "auto" } },
                [
                  _c("v-divider", {
                    staticClass: "d-inline",
                    attrs: { vertical: "" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-center mr-3", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", dark: "", outlined: "" },
                      on: { click: _vm.bulkExport }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-database")
                      ]),
                      _vm._v("\n          Create Data Backup\n          "),
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            inline: "",
                            content:
                              "COMP/CON relies on your browser to save and load its data. Settings, utilities, and other applications can erase your browser's localStorage cache, resulting in the loss of your COMP/CON data. IT is <b>strongly</b> recommended to back up your data often."
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "fadeSelect", attrs: { right: "" } },
                            [_vm._v("mdi-help-circle-outline")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "50%" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: { small: "", dark: "", outlined: "" }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-database-refresh")
                                  ]),
                                  _vm._v(
                                    "\n              Load Data Backup\n              "
                                  ),
                                  _c(
                                    "cc-tooltip",
                                    {
                                      attrs: {
                                        inline: "",
                                        content:
                                          "COMP/CON relies on your browser to save and load its data. Settings, utilities, and other applications can erase your browser's localStorage cache, resulting in the loss of your COMP/CON data. IT is <b>strongly</b> recommended to back up your data often."
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "fadeSelect",
                                          attrs: { right: "" }
                                        },
                                        [_vm._v("mdi-help-circle-outline")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.importDialog,
                        callback: function($$v) {
                          _vm.importDialog = $$v
                        },
                        expression: "importDialog"
                      }
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-6" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-center heading h3 text--text"
                                },
                                [
                                  _vm._v(
                                    "\n                This will OVERWRITE\n                "
                                  ),
                                  _c("b", { staticClass: "accent--text" }, [
                                    _vm._v("ALL")
                                  ]),
                                  _vm._v(
                                    "\n                local COMP/CON data.\n                "
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "\n                This\n                "
                                  ),
                                  _c("b", { staticClass: "accent--text" }, [
                                    _vm._v("cannot")
                                  ]),
                                  _vm._v(
                                    "\n                be undone.\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("v-file-input", {
                                attrs: {
                                  accept: ".compcon",
                                  outlined: "",
                                  dense: "",
                                  "hide-details": "",
                                  autofocus: "",
                                  placeholder:
                                    "Select COMP/CON Bulk Export File",
                                  "prepend-icon": "mdi-paperclip"
                                },
                                on: { change: _vm.bulkImport },
                                model: {
                                  value: _vm.fileValue,
                                  callback: function($$v) {
                                    _vm.fileValue = $$v
                                  },
                                  expression: "fileValue"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ml-auto text-right", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "space-between" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", dark: "", text: "" },
                              on: {
                                mouseenter: function($event) {
                                  return _vm.ccLog("options")
                                },
                                click: function($event) {
                                  return _vm.$refs.optionsModal.show()
                                }
                              }
                            },
                            [_vm._v("\n              Options\n            ")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", dark: "", text: "" },
                              on: {
                                mouseenter: function($event) {
                                  return _vm.ccLog("about")
                                },
                                click: function($event) {
                                  return _vm.$refs.aboutModal.show()
                                }
                              }
                            },
                            [_vm._v("\n              About\n            ")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", dark: "", text: "" },
                              on: {
                                mouseenter: function($event) {
                                  return _vm.ccLog("about")
                                },
                                click: function($event) {
                                  return _vm.$refs.creditsModal.show()
                                }
                              }
                            },
                            [_vm._v("\n              Credits\n            ")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", dark: "", text: "" },
                              on: {
                                mouseenter: function($event) {
                                  return _vm.ccLog("help")
                                },
                                click: function($event) {
                                  return _vm.$refs.helpModal.show()
                                }
                              }
                            },
                            [_vm._v("\n              Help\n            ")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                target: "_blank",
                                color: "warning",
                                small: "",
                                dark: "",
                                text: "",
                                href: "https://www.patreon.com/compcon"
                              }
                            },
                            [
                              _vm._v(
                                "\n              Support This Project\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "loginModal",
          attrs: { large: "", "no-confirm": "", title: "CLOUD ACCOUNT" }
        },
        [_c("sign-in")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "optionsModal",
          attrs: {
            large: "",
            "no-confirm": "",
            "no-pad": "",
            "no-title-clip": "",
            title: "Options & User Profile"
          }
        },
        [_c("options-page")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "aboutModal",
          attrs: { large: "", "no-confirm": "", title: "About" }
        },
        [_c("about-page")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "helpModal",
          attrs: { large: "", "no-confirm": "", title: "Help" }
        },
        [_c("help-page")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "creditsModal",
          attrs: { fullscreen: "", "no-confirm": "", title: "Credits" }
        },
        [_c("credits-page")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "contentModal",
          attrs: {
            "no-title-clip": "",
            "no-pad": "",
            large: "",
            "no-confirm": "",
            title: "Manage Content Packs"
          }
        },
        [_c("content-page")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }