var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: {
        overflow: "hidden",
        "margin-top": "48px",
        height: "calc(100vh - 50px)"
      },
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-col",
            [
              _c("home-card", {
                attrs: {
                  title: "Mission Builder",
                  icon: "mdi-wrench-outline",
                  text: "Compose and edit Missions",
                  to: "/gm/mission/builder"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("home-card", {
                attrs: {
                  title: "Mission Runner",
                  icon: "mdi-play-speed",
                  text: "Start a new Mission or continue an active one",
                  to: "/gm/mission/selector"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }