var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-base",
    {
      attrs: { item: _vm.item, active: _vm.active, readonly: _vm.readonly },
      on: {
        "remove-feature": function($event) {
          return _vm.$emit("remove-feature", $event)
        },
        recalc: function($event) {
          return _vm.$emit("recalc")
        }
      }
    },
    [
      _vm.item.Feature.Trigger
        ? _c("span", { staticClass: "overline" }, [_vm._v("TRIGGER")])
        : _vm._e(),
      _vm._v(" "),
      _c("p", {
        directives: [
          {
            name: "html-safe",
            rawName: "v-html-safe",
            value: _vm.item.Feature.Trigger,
            expression: "item.Feature.Trigger"
          }
        ],
        staticClass: "panel body-1 mb-0"
      }),
      _vm._v(" "),
      _c("span", { staticClass: "overline" }, [_vm._v("EFFECT")]),
      _vm._v(" "),
      _vm.item.Tier
        ? _c("p", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.item.Feature.EffectByTier(_vm.item.Tier),
                expression: "item.Feature.EffectByTier(item.Tier)"
              }
            ],
            staticClass: "body-1 mb-0"
          })
        : _c("p", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.item.Feature.Effect,
                expression: "item.Feature.Effect"
              }
            ],
            staticClass: "body-1 mb-0"
          }),
      _vm._v(" "),
      _c(
        "v-col",
        {
          staticClass: "mx-2",
          attrs: { slot: "extra-action", cols: "auto" },
          slot: "extra-action"
        },
        [
          _c(
            "cc-tooltip",
            {
              attrs: { simple: "", content: "Stage Reaction: " + _vm.item.Name }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", small: "", color: "action--reaction" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add-reaction", _vm.item.Name)
                    }
                  }
                },
                [
                  _c("v-icon", [_vm._v("mdi-plus")]),
                  _vm._v(" "),
                  _c("v-icon", [_vm._v("cci-reaction")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.Feature.Tags
        ? _c("cc-tags", { attrs: { tags: _vm.item.Feature.Tags, small: "" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }