var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-solo-dialog",
    {
      ref: "dialog",
      attrs: {
        id: "image-selector-dialog",
        fullscreen: "",
        "no-confirm": "",
        title: "Select Image"
      }
    },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("div", { staticClass: "heading h3 ml-n2" }, [
                    _vm._v("AVAILABLE IMAGES")
                  ]),
                  _vm._v(" "),
                  _vm.type === "mech"
                    ? _c("mech-image-selector", {
                        attrs: { mech: _vm.item },
                        on: {
                          "set-img": function($event) {
                            _vm.selectedImage = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "pilot"
                    ? _c("pilot-image-selector", {
                        attrs: { pilot: _vm.item },
                        on: {
                          "set-img": function($event) {
                            _vm.selectedImage = $event
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "mx-3" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "heading h3 ml-n2" }, [
                    _vm._v("UPLOAD IMAGE")
                  ]),
                  _vm._v(" "),
                  _c("v-file-input", {
                    ref: "fileInput",
                    staticClass: "px-6 mt-2",
                    attrs: {
                      accept: "image/*",
                      dense: "",
                      outlined: "",
                      placeholder: "Select Image",
                      "prepend-icon": "mdi-file-upload-outline",
                      disabled: _vm.loading
                    },
                    on: { change: _vm.onChange }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", [
                      _vm._v(
                        "10MB maximum file size. PNG files over 5MB will be converted to JPEGs."
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c("v-img", {
                      staticClass: "ml-auto mr-auto",
                      style:
                        "image-rendering: " +
                        (_vm.isPixel ? "pixelated" : "crisp-edges") +
                        ";",
                      attrs: {
                        src: _vm.displayImage,
                        contain: "",
                        "max-width": "500px",
                        "max-height": "500px"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "cc-btn",
                      {
                        attrs: {
                          color: "secondary",
                          disabled:
                            !_vm.selectedImage &&
                            (!_vm.imageData || _vm.loading)
                        },
                        on: {
                          click: function($event) {
                            return _vm.saveImage()
                          }
                        }
                      },
                      [
                        !_vm.loading
                          ? [_vm._v("\n              Set Image\n            ")]
                          : [
                              _c("v-progress-circular", {
                                attrs: {
                                  size: "25",
                                  width: "3",
                                  indeterminate: ""
                                }
                              })
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }