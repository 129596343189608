var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "" } },
    [
      _c(
        "div",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "v-card",
            {
              staticClass: "clipped-large panel",
              staticStyle: { height: "100%" },
              attrs: { flat: "", tile: "" }
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "pilot white--text py-0 heading h3",
                  staticStyle: { height: "24px" }
                },
                [
                  _vm.item
                    ? _c(
                        "v-menu",
                        {
                          attrs: { "offset-x": "", left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "fadeSelect mt-n1 ml-n2 mr-1",
                                          attrs: {
                                            icon: "",
                                            small: "",
                                            dark: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n              mdi-settings\n            "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1540686366
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.selectorDialog.show()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "ma-0 mr-2 mt-2" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-swap-vertical-variant")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Change Item")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _vm.item.CanSetDamage
                                ? _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs.damageTypeDialog.show()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-icon",
                                        { staticClass: "ma-0 mr-2 mt-2" },
                                        [
                                          _c("v-icon", [_vm._v("cci-variable")])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v("Select Damage Type")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.cName.show()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "ma-0 mr-2 mt-2" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-circle-edit-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Set Custom Name")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.cDesc.show()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "ma-0 mr-2 mt-2" },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-circle-edit-outline")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Set Custom Description")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("remove")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "ma-0 mr-2 mt-2" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "error" } },
                                        [_vm._v("mdi-delete")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Remove Item")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-n1" },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.title) + "\n          "
                      ),
                      _vm.extended
                        ? _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                simple: "",
                                inline: "",
                                content: "Extended Harness"
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-n1",
                                  attrs: { dark: "", right: "", small: "" }
                                },
                                [_vm._v("mdi-alpha-e-box-outline")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  !_vm.readonly
                    ? _c(
                        "div",
                        { staticClass: "text-right mt-n2" },
                        [
                          _vm.item
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { small: "", icon: "", dark: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("remove")
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { staticClass: "fadeSelect" }, [
                                    _vm._v("delete")
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", small: "", dark: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.selectorDialog.show()
                                }
                              }
                            },
                            [
                              _c("v-icon", {
                                staticClass: "fadeSelect",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.item
                                      ? "mdi-swap-vertical-variant"
                                      : "add"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                {
                  staticClass: "`px-2 py-1 text-center`",
                  staticStyle: { height: "calc(100% - 28px)" },
                  attrs: { id: _vm.item ? "underline-parent" : "" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "underline-slide",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _vm.item
                        ? _c(
                            "div",
                            [
                              _vm._t("default"),
                              _vm._v(" "),
                              _vm.item.Deployables.length
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "overline ml-n2 subtle--text"
                                        },
                                        [_vm._v("EQUIPMENT DEPLOYABLES")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            justify: "center"
                                          }
                                        },
                                        _vm._l(_vm.item.Deployables, function(
                                          d,
                                          i
                                        ) {
                                          return _c(
                                            "v-col",
                                            {
                                              key:
                                                _vm.item.Name +
                                                "_deployable_" +
                                                i,
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c("cc-deployable-info", {
                                                staticClass: "ma-2",
                                                attrs: {
                                                  deployable: d,
                                                  panel: false,
                                                  "name-override": _vm.item.Name
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "py-3 text-center fadeSelect",
                              staticStyle: {
                                cursor: "pointer",
                                height: "100%"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.selectorDialog.show()
                                }
                              }
                            },
                            [
                              _c("v-row", { staticStyle: { height: "100%" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "heading h2 subtle--text my-auto",
                                    staticStyle: { width: "100%" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                // EMPTY //\n              "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "selectorDialog",
          attrs: {
            "no-confirm": "",
            title: "Equip " + _vm.title,
            fullscreen: "",
            "no-pad": ""
          }
        },
        [_vm._t("selector")],
        2
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "detailDialog",
          attrs: {
            "no-confirm": "",
            title: _vm.item ? _vm.item.Name : "",
            large: ""
          }
        },
        [
          _c("cc-item-card", { attrs: { item: _vm.item } }),
          _vm._v(" "),
          _vm._t("detail"),
          _vm._v(" "),
          _vm.item
            ? _c(
                "div",
                [
                  _c("v-textarea", {
                    staticClass: "mt-2",
                    attrs: {
                      outlined: "",
                      "auto-grow": "",
                      rows: "2",
                      filled: "",
                      dense: "",
                      "hide-details": "",
                      "prepend-icon": "mdi-note",
                      label: "Equipment Notes"
                    },
                    model: {
                      value: _vm.item.Note,
                      callback: function($$v) {
                        _vm.$set(_vm.item, "Note", $$v)
                      },
                      expression: "item.Note"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.item
        ? _c("cc-string-edit-dialog", {
            ref: "cName",
            attrs: { placeholder: _vm.item.Name, label: "Custom Item Name" },
            on: {
              save: function($event) {
                _vm.item.Name = $event
              },
              reset: function($event) {
                _vm.item.Name = ""
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item
        ? _c("cc-string-edit-dialog", {
            ref: "cDesc",
            attrs: {
              placeholder: _vm.item.Description,
              label: "Custom Item Description"
            },
            on: {
              save: function($event) {
                _vm.item.Description = $event
              },
              reset: function($event) {
                _vm.item.Description = ""
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item
        ? _c("cc-damage-type-picker", {
            ref: "damageTypeDialog",
            attrs: { "allowed-types": ["Explosive", "Energy", "Kinetic"] },
            on: {
              select: function($event) {
                _vm.item.DamageTypeOverride = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }