var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        { attrs: { text: "", color: "white", tile: "", to: "/gm/npc-roster" } },
        [_vm._v("NPC Roster")]
      ),
      _vm._v(" "),
      _c("v-divider", {
        staticClass: "ml-2 mr-2",
        attrs: { vertical: "", dark: "" }
      }),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: {
            text: "",
            color: "white",
            tile: "",
            to: "/gm/encounter-builder"
          }
        },
        [_vm._v("Encounters")]
      ),
      _vm._v(" "),
      _c("v-divider", {
        staticClass: "ml-2 mr-2",
        attrs: { vertical: "", dark: "" }
      }),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.lgAndUp
        ? _c(
            "v-btn",
            {
              attrs: {
                text: "",
                color: "white",
                tile: "",
                to: "/gm/mission/builder"
              }
            },
            [_vm._v("\n    Mission Builder\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.lgAndUp
        ? _c(
            "v-btn",
            {
              attrs: {
                text: "",
                color: "white",
                tile: "",
                to: "/gm/mission/selector"
              }
            },
            [_vm._v("\n    Mission Runner\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.$vuetify.breakpoint.lgAndUp
        ? _c(
            "v-btn",
            {
              attrs: { text: "", color: "white", tile: "", to: "/gm/mission" }
            },
            [_vm._v("\n    Missions\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-divider", {
        staticClass: "ml-2 mr-2",
        attrs: { vertical: "", dark: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }