var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    { staticClass: "border-highlight" },
    [
      _c(
        "v-expansion-panel-header",
        {
          staticStyle: { height: "65px" },
          attrs: { id: "hover-parent", "hide-actions": "" }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "py-2",
              staticStyle: { position: "absolute", "z-index": "10" }
            },
            [
              _c(
                "v-col",
                { staticClass: "ml-n3 mr-4", attrs: { cols: "1" } },
                [
                  _vm.rank
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "d-inline",
                          attrs: { size: "50", color: "accent" }
                        },
                        [_vm._v("cci-rank-" + _vm._s(_vm.rank))]
                      )
                    : _c("cc-logo", {
                        staticClass: "d-inline",
                        attrs: {
                          size: "xLarge",
                          color: "grey",
                          source: _vm.license.Manufacturer
                        }
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ml-2" },
                [
                  _c("div", { staticClass: "caption" }, [
                    _vm._v(_vm._s(_vm.frame(_vm.license.FrameID).Source))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "heading h2 font-weight-bold pop" },
                    [_vm._v(_vm._s(_vm.license.Name))]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.frame(_vm.license.FrameID).Mechtype, function(f) {
                    return _c(
                      "v-chip",
                      {
                        key: f,
                        staticClass: "mr-2 pop",
                        attrs: {
                          small: "",
                          dark: "",
                          outlined: "",
                          color: "accent"
                        }
                      },
                      [_vm._v("\n          " + _vm._s(f) + "\n        ")]
                    )
                  })
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-img", {
            staticStyle: {
              position: "absolute",
              top: "0",
              right: "0",
              "z-index": "9"
            },
            attrs: {
              id: "img-hover",
              src: _vm.frame(_vm.license.FrameID).DefaultImage,
              "max-height": "100%",
              position:
                "top " +
                _vm.frame(_vm.license.FrameID).YPosition +
                "% left 100px"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-expansion-panel-content",
        { attrs: { color: "panel" } },
        [
          !!_vm.prereq(_vm.license)
            ? _c(
                "v-alert",
                {
                  staticClass: "text-center mx-10 mt-2 mb-n1",
                  attrs: { outlined: "", dense: "", color: "warning" }
                },
                [
                  _vm.prereq(_vm.license).cumulative
                    ? _c("div", [
                        _vm._v(
                          "\n        This License requires at least " +
                            _vm._s(_vm.prereq(_vm.license).min_rank) +
                            " cumulative Ranks of\n        " +
                            _vm._s(_vm.prereq(_vm.license).source) +
                            " licenses\n      "
                        )
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n        This License requires at least one other " +
                            _vm._s(_vm.prereq(_vm.license).source) +
                            " License at Rank\n        " +
                            _vm._s(_vm.prereq(_vm.license).min_rank) +
                            " or above\n      "
                        )
                      ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("cc-license-panel", {
            attrs: { license: _vm.license, ranked: "", rank: _vm.rank }
          }),
          _vm._v(" "),
          _vm.rank < _vm.license.Unlocks.length && _vm.isSelectable
            ? _c(
                "v-btn",
                {
                  attrs: { block: "", outlined: "", color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("cci-accuracy")
                  ]),
                  _vm._v(
                    "\n      Unlock " +
                      _vm._s(_vm.license.Name) +
                      " " +
                      _vm._s("I".repeat(_vm.rank + 1)) +
                      "\n    "
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.rank
            ? _c(
                "v-btn",
                {
                  attrs: { block: "", outlined: "", color: "error" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("remove")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("cci-difficulty")
                  ]),
                  _vm._v(
                    "\n      Remove " +
                      _vm._s(_vm.license.Name) +
                      " " +
                      _vm._s("I".repeat(_vm.rank)) +
                      "\n    "
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }