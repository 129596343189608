var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-n3" },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "pt-2 ml-n6",
              staticStyle: { "max-width": "25vw" },
              attrs: { cols: "auto" }
            },
            [
              _c(
                "v-list",
                {
                  staticClass: "side-fixed pr-2",
                  attrs: { dense: "", color: "panel" }
                },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { color: "accent" },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    },
                    _vm._l(_vm.items, function(i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i.ID + "_sidebar'",
                          attrs: { value: i.ID, dense: "" }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              i.IsExotic
                                ? _c("v-icon", { attrs: { color: "exotic" } }, [
                                    _vm._v(
                                      "\n                mdi-star\n              "
                                    )
                                  ])
                                : i.Source && i.Manufacturer
                                ? _c("cc-logo", {
                                    staticClass: "mb-n1",
                                    attrs: { source: i.Manufacturer }
                                  })
                                : _c("v-icon", [_vm._v("cci-trait")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            { staticClass: "ml-n6 mb-n1" },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "heading h3 stark--text font-weight-bold",
                                  staticStyle: { "font-size": "15px" }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(i.Name) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { staticClass: "pl-7 mr-7" }, [
            !_vm.selectedItem
              ? _c(
                  "div",
                  {
                    staticClass: "heading h2 light-panel--text text-center",
                    staticStyle: { "margin-top": "calc(50vh - 150px)" }
                  },
                  [_vm._v("\n        NO SELECTION\n      ")]
                )
              : _c(
                  "div",
                  { staticClass: "side-fixed" },
                  [
                    _c("div", { staticClass: "heading h1 stark--text" }, [
                      _vm._v(_vm._s(_vm.selectedItem.Name))
                    ]),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "mt-4 mb-1" }),
                    _vm._v(" "),
                    _c("cc-item-card", { attrs: { item: _vm.selectedItem } })
                  ],
                  1
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }