var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-notification",
    { ref: "note", attrs: { color: _vm.noteColor } },
    [_c("span", { domProps: { innerHTML: _vm._s(_vm.notification) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }