var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      staticClass: "row justify-start pr-4",
      attrs: { name: "fade-transition", duration: 250, tag: "div" }
    },
    [
      _vm._l(
        _vm.actors.flatMap(function(x) {
          return x.CounterData
        }),
        function(cd) {
          return _c(
            "v-col",
            { key: cd.id, attrs: { cols: "auto" } },
            [
              _c("counter-component", {
                attrs: {
                  "counter-data": cd,
                  "save-data": _vm.actors[0].CounterSaveData
                },
                on: {
                  delete: function($event) {
                    return _vm.deleteCustom(cd.id)
                  },
                  update: function($event) {
                    return _vm.updateCounter($event)
                  }
                }
              })
            ],
            1
          )
        }
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { key: "NewCounter", attrs: { cols: "auto" } },
        [_c("new-counter", { on: { create: _vm.onCustomCounterCreate } })],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }