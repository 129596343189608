


















































































import CloudManager from '../../../components/CloudManager.vue'
import activePilot from '@/features/pilot_management/mixins/activePilot'
import { Auth } from 'aws-amplify'
import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'ident-block',
  components: { CloudManager },

  data: () => ({
    pilotStatuses: [
      { text: 'Active', value: 'ACTIVE' },
      { text: 'Inactive', value: 'INACTIVE' },
      { text: 'Retired', value: 'RET' },
      { text: 'Missing In Action', value: 'MIA' },
      { text: 'Killed In Action', value: 'KIA' },
      { text: 'Unknown', value: 'UNKNOWN' },
    ],
    noteColor: '',
    notification: '',
    syncing: false,
    currentAuthedUser: null,
  }),
  async mounted() {
    await Auth.currentAuthenticatedUser().then(res => {
      this.currentAuthedUser = !!res.username
    })
  },
  methods: {
    statusColor(): string {
      switch (this.pilot.Status.toLowerCase()) {
        case 'active':
          return 'success'
          break
        case 'mia':
        case 'kia':
          return 'error'
        default:
          return 'text'
          break
      }
    },
    async copyCode() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      this.copyConfirm = true
      navigator.clipboard
        .writeText(this.pilot.GistCode)
        .then(() => self.$notify('Cloud share code copied to clipboard.', 'success'))
        .catch(() => self.$notify('Unable to copy cloud share code', 'error'))
    },
    async copyVault() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this
      this.copyConfirm = true
      navigator.clipboard
        .writeText(this.pilot.ShareCode)
        .then(() => self.$notify('Vault sync code copied to clipboard.', 'success'))
        .catch(() => self.$notify('Unable to copy vault sync code', 'error'))
    },
    sync() {
      this.syncing = true
      this.$refs.cloud.sync()
    },
  },
})
