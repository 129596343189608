var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("h1", { staticClass: "heading mb-3 ml-5" }, [_vm._v("REFERENCE")]),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "effect-text", attrs: { fluid: "" } },
        [
          _c("span", { staticClass: "heading mech" }, [
            _vm._v("Structure Damage & Overheating")
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "red darken-3", dark: "" } },
                    [_c("v-toolbar-title", [_vm._v("STRUCTURE DAMAGE")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Roll")
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("Result")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Effect")
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-5")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-minus")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-6")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("Glancing Blow")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Emergency systems kick in and stabilize your mech. However, your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("impaired")]),
                                    _vm._v(
                                      "\n                  until the end of your next turn.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-2")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-minus")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-4")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("System Trauma")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Parts of your mech are torn off by the damage. Roll a d6. On a 1-3, all the\n                  weapons on one mount are destroyed. On a 4-6, a system is destroyed. You choose\n                  what’s destroyed, but systems or weapons with the limited tag and no charges\n                  left are not valid. If there’s nothing left of one result, it becomes the other.\n                  If there’s absolutely nothing left to destroy, this result becomes DIRECT HIT\n                  instead.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("Direct Hit")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  This result has different outcomes depending on how much structure your mech has\n                  remaining.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  3+ - Your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("stunned")]),
                                    _vm._v(
                                      "\n                  until the end of your next turn.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  2 - Your mech must pass a\n                  "
                                    ),
                                    _c("b", [_vm._v("hull")]),
                                    _vm._v(
                                      "\n                  check or be\n                  "
                                    ),
                                    _c("b", [_vm._v("destroyed.")]),
                                    _vm._v(
                                      "\n                  Even on a successful check, your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("stunned")]),
                                    _vm._v(
                                      "\n                  until the end of your next turn.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  1 or lower - Your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("destroyed.")])
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("Crushing Hit")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Your mech is damaged beyond repair and is\n                  "
                                    ),
                                    _c("b", [_vm._v("destroyed")]),
                                    _vm._v(
                                      "\n                  . You can still exit it as normal.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-4", attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "orange darken-4", dark: "" } },
                    [_c("v-toolbar-title", [_vm._v("OVERHEATING")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Roll")
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("Result")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Effect")
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-5")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-minus")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-6")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("Emergency Shunt")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Cooling systems recover and manage to contain the peaking heat levels. However,\n                  your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("impaired")]),
                                    _vm._v(
                                      "\n                  until the end of your next turn.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-2")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-minus")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-4")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("Power Plant Destabilize")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Your mech’s power plant becomes unstable, ejecting jets of plasma. Your mech\n                  suffers from the\n                  "
                                    ),
                                    _c("b", [_vm._v("exposed")]),
                                    _vm._v(
                                      "\n                  status (taking double damage) until it takes action to remove it.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("Meltdown")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  This result has different outcomes depending on how much reactor stress your\n                  mech has remaining.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  3+ - Your mech immediately suffers from the\n                  "
                                    ),
                                    _c("b", [_vm._v("exposed")]),
                                    _vm._v(
                                      "\n                  condition until it takes action to remove it.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  2 - Your mech must pass a engineering check or suffer a reactor meltdown at the\n                  end of 1d6 turns after this one (rolled by the GM). You can reverse it by taking\n                  a full action and repeating this check. Even on a successful check, your mech\n                  suffers from the\n                  "
                                    ),
                                    _c("b", [_vm._v("exposed")]),
                                    _vm._v(
                                      "\n                  condition until it takes action to remove it.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  1 or lower - Your mech suffers a reactor meltdown at the end of your next turn.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.smAndUp
                                ? _c("v-col", { attrs: { cols: "3" } }, [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v("Irreversible Meltdown")
                                    ])
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Your reactor goes critical. Your mech will suffer a reactor meltdown at the end\n                  of your next turn.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c("p", { staticClass: "ma-3 flavor-text" }, [
                            _vm._v(
                              "\n              Certain overheating results can cause a reactor meltdown. This can be immediate, or\n              involve a countdown (in which case update the countdown at the start of your turn.\n              The meltdown triggers when specified). When a mech suffers a reactor meltdown, any\n              pilot inside immediately dies, the mech is immediately vaporized in a catastrophic\n              eruption, annihilating the wreck, and any characters inside a burst 2 area centered\n              on the mech must pass an agility save or take 4d6 explosive damage, and half on a\n              successful save\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-4", attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "yellow darken-4", dark: "" } },
                    [_c("v-toolbar-title", [_vm._v("DOWN AND OUT")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Roll")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "10" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Effect")
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-6")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "10" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  On 6, your pilot barely shrugs off the hit (or it’s a close call) – they return\n                  to 1 HP.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-2")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-minus")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-5")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "10" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  On 2–5, your pilot gains the DOWN AND OUT status (and the STUNNED condition) and\n                  remains at 0 HP. They are unconscious, pinned, bleeding out, or otherwise unable\n                  to act. If you’re in mech combat, they are Stunned and their EVASION drops to 5.\n                  If they take any more damage – from being shot in the head, for instance – they\n                  die.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  You can choose for your pilot to die instead of becoming DOWN AND OUT.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "10" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  On 1, your pilot’s luck has run out – they die immediately.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c("p", { staticClass: "ma-3 flavor-text" }, [
                            _vm._v(
                              "If your pilot is ever reduced to 0 HP, roll 1d6"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }