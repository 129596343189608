var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-title",
        {
          staticClass: "pl-3",
          staticStyle: { "margin-left": "-50px!important" },
          attrs: { small: "", color: "pilot" }
        },
        [_c("span", { staticClass: "pl-12" }, [_vm._v("Special Equipment")])]
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "specialSelector",
          attrs: {
            icon: "mdi-star-circle-outline",
            "no-confirm": "",
            title: "Manage Special Equipment",
            fullscreen: ""
          }
        },
        [
          _c("cc-equipment-selector", {
            attrs: { pilot: _vm.pilot },
            on: {
              select: function($event) {
                return _vm.addItem($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "exoticSelector",
          attrs: {
            icon: "mdi-star-circle-outline",
            "no-confirm": "",
            title: "Manage Exotic Equipment",
            fullscreen: ""
          }
        },
        [
          _c("cc-exotic-selector", {
            attrs: { pilot: _vm.pilot },
            on: {
              select: function($event) {
                return _vm.addItem($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "mb-2", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { tile: "", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.specialSelector.show()
                        }
                      }
                    },
                    [_vm._v("Add Standard Equipment")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { tile: "", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.exoticSelector.show()
                        }
                      }
                    },
                    [_vm._v("Add Exotic Equipment")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.pilot.SpecialEquipment.length
            ? _c("no-data-block")
            : _c(
                "v-row",
                { attrs: { dense: "", justify: "space-around" } },
                _vm._l(_vm.pilot.SpecialEquipment, function(i) {
                  return _c(
                    "v-col",
                    {
                      key: "special_" + i.ID,
                      staticClass: "text-center",
                      attrs: { cols: "12", md: "4" }
                    },
                    [
                      _c("cc-item-modal", {
                        staticStyle: { display: "inline-block" },
                        attrs: { item: i }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect",
                          staticStyle: { display: "inline-block" },
                          attrs: { icon: "", small: "" },
                          on: {
                            click: function($event) {
                              return _vm.removeItem(i)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }