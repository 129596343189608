var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("action-detail-expander", { attrs: { action: _vm.action } }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { attrs: { dense: "", justify: "space-around" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "auto" } },
                    [
                      _c("div", { staticClass: "heading h3 text-center" }, [
                        _vm._v("Choose one of the following:")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { dense: "", "hide-details": "", colum: "" },
                          model: {
                            value: _vm.state.StabilizeMajor,
                            callback: function($$v) {
                              _vm.$set(_vm.state, "StabilizeMajor", $$v)
                            },
                            expression: "state.StabilizeMajor"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label:
                                "Cool Mech, resetting the heat gauge and ending the EXPOSED status",
                              value: "cool",
                              color: "accent"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label:
                                "Spend 1 Repair to restore HP to maximum. " +
                                (!_vm.mech.CurrentRepairs
                                  ? " // REPAIR CAPACITY EXHAUSTED //"
                                  : ""),
                              value: "repair",
                              color: "accent",
                              disabled: !_vm.mech.CurrentRepairs
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [_c("v-divider", { attrs: { vertical: "" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "auto" } },
                    [
                      _c("div", { staticClass: "heading h3 text-center" }, [
                        _vm._v("And one of the following:")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            color: "accent",
                            dense: "",
                            "hide-details": "",
                            column: ""
                          },
                          model: {
                            value: _vm.state.StabilizeMinor,
                            callback: function($$v) {
                              _vm.$set(_vm.state, "StabilizeMinor", $$v)
                            },
                            expression: "state.StabilizeMinor"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: "Reload all weapons with the LOADING Tag",
                              value: "reload",
                              color: "accent"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label:
                                "End all BURN currently affecting your mech",
                              value: "end_burn",
                              color: "accent",
                              disabled: _vm.mech.Burn === 0
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label: "End a condition affecting your mech",
                              value: "end_self_condition",
                              color: "accent",
                              disabled: !_vm.mech.Conditions.length
                            }
                          }),
                          _vm._v(" "),
                          _c("v-radio", {
                            attrs: {
                              label:
                                "End a condition affecting an adjacent ally",
                              value: "end_ally_condition",
                              color: "accent"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    tile: "",
                    block: "",
                    dark: "",
                    disabled: !_vm.canUse,
                    color: _vm.action.Color
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("use")
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.action.Icon))
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.action.Name) + "\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _vm.action.Activation !== "Free"
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        dark: "",
                        tile: "",
                        block: "",
                        disabled: !_vm.canUse,
                        color: "action--free"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("use", true)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "", small: "" } }, [
                        _vm._v("cci-free-action")
                      ]),
                      _vm._v("\n        Free Action\n        "),
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            inline: "",
                            content:
                              "Special rules or equipment may allow you to " +
                              _vm.action.Name +
                              " as a Free Action. Using this button will commit the action without spending a " +
                              _vm.action.Activation +
                              " Action this turn"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "fadeSelect",
                              attrs: { right: "", small: "" }
                            },
                            [_vm._v("mdi-information-outline")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }