var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-alert",
        {
          staticClass: "my-2",
          attrs: {
            outlined: "",
            prominent: "",
            icon: "mdi-information-outline"
          }
        },
        [
          _c("div", [_c("b", [_vm._v("Cloud Account")])]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "\n      The e-mail address input below will be used to send you a confirmation code to finalize the\n      creation of your account. From there, your e-mail will only be used to log in to your\n      COMP/CON account. We are committed to keeping your e-mail address confidential. We do not\n      sell, rent, or lease our contact data or lists to third parties, and we will not provide\n      your personal information to any third party individual, government agency, or company at\n      any time.\n    "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "panel",
          attrs: { dense: "", justify: "center", align: "center" }
        },
        [
          _c(
            "v-col",
            {
              staticStyle: { "letter-spacing": "5px" },
              attrs: { cols: "auto" }
            },
            [_vm._v("CREATE ACCOUNT")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { lg: "4", cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "E-Mail Address",
                      rules: [_vm.rules.required, _vm.rules.emailMatch],
                      solo: ""
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { lg: "4", cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Password",
                      "append-icon": _vm.show ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.show ? "text" : "password",
                      solo: "",
                      rules: [_vm.rules.required, _vm.rules.min]
                    },
                    on: {
                      "click:append": function($event) {
                        _vm.show = !_vm.show
                      }
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        color: "secondary",
                        loading: _vm.loading,
                        disabled: !_vm.submitOk
                      },
                      on: { click: _vm.createAccount }
                    },
                    [_vm._v("\n          submit\n        ")]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-1",
                      attrs: { text: "", color: "accent" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("set-state", "sign-in")
                        }
                      }
                    },
                    [_vm._v("\n          Cancel\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-scroll-y-transition",
        { attrs: { "leave-absolute": "", "hide-on-leave": "" } },
        [
          _vm.error
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-2",
                  attrs: {
                    color: "error darken-1",
                    dark: "",
                    dense: "",
                    icon: "mdi-alert",
                    dismissible: ""
                  },
                  model: {
                    value: _vm.showError,
                    callback: function($$v) {
                      _vm.showError = $$v
                    },
                    expression: "showError"
                  }
                },
                [
                  _c("div", { staticClass: "font-weight-bold" }, [
                    _vm._v("ERROR")
                  ]),
                  _vm._v(" "),
                  _c("div", { domProps: { innerHTML: _vm._s(_vm.error) } })
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }