var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols } },
    [
      _c(
        "v-card",
        { staticClass: "text-center", attrs: { tile: "", outlined: "" } },
        [
          _c(
            "v-card-title",
            {
              class: _vm.color + " white--text caption pa-1",
              staticStyle: {
                "font-weight": "bold",
                "max-height": "28px",
                "font-size": "15px!important"
              }
            },
            [
              _vm._v("\n      SIZE\n      "),
              _c(
                "v-menu",
                {
                  attrs: {
                    value:
                      _vm.editable ||
                      (_vm.selectable && _vm.stats.Sizes.length > 1),
                    right: "",
                    "offset-y": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              {
                                staticClass: "fadeSelect",
                                attrs: { dark: "", small: "" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.editable
                                      ? "mdi-circle-edit-outline"
                                      : "mdi-resize"
                                  )
                                }
                              },
                              on
                            )
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.menu,
                    callback: function($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu"
                  }
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-2" },
                        _vm._l(
                          _vm.editable ? _vm.allSizes : _vm.stats.Sizes,
                          function(i) {
                            return _c(
                              "v-btn",
                              {
                                key: "size_" + i,
                                staticClass: "ma-3",
                                staticStyle: { width: "80px", height: "80px" },
                                attrs: { icon: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.stats.Size = i
                                  }
                                }
                              },
                              [
                                i === 0.5
                                  ? _c("v-icon", { attrs: { size: "80" } }, [
                                      _vm._v("cci-size-half")
                                    ])
                                  : _c("v-icon", { attrs: { size: "80" } }, [
                                      _vm._v("cci-size-" + _vm._s(i))
                                    ])
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "pa-1 text--text" }, [
            _c("span", { staticClass: "heading h2" }, [
              _vm._v(
                _vm._s(
                  parseFloat(_vm.stats.Size) === 0.5 ? "½" : _vm.stats.Size
                )
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }