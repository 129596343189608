














































































































import Vue from 'vue'
import _ from 'lodash'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { Pilot, Frame, Mech } from '@/class'
import { mechname } from '@/io/Generators'
import ItemFilter from '@/classes/utility/ItemFilter'

export default Vue.extend({
  name: 'new-mech-menu',
  props: {
    pilot: Pilot,
  },
  data: () => ({
    mechName: null,
    showAll: false,
    frames: [],
    selectedFrame: null,
    filters: {},
  }),
  computed: {
    filteredFrames() {
      let i = this.frames as Frame[]

      if (!this.showAll)
        i = i.filter(
          x =>
            !x.IsExotic &&
            (this.pilot.has('License', x.Name, 2) ||
              this.pilot.has('License', x.Variant, 2) ||
              !x.LicenseLevel)
        )

      if (Object.keys(this.filters).length) {
        i = ItemFilter.Filter(i, this.filters) as Frame[]
      }

      return i
        .map(x => x.ID)
        .concat(this.pilot.SpecialEquipment.filter(x => x.ItemType === 'Frame').map(f => f.ID))
    },
  },
  mounted() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.frames = _.sortBy(
      compendium.Frames.filter(x => !x.IsHidden),
      ['Source', 'Name']
    )
  },
  methods: {
    setFilters(newFilter) {
      this.filters = newFilter
    },
    selectIncl(id: string) {
      return this.filteredFrames.includes(id)
    },
    randomName() {
      this.mechName = mechname()
    },
    addMech() {
      const f = this.frames[this.selectedFrame]
      const newMech = new Mech(f, this.pilot)
      newMech.Name = this.mechName
      this.pilot.AddMech(newMech)
      this.mechName = null
      this.selectedFrame = null
      this.showAll = false
      this.$emit('close')
    },
  },
})
