var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pa-2" },
    [
      _c(
        "div",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "v-card",
            {
              staticClass: "clipped-large panel",
              staticStyle: { height: "100%" },
              attrs: { flat: "", tile: "" }
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "white--text py-0 heading h3 hover-item",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function($event) {
                      _vm.empty ? "" : _vm.$refs.detailDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticStyle: { display: "flex", width: "100%" } },
                    [
                      _vm._t("header"),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm._t("header-items")
                    ],
                    2
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                {
                  staticClass: "`px-2 py-0 text-center`",
                  attrs: { id: _vm.item ? "underline-parent" : "" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "underline-slide" },
                    [
                      _vm._t("default"),
                      _vm._v(" "),
                      _vm.item
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "text-left",
                                  attrs: { dense: "", align: "end" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            justify: "space-around",
                                            dense: ""
                                          }
                                        },
                                        [
                                          _vm.item.Actions &&
                                          _vm.item.Actions.length
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  !_vm.readonly
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "overline ml-n2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "EQUIPMENT ACTIONS"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                        justify: "center"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.item.Actions,
                                                      function(a, i) {
                                                        return _c(
                                                          "v-col",
                                                          {
                                                            key:
                                                              _vm.item.Name +
                                                              "_action_" +
                                                              i,
                                                            attrs: {
                                                              cols: "auto"
                                                            }
                                                          },
                                                          [
                                                            _c("cc-action", {
                                                              staticClass:
                                                                "ma-2",
                                                              attrs: {
                                                                action: a,
                                                                panel:
                                                                  !_vm.readonly &&
                                                                  _vm.$vuetify
                                                                    .breakpoint
                                                                    .lgAndUp
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.item.Deployables.length
                                            ? _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  !_vm.readonly
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "overline ml-n2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "EQUIPMENT DEPLOYABLES"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                        justify: "center"
                                                      }
                                                    },
                                                    _vm._l(
                                                      _vm.item.Deployables,
                                                      function(d, i) {
                                                        return _c(
                                                          "v-col",
                                                          {
                                                            key:
                                                              _vm.item.Name +
                                                              "_deployable_" +
                                                              i,
                                                            attrs: {
                                                              cols: "auto"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "cc-deployable-info",
                                                              {
                                                                staticClass:
                                                                  "ma-2",
                                                                attrs: {
                                                                  deployable: d,
                                                                  panel:
                                                                    !_vm.readonly &&
                                                                    _vm.$vuetify
                                                                      .breakpoint
                                                                      .lgAndUp,
                                                                  "name-override":
                                                                    _vm.item
                                                                      .Name
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mr-3 mt-n2",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _vm.item.Tags && !_vm.item.ProfileTags
                                        ? _c("cc-tags", {
                                            attrs: {
                                              small: "",
                                              tags: _vm.item.Tags,
                                              color: _vm.color,
                                              bonus: _vm.mech.Pilot.LimitedBonus
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("cc-tags", {
                                        attrs: {
                                          small: "",
                                          tags: _vm.item.ProfileTags,
                                          color: _vm.color,
                                          bonus: _vm.mech.Pilot.LimitedBonus
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.item.Mod
                                        ? _c("cc-tags", {
                                            attrs: {
                                              small: "",
                                              tags: _vm.item.Mod.AddedTags,
                                              color: "mod darken-2",
                                              bonus: _vm.mech.Pilot.LimitedBonus
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c("cc-bonus-display", {
                                        attrs: { item: _vm.item }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c("cc-synergy-display", {
                                        attrs: {
                                          item: _vm.item,
                                          location: _vm.synergyLocation,
                                          mech: _vm.mech,
                                          large: ""
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "py-3 text-center fadeSelect",
                              staticStyle: { height: "100%" },
                              style: {
                                cursor: _vm.readonly ? "inherit" : "pointer"
                              },
                              on: {
                                click: function($event) {
                                  if (!_vm.readonly) {
                                    _vm.$refs.selectorDialog.show()
                                  }
                                }
                              }
                            },
                            [
                              _c("v-row", { staticStyle: { height: "100%" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "heading h2 subtle--text my-auto py-3",
                                    staticStyle: { width: "100%" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                // EMPTY //\n              "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                    ],
                    2
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "selectorDialog",
          attrs: {
            "no-confirm": "",
            title: "SELECT EQUIPMENT",
            fullscreen: "",
            "no-pad": ""
          }
        },
        [_vm._t("selector")],
        2
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "detailDialog",
          attrs: {
            "no-confirm": "",
            title: _vm.item ? _vm.item.Name : "",
            large: ""
          }
        },
        [
          _c("cc-item-card", { attrs: { item: _vm.item, notes: "" } }),
          _vm._v(" "),
          _vm._t("detail")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }