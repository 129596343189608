var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      style: _vm.$vuetify.breakpoint.mdAndDown ? "x-overflow: hidden" : "",
      attrs: { fullscreen: _vm.$vuetify.breakpoint.mdAndDown, width: "90vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { tile: "" } },
        [
          _c("action-titlebar", {
            attrs: { action: _vm.action, mech: _vm.mech },
            on: {
              hide: function($event) {
                return _vm.hide()
              }
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pt-4" },
            [
              _c("cc-active-synergy", {
                staticClass: "mb-n4",
                attrs: {
                  locations: _vm.action.SynergyLocations,
                  mech: _vm.mech
                }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("action-detail-expander", {
                        attrs: { action: _vm.action }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            tile: "",
                            dark: "",
                            block: "",
                            disabled: _vm.actionFree,
                            color:
                              _vm.action.Color +
                              " " +
                              (_vm.actionCost ? "lighten-1" : "")
                          },
                          on: {
                            click: function($event) {
                              _vm.actionCost = !_vm.actionCost
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(_vm._s(_vm.action.Icon))
                          ]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.action.Name) +
                              "\n          "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.action.Activation !== "Free"
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                tile: "",
                                dark: "",
                                block: "",
                                disabled: _vm.actionCost,
                                color:
                                  "action--free " +
                                  (_vm.actionFree ? "lighten-1" : "")
                              },
                              on: {
                                click: function($event) {
                                  _vm.actionFree = !_vm.actionFree
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "", small: "" } }, [
                                _vm._v("cci-free-action")
                              ]),
                              _vm._v("\n            Free Action\n            "),
                              _c(
                                "cc-tooltip",
                                {
                                  attrs: {
                                    inline: "",
                                    content:
                                      "Special rules or equipment may allow you to " +
                                      _vm.action.Name +
                                      " as a Free Action. Using this button will commit the action without spending a " +
                                      _vm.action.Activation +
                                      " Action this turn"
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "fadeSelect",
                                      attrs: { right: "", small: "" }
                                    },
                                    [_vm._v("mdi-information-outline")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.mech.Pilot.State.IsJockeying
                ? _c(
                    "div",
                    [
                      _c(
                        "v-slide-x-reverse-transition",
                        [
                          _vm.actionFree || _vm.actionCost
                            ? _c(
                                "v-row",
                                {
                                  attrs: { justify: "center", align: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-n5",
                                      attrs: { lg: "auto", md: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "text-center mb-n3",
                                          attrs: {
                                            dense: "",
                                            justify: "start",
                                            align: "start"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ml-auto px-12 panel dual-sliced",
                                              staticStyle: { height: "70px" },
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "overline mt-n2 pl-4 mr-n4"
                                                },
                                                [_vm._v("Contested HULL")]
                                              ),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass:
                                                  "hide-input-spinners mt-n1 ml-10",
                                                staticStyle: { width: "60px" },
                                                attrs: {
                                                  type: "number",
                                                  color: "accent",
                                                  dense: "",
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    _vm.hull = parseInt($event)
                                                  }
                                                },
                                                model: {
                                                  value: _vm.hull,
                                                  callback: function($$v) {
                                                    _vm.hull = $$v
                                                  },
                                                  expression: "hull"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "ml-auto",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: { dense: "", justify: "end" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ml-auto px-12 mr-n10 panel dual-sliced",
                                              staticStyle: { height: "70px" },
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "overline mt-n2 pl-1"
                                                },
                                                [_vm._v("Accuracy")]
                                              ),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass:
                                                  "hide-input-spinners",
                                                staticStyle: { width: "115px" },
                                                attrs: {
                                                  type: "number",
                                                  "append-outer-icon":
                                                    "mdi-plus-circle-outline",
                                                  "append-icon": "cci-accuracy",
                                                  "prepend-icon":
                                                    "mdi-minus-circle-outline",
                                                  color: "accent",
                                                  dense: "",
                                                  "hide-details": "",
                                                  disabled: _vm.actionUsed
                                                },
                                                on: {
                                                  "click:append-outer": function(
                                                    $event
                                                  ) {
                                                    _vm.accuracy < 99
                                                      ? (_vm.accuracy += 1)
                                                      : ""
                                                  },
                                                  "click:prepend": function(
                                                    $event
                                                  ) {
                                                    _vm.accuracy >
                                                    _vm.minAccuracy
                                                      ? (_vm.accuracy -= 1)
                                                      : ""
                                                  },
                                                  change: function($event) {
                                                    _vm.accuracy = parseInt(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.accuracy,
                                                  callback: function($$v) {
                                                    _vm.accuracy = $$v
                                                  },
                                                  expression: "accuracy"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "px-12 mr-n10 panel dual-sliced",
                                              staticStyle: { height: "70px" },
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "overline mt-n2 pl-1"
                                                },
                                                [_vm._v("Difficulty")]
                                              ),
                                              _vm._v(" "),
                                              _c("v-text-field", {
                                                staticClass:
                                                  "hide-input-spinners",
                                                staticStyle: { width: "115px" },
                                                attrs: {
                                                  type: "number",
                                                  "append-outer-icon":
                                                    "mdi-plus-circle-outline",
                                                  "append-icon":
                                                    "cci-difficulty",
                                                  "prepend-icon":
                                                    "mdi-minus-circle-outline",
                                                  color: "accent",
                                                  dense: "",
                                                  disabled: _vm.actionUsed,
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  "click:append-outer": function(
                                                    $event
                                                  ) {
                                                    _vm.difficulty < 99
                                                      ? (_vm.difficulty += 1)
                                                      : ""
                                                  },
                                                  "click:prepend": function(
                                                    $event
                                                  ) {
                                                    _vm.difficulty >
                                                    _vm.minDifficulty
                                                      ? (_vm.difficulty -= 1)
                                                      : ""
                                                  },
                                                  change: function($event) {
                                                    _vm.difficulty = parseInt(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.difficulty,
                                                  callback: function($$v) {
                                                    _vm.difficulty = $$v
                                                  },
                                                  expression: "difficulty"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "px-12 panel dual-sliced",
                                              staticStyle: { height: "70px" },
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "overline mt-n2 mr-n6 pl-3"
                                                },
                                                [_vm._v("Contested Roll")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "mr-n2 ml-n2"
                                                    },
                                                    [
                                                      _c(
                                                        "cc-tooltip",
                                                        {
                                                          attrs: {
                                                            title:
                                                              "hullTEMS Roll",
                                                            content:
                                                              _vm.rollResultTooltip
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mt-1 mr-n3",
                                                              attrs: {
                                                                icon: "",
                                                                small: "",
                                                                color: "accent",
                                                                disabled:
                                                                  _vm.actionUsed
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.rollSkill
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    large: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-dice-multiple"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass:
                                                          "hide-input-spinners ml-n3",
                                                        staticStyle: {
                                                          "max-width": "60px",
                                                          "margin-top": "-0.5px"
                                                        },
                                                        attrs: {
                                                          type: "number",
                                                          color: "accent",
                                                          dense: "",
                                                          "hide-details": ""
                                                        },
                                                        model: {
                                                          value: _vm.roll,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.roll = $$v
                                                          },
                                                          expression: "roll"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "text-center mt-3 mr-6",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "heading h1",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    "+" + _vm.mech.Pilot.Grit
                                                  )
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "mt-2" },
                                                [_vm._v("GRIT")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-slide-x-reverse-transition", [
                (_vm.roll && _vm.hull) ||
                ((_vm.actionFree || _vm.actionCost) &&
                  _vm.mech.Pilot.State.IsJockeying)
                  ? _c(
                      "div",
                      [
                        !_vm.mech.Pilot.State.IsJockeying
                          ? _c(
                              "div",
                              { staticClass: "heading h3 text-right" },
                              [
                                _vm._v(
                                  "\n            JOCKEY SUCCESSFUL\n          "
                                )
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "heading h3 text-right stark--text"
                              },
                              [_vm._v("JOCKEY IN PROGRESS")]
                            ),
                        _vm._v(" "),
                        _c("div", { staticClass: "flavor-text text-right" }, [
                          _vm._v("Select Jockey Option")
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { staticClass: "mt-2", attrs: { dense: "" } },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-card",
                                  {
                                    class:
                                      _vm.selected === "distract"
                                        ? ""
                                        : "fadeSelect",
                                    attrs: {
                                      outlined: "",
                                      disabled: _vm.actionUsed
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.selected = "distract"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-toolbar",
                                      {
                                        attrs: {
                                          dense: "",
                                          color:
                                            _vm.selected === "distract"
                                              ? "primary lighten-2"
                                              : "primary"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "heading h3 white--text"
                                          },
                                          [_vm._v("DISTRACT")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "body-text" },
                                      [
                                        _vm._v(
                                          "\n                  The mech is IMPAIRED and SLOWED until the end of its next turn\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-card",
                                  {
                                    class:
                                      _vm.selected === "shred"
                                        ? ""
                                        : "fadeSelect",
                                    attrs: {
                                      outlined: "",
                                      disabled: _vm.actionUsed
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.selected = "shred"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-toolbar",
                                      {
                                        attrs: {
                                          dense: "",
                                          color:
                                            _vm.selected === "shred"
                                              ? "primary lighten-2"
                                              : "primary"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "heading h3 white--text"
                                          },
                                          [_vm._v("SHRED")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "body-text" },
                                      [
                                        _vm._v(
                                          "\n                  Deal 2 Heat to the mech by ripping at wiring, paneling, and so on\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-card",
                                  {
                                    class:
                                      _vm.selected === "damage"
                                        ? ""
                                        : "fadeSelect",
                                    attrs: {
                                      outlined: "",
                                      disabled: _vm.actionUsed
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.selected = "damage"
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-toolbar",
                                      {
                                        attrs: {
                                          dense: "",
                                          color:
                                            _vm.selected === "damage"
                                              ? "primary lighten-2"
                                              : "primary"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "heading h3 white--text"
                                          },
                                          [_vm._v("DAMAGE")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "body-text" },
                                      [
                                        _vm._v(
                                          "\n                  Deal 4 Kinetic dmage to the mech by attacking joints, hatches, and so on\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "v-slide-x-reverse-transition",
                [
                  _vm.selected
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "", justify: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    color: "primary",
                                    disabled: _vm.actionUsed
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.confirmJockey()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              COMFIRM JOCKEY::" +
                                      _vm._s(_vm.selected.toUpperCase()) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-slide-x-reverse-transition",
                [
                  _vm.actionUsed
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "ml-auto", attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "Undo this action, refunding any cost it may have had"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "fadeSelect",
                                      attrs: {
                                        "x-small": "",
                                        color: "primary"
                                      },
                                      on: { click: _vm.reset }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", left: "" } },
                                        [_vm._v("mdi-reload")]
                                      ),
                                      _vm._v(
                                        "\n                UNDO\n              "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-slide-y-reverse-transition", [
            _vm.actionUsed
              ? _c(
                  "div",
                  [
                    _c("v-divider"),
                    _vm._v(" "),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", tile: "" },
                            on: {
                              click: function($event) {
                                _vm.dialog = false
                              }
                            }
                          },
                          [_vm._v("DISMISS")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }