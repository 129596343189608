var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols } },
    [
      _c(
        "v-card",
        { staticClass: "text-center", attrs: { tile: "", outlined: "" } },
        [
          _c(
            "v-card-title",
            {
              class: _vm.color + " white--text caption pa-1",
              staticStyle: {
                "font-weight": "bold",
                "max-height": "28px",
                "font-size": "15px!important"
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.attr) + "\n      "),
              _vm.editable && !_vm.editMode
                ? _c(
                    "v-icon",
                    {
                      staticClass: "fadeSelect",
                      attrs: { dark: "", small: "" },
                      on: {
                        click: function($event) {
                          _vm.editMode = true
                        }
                      }
                    },
                    [_vm._v("\n        mdi-circle-edit-outline\n      ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pa-1 text--text" },
            [
              _vm.editMode
                ? _c("v-text-field", {
                    attrs: {
                      solo: "",
                      outlined: "",
                      filled: "",
                      dense: "",
                      "hide-details": "",
                      autofocus: "",
                      type: "number"
                    },
                    on: {
                      input: function($event) {
                        _vm.$emit("set", parseInt(_vm.model))
                      },
                      blur: function($event) {
                        _vm.editMode = false
                      },
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        _vm.editMode = false
                      },
                      focus: function($event) {
                        return $event.target.select()
                      }
                    },
                    model: {
                      value: _vm.model,
                      callback: function($$v) {
                        _vm.model = $$v
                      },
                      expression: "model"
                    }
                  })
                : _c("span", { staticClass: "heading h2" }, [
                    _vm._v(_vm._s(_vm.val || "0"))
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }