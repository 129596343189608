var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: {
        complete: !_vm.pilot.IsMissingLicenses,
        exit: "/pilot/" + _vm.pilot.ID,
        back: ""
      },
      on: {
        back: function($event) {
          return _vm.$emit("back")
        },
        complete: function($event) {
          return _vm.$emit("next")
        }
      }
    },
    [
      _c("cc-title", { attrs: { large: "" } }, [_vm._v("Unlock License ")]),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "heading" },
        [
          _vm._v("\n    MV-2 License Acquisition Request\n    "),
          _c("cc-slashes"),
          _vm._v("\n     MV-2//d Licensor/Licensee Transmit Record\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "absolute", right: "16px", top: "16px" } },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Feature In Development" } },
            [
              _c(
                "v-btn",
                { attrs: { small: "", outlined: "", disabled: "" } },
                [_vm._v("Suggest License")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text", staticStyle: { "font-size": "14px" } },
        [
          _c("span", [
            _vm._v(
              "\n      The MV-2 Licensor/Licensee Transmit Record registers the transmission and receipt of a\n      UN-recognized FRAME License consisting of, but not limited to, Equipment Print Templates not\n      exceeding 1.5EB, FRAME Print Templates not exceeding 3.75EB, and access protocols to\n      FRAME-compliant system software.\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "mt-2",
              attrs: {
                color: "accent",
                outlined: "",
                dense: "",
                border: "left"
              }
            },
            [
              _c("b", [_vm._v("Unlock a License.")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  "\n        Union and its representatives are not liable for problems arising from the printing,\n        installation, and/or use of unrecognized or prohibited License materials.\n      "
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-license-selector", { attrs: { "level-up": "", pilot: _vm.pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }