var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mx-6" },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", [
            _c("div", [
              _c(
                "span",
                {
                  staticClass: "heading mech",
                  staticStyle: { "line-height": "25px" }
                },
                [_vm._v(_vm._s(_vm.npc.Name))]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "heading h2 light-text--text" },
                [
                  _c("cc-slashes"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.npc.Side) +
                      "\n          " +
                      _vm._s(
                        typeof _vm.npc.Tier === "number"
                          ? "T" + _vm.npc.Tier
                          : "Custom"
                      ) +
                      " " +
                      _vm._s(_vm.npc.Class.Name) +
                      "\n          " +
                      _vm._s(
                        _vm.npc.Templates.map(function(t) {
                          return t.Name
                        }).join(" ")
                      ) +
                      " " +
                      _vm._s(_vm.npc.Tag) +
                      "\n        "
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flavor-text mt-n1 ml-2" }, [
              _vm._v(_vm._s(_vm.npc.Subtitle))
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _vm.npc.Activations === 0
                ? _c(
                    "v-btn",
                    {
                      attrs: { large: "", color: "secondary" },
                      on: {
                        click: function($event) {
                          _vm.npc.Activations += 1
                        }
                      }
                    },
                    [_vm._v("\n        Reactivate\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.npc.Defeat
        ? _c(
            "v-alert",
            {
              attrs: {
                prominent: "",
                color: "error",
                dark: "",
                border: "left",
                icon: "mdi-skull"
              }
            },
            [
              _c("span", { staticClass: "heading h2" }, [
                _vm._v("NPC " + _vm._s(_vm.npc.Defeat))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.npc.Activations === 0 && !_vm.npc.Defeat
        ? _c(
            "v-alert",
            {
              attrs: {
                dark: "",
                dense: "",
                border: "left",
                icon: "mdi-check",
                color: "panel"
              }
            },
            [
              _c("span", { staticClass: "heading h2" }, [
                _vm._v("Turn Complete")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between", dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("cc-status-select", {
                        key: _vm.npc.Statuses.length,
                        attrs: {
                          label: "Statuses",
                          items: _vm.statuses,
                          model: _vm.npc.Statuses,
                          dark: "",
                          color: "deep-orange darken-1"
                        },
                        on: {
                          set: function($event) {
                            _vm.npc.Statuses = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("cc-status-select", {
                        key: _vm.npc.Conditions.length,
                        attrs: {
                          label: "Conditions",
                          items: _vm.conditions,
                          model: _vm.npc.Conditions,
                          dark: "",
                          color: "red darken-2"
                        },
                        on: {
                          set: function($event) {
                            _vm.npc.Conditions = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("cc-status-select", {
                        key: _vm.npc.Resistances.length,
                        attrs: {
                          label: "Resistances",
                          items: _vm.resistances,
                          model: _vm.npc.Resistances,
                          dark: "",
                          color: "blue darken-2"
                        },
                        on: {
                          set: function($event) {
                            _vm.npc.Resistances = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto mr-3", attrs: { cols: "auto" } },
                    [
                      _c("v-text-field", {
                        staticClass: "hide-input-spinners",
                        staticStyle: { width: "115px" },
                        attrs: {
                          type: "number",
                          "append-outer-icon": "mdi-plus-circle-outline",
                          "append-icon": "mdi-fire",
                          "prepend-icon": "mdi-minus-circle-outline",
                          hint: "BURN",
                          "persistent-hint": "",
                          dense: ""
                        },
                        on: {
                          "click:append-outer": function($event) {
                            _vm.npc.Burn += 1
                          },
                          "click:prepend": function($event) {
                            _vm.npc.Burn -= 1
                          },
                          change: function($event) {
                            _vm.npc.Burn = parseInt($event)
                          }
                        },
                        model: {
                          value: _vm.npc.Burn,
                          callback: function($$v) {
                            _vm.$set(_vm.npc, "Burn", $$v)
                          },
                          expression: "npc.Burn"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n3", attrs: { dense: "", align: "center" } },
                [
                  _vm.npc.MaxStructure > 1
                    ? _c(
                        "v-col",
                        { staticClass: "mr-1", attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tick-bar",
                            {
                              key: _vm.npc.CurrentStructure,
                              class: { rolledOver: _vm.structRolledOver },
                              attrs: {
                                current: _vm.npc.CurrentStructure,
                                max: _vm.npc.MaxStructure,
                                large: "",
                                color: "structure",
                                "full-icon": "cci-structure"
                              },
                              on: {
                                update: function($event) {
                                  _vm.npc.CurrentStructure = $event
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v(
                                  "\n              Structure\n            "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.npc.Stats.Armor
                    ? _c(
                        "v-col",
                        { staticClass: "mx-1", attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tick-bar",
                            {
                              key: _vm.npc.Stats.Armor,
                              attrs: {
                                current: _vm.npc.Stats.Armor,
                                max: _vm.npc.Stats.Armor,
                                large: "",
                                color: "armor",
                                "full-icon": "mdi-shield",
                                readonly: ""
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v("Armor")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.npc.CurrentHP,
                          attrs: {
                            current: _vm.npc.CurrentHP,
                            max: _vm.npc.MaxHP,
                            large: "",
                            color: "hp",
                            rollover: ""
                          },
                          on: {
                            update: function($event) {
                              _vm.npc.CurrentHP = $event
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("HP")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.npc.Overshield,
                          attrs: {
                            current: _vm.npc.Overshield,
                            max: _vm.npc.Overshield,
                            large: "",
                            color: "stark",
                            "number-only": "",
                            "hide-values": "",
                            "full-icon": "mdi-octagram"
                          },
                          on: {
                            update: function($event) {
                              _vm.npc.Overshield = $event
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("Overshield")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _vm.npc.MaxStress > 1
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tick-bar",
                            {
                              key: _vm.npc.CurrentStress,
                              class: { rolledOver: _vm.stressRolledOver },
                              attrs: {
                                current: _vm.npc.CurrentStress,
                                max: _vm.npc.MaxStress,
                                large: "",
                                color: "stress",
                                "full-icon": "cci-reactor"
                              },
                              on: {
                                update: function($event) {
                                  _vm.npc.CurrentStress = $event
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v("Stress")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mr-4", attrs: { cols: "auto" } },
                    [
                      !_vm.npc.IsBiological
                        ? _c(
                            "cc-tick-bar",
                            {
                              key: _vm.npc.CurrentHeat,
                              attrs: {
                                current: _vm.npc.CurrentHeat,
                                max: _vm.npc.HeatCapacity,
                                large: "",
                                color: "dangerzone",
                                "full-icon": "mdi-fire",
                                "rollover-negative": "",
                                clearable: ""
                              },
                              on: {
                                update: function($event) {
                                  _vm.npc.CurrentHeat = $event
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v("HEAT")
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mr-4", attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.npc.CurrentMove,
                          attrs: {
                            current: _vm.npc.CurrentMove,
                            max: _vm.npc.MaxMove,
                            large: "",
                            color: "action--move",
                            "full-icon": "$vuetify.icons.move"
                          },
                          on: {
                            update: function($event) {
                              _vm.npc.CurrentMove = $event
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("MOVES")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mr-4" },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.npc.Activations,
                          attrs: {
                            current: _vm.npc.Activations,
                            max: _vm.npc.Activations,
                            large: "",
                            color: "secondary",
                            "full-icon": "cci-activate",
                            readonly: ""
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("ACTIVATIONS")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        [
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: _vm.npc.Class.Color,
                              header: "Hull",
                              content: _vm.npc.Stats.Hull || "0"
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: _vm.npc.Class.Color,
                              header: "Agility",
                              content: _vm.npc.Stats.Agility || "0"
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: _vm.npc.Class.Color,
                              header: "Systems",
                              content: _vm.npc.Stats.Systems || "0"
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: _vm.npc.Class.Color,
                              header: "Engineering",
                              content: _vm.npc.Stats.Engineering || "0"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        [
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: _vm.npc.Class.Color,
                              header: "Save",
                              content: _vm.npc.Stats.Save
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: _vm.npc.Class.Color,
                              header: "Evade",
                              content: _vm.npc.Stats.Evade
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: _vm.npc.Class.Color,
                              header: "E-Def",
                              content: _vm.npc.Stats.EDefense
                            }
                          }),
                          _vm._v(" "),
                          _c("cc-active-card", {
                            attrs: {
                              prominent: "",
                              color: _vm.npc.Class.Color,
                              header: "Sensor",
                              content: _vm.npc.Stats.Sensor
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        { attrs: { size: "120", color: _vm.npc.Class.Color } },
                        [_vm._v(_vm._s(_vm.npc.SizeIcon))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.npc.HasImage
            ? _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "", outlined: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-1" },
                        [
                          _vm.npc.Image
                            ? _c("v-img", {
                                key: _vm.npc.Image,
                                attrs: {
                                  "max-width": "30vw",
                                  "max-height": "30vh",
                                  src: _vm.npc.Image,
                                  "aspect-ratio": "1"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "overline" }, [_vm._v("COUNTERS")]),
      _vm._v(" "),
      _c("cc-counter-set", { attrs: { actor: _vm.npc } }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "overline" }, [_vm._v("FEATURES")])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn-toggle",
                { attrs: { value: _vm.profile.GetView("npc"), mandatory: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", value: "list" },
                      on: {
                        click: function($event) {
                          return _vm.profile.SetView("npc", "list")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-list")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", value: "chips" },
                      on: {
                        click: function($event) {
                          return _vm.profile.SetView("npc", "chips")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-comfy")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-2", attrs: { cols: "auto" } },
            [_c("recharge-menu", { attrs: { npc: _vm.npc } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.profile.GetView("npc") === "list"
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            _vm._l(_vm.npc.Items, function(i, idx) {
              return _c(
                "v-col",
                {
                  key: i.Feature.ID + idx,
                  attrs: { md: "12", lg: "6", xl: "4" }
                },
                [
                  _c("cc-npc-item-card", {
                    attrs: { item: i, active: "" },
                    on: {
                      "remove-feature": function($event) {
                        return _vm.npc.RemoveFeature(i.Feature)
                      },
                      recalc: function($event) {
                        return _vm.npc.RecalcBonuses()
                      },
                      "add-reaction": function($event) {
                        return _vm.npc.AddReaction($event)
                      }
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        : _vm.profile.GetView("npc") === "chips"
        ? _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-chip-group",
                { attrs: { column: "" } },
                _vm._l(_vm.npc.Items, function(i, idx) {
                  return _c("cc-npc-item-chip", {
                    key: i.Feature.ID + idx,
                    attrs: { item: i, active: "" },
                    on: {
                      "remove-feature": function($event) {
                        return _vm.npc.RemoveFeature(i.Feature)
                      },
                      recalc: function($event) {
                        return _vm.npc.RecalcBonuses()
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "cc-title",
        { attrs: { small: "", color: _vm.npc.Class.Color } },
        [
          _vm._v("\n    NPC Notes\n    "),
          _c("cc-text-editor", {
            attrs: { label: "Edit NPC Notes", original: _vm.npc.Note },
            on: {
              save: function($event) {
                _vm.npc.Note = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.npc.Note) } }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-3" }),
      _vm._v(" "),
      _vm.reactions.length
        ? _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "10" } },
                [
                  _c("div", { staticClass: "overline" }, [
                    _vm._v("STAGED REACTIONS")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-chip-group",
                    { key: "cr_" + _vm.reactions.length },
                    _vm._l(_vm.reactions, function(r, i) {
                      return _c(
                        "v-chip",
                        {
                          key: r + i,
                          staticClass: "mx-1",
                          attrs: {
                            dark: "",
                            color: "action--reaction",
                            close: "",
                            "close-icon": "mdi-close"
                          },
                          on: {
                            "click:close": function($event) {
                              return _vm.npc.RemoveReaction(r)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "", dark: "" } }, [
                            _vm._v("cci-reaction")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v(_vm._s(r))
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("cc-ref-stress-table", { ref: "stressTable" }),
      _vm._v(" "),
      _c("cc-ref-structure-table", { ref: "structureTable" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }