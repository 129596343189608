var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-selector-table",
        {
          attrs: {
            items: _vm.availableSystems,
            headers: _vm.headers,
            "sp-disable": "",
            "sp-ignore": _vm.showOverSP,
            sp: _vm.freeSP,
            "item-type-fallback": "MechSystem"
          },
          on: {
            equip: function($event) {
              return _vm.$emit("equip", $event)
            }
          }
        },
        [
          _vm.equipped
            ? _c("div", [
                _c("span", { staticClass: "overline" }, [
                  _vm._v(
                    "\n        UNION ARMORY PRINTID: " +
                      _vm._s(_vm.fID("ANN-NNN-NNN::AA//AA")) +
                      " —\n        "
                  ),
                  _c("span", { staticClass: "success--text text--darken-1" }, [
                    _vm._v(
                      "\n          [ FRAME EQUIPMENT REGISTRATION VERIFIED ]\n        "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "heading h1 accent--text",
                    staticStyle: { "line-height": "20px" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.equipped.Name) + "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "flavor-text overline mt-n1",
                    staticStyle: { display: "block" }
                  },
                  [_vm._v("CURRENTLY EQUIPPED")]
                )
              ])
            : _c("div", [
                _c("span", { staticClass: "overline" }, [
                  _vm._v(
                    "\n        UNION ARMORY EQUIPMENT AUTHORIZATION: FRAME EQUIPMENT//COMBAT SYSTEM\n      "
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "heading h1 subtle--text text--lighten-1",
                    staticStyle: { "line-height": "20px" }
                  },
                  [_vm._v("\n        NO SELECTION\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "flavor-text overline mt-n1 error--text",
                    staticStyle: { display: "block" }
                  },
                  [
                    _vm._v(
                      "\n        [ EQUIPMENT ID INVALID OR MISSING ]\n      "
                    )
                  ]
                )
              ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt-2 mb-n2",
              attrs: { slot: "extra-item" },
              slot: "extra-item"
            },
            [
              _c(
                "div",
                { staticClass: "mb-n2" },
                [
                  _c(
                    "v-switch",
                    {
                      staticClass: "mr-3 d-inline",
                      attrs: {
                        dense: "",
                        inset: "",
                        "hide-details": "",
                        color: "warning"
                      },
                      model: {
                        value: _vm.showUnlicensed,
                        callback: function($$v) {
                          _vm.showUnlicensed = $$v
                        },
                        expression: "showUnlicensed"
                      }
                    },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            slot: "label",
                            simple: "",
                            inline: "",
                            content: _vm.showUnlicensed
                              ? "Unlicensed equipment: SHOWN"
                              : "Unlicensed equipment: HIDDEN"
                          },
                          slot: "label"
                        },
                        [
                          _c("v-icon", {
                            staticClass: "ml-n2",
                            attrs: {
                              color: _vm.showUnlicensed ? "warning" : "success"
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.showUnlicensed
                                  ? "mdi-lock-open"
                                  : "mdi-lock"
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-n4" },
                [
                  _c(
                    "v-switch",
                    {
                      staticClass: "mr-3 d-inline",
                      attrs: {
                        dense: "",
                        inset: "",
                        "hide-details": "",
                        color: "warning"
                      },
                      model: {
                        value: _vm.showOverSP,
                        callback: function($$v) {
                          _vm.showOverSP = $$v
                        },
                        expression: "showOverSP"
                      }
                    },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            slot: "label",
                            simple: "",
                            inline: "",
                            content: _vm.showOverSP
                              ? "Systems exceeding SP Capacity: SHOWN"
                              : "Systems exceeding SP Capacity: HIDDEN"
                          },
                          slot: "label"
                        },
                        [
                          _c("v-icon", {
                            staticClass: "ml-n2",
                            attrs: {
                              color: _vm.showOverSP ? "warning" : "success"
                            },
                            domProps: { innerHTML: _vm._s("cci-system-point") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }