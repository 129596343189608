var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "equipment-card-base",
    { attrs: { item: _vm.item } },
    [
      _c(
        "v-col",
        { staticClass: "ml-auto text-right", attrs: { cols: "auto" } },
        [
          _c("div", { staticClass: "heading h2" }, [
            _vm._v(_vm._s(_vm.item.FeatureType))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "flavor-text subtle--text" }, [
            _vm._v("// " + _vm._s(_vm.item.Origin))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }