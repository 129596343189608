var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      style: _vm.$vuetify.breakpoint.mdAndDown ? "x-overflow: hidden" : "",
      attrs: { fullscreen: _vm.$vuetify.breakpoint.mdAndDown, width: "85vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { tile: "" } },
        [
          _c("downtime-titlebar", {
            attrs: {
              used: _vm.action.Used,
              "no-action": _vm.noAction,
              action: _vm.action,
              pilot: _vm.pilot
            },
            on: {
              hide: function($event) {
                return _vm.hide()
              }
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pt-5 pb-0" },
            [
              _vm.component
                ? _c(_vm.component, {
                    ref: "c",
                    tag: "component",
                    attrs: {
                      used: _vm.action.Used,
                      pilot: _vm.pilot,
                      action: _vm.action
                    },
                    on: {
                      use: function($event) {
                        return _vm.use($event)
                      },
                      hide: function($event) {
                        return _vm.hide()
                      },
                      close: function($event) {
                        return _vm.hide()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }