var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isExternal
    ? _c("img", {
        style: {
          maxWidth: _vm.iconSize,
          height: "auto"
        },
        attrs: { src: _vm.source.Logo, alt: _vm.source.Name }
      })
    : _c(
        "svg",
        {
          style:
            "width:" +
            _vm.iconSize +
            "; height:" +
            _vm.iconSize +
            "; fill:" +
            _vm.iconColor +
            "; stroke:" +
            _vm.stroke +
            "; " +
            (_vm.stroke ? "stroke-width: 25px;" : "")
        },
        [_c("use", { attrs: { href: _vm.source.Logo + "#Content" } })]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }