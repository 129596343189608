var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "my-1",
      attrs: { id: "pc-wrapper" },
      on: {
        click: function($event) {
          _vm.selectable ? _vm.$emit("select", _vm.pilot) : _vm.toPilotSheet()
        }
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "overlay clipped-square-invert",
          staticStyle: { position: "absolute", "z-index": "5" },
          attrs: {
            tile: "",
            color: "primary",
            "min-width": _vm.mobile ? "75px" : "108px",
            "min-height": _vm.mobile ? "75px" : "108px"
          }
        },
        [
          _c(
            "div",
            { staticClass: "clipped-square-invert", attrs: { id: "interior" } },
            [
              _c("v-img", {
                attrs: {
                  src: _vm.pilot.Portrait,
                  position: "top",
                  height: _vm.mobile ? "75px" : "108px"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { width: "100%" }, attrs: { id: "banner" } }, [
        _c(
          "div",
          {
            staticClass: "overlay primary",
            staticStyle: {
              width: "100%",
              display: "flex",
              "justify-content": "space-between",
              "align-content": "center"
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "heading callsign",
                style:
                  "margin-left: " +
                  (_vm.mobile ? "75px" : "108px") +
                  "; display: inline-block;"
              },
              [_vm._v("\n        " + _vm._s(_vm.pilot.Callsign) + "\n      ")]
            ),
            _vm._v(" "),
            _c("edit-menu", {
              staticStyle: { display: "inline-block", "padding-right": "10px" },
              attrs: { dense: "", pilot: _vm.pilot }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "light-panel clipped",
            style:
              "border-top: 0!important;  min-height: " +
              (_vm.mobile ? "44px" : "72px") +
              ";"
          },
          [
            _c(
              "div",
              {
                staticClass: "mt-n1",
                style:
                  "margin-left: " +
                  (_vm.mobile ? "75px" : "108px") +
                  "; padding-left: 8px;"
              },
              [
                _c(
                  "p",
                  { staticClass: "flavor-text" },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.mobile,
                            expression: "!mobile"
                          }
                        ]
                      },
                      [
                        _c("span", { staticClass: "subtle--text" }, [
                          _vm._v(">[")
                        ]),
                        _vm._v(" "),
                        _c("b", { staticClass: "stark--text" }, [
                          _vm._v(_vm._s(_vm.pilot.Name))
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "subtle--text" }, [
                          _vm._v("]")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "subtle--text" }, [
                          _vm._v("STATUS [")
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class: _vm.statusColor(_vm.pilot.Status) + "--text"
                          },
                          [_vm._v(_vm._s(_vm.pilot.Status))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "subtle--text" }, [
                          _vm._v("] -")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text--text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.pilot.Background.Name) +
                              "\n            "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("b", { staticClass: "success--text" }, [
                      _vm._v("LL: " + _vm._s(_vm.pilot.Level))
                    ]),
                    _vm._v(" "),
                    _c("cc-slashes", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$vuetify.breakpoint.mdAndUp,
                          expression: "$vuetify.breakpoint.mdAndUp"
                        }
                      ]
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "text--text" }, [
                      _vm._v(
                        "\n            [ H:" +
                          _vm._s(_vm.pilot.MechSkills.Hull) +
                          " A:" +
                          _vm._s(_vm.pilot.MechSkills.Agi) +
                          " S:" +
                          _vm._s(_vm.pilot.MechSkills.Sys) +
                          "\n            E:" +
                          _vm._s(_vm.pilot.MechSkills.Eng) +
                          " ]\n          "
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.pilot.ActiveMech && !_vm.mobile
                  ? _c(
                      "p",
                      { staticClass: "flavor-text mb-0 pb-2 mt-n1" },
                      [
                        _c("span", { staticClass: "subtle--text" }, [
                          _vm._v("UNB::CAV (LNCR)")
                        ]),
                        _vm._v(" "),
                        _c("cc-slashes"),
                        _vm._v(" "),
                        _c("span", { staticClass: "text--text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.pilot.ActiveMech.Frame.Source) +
                              " " +
                              _vm._s(_vm.pilot.ActiveMech.Frame.Name) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "subtle--text" }, [
                          _vm._v("[")
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "text--text font-weight-bold" },
                          [_vm._v(_vm._s(_vm.pilot.ActiveMech.Name))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "subtle--text" }, [
                          _vm._v("]")
                        ])
                      ],
                      1
                    )
                  : _vm.pilot.ActiveMech
                  ? _c("div", { staticClass: "mt-n6" }, [
                      _c("span", { staticClass: "overline" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.pilot.ActiveMech.Frame.Source) +
                            " " +
                            _vm._s(_vm.pilot.ActiveMech.Frame.Name) +
                            "\n          "
                        )
                      ])
                    ])
                  : _vm._e()
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }