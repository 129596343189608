import { render, staticRenderFns } from "./ActiveRest.vue?vue&type=template&id=34ed4a5d&"
import script from "./ActiveRest.vue?vue&type=script&lang=ts&"
export * from "./ActiveRest.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VAlert,VBtn,VCol,VContainer,VDivider,VIcon,VProgressLinear,VRow,VScrollYReverseTransition,VSlider})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('34ed4a5d')) {
      api.createRecord('34ed4a5d', component.options)
    } else {
      api.reload('34ed4a5d', component.options)
    }
    module.hot.accept("./ActiveRest.vue?vue&type=template&id=34ed4a5d&", function () {
      api.rerender('34ed4a5d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/pilot_management/ActiveSheet/views/ActiveRest.vue"
export default component.exports