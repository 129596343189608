var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        [
          _c("p", {
            staticClass: "text-center body-text",
            domProps: {
              innerHTML: _vm._s(
                "You try and get your hands on some gear or asset for your group by dredging the scrapyard, chasing down rumors, bartering in the local market, hunting around, or through good old fashioned force of will. You could try and get some better pilot gear that could help you, a vehicle, narcotics, goods, or other sundries. It’s got to be something physical that you can acquire, but doesn’t necessarily have to be on the gear list. If you get it, you can take it on the next mission as <strong>reserves.</strong>"
              )
            }
          }),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mb-2" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pt-2 heading h3 text-center" },
            [
              _vm._v("\n      Roll\n      "),
              _c("v-icon", { attrs: { large: "", color: "primary" } }, [
                _vm._v("mdi-dice-d20")
              ]),
              _vm._v(
                "\n       and add any relevant Skill Trigger bonuses, modifiers, or accuracy\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      label: "Roll Result",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      "append-outer-icon": "mdi-plus-circle-outline",
                      "prepend-icon": "mdi-minus-circle-outline"
                    },
                    on: {
                      "click:append-outer": function($event) {
                        _vm.skillRoll++
                      },
                      "click:prepend": function($event) {
                        _vm.skillRoll > 1 ? _vm.skillRoll-- : ""
                      }
                    },
                    model: {
                      value: _vm.skillRoll,
                      callback: function($$v) {
                        _vm.skillRoll = $$v
                      },
                      expression: "skillRoll"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-slide-y-transition",
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.skillRoll,
                      expression: "skillRoll"
                    }
                  ],
                  staticClass: "text-center flavor-text",
                  attrs: { justify: "center" }
                },
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _vm.skillRoll < 10
                      ? _c(
                          "p",
                          { staticClass: "font-weight-bold px-3" },
                          [
                            _vm._v(
                              "\n            You can get what you’re looking for, but...\n            "
                            ),
                            _c(
                              "v-radio-group",
                              {
                                attrs: { mandatory: "" },
                                model: {
                                  value: _vm.choice,
                                  callback: function($$v) {
                                    _vm.choice = $$v
                                  },
                                  expression: "choice"
                                }
                              },
                              _vm._l(_vm.choices, function(c, i) {
                                return _c("v-radio", {
                                  key: c,
                                  attrs: { label: c, value: i }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm.skillRoll < 20
                      ? _c(
                          "p",
                          { staticClass: "font-weight-bold px-3" },
                          [
                            _vm._v(
                              "\n            You can get what you’re looking for, as long as you trade in a little...\n            "
                            ),
                            _c(
                              "v-radio-group",
                              {
                                attrs: { mandatory: "" },
                                model: {
                                  value: _vm.trade,
                                  callback: function($$v) {
                                    _vm.trade = $$v
                                  },
                                  expression: "trade"
                                }
                              },
                              _vm._l(_vm.trades, function(t, i) {
                                return _c("v-radio", {
                                  key: t,
                                  attrs: { label: t, value: i }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _c("p", { staticClass: "font-weight-bold px-3" }, [
                          _vm._v(
                            "\n            You get what you’re looking for, no problems at all.\n          "
                          )
                        ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "ml-5 mr-5 mt-2",
                              attrs: { color: "panel" }
                            },
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    dark: "",
                                    dense: "",
                                    color: "action--downtime"
                                  }
                                },
                                [_c("v-toolbar-title", [_vm._v("New Asset")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                [
                                  _c("v-text-field", {
                                    staticStyle: { width: "500px" },
                                    attrs: {
                                      label: "Asset or Gear",
                                      dense: "",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.custom_name,
                                      callback: function($$v) {
                                        _vm.custom_name = $$v
                                      },
                                      expression: "custom_name"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-textarea", {
                                    attrs: {
                                      "auto-grow": "",
                                      rows: "1",
                                      label: "Details",
                                      filled: ""
                                    },
                                    model: {
                                      value: _vm.details,
                                      callback: function($$v) {
                                        _vm.details = $$v
                                      },
                                      expression: "details"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("cancel")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                tile: "",
                color: "primary",
                disabled: !_vm.skillRoll || !_vm.custom_name
              },
              on: { click: _vm.addReserve }
            },
            [_vm._v("\n      add reserve\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }