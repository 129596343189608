var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", { staticClass: "mt-4", attrs: { cols: "auto" } }, [
            _c(
              "span",
              {
                staticClass: "heading mech",
                staticStyle: { "line-height": "0" }
              },
              [_vm._v("\n        " + _vm._s(_vm.npcc.Name) + "\n      ")]
            )
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "ml-auto text-center mt-n4",
              attrs: { cols: "auto" }
            },
            [
              _c("v-icon", { attrs: { size: "60", color: _vm.npcc.Color } }, [
                _vm._v(_vm._s(_vm.npcc.RoleIcon))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "overline mt-n1" }, [
                _vm._v(_vm._s(_vm.npcc.Role))
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("p", {
        directives: [
          {
            name: "html-safe",
            rawName: "v-html-safe",
            value: _vm.npcc.Flavor,
            expression: "npcc.Flavor"
          }
        ],
        staticClass: "flavor-text panel pa-2 stark--text"
      }),
      _vm._v(" "),
      _c("span", { staticClass: "heading h3 accent--text" }, [
        _vm._v("Tactics")
      ]),
      _vm._v(" "),
      _c("p", {
        directives: [
          {
            name: "html-safe",
            rawName: "v-html-safe",
            value: _vm.npcc.Tactics,
            expression: "npcc.Tactics"
          }
        ],
        staticClass: "body-1"
      }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mb-3" }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", align: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn-toggle",
                {
                  staticClass: "mb-2",
                  attrs: {
                    dense: "",
                    mandatory: "",
                    "active-class": "accent--text"
                  },
                  model: {
                    value: _vm.tierPreview,
                    callback: function($$v) {
                      _vm.tierPreview = $$v
                    },
                    expression: "tierPreview"
                  }
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { value: 1 } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("cci-rank-1")
                      ]),
                      _vm._v("\n          Tier 1\n        ")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { value: 2 } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("cci-rank-2")
                      ]),
                      _vm._v("\n          Tier 2\n        ")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { value: 3 } },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("cci-rank-3")
                      ]),
                      _vm._v("\n          Tier 3\n        ")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  _c("editable-attribute", {
                    attrs: {
                      attr: "HULL",
                      val: _vm.npcc.Stats.Hull(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "AGI",
                      val: _vm.npcc.Stats.Agility(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "SYS",
                      val: _vm.npcc.Stats.Systems(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "ENG",
                      val: _vm.npcc.Stats.Engineering(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-2" }),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  _c("editable-attribute", {
                    attrs: {
                      attr: "STRUCTURE",
                      val: _vm.npcc.Stats.Structure(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "ARMOR",
                      val: _vm.npcc.Stats.Armor(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "HP",
                      val: _vm.npcc.Stats.HP(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "REACTOR",
                      val: _vm.npcc.Stats.Stress(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _vm.npcc.Role.toLowerCase() !== "biological"
                    ? _c("editable-attribute", {
                        attrs: {
                          attr: "HEAT CAPACITY",
                          val: _vm.npcc.Stats.HeatCapacity(_vm.tierPreview),
                          color: _vm.npcc.Color
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  _c("editable-attribute", {
                    attrs: {
                      attr: "SPEED",
                      val: _vm.npcc.Stats.Speed(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "SAVE",
                      val: _vm.npcc.Stats.Save(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "EVADE",
                      val: _vm.npcc.Stats.Evade(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "E-DEFENSE",
                      val: _vm.npcc.Stats.EDefense(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "SENSOR",
                      val: _vm.npcc.Stats.Sensor(_vm.tierPreview),
                      color: _vm.npcc.Color
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-2" }),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  _c("editable-attribute", {
                    attrs: {
                      attr: "ACTIVATIONS",
                      val: 1,
                      color: _vm.npcc.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("editable-attribute", {
                    attrs: {
                      attr: "SIZE",
                      val: _vm.npcc.Stats.Sizes(_vm.tierPreview)
                        .join(" or ")
                        .replace("0.5", "½"),
                      color: _vm.npcc.Color
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-title",
        { staticClass: "mt-2", attrs: { small: "", color: _vm.npcc.Color } },
        [_vm._v("\n    Base Features\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        _vm._l(_vm.npcc.BaseFeatures, function(f) {
          return _c(
            "v-col",
            { key: f.ID, attrs: { cols: "12" } },
            [
              _c("cc-npc-feature-card", {
                attrs: { feature: f, tier: _vm.tierPreview }
              })
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.npcc.OptionalFeatures.length
        ? _c(
            "cc-title",
            {
              staticClass: "mt-2",
              attrs: { small: "", color: _vm.npcc.Color }
            },
            [_vm._v("\n    Optional Features\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        _vm._l(_vm.npcc.OptionalFeatures, function(f) {
          return _c(
            "v-col",
            { key: f.ID, attrs: { cols: "12" } },
            [
              _c("cc-npc-feature-card", {
                attrs: { feature: f, tier: _vm.tierPreview }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }