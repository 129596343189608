var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-slide-x-reverse-transition",
        [
          _vm.used
            ? _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-n5", attrs: { lg: "auto", md: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-center mb-n3",
                          attrs: { dense: "", justify: "start", align: "start" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-8", attrs: { cols: "auto" } },
                            [
                              _c("div", { staticClass: "overline" }, [
                                _vm._v("Tech Attack Roll")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "heading text--text",
                                  staticStyle: { "font-size": "24pt" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-n1",
                                      attrs: { "x-large": "" }
                                    },
                                    [_vm._v("mdi-dice-d20-outline")]
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        "" +
                                          (_vm.mech.TechAttack >= 0
                                            ? "+"
                                            : "") +
                                          _vm.mech.TechAttack
                                      ) +
                                      "\n            "
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "mx-8", attrs: { cols: "auto" } },
                            [
                              _c("div", { staticClass: "overline" }, [
                                _vm._v("vs. Target")
                              ]),
                              _vm._v(" "),
                              _c("v-icon", {
                                attrs: { "x-large": "" },
                                domProps: { innerHTML: _vm._s("cci-edef") }
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "overline font-weight-bold mt-n2",
                                domProps: { innerHTML: _vm._s("E-Defense") }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "", justify: "end" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "ml-auto px-12 mr-n10 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", { staticClass: "overline pl-1" }, [
                                _vm._v("Accuracy")
                              ]),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "hide-input-spinners",
                                staticStyle: { width: "115px" },
                                attrs: {
                                  type: "number",
                                  "append-outer-icon":
                                    "mdi-plus-circle-outline",
                                  "append-icon": "cci-accuracy",
                                  "prepend-icon": "mdi-minus-circle-outline",
                                  color: "accent",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  "click:append-outer": function($event) {
                                    _vm.accuracy += 1
                                  },
                                  "click:prepend": function($event) {
                                    _vm.accuracy > 0 ? (_vm.accuracy -= 1) : ""
                                  },
                                  change: function($event) {
                                    _vm.accuracy = parseInt($event)
                                  }
                                },
                                model: {
                                  value: _vm.accuracy,
                                  callback: function($$v) {
                                    _vm.accuracy = $$v
                                  },
                                  expression: "accuracy"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-12 mr-n10 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", { staticClass: "overline pl-1" }, [
                                _vm._v("Difficulty")
                              ]),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "hide-input-spinners",
                                staticStyle: { width: "115px" },
                                attrs: {
                                  type: "number",
                                  "append-outer-icon":
                                    "mdi-plus-circle-outline",
                                  "append-icon": "cci-difficulty",
                                  "prepend-icon": "mdi-minus-circle-outline",
                                  color: "accent",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  "click:append-outer": function($event) {
                                    _vm.difficulty += 1
                                  },
                                  "click:prepend": function($event) {
                                    _vm.difficulty > 0
                                      ? (_vm.difficulty -= 1)
                                      : ""
                                  },
                                  change: function($event) {
                                    _vm.difficulty = parseInt($event)
                                  }
                                },
                                model: {
                                  value: _vm.difficulty,
                                  callback: function($$v) {
                                    _vm.difficulty = $$v
                                  },
                                  expression: "difficulty"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-12 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "overline mr-n6 pl-3" },
                                [_vm._v("Tech Attack Roll")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "mr-n2 ml-n2" },
                                    [
                                      _c("cc-dice-menu", {
                                        attrs: {
                                          preset: "1d20+" + _vm.mech.TechAttack,
                                          "preset-accuracy":
                                            _vm.accuracy - _vm.difficulty,
                                          title: "Tech Attack"
                                        },
                                        on: {
                                          commit: function($event) {
                                            return _vm.registerTechRoll(
                                              $event.total
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        staticClass:
                                          "hide-input-spinners ml-n3",
                                        staticStyle: {
                                          "max-width": "60px",
                                          "margin-top": "-0.5px"
                                        },
                                        attrs: {
                                          type: "number",
                                          color: "accent",
                                          dense: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.attackRoll,
                                          callback: function($$v) {
                                            _vm.attackRoll = $$v
                                          },
                                          expression: "attackRoll"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-x-reverse-transition",
        [
          !!_vm.attackRoll
            ? _c(
                "v-row",
                { staticClass: "mt-n2", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto",
                      attrs: { md: "6", lg: "3", xl: "2" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: {
                            tile: "",
                            block: "",
                            color:
                              "primary " + (_vm.succeeded ? "lighten-1" : ""),
                            disabled: _vm.failed
                          },
                          on: {
                            click: function($event) {
                              return _vm.complete(true)
                            }
                          }
                        },
                        [_vm._v("\n          SUCCESS\n        ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "6", lg: "3", xl: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            tile: "",
                            block: "",
                            disabled: _vm.succeeded,
                            color: _vm.failed ? "error" : ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.complete(false)
                            }
                          }
                        },
                        [_vm._v("\n          FAILURE\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }