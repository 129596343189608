var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: {
        overflow: "hidden",
        "margin-top": "48px",
        height: "calc(100vh - 50px)"
      },
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-col",
            [
              _c("home-card", {
                attrs: {
                  title: "NPC Roster",
                  icon: "mdi-account-edit",
                  text: "Design, edit, and manage NPCs for use in encounters.",
                  to: "/gm/npc-roster"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("home-card", {
                attrs: {
                  title: "Encounter Builder",
                  icon: "mdi-account-multiple-plus",
                  text:
                    "Compose encounters with NPCs, objectives, and battlefield conditions",
                  to: "/gm/encounter-builder"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("home-card", {
                attrs: {
                  title: "Mission Runner",
                  icon: "mdi-account-group",
                  text:
                    "Run sets of encounters digitally with NPC and Pilot status tracking.",
                  to: "/gm/mission",
                  "icon-offset": 13
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }