var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-n2" },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "pt-2 ml-n6", attrs: { cols: "3" } },
            [
              _c(
                "v-list",
                { staticClass: "side-fixed", attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { color: "accent" },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    },
                    _vm._l(_vm.items, function(i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i.ID + "_sidebar'",
                          attrs: { value: i.ID, dense: "" }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _vm.equipmentAdd
                                ? _c("v-icon", {
                                    domProps: { innerHTML: _vm._s(i.Icon) }
                                  })
                                : i.IsExotic
                                ? _c("v-icon", { attrs: { color: "exotic" } }, [
                                    _vm._v(
                                      "\n                mdi-star\n              "
                                    )
                                  ])
                                : i.Manufacturer
                                ? _c("cc-logo", {
                                    staticClass: "mb-n1",
                                    attrs: { source: i.Manufacturer }
                                  })
                                : _c("v-icon", [_vm._v("cci-trait")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            { staticClass: "ml-n6 mt-n1" },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  class:
                                    "heading h3 " +
                                    (_vm.spDisable &&
                                    i.SP > 0 &&
                                    i.SP > _vm.sp &&
                                    !_vm.spIgnore
                                      ? "subtle--text"
                                      : "stark--text") +
                                    " font-weight-bold",
                                  staticStyle: { "font-size": "15px" }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(i.Name) +
                                      "\n                "
                                  ),
                                  _vm.spDisable &&
                                  i.SP > 0 &&
                                  i.SP > _vm.sp &&
                                  !_vm.spIgnore
                                    ? _c(
                                        "cc-tooltip",
                                        {
                                          attrs: {
                                            inline: "",
                                            content:
                                              "Equipment exceeds System Point capacity"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "warning" } },
                                            [_vm._v("mdi-alert")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { staticClass: "pl-7 mr-7" }, [
            !_vm.selectedItem
              ? _c(
                  "div",
                  {
                    staticClass: "heading h2 light-panel--text text-center",
                    staticStyle: { "margin-top": "calc(50vh - 150px)" }
                  },
                  [_vm._v("\n        NO SELECTION\n      ")]
                )
              : _c(
                  "div",
                  { staticClass: "side-fixed" },
                  [
                    _c("div", { staticClass: "heading h1 mt-2 stark--text" }, [
                      _vm._v(_vm._s(_vm.selectedItem.Name))
                    ]),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "mt-4 mb-1" }),
                    _vm._v(" "),
                    _c("cc-item-card", { attrs: { item: _vm.selectedItem } }),
                    _vm._v(" "),
                    _vm.equipmentAdd
                      ? _c(
                          "div",
                          { staticClass: "text-center mt-3" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "accent",
                                  "x-large": "",
                                  tile: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("equip", _vm.selectedItem)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            Add " +
                                    _vm._s(_vm.selectedItem.Name) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "text-center mt-3" },
                          [
                            _vm.spDisable &&
                            _vm.selectedItem.SP > 0 &&
                            _vm.selectedItem.SP > _vm.sp &&
                            !_vm.spIgnore
                              ? _c(
                                  "div",
                                  { staticClass: "overline warning--text" },
                                  [
                                    _vm._v(
                                      "\n            // ALERT: EQUIPMENT EXCEEDS SYSTEM POINT CAPACITY //\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "secondary",
                                  "x-large": "",
                                  tile: "",
                                  disabled:
                                    _vm.spDisable &&
                                    _vm.selectedItem.SP > 0 &&
                                    _vm.selectedItem.SP > _vm.sp &&
                                    !_vm.spIgnore
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("equip", _vm.selectedItem)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            Equip " +
                                    _vm._s(_vm.selectedItem.Name) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                  ],
                  1
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }