import { render, staticRenderFns } from "./_ModInset.vue?vue&type=template&id=8f717b26&scoped=true&"
import script from "./_ModInset.vue?vue&type=script&lang=ts&"
export * from "./_ModInset.vue?vue&type=script&lang=ts&"
import style0 from "./_ModInset.vue?vue&type=style&index=0&id=8f717b26&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f717b26",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCard,VCardTitle,VCol,VIcon,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8f717b26')) {
      api.createRecord('8f717b26', component.options)
    } else {
      api.reload('8f717b26', component.options)
    }
    module.hot.accept("./_ModInset.vue?vue&type=template&id=8f717b26&scoped=true&", function () {
      api.rerender('8f717b26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/mech_loadout/components/mount/weapon/_ModInset.vue"
export default component.exports