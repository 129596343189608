var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    [
      _c(
        "div",
        { staticClass: "ml-n3" },
        [
          _c(
            "cc-title",
            {
              staticClass: "pl-3 ml-n6",
              attrs: { small: "", color: _vm.color }
            },
            [_vm._v("Licenses Required")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            _vm._l(_vm.mech.RequiredLicenses, function(l, index) {
              return _c("requirement-item", {
                key: "req_" + index + _vm.mech.ActiveLoadout.ID,
                attrs: { license: l }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }