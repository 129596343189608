var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "equipment-card-base",
    { attrs: { item: _vm.item, notes: _vm.notes } },
    [
      _vm.item.SP
        ? _c("v-col", { staticClass: "text-center", attrs: { cols: "auto" } }, [
            _c(
              "div",
              { staticClass: "panel clipped" },
              _vm._l(_vm.item.SP, function(n) {
                return _c(
                  "v-icon",
                  { key: _vm.item.ID + "_sp-" + n, attrs: { "x-large": "" } },
                  [_vm._v("cci-system-point")]
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "overline" }, [
              _c("b", [_vm._v(_vm._s(_vm.item.SP))]),
              _vm._v(
                "\n      SYSTEM POINT" +
                  _vm._s(_vm.item.SP > 1 ? "S" : "") +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.LicenseString
        ? _c(
            "v-col",
            { staticClass: "ml-auto text-right", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "heading h2" }, [
                _vm._v(_vm._s(_vm.item.Type))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "flavor-text subtle--text" }, [
                _vm._v("// " + _vm._s(_vm.item.LicenseString))
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }