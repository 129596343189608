import { render, staticRenderFns } from "./PilotCard.vue?vue&type=template&id=c74245d2&scoped=true&"
import script from "./PilotCard.vue?vue&type=script&lang=ts&"
export * from "./PilotCard.vue?vue&type=script&lang=ts&"
import style0 from "./PilotCard.vue?vue&type=style&index=0&id=c74245d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c74245d2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VCard,VCardText,VDivider,VFadeTransition,VHover,VImg,VOverlay})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c74245d2')) {
      api.createRecord('c74245d2', component.options)
    } else {
      api.reload('c74245d2', component.options)
    }
    module.hot.accept("./PilotCard.vue?vue&type=template&id=c74245d2&scoped=true&", function () {
      api.rerender('c74245d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/pilot_management/Roster/components/PilotCard.vue"
export default component.exports