var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    {
      attrs: { color: "active" },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.$refs.dialog.show()
        }
      }
    },
    [
      _c(
        "v-list-item-content",
        [
          _c(
            "v-list-item-title",
            { staticClass: "title" },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mt-n1",
                  style: !_vm.available && !_vm.action.Used ? "opacity:0.3" : ""
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.action.Used ? "mdi-check-circle" : _vm.action.Icon
                      ) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  style: !_vm.available && !_vm.action.Used ? "opacity:0.3" : ""
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.action.Name.toUpperCase()) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-list-item-subtitle", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.terse,
                expression: "terse"
              }
            ],
            style: !_vm.available && !_vm.action.Used ? "opacity:0.3" : ""
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: {
            left: "",
            width: "80vw",
            "offset-x": "",
            "open-on-hover": "",
            "open-delay": "100"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      {
                        staticClass: "fadeSelect ml-4",
                        staticStyle: { cursor: "help" }
                      },
                      on
                    ),
                    [_vm._v("\n        mdi-help-circle-outline\n      ")]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            { attrs: { tile: "" } },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "heading h2",
                  attrs: {
                    dense: "",
                    flat: "",
                    color: _vm.action.Color,
                    dark: ""
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.action.Name) + "\n      ")]
              ),
              _vm._v(" "),
              _c("v-card-text", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.action.Detail,
                    expression: "action.Detail"
                  }
                ],
                staticClass: "body-text text--text"
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-downtime-dialog", {
        ref: "dialog",
        attrs: { action: _vm.action, pilot: _vm.pilot }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }