var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-base",
    {
      attrs: { item: _vm.item, active: _vm.active, readonly: _vm.readonly },
      on: {
        "remove-feature": function($event) {
          return _vm.$emit("remove-feature", $event)
        },
        recalc: function($event) {
          return _vm.$emit("recalc")
        }
      }
    },
    [
      _c("span", { staticClass: "overline" }, [_vm._v("EFFECT")]),
      _vm._v(" "),
      _vm.item.Tier
        ? _c("p", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.item.Feature.EffectByTier(_vm.item.Tier),
                expression: "item.Feature.EffectByTier(item.Tier)"
              }
            ],
            staticClass: "body-1 mb-0"
          })
        : _c("p", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.item.Feature.Effect,
                expression: "item.Feature.Effect"
              }
            ],
            staticClass: "body-1 mb-0"
          }),
      _vm._v(" "),
      _vm.item.Feature.Tags
        ? _c("cc-tags", { attrs: { tags: _vm.item.Feature.Tags, small: "" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }