





















































































































import Vue from 'vue'
import TalentRankContents from './_TalentRankContents.vue'
import TalentEmblem from './_TalentEmblem.vue'
export default Vue.extend({
  components: { TalentRankContents, TalentEmblem },
  name: 'talent-small',
  props: {
    hideLocked: { type: Boolean },
    talent: { type: Object, required: true },
    canAdd: { type: Boolean },
    selectable: { type: Boolean },
    rank: { type: [Number, String], required: false, default: null },
  },
  data: () => ({
    showAll: false,
  }),
  computed: {
    showFull() {
      if (this.hideLocked) return this.showAll
      return true
    },
  },
})
