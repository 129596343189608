var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        [
          _c("p", {
            staticClass: "text-center body-text",
            domProps: {
              innerHTML: _vm._s(
                "You can only make this action where there’s a drink to actually get (in town, a station, a city, or some other populated area). It doesn’t have to be an actual drink, but you blow off some steam, carouse, and generally get into trouble. You could be doing this to make connections, collect gossip, forge a reputation, or maybe just to forget what happened on the last mission. There’s usually trouble."
              )
            }
          }),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mb-2" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pt-2 heading h3 text-center" },
            [
              _vm._v("\n      Roll\n      "),
              _c("v-icon", { attrs: { large: "", color: "accent" } }, [
                _vm._v("mdi-dice-d20")
              ]),
              _vm._v(
                "\n       and add any relevant Skill Trigger bonuses, modifiers, or accuracy\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      label: "Roll Result",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      "append-outer-icon": "mdi-plus-circle-outline",
                      "prepend-icon": "mdi-minus-circle-outline"
                    },
                    on: {
                      "click:append-outer": function($event) {
                        _vm.skillRoll++
                      },
                      "click:prepend": function($event) {
                        _vm.skillRoll > 1 ? _vm.skillRoll-- : ""
                      }
                    },
                    model: {
                      value: _vm.skillRoll,
                      callback: function($$v) {
                        _vm.skillRoll = $$v
                      },
                      expression: "skillRoll"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-slide-y-transition",
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.skillRoll,
                      expression: "skillRoll"
                    }
                  ],
                  staticClass: "text-center flavor-text",
                  attrs: { justify: "center" }
                },
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _vm.skillRoll < 10
                      ? _c(
                          "p",
                          { staticClass: "font-weight-bold px-3" },
                          [
                            _vm._v(
                              "\n            You can decide whether you had good time or not. However, you wake up in a gutter\n            somewhere with only one of the following:\n            "
                            ),
                            _c("v-select", {
                              staticClass: "ml-5 mr-5",
                              attrs: {
                                outlined: "",
                                items: _vm.losses,
                                label: "You retain..."
                              },
                              model: {
                                value: _vm.kept,
                                callback: function($$v) {
                                  _vm.kept = $$v
                                },
                                expression: "kept"
                              }
                            })
                          ],
                          1
                        )
                      : _vm.skillRoll < 20
                      ? _c(
                          "p",
                          { staticClass: "font-weight-bold px-3" },
                          [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  "You gain one of the following choices as <strong>reserves</strong> , and lose one:"
                                )
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        "hide-details": "",
                                        dense: "",
                                        outlined: "",
                                        items: _vm.choices,
                                        label: "You gain..."
                                      },
                                      model: {
                                        value: _vm.reserve1,
                                        callback: function($$v) {
                                          _vm.reserve1 = $$v
                                        },
                                        expression: "reserve1"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _vm.reserve1
                                      ? _c("v-textarea", {
                                          attrs: {
                                            "auto-grow": "",
                                            rows: "1",
                                            label: "Details",
                                            filled: ""
                                          },
                                          model: {
                                            value: _vm.details1,
                                            callback: function($$v) {
                                              _vm.details1 = $$v
                                            },
                                            expression: "details1"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "6" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        outlined: "",
                                        dense: "",
                                        "hide-details": "",
                                        items: _vm.choices,
                                        label: "But lose..."
                                      },
                                      model: {
                                        value: _vm.loss,
                                        callback: function($$v) {
                                          _vm.loss = $$v
                                        },
                                        expression: "loss"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "p",
                          { staticClass: "font-weight-bold px-3" },
                          [
                            _vm._v(
                              "\n            Gain two reserves:\n            "
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "ma-2" },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "hide-details": "",
                                          outlined: "",
                                          dense: "",
                                          items: _vm.choices,
                                          label: "You gain..."
                                        },
                                        model: {
                                          value: _vm.reserve1,
                                          callback: function($$v) {
                                            _vm.reserve1 = $$v
                                          },
                                          expression: "reserve1"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.reserve1
                                        ? _c("v-textarea", {
                                            attrs: {
                                              "auto-grow": "",
                                              rows: "1",
                                              label: "Details",
                                              filled: ""
                                            },
                                            model: {
                                              value: _vm.details1,
                                              callback: function($$v) {
                                                _vm.details1 = $$v
                                              },
                                              expression: "details1"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "div",
                                    { staticClass: "ma-2" },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "hide-details": "",
                                          outlined: "",
                                          dense: "",
                                          items: _vm.choices,
                                          label: "...as well as..."
                                        },
                                        model: {
                                          value: _vm.reserve2,
                                          callback: function($$v) {
                                            _vm.reserve2 = $$v
                                          },
                                          expression: "reserve2"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.reserve2
                                        ? _c("v-textarea", {
                                            attrs: {
                                              "auto-grow": "",
                                              rows: "1",
                                              label: "Details",
                                              filled: ""
                                            },
                                            model: {
                                              value: _vm.details2,
                                              callback: function($$v) {
                                                _vm.details2 = $$v
                                              },
                                              expression: "details2"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("cancel")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                tile: "",
                color: "primary",
                disabled: _vm.addDisabled
              },
              on: { click: _vm.addReserve }
            },
            [_vm._v("\n      add reserve\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }