var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-sidebar-view",
    [
      _vm._l(_vm.talents, function(t, i) {
        return _c(
          "v-list-item",
          {
            key: i + "_sidebar'",
            attrs: { slot: "sidebar", link: "" },
            on: {
              click: function($event) {
                return _vm.$vuetify.goTo("#e_" + t.ID, {
                  duration: 150,
                  easing: "easeInOutQuad",
                  offset: 25
                })
              }
            },
            slot: "sidebar"
          },
          [
            _c("v-list-item-title", { staticClass: "heading h3 ml-2" }, [
              _vm._v(_vm._s(t.Name))
            ])
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", align: "center" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "heading h1 mt-3 mb-2" }, [
              _vm._v("PILOT TALENTS")
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn-toggle",
                {
                  attrs: { mandatory: "" },
                  model: {
                    value: _vm.ctype,
                    callback: function($$v) {
                      _vm.ctype = $$v
                    },
                    expression: "ctype"
                  }
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { value: "full" } },
                    [_c("v-icon", [_vm._v("mdi-view-stream")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { value: "terse" } },
                    [_c("v-icon", [_vm._v("mdi-view-list")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { value: "small" } },
                    [_c("v-icon", [_vm._v("mdi-view-comfy")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "pb-3", attrs: { justify: "center" } },
        _vm._l(_vm.talents, function(t) {
          return _c("cc-talent", {
            key: t.ID + "_data'",
            attrs: {
              id: "e_" + t.ID,
              talent: t,
              terse: _vm.ctype === "terse",
              small: _vm.ctype === "small"
            }
          })
        }),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }