var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.readonly
        ? _c(
            "v-toolbar",
            {
              staticClass: "sliced",
              attrs: {
                id: "toolbar",
                color: _vm.color,
                dark: "",
                flat: "",
                dense: "",
                "max-height": "30px"
              }
            },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "mt-n3", staticStyle: { "max-height": "30px" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "", top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      staticClass: "fadeSelect mt-n2",
                                      attrs: { left: "" }
                                    },
                                    on
                                  ),
                                  [_vm._v("mdi-menu")]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        4072243947
                      )
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-list",
                        { staticClass: "px-2 py-3" },
                        [
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "overline" },
                            [_vm._v("Available Loadouts")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.loadouts, function(l, i) {
                            return _c(
                              "v-list-item",
                              {
                                key: "pl_" + i,
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("set-active", l)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "stat-text" },
                                  [_vm._v(_vm._s(l.Name))]
                                )
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          !_vm.readonly
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("add-loadout")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "accent--text font-weight-bold"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "primary", left: "" }
                                        },
                                        [_vm._v("add")]
                                      ),
                                      _vm._v(
                                        "\n              Add New Loadout\n            "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "l-title" },
                    [
                      !_vm.readonly
                        ? _c(
                            "cc-short-string-editor",
                            {
                              attrs: { inline: "" },
                              on: {
                                set: function($event) {
                                  _vm.activeLoadout.Name = $event
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.activeLoadout.Name) +
                                  "\n        "
                              )
                            ]
                          )
                        : _c("span", [_vm._v(_vm._s(_vm.activeLoadout.Name))])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              !_vm.readonly
                ? _c(
                    "v-toolbar-items",
                    { staticClass: "mr-6 mt-n4" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("clone-loadout")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-content-duplicate")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "fadeSelect",
                                          attrs: {
                                            small: "",
                                            icon: "",
                                            disabled: _vm.loadouts.length === 1
                                          }
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1712292183
                          ),
                          model: {
                            value: _vm.confirmMenu,
                            callback: function($$v) {
                              _vm.confirmMenu = $$v
                            },
                            expression: "confirmMenu"
                          }
                        },
                        [
                          _vm._v(" "),
                          _c("cc-confirmation", {
                            attrs: {
                              content:
                                "Lancer, please confirm deletion of:\n        <span class='accent--text'>\n          " +
                                _vm.activeLoadout.Name +
                                "\n        </span> Loadout"
                            },
                            on: { confirm: _vm.removeConfirm }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card",
        {
          attrs: {
            flat: "",
            outlined: !_vm.readonly,
            tile: "",
            color: _vm.color
          }
        },
        [
          _c(
            "v-card-text",
            { staticClass: "px-2 py-0 background" },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }