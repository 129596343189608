var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: {
        complete: !_vm.pilot.IsMissingCBs,
        exit: "/pilot/" + _vm.pilot.ID,
        back: ""
      },
      on: {
        back: function($event) {
          return _vm.$emit("back")
        },
        complete: function($event) {
          return _vm.$emit("next")
        }
      }
    },
    [
      _c("cc-title", { attrs: { large: "" } }, [_vm._v("Add CORE Bonus ")]),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "heading" },
        [
          _vm._v("\n    MV-2 License Acquisition Request\n    "),
          _c("cc-slashes"),
          _vm._v("\n     MV-2(a) CORE Supplemental\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "absolute", right: "16px", top: "16px" } },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Feature In Development" } },
            [
              _c(
                "v-btn",
                { attrs: { small: "", outlined: "", disabled: "" } },
                [_vm._v("Suggest CORE Bonus")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text", staticStyle: { "font-size": "14px" } },
        [
          _c("span", [
            _vm._v(
              "\n      The MV-2(a) Supplemental Amendment registers a major CORE modification, upgrade, retrofit,\n      or enhancement. The Union Naval Intelligence Board, the Union Naval Licensing Board, the\n      Union Administrative Pilot Advocacy Council, and The Union Department of Justice and Human\n      Resources Ethical Engineering Subcommittee only recognize CORE augmentation from a\n      Union-approved and registered Lincensor.\n    "
            )
          ]),
          _vm._v(" "),
          _vm.cbEligible
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-2",
                  attrs: {
                    color: "accent",
                    outlined: "",
                    dense: "",
                    border: "left"
                  }
                },
                [
                  _c("b", [_vm._v("Select a CORE Bonus")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "overline" }, [
                    _vm._v(
                      "\n        Implementing CORE augmentation protocols from a nonrecognized, phohibited, or unregistered\n        Licensor or Licensors renders the operating pilot liable for any and all damages resultant\n        from CORE malfunction, up to and including reactor meltdown.\n      "
                    )
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.cbEligible
        ? _c("cc-core-bonus-selector", {
            attrs: { "level-up": "", pilot: _vm.pilot }
          })
        : _c(
            "v-card",
            { attrs: { flat: "", tile: "", color: "panel" } },
            [
              _c(
                "v-card-text",
                { staticClass: "flavor-text text-center py-5 px-3" },
                [
                  _c(
                    "span",
                    { staticClass: "heading h2 subtle--text text--darken-1" },
                    [_vm._v("PILOT INELIGIBLE FOR CORE BONUS")]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "subtle--text text--darken-2" }, [
                    _vm._v(
                      "\n        NEXT CORE BONUS IN " +
                        _vm._s(3 - (_vm.pilot.Level % 3)) +
                        " LICENSE LEVELS\n      "
                    )
                  ])
                ]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }