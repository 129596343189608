var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticStyle: {
        "padding-bottom": "2px",
        "border-top": "2px solid var(--v-primary-base)"
      },
      attrs: { fixed: "" }
    },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "80vw" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "mr-5",
                        staticStyle: {
                          "border-color": "var(--v-warning-base)"
                        },
                        attrs: { outlined: "", small: "" }
                      },
                      on
                    ),
                    [_vm._v("\n        NEXT ENCOUNTER\n      ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.rcDialog,
            callback: function($$v) {
              _vm.rcDialog = $$v
            },
            expression: "rcDialog"
          }
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    dense: "",
                    dark: "",
                    flat: "",
                    tile: "",
                    color: "warning darken-3 heading h2"
                  }
                },
                [_vm._v("\n        NEXT ENCOUNTER\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "p",
                    { staticClass: "flavor-text stark--text mt-2 mb-0 mx-6" },
                    [
                      _vm._v("\n          >//[\n          "),
                      _c("span", { staticClass: "accent--text" }, [
                        _vm._v("COMP/CON")
                      ]),
                      _vm._v("\n          :\n          "),
                      _c("span", { staticClass: "stark-text--text" }, [
                        _vm._v("Confirmation Required.")
                      ]),
                      _vm._v("\n          Continue?\n        ")
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-2", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "x-large": "",
                                tile: "",
                                color: "warning darken-3"
                              },
                              on: {
                                click: function($event) {
                                  _vm.rcDialog = false
                                  _vm.pilot.State.StartCombat()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n               Reactivate Combat Mode\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "80vw" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "mr-5",
                        staticStyle: { "border-color": "var(--v-error-base)" },
                        attrs: { outlined: "", small: "" }
                      },
                      on
                    ),
                    [_vm._v("\n        END MISSION\n      ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.emDialog,
            callback: function($$v) {
              _vm.emDialog = $$v
            },
            expression: "emDialog"
          }
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    dense: "",
                    dark: "",
                    flat: "",
                    tile: "",
                    color: "warning darken-3 heading h2"
                  }
                },
                [_vm._v("\n        END MISSION\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "p",
                    { staticClass: "flavor-text stark--text mt-2 mb-0 mx-6" },
                    [
                      _vm._v("\n          >//[\n          "),
                      _c("span", { staticClass: "accent--text" }, [
                        _vm._v("COMP/CON")
                      ]),
                      _vm._v("\n          :\n          "),
                      _c("span", { staticClass: "stark-text--text" }, [
                        _vm._v("Confirmation Required")
                      ]),
                      _vm._v(
                        "\n          ] Pilot, proceeding will end the current mission and write all combat telemetry data to\n          your record. This cannot be undone. Continue?\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-row", {
                    staticClass: "mt-n2",
                    attrs: { justify: "center", "no-gutters": "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-alert",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        dense: "",
                        outlined: "",
                        color: _vm.pilot.ActiveMech.Frame.Manufacturer.Color
                      }
                    },
                    [
                      _c("span", { staticClass: "text--text" }, [
                        _vm._v(
                          "\n            The following mission data will be added to the Pilot Combat Telemetry Record:\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pl-2 pr-4 flavor-text" }, [
                        _c("div", [
                          _vm._v("\n              MOVES:\n              "),
                          _c("b", { staticClass: "stark--text" }, [
                            _vm._v(_vm._s(_vm.pilot.State.Stats.moves))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n              DAMAGE DEALT:\n              "
                          ),
                          _c("b", { staticClass: "stark--text" }, [
                            _vm._v(_vm._s(_vm.pilot.State.Stats.damage))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n              ENEMIES DESTROYED:\n              "
                          ),
                          _c("b", { staticClass: "stark--text" }, [
                            _vm._v(_vm._s(_vm.pilot.State.Stats.kills))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n              DAMAGE TAKEN:\n              "
                          ),
                          _c("b", { staticClass: "stark--text" }, [
                            _vm._v(_vm._s(_vm.pilot.State.Stats.hp_damage))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n              STRUCTURE LOST:\n              "
                          ),
                          _c("b", { staticClass: "stark--text" }, [
                            _vm._v(
                              _vm._s(_vm.pilot.State.Stats.structure_damage)
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("\n              HEAT TAKEN:\n              "),
                          _c("b", { staticClass: "stark--text" }, [
                            _vm._v(_vm._s(_vm.pilot.State.Stats.heat_damage))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n              REACTOR STRESS:\n              "
                          ),
                          _c("b", { staticClass: "stark--text" }, [
                            _vm._v(_vm._s(_vm.pilot.State.Stats.reactor_damage))
                          ])
                        ])
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-2", attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                "x-large": "",
                                tile: "",
                                color: "warning darken-3"
                              },
                              on: {
                                click: function($event) {
                                  _vm.emDialog = false
                                  _vm.pilot.State.EndMission()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n               Complete Mission\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("span", { staticClass: "flavor-text" }, [
        _vm._v("\n    >//[\n    "),
        _c("span", { staticClass: "accent--text" }, [_vm._v("COMP/CON")]),
        _vm._v("\n    :\n    "),
        _c("span", { staticClass: "stark-text--text" }, [
          _vm._v("Field Repair Protocols Active")
        ]),
        _vm._v("\n    ]\n  ")
      ]),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }