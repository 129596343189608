var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-dialog",
    { attrs: { "no-dismiss": "", large: "" } },
    [
      _c(
        "span",
        {
          staticClass: "white--text",
          staticStyle: { width: "100%" },
          attrs: { slot: "button" },
          slot: "button"
        },
        [
          _c("v-icon", { attrs: { dark: "" } }, [_vm._v("cci-drone")]),
          _vm._v("\n    " + _vm._s(_vm.deployable.name) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("v-icon", { attrs: { left: "", large: "", dark: "" } }, [
            _vm._v("cci-drone")
          ]),
          _vm._v("\n    " + _vm._s(_vm.deployable.name) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-chip",
        {
          staticClass: "stat-text mr-2 pr-0 mt-4",
          attrs: {
            slot: "title-items",
            dark: "",
            small: "",
            outlined: "",
            label: ""
          },
          slot: "title-items"
        },
        [
          _vm._v("\n    DEPLOY \n    "),
          _c(
            "v-chip",
            {
              attrs: {
                small: "",
                label: "",
                dark: "",
                color: "action--" + _vm.activation.toLowerCase()
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.activation.toUpperCase()) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("deployable-info-base", { attrs: { deployable: _vm.deployable } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }