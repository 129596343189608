var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.cType, {
    tag: "component",
    attrs: {
      action: _vm.action,
      "no-action": _vm.noAction,
      activations: _vm.activations,
      unusable: _vm.unusable,
      disabled: _vm.disabled
    },
    on: {
      use: function($event) {
        return _vm.$emit("use", $event)
      },
      undo: function($event) {
        return _vm.$emit("undo")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }