var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "mt-4 " + (_vm.$vuetify.breakpoint.mdAndDown ? "text-center" : "")
    },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-progress-circular", {
                attrs: {
                  size: 80,
                  width: 5,
                  color: "primary",
                  indeterminate: ""
                }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "heading h2 accent--text text-center mt-2 mb-1"
                },
                [_vm._v("LANCER by:")]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(_vm.credits.writers, function(c) {
                  return _c("dev-badge", {
                    key: c.name,
                    attrs: { info: c, cols: 6 }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "heading h2 accent--text text-center mt-2 mb-1"
                },
                [_vm._v("COMP/CON by:")]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(_vm.credits.lead_devs, function(c) {
                  return _c("dev-badge", {
                    key: c.name,
                    attrs: { info: c, cols: 12 }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "heading h2 accent--text text-center mt-2 mb-1"
                },
                [_vm._v("With:")]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                _vm._l(_vm.credits.devs, function(c) {
                  return _c("dev-badge", {
                    key: c.name,
                    attrs: { info: c, cols: 6 }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "heading h2 accent--text text-center mt-2 mb-1"
                },
                [_vm._v("Graphic design by:")]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                _vm._l(_vm.credits.graphics, function(c) {
                  return _c("dev-badge", {
                    key: c.name,
                    attrs: { info: c, cols: 4 }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "heading h2 accent--text text-center mt-2 mb-1"
                },
                [_vm._v("Additional art by:")]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                _vm._l(_vm.credits.art, function(c) {
                  return _c("dev-badge", {
                    key: c.name,
                    attrs: { info: c, cols: 4 }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-3" }),
              _vm._v(" "),
              _c("div", { staticClass: "text-center mt-6 mb-2" }, [
                _c("span", { staticClass: "heading h2 text--text" }, [
                  _vm._v(
                    "\n        The continued development of COMP/CON would not be possible without the generous\n        "
                  ),
                  _c("a", {
                    attrs: {
                      target: "_blank",
                      href: "https://www.patreon.com/compcon"
                    },
                    domProps: { innerHTML: _vm._s("support") }
                  }),
                  _vm._v("\n        of:\n      ")
                ])
              ]),
              _vm._v(" "),
              _vm._l(_vm.tiers, function(t, i) {
                return _c(
                  "v-row",
                  { key: "tier_" + t },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "cc-title",
                          { staticClass: "mb-n4", attrs: { small: "" } },
                          [_vm._v(_vm._s(t.toUpperCase()) + " TIER")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.patrons[t], function(p) {
                      return _c(
                        "v-col",
                        {
                          key: "tier_" + t + "_patron_" + p.Name,
                          attrs: {
                            dense: "",
                            cols: _vm.isActive(p) ? _vm.cols[i] : 3
                          }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-img", {
                                    staticClass: "mr-n5",
                                    style: _vm.$vuetify.theme.dark
                                      ? "filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);"
                                      : "filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(96%) contrast(104%);",
                                    attrs: {
                                      src:
                                        "/static/img/misc/patreon_tiers/" +
                                        t +
                                        ".svg",
                                      "max-height": _vm.isActive(p)
                                        ? "100px"
                                        : "50px",
                                      "max-width": _vm.isActive(p)
                                        ? "100px"
                                        : "50px"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isActive(p)
                                ? _c("v-col", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "heading h2 accent--text"
                                      },
                                      [_vm._v(_vm._s(p.Name))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flavor-text font-weight-bold"
                                      },
                                      [
                                        _vm._v(
                                          "\n              STATUS//[\n              "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "success--text" },
                                          [_vm._v("ACTIVE")]
                                        ),
                                        _vm._v(
                                          "\n              ]\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    p["Patronage Since Date"]
                                      ? _c(
                                          "div",
                                          { staticClass: "flavor--text" },
                                          [
                                            _vm._v(
                                              "\n              Since " +
                                                _vm._s(
                                                  p[
                                                    "Patronage Since Date"
                                                  ].substring(0, 10)
                                                ) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                : _c("v-col", [
                                    _c("div", { staticClass: "heading h3" }, [
                                      _vm._v(_vm._s(p.Name))
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("Former Patron")])
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _c("span", { staticClass: "heading h2 text--text mt-4" }, [
                _vm._v("Special Thanks to:")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "my-2" },
                _vm._l(_vm.credits.special_thanks, function(c) {
                  return _c("special-thanks", { key: c, attrs: { name: c } })
                }),
                1
              )
            ],
            2
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }