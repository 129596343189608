var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: {
        complete: _vm.canContinue,
        exit: "/pilot/" + _vm.pilot.ID,
        back: ""
      },
      on: {
        back: function($event) {
          return _vm.$emit("back")
        },
        complete: function($event) {
          return _vm.$emit("next")
        }
      }
    },
    [
      _c("cc-title", { attrs: { large: "" } }, [
        _vm._v("Improve Mech Skills ")
      ]),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "heading" },
        [
          _vm._v("\n    MV-2 License Acquisition Request\n    "),
          _c("cc-slashes"),
          _vm._v("\n     MV-2//c Frame Configuration Update\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "absolute", right: "16px", top: "16px" } },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Feature In Development" } },
            [
              _c(
                "v-btn",
                { attrs: { small: "", outlined: "", disabled: "" } },
                [_vm._v("Suggest Mech Skills")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text", staticStyle: { "font-size": "14px" } },
        [
          _c("span", [
            _vm._v(
              "\n      On acceptance of the MV-2//c Frame Configuration Update the Union IDENT//eng subsystem will\n      automatically update the frame configuration protocols stored in the Omninet Vault address\n      registered to your IDENT profile (V.node::/" +
                _vm._s(_vm.pilot.ID) +
                "). To ensure configuration data is\n      remitted to the correct address, any changes in Vault node data must be submitted to the\n      IDENT service using form RM-09-E IDENT Supplemental (e)\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "mt-2",
              attrs: {
                color: "accent",
                outlined: "",
                dense: "",
                border: "left"
              }
            },
            [
              _c("b", [_vm._v("Improve a Mech Skill.")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  "\n        Union and its representatives are not liable for problems arising from FRAME configuration\n        options that exceed manufacturer specifications. Consult the official FRAME datastore\n        provided by the manufacturer prior to implementing any nonstandard CORE configuration.\n      "
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-mech-skills-selector", {
        attrs: { "level-up": "", pilot: _vm.pilot }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }