var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { attrs: { flat: "", tile: "", color: "transparent" } }, [
    _c(
      "fieldset",
      [
        _c(
          "legend",
          { staticClass: "heading h3", style: "color: " + _vm.color },
          [_vm._v("\n      Systems\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { position: "relative" } }, [
          _c("div", { staticClass: "side-legend" }, [
            _c(
              "span",
              {
                class:
                  "heading h3 " +
                  (_vm.mech.FreeSP < 0
                    ? "error--text"
                    : "subtle--text text--darken-3")
              },
              [
                _vm.mech.FreeSP < 0
                  ? _c("v-icon", { attrs: { color: "error", left: "" } }, [
                      _vm._v("mdi-alert")
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.mech.FreeSP) +
                    " / " +
                    _vm._s(_vm.mech.MaxSP) +
                    "\n          "
                ),
                _c("span", { staticClass: "overline" }, [_vm._v("SP")])
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "v-row",
          { staticClass: "mx-2", attrs: { dense: "" } },
          [
            _vm._l(_vm.mech.ActiveLoadout.IntegratedSystems, function(s, i) {
              return _c("active-system-card", {
                key: s.ID + "-" + i + "-intg",
                attrs: {
                  mech: _vm.mech,
                  item: s,
                  color: _vm.color,
                  rest: _vm.rest,
                  readonly: ""
                }
              })
            }),
            _vm._v(" "),
            _vm._l(_vm.moddedWeapons, function(w, i) {
              return _c(
                "v-col",
                {
                  key: w.ID + "-" + i + "-mod",
                  attrs: { cols: "12", lg: "6" }
                },
                [
                  _c("mod-equipped-card", {
                    attrs: {
                      mech: _vm.mech,
                      mod: w.Mod,
                      weapon: w,
                      color: _vm.color,
                      readonly: "",
                      rest: _vm.rest
                    },
                    on: {
                      remove: function($event) {
                        w.Mod = null
                      }
                    }
                  })
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.mech.ActiveLoadout.Systems, function(s, i) {
              return _c("active-system-card", {
                key: s.ID + "-" + i,
                attrs: {
                  mech: _vm.mech,
                  item: s,
                  color: _vm.color,
                  index: i,
                  rest: _vm.rest,
                  readonly: _vm.readonly
                }
              })
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }