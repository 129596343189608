var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "equipment-card-base",
    { attrs: { item: _vm.item } },
    [
      _c(
        "v-container",
        {
          staticClass: "mt-0 pt-1",
          attrs: { slot: "statblock", "grid-list-md": "" },
          slot: "statblock"
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c("cc-statblock-panel", {
                attrs: {
                  icon: "$vuetify.icons.armor",
                  name: "Armor",
                  value: _vm.armor
                }
              }),
              _vm._v(" "),
              _c("cc-statblock-panel", {
                attrs: {
                  icon: "$vuetify.icons.hp",
                  name: "HP Bonus",
                  value: "+" + _vm.hp
                }
              }),
              _vm._v(" "),
              _c("cc-statblock-panel", {
                attrs: {
                  icon: "$vuetify.icons.edef",
                  name: "E-Defense",
                  value: _vm.edef
                }
              }),
              _vm._v(" "),
              _c("cc-statblock-panel", {
                attrs: {
                  icon: "$vuetify.icons.evasion",
                  name: "Evasion",
                  value: _vm.evasion
                }
              }),
              _vm._v(" "),
              _c("cc-statblock-panel", {
                attrs: {
                  icon: "$vuetify.icons.speed",
                  name: "Speed",
                  value: _vm.speed
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }