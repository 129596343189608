var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: { "margin-top": "50px", "min-height": "calc(100vh - 50px)" },
      attrs: { fluid: "" }
    },
    [
      _c("div", { staticClass: "overline" }, [_vm._v("//MISSION COMPLETE")]),
      _vm._v(" "),
      _c("div", { staticClass: "heading mech mt-n6" }, [
        _vm._v(_vm._s(_vm.mission.Mission.Name))
      ]),
      _vm._v(" "),
      _c("p", {
        staticClass: "text-center flavor-text panel text--text",
        domProps: { innerHTML: _vm._s(_vm.mission.Mission.Note) }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "heading h3 accent--text mt-1" }, [
              _vm._v("\n        Result:\n      ")
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto ml-1" } },
            [
              _c("v-combobox", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  items: _vm.resolutions
                },
                model: {
                  value: _vm.mission.Result,
                  callback: function($$v) {
                    _vm.$set(_vm.mission, "Result", $$v)
                  },
                  expression: "mission.Result"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-3" }),
      _vm._v(" "),
      _c("cc-title", [_vm._v("\n    After-Action Report\n  ")]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "panel heading h3 py-3 text-center subtle--text" },
        [
          _c("v-icon", { attrs: { large: "", color: "grey" } }, [
            _vm._v("mdi-lock")
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n    // FEATURE IN DEVELOPMENT //\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-title", [_vm._v("\n    Campaign Progression\n  ")]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "panel heading h3 py-3 text-center subtle--text" },
        [
          _c("v-icon", { attrs: { large: "", color: "grey" } }, [
            _vm._v("mdi-lock")
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n    // FEATURE IN DEVELOPMENT //\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        { attrs: { text: "", color: "primary", to: "/gm/mission/selector" } },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v("mdi-chevron-triple-left")
          ]),
          _vm._v("\n    Return to Mission Menu\n  ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }