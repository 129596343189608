var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: { complete: _vm.pilot.HasIdent, exit: "pilot_management" },
      on: {
        complete: function($event) {
          return _vm.$emit("next")
        }
      }
    },
    [
      _c("cc-title", { attrs: { large: "" } }, [
        _vm._v("New Pilot Registration ")
      ]),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "heading" },
        [
          _vm._v("\n    UAD IDENT Service\n    "),
          _c("cc-slashes"),
          _vm._v("\n     RM-4 Personnel::Pilot (C)\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text", staticStyle: { "font-size": "14px" } },
        [
          _c("div", { staticClass: "mt-n2" }, [
            _vm._v(
              "\n      Welcome to the Union Administrative Department's IDENT registration service. IDENT is the\n      omninet-based certification system that guides the user through the UAD's pilot registration\n      process. IDENT helps ensure pilots meet regulatory and policy requirements through the use\n      of NHP-directed data validation protocols. Union Regulars that have already been issued an\n      RM-4 IDENT fingerprint should not complete this form unless instructed to by their\n      commanding officer.\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "mt-2",
              attrs: {
                type: "warning",
                color: "accent",
                outlined: "",
                dense: "",
                prominent: ""
              }
            },
            [
              _c(
                "b",
                [
                  _vm._v("\n        All fields marked with the\n        "),
                  _c("v-icon", { attrs: { color: "error" } }, [
                    _vm._v("mdi-alert")
                  ]),
                  _vm._v("\n        glyph must be populated.\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "overline",
                  staticStyle: { "line-height": "13px" }
                },
                [
                  _vm._v(
                    "\n        By submitting this form you attest that your responses are truthful and accurate to the\n        best of your knowledge. Knowingly providing false or or incomplete information is\n        punishable under DoJ/HR AR 303-J.\n      "
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-6" },
        [
          _c(
            "v-col",
            { staticClass: "mr-auto", attrs: { cols: "5" } },
            [
              _c("span", { staticClass: "overline" }, [
                _vm._v("RM-4-01 // FULL NAME OR PRIMARY ALIAS")
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: { outlined: "", label: "Name", "hide-details": "" },
                on: {
                  change: function($event) {
                    return _vm.$emit("set", { attr: "Name", val: $event })
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function() {
                      return [
                        _c(
                          "cc-tooltip",
                          {
                            attrs: {
                              simple: "",
                              content: "Generate Random Name"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: { color: "secondary" },
                                on: {
                                  click: function($event) {
                                    return _vm.randomName()
                                  }
                                }
                              },
                              [_vm._v("mdi-dice-multiple")]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "append-outer",
                    fn: function() {
                      return [
                        !_vm.pilot.Name
                          ? _c("v-icon", { attrs: { color: "error" } }, [
                              _vm._v("mdi-alert")
                            ])
                          : _c("v-icon", { attrs: { color: "success" } }, [
                              _vm._v("mdi-check-circle-outline")
                            ])
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.pilot.Name,
                  callback: function($$v) {
                    _vm.$set(_vm.pilot, "Name", $$v)
                  },
                  expression: "pilot.Name"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  "\n        RM-4-02 // APPROVED CALLSIGN (OR CADET DESIGNATION, IF APPLICABLE)\n      "
                )
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: { outlined: "", label: "Callsign", "hide-details": "" },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function() {
                      return [
                        _c(
                          "cc-tooltip",
                          {
                            attrs: {
                              simple: "",
                              content: "Generate Random Callsign"
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: { color: "secondary" },
                                on: {
                                  click: function($event) {
                                    return _vm.randomCallsign()
                                  }
                                }
                              },
                              [_vm._v("mdi-dice-multiple")]
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "append-outer",
                    fn: function() {
                      return [
                        !_vm.pilot.Callsign
                          ? _c("v-icon", { attrs: { color: "error" } }, [
                              _vm._v("mdi-alert")
                            ])
                          : _c("v-icon", { attrs: { color: "success" } }, [
                              _vm._v("mdi-check-circle-outline")
                            ])
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.pilot.Callsign,
                  callback: function($$v) {
                    _vm.$set(_vm.pilot, "Callsign", $$v)
                  },
                  expression: "pilot.Callsign"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  "RM-4-03 // PRIOR OCCUPATION OR POSITION (ANSWER 17b ON RM-2-C)"
                )
              ]),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  label: "Background",
                  "hide-details": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function() {
                      return [
                        _c(
                          "cc-tooltip",
                          {
                            attrs: {
                              simple: "",
                              content: "Select Predefined Background"
                            }
                          },
                          [
                            _c("cc-background-selector", {
                              on: {
                                select: function($event) {
                                  return _vm.$emit("set", {
                                    attr: "Background",
                                    val: $event
                                  })
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "append-outer",
                    fn: function() {
                      return [
                        !_vm.pilot.Background
                          ? _c("v-icon", { attrs: { color: "grey" } }, [
                              _vm._v("mdi-circle-outline")
                            ])
                          : _c("v-icon", { attrs: { color: "success" } }, [
                              _vm._v("mdi-check-circle-outline")
                            ])
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.pilot.Background,
                  callback: function($$v) {
                    _vm.$set(_vm.pilot, "Background", $$v)
                  },
                  expression: "pilot.Background"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  "RM-4-04 // ATTACHED BIOGRAPHICAL DOSSIER RM-4b SUPPLEMENTAL"
                )
              ]),
              _vm._v(" "),
              _c(
                "text-entry-popup",
                {
                  attrs: {
                    label: "Pilot Biography",
                    prepopulate: _vm.pilot.History
                  },
                  on: {
                    save: function($event) {
                      return _vm.$emit("set", { attr: "History", val: $event })
                    }
                  }
                },
                [
                  !_vm.pilot.History
                    ? _c(
                        "span",
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus")
                          ]),
                          _vm._v("\n          Add Pilot Biography\n        ")
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-circle-edit-outline")
                          ]),
                          _vm._v("\n          Edit Pilot Biography\n        ")
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { position: "absolute", right: "-53px" } },
                    [
                      !_vm.pilot.History
                        ? _c("v-icon", { attrs: { color: "grey" } }, [
                            _vm._v("mdi-circle-outline")
                          ])
                        : _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-check-circle-outline")
                          ])
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "overline" }, [
                _vm._v("RM-4-05 // ATTACHED OHM HEALTH EXAMINATION RESULTS")
              ]),
              _vm._v(" "),
              _c(
                "text-entry-popup",
                {
                  attrs: {
                    label: "Pilot Description",
                    prepopulate: _vm.pilot.TextAppearance
                  },
                  on: {
                    save: function($event) {
                      return _vm.$emit("set", {
                        attr: "TextAppearance",
                        val: $event
                      })
                    }
                  }
                },
                [
                  !_vm.pilot.TextAppearance
                    ? _c(
                        "span",
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus")
                          ]),
                          _vm._v("\n          Add Pilot Description\n        ")
                        ],
                        1
                      )
                    : _c(
                        "span",
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-circle-edit-outline")
                          ]),
                          _vm._v("\n          Edit Pilot Description\n        ")
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { position: "absolute", right: "-53px" } },
                    [
                      !_vm.pilot.TextAppearance
                        ? _c("v-icon", { attrs: { color: "grey" } }, [
                            _vm._v("mdi-circle-outline")
                          ])
                        : _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-check-circle-outline")
                          ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "ml-auto mr-auto text-center",
              attrs: { cols: "1" }
            },
            [_c("v-divider", { attrs: { vertical: "" } })],
            1
          ),
          _vm._v(" "),
          _c("v-col", { staticClass: "ml-auto", attrs: { cols: "5" } }, [
            _c("span", { staticClass: "overline" }, [
              _vm._v(
                "\n        RM-4-06 // ATTACHED OHM IMAGING SCAN (MUST INCLUDE RETINAL DATA)\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "border mr-8 ml-auto mr-auto",
                staticStyle: { width: "300px", height: "300px" }
              },
              [
                _vm.pilot.Portrait
                  ? _c("v-img", {
                      key: _vm.pilot.Image,
                      attrs: { src: _vm.pilot.Portrait, "aspect-ratio": "1" }
                    })
                  : _c("v-img", {
                      attrs: { src: "https://via.placeholder.com/550" }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mr-8 mt-3" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      outlined: "",
                      large: "",
                      block: "",
                      color: "secondary"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$refs.imageSelector.open()
                      }
                    }
                  },
                  [
                    !_vm.pilot.Portrait
                      ? _c(
                          "span",
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("mdi-plus")
                            ]),
                            _vm._v("\n            Add Pilot Image\n          ")
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("mdi-circle-edit-outline")
                            ]),
                            _vm._v("\n            Edit Pilot Image\n          ")
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { position: "absolute", right: "-53px" } },
                      [
                        !_vm.pilot.Portrait
                          ? _c("v-icon", { attrs: { color: "grey" } }, [
                              _vm._v("mdi-circle-outline")
                            ])
                          : _c("v-icon", { attrs: { color: "success" } }, [
                              _vm._v("mdi-check-circle-outline")
                            ])
                      ],
                      1
                    )
                  ]
                ),
                _vm._v(" "),
                _c("cc-image-selector-web", {
                  ref: "imageSelector",
                  attrs: { item: _vm.pilot, type: "pilot" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center", attrs: { slot: "other" }, slot: "other" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: {
                color: "accent",
                disabled: !_vm.pilot.HasIdent,
                large: ""
              },
              on: {
                click: function($event) {
                  return _vm.$emit("templates")
                }
              }
            },
            [_vm._v("\n      Select Character Template\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "overline stark--text" }, [
            _vm._v("Recommended for New Players")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }