var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-title",
        {
          staticClass: "pl-3",
          staticStyle: { "margin-left": "-70px!important" },
          attrs: { small: "", color: "pilot" }
        },
        [
          _c("section-edit-icon", {
            attrs: { label: "Edit Pilot Biography" },
            on: {
              "open-selector": function($event) {
                return _vm.show()
              }
            }
          }),
          _vm._v("\n    Pilot Biography\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "my-2" },
        [
          _vm.pilot.History
            ? _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.pilot.History,
                    expression: "pilot.History"
                  }
                ],
                staticClass: "flavor-text text--text mx-2 preserve-linebreaks"
              })
            : _c("no-data-block")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            icon: "mdi-circle-edit-outline",
            color: "primary",
            large: "",
            title: "Pilot Biography"
          },
          on: {
            confirm: function($event) {
              _vm.pilot.History = _vm.history
            }
          }
        },
        [
          _c("tiptap-vuetify", {
            staticClass: "mt-4",
            attrs: {
              extensions: _vm.extensions,
              "card-props": { flat: true, tile: true, elevation: 0 },
              "toolbar-attributes": _vm.$vuetify.theme.dark
                ? { color: "black", dark: true }
                : {}
            },
            model: {
              value: _vm.history,
              callback: function($$v) {
                _vm.history = $$v
              },
              expression: "history"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }