var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    [
      _c(
        "v-card",
        {
          staticClass: "mx-4 my-2 mod-border",
          attrs: { flat: "", tile: "", color: "transparent" }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "mod darken-3 py-0 pt-n1 heading h3 white--text",
              staticStyle: { height: "24px!important" }
            },
            [
              _c(
                "v-row",
                { staticClass: "mt-n1", attrs: { "no-gutters": "" } },
                [
                  _c("equipment-options", {
                    attrs: { slot: "options", item: _vm.mod },
                    slot: "options"
                  }),
                  _vm._v(" "),
                  _vm.mod.Destroyed
                    ? _c(
                        "span",
                        {
                          staticClass: "error",
                          staticStyle: { "text-decoration": "line-through" }
                        },
                        [_vm._v("\n          DESTROYED\n        ")]
                      )
                    : _c("span", [
                        _vm._v(
                          "\n          " + _vm._s(_vm.mod.Name) + "\n        "
                        )
                      ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.mod.SP
                    ? _c("span", { staticClass: "pr-3" }, [
                        _vm._v(_vm._s(_vm.mod.SP) + "SP")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "cc-tooltip",
                    {
                      attrs: { simple: "", inline: "", content: "Remove Mod" }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mt-n1 fadeSelect",
                          attrs: { dark: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("remove-mod")
                            }
                          }
                        },
                        [_vm._v("\n            mdi-delete\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mod-border px-2 pt-1" },
            [
              _c("equipment-header", {
                staticClass: "mt-n1",
                attrs: {
                  item: _vm.mod,
                  "use-bonus": _vm.mech.Pilot.LimitedBonus,
                  mod: "",
                  interior: "",
                  dark: ""
                }
              }),
              _vm._v(" "),
              _vm.mod.IsCascading
                ? _c(
                    "v-alert",
                    {
                      staticClass: "text-center white--text stat-text",
                      staticStyle: { "letter-spacing": "3px" },
                      attrs: { dense: "", tile: "", color: "error" }
                    },
                    [_vm._v("\n        / / NHP IN CASCADE / /\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "py-1" }, [
                _c(
                  "div",
                  { staticClass: "overline mb-n2" },
                  [
                    _c("v-icon", [_vm._v("cci-system")]),
                    _vm._v("\n          EQUIPMENT EFFECT\n        ")
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", {
                  staticClass: "text--text body-text mb-1 mr-3 ml-7",
                  domProps: { innerHTML: _vm._s(_vm.mod.Effect) }
                })
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "text-left",
                  attrs: { dense: "", align: "end" }
                },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "space-around", dense: "" } },
                        [
                          _vm.mod.Actions.length
                            ? _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("div", { staticClass: "overline ml-n2" }, [
                                    _vm._v("EQUIPMENT ACTIONS")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        "no-gutters": "",
                                        justify: "center"
                                      }
                                    },
                                    _vm._l(_vm.mod.Actions, function(a, i) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: _vm.mod.Name + "_action_" + i,
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("cc-action", {
                                            staticClass: "ma-2",
                                            attrs: {
                                              action: a,
                                              panel:
                                                _vm.$vuetify.breakpoint.lgAndUp
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mod.Deployables.length
                            ? _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("div", { staticClass: "overline ml-n2" }, [
                                    _vm._v("EQUIPMENT DEPLOYABLES")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        "no-gutters": "",
                                        justify: "center"
                                      }
                                    },
                                    _vm._l(_vm.mod.Deployables, function(d, i) {
                                      return _c(
                                        "v-col",
                                        {
                                          key:
                                            _vm.mod.Name + "_deployable_" + i,
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("cc-deployable-info", {
                                            staticClass: "ma-2",
                                            attrs: {
                                              deployable: d,
                                              panel:
                                                _vm.$vuetify.breakpoint.lgAndUp,
                                              "name-override": _vm.mod.Name
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mr-3 mt-n3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("cc-tags", {
                        attrs: {
                          small: "",
                          tags: _vm.mod.Tags,
                          color: _vm.color
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [_c("cc-bonus-display", { attrs: { item: _vm.mod } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("cc-synergy-display", {
                        attrs: {
                          item: _vm.mod,
                          location: "mod",
                          mech: _vm.mech,
                          large: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "cc-solo-dialog",
            {
              ref: "detailDialog",
              attrs: { "no-confirm": "", title: _vm.mod.Name, large: "" }
            },
            [_c("cc-item-card", { attrs: { item: _vm.mod } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }