

















































import Vue from 'vue'
import { CompendiumItem } from '@/class'
import { accentInclude } from '@/classes/utility/accent_fold'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'search-results',
  data: () => ({
    searchText: '',
    loaded: false,
  }),
  computed: {
    validResults(): CompendiumItem[] {
      const compendium = getModule(CompendiumStore, this.$store)

      return this.$_.flatten(
        this.$_.values(
          this.$_.pick(compendium, ['Frames', 'MechSystems', 'MechWeapons', 'WeaponMods'])
        )
      )
    },
    searchResults(): CompendiumItem[] {
      if (!this.searchText) {
        return []
      }
      const results = this.validResults.filter(
        r => !r.IsHidden && accentInclude(r.Name, this.searchText)
      )
      return results
    },
  },
  mounted() {
    this.searchText = this.$route.query.search as string
    const input = this.$refs.input as HTMLInputElement
    input.focus()
  },
  methods: {
    setSearch(value: string) {
      if (value === this.searchText) {
        return
      }
      this.searchText = value
      this.$router.replace(`/compendium/search?search=${value}`)
    },
    forceInput() {
      this.setSearch((this.$refs.input as HTMLInputElement).value)
    },

    onClick(item: CompendiumItem) {
      alert(item.Name)
    },
  },
})
