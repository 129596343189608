var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: {
        complete: _vm.pilotReady,
        exit: "pilot_management",
        back: "",
        "no-confirm": ""
      },
      on: {
        back: function($event) {
          return _vm.$emit("back")
        }
      }
    },
    [
      _c("pilot-registration-card", {
        attrs: { pilot: _vm.pilot, "pilot-ready": _vm.pilotReady }
      }),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mx-2 my-8",
          attrs: {
            "x-large": "",
            block: "",
            disabled: !_vm.pilotReady,
            color: "secondary",
            tile: ""
          },
          on: {
            click: function($event) {
              return _vm.savePilot()
            }
          }
        },
        [
          _vm._v(
            "\n    Register New Pilot // " +
              _vm._s(_vm.pilot.Callsign || "ERR CALLSIGN NOT FOUND") +
              " (" +
              _vm._s(_vm.pilot.Name || "ERR NAME NOT FOUND") +
              ")\n  "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "v-alert",
        { attrs: { type: "error", outlined: "", value: !_vm.pilotReady } },
        [
          _c("span", { staticClass: "stat-text accent--text" }, [
            _vm._v(
              "\n      WARNING: IDENT record " +
                _vm._s(_vm.pilot.ID) +
                " cannot be generated due to the following reason(s):\n    "
            )
          ]),
          _vm._v(" "),
          _c("ul", { staticClass: "flavor-text error--text" }, [
            !_vm.pilot.Callsign
              ? _c("li", [_vm._v("PILOT CALLSIGN blank or invalid")])
              : _vm._e(),
            _vm._v(" "),
            !_vm.pilot.Name
              ? _c("li", [_vm._v("PILOT NAME blank or invalid")])
              : _vm._e(),
            _vm._v(" "),
            !_vm.pilot.HasFullSkills
              ? _c("li", [_vm._v("PILOT SKILL TRIGGERS missing or incomplete")])
              : _vm._e(),
            _vm._v(" "),
            !_vm.pilot.HasFullTalents
              ? _c("li", [_vm._v("PILOT TALENTS missing or incomplete")])
              : _vm._e(),
            _vm._v(" "),
            !_vm.pilot.HasFullHASE
              ? _c("li", [_vm._v("PILOT MECH SKILLS missing or incomplete")])
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }