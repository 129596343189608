


















































































































import Vue from 'vue'
import SlideItem from '../components/SlideItem.vue'
import PlayerCard from '../components/PlayerCard.vue'
import NpcCard from '../components/NpcCard.vue'
import EncounterNav from '../components/EncounterNav.vue'

export default Vue.extend({
  name: 'encounter-view',
  components: { SlideItem, PlayerCard, NpcCard, EncounterNav },
  props: {
    activeMission: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selectedActor: null,
  }),
  computed: {
    actors(): IActor[] {
      return this.players.concat(this.npcs)
    },
    players(): IActor[] {
      return this.activeMission.Pilots.map(x => x.ActiveMech)
    },
    npcs(): IActor[] {
      return this.activeMission.ActiveNpcs
    },
    selected(): IActor {
      return this.actors.find(x => x.ID === this.selectedActor)
    },
    initiative() {
      return this.actors.filter(x => x.Activations > 0 && !x.Defeat)
    },
    finished() {
      return this.actors.filter(x => x.Activations === 0 && !x.Defeat)
    },
    defeated() {
      return this.actors.filter(x => x.Defeat)
    },
  },
  watch: {
    selectedActor() {
      document.getElementById('scroll').scrollTop = 0
    },
  },
  methods: {
    isPlayer(a: any) {
      return !!a.Frame
    },
    stageRoundEnd() {
      if (this.initiative.length) {
        this.$refs.endConfirmDialog.show()
      } else this.commitRoundEnd()
    },
    commitRoundEnd() {
      this.$store.dispatch('cloudSync', { callback: null, condition: 'turnEnd' })
      this.finished.forEach((a: IActor) => {
        a.NewTurn()
      })
      this.activeMission.Round++
    },
    deleteActor() {
      if (this.isPlayer(this.selected)) {
        this.activeMission.RemovePilot(this.selected)
      } else {
        this.activeMission.RemoveActiveNpc(this.selected)
      }
    },
  },
})
