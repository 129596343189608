var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "50vw", value: _vm.value },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event)
        }
      }
    },
    [
      _c(
        "v-card",
        {
          staticStyle: {
            border: "6px double var(--v-panel-border-base) !important",
            "border-radius": "2px !important"
          },
          attrs: { id: "panel", tile: "", color: "black" }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "ml-auto mr-auto", attrs: { cols: "7" } },
                [_vm._t("default")],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            {
              staticClass: "subtle--text stat-text",
              staticStyle: { "min-height": "50vh" }
            },
            [
              _c("span", { ref: "preambleLog" }),
              _vm._v(" "),
              _c("span", { ref: "infoLog" })
            ]
          ),
          _vm._v(" "),
          _c("v-divider", { attrs: { dark: "" } }),
          _vm._v(" "),
          _vm.error
            ? _c(
                "v-card-actions",
                [
                  _c("span", { staticClass: "error--text flavor-text" }, [
                    _vm._v("ERROR IMPORTING PILOT: " + _vm._s(_vm.error))
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "white", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v("Dismiss")]
                  )
                ],
                1
              )
            : _vm.pilot
            ? _c(
                "v-card-actions",
                [
                  _c("span", { staticClass: "white--text flavor-text" }, [
                    _vm._v("\n        Import\n        "),
                    _c("b", [_vm._v(_vm._s(_vm.pilot.Callsign.toUpperCase()))]),
                    _vm._v("\n        //\n        "),
                    _c("b", [_vm._v(_vm._s(_vm.pilot.Name))]),
                    _vm._v("\n        ?\n      ")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "cc-btn",
                    {
                      staticClass: "mx-2",
                      attrs: { small: "", color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "cc-btn",
                    {
                      staticClass: "mx-2",
                      attrs: { small: "", color: "success" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("confirm")
                        }
                      }
                    },
                    [_vm._v("Confirm")]
                  )
                ],
                1
              )
            : _vm.loading
            ? _c(
                "v-card-actions",
                [
                  _c("span", { staticClass: "flavor-text" }, [
                    _vm._v("Loading...")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "white", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v("Dismiss")]
                  )
                ],
                1
              )
            : _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "grey", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v("Dismiss")]
                  )
                ],
                1
              ),
          _vm._v(" "),
          _c("v-progress-linear", {
            style: { visibility: _vm.loading ? "visible" : "hidden" },
            attrs: { indeterminate: "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }