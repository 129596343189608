var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-solo-dialog",
    {
      ref: "dialog",
      attrs: {
        icon: "mdi-text-subject",
        large: "",
        "no-confirm": "",
        title: "Pilot Statblock"
      }
    },
    [
      _c(
        "v-card-text",
        [
          _c("span", [_vm._v("Convert to Roll20...")]),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              items: _vm.pilot.Mechs,
              "item-text": "Name",
              "item-value": "ID",
              label: "Select Mech",
              required: "",
              outlined: ""
            },
            model: {
              value: _vm.mechSelect,
              callback: function($$v) {
                _vm.mechSelect = $$v
              },
              expression: "mechSelect"
            }
          }),
          _vm._v(" "),
          _c("v-btn", { on: { click: _vm.doConvert } }, [_vm._v("Convert")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }