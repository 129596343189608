var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          staticClass: "ml-n6",
          staticStyle: { width: "calc(100% + 48px)" },
          attrs: {
            color: "accent",
            "icons-and-text": "",
            "background-color": "primary",
            grow: "",
            "align-with-title": ""
          },
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "v-tab",
            [
              _vm._v("\n      Protocols\n      "),
              _c("v-icon", [_vm._v("cci-protocol")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            [
              _vm._v("\n      Full Actions\n      "),
              _c("v-icon", [_vm._v("mdi-hexagon-slice-6")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            [
              _vm._v("\n      Quick Actions\n      "),
              _c("v-icon", [_vm._v("mdi-hexagon-slice-3")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            [
              _vm._v("\n      Reactions\n      "),
              _c("v-icon", [_vm._v("cci-reaction")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            [
              _vm._v("\n      Free Actions\n      "),
              _c("v-icon", [_vm._v("cci-free-action")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            [
              _vm._v("\n      Combat Log\n      "),
              _c("v-icon", [_vm._v("mdi-notebook")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            { staticClass: "mr-12" },
            [
              _vm._v("\n      Other\n      "),
              _c("v-icon", [_vm._v("mdi-dots-vertical")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "background" } },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.pilot.State.ActionsByType("Protocol"), function(
                      a,
                      i
                    ) {
                      return _c("action-menu-item", {
                        key: "am_protocol_" + i,
                        attrs: {
                          action: a,
                          available: _vm.pilot.State.IsProtocolAvailable,
                          mech: _vm.pilot.ActiveMech
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "background" } },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.pilot.State.ActionsByType("Full"), function(
                      a,
                      i
                    ) {
                      return _c("action-menu-item", {
                        key: "am_protocol_" + i,
                        attrs: {
                          action: a,
                          available: _vm.pilot.State.Actions > 1,
                          mech: _vm.pilot.ActiveMech
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "background" } },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.pilot.State.ActionsByType("Quick"), function(
                      a,
                      i
                    ) {
                      return _c("action-menu-item", {
                        key: "am_protocol_" + i,
                        attrs: {
                          action: a,
                          mech: _vm.pilot.ActiveMech,
                          available: _vm.pilot.State.Actions > 0
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "background" } },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.pilot.State.ActionsByType("Reaction"), function(
                      a,
                      i
                    ) {
                      return _c("action-menu-item", {
                        key: "am_protocol_" + i,
                        attrs: {
                          action: a,
                          available: "",
                          mech: _vm.pilot.ActiveMech
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "background" } },
                [
                  _c(
                    "v-list",
                    _vm._l(_vm.pilot.State.ActionsByType("Free"), function(
                      a,
                      i
                    ) {
                      return _c("action-menu-item", {
                        key: "am_protocol_" + i,
                        attrs: {
                          action: a,
                          available: "",
                          mech: _vm.pilot.ActiveMech
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "background" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("combat-log-renderer", {
                        attrs: { state: _vm.pilot.State }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                { attrs: { flat: "", color: "background" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-divider", { staticClass: "my-4" }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("span", { staticClass: "overline" }, [
                              _vm._v("PILOT SKILL TRIGGERS")
                            ])
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "", justify: "center" } },
                        _vm._l(_vm.pilot.Skills, function(s, i) {
                          return _c("cc-active-card", {
                            key: "sk_" + i,
                            ref: "sk_" + i,
                            refInFor: true,
                            attrs: {
                              cols: 12,
                              color: "primary",
                              collapsible: "",
                              header: s.Skill.Name + " (+" + s.Bonus + ")",
                              content: s.Skill.Detail
                            }
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      _vm.pilot.Reserves || _vm.pilot.Organizations
                        ? _c(
                            "div",
                            [
                              _c("v-divider", { staticClass: "my-4" }),
                              _vm._v(" "),
                              _c("span", { staticClass: "overline" }, [
                                _vm._v(
                                  "\n              RESERVES AND RESOURCES\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _vm.pilot.Reserves || _vm.pilot.Organizations
                                ? _c(
                                    "v-row",
                                    { staticClass: "mt-n3" },
                                    [
                                      _vm._l(
                                        _vm.pilot.Reserves.filter(function(r) {
                                          return r.Type !== "Bonus"
                                        }),
                                        function(r, i) {
                                          return _c("cc-reserve-item", {
                                            key: "r_" + i,
                                            attrs: { reserve: r },
                                            on: {
                                              remove: function($event) {
                                                return _vm.pilot.RemoveReserve(
                                                  i
                                                )
                                              }
                                            }
                                          })
                                        }
                                      ),
                                      _vm._v(" "),
                                      _vm._l(_vm.pilot.Organizations, function(
                                        o,
                                        i
                                      ) {
                                        return _c("cc-org-item", {
                                          key: "o_" + i,
                                          attrs: { org: o },
                                          on: {
                                            remove: function($event) {
                                              return _vm.pilot.RemoveOrganization(
                                                i
                                              )
                                            }
                                          }
                                        })
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }