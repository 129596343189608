





























































import Vue from 'vue'
export default Vue.extend({
  name: 'action-menu-button',
  props: {
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    actions: {
      type: Array,
      required: true,
    },
    mech: {
      type: Object,
      required: true,
    },
    available: {
      type: Boolean,
    },
  },
  computed: {
    baseActions() {
      return this.actions.filter(x => !x.IsItemAction)
    },
    itemActions() {
      return this.actions.filter(x => x.IsItemAction)
    },
    allowed() {
      return this.mech.Pilot.State.AvailableActions
    },
  },
})
