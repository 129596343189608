



























































































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

import { ContentPack } from '@/class'

@Component
export default class PacksList extends Vue {
  private expanded
  private compendiumStore = getModule(CompendiumStore, this.$store)

  public async toggleActive(packID: string, active: boolean): Promise<void> {
    await this.compendiumStore.setPackActive({
      packID,
      active,
    })
  }

  public async deletePack(id: string): Promise<void> {
    await this.compendiumStore.deleteContentPack(id)
  }

  public get contentPacks(): ContentPack[] {
    return this.compendiumStore.ContentPacks
  }

  public headers = [
    { text: 'Active', value: 'toggleActive', sortable: false },
    { text: 'Name', value: 'name' },
    { text: 'Author', value: 'Author' },
    { text: 'Version', value: 'version' },
    { text: '', value: 'deleteAction', sortable: false },
  ]
}
