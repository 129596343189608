var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticStyle: {
        "padding-bottom": "2px",
        "border-top": "2px solid var(--v-primary-base)"
      },
      attrs: { fixed: "" }
    },
    [
      _vm.pilot && _vm.pilot.State
        ? _c(
            "div",
            [
              _c(
                "v-slide-y-transition",
                { attrs: { group: "", "leave-absolute": "" } },
                [
                  _vm.pilot.State.Stage === "Narrative"
                    ? _c("narrative-footer", { key: "a-ftr-narrative" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pilot.State.Stage === "Combat"
                    ? _c("combat-footer", { key: "a-ftr-combat" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pilot.State.Stage === "Rest"
                    ? _c("rest-footer", { key: "a-ftr-rest" })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }