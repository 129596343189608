var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-0 pt-1" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", dense: "" } },
        [
          _c("cc-statblock-panel", {
            attrs: {
              icon: "cci-structure",
              name: "Structure",
              value: _vm.frame.Structure
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "cci-reactor",
              name: "Stress",
              value: _vm.frame.HeatStress
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.armor",
              name: "Armor",
              value: _vm.frame.Armor
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.hp",
              name: "HP",
              value: _vm.frame.HP
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.evasion",
              name: "Evasion",
              value: _vm.frame.Evasion
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.edef",
              name: "E-Defense",
              value: _vm.frame.EDefense
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.heat",
              name: "Heat Capacity",
              value: _vm.frame.HeatCap
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.sensor",
              name: "Sensors",
              value: _vm.frame.SensorRange
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.tech",
              name: "Tech Attack",
              value: _vm.frame.TechAttack
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.repair",
              name: "Repair Capacity",
              value: _vm.frame.RepCap
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.save",
              name: "Save Target",
              value: _vm.frame.SaveTarget
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.speed",
              name: "Speed",
              value: _vm.frame.Speed
            }
          }),
          _vm._v(" "),
          _c("cc-statblock-panel", {
            attrs: {
              icon: "$vuetify.icons.sp",
              name: "System Points",
              value: _vm.frame.SP
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }