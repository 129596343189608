var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-3" },
    [
      _c(
        "cc-title",
        {
          staticStyle: { "margin-left": "-70px!important" },
          attrs: { small: "", color: "pilot" }
        },
        [
          _c("section-edit-icon", {
            attrs: { label: "Add Reserves and Bonuses" },
            on: {
              "open-selector": function($event) {
                return _vm.$refs.dtSelector.show()
              }
            }
          }),
          _vm._v("\n    Reserves and Bonuses\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dtSelector",
          attrs: {
            icon: "cci-barrage",
            "no-confirm": "",
            title: "Edit Reserves and Bonuses",
            fullscreen: "",
            "no-pad": ""
          }
        },
        [
          _c("cc-reserve-selector", {
            attrs: { pilot: _vm.pilot },
            on: {
              close: function($event) {
                return _vm.$refs.dtSelector.hide()
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        [
          !_vm.pilot.Reserves.length && !_vm.pilot.Organizations.length
            ? _c("no-data-block")
            : _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _vm._l(_vm.pilot.Reserves, function(r, i) {
                    return _c("cc-reserve-item", {
                      key: "r_" + i,
                      attrs: { reserve: r },
                      on: {
                        remove: function($event) {
                          return _vm.pilot.RemoveReserve(i)
                        }
                      }
                    })
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.pilot.Organizations, function(o, i) {
                    return _c("cc-org-item", {
                      key: "o_" + i,
                      attrs: { org: o },
                      on: {
                        remove: function($event) {
                          return _vm.pilot.RemoveOrganization(i)
                        }
                      }
                    })
                  })
                ],
                2
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }