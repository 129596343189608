var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$vuetify.breakpoint.mdAndUp,
            expression: "$vuetify.breakpoint.mdAndUp"
          }
        ],
        staticClass: "primary",
        staticStyle: {
          position: "absolute",
          top: "0",
          right: "0",
          height: "108px",
          width: "100px",
          "overflow-x": "hidden"
        }
      }),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          attrs: { "background-color": "primary", "hide-slider": "", grow: "" }
        },
        [
          _c("v-tab", [_c("b", [_vm._v("Pilot Bonuses")])]),
          _vm._v(" "),
          _c("v-tab", [_c("b", [_vm._v("Resource Reserves")])]),
          _vm._v(" "),
          _c("v-tab", [_c("b", [_vm._v("Tactical Reserves")])]),
          _vm._v(" "),
          _c("v-tab", [_c("b", [_vm._v("Mech Reserves")])]),
          _vm._v(" "),
          _c("v-tab", [_c("b", [_vm._v("Custom Reserve")])]),
          _vm._v(" "),
          _c("v-tab", [_c("b", [_vm._v("Project")])]),
          _vm._v(" "),
          _c("v-tab", [_c("b", [_vm._v("Organization")])]),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                _vm._l(_vm.reserves["Bonus"], function(r) {
                  return _c(
                    "v-col",
                    { key: "item_" + r.ID, attrs: { cols: "12", md: "6" } },
                    [
                      _c("reserve-item", {
                        staticClass: "ma-2",
                        attrs: {
                          reserve: r,
                          icon: "cci-pilot",
                          color: "pilot"
                        },
                        on: {
                          click: function($event) {
                            return _vm.add(r)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                _vm._l(_vm.reserves["Resource"], function(r) {
                  return _c(
                    "v-col",
                    { key: "item_" + r.ID, attrs: { cols: "12", md: "6" } },
                    [
                      _c("reserve-item", {
                        staticClass: "ma-2",
                        attrs: {
                          reserve: r,
                          icon: "cci-reserve-resource",
                          color: "reserve--resource"
                        },
                        on: {
                          click: function($event) {
                            return _vm.add(r)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                _vm._l(_vm.reserves["Tactical"], function(r) {
                  return _c(
                    "v-col",
                    { key: "item_" + r.ID, attrs: { cols: "12", md: "6" } },
                    [
                      _c("reserve-item", {
                        staticClass: "ma-1",
                        attrs: {
                          reserve: r,
                          icon: "cci-reserve-tactical",
                          color: "reserve--tactical"
                        },
                        on: {
                          click: function($event) {
                            return _vm.add(r)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                _vm._l(_vm.reserves["Mech"], function(r) {
                  return _c(
                    "v-col",
                    { key: "item_" + r.ID, attrs: { cols: "12", md: "6" } },
                    [
                      _c("reserve-item", {
                        staticClass: "ma-1",
                        attrs: {
                          reserve: r,
                          icon: "cci-reserve-mech",
                          color: "reserve--mech"
                        },
                        on: {
                          click: function($event) {
                            return _vm.add(r)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c("custom-reserve-panel", {
                staticClass: "mt-2",
                on: {
                  add: function($event) {
                    return _vm.add($event)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c("downtime-project-panel", {
                staticClass: "mt-2",
                on: {
                  add: function($event) {
                    return _vm.add($event)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c("organization-panel", {
                staticClass: "mt-2",
                on: {
                  add: function($event) {
                    return _vm.addOrg($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }