

























import Vue from 'vue'

export default Vue.extend({
  name: 'main-btn',
  props: {
    to: {
      type: String,
      required: false,
      default: '',
    },
    help: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    icon: {
      type: String,
      default: 'cci-pilot',
    },
  },
  data: () => ({
    mouseOver: false,
  }),
})
