var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "auto" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "white--text",
          attrs: {
            large: "",
            tile: "",
            block: "",
            disabled: _vm.disableCostActivate,
            color: "" + _vm.action.Color
          },
          on: {
            click: function($event) {
              return _vm.select()
            }
          }
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v(_vm._s(_vm.action.Icon))
          ]),
          _vm._v("\n    " + _vm._s(_vm.action.Name) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      !_vm.fulltech &&
      _vm.action.Activation !== "Free" &&
      _vm.action.Activation !== "Protocol"
        ? _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: { small: "", tile: "", block: "", color: "action--free" },
              on: {
                click: function($event) {
                  return _vm.select(true)
                }
              }
            },
            [
              _c("v-icon", { attrs: { left: "", small: "" } }, [
                _vm._v("cci-free-action")
              ]),
              _vm._v("\n    Free Action\n    "),
              _c(
                "cc-tooltip",
                {
                  attrs: {
                    inline: "",
                    content:
                      "Special rules or equipment may allow you to " +
                      _vm.action.Name +
                      " as a Free Action. Using this button will commit the action without spending a " +
                      _vm.action.Activation +
                      " Action this turn"
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "fadeSelect",
                      attrs: { right: "", small: "" }
                    },
                    [_vm._v("mdi-information-outline")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.action.HeatCost
        ? _c("div", { staticClass: "overline error--text text-center" }, [
            _vm._v(
              "\n    ALERT: This action will incur " +
                _vm._s(_vm.action.HeatCost) +
                " heat\n  "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }