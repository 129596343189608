var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("selector", {
    attrs: {
      title: "Pilot Skill Triggers",
      height: "60vh",
      success: !_vm.pilot.IsMissingSkills && _vm.enoughSelections
    },
    scopedSlots: _vm._u([
      {
        key: "left-column",
        fn: function() {
          return [
            _vm._l(_vm.pilot.Skills, function(pSkill, i) {
              return _c(
                "div",
                { key: "summary_" + pSkill.Skill.ID + "_" + i },
                [
                  pSkill.Skill.err
                    ? _c("missing-item", {
                        on: {
                          remove: function($event) {
                            return _vm.pilot.RemoveSkill(pSkill)
                          }
                        }
                      })
                    : _c(
                        "v-chip",
                        {
                          staticClass: "my-1 pa-1",
                          staticStyle: { width: "100%" },
                          attrs: { label: "", color: "panel" }
                        },
                        [
                          _c(
                            "v-chip",
                            { attrs: { dark: "", color: "accent", small: "" } },
                            [
                              _vm._v("\n          +\n          "),
                              _c("b", [_vm._v(_vm._s(pSkill.Bonus))])
                            ]
                          ),
                          _vm._v("\n         \n        "),
                          _c("strong", [_vm._v(_vm._s(pSkill.Skill.Trigger))])
                        ],
                        1
                      )
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm.pilot.Skills.length
              ? _c("v-divider", { staticClass: "ma-2 ml-4 mr-4" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-alert",
                      {
                        staticClass: "stat-text",
                        attrs: {
                          outlined: "",
                          prominent: _vm.$vuetify.breakpoint.mdAndUp,
                          dense: "",
                          border: "left",
                          color: "success",
                          icon: "check_circle",
                          value:
                            !_vm.pilot.IsMissingSkills && _vm.enoughSelections
                        }
                      },
                      [_vm._v("\n          Skill Selection Complete\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-alert",
                      {
                        staticClass: "stat-text",
                        attrs: {
                          outlined: "",
                          prominent: _vm.$vuetify.breakpoint.mdAndUp,
                          dense: "",
                          border: "left",
                          color: "accent",
                          icon: "warning",
                          value:
                            _vm.pilot.MaxSkillPoints >
                            _vm.pilot.CurrentSkillPoints
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.pilot.MaxSkillPoints -
                                _vm.pilot.CurrentSkillPoints
                            ) +
                            " Skill Points remaining\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-alert",
                      {
                        staticClass: "stat-text",
                        attrs: {
                          outlined: "",
                          prominent: _vm.$vuetify.breakpoint.mdAndUp,
                          dense: "",
                          border: "left",
                          color: "accent",
                          icon: "warning",
                          value: !_vm.enoughSelections
                        }
                      },
                      [
                        _vm._v(
                          "\n          Must select a minimum of " +
                            _vm._s(_vm.selectedMin) +
                            " skills\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          text: "",
                          small: "",
                          disabled: !_vm.pilot.Skills.length
                        },
                        on: {
                          click: function($event) {
                            return _vm.pilot.ClearSkills()
                          }
                        }
                      },
                      [_vm._v("\n          Reset\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "right-column",
        fn: function() {
          return [
            _vm._l(_vm.headers, function(h) {
              return _c(
                "div",
                { key: "h_" + h.attr, staticClass: "mb-4" },
                [
                  _vm.$vuetify.breakpoint.smAndDown
                    ? _c("v-divider", { staticClass: "my-2" })
                    : _vm._e(),
                  _vm._v(" "),
                  h.attr !== "Custom"
                    ? _c("span", { staticClass: "overline" }, [
                        _vm._v("Your Ability To")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c("cc-title", { attrs: { small: "" } }, [
                        _vm._v(_vm._s(h.description))
                      ])
                    : _c(
                        "div",
                        { staticClass: "heading h3 accent--text mb-1" },
                        [
                          _vm._v(
                            "\n        " + _vm._s(h.description) + "\n      "
                          )
                        ]
                      ),
                  _vm._v(" "),
                  _vm._l(_vm.skills[h.attr], function(s, i) {
                    return _c("skill-select-item", {
                      key: "skill_" + h.attr + "_" + i,
                      attrs: {
                        skill: s,
                        "can-add": _vm.pilot.CanAddSkill(s),
                        "can-remove": _vm.pilot.CanRemoveSkill(s)
                      },
                      on: {
                        add: function($event) {
                          return _vm.pilot.AddSkill(s)
                        },
                        remove: function($event) {
                          return _vm.pilot.RemoveSkill(s)
                        }
                      }
                    })
                  })
                ],
                2
              )
            }),
            _vm._v(" "),
            _c("add-custom-skill", {
              attrs: { pilot: _vm.pilot },
              on: {
                "add-custom": function($event) {
                  return _vm.pilot.AddCustomSkill($event)
                }
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }