var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { attrs: { flat: "", tile: "", color: "transparent" } }, [
    _c(
      "fieldset",
      [
        _c(
          "legend",
          { staticClass: "heading h3", style: "color: " + _vm.color },
          [_vm._v("\n      Systems\n    ")]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { position: "relative" } }, [
          _c("div", { staticClass: "side-legend" }, [
            _c(
              "span",
              {
                class:
                  "heading h3 " +
                  (_vm.mech.FreeSP < 0
                    ? "error--text"
                    : "subtle--text text--darken-3")
              },
              [
                _vm.mech.FreeSP < 0
                  ? _c("v-icon", { attrs: { color: "error", left: "" } }, [
                      _vm._v("mdi-alert")
                    ])
                  : _vm._e(),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.mech.FreeSP) +
                    " / " +
                    _vm._s(_vm.mech.MaxSP) +
                    "\n          "
                ),
                _c("span", { staticClass: "overline" }, [_vm._v("SP")])
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.mech.ActiveLoadout.IntegratedSystems, function(s, i) {
          return _c("system-slot-card", {
            key: s.ID + "-" + i + "-intg",
            attrs: { mech: _vm.mech, item: s, color: _vm.color, readonly: "" }
          })
        }),
        _vm._v(" "),
        _vm._l(_vm.moddedWeapons, function(w, i) {
          return _c("mod-equipped-card", {
            key: w.ID + "-" + i + "-mod",
            attrs: {
              mech: _vm.mech,
              mod: w.Mod,
              weapon: w,
              color: _vm.color,
              readonly: _vm.readonly
            },
            on: {
              remove: function($event) {
                w.Mod = null
              }
            }
          })
        }),
        _vm._v(" "),
        _vm._l(_vm.mech.ActiveLoadout.Systems, function(s, i) {
          return _c("system-slot-card", {
            key: s.ID + "-" + i,
            attrs: {
              mech: _vm.mech,
              item: s,
              color: _vm.color,
              index: i,
              readonly: _vm.readonly
            }
          })
        }),
        _vm._v(" "),
        !_vm.readonly
          ? _c("system-slot-card", { attrs: { mech: _vm.mech, empty: "" } })
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }