var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pl-card-base",
    {
      ref: "base",
      attrs: {
        title: "PILOT ARMOR",
        extended: _vm.extended,
        item: _vm.item,
        readonly: _vm.readonly
      },
      on: {
        remove: function($event) {
          return _vm.$emit("remove")
        }
      }
    },
    [
      _vm.item
        ? _c(
            "div",
            {
              staticClass: "text-left",
              staticStyle: {
                cursor: "pointer!important",
                height: "100%",
                "min-height": "80px"
              },
              on: {
                click: function($event) {
                  return _vm.$refs.base.openDetail()
                }
              }
            },
            [
              _c(
                "span",
                {
                  key: _vm.item.Name,
                  staticClass: "h2 heading text--text",
                  staticStyle: { "line-height": "35px" }
                },
                [
                  _vm._v("\n      " + _vm._s(_vm.item.Name) + "\n      "),
                  _vm.item.Note
                    ? _c(
                        "cc-tooltip",
                        {
                          key: _vm.item.Note.length,
                          attrs: {
                            simple: "",
                            inline: "",
                            content: _vm.item.Note
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", color: "active" } },
                            [_vm._v("mdi-note")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "pb-2", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "my-auto" },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            content: "Armor Bonus"
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-shield-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.armor))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "my-auto" },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: { simple: "", inline: "", content: "HP Bonus" }
                        },
                        [_c("v-icon", [_vm._v("mdi-heart")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v("+" + _vm._s(_vm.hp))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "my-auto" },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            content: "Electronic Defense"
                          }
                        },
                        [_c("v-icon", [_vm._v("cci-edef")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.edef))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "my-auto" },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: { simple: "", inline: "", content: "Evasion" }
                        },
                        [_c("v-icon", [_vm._v("cci-evasion")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.evasion))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "my-auto" },
                    [
                      _c(
                        "cc-tooltip",
                        { attrs: { simple: "", inline: "", content: "Speed" } },
                        [_c("v-icon", [_vm._v("$vuetify.icons.move")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "stat-text" }, [
                        _vm._v(_vm._s(_vm.speed))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-4 mt-n2",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-tags", {
                        attrs: {
                          small: "",
                          tags: _vm.item.Tags,
                          color: "secondary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card-text",
        { attrs: { slot: "selector" }, slot: "selector" },
        [
          _c(
            "cc-selector-table",
            {
              attrs: {
                "no-filter": "",
                "no-extra": "",
                items: _vm.getArmor(),
                headers: _vm.headers
              },
              on: {
                equip: function($event) {
                  return _vm.equip($event)
                }
              }
            },
            [
              _vm.item
                ? _c("div", [
                    _c("span", { staticClass: "overline" }, [
                      _vm._v(
                        "\n          GMS ARMORY PRINTID: " +
                          _vm._s(_vm.fID("ANN-NNN-NNN::AA//AA")) +
                          " —\n          "
                      ),
                      _c(
                        "span",
                        { staticClass: "success--text text--darken-1" },
                        [
                          _vm._v(
                            "\n            [ PILOT MATERIEL REGISTRATION VERIFIED ]\n          "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "heading h1 accent--text",
                        staticStyle: { "line-height": "20px" }
                      },
                      [_vm._v(_vm._s(_vm.item.Name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "flavor-text overline mt-n1",
                        staticStyle: { display: "block" }
                      },
                      [_vm._v("CURRENTLY EQUIPPED")]
                    )
                  ])
                : _c("div", [
                    _c("span", { staticClass: "overline" }, [
                      _vm._v(
                        "\n          GMS ARMORY EQUIPMENT AUTHORIZATION: PILOT/PERSONAL ARMOR::TI - TVII-A\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "heading h1 subtle--text text--lighten-1",
                        staticStyle: { "line-height": "20px" }
                      },
                      [_vm._v("\n          NO SELECTION\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "flavor-text overline mt-n1 error--text",
                        staticStyle: { display: "block" }
                      },
                      [
                        _vm._v(
                          "\n          [ MATERIEL ID INVALID OR MISSING ]\n        "
                        )
                      ]
                    )
                  ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }