var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      !_vm.npc
        ? _c(
            "v-row",
            {
              staticStyle: { width: "100%", height: "100%" },
              attrs: { align: "center", justify: "center" }
            },
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("span", { staticClass: "heading h1 light-panel--text" }, [
                  _vm._v("no npc selected")
                ])
              ])
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "mt-n6", attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c(
                      "div",
                      { staticClass: "heading mech" },
                      [
                        _c(
                          "cc-short-string-editor",
                          {
                            attrs: { large: "", placeholder: _vm.npc.Name },
                            on: {
                              set: function($event) {
                                _vm.npc.Name = $event
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "heading-block" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.npc.Name) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flavor-text mt-n6 ml-2" },
                      [
                        _c(
                          "cc-short-string-editor",
                          {
                            attrs: { large: "", placeholder: _vm.npc.Subtitle },
                            on: {
                              set: function($event) {
                                _vm.npc.Subtitle = $event
                              }
                            }
                          },
                          [
                            _vm.npc.Subtitle
                              ? _c("b", {
                                  staticClass: "heading-block stark--text",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.npc.Subtitle)
                                  }
                                })
                              : _c("i", {
                                  staticClass: "heading-block subtle--text",
                                  domProps: {
                                    innerHTML: _vm._s("Add GM Summary")
                                  }
                                })
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto text-center mt-1",
                      attrs: { cols: "auto" }
                    },
                    [
                      _vm.npc.Tier === "custom"
                        ? _c(
                            "v-icon",
                            {
                              attrs: { size: "60", color: _vm.npc.Class.Color }
                            },
                            [
                              _vm._v(
                                "\n          mdi-star-circle-outline\n        "
                              )
                            ]
                          )
                        : _c(
                            "v-icon",
                            {
                              attrs: { size: "60", color: _vm.npc.Class.Color }
                            },
                            [_vm._v("cci-rank-" + _vm._s(_vm.npc.Tier))]
                          ),
                      _vm._v(" "),
                      _vm.npc.Tier === "custom"
                        ? _c("div", { staticClass: "overline mt-n1" }, [
                            _vm._v("CUSTOM")
                          ])
                        : _c("div", { staticClass: "overline mt-n1" }, [
                            _vm._v("TIER " + _vm._s(_vm.npc.Tier))
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center mt-n4",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { size: "60", color: _vm.npc.Class.Color } },
                        [_vm._v("cci-role-" + _vm._s(_vm.npc.Role))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "overline mt-n1" }, [
                        _vm._v(_vm._s(_vm.npc.Role))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", [_c("v-divider")], 1),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-n1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "span",
                        {
                          class: "heading " + _vm.npc.Class.Color + "--text",
                          staticStyle: {
                            "font-size": "35px",
                            "line-height": "0"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.npc.Class.Name) +
                              "\n          "
                          ),
                          _vm.npc.Templates
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.npc.Templates.map(function(t) {
                                      return t.Name
                                    }).join(" ")
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-col", [_c("v-divider")], 1)
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-combobox", {
                        attrs: {
                          "active-class": "accent",
                          outlined: "",
                          dense: "",
                          multiple: "",
                          "background-color": "stark-panel",
                          label: "User Labels",
                          items: _vm.labels
                        },
                        model: {
                          value: _vm.npc.Labels,
                          callback: function($$v) {
                            _vm.$set(_vm.npc, "Labels", $$v)
                          },
                          expression: "npc.Labels"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-combobox", {
                        attrs: {
                          "active-class": "accent",
                          "background-color": "stark-panel",
                          outlined: "",
                          dense: "",
                          label: "NPC Tag",
                          items: _vm.tags
                        },
                        model: {
                          value: _vm.npc.Tag,
                          callback: function($$v) {
                            _vm.$set(_vm.npc, "Tag", $$v)
                          },
                          expression: "npc.Tag"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-combobox", {
                        attrs: {
                          "active-class": "accent",
                          "background-color": "stark-panel",
                          outlined: "",
                          dense: "",
                          label: "Campaign",
                          items: _vm.campaigns
                        },
                        model: {
                          value: _vm.npc.Campaign,
                          callback: function($$v) {
                            _vm.$set(_vm.npc, "Campaign", $$v)
                          },
                          expression: "npc.Campaign"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto mt-n6", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { "margin-bottom": "2px" },
                          attrs: { "x-small": "", block: "", outlined: "" },
                          on: {
                            click: function($event) {
                              _vm.flavor_dialog = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.npc.Class.Name) +
                              " Flavor\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { "x-small": "", block: "", outlined: "" },
                          on: {
                            click: function($event) {
                              _vm.tactics_dialog = true
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.npc.Class.Name) +
                              " Tactics\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n2", attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-btn-toggle",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            dense: "",
                            mandatory: "",
                            "active-class": "accent--text"
                          },
                          model: {
                            value: _vm.npc.Tier,
                            callback: function($$v) {
                              _vm.$set(_vm.npc, "Tier", $$v)
                            },
                            expression: "npc.Tier"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            { attrs: { value: 1 } },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("cci-rank-1")
                              ]),
                              _vm._v("\n            Tier 1\n          ")
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            { attrs: { value: 2 } },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("cci-rank-2")
                              ]),
                              _vm._v("\n            Tier 2\n          ")
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            { attrs: { value: 3 } },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("cci-rank-3")
                              ]),
                              _vm._v("\n            Tier 3\n          ")
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            { attrs: { value: "custom" } },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-star-circle-outline")
                              ]),
                              _vm._v("\n            Custom\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "", "no-gutters": "" } },
                        [
                          _c("editable-attribute", {
                            attrs: {
                              attr: "HULL",
                              val: _vm.npc.Stats.Hull,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Hull = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "AGI",
                              val: _vm.npc.Stats.Agility,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Agility = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "SYS",
                              val: _vm.npc.Stats.Systems,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Systems = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "ENG",
                              val: _vm.npc.Stats.Engineering,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Engineering = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "my-2" }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "", "no-gutters": "" } },
                        [
                          _c("editable-attribute", {
                            attrs: {
                              attr: "STRUCT.",
                              val: _vm.npc.Stats.Structure,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Structure = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "ARMOR",
                              val: _vm.npc.Stats.Armor,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Armor = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "HP",
                              val: _vm.npc.Stats.HP,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.HP = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "REACTOR",
                              val: _vm.npc.Stats.Stress,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Stress = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          !_vm.npc.IsBiological
                            ? _c("editable-attribute", {
                                attrs: {
                                  attr: "HEAT CAP.",
                                  val: _vm.npc.Stats.HeatCapacity,
                                  color: _vm.npc.Class.Color,
                                  editable: _vm.npc.IsCustomTier
                                },
                                on: {
                                  set: function($event) {
                                    _vm.npc.Stats.HeatCapacity = $event
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "", "no-gutters": "" } },
                        [
                          _c("editable-attribute", {
                            attrs: {
                              attr: "SPEED",
                              val: _vm.npc.Stats.Speed,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Speed = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "SAVE",
                              val: _vm.npc.Stats.Save,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Save = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "EVADE",
                              val: _vm.npc.Stats.Evade,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Evade = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "E-DEF.",
                              val: _vm.npc.Stats.EDefense,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.EDefense = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("editable-attribute", {
                            attrs: {
                              attr: "SENSOR",
                              val: _vm.npc.Stats.Sensor,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Sensor = $event
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "my-2" }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "", "no-gutters": "" } },
                        [
                          _c("editable-attribute", {
                            attrs: {
                              attr: "ACTIVATIONS",
                              val: _vm.npc.Stats.Activations,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier
                            },
                            on: {
                              set: function($event) {
                                _vm.npc.Stats.Activations = $event
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("size-attribute", {
                            attrs: {
                              stats: _vm.npc.Stats,
                              color: _vm.npc.Class.Color,
                              editable: _vm.npc.IsCustomTier,
                              selectable: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-n2",
                          attrs: { dense: "", align: "start" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "ml-auto", attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tooltip",
                                {
                                  attrs: {
                                    title: "Power Rating",
                                    content:
                                      "The Power Rating is an attempt to calculate the relative strength of an NPC (or encounters’ worth of NPCs) based on tier and applied templates, compared to mission’s Pilot and their current level. It should, generally, produce results more or less in line with the Balancing Combat section on pp. 283 of the LANCER Core Book.<br> That said, this is an experimental feature that is still very heavily in development, and does not (yet) always produce reliable results. Moreover, this tool doesn’t consider NPC or player team composition, synergies, strengths, and weaknesses. Nor does this tool consider map layout, mission objectives, or reinforcement schedules.<br>While we will continue to work on this tool to produce more accurate, actionable results, please use it only as a general indicator of relative NPC strength."
                                  }
                                },
                                [
                                  _c("i", { staticClass: "flavor-text" }, [
                                    _vm._v(
                                      "\n                Calculated Power Rating: " +
                                        _vm._s(
                                          _vm.npc.Power.toString().padStart(
                                            4,
                                            "0"
                                          )
                                        ) +
                                        "\n              "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", outlined: "" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-1" },
                            [
                              _vm.npc.Image
                                ? _c("v-img", {
                                    key: _vm.npc.Image,
                                    attrs: {
                                      src: _vm.npc.Image,
                                      "max-width": "30vw",
                                      "max-height": "30vh",
                                      "aspect-ratio": "1"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outlined: "",
                                    small: "",
                                    block: "",
                                    color: "secondary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.imageSelector.open()
                                    }
                                  }
                                },
                                [
                                  !_vm.npc.Image
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-plus")]
                                          ),
                                          _vm._v(
                                            "\n                Add NPC Image\n              "
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-circle-edit-outline")]
                                          ),
                                          _vm._v(
                                            "\n                Edit NPC Image\n              "
                                          )
                                        ],
                                        1
                                      )
                                ]
                              ),
                              _vm._v(" "),
                              _c("cc-image-selector-web", {
                                ref: "imageSelector",
                                attrs: { item: _vm.npc, type: "npc" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "cc-title",
                { attrs: { small: "", color: _vm.npc.Class.Color } },
                [
                  _vm._v(
                    "\n      Template" +
                      _vm._s(_vm.npc.Templates.length > 1 ? "s" : "") +
                      "\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mb-2", attrs: { dense: "", align: "center" } },
                [
                  _vm._l(_vm.npc.Templates, function(t) {
                    return _c(
                      "v-col",
                      { key: t.Name, attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-dialog",
                          {
                            attrs: { width: "50%" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "d-inline",
                                            attrs: { block: "", outlined: "" }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(t.Name) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "cc-titled-panel",
                              { attrs: { title: t.Name } },
                              [
                                _c("p", {
                                  directives: [
                                    {
                                      name: "html-safe",
                                      rawName: "v-html-safe",
                                      value: t.Description,
                                      expression: "t.Description"
                                    }
                                  ],
                                  staticClass: "text--text"
                                }),
                                _vm._v(" "),
                                _c("v-divider", { staticClass: "my-2" }),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      block: "",
                                      outlined: "",
                                      color: "error"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.npc.RemoveTemplate(t)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              REMOVE TEMPLATE\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            color: "accent",
                            tile: "",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.templateDialog.show()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus")
                          ]),
                          _vm._v("\n          Add Template\n        ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-title",
                        { attrs: { small: "", color: _vm.npc.Class.Color } },
                        [_vm._v("\n          Features\n        ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn-toggle",
                        {
                          attrs: {
                            value: _vm.profile.GetView("npc"),
                            mandatory: ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "", value: "list" },
                              on: {
                                click: function($event) {
                                  return _vm.profile.SetView("npc", "list")
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "accent" } }, [
                                _vm._v("mdi-view-list")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", icon: "", value: "chips" },
                              on: {
                                click: function($event) {
                                  return _vm.profile.SetView("npc", "chips")
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "accent" } }, [
                                _vm._v("mdi-view-comfy")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.profile.GetView("npc") === "list"
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    _vm._l(_vm.npc.Items, function(i, idx) {
                      return _c(
                        "v-col",
                        {
                          key: i.Feature.ID + idx,
                          attrs: { md: "12", lg: "12", xl: "6" }
                        },
                        [
                          _c("cc-npc-item-card", {
                            attrs: { item: i },
                            on: {
                              "remove-feature": function($event) {
                                return _vm.npc.RemoveFeature(i.Feature)
                              },
                              recalc: function($event) {
                                return _vm.npc.RecalcBonuses()
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm.profile.GetView("npc") === "chips"
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-chip-group",
                        { attrs: { column: "" } },
                        _vm._l(_vm.npc.Items, function(i, idx) {
                          return _c("cc-npc-item-chip", {
                            key: i.Feature.ID + idx,
                            attrs: { item: i },
                            on: {
                              "remove-feature": function($event) {
                                return _vm.npc.RemoveFeature(i.Feature)
                              },
                              recalc: function($event) {
                                return _vm.npc.RecalcBonuses()
                              }
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "accent", tile: "", outlined: "" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.featureDialog.show()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus")
                          ]),
                          _vm._v("\n          Add Feature\n          "),
                          _vm.npc.AvailableFeatures.length
                            ? _c("span", [
                                _vm._v(
                                  "\n            (" +
                                    _vm._s(_vm.npc.AvailableFeatures.length) +
                                    " Available)\n          "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-2" }),
              _vm._v(" "),
              _c(
                "cc-title",
                { attrs: { small: "", color: _vm.npc.Class.Color } },
                [
                  _vm._v("\n      NPC Notes\n      "),
                  _c("cc-text-editor", {
                    attrs: { label: "Edit NPC Notes", original: _vm.npc.Note },
                    on: {
                      save: function($event) {
                        _vm.npc.Note = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { domProps: { innerHTML: _vm._s(_vm.npc.Note) } }),
              _vm._v(" "),
              _c(
                "v-dialog",
                {
                  attrs: { width: "60vw" },
                  model: {
                    value: _vm.flavor_dialog,
                    callback: function($$v) {
                      _vm.flavor_dialog = $$v
                    },
                    expression: "flavor_dialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    { attrs: { tile: "" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          class: "heading h1 white--text " + _vm.npc.Class.Color
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.npc.Class.Name) +
                              "//Flavor\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _c("p", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: _vm.npc.Class.Flavor,
                              expression: "npc.Class.Flavor"
                            }
                          ],
                          staticClass: "ma-0 pt-2 flavor-text text--text"
                        })
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-dialog",
                {
                  attrs: { width: "60vw" },
                  model: {
                    value: _vm.tactics_dialog,
                    callback: function($$v) {
                      _vm.tactics_dialog = $$v
                    },
                    expression: "tactics_dialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    { attrs: { tile: "" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          class: "heading h1 white--text " + _vm.npc.Class.Color
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.npc.Class.Name) +
                              "//Tactics\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-card-text", [
                        _c("p", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: _vm.npc.Class.Tactics,
                              expression: "npc.Class.Tactics"
                            }
                          ],
                          staticClass: "ma-0 pt-2 flavor-text text--text"
                        })
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "cc-solo-dialog",
                {
                  ref: "featureDialog",
                  attrs: {
                    "no-confirm": "",
                    title: "SELECT FEATURES",
                    fullscreen: "",
                    "no-pad": ""
                  }
                },
                [
                  _c("feature-selector", {
                    attrs: { npc: _vm.npc },
                    on: {
                      equip: function($event) {
                        return _vm.equip($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "cc-solo-dialog",
                {
                  ref: "templateDialog",
                  attrs: {
                    "no-confirm": "",
                    title: "SELECT TEMPLATE",
                    fullscreen: "",
                    "no-pad": ""
                  }
                },
                [
                  _c("template-selector", {
                    attrs: { npc: _vm.npc },
                    on: {
                      select: function($event) {
                        return _vm.assign($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }