var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _vm.visible
                ? _c("div", { staticStyle: { position: "relative" } }, [
                    _c(
                      "div",
                      { staticClass: "side-legend" },
                      [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                small: "",
                                outlined: "",
                                color: _vm.color
                              }
                            },
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { left: !_vm.hasEffect } }, [
                              _vm._v("cci-corebonus")
                            ]),
                            _vm._v(" "),
                            !_vm.hasEffect
                              ? _c("span", [_vm._v("Core Bonus Available")])
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { outlined: "", elevation: "10" } },
        [
          _c(
            "v-card-title",
            {
              staticClass: "white--text",
              style: "background-color: " + _vm.color + "; height: 32px"
            },
            [
              _c(
                "div",
                { staticClass: "mt-n4" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mt-n2",
                      attrs: { large: "", left: "", dark: "" }
                    },
                    [_vm._v("cci-corebonus")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "heading h2" }, [
                    _vm._v("Mount CORE Bonuses")
                  ])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "text-center" },
            [
              _c(
                "v-row",
                { staticClass: "mt-2 mx-3", attrs: { dense: "" } },
                [
                  _vm._l(_vm.mech.AvailableBonuses, function(b) {
                    return _c(
                      "v-btn",
                      {
                        key: _vm.mount.ID + "_cb_" + b.Name,
                        staticClass: "my-1",
                        attrs: {
                          block: "",
                          color: "primary",
                          tile: "",
                          large: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.mount.AddCoreBonus(b)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          Install " + _vm._s(b.Name) + "\n        "
                        )
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.mount.Bonuses, function(b) {
                    return _c(
                      "v-btn",
                      {
                        key: _vm.mount.ID + "_cb_" + b.Name,
                        staticClass: "my-1",
                        attrs: {
                          block: "",
                          color: "secondary",
                          outlined: "",
                          large: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.mount.RemoveCoreBonus(b)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          Uninstall " +
                            _vm._s(b.Name) +
                            "\n        "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }