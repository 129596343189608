var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { lg: "4", md: "6", xs: "12" } },
    [
      _c(
        "cc-titled-panel",
        {
          staticStyle: { height: "100%" },
          attrs: {
            clickable: "",
            title: "" + _vm.action.Name + _vm.exclusive,
            icon: _vm.action.Icon,
            color: _vm.action.Color
          },
          on: {
            click: function($event) {
              return _vm.$refs.dialog.show()
            }
          }
        },
        [
          _c("v-card-text", { staticClass: "text-left py-1" }, [
            _c("p", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.action.Terse,
                  expression: "action.Terse"
                }
              ],
              staticClass: "body-text mb-1 pa-2"
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            title: "" + _vm.action.Name + _vm.exclusive,
            icon: _vm.action.Icon,
            color: _vm.action.Color,
            "no-actions": "",
            width: "80vw"
          }
        },
        [
          _c(
            "v-container",
            [
              _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.action.Detail,
                    expression: "action.Detail"
                  }
                ],
                staticClass: "body-text text--text mb-1 mt-2"
              }),
              _vm._v(" "),
              _c("div", { staticClass: "overline subtle--text" }, [
                _vm._v("OPTIONS")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                _vm._l(_vm.action.SubActions, function(a, i) {
                  return _c(
                    "v-col",
                    {
                      key: _vm.action.Name + "_action_" + i,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-action", {
                        staticClass: "ma-2",
                        attrs: { action: a, panel: false }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }