var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "cc-tooltip",
        { attrs: { inline: "", simple: "", content: _vm.label } },
        [
          _c(
            "v-icon",
            {
              staticClass: "fadeSelect ml-8 mr-2",
              attrs: { dark: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("open-selector")
                }
              }
            },
            [_vm._v("mdi-circle-edit-outline")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }