var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-solo-dialog",
    {
      ref: "dialog",
      attrs: { "no-confirm": "", large: "", title: _vm.title },
      on: {
        close: function($event) {
          return _vm.setHash()
        }
      }
    },
    [
      _c("div", {
        directives: [
          {
            name: "html-safe",
            rawName: "v-html-safe",
            value: _vm.body,
            expression: "body"
          }
        ],
        staticClass: "mt-2 body-text text--text"
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", align: "end", justify: "end" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-checkbox",
                {
                  attrs: { color: "secondary", "hide-details": "", dense: "" },
                  model: {
                    value: _vm.noshow,
                    callback: function($$v) {
                      _vm.noshow = $$v
                    },
                    expression: "noshow"
                  }
                },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("Don't show this message again")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }