var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "div",
                {
                  style: _vm.$vuetify.breakpoint.smAndDown
                    ? "z-index:2; position: fixed; bottom: 28px; right: 28px"
                    : ""
                },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        value: _vm.filterCount,
                        overlap: "",
                        right: "",
                        color: "secondary"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "badge",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "stat-text white--text" },
                                  [_vm._v(_vm._s(_vm.filterCount))]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { fab: "", color: "primary" } }, on),
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("mdi-filter-variant")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.panel,
        callback: function($$v) {
          _vm.panel = $$v
        },
        expression: "panel"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-sheet",
        [
          _c(
            "cc-titlebar",
            { attrs: { dark: "", icon: "mdi-filter-variant" } },
            [_vm._v("Set Item Filters")]
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { attrs: { "pb-0": "" } },
            [
              _c("cc-item-filter", {
                ref: "controls",
                attrs: { "item-type": _vm.itemType },
                on: {
                  "set-filters": function($event) {
                    return _vm.applyFilters($event)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.panel = false
                    }
                  }
                },
                [_vm._v("dismiss")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "cc-btn",
                {
                  staticClass: "mr-3",
                  attrs: { color: "warning" },
                  on: { click: _vm.clearFilters }
                },
                [_vm._v("clear all")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }