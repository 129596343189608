var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "mt-2", attrs: { cols: "12" } },
            [
              _c("span", { staticClass: "overline" }, [
                _vm._v(_vm._s(_vm.mech.ActiveLoadout.Name) + " Loadout")
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "fadeSelect",
                  attrs: { small: "", right: "", icon: "" },
                  on: {
                    click: function($event) {
                      _vm.showLoadout = !_vm.showLoadout
                    }
                  }
                },
                [
                  _c("v-icon", {
                    attrs: { small: "" },
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.showLoadout
                          ? "mdi-eye-outline"
                          : "mdi-eye-off-outline"
                      )
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-scroll-y-reverse-transition",
            { attrs: { mode: "out-in" } },
            [
              _vm.showLoadout
                ? _c("cc-active-mech-loadout", {
                    attrs: { mech: _vm.mech, rest: _vm.rest }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }