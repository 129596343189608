var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("selector", {
    attrs: {
      title: "Pilot CORE Bonuses",
      height: "60vh",
      success: !_vm.pilot.IsMissingCBs
    },
    scopedSlots: _vm._u([
      {
        key: "left-column",
        fn: function() {
          return [
            _vm._l(_vm.pilot.CoreBonuses, function(b) {
              return _c(
                "v-row",
                {
                  key: "summary_" + b.ID,
                  staticClass: "my-2",
                  staticStyle: { width: "98%" }
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-icon", { attrs: { small: "", color: "accent" } }, [
                        _vm._v("cci-corebonus")
                      ]),
                      _vm._v(" "),
                      _c("strong", [_vm._v(_vm._s(b.Name))]),
                      _vm._v(" "),
                      _c("span", { staticClass: "overline" }, [
                        _vm._v(_vm._s(b.Source))
                      ])
                    ],
                    1
                  )
                ]
              )
            }),
            _vm._v(" "),
            _vm.pilot.CoreBonuses.length
              ? _c("v-divider", { staticClass: "ma-2 ml-4 mr-4" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c(
                  "v-alert",
                  {
                    staticClass: "stat-text",
                    staticStyle: { width: "95%" },
                    attrs: {
                      outlined: "",
                      color: "success",
                      icon: "check_circle",
                      value: !_vm.pilot.IsMissingCBs
                    }
                  },
                  [_vm._v("\n        CORE Bonus Selection Complete\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "v-alert",
                  {
                    staticClass: "stat-text",
                    attrs: {
                      outlined: "",
                      color: "accent",
                      icon: "warning",
                      value: _vm.pilot.IsMissingCBs
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.pilot.CurrentCBPoints) +
                        " / " +
                        _vm._s(_vm.pilot.MaxCBPoints) +
                        " CORE Bonuses selected\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "my-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          text: "",
                          small: "",
                          disabled: !_vm.pilot.CoreBonuses.length
                        },
                        on: {
                          click: function($event) {
                            return _vm.pilot.ClearCoreBonuses()
                          }
                        }
                      },
                      [_vm._v("\n          Reset\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "right-column",
        fn: function() {
          return [
            _c(
              "v-expansion-panels",
              _vm._l(_vm.manufacturersWithCBs, function(ref) {
                var manufacturer = ref.manufacturer
                var coreBonuses = ref.coreBonuses
                return _c(
                  "v-expansion-panel",
                  {
                    key: "panel_m" + manufacturer.ID,
                    staticClass: "no-shadow"
                  },
                  [
                    _c(
                      "v-expansion-panel-header",
                      { staticClass: "px-1", attrs: { color: "panel" } },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              {
                                class:
                                  "heading " +
                                  (_vm.$vuetify.breakpoint.smAndDown
                                    ? "h3"
                                    : "h1"),
                                style:
                                  "color: " +
                                  manufacturer.GetColor(
                                    _vm.$vuetify.theme.dark
                                  ),
                                attrs: { "no-gutters": "", align: "center" }
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "auto" } },
                                  [
                                    _c("cc-logo", {
                                      attrs: {
                                        source: manufacturer,
                                        size: _vm.$vuetify.breakpoint.smAndDown
                                          ? "large"
                                          : "xLarge"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-col", { attrs: { cols: "auto" } }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(manufacturer.Name) +
                                      "\n              "
                                  )
                                ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-alert",
                              {
                                staticClass: "py-3 my-2",
                                attrs: {
                                  outlined: "",
                                  color: manufacturer.GetColor(
                                    _vm.$vuetify.theme.dark
                                  )
                                }
                              },
                              [
                                _c("v-row", { staticClass: "text-center" }, [
                                  _c("span", {
                                    staticClass: "flavor-text text--text",
                                    staticStyle: { width: "100%" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.requirement(manufacturer)
                                      )
                                    }
                                  })
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-expansion-panel-content",
                      { attrs: { color: "panel" } },
                      _vm._l(coreBonuses, function(b) {
                        return _c("core-bonus-select-item", {
                          key: b.ID,
                          attrs: {
                            bonus: b,
                            "is-selectable": _vm.isSelectable(b),
                            "is-selected": _vm.isSelected(b),
                            color: manufacturer.GetColor(
                              _vm.$vuetify.theme.dark
                            )
                          },
                          on: {
                            add: function($event) {
                              return _vm.pilot.AddCoreBonus(b)
                            },
                            remove: function($event) {
                              return _vm.pilot.RemoveCoreBonus(b)
                            }
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }