var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "60vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "heading h2",
              attrs: { flat: "", dense: "", dark: "", color: "damage--burn" }
            },
            [_vm._v("Burn")]
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "text-center" },
            [
              _c(
                "div",
                { staticClass: "heading h3 font-weight-bold stark--text mt-2" },
                [
                  _c("span", { staticClass: "subtle--text" }, [
                    _vm._v("FRAME.ALERT::")
                  ]),
                  _vm._v("\n        mech has been inflicted with\n        "),
                  _c("span", { staticClass: "damage--burn--text" }, [
                    _vm._v(_vm._s(_vm.mech.Burn) + " Burn")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "my-2 body-text text--text" }, [
                _vm._v("\n        Make an\n        "),
                _c("b", [_vm._v("ENGINEERING")]),
                _vm._v(
                  "\n        check to clear the current Burn , or suffer\n        "
                ),
                _c("span", { staticClass: "damage--burn--text" }, [
                  _vm._v(_vm._s(_vm.mech.Burn) + " Damage")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "text-center mt-4",
                  attrs: { justify: "center" }
                },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-n5", attrs: { lg: "auto", md: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-center mb-n3",
                          attrs: { dense: "", justify: "start", align: "start" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-8", attrs: { cols: "auto" } },
                            [
                              _c("div", { staticClass: "overline mb-n2" }, [
                                _vm._v("Engineering Roll")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "heading text--text",
                                  staticStyle: { "font-size": "24pt" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-n1",
                                      attrs: { "x-large": "" }
                                    },
                                    [_vm._v("mdi-dice-d20-outline")]
                                  ),
                                  _vm._v(
                                    "\n                + " +
                                      _vm._s(_vm.mech.Eng) +
                                      "\n                "
                                  ),
                                  _c("cc-synergy-display", {
                                    staticClass: "d-inline",
                                    attrs: {
                                      location: "engineering",
                                      mech: _vm.mech
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mr-2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            title: "Roll Engineering Check",
                            content: _vm.rollTooltip
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-1 mr-n3",
                              attrs: { icon: "", small: "", color: "accent" },
                              on: { click: _vm.rollCheck }
                            },
                            [
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v("mdi-dice-multiple")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-text-field", {
                        staticClass: "flavor-text",
                        staticStyle: { width: "300px" },
                        attrs: {
                          type: "number",
                          label: "Engineering Check Result",
                          outlined: "",
                          dense: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.roll,
                          callback: function($$v) {
                            _vm.roll = $$v
                          },
                          expression: "roll"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.roll
                ? _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _vm.roll < 10
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dark: "",
                                    tile: "",
                                    block: "",
                                    color: "damage--burn",
                                    disabled: !_vm.roll
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.complete()
                                    }
                                  }
                                },
                                [_vm._v("\n            FAILURE\n          ")]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    large: "",
                                    dark: "",
                                    tile: "",
                                    block: "",
                                    color: "success",
                                    disabled: !_vm.roll
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.complete(true)
                                    }
                                  }
                                },
                                [_vm._v("\n            SUCCESS\n          ")]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }