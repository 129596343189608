var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    {
      style: _vm.$vuetify.breakpoint.mdAndUp
        ? "min-width: 400px; max-width: 50%"
        : "",
      attrs: { cols: "12", md: "" }
    },
    [
      _vm.mech
        ? _c(
            "div",
            {
              style:
                "border: 2px solid " +
                (_vm.mech.IsActive
                  ? "var(--v-success-base)"
                  : _vm.mech.Frame.Manufacturer.GetColor(
                      _vm.$vuetify.theme.dark
                    ))
            },
            [
              _c("v-hover", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var hover = ref.hover
                        return [
                          _c(
                            "v-card",
                            {
                              attrs: { height: "300px", tile: "", flat: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("go", _vm.mech)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clipped-large",
                                  style:
                                    "z-index: 2; position: absolute; top: 0; left: -2px; right: -2px; height: 32px; background-color: " +
                                    (_vm.mech.IsActive
                                      ? "var(--v-success-base)"
                                      : _vm.mech.Frame.Manufacturer.GetColor(
                                          _vm.$vuetify.theme.dark
                                        ))
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "heading h2 white--text flavor-text ml-2",
                                      staticStyle: { "letter-spacing": "3px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.mech.Name) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  style:
                                    "z-index: 3; position: absolute; top: -5px; right: 20px; height: 32px; width:32px"
                                },
                                [
                                  _c("cc-logo", {
                                    attrs: {
                                      size: "xLarge",
                                      source: _vm.mech.Frame.Manufacturer,
                                      color: "white",
                                      stroke: _vm.mech.Frame.Manufacturer.GetColor(
                                        _vm.$vuetify.theme.dark
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.mech.IsActive
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "overline",
                                      style:
                                        "z-index: 3; position: absolute; top: 30px; left: 4px;"
                                    },
                                    [
                                      _c(
                                        "b",
                                        { staticClass: "success--text" },
                                        [_vm._v("//ACTIVE")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-img", {
                                attrs: {
                                  src: _vm.mech.Portrait,
                                  position: "top center",
                                  height: "100%"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "v-fade-transition",
                                [
                                  hover
                                    ? _c(
                                        "v-overlay",
                                        {
                                          attrs: {
                                            absolute: "",
                                            color: "grey darken-3",
                                            opacity: "0.8"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass: "flavor-text",
                                              attrs: {
                                                flat: "",
                                                tile: "",
                                                light: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(_vm.mech.Name)
                                                    )
                                                  ]),
                                                  _vm._v(
                                                    "\n                  //\n                  "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.mech.Frame.Source
                                                      ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.mech.Frame.Name
                                                        )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c("fieldset", [
                                                    _c(
                                                      "legend",
                                                      { staticClass: "px-2" },
                                                      [
                                                        _vm._v(
                                                          "\n                      Loadout//" +
                                                            _vm._s(
                                                              _vm.mech
                                                                .ActiveLoadout
                                                                ? _vm.mech
                                                                    .ActiveLoadout
                                                                    .Name
                                                                : "ERR"
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _vm.mech &&
                                                    _vm.mech.ActiveLoadout
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _vm._l(
                                                              _vm.loadoutWeapons,
                                                              function(
                                                                item,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "span",
                                                                  {
                                                                    key:
                                                                      _vm.mech
                                                                        .ID +
                                                                      "_lw_" +
                                                                      i
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          item
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            _c("br"),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              _vm.loadoutSystems,
                                                              function(
                                                                item,
                                                                i
                                                              ) {
                                                                return _c(
                                                                  "span",
                                                                  {
                                                                    key:
                                                                      _vm.mech
                                                                        .ID +
                                                                      "_ls_" +
                                                                      i
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          i > 0
                                                                            ? " - "
                                                                            : ""
                                                                        ) +
                                                                        _vm._s(
                                                                          item
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e()
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                        justify: "space-between"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "overline"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        STR\n                        "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mech
                                                                      .MaxStructure
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-divider", {
                                                        staticClass: "mx-2",
                                                        attrs: { vertical: "" }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "overline"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        HP\n                        "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mech
                                                                      .MaxHP
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-divider", {
                                                        staticClass: "mx-2",
                                                        attrs: { vertical: "" }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "overline"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        Stress\n                        "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mech
                                                                      .MaxStress
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-divider", {
                                                        staticClass: "mx-2",
                                                        attrs: { vertical: "" }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "overline"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        Heat\n                        "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mech
                                                                      .HeatCapacity
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-divider", {
                                                        staticClass: "mx-2",
                                                        attrs: { vertical: "" }
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: {
                                                            cols: "auto"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "overline"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        RepCap\n                        "
                                                              ),
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.mech
                                                                      .RepairCapacity
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.mech.Destroyed
                                                ? _c(
                                                    "v-alert",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: {
                                                        color: "error",
                                                        dense: "",
                                                        tile: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "letter-spacing":
                                                              "5px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "// DESTROYED //"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.mech.ReactorDestroyed
                                                ? _c(
                                                    "v-alert",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: {
                                                        color: "accent",
                                                        dense: "",
                                                        tile: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "letter-spacing":
                                                              "5px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "// REACTOR DESTROYED //"
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "v-expand-transition",
                                                [
                                                  _vm.mech.IsActive
                                                    ? _c(
                                                        "v-alert",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                          attrs: {
                                                            color: "success",
                                                            dense: "",
                                                            tile: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "letter-spacing":
                                                                  "5px"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "// ACTIVE //"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-divider"),
                                              _vm._v(" "),
                                              _c(
                                                "v-card-actions",
                                                [
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "cc-tooltip",
                                                    {
                                                      attrs: {
                                                        simple: "",
                                                        inline: "",
                                                        content: "Delete Mech"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "fadeSelect",
                                                          attrs: {
                                                            small: "",
                                                            icon: "",
                                                            color: "error"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.$refs.delete.show()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("delete")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "cc-tooltip",
                                                    {
                                                      attrs: {
                                                        simple: "",
                                                        inline: "",
                                                        content:
                                                          "Duplicate Mech"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "fadeSelect",
                                                          attrs: {
                                                            small: "",
                                                            icon: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.$refs.copy.show()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-content-copy"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "cc-tooltip",
                                                    {
                                                      attrs: {
                                                        simple: "",
                                                        inline: "",
                                                        content:
                                                          "Print Mech Sheet"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "fadeSelect",
                                                          attrs: {
                                                            small: "",
                                                            icon: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.$refs.print.show()
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-printer"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "cc-tooltip",
                                                    {
                                                      attrs: {
                                                        simple: "",
                                                        inline: "",
                                                        content:
                                                          "Set As Active Mech"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "fadeSelect",
                                                          attrs: {
                                                            small: "",
                                                            icon: "",
                                                            disabled:
                                                              _vm.mech.Pilot
                                                                .ActiveMech ===
                                                              _vm.mech
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              _vm.mech.Pilot.State.ActiveMech =
                                                                _vm.mech
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "cci-activate"
                                                            )
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3066558662
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("copy-mech-dialog", {
        ref: "copy",
        attrs: { mech: _vm.mech },
        on: {
          copy: function($event) {
            return _vm.$emit("copy", _vm.mech)
          }
        }
      }),
      _vm._v(" "),
      _c("delete-mech-dialog", {
        ref: "delete",
        attrs: { mech: _vm.mech },
        on: {
          delete: function($event) {
            return _vm.$emit("delete", _vm.mech)
          }
        }
      }),
      _vm._v(" "),
      _c("print-dialog", { ref: "print", attrs: { pilot: _vm.mech.Pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }