var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card-base",
    { attrs: { item: _vm.item, small: _vm.small, hover: _vm.hover } },
    [
      _c(
        "div",
        { attrs: { slot: "top" }, slot: "top" },
        [
          _vm.item.ItemType.toLowerCase() === "pilotarmor"
            ? _c(
                "v-row",
                {
                  staticClass: "mt-2",
                  attrs: { dense: "", justify: "space-around" }
                },
                [
                  _vm.item.Armor
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                simple: "",
                                inline: "",
                                content: "Armor Bonus"
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-shield-outline")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "stat-text" }, [
                            _vm._v(_vm._s(_vm.item.Armor))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.HPBonus
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                simple: "",
                                inline: "",
                                content: "HP Bonus"
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-heart")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "stat-text" }, [
                            _vm._v("+" + _vm._s(_vm.item.HPBonus))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.EDefense
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                simple: "",
                                inline: "",
                                content: "Electronic Defense"
                              }
                            },
                            [_c("v-icon", [_vm._v("cci-edef")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "stat-text" }, [
                            _vm._v(_vm._s(_vm.item.EDefense))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.Evasion
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                simple: "",
                                inline: "",
                                content: "Evasion"
                              }
                            },
                            [_c("v-icon", [_vm._v("cci-evasion")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "stat-text" }, [
                            _vm._v(_vm._s(_vm.item.Evasion))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.Speed
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                simple: "",
                                inline: "",
                                content: "Speed"
                              }
                            },
                            [_c("v-icon", [_vm._v("$vuetify.icons.move")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "stat-text" }, [
                            _vm._v(_vm._s(_vm.item.Speed))
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.Description
            ? _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.item.Description,
                    expression: "item.Description"
                  }
                ],
                staticClass: "card-effect pa-2 mb-1",
                staticStyle: { "font-size": "15px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.item.Detail
            ? _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.item.Detail,
                    expression: "item.Detail"
                  }
                ],
                staticClass: "card-effect pa-2 mb-1",
                staticStyle: { "font-size": "15px" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm.item.Damage || _vm.item.Range
                ? _c(
                    "v-row",
                    {
                      staticStyle: {
                        "max-height": "200px",
                        "min-height": "50px"
                      },
                      attrs: {
                        "no-gutters": "",
                        justify: "space-around",
                        align: "center"
                      }
                    },
                    [
                      _vm.item.Damage
                        ? _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("cc-damage-element", {
                                attrs: { damage: _vm.item.Damage }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item.Range
                        ? _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("cc-range-element", {
                                attrs: { range: _vm.item.Range }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }