var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "cc-title",
                {
                  staticClass: "pl-3",
                  staticStyle: { "margin-left": "-50px !important" },
                  attrs: { small: "", color: "pilot" }
                },
                [
                  _c("section-edit-chip", {
                    attrs: {
                      highlight: !_vm.pilot.HasFullTalents,
                      current: _vm.pilot.CurrentTalentPoints,
                      max: _vm.pilot.MaxTalentPoints,
                      label:
                        "Edit Pilot Talents (" +
                        _vm.pilot.CurrentTalentPoints +
                        "/" +
                        _vm.pilot.MaxTalentPoints +
                        ")"
                    },
                    on: {
                      "open-selector": function($event) {
                        return _vm.$refs.talentSelector.show()
                      }
                    }
                  }),
                  _vm._v("\n        Talents\n      ")
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn-toggle",
                {
                  attrs: { mandatory: "" },
                  model: {
                    value: _vm.ctype,
                    callback: function($$v) {
                      _vm.ctype = $$v
                    },
                    expression: "ctype"
                  }
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { value: "full" } },
                    [_c("v-icon", [_vm._v("mdi-view-stream")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { value: "terse" } },
                    [_c("v-icon", [_vm._v("mdi-view-list")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { value: "small" } },
                    [_c("v-icon", [_vm._v("mdi-view-comfy")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "px-0" },
        [
          !_vm.pilot.Talents.length
            ? _c("no-data-block")
            : _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                _vm._l(_vm.pilot.Talents, function(t, i) {
                  return _c("cc-talent", {
                    key: "t_" + i,
                    attrs: {
                      "hide-locked": "",
                      talent: t.Talent,
                      rank: t.Rank,
                      terse: _vm.ctype === "terse",
                      small: _vm.ctype === "small"
                    }
                  })
                }),
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "talentSelector",
          attrs: {
            icon: "cci-trait",
            "no-confirm": "",
            title: "Set Pilot Talents",
            fullscreen: ""
          }
        },
        [_c("cc-talent-selector", { attrs: { pilot: _vm.pilot } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }