import { render, staticRenderFns } from "./_LicenseSelectItem.vue?vue&type=template&id=fe5ac670&scoped=true&"
import script from "./_LicenseSelectItem.vue?vue&type=script&lang=ts&"
export * from "./_LicenseSelectItem.vue?vue&type=script&lang=ts&"
import style0 from "./_LicenseSelectItem.vue?vue&type=style&index=0&id=fe5ac670&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe5ac670",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VChip,VCol,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VIcon,VImg,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fe5ac670')) {
      api.createRecord('fe5ac670', component.options)
    } else {
      api.reload('fe5ac670', component.options)
    }
    module.hot.accept("./_LicenseSelectItem.vue?vue&type=template&id=fe5ac670&scoped=true&", function () {
      api.rerender('fe5ac670', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/selectors/components/_LicenseSelectItem.vue"
export default component.exports