var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-btn-toggle",
        {
          attrs: { dense: "", mandatory: "", "active-class": "accent--text" },
          model: {
            value: _vm.tier,
            callback: function($$v) {
              _vm.tier = $$v
            },
            expression: "tier"
          }
        },
        [
          _c(
            "v-btn",
            { attrs: { value: 1 } },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("cci-rank-1")]),
              _vm._v("\n      Tier 1\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { value: 2 } },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("cci-rank-2")]),
              _vm._v("\n      Tier 2\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { value: 3 } },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("cci-rank-3")]),
              _vm._v("\n      Tier 3\n    ")
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-2 mt-n2 mb-2", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("h1", { staticClass: "heading" }, [_vm._v("NPC Classes")])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto mr-5", attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                staticClass: "search-field",
                attrs: {
                  "prepend-icon": "search",
                  flat: "",
                  "hide-actions": "",
                  "single-line": "",
                  placeholder: "Search",
                  clearable: "",
                  "persistent-hint": "",
                  hint: _vm.fItems.length + " Items"
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        directives: [
          {
            name: "resize",
            rawName: "v-resize",
            value: _vm.onResize,
            expression: "onResize"
          }
        ],
        staticClass: "elevation-0 flavor-text background",
        attrs: {
          headers: _vm.headers,
          items: _vm.fItems,
          "item-key": "ID",
          height: _vm.tableHeight,
          "hide-default-footer": "",
          "disable-pagination": "",
          "calculate-widths": "",
          "fixed-header": "",
          "multi-sort": "",
          "show-select": "",
          "single-select": ""
        },
        scopedSlots: _vm._u([
          {
            key: "item.data-table-select",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      "x-small": "",
                      fab: "",
                      color: "primary",
                      dark: ""
                    },
                    on: {
                      click: function($event) {
                        _vm.$refs["modal_" + item.ID].show()
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                  1
                ),
                _vm._v(" "),
                _c("cc-search-result-modal", {
                  ref: "modal_" + item.ID,
                  attrs: { item: item }
                })
              ]
            }
          },
          {
            key: "item.Name",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", { staticClass: "stat-text" }, [
                  _vm._v(_vm._s(item.Name))
                ])
              ]
            }
          },
          {
            key: "item.Role",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", { staticStyle: { "text-transform": "uppercase" } }, [
                  _vm._v(_vm._s(item.Role))
                ])
              ]
            }
          },
          {
            key: "item.HP",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v("\n      " + _vm._s(item.Stats.HP(_vm.tier)) + "\n    ")
              ]
            }
          },
          {
            key: "item.Armor",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " + _vm._s(item.Stats.Armor(_vm.tier)) + "\n    "
                )
              ]
            }
          },
          {
            key: "item.Structure",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " + _vm._s(item.Stats.Structure(_vm.tier)) + "\n    "
                )
              ]
            }
          },
          {
            key: "item.Heatcap",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(item.Stats.HeatCapacity(_vm.tier)) +
                    "\n    "
                )
              ]
            }
          },
          {
            key: "item.Stress",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " + _vm._s(item.Stats.Stress(_vm.tier)) + "\n    "
                )
              ]
            }
          },
          {
            key: "item.Evasion",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " + _vm._s(item.Stats.Evade(_vm.tier)) + "\n    "
                )
              ]
            }
          },
          {
            key: "item.EDef",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " + _vm._s(item.Stats.EDefense(_vm.tier)) + "\n    "
                )
              ]
            }
          },
          {
            key: "item.Speed",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " + _vm._s(item.Stats.Speed(_vm.tier)) + "\n    "
                )
              ]
            }
          },
          {
            key: "item.Sensor",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " + _vm._s(item.Stats.Sensor(_vm.tier)) + "\n    "
                )
              ]
            }
          },
          {
            key: "item.Save",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " + _vm._s(item.Stats.Save(_vm.tier)) + "\n    "
                )
              ]
            }
          },
          {
            key: "item.Hase",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(item.Stats.Hull(_vm.tier)) +
                    "/" +
                    _vm._s(item.Stats.Agility(_vm.tier)) +
                    "/" +
                    _vm._s(item.Stats.Systems(_vm.tier)) +
                    "/" +
                    _vm._s(item.Stats.Engineering(_vm.tier)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }