var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-3 mt-4", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mt-2", attrs: { dense: "", align: "end" } },
        [
          _c("v-col", { attrs: { cols: "12", md: "auto" } }, [
            _c("div", { staticClass: "heading h1 mb-n3" }, [
              _vm._v("Pilot Roster")
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn-toggle",
                {
                  staticClass: "mt-n4",
                  attrs: {
                    value: _vm.profile.GetView("roster"),
                    mandatory: "",
                    dense: ""
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", value: "list" },
                      on: {
                        click: function($event) {
                          return _vm.profile.SetView("roster", "list")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-list")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", value: "cards" },
                      on: {
                        click: function($event) {
                          return _vm.profile.SetView("roster", "cards")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-grid")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", value: "small-cards" },
                      on: {
                        click: function($event) {
                          return _vm.profile.SetView("roster", "small-cards")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-grid")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn-toggle",
                { staticClass: "mr-2" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", small: "" },
                      on: { click: _vm.showAll }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-chevron-down")]),
                      _vm._v(" "),
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c("span", [_vm._v("Expand All")])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", small: "" },
                      on: { click: _vm.hideAll }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-chevron-up")]),
                      _vm._v(" "),
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c("span", [_vm._v("Collapse All")])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.isTouch
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-switch",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        dense: "",
                        inset: "",
                        "hide-details": "",
                        color: "accent"
                      },
                      model: {
                        value: _vm.preventDnd,
                        callback: function($$v) {
                          _vm.preventDnd = $$v
                        },
                        expression: "preventDnd"
                      }
                    },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            slot: "label",
                            simple: "",
                            inline: "",
                            content: _vm.preventDnd
                              ? "Pilot Reordering: OFF"
                              : "Pilot Reordering: ON"
                          },
                          slot: "label"
                        },
                        [
                          _c("v-icon", {
                            staticClass: "ml-n2",
                            attrs: {
                              color: _vm.preventDnd ? "primary" : "accent"
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.preventDnd ? "mdi-lock" : "mdi-cursor-move"
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-x-transition",
        { attrs: { mode: "out-in" } },
        [
          _c(
            "v-container",
            {
              class: _vm.$vuetify.breakpoint.mdAndUp ? "mx-1" : "mx-n4 pa-0",
              attrs: { fluid: "" }
            },
            _vm._l(_vm.groups, function(g) {
              return _c(
                "div",
                { key: "pg_" + g },
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "pl-10 ml-n12 heading h3 white--text primary sliced",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-n1",
                              attrs: { small: "", dark: "", icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.toggleShown(g)
                                }
                              }
                            },
                            [
                              _c("v-icon", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.shown.includes(g)
                                      ? "mdi-chevron-down"
                                      : "mdi-chevron-up"
                                  )
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(
                            "\n            " +
                              _vm._s(g ? g : "Ungrouped") +
                              "\n            "
                          ),
                          _c("span", { staticClass: "overline" }, [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  _vm.pilots.filter(function(x) {
                                    return x.Group === g
                                  }).length
                                ) +
                                ")"
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      g
                        ? _c(
                            "v-col",
                            {
                              staticClass: "ml-auto mr-8",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "offset-x": "",
                                    left: "",
                                    "close-on-content-click": false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "fadeSelect",
                                                  attrs: {
                                                    dark: "",
                                                    small: "",
                                                    icon: ""
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "mdi-circle-edit-outline"
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              value: g,
                                              autofocus: "",
                                              outlined: "",
                                              "hide-details": "",
                                              label: "Group Name"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.setGroupName(
                                                  g,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "fadeSelect",
                                  attrs: { dark: "", small: "", icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteGroup(g)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-delete")])],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.shown.includes(g)
                    ? _c(
                        "div",
                        {
                          style:
                            _vm.profile.GetView("roster") !== "list"
                              ? "margin-left: -8px; width: 100vw;"
                              : ""
                        },
                        [
                          _c(
                            "v-expand-transition",
                            [
                              _c(
                                "draggable",
                                _vm._b(
                                  {
                                    key: "draggable" + g,
                                    attrs: {
                                      list: _vm.pilots.filter(function(x) {
                                        return x.Group === g
                                      }),
                                      disabled: _vm.preventDnd,
                                      group: "pilots"
                                    },
                                    on: {
                                      start: function($event) {
                                        _vm.drag = true
                                      },
                                      end: function($event) {
                                        _vm.drag = false
                                      },
                                      change: function($event) {
                                        return _vm.moved($event, g)
                                      }
                                    }
                                  },
                                  "draggable",
                                  _vm.dragOptions,
                                  false
                                ),
                                _vm._l(
                                  _vm.pilots.filter(function(x) {
                                    return x.Group === g
                                  }),
                                  function(p, i) {
                                    return _c(_vm.pilotCardType, {
                                      key: _vm.pilotCardType + "_" + i,
                                      tag: "component",
                                      attrs: {
                                        pilot: p,
                                        small:
                                          _vm.profile.GetView("roster") ===
                                          "small-cards"
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "3" } },
            [
              _c("add-pilot"),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: { top: "", "close-on-content-click": false },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mt-1",
                                attrs: {
                                  small: "",
                                  block: "",
                                  outlined: "",
                                  color: "accent"
                                }
                              },
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-folder")
                              ]),
                              _vm._v("\n            Add Group\n          ")
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.newGroupMenu,
                    callback: function($$v) {
                      _vm.newGroupMenu = $$v
                    },
                    expression: "newGroupMenu"
                  }
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { attrs: { tile: "", flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "", justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "cc-tooltip",
                                    {
                                      attrs: {
                                        simple: "",
                                        content: "Generate Random Name"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            large: "",
                                            color: "secondary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.randomName()
                                            }
                                          }
                                        },
                                        [_vm._v("mdi-dice-multiple")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      label: "Group Name",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.newGroupName,
                                      callback: function($$v) {
                                        _vm.newGroupName = $$v
                                      },
                                      expression: "newGroupName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                outlined: "",
                                block: "",
                                disabled: !_vm.newGroupName
                              },
                              on: { click: _vm.addNewGroup }
                            },
                            [
                              _vm._v(
                                "\n              Add New Group\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }