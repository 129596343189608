var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-fade-transition", { attrs: { "leave-absolute": "" } }, [
    !_vm.editing
      ? _c(
          "div",
          { key: "str", class: { "d-inline": _vm.inline } },
          [
            _vm._t("default"),
            _vm._v(" "),
            _c(
              "v-icon",
              {
                class: "fadeSelect " + (_vm.before ? "mt-n12" : ""),
                attrs: { dark: _vm.before, small: "" },
                on: {
                  click: function($event) {
                    return _vm.edit()
                  }
                }
              },
              [_vm._v("\n      mdi-circle-edit-outline\n    ")]
            )
          ],
          2
        )
      : _c(
          "div",
          { key: "editname", class: { "d-inline": _vm.inline } },
          [
            _c("v-text-field", {
              class: _vm.inline ? "" : "d-inline mx-0 my-0 mt-n4",
              attrs: {
                dense: !_vm.large,
                height: _vm.large ? "50px" : "",
                placeholder: _vm.placeholder,
                required: "",
                "hide-details": "",
                autofocus: ""
              },
              on: {
                blur: function($event) {
                  return _vm.submit()
                },
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.submit()
                },
                focus: function($event) {
                  return $event.target.select()
                }
              },
              model: {
                value: _vm.newStr,
                callback: function($$v) {
                  _vm.newStr = $$v
                },
                expression: "newStr"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }