var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-simple-table",
    {
      staticClass: "mb-4 mt-4 flavor-text panel px-0",
      attrs: { dense: "", "fixed-header": "" }
    },
    [
      _c("thead", [
        _c("tr", [
          _c("th", { staticClass: "text-left" }, [_vm._v("Name")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-left" }, [_vm._v("Frame")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-left" }, [_vm._v("Status")])
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.mechs, function(m) {
          return _c("tr", { key: m.ID }, [
            _c(
              "td",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "heading h3",
                    attrs: { text: "", color: "accent" },
                    on: {
                      click: function($event) {
                        return _vm.$emit("go", m)
                      }
                    }
                  },
                  [_vm._v(_vm._s(m.Name))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("td", [
              _vm._v(_vm._s(m.Frame.Source) + " " + _vm._s(m.Frame.Name))
            ]),
            _vm._v(" "),
            _c("td", [
              _c("b", { class: _vm.getMechStatus(m)[1] }, [
                _vm._v(_vm._s(_vm.getMechStatus(m)[0]))
              ])
            ])
          ])
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }