var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "notifier" } },
    [
      _c(
        "transition-group",
        {
          staticClass: "d-flex flex-column align-right notifierContainer",
          attrs: { name: "snackFade", tag: "div" }
        },
        _vm._l(_vm.shownNotifications, function(notification) {
          return _c("notification-snackbar", {
            key: notification.id,
            staticClass: "mb-1 align-end snackFade",
            attrs: { notification: notification, timeout: 5000 },
            on: {
              "dismiss-snackbar": function($event) {
                return _vm.hideNotification(notification.id)
              }
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }