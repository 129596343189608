var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-col", [
    _c("div", { staticClass: "heading h3 text-center" }, [
      _vm._v("\n    " + _vm._s(_vm.attr) + "\n    "),
      _c("br"),
      _vm._v(" "),
      _c("b", { staticClass: "accent--text heading h2" }, [
        _vm._v(_vm._s(_vm.val))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }