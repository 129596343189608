











































































































































import ActionMenuButton from '../../components/ActionMenuButton.vue'
import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'turn-footer',
  components: { ActionMenuButton },
  data: () => ({
    emDialog: false,
    rcDialog: false,
  }),
  methods: {
    openMenu(idx: number) {
      console.log(idx)
    },
  },
})
