var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: {
        complete: _vm.canContinue,
        exit: "/pilot/" + _vm.pilot.ID,
        back: ""
      },
      on: {
        back: function($event) {
          return _vm.$emit("back")
        },
        complete: function($event) {
          return _vm.$emit("next")
        }
      }
    },
    [
      _c("cc-title", { attrs: { large: "" } }, [
        _vm._v("Improve Skill Triggers ")
      ]),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "heading" },
        [
          _vm._v("\n    MV-2 License Acquisition Request\n    "),
          _c("cc-slashes"),
          _vm._v("\n     MV-2//a Skill Improvement Assessment\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "absolute", right: "16px", top: "16px" } },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Feature In Development" } },
            [
              _c(
                "v-btn",
                { attrs: { small: "", outlined: "", disabled: "" } },
                [_vm._v("Suggest Skills")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text", staticStyle: { "font-size": "14px" } },
        [
          _c("span", [
            _vm._v(
              "\n      The MV2//a Pilot Self Assessment audit catalogs an individual pilot's self-reported\n      strengths based on selections derived from battlefield telemetry and debrief records\n      analyzed by a Union Naval NHP representative.\n      "
            ),
            _c("br"),
            _vm._v(" "),
            _c("b", [_vm._v("NB:")]),
            _vm._v(
              "\n      The following form is comprised of unfiltered output generated by one or more\n      UNI-TACANALYSIS Non-Human Persons and MAY NOT be copied, translated, reproduced,\n      transmitted, or encoded without the express permisson of Union Naval Intelligence.\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "mt-2",
              attrs: {
                color: "accent",
                outlined: "",
                dense: "",
                border: "left"
              }
            },
            [
              _c("b", [_vm._v("Add or Improve a Skill Trigger.")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  "\n        By submitting this form you attest that your responses are truthful and accurate to the\n        best of your knowledge. Knowingly providing false or or incomplete information is\n        punishable under DoJ/HR AR 303-J.\n      "
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-skill-selector", { attrs: { "level-up": "", pilot: _vm.pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }