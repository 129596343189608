var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-title",
        {
          staticClass: "pl-3",
          staticStyle: { "margin-left": "-50px!important" },
          attrs: { small: "", color: "pilot" }
        },
        [
          _c("section-edit-chip", {
            attrs: {
              highlight: !_vm.pilot.HasFullHASE,
              current: _vm.pilot.CurrentHASEPoints,
              max: _vm.pilot.MaxHASEPoints,
              label:
                "Edit Pilot Mech Skills (" +
                _vm.pilot.CurrentHASEPoints +
                "/" +
                _vm.pilot.MaxHASEPoints +
                ")"
            },
            on: {
              "open-selector": function($event) {
                return _vm.$refs.mechskillsSelector.show()
              }
            }
          }),
          _vm._v("\n    Mech Skills\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "mechskillsSelector",
          attrs: {
            icon: "cci-frame",
            "no-confirm": "",
            title: "Set Pilot Mech Skills",
            fullscreen: ""
          }
        },
        [_c("cc-mech-skills-selector", { attrs: { pilot: _vm.pilot } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "pt-3 pb-3",
          attrs: { dense: "", justify: "space-around" }
        },
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "auto", dense: "" } },
            [
              _c("hase-pips", {
                attrs: {
                  title: "hull",
                  "skill-points": _vm.pilot.MechSkills.Hull
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "auto", dense: "" } },
            [
              _c("hase-pips", {
                attrs: {
                  title: "agility",
                  "skill-points": _vm.pilot.MechSkills.Agi
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "auto", dense: "" } },
            [
              _c("hase-pips", {
                attrs: {
                  title: "systems",
                  "skill-points": _vm.pilot.MechSkills.Sys
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "auto", dense: "" } },
            [
              _c("hase-pips", {
                attrs: {
                  title: "engineering",
                  "skill-points": _vm.pilot.MechSkills.Eng
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }