var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.empty && _vm.mech.FreeSP <= 0
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "end" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "fadeSelect",
                      attrs: {
                        text: "",
                        dark: "",
                        small: "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.$refs.selectorDialog.show()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v("\n        Add Additional System\n      ")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "cc-solo-dialog",
                    {
                      ref: "selectorDialog",
                      attrs: {
                        "no-confirm": "",
                        title: "SELECT EQUIPMENT",
                        fullscreen: "",
                        "no-pad": ""
                      }
                    },
                    [
                      _c("system-selector", {
                        attrs: { mech: _vm.mech },
                        on: {
                          equip: function($event) {
                            return _vm.equipExtra($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "slot-card-base",
            {
              ref: "base",
              attrs: {
                item: _vm.item,
                color: _vm.color,
                mech: _vm.mech,
                empty: _vm.empty,
                readonly: _vm.readonly
              }
            },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm.item
                  ? _c(
                      "span",
                      [
                        _c("equipment-options", {
                          attrs: { item: _vm.item, readonly: _vm.readonly }
                        }),
                        _vm._v(" "),
                        !_vm.item.Destroyed
                          ? _c(
                              "span",
                              { key: _vm.item.Name, staticClass: "ml-n2" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.item.Name) +
                                    "\n          "
                                ),
                                _vm.item.FlavorName
                                  ? _c(
                                      "span",
                                      { staticClass: "caption ml-2 my-n1" },
                                      [_vm._v("//" + _vm._s(_vm.item.TrueName))]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _c(
                              "span",
                              {
                                key: _vm.item.Name + "_dest",
                                staticClass: "py-1 error",
                                staticStyle: { "letter-spacing": "3px" }
                              },
                              [
                                _vm._v("\n           //\n          "),
                                _c("strike", [_vm._v(_vm._s(_vm.item.Name))]),
                                _vm._v("\n          // \n        ")
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _c("span", [_vm._v("System")])
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c(
                    "div",
                    {
                      staticClass: "text-right d-inline",
                      attrs: { slot: "header-items" },
                      slot: "header-items"
                    },
                    [
                      _vm.item
                        ? _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c("span", { staticClass: "heading h2" }, [
                                _vm._v(_vm._s(_vm.item.SP))
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v("SP")
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.readonly
                        ? _c(
                            "div",
                            {
                              staticClass: "d-inline pl-3 ml-3",
                              staticStyle: {
                                "border-left": "1px solid #616161"
                              }
                            },
                            [
                              _vm.item
                                ? _c(
                                    "v-icon",
                                    {
                                      staticClass: "fadeSelect mt-n1",
                                      attrs: { dark: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.remove(_vm.item)
                                        }
                                      }
                                    },
                                    [_vm._v("\n          delete\n        ")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-icon", {
                                staticClass: "fadeSelect mt-n1",
                                attrs: { dark: "" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.item
                                      ? "mdi-swap-vertical-variant"
                                      : "add"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.$refs.base.$refs.selectorDialog.show()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item
                ? _c(
                    "div",
                    [
                      _vm.item.IsCascading
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "text-center white--text stat-text",
                              staticStyle: { "letter-spacing": "3px" },
                              attrs: { dense: "", tile: "", color: "error" }
                            },
                            [_vm._v("\n        / / AI IN CASCADE / /\n      ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.item && _vm.item.Effect
                        ? _c("div", [
                            _c(
                              "div",
                              { staticClass: "overline mt-2" },
                              [
                                _c("v-icon", [_vm._v("cci-system")]),
                                _vm._v("\n          EQUIPMENT EFFECT\n        ")
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("p", {
                              directives: [
                                {
                                  name: "html-safe",
                                  rawName: "v-html-safe",
                                  value: _vm.item.Effect,
                                  expression: "item.Effect"
                                }
                              ],
                              staticClass: "text--text body-text mb-1 mr-3 ml-7"
                            })
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item && _vm.item.Ammo && _vm.item.Ammo.length
                ? _c(
                    "div",
                    _vm._l(_vm.item.Ammo, function(a, i) {
                      return _c(
                        "div",
                        {
                          key: _vm.item.Name + "_ammo_" + i,
                          staticClass: "body-text"
                        },
                        [
                          _c("b", [_vm._v(_vm._s(a.name))]),
                          _vm._v("\n        :\n        "),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(a.detail) }
                          })
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("system-selector", {
                attrs: { slot: "selector", mech: _vm.mech, equipped: _vm.item },
                on: {
                  equip: function($event) {
                    return _vm.equip($event)
                  }
                },
                slot: "selector"
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }