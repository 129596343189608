var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "auto", md: "12" } },
    [
      _c(
        "cc-tooltip",
        {
          attrs: {
            simple: "",
            inline: "",
            content: _vm.attr.toUpperCase() + ": " + _vm.val
          }
        },
        [
          _c("span", { staticClass: "overline no-height ml-n1" }, [
            _vm._v("\n      " + _vm._s(_vm.attr) + "\n    ")
          ])
        ]
      ),
      _vm._v(" "),
      _c("cc-synergy-display", {
        staticClass: "d-inline",
        attrs: { location: _vm.attr, mech: _vm.mech }
      }),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.lgAndUp
        ? _c("div", { staticClass: "mt-n2 ml-1" }, [
            _c(
              "span",
              { staticClass: "no-height ml-n1" },
              _vm._l(_vm.val, function(n) {
                return _c("v-icon", {
                  key: "hase_" + _vm.attr + "-" + n,
                  attrs: { small: "", color: _vm.color },
                  domProps: { innerHTML: _vm._s("mdi-hexagon") }
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { class: "no-height " + (_vm.val ? "ml-n1" : "") },
              _vm._l(6 - _vm.val, function(n) {
                return _c("v-icon", {
                  key: "hase_" + _vm.attr + "-n" + n,
                  attrs: { small: "", color: "grey" },
                  domProps: { innerHTML: _vm._s("mdi-hexagon-outline") }
                })
              }),
              1
            )
          ])
        : _c("div", { staticClass: "mt-n2" }, [
            _c("span", { staticClass: "accent--text heading h2" }, [
              _vm._v(_vm._s(_vm.val))
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }