var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("selector", {
    attrs: {
      title: "Pilot Licenses",
      height: "60vh",
      success: !_vm.pilot.IsMissingLicenses
    },
    scopedSlots: _vm._u([
      {
        key: "left-column",
        fn: function() {
          return [
            _vm._l(_vm.pilot.Licenses, function(pl) {
              return _c(
                "v-row",
                {
                  key: "summary_" + pl.License.Name,
                  staticClass: "my-2",
                  staticStyle: { width: "98%" }
                },
                [
                  pl.License.err
                    ? _c("missing-item", {
                        on: {
                          remove: function($event) {
                            return _vm.remove(pl)
                          }
                        }
                      })
                    : _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: _vm.manufacturer(pl.License.Source).Color
                              }
                            },
                            [_vm._v("cci-rank-" + _vm._s(pl.Rank))]
                          ),
                          _vm._v(" "),
                          _c("strong", [_vm._v(_vm._s(pl.License.Name))]),
                          _vm._v(" "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "fadeSelect",
                              attrs: { right: "" },
                              on: {
                                click: function($event) {
                                  return _vm.scroll(pl.License.FrameID)
                                }
                              }
                            },
                            [_vm._v("\n          mdi-chevron-right\n        ")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm.pilot.Licenses.length
              ? _c("v-divider", { staticClass: "ma-2 ml-4 mr-4" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c(
                  "v-alert",
                  {
                    staticClass: "stat-text",
                    staticStyle: { width: "95%" },
                    attrs: {
                      outlined: "",
                      color: "success",
                      icon: "check_circle",
                      value: !_vm.pilot.IsMissingLicenses
                    }
                  },
                  [_vm._v("\n        License Selection Complete\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "v-alert",
                  {
                    staticClass: "stat-text",
                    attrs: {
                      outlined: "",
                      color: "accent",
                      icon: "warning",
                      value: _vm.pilot.IsMissingLicenses
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.pilot.CurrentLicensePoints) +
                        " / " +
                        _vm._s(_vm.pilot.MaxLicensePoints) +
                        " Licenses selected\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "my-2" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          text: "",
                          small: "",
                          disabled: !_vm.pilot.Licenses.length
                        },
                        on: {
                          click: function($event) {
                            return _vm.pilot.ClearLicenses()
                          }
                        }
                      },
                      [_vm._v("\n          Reset\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "right-column",
        fn: function() {
          return _vm._l(Object.keys(_vm.licenses), function(m) {
            return _c(
              "v-row",
              { key: m },
              [
                _c(
                  "v-col",
                  { staticClass: "text-center pa-3" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "heading mech",
                        style:
                          "color: " +
                          _vm.manufacturer(m).GetColor(_vm.$vuetify.theme.dark)
                      },
                      [
                        _c("cc-logo", {
                          staticClass: "pt-4",
                          attrs: { source: _vm.manufacturer(m), size: "xLarge" }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.manufacturer(m).Name) +
                            "\n        "
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-expansion-panels",
                      {
                        attrs: {
                          accordion: "",
                          focusable: "",
                          "active-class": "border-primary"
                        }
                      },
                      _vm._l(_vm.licenses[m], function(l) {
                        return _c("license-select-item", {
                          key: l.FrameID,
                          attrs: {
                            id: "e_" + l.FrameID,
                            license: l,
                            "is-selectable": l.CanSelect(_vm.pilot),
                            rank: _vm.pilot.getLicenseRank(l.Name)
                          },
                          on: {
                            add: function($event) {
                              return _vm.pilot.AddLicense(l)
                            },
                            remove: function($event) {
                              return _vm.pilot.RemoveLicense(l)
                            }
                          }
                        })
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "mt-5 mb-0" })
                  ],
                  1
                )
              ],
              1
            )
          })
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }