












































































import Vue from 'vue'
import Selector from './components/_SelectorBase.vue'
import MissingItem from './components/_MissingItem.vue'
import LicenseSelectItem from './components/_LicenseSelectItem.vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { Pilot } from '@/class'

export default Vue.extend({
  name: 'cc-license-selector',
  components: { Selector, LicenseSelectItem, MissingItem },
  props: {
    pilot: Pilot,
    levelUp: Boolean,
  },
  data: () => ({
    licenses: [],
  }),
  computed: {
    selectionComplete(): boolean {
      return this.levelUp && !this.pilot.IsMissingLicenses
    },
  },
  watch: {
    selectionComplete(bool) {
      if (bool) window.scrollTo(0, document.body.scrollHeight)
    },
  },
  created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.licenses = this.$_.groupBy(
      compendium.Licenses.filter(x => !x.Hidden),
      'Source'
    )
  },
  methods: {
    scroll(id) {
      if (this.levelUp)
        this.$vuetify.goTo(`#e_${id}`, {
          duration: 150,
          easing: 'easeInOutQuad',
          offset: 25,
        })
      else
        this.$vuetify.goTo(`#e_${id}`, {
          duration: 150,
          easing: 'easeInOutQuad',
          offset: 25,
          container: '.v-dialog--active',
        })
    },
    manufacturer(id: string) {
      const compendium = getModule(CompendiumStore, this.$store)
      return compendium.referenceByID('Manufacturers', id.toUpperCase())
    },
  },
})
