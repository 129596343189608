import { render, staticRenderFns } from "./_OverchargeDialog.vue?vue&type=template&id=bfbcd0ec&"
import script from "./_OverchargeDialog.vue?vue&type=script&lang=ts&"
export * from "./_OverchargeDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VIcon,VRow,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bfbcd0ec')) {
      api.createRecord('bfbcd0ec', component.options)
    } else {
      api.reload('bfbcd0ec', component.options)
    }
    module.hot.accept("./_OverchargeDialog.vue?vue&type=template&id=bfbcd0ec&", function () {
      api.rerender('bfbcd0ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/active_loadout/dialogs/action/_OverchargeDialog.vue"
export default component.exports