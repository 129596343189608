var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "stat-text pt-0 pb-0 mt-n2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6", md: "4", xl: "3" } },
            [
              _c("div", { staticClass: "overline mb-n3 subtle--text" }, [
                _vm._v("CALLSIGN")
              ]),
              _vm._v(" "),
              _c(
                "cc-short-string-editor",
                {
                  on: {
                    set: function($event) {
                      _vm.pilot.Callsign = $event
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(_vm.pilot.Callsign) + "\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6", md: "4", xl: "3" } },
            [
              _c("div", { staticClass: "overline mb-n3 subtle--text" }, [
                _vm._v("NAME")
              ]),
              _vm._v(" "),
              _c(
                "cc-short-string-editor",
                {
                  on: {
                    set: function($event) {
                      _vm.pilot.Name = $event
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.pilot.Name))]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6", md: "4", xl: "3" } },
            [
              _c("div", { staticClass: "overline mb-n3 subtle--text" }, [
                _vm._v("BACKGROUND")
              ]),
              _vm._v(" "),
              _c(
                "cc-short-string-editor",
                {
                  staticClass: "d-inline",
                  on: {
                    set: function($event) {
                      _vm.pilot.Background = $event
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.pilot.Background) + "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c("cc-background-selector", {
                    staticClass: "d-inline fadeSelect ml-n1",
                    attrs: { pilot: _vm.pilot, small: "" },
                    on: {
                      select: function($event) {
                        _vm.pilot.Background = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6", md: "4", xl: "3" } },
            [
              _c("div", { staticClass: "overline mb-n3 subtle--text" }, [
                _vm._v("PLAYER")
              ]),
              _vm._v(" "),
              _c(
                "cc-short-string-editor",
                {
                  on: {
                    set: function($event) {
                      _vm.pilot.PlayerName = $event
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.pilot.PlayerName || "---") +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6", md: "4", xl: "3" } },
            [
              _c("div", { staticClass: "overline mb-n3 subtle--text" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$vuetify.breakpoint.mdAndUp,
                        expression: "$vuetify.breakpoint.mdAndUp"
                      }
                    ]
                  },
                  [_vm._v("OMNINET")]
                ),
                _vm._v("\n        VAULT\n      ")
              ]),
              _vm._v(" "),
              (_vm.pilot.CloudOwnerID && _vm.pilot.IsLocallyOwned) ||
              _vm.pilot.IsUserOwned
                ? _c("span", [_vm._v("\n        Current User\n      ")])
                : !_vm.pilot.IsLocallyOwned ||
                  (!_vm.pilot.IsUserOwned && _vm.pilot.GistOwner)
                ? _c("span", [_vm._v("\n        Remote Sync\n      ")])
                : _c("span", { staticClass: "stat-text error--text" }, [
                    _vm._v("\n        // NOT SYNCED //\n      ")
                  ]),
              _vm._v(" "),
              _vm.currentAuthedUser
                ? _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        title: "Copy Vault Sync (COMP/CON Account) Code",
                        content: "Last Sync at:<br>" + _vm.pilot.LastSync
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "fadeSelect",
                          attrs: { small: "" },
                          on: {
                            click: function($event) {
                              return _vm.copyVault()
                            }
                          }
                        },
                        [_vm._v("mdi-qrcode-scan")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pilot.IsUserOwned && _vm.pilot.GistCode
                ? _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        title: "Copy Share Code",
                        content:
                          "Public Share Code<br>Last Sync at:<br>" +
                          _vm.pilot.LastSync
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "fadeSelect",
                          attrs: { small: "" },
                          on: {
                            click: function($event) {
                              return _vm.copyCode()
                            }
                          }
                        },
                        [_vm._v("mdi-barcode-scan")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.pilot.GistOwner && _vm.pilot.GistCode
                ? _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        title: "Sync",
                        content:
                          "Public Cloud Save<br>Last Sync at:<br>" +
                          _vm.pilot.LastSync
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "fadeSelect",
                          attrs: { small: "" },
                          on: {
                            click: function($event) {
                              return _vm.sync()
                            }
                          }
                        },
                        [_vm._v("mdi-reload")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "6", md: "4", xl: "3" } },
            [
              _c("div", { staticClass: "overline mb-n3 subtle--text" }, [
                _vm._v("STATUS")
              ]),
              _vm._v(" "),
              _c(
                "span",
                { class: "stat-text " + _vm.statusColor() + "--text" },
                [_vm._v(_vm._s(_vm.pilot.Status))]
              ),
              _vm._v(" "),
              _c("cc-combo-select", {
                attrs: { items: _vm.pilotStatuses },
                on: {
                  set: function($event) {
                    _vm.pilot.Status = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cloud-manager", {
        ref: "cloud",
        attrs: { pilot: _vm.pilot },
        on: {
          "end-sync": function($event) {
            _vm.syncing = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }