var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: { "margin-top": "50px", "min-height": "calc(100vh - 50px)" },
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "cc-title",
                { staticClass: "mt-3 ml-n12", attrs: { color: "primary" } },
                [_vm._v("\n         ACTIVE MISSIONS\n      ")]
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "transparent heading h3",
                staticStyle: { "min-width": "100%" },
                attrs: {
                  items: _vm.activeMissions,
                  headers: _vm.headers,
                  "no-data-text": "No Active Missions",
                  "disable-pagination": "",
                  "hide-default-footer": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.Mission.Name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.Mission.Name) +
                            "\n          "
                        ),
                        _c("span", { staticClass: "overline" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                item.Mission.Campaign &&
                                  item.Mission.Campaign !== "None"
                                  ? "//" + item.Mission.Campaign
                                  : ""
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.Encounter",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.Mission.Steps[item.Step].Name
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.Mission.Steps[item.Step].Name) +
                                  "\n          "
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  item.Mission.Steps[item.Step].IsLong
                                    ? "Full"
                                    : "Short"
                                ) + " Rest"
                              )
                            ])
                      ]
                    }
                  },
                  {
                    key: "item.Continue",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "offset-y": "",
                              "offset-x": "",
                              top: "",
                              "nudge-left": "30px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "fadeSelect mr-2",
                                            attrs: {
                                              small: "",
                                              icon: "",
                                              color: "error"
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-delete")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "text-center" },
                                  [
                                    _vm._v(
                                      "\n                This will delete the active mission\n                "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        "(" +
                                          _vm._s(item.Mission.Name) +
                                          " - " +
                                          _vm._s(item.StartDate) +
                                          ")"
                                      )
                                    ]),
                                    _vm._v(
                                      "\n                and all progress will be lost.\n                "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                Are you sure?\n                "
                                    ),
                                    _c("v-divider", { staticClass: "my-2" }),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          { attrs: { small: "", text: "" } },
                                          [_vm._v("CANCEL")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-auto",
                                            attrs: {
                                              small: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteActiveMission(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    CONFIRM\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            attrs: {
                              small: "",
                              tile: "",
                              color: "primary",
                              to: "runner/" + item.ID
                            }
                          },
                          [
                            _vm._v(
                              "\n            CONTINUE MISSION\n            "
                            ),
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v("mdi-chevron-double-right")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "cc-title",
                { staticClass: "mt-3 ml-n12", attrs: { color: "secondary" } },
                [_vm._v("\n         AVAILABLE MISSIONS\n      ")]
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "transparent heading",
                staticStyle: { "min-width": "100%" },
                attrs: {
                  items: _vm.availableMissions,
                  headers: _vm.availableHeaders,
                  "group-by": "Campaign",
                  "no-data-text": "No Saved Missions"
                },
                scopedSlots: _vm._u([
                  {
                    key: "group.header",
                    fn: function(h) {
                      return [
                        _c(
                          "div",
                          { staticClass: "secondary darken-2 sliced" },
                          [
                            h.group && h.group !== "null"
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "heading white--text text-uppercase"
                                  },
                                  [
                                    Array.isArray(h.group)
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              h.group.join(", ")
                                            )
                                          }
                                        })
                                      : _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(h.group)
                                          }
                                        })
                                  ]
                                )
                              : _c("span", [_vm._v("NONE")])
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.Name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("span", { staticClass: "heading h3" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.Name) +
                              "\n          "
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.Encounters",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("span", { staticClass: "heading h3" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.Encounters.length) +
                              "\n            "
                          ),
                          _c("span", { staticClass: "overline" }, [
                            _vm._v("// " + _vm._s(item.Rests.length) + " Rests")
                          ])
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.Labels",
                    fn: function(ref) {
                      var item = ref.item
                      return _vm._l(item.Labels, function(l) {
                        return _c(
                          "v-chip",
                          { key: item.ID + l, attrs: { small: "" } },
                          [_vm._v(_vm._s(l))]
                        )
                      })
                    }
                  },
                  {
                    key: "item.Start",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "white--text",
                            attrs: {
                              small: "",
                              tile: "",
                              color: "primary",
                              to: "briefing/" + item.ID
                            }
                          },
                          [
                            _vm._v("\n            START MISSION\n            "),
                            _c("v-icon", { attrs: { right: "" } }, [
                              _vm._v("mdi-chevron-double-right")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            [
              _c(
                "cc-title",
                { staticClass: "mt-3 ml-n12", attrs: { color: "pilot" } },
                [_vm._v("\n         COMPLETED MISSIONS\n      ")]
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "transparent heading h3",
                staticStyle: { "min-width": "100%" },
                attrs: {
                  items: _vm.completedMissions,
                  headers: _vm.completedHeaders,
                  "group-by": "Campaign",
                  "no-data-text": "No Completed Missions"
                },
                scopedSlots: _vm._u([
                  {
                    key: "group.header",
                    fn: function(h) {
                      return [
                        _c("div", { staticClass: "pilot sliced" }, [
                          h.group && h.group !== "null"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "heading white--text text-uppercase"
                                },
                                [
                                  Array.isArray(h.group)
                                    ? _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(h.group.join(", "))
                                        }
                                      })
                                    : _c("span", {
                                        domProps: { innerHTML: _vm._s(h.group) }
                                      })
                                ]
                              )
                            : _c("span", [_vm._v("NONE")])
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.Remove",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "offset-y": "",
                              "offset-x": "",
                              top: "",
                              "nudge-left": "30px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "fadeSelect mr-2",
                                            attrs: {
                                              small: "",
                                              icon: "",
                                              color: "error"
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-delete")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _vm._v(" "),
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "text-center" },
                                  [
                                    _vm._v(
                                      "\n                This will delete the saved mission data for\n                "
                                    ),
                                    _c("b", [
                                      _vm._v(_vm._s(item.Mission.Name))
                                    ]),
                                    _vm._v(
                                      "\n                .\n                "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                Are you sure?\n                "
                                    ),
                                    _c("v-divider", { staticClass: "my-2" }),
                                    _vm._v(" "),
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          { attrs: { small: "", text: "" } },
                                          [_vm._v("CANCEL")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ml-auto",
                                            attrs: {
                                              small: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteActiveMission(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    CONFIRM\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }