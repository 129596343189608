var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-inline-flex ma-2",
      style: "min-width: " + _vm.minWidth + ";"
    },
    [
      _c("v-hover", {
        style: "min-width: " + _vm.minWidth,
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hover = ref.hover
              return [
                _c(
                  "v-card",
                  {
                    staticClass: "card-outline",
                    attrs: {
                      "min-height": _vm.minWidth,
                      "min-width": _vm.minWidth,
                      tile: "",
                      flat: ""
                    },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("pilot/" + _vm.pilot.ID)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !(_vm.small && _vm.mobile),
                            expression: "!(small && mobile)"
                          }
                        ],
                        staticClass: "clipped-large",
                        style:
                          "\n            z-index: 2; position: absolute; top: 0; left: -2px; right: -2px; height: " +
                          (_vm.small || _vm.mobile ? "25" : "32") +
                          "px; " +
                          (_vm.small && hover ? "opacity: 1" : "opacity: 0.6") +
                          "; background-color: var(--v-primary-base); transition: opacity 0.2s;"
                      },
                      [
                        _c(
                          "div",
                          {
                            class:
                              "heading " +
                              (_vm.small || _vm.mobile ? "h3" : "h2") +
                              " white--text flavor-text ml-2",
                            staticStyle: {
                              "letter-spacing": "3px",
                              "text-overflow": "ellipsis"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.pilot.Callsign) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !(_vm.small && _vm.mobile),
                          expression: "!(small && mobile)"
                        }
                      ],
                      class: _vm.small ? "small-triangle" : "triangle"
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !(_vm.small && _vm.mobile),
                            expression: "!(small && mobile)"
                          }
                        ],
                        staticClass: "ll white--text",
                        staticStyle: { "line-height": "25px" }
                      },
                      [
                        !_vm.small
                          ? _c(
                              "div",
                              { staticClass: "overline mb-n1 text-right" },
                              [_vm._v("LL")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            class:
                              "heading " + (_vm.small ? "h3" : "h2") + " mt-n2"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.pilot.Level.toString().padStart(2, "0")
                                ) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-img", {
                      attrs: {
                        src: _vm.pilot.Portrait,
                        position: "top center",
                        height: "100%",
                        "aspect-ratio": 1
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "v-fade-transition",
                      [
                        hover && !_vm.small
                          ? _c(
                              "v-overlay",
                              {
                                attrs: {
                                  absolute: "",
                                  color: "grey darken-3",
                                  opacity: "0.8"
                                }
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "flavor-text",
                                    attrs: { flat: "", tile: "", light: "" }
                                  },
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.pilot.Name) +
                                            "\n                "
                                        ),
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.pilot.Callsign))
                                        ]),
                                        _vm._v(" "),
                                        _c("cc-slashes"),
                                        _vm._v(" "),
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.pilot.Background))
                                        ]),
                                        _vm._v(" "),
                                        _c("cc-slashes"),
                                        _vm._v(" "),
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.pilot.Status))
                                        ]),
                                        _vm._v(" "),
                                        _c("v-divider"),
                                        _vm._v(
                                          "\n                HULL " +
                                            _vm._s(_vm.pilot.MechSkills.Hull) +
                                            " - AGI " +
                                            _vm._s(_vm.pilot.MechSkills.Agi) +
                                            " - SYS\n                " +
                                            _vm._s(_vm.pilot.MechSkills.Sys) +
                                            " - ENG\n                " +
                                            _vm._s(_vm.pilot.MechSkills.Eng) +
                                            "\n                "
                                        ),
                                        _c("v-divider"),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          _vm._l(_vm.pilot.Talents, function(
                                            s,
                                            i
                                          ) {
                                            return _c(
                                              "span",
                                              {
                                                key:
                                                  _vm.pilot.ID + s.Talent.Name
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(s.Talent.Name) +
                                                    " " +
                                                    _vm._s("I".repeat(s.Rank)) +
                                                    "\n                    " +
                                                    _vm._s(
                                                      i + 1 !==
                                                        _vm.pilot.Talents.length
                                                        ? "-"
                                                        : ""
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c("v-divider"),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          _vm._l(
                                            _vm.pilot.CoreBonuses,
                                            function(b, i) {
                                              return _c(
                                                "span",
                                                { key: _vm.pilot.ID + b.Name },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(b.Name) +
                                                      " " +
                                                      _vm._s(
                                                        i + 1 !==
                                                          _vm.pilot.CoreBonuses
                                                            .length
                                                          ? "-"
                                                          : ""
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _vm.pilot.ActiveMech
                                          ? _c(
                                              "div",
                                              [
                                                _c("v-divider"),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "flavor-text anti--text mb-0"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.pilot.ActiveMech
                                                            .Frame.Source
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm.pilot.ActiveMech
                                                            .Frame.Name
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c("cc-slashes"),
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.pilot.ActiveMech
                                                            .Name
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }