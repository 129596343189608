var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-solo-dialog",
    {
      ref: "dialog",
      attrs: { icon: "mdi-printer", large: "", title: "Print Options" },
      on: {
        confirm: function($event) {
          return _vm.$emit("set", _vm.options)
        }
      }
    },
    [
      _c(
        "v-card-text",
        { staticClass: "flavor-text" },
        [
          _c(
            "v-card",
            { attrs: { flat: "", tile: "" } },
            [
              _c(
                "fieldset",
                [
                  _c("legend", { staticClass: "clipped-small heading h3" }, [
                    _vm._v("General Print Options ")
                  ]),
                  _vm._v(" "),
                  _c("print-option-select", {
                    attrs: {
                      mandatory: "",
                      title: "Layout",
                      items: _vm.layoutOptions
                    }
                  }),
                  _vm._v(" "),
                  _c("print-option-select", {
                    attrs: {
                      mandatory: "",
                      title: "Font Size",
                      items: _vm.fontSizeOptions
                    }
                  }),
                  _vm._v(" "),
                  _c("print-option-select", {
                    attrs: {
                      mandatory: "",
                      title: "Print Trackable Stats as:",
                      items: _vm.trackableStatOptions
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-overlay",
                {
                  attrs: {
                    absolute: "",
                    value: true,
                    color: "panel",
                    opacity: "0.85"
                  }
                },
                [
                  _c("span", { staticClass: "heading h2 text--text" }, [
                    _vm._v("Feature In Development")
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { attrs: { flat: "", tile: "" } },
            [
              _c(
                "fieldset",
                [
                  _c("legend", { staticClass: "clipped-small heading h3" }, [
                    _vm._v("Pilot Sheet Options ")
                  ]),
                  _vm._v("\n        include:\n        "),
                  _c("print-option-select", {
                    attrs: {
                      multiple: "",
                      title: "Include",
                      items: _vm.pilotIncludeOptions
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-overlay",
                {
                  attrs: {
                    absolute: "",
                    value: true,
                    color: "panel",
                    opacity: "0.85"
                  }
                },
                [
                  _c("span", { staticClass: "heading h2 text--text" }, [
                    _vm._v("Feature In Development")
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-scroll-y-transition",
            [
              _c(
                "v-card",
                { attrs: { flat: "", tile: "" } },
                [
                  _c(
                    "fieldset",
                    [
                      _c(
                        "legend",
                        { staticClass: "clipped-small heading h3" },
                        [_vm._v("Mech Sheet Options ")]
                      ),
                      _vm._v("\n          include:\n          "),
                      _c("print-option-select", {
                        attrs: {
                          multiple: "",
                          title: "Include",
                          items: _vm.mechIncludeOptions
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-overlay",
                    {
                      attrs: {
                        absolute: "",
                        value: true,
                        color: "panel",
                        opacity: "0.85"
                      }
                    },
                    [
                      _c("span", { staticClass: "heading h2 text--text" }, [
                        _vm._v("Feature In Development")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card",
            { attrs: { flat: "", tile: "" } },
            [
              _c(
                "fieldset",
                [
                  _c("legend", [_vm._v("Extras")]),
                  _vm._v(" "),
                  _c("print-option-select", {
                    attrs: {
                      multiple: "",
                      title: "Also Print",
                      items: _vm.extraOptions
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-overlay",
                {
                  attrs: {
                    absolute: "",
                    value: true,
                    color: "panel",
                    opacity: "0.85"
                  }
                },
                [
                  _c("span", { staticClass: "heading h2 text--text" }, [
                    _vm._v("Feature In Development")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }