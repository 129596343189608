var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        [
          _c("p", {
            staticClass: "text-center body-text",
            domProps: {
              innerHTML: _vm._s(
                "You poke your nose around, perhaps where it doesn’t belong. You’re investigating something, doing research, following up on a mystery, tracking a target, or keeping an eye on something. You might be doing research in a library, or go undercover in an organization to learn what you can. Whatever you’re doing, you’re generally trying to gather information on a subject of your choosing."
              )
            }
          }),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mb-2" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pt-2 heading h3 text-center" },
            [
              _vm._v("\n      Roll\n      "),
              _c("v-icon", { attrs: { large: "", color: "accent" } }, [
                _vm._v("mdi-dice-d20")
              ]),
              _vm._v(
                "\n       and add any relevant Skill Trigger bonuses, modifiers, or accuracy\n    "
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      label: "Roll Result",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      "append-outer-icon": "mdi-plus-circle-outline",
                      "prepend-icon": "mdi-minus-circle-outline"
                    },
                    on: {
                      "click:append-outer": function($event) {
                        _vm.skillRoll++
                      },
                      "click:prepend": function($event) {
                        _vm.skillRoll > 1 ? _vm.skillRoll-- : ""
                      }
                    },
                    model: {
                      value: _vm.skillRoll,
                      callback: function($$v) {
                        _vm.skillRoll = $$v
                      },
                      expression: "skillRoll"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-slide-y-transition",
            [
              _c(
                "v-row",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.skillRoll,
                      expression: "skillRoll"
                    }
                  ],
                  staticClass: "text-center flavor-text",
                  attrs: { dense: "", justify: "center" }
                },
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _vm.skillRoll < 10
                      ? _c(
                          "p",
                          { staticClass: "font-weight-bold px-3" },
                          [
                            _vm._v(
                              "\n            You can choose to get out now, or get what you are looking for. If you choose the\n            latter, you get your information but it immediately gets you into trouble.\n            "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "v-slide-x-transition",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.commit,
                                        expression: "!commit"
                                      }
                                    ],
                                    staticClass: "mx-2",
                                    attrs: {
                                      small: "",
                                      tile: "",
                                      color: "success darken-1"
                                    },
                                    on: { click: _vm.close }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Get out now\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-slide-x-reverse-transition",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.commit,
                                        expression: "!commit"
                                      }
                                    ],
                                    staticClass: "mx-2",
                                    attrs: {
                                      small: "",
                                      tile: "",
                                      color: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.commit = true
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                Get what you are looking for\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm.skillRoll < 20
                      ? _c(
                          "p",
                          { staticClass: "font-weight-bold px-3" },
                          [
                            _vm._v(
                              "\n            you find the information you’ve looking for. However...\n            "
                            ),
                            _c(
                              "v-card",
                              {
                                staticClass: "pa-1 ma-0",
                                attrs: { color: "panel" }
                              },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "pa-1 ma-0" },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        attrs: { mandatory: "" },
                                        model: {
                                          value: _vm.choice,
                                          callback: function($$v) {
                                            _vm.choice = $$v
                                          },
                                          expression: "choice"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            label: _vm.choices[0],
                                            value: 0
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("v-radio", {
                                          attrs: {
                                            label: _vm.choices[1],
                                            value: 1
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c("p", { staticClass: "font-weight-bold px-3" }, [
                          _vm._v(
                            "\n            You get your information cleanly, no complications\n          "
                          )
                        ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-slide-y-transition",
                            [
                              _vm.skillRoll >= 10 ||
                              (_vm.skillRoll < 10 && _vm.commit)
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "ml-5 mr-5 mt-2",
                                      attrs: { color: "panel" }
                                    },
                                    [
                                      _c(
                                        "v-toolbar",
                                        {
                                          attrs: {
                                            dark: "",
                                            dense: "",
                                            color: "action--downtime"
                                          }
                                        },
                                        [
                                          _c("v-toolbar-title", [
                                            _vm._v("Gathered Information")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              "auto-grow": "",
                                              rows: "1",
                                              label: "Details"
                                            },
                                            model: {
                                              value: _vm.details,
                                              callback: function($$v) {
                                                _vm.details = $$v
                                              },
                                              expression: "details"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("cancel")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                tile: "",
                color: "primary",
                disabled: !_vm.skillRoll || !_vm.details
              },
              on: { click: _vm.addReserve }
            },
            [_vm._v("\n      add reserve\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }