



































































































import _ from 'lodash'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'
import ItemSelectorRow from '../../components/_ItemSelectorRow.vue'
import InvadeDialog from './_InvadeDialog.vue'

import Vue from 'vue'
import { ActivationType } from '@/classes/enums'

export default Vue.extend({
  name: 'full-tech-dialog',
  components: { ActionDetailExpander, ItemSelectorRow, InvadeDialog },
  props: {
    used: { type: Boolean },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    quick: [],
  }),
  computed: {
    state() {
      return this.mech.Pilot.State
    },
    invadeAction() {
      return this.state.TechActions.find(x => x.ID === 'act_invade')
    },
    quickActions() {
      const qtArr = this.state.TechActions.filter(
        x => x.Activation === ActivationType.QuickTech && x.ID !== 'act_invade'
      )
      return _.groupBy(qtArr, 'Origin')
    },
    fullActions() {
      return _.groupBy(
        this.state.TechActions.filter(x => x.Activation === ActivationType.FullTech),
        'Origin'
      )
    },
  },
  watch: {
    used: {
      immediate: true,
      deep: true,
      handler: function(newval) {
        if (!newval) this.quick = []
      },
    },
  },
  methods: {
    fulltech(action) {
      const ref = `dialog_${action.ID}`
      this.$refs[ref][0].show()
    },
    init() {
      this.quick = this.quick.splice(0, this.quick.length)
    },
    addQuick(action) {
      if (action.IsTechAttack) this.fulltech(action)
      else if (this.quick.length < 2) this.quick.push(action)
    },
    removeQuick(idx) {
      this.quick.splice(idx, 1)
    },
    openInvade() {
      this.$refs.inv_dialog.init()
      this.$refs.inv_dialog.show()
    },
    systemFromFailure(failureString) {
        return failureString.split('-')[2].toUpperCase()
    },
  },
})
