import { render, staticRenderFns } from "./_BarrageDialog.vue?vue&type=template&id=431187c4&"
import script from "./_BarrageDialog.vue?vue&type=script&lang=ts&"
export * from "./_BarrageDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VContainer,VDivider})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('431187c4')) {
      api.createRecord('431187c4', component.options)
    } else {
      api.reload('431187c4', component.options)
    }
    module.hot.accept("./_BarrageDialog.vue?vue&type=template&id=431187c4&", function () {
      api.rerender('431187c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/active_loadout/dialogs/action/_BarrageDialog.vue"
export default component.exports