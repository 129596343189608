var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      class: _vm.$vuetify.breakpoint.smAndDown ? "px-1 mt-n4 mb-12" : "",
      attrs: { fluid: "" }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "mx-2 mt-n2 mb-2",
          attrs: { dense: "", "no-gutters": "", align: "center" }
        },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("v-col", [_vm._t("default")], 2)
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.noExtra,
                  expression: "!noExtra"
                }
              ],
              staticClass: "ml-auto mr-5",
              attrs: { cols: "auto" }
            },
            [_vm._t("extra-item")],
            2
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mr-1", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn-toggle",
                {
                  attrs: { mandatory: "" },
                  model: {
                    value: _vm.profile.SelectorView,
                    callback: function($$v) {
                      _vm.$set(_vm.profile, "SelectorView", $$v)
                    },
                    expression: "profile.SelectorView"
                  }
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { small: "", icon: "", value: "split" } },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-split-vertical")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { small: "", icon: "", value: "list" } },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-list")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { attrs: { small: "", icon: "", value: "cards" } },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-grid")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.$vuetify.breakpoint.mdAndUp,
                expression: "$vuetify.breakpoint.mdAndUp"
              }
            ],
            staticClass: "mx-2",
            attrs: { vertical: "" }
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto mr-5", attrs: { cols: "12", md: "3" } },
            [
              _c("v-text-field", {
                staticClass: "search-field",
                attrs: {
                  "prepend-icon": "search",
                  flat: "",
                  "hide-actions": "",
                  "single-line": "",
                  dense: "",
                  placeholder: "Search",
                  clearable: "",
                  "persistent-hint": "",
                  hint: _vm.fItems.length + " Items"
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          !_vm.noFilter
            ? _c("cc-filter-panel", {
                attrs: { "item-type": _vm.itemType },
                on: { "set-filters": _vm.setFilters }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.profile.SelectorView === "list"
        ? _c("selector-table-view", {
            attrs: {
              headers: _vm.headers,
              items: _vm.fItems,
              "sp-disable": "",
              "sp-ignore": _vm.spIgnore,
              sp: _vm.sp,
              "equipment-add": _vm.equipmentAdd
            },
            on: {
              equip: function($event) {
                return _vm.$emit("equip", $event)
              }
            }
          })
        : _vm.profile.SelectorView === "split" &&
          _vm.$vuetify.breakpoint.mdAndUp
        ? _c("selector-split-view", {
            attrs: {
              headers: _vm.headers,
              items: _vm.fItems,
              "sp-disable": "",
              "sp-ignore": _vm.spIgnore,
              sp: _vm.sp,
              "equipment-add": _vm.equipmentAdd
            },
            on: {
              equip: function($event) {
                return _vm.$emit("equip", $event)
              }
            }
          })
        : _vm.profile.SelectorView === "split"
        ? _c("selector-mobile-view", {
            attrs: {
              headers: _vm.headers,
              items: _vm.fItems,
              "sp-disable": "",
              "sp-ignore": _vm.spIgnore,
              sp: _vm.sp,
              "equipment-add": _vm.equipmentAdd
            },
            on: {
              equip: function($event) {
                return _vm.$emit("equip", $event)
              }
            }
          })
        : _vm.profile.SelectorView === "cards"
        ? _c("selector-cards-view", {
            attrs: {
              headers: _vm.headers,
              items: _vm.fItems,
              "sp-disable": "",
              "sp-ignore": _vm.spIgnore,
              sp: _vm.sp,
              "equipment-add": _vm.equipmentAdd
            },
            on: {
              equip: function($event) {
                return _vm.$emit("equip", $event)
              }
            }
          })
        : _c("div")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }