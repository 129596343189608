


















import Vue from 'vue'
export default Vue.extend({
  name: 'item-selector-row',
  computed: {
    btnColor() {
      if (this.item.ItemType === 'MechWeapon' && !this.item.Loaded) return 'grey darken-2'
      else if (this.selected) return 'accent'
      return this.color
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    overwatch: {
      type: Boolean,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
  },
})
