var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-icon",
        {
          attrs: { small: _vm.small, color: "secondary" },
          on: {
            click: function($event) {
              return _vm.open()
            }
          }
        },
        [_vm._v("cci-orbit")]
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            fullscreen: "",
            "no-confirm": "",
            title: "Select Pilot Background"
          }
        },
        [
          _c(
            "cc-sidebar-view",
            [
              _vm._l(_vm.backgrounds, function(e, i) {
                return _c(
                  "v-list-item",
                  {
                    key: i + "_sidebar'",
                    attrs: { slot: "sidebar", link: "" },
                    on: {
                      click: function($event) {
                        return _vm.$vuetify.goTo("#e_" + e.id, {
                          duration: 250,
                          easing: "easeInOutQuad",
                          offset: 25,
                          container: ".v-dialog--active"
                        })
                      }
                    },
                    slot: "sidebar"
                  },
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "heading h3 ml-2" },
                      [_vm._v(_vm._s(e.Name))]
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm._l(_vm.backgrounds, function(e, i) {
                return _c(
                  "v-row",
                  {
                    key: e.id + "_" + i,
                    attrs: { id: "e_" + e.id, dense: "" }
                  },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "cc-titled-panel",
                          {
                            staticClass: "ma-3 ml-5",
                            attrs: {
                              icon: "cci-orbit",
                              title: e.Name,
                              clickable: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.choose(e.Name)
                              }
                            }
                          },
                          [
                            _c("p", {
                              directives: [
                                {
                                  name: "html-safe",
                                  rawName: "v-html-safe",
                                  value: e.Description,
                                  expression: "e.Description"
                                }
                              ],
                              staticClass: "flavor-text"
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }