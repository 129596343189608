var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "6" } },
    [
      _c(
        "v-card",
        {
          staticClass: "text-center",
          attrs: { ripple: !_vm.disabled, outlined: "", tile: "" },
          on: {
            click: function($event) {
              _vm.to ? _vm.$router.push(_vm.to) : _vm.$emit("clicked")
            }
          }
        },
        [
          _c(
            "div",
            { class: _vm.disabled ? "grey py-4" : "primary fade py-4" },
            [
              _c("v-icon", { attrs: { dark: "", size: "125" } }, [
                _vm._v("\n        " + _vm._s(_vm.icon) + "\n      ")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "h2",
            {
              directives: [
                {
                  name: "resize-text",
                  rawName: "v-resize-text",
                  value: { minFontSize: "18px" },
                  expression: "{ minFontSize: '18px' }"
                }
              ],
              staticClass: "heading mt-1 no-wrap"
            },
            [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-1" }),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [{ name: "resize-text", rawName: "v-resize-text" }],
              staticClass: "flavor-text px-2 no-wrap"
            },
            [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }