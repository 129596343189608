var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.action.Trigger
      ? _c("p", {
          directives: [
            {
              name: "html-safe",
              rawName: "v-html-safe",
              value: _vm.action.Terse ? _vm.action.Terse : _vm.action.Detail,
              expression: "action.Terse ? action.Terse : action.Detail"
            }
          ],
          staticClass: "text--text body-text mb-2"
        })
      : _c("div", [
          _c("div", { staticClass: "overline my-n3" }, [_vm._v("Trigger")]),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.action.Trigger,
                expression: "action.Trigger"
              }
            ],
            staticClass: "ml-3 body-text stark--text"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "overline mb-n3" }, [_vm._v("Effect")]),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.action.Detail,
                expression: "action.Detail"
              }
            ],
            staticClass: "ml-3 body-text stark--text"
          })
        ]),
    _vm._v(" "),
    _vm.action.Terse
      ? _c(
          "div",
          {
            staticClass: "sidebar-box ml-2",
            style: "max-height:" + (_vm.expanded ? "100%" : "40px;")
          },
          [
            _c("div", { staticClass: "panel clipped py-1 px-2" }, [
              _c(
                "div",
                { staticClass: "caption accent--text mt-1" },
                [
                  _vm._v("\n        COMPENDIUM ENTRY\n        "),
                  _c("cc-slashes"),
                  _vm._v(" "),
                  _c("b", [_vm._v(_vm._s(_vm.action.Name))])
                ],
                1
              ),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.action.Detail,
                    expression: "action.Detail"
                  }
                ],
                staticClass: "pb-1 mb-0"
              })
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "read-more" },
              [
                _c(
                  "v-btn",
                  {
                    class: "fadeSelect " + (_vm.expanded ? "mb-n2" : "mb-n6"),
                    staticStyle: {
                      "background-color": "var(--v-stark-panel-base)"
                    },
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        _vm.expanded = !_vm.expanded
                      }
                    }
                  },
                  [
                    _c("v-icon", { attrs: { color: "accent" } }, [
                      _vm._v(
                        "mdi-chevron-double-" +
                          _vm._s(_vm.expanded ? "up" : "down")
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.expanded
              ? _c("div", { staticStyle: { "min-height": "40px" } })
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }