var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "", color: "background" } },
        [
          _c(
            "v-list",
            _vm._l(_vm.pilot.State.DowntimeActions, function(a, i) {
              return _c("downtime-menu-item", {
                key: "am_downtime_" + i,
                attrs: { action: a, available: "", pilot: _vm.pilot }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }