var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    {
      staticClass: "pa-2",
      attrs: { cols: "auto" },
      on: {
        click: function($event) {
          return _vm.$emit("clicked")
        }
      }
    },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "open-on-hover": "",
            bottom: "",
            "offset-y": "",
            "open-delay": "100"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "div",
                    _vm._g(
                      {
                        staticClass: "pa-1 mx-n2",
                        staticStyle: { position: "relative" }
                      },
                      on
                    ),
                    [
                      _c("talent-emblem", {
                        attrs: {
                          url: _vm.talent.Image,
                          name: _vm.talent.Name,
                          small: ""
                        }
                      })
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", dense: "", tile: "", color: "primary" } },
                [
                  _c("span", { staticClass: "heading h3 white--text" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.talent.Name) + "\n          "
                    ),
                    _vm.rank
                      ? _c(
                          "span",
                          { staticClass: "flavor-text white--text" },
                          [
                            _c("cc-slashes"),
                            _vm._v(
                              "\n            RANK " +
                                _vm._s("I".repeat(parseInt(_vm.rank))) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }