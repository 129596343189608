var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c("v-card-text", { staticClass: "px-2 py-0 background" }, [
            _c(
              "div",
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  _vm._l(_vm.mech.ActiveMounts, function(m, j) {
                    return _c("active-mount-block", {
                      key: "a_m_" + j,
                      attrs: {
                        mount: m,
                        mech: _vm.mech,
                        color: _vm.color,
                        rest: _vm.rest
                      }
                    })
                  }),
                  1
                ),
                _vm._v(" "),
                _c("v-divider", { staticClass: "my-2" }),
                _vm._v(" "),
                _c("active-systems-block", {
                  attrs: { mech: _vm.mech, color: _vm.color, rest: _vm.rest }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }