var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mt-0 mb-n1", attrs: { "no-gutters": "", align: "center" } },
    [
      _c("v-col", { attrs: { cols: "auto" } }, [
        _c(
          "span",
          {
            class: "heading h3 " + (_vm.interior ? "white--text" : "text--text")
          },
          [
            _vm.item.IsCascading
              ? _c(
                  "v-icon",
                  { staticClass: "mt-n1", attrs: { color: "warning" } },
                  [_vm._v("\n        mdi-link-variant-off\n      ")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.item.IsLimited
        ? _c(
            "v-col",
            { staticClass: "mx-2", attrs: { cols: "auto" } },
            [
              _c("cc-item-uses", {
                staticClass: "d-inline",
                attrs: { item: _vm.item, bonus: _vm.useBonus, color: _vm.color }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  "(" +
                    _vm._s(_vm.item.Uses) +
                    "/" +
                    _vm._s(_vm.item.MaxUses + _vm.useBonus) +
                    ") USES"
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.IsLoading && _vm.readonly
        ? _c(
            "v-col",
            { staticClass: "ma-1", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    dark: "",
                    color: _vm.item.Loaded ? "pilot" : "grey"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.item.Loaded = !_vm.item.Loaded
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v(
                      "mdi-progress-" +
                        _vm._s(_vm.item.Loaded ? "upload" : "download")
                    )
                  ]),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.item.Loaded ? "LOADED" : "NOT LOADED") +
                      "\n    "
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("v-col", { attrs: { cols: "auto" } }, [_vm._t("left")], 2),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "mx-2", attrs: { cols: "auto" } },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }