var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      class: _vm.$vuetify.breakpoint.smAndDown ? "mb-3" : "",
      staticStyle: { "min-width": "100%" },
      attrs: { dense: "" }
    },
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c("v-col", { attrs: { cols: "auto" } }, [
            _c(
              "div",
              {
                staticClass: "pa-2",
                staticStyle: {
                  position: "relative",
                  border: "1px solid var(--v-primary-base)",
                  "border-radius": "1px"
                }
              },
              [
                _c("talent-emblem", {
                  attrs: {
                    url: _vm.talent.Image,
                    name: _vm.talent.Name,
                    large: ""
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-col", [
        _c(
          "div",
          { staticClass: "sliced primary white--text pl-3 ml-n2 heading h2" },
          [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c("v-col", { attrs: { cols: "auto" } }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.talent.Name) + "\n          "
                  ),
                  _vm.rank
                    ? _c(
                        "span",
                        { staticClass: "flavor-text white--text" },
                        [
                          _c("cc-slashes"),
                          _vm._v(
                            "\n            RANK " +
                              _vm._s("I".repeat(_vm.rank)) +
                              "\n          "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  {
                    staticClass: "ml-auto mr-8 mt-n1",
                    attrs: { cols: "auto" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "fadeSelect",
                        attrs: { icon: "", color: "white" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("expand", "full")
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("mdi-arrow-expand")])],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            class: _vm.$vuetify.breakpoint.mdAndUp
              ? "box-outline px-2 py-1"
              : "px-2"
          },
          [
            _vm.showFull
              ? _c("div", { staticClass: "flavor-text mb-2" }, [
                  _vm._v(_vm._s(_vm.talent.Terse))
                ])
              : _c("div", { staticStyle: { height: "30px" } }),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "text-center",
                attrs: { align: "center", justify: "space-around", dense: "" }
              },
              _vm._l(3, function(n) {
                return _c(
                  "v-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.showFull ||
                          (!_vm.showFull &&
                            _vm.rank &&
                            parseInt(_vm.rank) >= n),
                        expression:
                          "showFull || (!showFull && rank && parseInt(rank) >= n)"
                      }
                    ],
                    key: "rank-btn-" + n
                  },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "open-on-hover": "",
                          bottom: "",
                          "offset-y": "",
                          "open-delay": "100"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          block:
                                            _vm.$vuetify.breakpoint.smAndDown,
                                          tile: "",
                                          color: _vm.rankColor(n)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      !_vm.rank ||
                                      (_vm.rank && parseInt(_vm.rank) >= n)
                                        ? _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [
                                              _vm._v(
                                                "\n                  cci-rank-" +
                                                  _vm._s(n) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : !_vm.rank ||
                                          (_vm.rank &&
                                            parseInt(_vm.rank) + 1 === n)
                                        ? _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-lock-open\n                "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-lock")]
                                          ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.talent.Rank(n).Name) +
                                          "\n              "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-toolbar",
                              {
                                attrs: {
                                  flat: "",
                                  dense: "",
                                  tile: "",
                                  color: "pilot"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "heading h3 white--text" },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          left: "",
                                          large: "",
                                          color: "white"
                                        }
                                      },
                                      [_vm._v("cci-rank-" + _vm._s(n))]
                                    ),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.talent.Rank(n).Name) +
                                        "\n                "
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              [
                                _c("talent-rank-contents", {
                                  attrs: {
                                    talentRank: _vm.talent.Rank(n),
                                    unlocked:
                                      !_vm.rank ||
                                      parseInt(_vm.rank) >=
                                        (_vm.selectable ? n - 1 : n)
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.selectable ? _c("v-divider") : _vm._e(),
                            _vm._v(" "),
                            _vm.selectable
                              ? _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _vm._v(" "),
                                    n === parseInt(_vm.rank) + 1
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              small: "",
                                              color: "secondary",
                                              disabled: !_vm.canAdd
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit("add")
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v("mdi-lock-open")]
                                            ),
                                            _vm._v(
                                              "\n                  Unlock " +
                                                _vm._s(
                                                  _vm.talent.Rank(n).Name
                                                ) +
                                                "\n                "
                                            )
                                          ],
                                          1
                                        )
                                      : n > parseInt(_vm.rank)
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: { small: "", disabled: "" }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v("mdi-lock")]
                                            ),
                                            _vm._v(
                                              "\n                  TALENT RANK LOCKED\n                "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm.selectable &&
                                        parseInt(_vm.rank) === n
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "fadeSelect",
                                            attrs: {
                                              color: "error",
                                              outlined: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit("remove")
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v("mdi-close")]
                                            ),
                                            _vm._v(
                                              "\n                  Remove\n                "
                                            )
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          { staticClass: "text-center" },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v("cci-rank-" + _vm._s(n))]
                                            ),
                                            _vm._v(
                                              "\n                  UNLOCKED\n                "
                                            )
                                          ],
                                          1
                                        )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.hideLocked
        ? _c(
            "v-col",
            { staticClass: "ma-0 pa-0 mt-n8 ml-n2", attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            content: (_vm.showAll ? "Hide" : "Show") + " All"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              attrs: { small: "", icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.showAll = !_vm.showAll
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("mdi-eye")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }