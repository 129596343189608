var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-sidebar-view",
    { attrs: { cols: "3" } },
    [
      _c(
        "div",
        { attrs: { slot: "sidebar" }, slot: "sidebar" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2 " }),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "transparent",
            staticStyle: { "min-width": "100%" },
            attrs: {
              dense: "",
              items: _vm.encounters,
              headers: _vm.headers,
              "group-by": "Campaign",
              search: _vm.search,
              "no-results-text": "No Encounters Found",
              "no-data-text": "No Saved Encounters",
              "disable-pagination": "",
              "hide-default-footer": "",
              "hide-default-header": "",
              "calculate-widths": ""
            },
            scopedSlots: _vm._u([
              {
                key: "group.header",
                fn: function(h) {
                  return [
                    _c(
                      "div",
                      { staticClass: "primary sliced" },
                      [
                        _c("v-icon", { attrs: { dark: "", left: "" } }, [
                          _vm._v("mdi-chevron-right")
                        ]),
                        _vm._v(" "),
                        h.group && h.group !== "null"
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "heading white--text text-uppercase"
                              },
                              [
                                Array.isArray(h.group)
                                  ? _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(h.group.join(", "))
                                      }
                                    })
                                  : _c("span", {
                                      domProps: { innerHTML: _vm._s(h.group) }
                                    })
                              ]
                            )
                          : _c("span", [_vm._v("NONE")])
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "item.Name",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "accent--text heading clickable ml-n2",
                        on: {
                          click: function($event) {
                            return _vm.$vuetify.goTo("#e_" + item.ID, {
                              duration: 150,
                              easing: "easeInOutQuad",
                              offset: 25,
                              container: ".v-dialog--active"
                            })
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.Name) + "\n        "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      !_vm.encounters.length
        ? _c("div", { staticClass: "subtle--text heading h2 text-center" }, [
            _vm._v("\n    // NO ENCOUNTERS AVAILABLE //\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.encounters, function(encounter, i) {
        return _c(
          "v-row",
          { key: encounter.ID + "_" + i, attrs: { id: "e_" + encounter.ID } },
          [
            _c(
              "v-col",
              { staticClass: "pl-0 mb-2" },
              [
                _c("encounter-panel", {
                  attrs: { encounter: encounter },
                  on: {
                    select: function($event) {
                      return _vm.$emit("select", $event)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }