var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-select", {
        attrs: {
          label: _vm.label,
          items: _vm.items,
          "item-text": "name",
          multiple: "",
          color: "accent",
          "item-color": "accent",
          dense: "",
          "hide-details": "",
          outlined: "",
          clearable: ""
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(ref) {
              var item = ref.item
              return [
                item.effects
                  ? _c(
                      "v-menu",
                      {
                        attrs: {
                          bottom: "",
                          "offset-y": "",
                          "open-on-hover": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      {
                                        attrs: {
                                          small: "",
                                          dark: _vm.dark,
                                          color: _vm.color
                                        }
                                      },
                                      on
                                    ),
                                    [_c("b", [_vm._v(_vm._s(item.name))])]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c(
                          "v-card",
                          [
                            _c("v-card-text", [
                              _c("p", {
                                directives: [
                                  {
                                    name: "html-safe",
                                    rawName: "v-html-safe",
                                    value: item.effects,
                                    expression: "item.effects"
                                  }
                                ],
                                staticClass: "body-text ma-0 pa-1"
                              })
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _c(
                      "v-chip",
                      {
                        attrs: { small: "", dark: _vm.dark, color: _vm.color }
                      },
                      [_c("b", [_vm._v(_vm._s(item.name))])]
                    )
              ]
            }
          }
        ]),
        model: {
          value: _vm.arr,
          callback: function($$v) {
            _vm.arr = $$v
          },
          expression: "arr"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }