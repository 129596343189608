var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-selector-table",
        {
          attrs: {
            items: _vm.availableFeatures,
            headers: _vm.headers,
            "no-filter": "",
            "item-type-fallback": "NpcFeature"
          },
          on: {
            equip: function($event) {
              return _vm.$emit("equip", $event)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "mt-2",
              attrs: { slot: "extra-item" },
              slot: "extra-item"
            },
            [
              _c(
                "v-switch",
                {
                  staticClass: "mr-3",
                  attrs: {
                    dense: "",
                    inset: "",
                    "hide-details": "",
                    color: "warning"
                  },
                  model: {
                    value: _vm.showAll,
                    callback: function($$v) {
                      _vm.showAll = $$v
                    },
                    expression: "showAll"
                  }
                },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        slot: "label",
                        simple: "",
                        inline: "",
                        content: _vm.showAll
                          ? "Showing all features"
                          : "Showing available features"
                      },
                      slot: "label"
                    },
                    [
                      _c("v-icon", {
                        staticClass: "ml-n2",
                        attrs: { color: _vm.showAll ? "warning" : "success" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.showAll ? "mdi-lock-open" : "mdi-lock"
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-switch",
                {
                  staticClass: "mr-3 mt-n1",
                  attrs: {
                    dense: "",
                    inset: "",
                    "hide-details": "",
                    color: "warning"
                  },
                  model: {
                    value: _vm.showDupe,
                    callback: function($$v) {
                      _vm.showDupe = $$v
                    },
                    expression: "showDupe"
                  }
                },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        slot: "label",
                        simple: "",
                        inline: "",
                        content: _vm.showDupe
                          ? "Showing installed features"
                          : "Hiding installed features"
                      },
                      slot: "label"
                    },
                    [
                      _c("v-icon", {
                        staticClass: "ml-n2",
                        attrs: { color: _vm.showDupe ? "warning" : "success" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.showDupe
                              ? "mdi-hexagon-multiple-outline"
                              : "mdi-hexagon-multiple"
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }