var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-base",
    {
      attrs: { item: _vm.item, active: _vm.active, readonly: _vm.readonly },
      on: {
        "remove-feature": function($event) {
          return _vm.$emit("remove-feature", $event)
        },
        recalc: function($event) {
          return _vm.$emit("recalc")
        }
      }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "heading h3 text-center",
          attrs: { dense: "", "no-gutters": "" }
        },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c(
              "span",
              { staticClass: "heading h3" },
              [
                _c("cc-range-element", {
                  attrs: { small: "", range: _vm.item.Feature.Range }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _vm.item.Tier
              ? _c(
                  "span",
                  { staticClass: "heading h3" },
                  [
                    _c("cc-damage-element", {
                      attrs: {
                        small: "",
                        damage: _vm.item.Feature.Damage(_vm.item.Tier)
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c("cc-damage-element", {
                      attrs: { small: "", damage: _vm.item.Feature.Damage(1) }
                    }),
                    _vm._v("\n        /\n        "),
                    _c("cc-damage-element", {
                      attrs: { small: "", damage: _vm.item.Feature.Damage(2) }
                    }),
                    _vm._v("\n        /\n        "),
                    _c("cc-damage-element", {
                      attrs: { small: "", damage: _vm.item.Feature.Damage(3) }
                    })
                  ],
                  1
                )
          ]),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("v-col", [
            _vm.item.Tier
              ? _c(
                  "span",
                  { staticClass: "heading h3" },
                  [
                    _c("v-icon", [_vm._v("cci-reticle")]),
                    _vm._v(" "),
                    _vm.item.Feature.AttackBonus(_vm.item.Tier) > 0
                      ? _c("span", [_vm._v("+")])
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.item.Feature.AttackBonus(_vm.item.Tier)) +
                        "\n        Attack Bonus\n      "
                    )
                  ],
                  1
                )
              : _c(
                  "span",
                  [
                    _c("v-icon", [_vm._v("cci-reticle")]),
                    _vm._v(" "),
                    _vm.item.Feature.AttackBonus(1) > 0
                      ? _c("span", [_vm._v("+")])
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.item.Feature.AttackBonus(1)) +
                        " /\n        "
                    ),
                    _vm.item.Feature.AttackBonus(2) > 0
                      ? _c("span", [_vm._v("+")])
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.item.Feature.AttackBonus(2)) +
                        " /\n        "
                    ),
                    _vm.item.Feature.AttackBonus(3) > 0
                      ? _c("span", [_vm._v("+")])
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.item.Feature.AttackBonus(3)) +
                        "\n        Attack Bonus\n      "
                    )
                  ],
                  1
                )
          ]),
          _vm._v(" "),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("v-col", [
            _vm.item.Tier
              ? _c("span", { staticClass: "heading h3" }, [
                  _vm.item.Feature.Accuracy(_vm.item.Tier) > 0
                    ? _c(
                        "div",
                        [
                          _c("v-icon", [_vm._v("cci-accuracy")]),
                          _vm._v(
                            "\n          +" +
                              _vm._s(_vm.item.Feature.Accuracy(_vm.item.Tier)) +
                              "\n          Accuracy\n        "
                          )
                        ],
                        1
                      )
                    : _vm.item.Feature.Accuracy(_vm.item.Tier) < 0
                    ? _c(
                        "div",
                        [
                          _c("v-icon", [_vm._v("cci-difficulty")]),
                          _vm._v(
                            "\n          +" +
                              _vm._s(
                                Math.abs(
                                  _vm.item.Feature.Accuracy(_vm.item.Tier)
                                )
                              ) +
                              "\n          Difficulty\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _c("span", [
                  _vm.item.Feature.Accuracy(1) > 0
                    ? _c(
                        "div",
                        [
                          _c("v-icon", [_vm._v("cci-accuracy")]),
                          _vm._v(
                            "\n          +" +
                              _vm._s(_vm.item.Feature.Accuracy(1)) +
                              " / +" +
                              _vm._s(_vm.item.Feature.Accuracy(2)) +
                              " / +" +
                              _vm._s(_vm.item.Feature.Accuracy(3)) +
                              "\n          Accuracy\n        "
                          )
                        ],
                        1
                      )
                    : _vm.item.Feature.Accuracy(1) < 0
                    ? _c(
                        "div",
                        [
                          _c("v-icon", [_vm._v("cci-difficulty")]),
                          _vm._v(
                            "\n          +" +
                              _vm._s(Math.abs(_vm.item.Feature.Accuracy(1))) +
                              " / +" +
                              _vm._s(Math.abs(_vm.item.Feature.Accuracy(2))) +
                              " /\n          +" +
                              _vm._s(Math.abs(_vm.item.Feature.Accuracy(3))) +
                              "\n          Difficulty\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
          ]),
          _vm._v(" "),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("v-col", [
            _c(
              "span",
              { staticClass: "heading h3", staticStyle: { float: "right" } },
              [_vm._v(_vm._s(_vm.item.Feature.WeaponType))]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.Feature.OnHit
        ? _c("div", [
            _c("span", { staticClass: "overline" }, [_vm._v("ON HIT")]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.item.Feature.OnHit,
                  expression: "item.Feature.OnHit"
                }
              ],
              staticClass: "body-1 mb-0",
              attrs: { else: "" }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.Feature.Effect
        ? _c("div", [
            _c("span", { staticClass: "overline" }, [_vm._v("EFFECT")]),
            _vm._v(" "),
            _vm.item.Tier
              ? _c("p", {
                  directives: [
                    {
                      name: "html-safe",
                      rawName: "v-html-safe",
                      value: _vm.item.Feature.EffectByTier(_vm.item.Tier),
                      expression: "item.Feature.EffectByTier(item.Tier)"
                    }
                  ],
                  staticClass: "body-1 mb-0"
                })
              : _c("p", {
                  directives: [
                    {
                      name: "html-safe",
                      rawName: "v-html-safe",
                      value: _vm.item.Feature.Effect,
                      expression: "item.Feature.Effect"
                    }
                  ],
                  staticClass: "body-1 mb-0"
                })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.Feature.Tags
        ? _c("cc-tags", { attrs: { tags: _vm.item.Feature.Tags, small: "" } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }