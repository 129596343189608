























































import Vue from 'vue'
import ActiveSystemCard from './_ActiveSystemCard.vue'
import ModEquippedCard from '../mech_loadout/components/system/_ModEquippedCard.vue'

export default Vue.extend({
  name: 'systems-block',
  components: { ActiveSystemCard, ModEquippedCard },
  props: {
    mech: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
    },
    rest: {
      type: Boolean,
    },
  },
  computed: {
    moddedWeapons() {
      return this.mech.ActiveLoadout.Weapons.filter(x => x.Mod)
    },
  },
})
