























import { Vue, Component, Prop} from 'vue-property-decorator'
import { Mech, Pilot, Statblock } from '@/class'
import CCSoloDialog from '@/ui/components/CCSoloDialog.vue'

@Component({ name: 'statblock-dialog' })
export default class StatblockDialog extends Vue {
  @Prop({type: Object, required: true})
  readonly pilot: Pilot

  mechSelect = ""
  buildSummary = false
  discordEmoji = false

  get statblock(): string {
    const mech = this.mechSelect ? this.pilot.Mechs.find(x => x.ID === this.mechSelect) : null
    if (this.buildSummary) {
      return Statblock.GenerateBuildSummary(this.pilot, mech, this.discordEmoji)
    }
    else return Statblock.Generate(this.pilot, mech, this.discordEmoji)
  }

  $refs!: {
    dialog: CCSoloDialog
  }

  show() {
    this.$refs.dialog.show()
  }

  hide() {
    this.$refs.dialog.hide()
  }
}
