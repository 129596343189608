var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-body elevation-10" },
    [
      _c("div", { attrs: { id: "cap" } }),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "div",
            { staticClass: "d-inline" },
            [
              _c(
                "cc-nav-item",
                {
                  attrs: {
                    tile: "",
                    depressed: "",
                    selected: _vm.selected === 0
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("set-page", 0)
                    }
                  }
                },
                [_vm._v("\n      MECH CONFIGURATION\n    ")]
              ),
              _vm._v(" "),
              _c(
                "cc-tooltip",
                {
                  attrs: {
                    simple: "",
                    inline: "",
                    content: "Feature In Development"
                  }
                },
                [
                  _c(
                    "cc-nav-item",
                    {
                      attrs: { disabled: "", selected: _vm.selected === 1 },
                      on: {
                        click: function($event) {
                          return _vm.$emit("set-page", 1)
                        }
                      }
                    },
                    [_vm._v("\n        COMBAT ANALYTICS\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4 unskew",
                  attrs: {
                    icon: "",
                    fab: "",
                    "x-small": "",
                    outlined: "",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.toPilotSheet()
                    }
                  }
                },
                [_c("v-icon", { attrs: { large: "" } }, [_vm._v("cci-pilot")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4 unskew",
                  attrs: {
                    icon: "",
                    fab: "",
                    "x-small": "",
                    outlined: "",
                    dark: "",
                    to: "/active/" + _vm.pilot.ID
                  }
                },
                [
                  _c("v-icon", { attrs: { large: "", color: "white" } }, [
                    _vm._v("cci-activate")
                  ])
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-menu",
            {
              attrs: { "open-on-hover": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "unskew pl-2 pr-0",
                            staticStyle: { "z-index": "9" },
                            attrs: { light: "", icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v("mdi-book-open-page-variant")
                          ]),
                          _vm._v(" "),
                          _c("v-icon", [_vm._v("arrow_drop_up")])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                { staticClass: "heading h3", attrs: { dense: "" } },
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("set-page", 0)
                        }
                      }
                    },
                    [_vm._v("\n        MECH CONFIGURATION\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      attrs: { disabled: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("set-page", 1)
                        }
                      }
                    },
                    [_vm._v("\n        COMBAT ANALYTICS\n      ")]
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "divider" } }),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var menu = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "unskew ml-2",
                        attrs: { icon: "", dark: "" }
                      },
                      menu
                    ),
                    [_c("v-icon", [_vm._v("mdi-settings")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { "two-line": "", subheader: "" } },
            [
              _c(
                "v-subheader",
                { staticClass: "heading h2 white--text primary py-0 px-2" },
                [_vm._v("Mech Options")]
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.printDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [_c("v-icon", [_vm._v("mdi-printer")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Print")]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _vm._v("Print a tabletop-ready mech sheet")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.statblockDialog.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [_c("v-icon", [_vm._v("mdi-file-document-box")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [_vm._v("Generate Statblock")]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          "\n            Get a plaintext representation of this mech configuration\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("delete")
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [
                      _c("v-icon", { attrs: { color: "error" } }, [
                        _vm._v("mdi-delete")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { staticClass: "error--text" }, [
                        _vm._v("Delete Mech")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "error--text" },
                        [
                          _vm._v(
                            "\n            Remove mech from the Hangar\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var menu = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "unskew ml-2",
                        attrs: { icon: "", dark: "" }
                      },
                      menu
                    ),
                    [_c("v-icon", [_vm._v("mdi-view-grid-plus")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { subheader: "" } },
            [
              _c(
                "v-subheader",
                { staticClass: "heading h2 white--text primary py-0 px-4" },
                [_vm._v("Layout Options")]
              ),
              _vm._v(" "),
              _c(
                "v-list-item-group",
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "ma-0 mr-2 mt-3" },
                        [_c("v-icon", [_vm._v("mdi-view-array")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Tabbed View")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { disabled: "" } },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "ma-0 mr-2 mt-3" },
                        [_c("v-icon", [_vm._v("mdi-playlist-edit")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                simple: "",
                                content: "Feature In Development"
                              }
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v("Manage Custom Views")
                              ]),
                              _vm._v(" "),
                              _c("v-list-item-subtitle", [
                                _vm._v("Feature In Development")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("print-dialog", {
        ref: "printDialog",
        staticClass: "unskew",
        attrs: { pilot: _vm.pilot }
      }),
      _vm._v(" "),
      _c("statblock-dialog", {
        ref: "statblockDialog",
        staticClass: "unskew",
        attrs: { pilot: _vm.pilot }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }