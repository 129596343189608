import { render, staticRenderFns } from "./_EquipmentOptions.vue?vue&type=template&id=6da3dda2&"
import script from "./_EquipmentOptions.vue?vue&type=script&lang=ts&"
export * from "./_EquipmentOptions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBtn,VDivider,VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VMenu})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6da3dda2')) {
      api.createRecord('6da3dda2', component.options)
    } else {
      api.reload('6da3dda2', component.options)
    }
    module.hot.accept("./_EquipmentOptions.vue?vue&type=template&id=6da3dda2&", function () {
      api.rerender('6da3dda2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/mech_loadout/components/_EquipmentOptions.vue"
export default component.exports