var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-1", attrs: { flat: "", tile: "", width: "100%" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "primary" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("talent-emblem", {
                    attrs: {
                      url: _vm.talent.Image,
                      name: _vm.talent.Name,
                      white: ""
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col", [
                _c("div", { staticClass: "white--text heading h1 ml-2" }, [
                  _vm._v(_vm._s(_vm.talent.Name))
                ])
              ]),
              _vm._v(" "),
              _vm.rank
                ? _c(
                    "v-col",
                    { staticClass: "ml-auto mr-3", attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { attrs: { size: "45", color: "white" } }, [
                        _vm._v("cci-rank-" + _vm._s(_vm.rank))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto", "align-self": "center" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "fadeSelect mr-n2",
                      attrs: { color: "white" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("expand", "terse")
                        }
                      }
                    },
                    [_vm._v("\n          mdi-arrow-collapse\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFull,
              expression: "showFull"
            }
          ],
          staticClass: "mb-0 pb-0"
        },
        [
          _c("div", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.talent.Description,
                expression: "talent.Description"
              }
            ],
            staticClass: "flavor-text"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        _vm._l(3, function(n) {
          return _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.showFull ||
                    (!_vm.showFull && _vm.rank && parseInt(_vm.rank) >= n),
                  expression:
                    "showFull || (!showFull && rank && parseInt(rank) >= n)"
                }
              ],
              key: "rank-layout-" + n,
              class:
                _vm.rank && parseInt(_vm.rank) < n
                  ? "text--disabled"
                  : "stark--text",
              attrs: { dense: "" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("cci-rank-" + _vm._s(n))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "heading h3",
                      attrs: { "no-gutters": "", align: "center" }
                    },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.talent.Rank(n).Name) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [_c("v-divider", { staticClass: "mx-2" })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "ml-auto", attrs: { cols: "auto" } },
                        [
                          _vm.selectable && parseInt(_vm.rank) > n
                            ? _c(
                                "v-icon",
                                { attrs: { color: "primary", left: "" } },
                                [
                                  _vm._v(
                                    "\n              mdi-lock-open\n            "
                                  )
                                ]
                              )
                            : _vm.selectable && parseInt(_vm.rank) + 1 === n
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    color: "secondary",
                                    disabled: !_vm.canAdd
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("add")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", small: "" } },
                                    [_vm._v("mdi-lock-open")]
                                  ),
                                  _vm._v("\n              Unlock\n            ")
                                ],
                                1
                              )
                            : _vm.selectable && parseInt(_vm.rank) === n
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "fadeSelect",
                                  attrs: {
                                    small: "",
                                    color: "error",
                                    outlined: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("remove")
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-close")
                                  ]),
                                  _vm._v("\n              Remove\n            ")
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("talent-rank-contents", {
                    attrs: {
                      talentRank: _vm.talent.Rank(n),
                      unlocked:
                        !_vm.rank ||
                        parseInt(_vm.rank) >= (_vm.selectable ? n - 1 : n)
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.hideLocked
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        content: (_vm.showAll ? "Hide" : "Show") + " All"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect",
                          attrs: { small: "", icon: "" },
                          on: {
                            click: function($event) {
                              _vm.showAll = !_vm.showAll
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("mdi-eye")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }