var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticStyle: {
        "padding-bottom": "4px",
        "border-top": "2px solid var(--v-primary-base)"
      },
      attrs: { fixed: "" }
    },
    [
      _c(
        "v-row",
        {
          attrs: { "no-gutters": "", justify: "space-between", align: "center" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "80vw" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mr-5",
                                staticStyle: {
                                  "border-color": "var(--v-warning-base)"
                                },
                                attrs: { outlined: "", small: "" }
                              },
                              on
                            ),
                            [_vm._v("\n            END COMBAT\n          ")]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.ecDialog,
                    callback: function($$v) {
                      _vm.ecDialog = $$v
                    },
                    expression: "ecDialog"
                  }
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            dense: "",
                            dark: "",
                            flat: "",
                            tile: "",
                            color: "warning darken-3 heading h2"
                          }
                        },
                        [_vm._v("\n            END COMBAT\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "flavor-text stark--text mt-1 mb-0 mx-6"
                            },
                            [
                              _vm._v("\n              >//[\n              "),
                              _c("span", { staticClass: "accent--text" }, [
                                _vm._v("COMP/CON")
                              ]),
                              _vm._v("\n              :\n              "),
                              _c("span", { staticClass: "stark-text--text" }, [
                                _vm._v("Confirmation Required")
                              ]),
                              _vm._v(
                                "\n              ] Pilot, proceeding will disengage combat mode and enable rest and repair protocols.\n              This cannot be undone. Continue?\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-row", {
                            staticClass: "mt-n2",
                            attrs: { justify: "center", "no-gutters": "" }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-2",
                              attrs: { justify: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-large": "",
                                        tile: "",
                                        color: "warning darken-3"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.ecDialog = false
                                          _vm.state.StartRest()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                   Start Rest\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _vm.state.InTurn
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": "",
                                "offset-y": ""
                              },
                              model: {
                                value: _vm.turnConfirm,
                                callback: function($$v) {
                                  _vm.turnConfirm = $$v
                                },
                                expression: "turnConfirm"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    slot: "activator",
                                    small: "",
                                    color: "secondary",
                                    dark: "",
                                    elevation: "0"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.endTurnConfirm.length
                                        ? (_vm.turnConfirm = true)
                                        : _vm.stageEndTurn()
                                    }
                                  },
                                  slot: "activator"
                                },
                                [
                                  _vm._v(
                                    "\n              END TURN\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("cc-confirmation", {
                                attrs: {
                                  "no-cc": "",
                                  content: _vm.endTurnConfirm
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.stageEndTurn()
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                small: "",
                                color: "secondary"
                              },
                              on: { click: _vm.undoEndTurn }
                            },
                            [_vm._v("\n            UNDO END TURN\n          ")]
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": "",
                            "offset-y": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "white--text",
                                        attrs: {
                                          small: "",
                                          color: "accent",
                                          elevation: "0",
                                          disabled: _vm.state.InTurn
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                START ROUND " +
                                          _vm._s(_vm.state.Round + 1) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.roundConfirm,
                            callback: function($$v) {
                              _vm.roundConfirm = $$v
                            },
                            expression: "roundConfirm"
                          }
                        },
                        [
                          _vm._v(" "),
                          _c("cc-confirmation", {
                            attrs: {
                              "no-cc": "",
                              content: _vm.nextRoundConfirm
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.stageNextRound()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { lg: "auto", cols: "auto" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "text-center",
                  attrs: { "no-gutters": "", justify: "space-around" }
                },
                [
                  !_vm.mech.Pilot.IsDownAndOut
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                inline: "",
                                content: "Protocol Actions",
                                delayed: ""
                              }
                            },
                            [
                              _c(
                                "action-menu-button",
                                {
                                  key:
                                    "protocol_btn_" +
                                    _vm.state.IsProtocolAvailable,
                                  attrs: {
                                    actions: _vm.state.ActionsByType(
                                      "Protocol"
                                    ),
                                    mech: _vm.pilot.ActiveMech,
                                    available: _vm.state.IsProtocolAvailable,
                                    color: _vm.state.IsProtocolAvailable
                                      ? "action--protocol"
                                      : "grey darken-1",
                                    title: "PROTOCOLS"
                                  },
                                  on: {
                                    "open-menu": function($event) {
                                      return _vm.openMenu(0)
                                    },
                                    "open-dialog": function($event) {
                                      return _vm.openDialog($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        slot: "icon",
                                        color: "white",
                                        size: "40"
                                      },
                                      slot: "icon"
                                    },
                                    [_vm._v("cci-protocol")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.lgAndUp &&
                  !_vm.mech.Pilot.IsDownAndOut
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-divider", {
                            staticClass: "mx-3",
                            attrs: { vertical: "" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.mech.Pilot.IsDownAndOut
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                inline: "",
                                content: "Movement",
                                delayed: ""
                              }
                            },
                            [
                              _c("move-menu-button", {
                                attrs: { mech: _vm.pilot.ActiveMech },
                                on: {
                                  "open-dialog": function($event) {
                                    return _vm.openDialog($event)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.mech.Pilot.IsDownAndOut
                    ? _c(
                        "v-col",
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                inline: "",
                                content: "Full Actions",
                                delayed: ""
                              }
                            },
                            [
                              _c(
                                "action-menu-button",
                                {
                                  attrs: {
                                    actions: _vm.state.ActionsByType("Full"),
                                    mech: _vm.pilot.ActiveMech,
                                    available: _vm.state.Actions > 1,
                                    color:
                                      _vm.state.Actions > 1
                                        ? "action--full"
                                        : "grey darken-1",
                                    title: "FULL ACTIONS"
                                  },
                                  on: {
                                    "open-menu": function($event) {
                                      return _vm.openMenu(1)
                                    },
                                    "open-dialog": function($event) {
                                      return _vm.openDialog($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        slot: "icon",
                                        color: "white",
                                        size: "30"
                                      },
                                      slot: "icon"
                                    },
                                    [_vm._v("mdi-hexagon-slice-6")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.mech.Pilot.IsDownAndOut
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                inline: "",
                                content: "Quick Actions",
                                delayed: ""
                              }
                            },
                            [
                              _c(
                                "action-menu-button",
                                {
                                  attrs: {
                                    actions: _vm.state.ActionsByType("Quick"),
                                    mech: _vm.pilot.ActiveMech,
                                    color:
                                      _vm.state.Actions > 0
                                        ? "action--quick"
                                        : "grey darken-1",
                                    available: _vm.state.Actions > 0,
                                    title: "QUICK ACTIONS"
                                  },
                                  on: {
                                    "open-menu": function($event) {
                                      return _vm.openMenu(2)
                                    },
                                    "open-dialog": function($event) {
                                      return _vm.openDialog($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        slot: "icon",
                                        color: "white",
                                        size: "30"
                                      },
                                      slot: "icon"
                                    },
                                    [_vm._v("mdi-hexagon-slice-3")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.mech.Pilot.IsDownAndOut
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                inline: "",
                                content: "Reactions",
                                delayed: ""
                              }
                            },
                            [
                              _c(
                                "action-menu-button",
                                {
                                  attrs: {
                                    actions: _vm.state.ActionsByType(
                                      "Reaction"
                                    ),
                                    mech: _vm.pilot.ActiveMech,
                                    color: "action--reaction",
                                    available: !_vm.state.IsBraceCooldown,
                                    title: "REACTIONS"
                                  },
                                  on: {
                                    "open-menu": function($event) {
                                      return _vm.openMenu(3)
                                    },
                                    "open-dialog": function($event) {
                                      return _vm.openDialog($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        slot: "icon",
                                        color: "white",
                                        size: "35"
                                      },
                                      slot: "icon"
                                    },
                                    [_vm._v("cci-reaction")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.mech.Pilot.IsDownAndOut
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                inline: "",
                                content: "Free Actions",
                                delayed: ""
                              }
                            },
                            [
                              _c(
                                "action-menu-button",
                                {
                                  attrs: {
                                    actions: _vm.state.ActionsByType("Free"),
                                    mech: _vm.pilot.ActiveMech,
                                    available: !_vm.state.IsBraceCooldown,
                                    color: "action--free",
                                    title: "FREE ACTIONS"
                                  },
                                  on: {
                                    "open-menu": function($event) {
                                      return _vm.openMenu(4)
                                    },
                                    "open-dialog": function($event) {
                                      return _vm.openDialog($event)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        slot: "icon",
                                        color: "white",
                                        size: "35"
                                      },
                                      slot: "icon"
                                    },
                                    [_vm._v("cci-free-action")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$vuetify.breakpoint.lgAndUp &&
                  !_vm.mech.Pilot.IsDownAndOut
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-divider", {
                            staticClass: "mx-3",
                            attrs: { vertical: "" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            inline: "",
                            content: "Combat Log",
                            delayed: ""
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-1",
                              attrs: {
                                small: "",
                                fab: _vm.$vuetify.breakpoint.lgAndUp,
                                elevation: "0",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openMenu(5)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "white", size: "25" } },
                                [_vm._v("mdi-notebook")]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.mdAndDown
                                ? _c("span", { staticClass: "pl-2" }, [
                                    _vm._v("COMBAT LOG")
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            inline: "",
                            content: "Other",
                            delayed: "",
                            cols: "auto"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-1",
                              attrs: {
                                small: "",
                                fab: _vm.$vuetify.breakpoint.lgAndUp,
                                elevation: "0",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openMenu(6)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "white", size: "25" } },
                                [_vm._v("mdi-dots-vertical")]
                              ),
                              _vm._v(" "),
                              _vm.$vuetify.breakpoint.mdAndDown
                                ? _c("span", { staticClass: "pl-2" }, [
                                    _vm._v("OTHER")
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.state.AllActions, function(a, i) {
        return _c("cc-combat-dialog", {
          key: "fa_" + i + "_" + a.ID,
          ref: "dialog_" + a.ID,
          refInFor: true,
          attrs: { action: a, mech: _vm.pilot.ActiveMech }
        })
      }),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "actionMenu",
          attrs: {
            "no-confirm": "",
            title: "Actions",
            large: "",
            "no-title-clip": ""
          }
        },
        [_c("action-menu", { attrs: { tab: _vm.menuTab } })],
        1
      ),
      _vm._v(" "),
      _c("burn-dialog", {
        ref: "burnDialog",
        attrs: { mech: _vm.mech },
        on: {
          complete: function($event) {
            return _vm.endTurn($event)
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }