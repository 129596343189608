var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card-base",
    { attrs: { item: _vm.item, small: _vm.small, hover: _vm.hover } },
    [
      _vm.item.Effect && _vm.item.Effect.length
        ? _c("p", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.item.Effect,
                expression: "item.Effect"
              }
            ],
            staticClass: "card-effect pa-2 mb-1",
            staticStyle: { "font-size": "15px" },
            attrs: { slot: "top" },
            slot: "top"
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { attrs: { slot: "overline" }, slot: "overline" }, [
        _vm._v(_vm._s(_vm.item.Type))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }