
























































































































































import _ from 'lodash'
import Vue from 'vue'
import PilotCard from './components/PilotCard.vue'
import PilotListItem from './components/PilotListItem.vue'
import AddPilot from './components/AddPilot.vue'
import { getModule } from 'vuex-module-decorators'
import { UserStore, PilotManagementStore } from '@/store'
import { Pilot } from '@/class'
import { UserProfile } from '@/user'
import draggable from 'vuedraggable'
import { teamName } from '@/io/Generators'

export default Vue.extend({
  name: 'roster-view',
  components: { AddPilot, PilotListItem, PilotCard, draggable },
  data: () => ({
    sortParams: null,
    drag: false,
    newGroupMenu: false,
    tempGroups: [],
    shown: [],
    newGroupName: '',
    preventDnd: true,
  }),
  computed: {
    pilotStore() {
      return getModule(PilotManagementStore, this.$store)
    },
    pilotCardType(): string {
      switch (this.profile.GetView('roster')) {
        case 'cards':
        case 'small-cards':
          return 'pilot-card'
        case 'list':
        default:
          return 'pilot-list-item'
      }
    },
    profile(): UserProfile {
      const store = getModule(UserStore, this.$store)
      return store.UserProfile
    },
    groups() {
      return _.uniq(this.pilots.map(x => x.Group).concat(this.tempGroups))
    },
    pilots() {
      return this.pilotStore.Pilots
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    isTouch() {
      if ('ontouchstart' in document.documentElement) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.shown = [...this.groups]
    this.preventDnd = this.isTouch
  },
  methods: {
    toggleShown(group: string) {
      const idx = this.shown.indexOf(group)
      if (idx === -1) this.shown.push(group)
      else this.shown.splice(idx, 1)
    },
    showAll() {
      Vue.set(this, 'shown', [...this.groups])
    },
    hideAll() {
      Vue.set(this, 'shown', [])
    },
    onSort(sortParams: any[]) {
      this.sortParams = sortParams
    },
    addNewGroup() {
      this.tempGroups.push(this.newGroupName)
      this.shown.push(this.newGroupName)
      this.newGroupName = ''
      this.newGroupMenu = false
    },
    moved(e, g) {
      if (e.moved && e.moved.element) {
        const p = e.moved.element as Pilot
        p.SortIndex = e.moved.newIndex
      }
      if (e.added && e.added.element) {
        const p = e.added.element as Pilot
        p.SortIndex = e.added.newIndex
        p.Group = g
        // if (this.tempGroups.includes(g))
      }
    },
    deleteGroup(g) {
      this.pilots.forEach((p: Pilot) => {
        if (p.Group === g) p.Group = ''
      })
      const idx = this.tempGroups.indexOf(g)
      if (idx === -1) return
      this.tempGroups.splice(idx, 1)
    },
    setGroupName(oldName, newName) {
      this.tempGroups.push(newName)
      this.pilots.forEach((p: Pilot) => {
        if (p.Group === oldName) p.Group = newName
      })
      const idx = this.tempGroups.indexOf(oldName)
      if (idx === -1) return
      this.tempGroups.splice(idx, 1)
    },
    randomName() {
      this.newGroupName = teamName()
    },
  },
})
