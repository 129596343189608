import { render, staticRenderFns } from "./CCSkillSelector.vue?vue&type=template&id=1a06aace&scoped=true&"
import script from "./CCSkillSelector.vue?vue&type=script&lang=ts&"
export * from "./CCSkillSelector.vue?vue&type=script&lang=ts&"
import style0 from "./CCSkillSelector.vue?vue&type=style&index=0&id=1a06aace&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a06aace",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VChip,VCol,VDivider,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a06aace')) {
      api.createRecord('1a06aace', component.options)
    } else {
      api.reload('1a06aace', component.options)
    }
    module.hot.accept("./CCSkillSelector.vue?vue&type=template&id=1a06aace&scoped=true&", function () {
      api.rerender('1a06aace', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/selectors/CCSkillSelector.vue"
export default component.exports