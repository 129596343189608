var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-2" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-col",
            [_c("action-detail-expander", { attrs: { action: _vm.action } })],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    tile: "",
                    dark: "",
                    block: "",
                    disabled: _vm.actionFree,
                    color:
                      _vm.action.Color +
                      " " +
                      (_vm.actionCost ? "lighten-1" : "")
                  },
                  on: {
                    click: function($event) {
                      _vm.actionCost = !_vm.actionCost
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.action.Icon))
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.action.Name) + "\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _vm.action.Activation !== "Free"
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        tile: "",
                        dark: "",
                        block: "",
                        disabled: _vm.actionCost,
                        color:
                          "action--free " + (_vm.actionFree ? "lighten-1" : "")
                      },
                      on: {
                        click: function($event) {
                          _vm.actionFree = !_vm.actionFree
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "", small: "" } }, [
                        _vm._v("cci-free-action")
                      ]),
                      _vm._v("\n        Free Action\n        "),
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            inline: "",
                            content:
                              "Special rules or equipment may allow you to " +
                              _vm.action.Name +
                              " as a Free Action. Using this button will commit the action without spending a " +
                              _vm.action.Activation +
                              " Action this turn"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "fadeSelect",
                              attrs: { right: "", small: "" }
                            },
                            [_vm._v("mdi-information-outline")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-x-reverse-transition",
        [
          _vm.actionFree || _vm.actionCost
            ? _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-n5", attrs: { cols: "12", md: "auto" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-center mb-n3",
                          attrs: { dense: "", justify: "start", align: "start" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-auto px-12 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "overline pl-4 mr-n4" },
                                [_vm._v("Contested SYS")]
                              ),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "hide-input-spinners mt-n1 ml-10",
                                staticStyle: { width: "60px" },
                                attrs: {
                                  type: "number",
                                  color: "accent",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  change: function($event) {
                                    _vm.sys = parseInt($event)
                                  }
                                },
                                model: {
                                  value: _vm.sys,
                                  callback: function($$v) {
                                    _vm.sys = $$v
                                  },
                                  expression: "sys"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "", justify: "end" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "ml-auto px-12 mr-n10 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", { staticClass: "overline pl-1" }, [
                                _vm._v("Accuracy")
                              ]),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "hide-input-spinners",
                                staticStyle: { width: "115px" },
                                attrs: {
                                  type: "number",
                                  "append-outer-icon":
                                    "mdi-plus-circle-outline",
                                  "append-icon": "cci-accuracy",
                                  "prepend-icon": "mdi-minus-circle-outline",
                                  color: "accent",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  "click:append-outer": function($event) {
                                    _vm.accuracy += 1
                                  },
                                  "click:prepend": function($event) {
                                    _vm.accuracy -= 1
                                  },
                                  change: function($event) {
                                    _vm.accuracy = parseInt($event)
                                  }
                                },
                                model: {
                                  value: _vm.accuracy,
                                  callback: function($$v) {
                                    _vm.accuracy = $$v
                                  },
                                  expression: "accuracy"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-12 mr-n10 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", { staticClass: "overline pl-1" }, [
                                _vm._v("Difficulty")
                              ]),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "hide-input-spinners",
                                staticStyle: { width: "115px" },
                                attrs: {
                                  type: "number",
                                  "append-outer-icon":
                                    "mdi-plus-circle-outline",
                                  "append-icon": "cci-difficulty",
                                  "prepend-icon": "mdi-minus-circle-outline",
                                  color: "accent",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  "click:append-outer": function($event) {
                                    _vm.difficulty += 1
                                  },
                                  "click:prepend": function($event) {
                                    _vm.difficulty -= 1
                                  },
                                  change: function($event) {
                                    _vm.difficulty = parseInt($event)
                                  }
                                },
                                model: {
                                  value: _vm.difficulty,
                                  callback: function($$v) {
                                    _vm.difficulty = $$v
                                  },
                                  expression: "difficulty"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-12 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", { staticClass: "overline pl-1" }, [
                                _vm._v("SYS Roll")
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "ml-n2" },
                                    [
                                      _c("cc-dice-menu", {
                                        attrs: {
                                          preset: "1d20+" + _vm.mech.Sys,
                                          "preset-accuracy":
                                            _vm.accuracy - _vm.difficulty,
                                          title: "SKILL CHECK"
                                        },
                                        on: {
                                          commit: function($event) {
                                            return _vm.registerSysRoll(
                                              $event.total
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        key: "input_" + _vm.sysRoll,
                                        staticClass:
                                          "hide-input-spinners ml-n3",
                                        staticStyle: {
                                          "max-width": "60px",
                                          "margin-top": "-0.5px"
                                        },
                                        attrs: {
                                          type: "number",
                                          color: "accent",
                                          dense: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.sysRoll,
                                          callback: function($$v) {
                                            _vm.sysRoll = $$v
                                          },
                                          expression: "sysRoll"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center mt-3 mr-6",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", {
                                staticClass: "heading h1",
                                domProps: {
                                  innerHTML: _vm._s("+" + _vm.mech.Sys)
                                }
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt-2" }, [
                                _vm._v("SYSTEMS")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-x-reverse-transition",
        [
          _vm.sysRoll && _vm.sys
            ? _c(
                "v-row",
                { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto",
                      attrs: { cols: "auto", align: "end" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            tile: "",
                            color: _vm.sysRoll > _vm.sys ? "success" : "error",
                            disabled: _vm.used
                          },
                          on: {
                            click: function($event) {
                              return _vm.complete()
                            }
                          }
                        },
                        [_vm._v("\n          CONFIRM\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }