var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "px-5": "" } },
    [
      _c("h1", { staticClass: "heading" }, [_vm._v("FACTIONS")]),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          attrs: {
            vertical: _vm.$vuetify.breakpoint.lgAndUp,
            "background-color": "primary",
            "slider-size": 12,
            "slider-color": "active",
            "icons-and-text": "",
            "show-arrows": ""
          },
          model: {
            value: _vm.tabModel,
            callback: function($$v) {
              _vm.tabModel = $$v
            },
            expression: "tabModel"
          }
        },
        [
          _vm._l(_vm.factions, function(f, i) {
            return _c(
              "v-tab",
              { key: f.ID, attrs: { ripple: "" } },
              [
                _c("cc-logo", {
                  attrs: {
                    size: "large",
                    source: f,
                    color: _vm.tabModel == i ? "white" : "black"
                  }
                }),
                _vm._v("\n      " + _vm._s(f.ID) + "\n    ")
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.factions, function(f) {
            return _c(
              "v-tab-item",
              { key: f.ID + "desc" },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "px-3 py-3 panel clipped-x-large",
                    attrs: { flat: "" }
                  },
                  [
                    _c(
                      "v-card-title",
                      {
                        class:
                          "heading " +
                          (_vm.$vuetify.breakpoint.lgAndUp
                            ? "mech pb-3"
                            : "h2"),
                        style:
                          "color: " +
                          f.Color +
                          "; word-break: break-word!important"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: { "overflow-wrap": "normal!important" }
                          },
                          [
                            _vm._v(
                              "\n            " + _vm._s(f.Name) + "\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-card-text",
                      { staticClass: "mt-1 pr-4 pt-0" },
                      [
                        _vm.$vuetify.breakpoint.lgAndUp
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  float: "right",
                                  "margin-left": "20px",
                                  "margin-right": "50px",
                                  "min-height": "22vw"
                                }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    style:
                                      "width:22vw; height:22vw; fill:" +
                                      f.Color +
                                      "; stroke:#fff; stroke-width: 8px;"
                                  },
                                  [
                                    _c("use", {
                                      attrs: { href: f.Logo + "#Content" }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("v-divider", {
                          staticClass: "ma-2",
                          staticStyle: { width: "800px" }
                        }),
                        _vm._v(" "),
                        _c("p", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: f.Description,
                              expression: "f.Description"
                            }
                          ],
                          staticClass: "body-text stark-text--text mb-2",
                          staticStyle: { "min-height": "400px" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }