var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [_c("v-icon", _vm._g({}, on), [_vm._v("cci-accuracy")])]
          }
        }
      ])
    },
    [_vm._v(" "), _c("bonus-base", { attrs: { bonus: _vm.bonus } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }