var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "heading ml-5" }, [_vm._v("ACTION ECONOMY")]),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c("h2", { staticClass: "heading mech" }, [_vm._v("Per Turn")]),
          _vm._v(" "),
          _c(
            "v-card",
            { attrs: { color: "panel", "elevation-10": "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "effect-text text-center",
                          attrs: {
                            cols: _vm.$vuetify.breakpoint.smAndDown ? 12 : 4
                          }
                        },
                        [
                          _vm._v("\n            Full Action\n            "),
                          _c(
                            "i",
                            { staticClass: "subtle--text text--darken-1" },
                            [_vm._v("or")]
                          ),
                          _vm._v("\n            2 Quick Actions\n            "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            {
                              staticClass: "text-center pt-4 pb-4",
                              attrs: {
                                tile: "",
                                color: "action--full",
                                width: "100%"
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "100", dark: "" } },
                                [_vm._v("$vuetify.icons.full")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    size: "50",
                                    color: "indigo lighten-3"
                                  }
                                },
                                [_vm._v("mdi-swap-horizontal")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-icon",
                                { attrs: { size: "65", dark: "" } },
                                [_vm._v("$vuetify.icons.quick")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-icon",
                                { attrs: { size: "65", dark: "" } },
                                [_vm._v("$vuetify.icons.quick")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.actionTypes, function(a) {
                        return _c(
                          "v-col",
                          {
                            key: "atc_" + a.action,
                            staticClass:
                              "effect-text text-center text-capitalize",
                            attrs: { sm: "6", lg: "2" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(a.action) +
                                "\n            "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "v-card",
                              {
                                staticClass: "pt-4 pb-4",
                                attrs: {
                                  tile: "",
                                  color: "action--" + a.action
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { size: "100", dark: "" } },
                                  [_vm._v(_vm._s(a.icon))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c("v-divider", { staticClass: "mt-5" }),
              _vm._v(" "),
              _c(
                "v-icon",
                { attrs: { "x-large": "", color: "panel-border" } },
                [_vm._v("cci-frame")]
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-5" })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "fill-height": "", justify: "center" } },
            _vm._l(_vm.actions, function(a) {
              return _c("action-card", { key: a.id, attrs: { action: a } })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c("v-divider", { staticClass: "mt-5" }),
              _vm._v(" "),
              _c(
                "v-icon",
                { attrs: { "x-large": "", color: "panel-border" } },
                [_vm._v("cci-pilot")]
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-5" })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "fill-height": "", justify: "center" } },
            _vm._l(_vm.pilotActions, function(a) {
              return _c("action-card", { key: a.id, attrs: { action: a } })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c("v-divider", { staticClass: "mt-5" }),
              _vm._v(" "),
              _c(
                "v-icon",
                { attrs: { "x-large": "", color: "panel-border" } },
                [_vm._v("cci-downtime")]
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-5" })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "fill-height": "", justify: "center" } },
            _vm._l(_vm.downtimeActions, function(a) {
              return _c("action-card", { key: a.id, attrs: { action: a } })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }