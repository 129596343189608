var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "mt-2 " + (_vm.$vuetify.breakpoint.mdAndDown ? "text-center" : "")
    },
    [
      _c("p", { staticClass: "heading mech mt-2" }, [_vm._v("COMP/CON")]),
      _vm._v(" "),
      _c("div", { staticClass: "mx-2" }, [
        _vm._v("\n    C/C version:\n    "),
        _c("b", { staticClass: "accent--text" }, [
          _vm._v(_vm._s(_vm.$appVersion))
        ]),
        _vm._v("\n    // LANCER CORE version:\n    "),
        _c("b", { staticClass: "accent--text" }, [
          _vm._v(_vm._s(_vm.$lancerVersion))
        ])
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mr-10 my-2" }),
      _vm._v(" "),
      _vm._m(1)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "my-2", attrs: { align: "center" } }, [
      _c(
        "a",
        { attrs: { href: "https://app.netlify.com/sites/compcon/deploys" } },
        [
          _c("img", {
            attrs: {
              alt: "Netlify Status",
              src:
                "https://api.netlify.com/api/v1/badges/8c8ba126-8074-4a99-98f9-9b0529107214/deploy-status"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("a", { attrs: { href: "https://discord.gg/rwcpzsU" } }, [
        _c("img", {
          attrs: {
            src:
              "https://img.shields.io/badge/discord-%23compcon-7289DA?logo=discord&logoColor=white",
            alt: "Join the LANCER discord"
          }
        })
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("a", { attrs: { href: "https://patreon.com/compcon" } }, [
        _c("img", {
          attrs: {
            src:
              "https://img.shields.io/endpoint.svg?url=https%3A%2F%2Fshieldsio-patreon.vercel.app%2Fapi%3Fusername%3Dcompcon%26type%3Dpatrons&style=for-the-badge",
            alt: "Support COMP/CON on Patreon"
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "body-text text-center" }, [
      _vm._v(
        "\n    COMP/CON is a free, open-source digital toolkit and gameplay assistant for building and\n    playing LANCER TTRPG games. The LANCER CORE Book, COMP/CON, and associated downloads and\n    expansions can be found at\n    "
      ),
      _c(
        "a",
        { attrs: { target: "_blank", href: "http://www.massifpress.com" } },
        [_vm._v("massifpress.com")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }