var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.hover
      ? "deployableInfoHover"
      : _vm.panel
      ? "deployableInfoPanel"
      : "deployableInfoPopup",
    { tag: "component", attrs: { deployable: _vm.deployable } }
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }