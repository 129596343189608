






























































































import Vue from 'vue'
import NpcChip from '../../encounter/components/NpcChip.vue'
import { EncounterSide } from '@/class'

export default Vue.extend({
  name: 'encounter-panel',
  components: { NpcChip },
  props: {
    encounter: {
      type: Object,
      required: true,
    },
    sideSelect: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  computed: {
    forces() {
      return {
        enemy: this.encounter.Npcs('Enemy'),
        allied: this.encounter.Npcs('Ally'),
        neutral: this.encounter.Npcs('Neutral'),
      }
    },
    reinforcements() {
      return {
        enemy: this.encounter.Reinforcements('Enemy'),
        allied: this.encounter.Reinforcements('Ally'),
        neutral: this.encounter.Reinforcements('Neutral'),
      }
    },
  },
})
