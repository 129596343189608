var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-body elevation-10" },
    [
      _c("div", { attrs: { id: "cap" } }),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "div",
            { staticClass: "d-inline" },
            [
              _c(
                "cc-nav-item",
                { attrs: { selected: _vm.selected === "0", to: "../sheet/0" } },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        delayed: "",
                        content:
                          "Pilot IDENT, Status, and Biographical Information"
                      }
                    },
                    [_vm._v("\n        DOSSIER\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "cc-nav-item",
                { attrs: { selected: _vm.selected === "1", to: "../sheet/1" } },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        delayed: "",
                        content:
                          "Pilot Skill Triggers, Reserves, and Pilot Gear Loadout"
                      }
                    },
                    [_vm._v("\n        NARRATIVE PROFILE\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "cc-nav-item",
                { attrs: { selected: _vm.selected === "2", to: "../sheet/2" } },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        delayed: "",
                        content:
                          "Pilot Licenses, Mech Skills, CORE Bonuses, and Talents"
                      }
                    },
                    [_vm._v("\n        TACTICAL PROFILE\n      ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "cc-nav-item",
                { attrs: { selected: _vm.selected === "3", to: "../sheet/3" } },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        delayed: "",
                        content: "Create and Modify Mechs and their Loadouts"
                      }
                    },
                    [_vm._v("\n        MECH HANGAR\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-menu",
            {
              attrs: { "open-on-hover": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "unskew pl-1 pr-0",
                            staticStyle: { "z-index": "9" },
                            attrs: { light: "", icon: "", color: "white" }
                          },
                          on
                        ),
                        [
                          _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v("mdi-book-open-page-variant")
                          ]),
                          _vm._v(" "),
                          _c("v-icon", [_vm._v("arrow_drop_up")])
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                { staticClass: "heading h3", attrs: { dense: "" } },
                [
                  _c("v-list-item", { attrs: { to: "../sheet/0" } }, [
                    _vm._v("\n        DOSSIER\n      ")
                  ]),
                  _vm._v(" "),
                  _c("v-list-item", { attrs: { to: "../sheet/1" } }, [
                    _vm._v("\n        NARRATIVE PROFILE\n      ")
                  ]),
                  _vm._v(" "),
                  _c("v-list-item", { attrs: { to: "../sheet/2" } }, [
                    _vm._v("\n        TACTICAL PROFILE\n      ")
                  ]),
                  _vm._v(" "),
                  _c("v-list-item", { attrs: { to: "../sheet/3" } }, [
                    _vm._v("\n        MECH HANGAR\n      ")
                  ])
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mx-4 unskew",
          attrs: {
            icon: "",
            fab: "",
            "x-small": "",
            outlined: "",
            disabled: !_vm.lastLoaded
          },
          on: {
            click: function($event) {
              return _vm.toMech()
            }
          }
        },
        [
          _c(
            "cc-tooltip",
            {
              attrs: { inline: "", delayed: "", content: "Active Mech Sheet" }
            },
            [
              _c("v-icon", { attrs: { large: "", color: "white" } }, [
                _vm._v("cci-frame")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mr-4 unskew",
          attrs: {
            icon: "",
            fab: "",
            "x-small": "",
            outlined: "",
            dark: "",
            to: "/active/" + _vm.pilot.ID
          }
        },
        [
          _c(
            "cc-tooltip",
            { attrs: { inline: "", delayed: "", content: "Active Mode" } },
            [
              _c("v-icon", { attrs: { large: "", color: "white" } }, [
                _vm._v("cci-activate")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "divider" } }),
      _vm._v(" "),
      _c(
        "cc-tooltip",
        { attrs: { inline: "", delayed: "", content: "Pilot Options" } },
        [
          _c("edit-menu", {
            staticClass: "unskew",
            staticStyle: { display: "inline-block" },
            attrs: { pilot: _vm.pilot }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "", top: "", right: "", absolute: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var menu = ref.on
                return [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        delayed: "",
                        content: "Pilot Sheet Layout Options"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "unskew ml-2",
                            attrs: { icon: "", dark: "" }
                          },
                          menu
                        ),
                        [_c("v-icon", [_vm._v("mdi-view-grid-plus")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { subheader: "" } },
            [
              _c(
                "v-subheader",
                { staticClass: "heading h2 white--text primary py-0 px-4" },
                [_vm._v("Layout Options")]
              ),
              _vm._v(" "),
              _c(
                "v-list-item-group",
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("set", "tabbed")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "ma-0 mr-2 mt-3" },
                        [_c("v-icon", [_vm._v("mdi-view-array")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Tabbed View")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("set", "classic")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "ma-0 mr-2 mt-3" },
                        [_c("v-icon", [_vm._v("mdi-view-sequential")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Sheet View (Classic View)")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }