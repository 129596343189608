

















































import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'

export default Vue.extend({
  name: 'npc-templates',
  data: () => ({
    templates: [],
  }),
  created() {
    const compendium = getModule(CompendiumStore, this.$store)
    this.templates = compendium.NpcTemplates
  },
})
