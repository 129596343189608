var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("div", { staticClass: "overline" }, [_vm._v("//MISSION")]),
              _vm._v(" "),
              _c("div", { staticClass: "heading h3 accent--text mt-n1" }, [
                _vm._v(_vm._s(_vm.mission.Name))
              ]),
              _vm._v(" "),
              _vm.mission.Note
                ? _c("p", {
                    staticClass: "flavor-text panel text-center",
                    domProps: { innerHTML: _vm._s(_vm.mission.Note) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(_vm.mission.Steps, function(s, i) {
                  return _c(
                    "v-col",
                    { key: "step_" + i, attrs: { cols: s.Name ? 3 : 1 } },
                    [
                      _c(
                        "v-card",
                        { attrs: { outlined: "", height: "100%" } },
                        [
                          _c(
                            "div",
                            {
                              class:
                                "caption white--text " +
                                (i > _vm.step
                                  ? "grey"
                                  : i === _vm.step
                                  ? "success"
                                  : s.Name
                                  ? "primary"
                                  : "secondary")
                            },
                            [
                              _vm._v(
                                "\n               //" +
                                  _vm._s(i + 1) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-card-text", { staticClass: "flavor-text" }, [
                            s.Name
                              ? _c("div", [
                                  i >= _vm.step
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "heading h3" },
                                            [_vm._v(_vm._s(s.Name))]
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider"),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "PR: " +
                                                _vm._s(s.Power) +
                                                " // COMBATANTS: " +
                                                _vm._s(s.Npcs("Enemy").length)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "ENV: " + _vm._s(s.Environment)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("div", [
                                            _vm._v(
                                              "SITREP: " + _vm._s(s.Sitrep.name)
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _c("div", [
                                        _vm._v(
                                          "\n                  COMPLETE\n                "
                                        )
                                      ])
                                ])
                              : _c(
                                  "div",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: {
                                      "margin-top": "25%",
                                      "margin-bottom": "25%"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "secondary", large: "" }
                                      },
                                      [_vm._v("mdi-restore")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "overline" }, [
                                      _vm._v(
                                        _vm._s(s.IsLong ? "Full" : "Short") +
                                          " Rest"
                                      )
                                    ])
                                  ],
                                  1
                                )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "overline" }, [_vm._v("//CURRENT ENCOUNTER")]),
      _vm._v(" "),
      _c("div", { staticClass: "heading mech" }, [
        _vm._v("\n    " + _vm._s(_vm.encounter.Name) + "\n  ")
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", {
        staticClass: "panel flavor-text",
        domProps: { innerHTML: _vm._s(_vm.encounter.NarrativeNotes) }
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-n2", attrs: { dense: "", align: "start" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "7" } },
            [
              _c("div", {
                staticClass: "heading h3",
                domProps: {
                  innerHTML: _vm._s(
                    "Location: " + (_vm.encounter.Location || "---")
                  )
                }
              }),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-2" }),
              _vm._v(" "),
              _c("p", {
                staticClass: "flavor-text",
                domProps: {
                  innerHTML: _vm._s("Environment: " + _vm.encounter.Environment)
                }
              }),
              _vm._v(" "),
              _vm.encounter.EnvironmentDetails
                ? _c("p", {
                    staticClass: "flavor-text",
                    domProps: {
                      innerHTML: _vm._s(_vm.encounter.EnvironmentDetails)
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.encounter.GmNotes ? _c("v-divider") : _vm._e(),
              _vm._v(" "),
              _vm.encounter.GmNotes
                ? _c("p", {
                    staticClass: "flavor-text",
                    domProps: { innerHTML: _vm._s(_vm.encounter.GmNotes) }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "v-card",
                { attrs: { flat: "", outlined: "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-1" },
                    [
                      _vm.encounter.Map
                        ? _c("v-img", {
                            key: _vm.encounter.Map,
                            attrs: {
                              src: _vm.encounter.Map,
                              "aspect-ratio": "1"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-title", { staticClass: "mb-3", attrs: { small: "" } }, [
        _vm._v("\n    SITREP\n  ")
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "heading h3",
        domProps: { innerHTML: _vm._s(_vm.encounter.Sitrep.Name) }
      }),
      _vm._v(" "),
      _c("p", {
        staticClass: "flavor-text",
        domProps: { innerHTML: _vm._s(_vm.encounter.Sitrep.description) }
      }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mt-2" }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c("v-col", { staticClass: "text-center" }, [
            _c("span", { staticClass: "heading h3 accent--text" }, [
              _vm._v("PC victory")
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "flavor-text",
              domProps: { innerHTML: _vm._s(_vm.encounter.Sitrep.pcVictory) }
            })
          ]),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("v-col", { staticClass: "text-center" }, [
            _c("span", { staticClass: "heading h3 accent--text" }, [
              _vm._v("enemy victory")
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "flavor-text",
              domProps: { innerHTML: _vm._s(_vm.encounter.Sitrep.enemyVictory) }
            })
          ]),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("v-col", { staticClass: "text-center" }, [
            _c("span", { staticClass: "heading h3 accent--text" }, [
              _vm._v("no victor")
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "flavor-text",
              domProps: { innerHTML: _vm._s(_vm.encounter.Sitrep.noVictory) }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c("v-col", { staticClass: "text-center" }, [
            _c("span", { staticClass: "heading h3 accent--text" }, [
              _vm._v("Deployment")
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "flavor-text",
              domProps: { innerHTML: _vm._s(_vm.encounter.Sitrep.deployment) }
            })
          ]),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("v-col", { staticClass: "text-center" }, [
            _c("span", { staticClass: "heading h3 accent--text" }, [
              _vm._v("Extraction")
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "flavor-text",
              domProps: { innerHTML: _vm._s(_vm.encounter.Sitrep.extraction) }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c("v-col", { staticClass: "text-center" }, [
            _c("span", { staticClass: "heading h3 accent--text" }, [
              _vm._v("Control Zones")
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "flavor-text",
              domProps: { innerHTML: _vm._s(_vm.encounter.Sitrep.controlZone) }
            })
          ]),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("v-col", { staticClass: "text-center" }, [
            _c("span", { staticClass: "heading h3 accent--text" }, [
              _vm._v("Objective")
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "flavor-text",
              domProps: { innerHTML: _vm._s(_vm.encounter.Sitrep.objective) }
            })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }