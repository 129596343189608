var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-window-item",
    [
      _c("v-card-title", {
        staticClass: "heading h2",
        attrs: { "primary-title": "" },
        domProps: { innerHTML: _vm._s(_vm.title) }
      }),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "text-center flavor-text" },
        [
          _vm.content
            ? _c("p", {
                staticClass: "flavor-text",
                domProps: { innerHTML: _vm._s(_vm.content) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "warning" },
              on: {
                click: function($event) {
                  return _vm.$emit("dismiss")
                }
              }
            },
            [_vm._v("dismiss")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          !_vm.hidePrevious
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("previous")
                    }
                  }
                },
                [_vm._v("previous")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.otherBtn
            ? _vm._t("confirm-button")
            : _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    large: "",
                    disabled: _vm.disabled
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm")
                    }
                  }
                },
                [_vm._v("\n      confirm\n    ")]
              )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }