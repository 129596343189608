var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notificationContainer" },
    [
      _c("div", {
        staticClass: "v-menu__content--active",
        staticStyle: { display: "none", "z-index": "1000" }
      }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple",
              value: false,
              expression: "false"
            }
          ],
          ref: "snackbar",
          attrs: {
            value: true,
            color: _vm.notificationVariant && _vm.notificationVariant.color,
            timeout: -1
          },
          on: {
            mouseover: _vm.onInteract,
            click: function($event) {
              $event.stopPropagation()
              return _vm.onClick($event)
            }
          }
        },
        [
          _c(
            "v-icon",
            { staticClass: "mr-2", attrs: { dark: "", prepend: "" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.notificationVariant && _vm.notificationVariant.icon
                  ) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _vm.notificationVariant && _vm.notificationVariant.prefix
            ? _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.notificationVariant && _vm.notificationVariant.prefix
                  )
                }
              })
            : _vm._e(),
          _vm._v("\n     \n    "),
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.notification.text) }
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "ml-auto",
              attrs: { dark: "", text: "" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("dismiss-snackbar")
                }
              }
            },
            [_vm._v("\n      Dismiss\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-fade-transition",
            [
              _vm.timeout > 0 && !_vm.interacted
                ? _c("v-progress-linear", {
                    ref: "progress",
                    attrs: {
                      absolute: "",
                      bottom: "",
                      color: "white",
                      "background-opacity": "0"
                    },
                    model: {
                      value: _vm.timeoutValue,
                      callback: function($$v) {
                        _vm.timeoutValue = $$v
                      },
                      expression: "timeoutValue"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }