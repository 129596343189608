var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "equipment-card-base",
    { attrs: { item: _vm.item } },
    [
      _c("v-col", { attrs: { cols: "auto" } }, [
        _c(
          "div",
          {
            staticClass: "text-center ml-auto mr-auto",
            staticStyle: { display: "inline-block" }
          },
          [
            _c(
              "div",
              { staticClass: "clip-icon" },
              [
                _c("v-icon", { attrs: { "x-large": "" } }, [
                  _vm._v("cci-reticle")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n        +" +
                  _vm._s(_vm.item.AttackBonus(1)) +
                  "/+" +
                  _vm._s(_vm.item.AttackBonus(2)) +
                  "/+" +
                  _vm._s(_vm.item.AttackBonus(3)) +
                  "\n        "
              ),
              _c("br"),
              _vm._v(" "),
              _c("div", { staticClass: "overline mt-n1" }, [
                _vm._v("\n          Attack Bonus\n        ")
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _vm.item.Accuracy(1) > 0
          ? _c(
              "div",
              {
                staticClass: "text-center ml-auto mr-auto",
                staticStyle: { display: "inline-block" }
              },
              [
                _c(
                  "div",
                  { staticClass: "clip-icon" },
                  [
                    _c("v-icon", { attrs: { "x-large": "" } }, [
                      _vm._v("cci-accuracy")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "\n        +" +
                      _vm._s(_vm.item.Accuracy(1)) +
                      "/+" +
                      _vm._s(_vm.item.Accuracy(2)) +
                      "/+" +
                      _vm._s(_vm.item.Accuracy(3)) +
                      "\n        "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("div", { staticClass: "overline mt-n1" }, [
                    _vm._v("\n          Accuracy\n        ")
                  ])
                ])
              ]
            )
          : _vm.item.Accuracy(1) < 0
          ? _c("div", [
              _c(
                "div",
                { staticClass: "clip-icon" },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("cci-difficulty")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n        +" +
                    _vm._s(Math.abs(_vm.item.Accuracy(1))) +
                    "/+" +
                    _vm._s(Math.abs(_vm.item.Accuracy(2))) +
                    "/+" +
                    _vm._s(Math.abs(_vm.item.Accuracy(3))) +
                    "\n        "
                ),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "overline mt-n1" }, [
                  _vm._v("\n          Difficulty\n        ")
                ])
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "ml-auto text-right", attrs: { cols: "auto" } },
        [
          _c("div", { staticClass: "heading h2" }, [
            _vm._v(_vm._s(_vm.item.TechType) + " Tech")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "flavor-text subtle--text" }, [
            _vm._v("// " + _vm._s(_vm.item.Origin))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }