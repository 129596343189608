var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", lg: "6" } },
    [
      _c(
        "v-card",
        {
          staticClass: "clipped-large light-panel pb-2",
          attrs: { flat: "", tile: "" }
        },
        [
          _c(
            "v-toolbar",
            { attrs: { dense: "", flat: "" } },
            [
              _c(
                "v-toolbar-title",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v(_vm._s(_vm.deployable.Icon))
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "heading h3" },
                        [
                          _c(
                            "cc-short-string-editor",
                            {
                              attrs: { inline: "" },
                              on: {
                                set: function($event) {
                                  _vm.deployable.Name = $event
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.deployable.Name) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-col", [
                        _c(
                          "span",
                          { staticClass: "pl-3 flavor-text subtle--text" },
                          [_vm._v("//" + _vm._s(_vm.deployable.BaseName))]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-toolbar-items",
                [
                  _vm.deployable.Recall && !_vm.recallState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect",
                          attrs: {
                            small: "",
                            text: "",
                            color: "accent",
                            disabled: !_vm.canRecall
                          },
                          on: { click: _vm.recall }
                        },
                        [
                          _vm.recallIcon
                            ? _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(_vm._s(_vm.recallIcon))
                              ])
                            : _vm._e(),
                          _vm._v("\n          Recall\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.deployable.Redeploy && _vm.recallState
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect",
                          attrs: {
                            small: "",
                            text: "",
                            color: "accent",
                            disabled: !_vm.canRedeploy
                          },
                          on: { click: _vm.redeploy }
                        },
                        [
                          _vm.redeployIcon
                            ? _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(_vm._s(_vm.redeployIcon))
                              ])
                            : _vm._e(),
                          _vm._v("\n          Redeploy\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "offset-x": "",
                        top: "",
                        left: ""
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "fadeSelect",
                                    attrs: {
                                      small: "",
                                      text: "",
                                      color: "error"
                                    }
                                  },
                                  on
                                ),
                                [_vm._v("Remove")]
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.removeMenu,
                        callback: function($$v) {
                          _vm.removeMenu = $$v
                        },
                        expression: "removeMenu"
                      }
                    },
                    [
                      _vm._v(" "),
                      _c("cc-confirmation", {
                        attrs: {
                          content:
                            "Lancer, this will remove this deployable from the Deployed Equipment list. <span class='accent--text'>This cannot be undone.</span> Do you want to continue?"
                        },
                        on: {
                          confirm: function($event) {
                            _vm.removeMenu = false
                            _vm.pilot.State.RemoveDeployable(_vm.deployable.ID)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.deployable.Destroyed
            ? _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "heading h2 error--text text-center" },
                  [_vm._v("EQUIPMENT DESTROYED")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right mr-3 mb-n3" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "fadeSelect",
                        attrs: { "x-small": "", color: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.deployable.Repair()
                          }
                        }
                      },
                      [
                        _c(
                          "cc-tooltip",
                          {
                            attrs: {
                              content:
                                "Restore this deployable to working order. This does not consume an action and should be used to correct an error or in special cases, such as GM fiat"
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "", left: "" } }, [
                              _vm._v("mdi-reload")
                            ]),
                            _vm._v("\n            RESTORE\n          ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _vm.recallState
            ? _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "heading h2 subtle--text text-center" },
                  [_vm._v("EQUIPMENT RECALLED")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-right mr-3 mb-n3" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "fadeSelect",
                        attrs: { "x-small": "", color: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.freeRecall()
                          }
                        }
                      },
                      [
                        _c(
                          "cc-tooltip",
                          {
                            attrs: {
                              content:
                                "Return this deployable to the battlefield. This does not consume an action and should be used to correct an error or in special cases, such as GM fiat"
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "", left: "" } }, [
                              _vm._v("mdi-reload")
                            ]),
                            _vm._v("\n            RESTORE\n          ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            : _c(
                "v-card-text",
                { staticClass: "py-1" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _vm.deployable.Armor
                        ? _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tick-bar",
                                {
                                  key: _vm.deployable.Armor,
                                  attrs: {
                                    current: _vm.deployable.Armor,
                                    max: _vm.deployable.Armor,
                                    color: "armor",
                                    "full-icon": "mdi-shield",
                                    "hide-max": "",
                                    readonly: ""
                                  }
                                },
                                [
                                  _c("span", { staticClass: "heading" }, [
                                    _vm._v(
                                      "Armor: " + _vm._s(_vm.deployable.Armor)
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.MaxHP
                        ? _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tick-bar",
                                {
                                  key: _vm.deployable.CurrentHP,
                                  attrs: {
                                    current: _vm.deployable.CurrentHP,
                                    max: _vm.deployable.MaxHP,
                                    color: "hp",
                                    "full-icon": "mdi-hexagon",
                                    "max-length": 15
                                  },
                                  on: {
                                    update: function($event) {
                                      _vm.deployable.CurrentHP = $event
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "heading" }, [
                                    _vm._v("HP")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.MaxHP
                        ? _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tick-bar",
                                {
                                  key: _vm.deployable.Overshield,
                                  attrs: {
                                    current: _vm.deployable.Overshield,
                                    max: _vm.deployable.Overshield,
                                    color: "stark",
                                    "full-icon": "mdi-octagram",
                                    "max-length": "3",
                                    "hide-max": ""
                                  },
                                  on: {
                                    update: function($event) {
                                      _vm.deployable.Overshield = $event
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "heading" }, [
                                    _vm._v(
                                      "OVERSHIELD: " +
                                        _vm._s(_vm.deployable.Overshield)
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.Heatcap
                        ? _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tick-bar",
                                {
                                  key: _vm.deployable.CurrentHeat,
                                  attrs: {
                                    current: _vm.deployable.CurrentHeat,
                                    max: _vm.deployable.Heatcap,
                                    color: "heatcap",
                                    "full-icon": "mdi-circle",
                                    clearable: ""
                                  },
                                  on: {
                                    update: function($event) {
                                      _vm.deployable.CurrentHeat = $event
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "heading" }, [
                                    _vm._v("HEAT")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.Repcap
                        ? _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tick-bar",
                                {
                                  key: _vm.deployable.CurrentRepairs,
                                  attrs: {
                                    current: _vm.deployable.CurrentRepairs,
                                    max: _vm.deployable.Repcap,
                                    color: "repcap",
                                    "full-icon": "cci-repair"
                                  },
                                  on: {
                                    update: function($event) {
                                      _vm.deployable.CurrentRepairs = $event
                                    }
                                  }
                                },
                                [
                                  _c("span", { staticClass: "heading" }, [
                                    _vm._v("REPAIR CAPACITY")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-8",
                      attrs: { justify: "center", dense: "" }
                    },
                    [
                      _vm.deployable.Size
                        ? _c("cc-statblock-panel", {
                            staticClass: "mx-1",
                            attrs: {
                              inline: "",
                              icon:
                                "cci-size-" +
                                (_vm.deployable.Size === 0.5
                                  ? "half"
                                  : _vm.deployable.Size),
                              name: "Size",
                              value:
                                "" +
                                (_vm.deployable.Size === 0.5
                                  ? "½"
                                  : _vm.deployable.Size)
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.Size
                        ? _c("cc-statblock-panel", {
                            staticClass: "mx-1",
                            attrs: {
                              icon: "$vuetify.icons.evasion",
                              inline: "",
                              name: "Evasion",
                              value: _vm.deployable.Evasion
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.EDefense
                        ? _c("cc-statblock-panel", {
                            staticClass: "mx-1",
                            attrs: {
                              inline: "",
                              icon: "$vuetify.icons.edef",
                              name: "E-Defense",
                              value: _vm.deployable.EDefense
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.Heatcap
                        ? _c("cc-statblock-panel", {
                            staticClass: "mx-1",
                            attrs: {
                              inline: "",
                              icon: "$vuetify.icons.heat",
                              name: "Heat Capacity",
                              value: _vm.deployable.Heatcap
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.Sensor
                        ? _c("cc-statblock-panel", {
                            staticClass: "mx-1",
                            attrs: {
                              inline: "",
                              icon: "$vuetify.icons.sensor",
                              name: "Sensor Range",
                              value: _vm.deployable.Sensor
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.TechAttack
                        ? _c("cc-statblock-panel", {
                            staticClass: "mx-1",
                            attrs: {
                              inline: "",
                              icon: "$vuetify.icons.tech",
                              name: "Tech Attack",
                              value: _vm.deployable.TechAttack
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.Repcap
                        ? _c("cc-statblock-panel", {
                            staticClass: "mx-1",
                            attrs: {
                              inline: "",
                              icon: "$vuetify.icons.repair",
                              name: "Repair Capacity",
                              value: _vm.deployable.Repcap
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.Save
                        ? _c("cc-statblock-panel", {
                            staticClass: "mx-1",
                            attrs: {
                              inline: "",
                              icon: "$vuetify.icons.save",
                              name: "Save Target",
                              value: _vm.deployable.Save
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.deployable.Speed
                        ? _c("cc-statblock-panel", {
                            staticClass: "mx-1",
                            attrs: {
                              inline: "",
                              icon: "$vuetify.icons.speed",
                              name: "Speed",
                              value: _vm.deployable.Speed
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { justify: "center", dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("p", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: _vm.deployable.Detail,
                              expression: "deployable.Detail"
                            }
                          ],
                          staticClass: "light-panel mb-0 clipped body-text px-4"
                        })
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    _vm._l(_vm.deployable.Actions, function(a, i) {
                      return _c(
                        "v-col",
                        {
                          key: _vm.deployable.Name + "_action_" + i,
                          staticStyle: { "min-width": "25%" },
                          attrs: { cols: "auto" }
                        },
                        [
                          _c("cc-action", {
                            attrs: {
                              action: a,
                              active: "",
                              activations: _vm.pilot.State.Actions,
                              unusable:
                                a.Used ||
                                (a.Activation === "Protocol" &&
                                  !_vm.pilot.State.IsProtocolAvailable)
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }