










































































































































































































































































































































































import Vue from 'vue'
import sleep from '@/util/sleep'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore, UserStore } from '@/store'
import RechargeMenu from './RechargeMenu.vue'
import { UserProfile } from '@/user'

export default Vue.extend({
  name: 'npc-card',
  components: { RechargeMenu },
  props: {
    npc: {
      type: Object,
      required: true,
    },
    defeated: {
      type: Boolean,
    },
  },
  data: () => ({
    resistances: [
      { name: 'Kinetic', color: 'kinetic' },
      { name: 'Energy', color: 'energy' },
      { name: 'Explosive', color: 'explosive' },
      { name: 'Heat', color: 'heat' },
      { name: 'Burn', color: 'burn' },
      { name: 'All', color: 'variable' },
      { name: 'Next Attack', color: 'overcharge' },
    ],
    structRolledOver: false,
    stressRolledOver: false,
    npcSwitch: false,
  }),
  computed: {
    statuses() {
      const store = getModule(CompendiumStore, this.$store)
      return store.Statuses.filter(x => x.type === 'Status')
    },
    conditions() {
      const store = getModule(CompendiumStore, this.$store)
      return store.Statuses.filter(x => x.type === 'Condition')
    },
    reactions() {
      return this.npc.Reactions
    },
    profile(): UserProfile {
      const store = getModule(UserStore, this.$store)
      return store.UserProfile
    },
  },
  watch: {
    'npc.CurrentStructure': {
      async handler(newVal: number, oldVal: number) {
        if (newVal < oldVal) {
          this.structRolledOver = true
          await sleep(500)
          this.structRolledOver = false
          if (this.npc.CurrentStructure > 0) this.$refs.structureTable.show()
        }
      },
    },
    'npc.CurrentStress': {
      async handler(newVal: number, oldVal: number) {
        if (newVal < oldVal) {
          this.stressRolledOver = true
          await sleep(500)
          this.stressRolledOver = false
          if (this.npc.CurrentStress > 0) this.$refs.stressTable.show()
        }
      },
    },
  },
})
