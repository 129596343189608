var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-btn",
        {
          attrs: {
            color: _vm.usable ? "grey darken-2" : _vm.action.Color,
            block: "",
            tile: "",
            dark: "",
            small: _vm.$vuetify.breakpoint.smAndDown,
            disabled: _vm.disabled
          },
          on: {
            click: function($event) {
              return _vm.$refs.dialog.show()
            }
          }
        },
        [
          !_vm.noAction
            ? _c("v-icon", { attrs: { dark: "", left: "" } }, [
                _vm._v(_vm._s(_vm.action.Icon))
              ])
            : _vm._e(),
          _vm._v("\n    " + _vm._s(_vm.action.Name) + "\n    "),
          !_vm.noAction
            ? _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", "max-width": "700px" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { attrs: { right: "", icon: "" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { staticClass: "fadeSelect" }, [
                                  _vm._v(
                                    "\n            mdi-information-outline\n          "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    27567258
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c("div", { staticClass: "heading h3 ma-1 pl-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.action.Name.toUpperCase()) +
                            " — " +
                            _vm._s(_vm.action.Activation.toUpperCase()) +
                            " ACTION\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _vm.action.Detail ? _c("v-divider") : _vm._e(),
                      _vm._v(" "),
                      _vm.action.Detail
                        ? _c("v-card-text", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: _vm.action.Detail,
                                expression: "action.Detail"
                              }
                            ],
                            staticClass: "body-text text--text mt-0 pt-1"
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-combat-dialog", {
        ref: "dialog",
        attrs: {
          "no-action": _vm.noAction,
          action: _vm.action,
          mech: _vm.pilot.ActiveMech
        },
        on: {
          use: function($event) {
            return _vm.$emit("use", $event)
          },
          undo: function($event) {
            return _vm.$emit("undo", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }