var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "px-5": "" } },
    [
      _c("h1", { staticClass: "heading" }, [_vm._v("LICENSES")]),
      _vm._v(" "),
      _vm._l(Object.keys(_vm.licenses), function(m) {
        return _c(
          "v-row",
          { key: m },
          [
            _c(
              "v-col",
              { staticClass: "text-center pa-3" },
              [
                _c(
                  "div",
                  [
                    _c("cc-logo", {
                      staticClass: "pt-3 mb-n1",
                      attrs: {
                        source: _vm.manufacturer(m),
                        size: _vm.$vuetify.breakpoint.mdAndDown
                          ? "large"
                          : "xLarge"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        class: _vm.$vuetify.breakpoint.mdAndDown
                          ? "heading h2"
                          : "heading mech",
                        style:
                          "width: 100%; color: " +
                          _vm.manufacturer(m).GetColor(_vm.$vuetify.theme.dark)
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.manufacturer(m).Name) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-expansion-panels",
                  {
                    attrs: {
                      accordion: "",
                      focusable: "",
                      "active-class": "border-primary"
                    }
                  },
                  _vm._l(_vm.licenses[m], function(l) {
                    return _c(
                      "v-expansion-panel",
                      { key: l.FrameID, staticClass: "panel border-highlight" },
                      [
                        _c(
                          "v-expansion-panel-header",
                          { attrs: { id: "hover-parent", "hide-actions": "" } },
                          [
                            _c(
                              "div",
                              [
                                _c("span", [
                                  _c("span", { staticClass: "caption" }, [
                                    _vm._v(_vm._s(_vm.frame(l.FrameID).Source))
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "heading h2 font-weight-bold"
                                    },
                                    [_vm._v(_vm._s(_vm.frame(l.FrameID).Name))]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.$vuetify.breakpoint.mdAndDown
                                  ? _c("br")
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.frame(l.FrameID).Mechtype, function(
                                  f
                                ) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: f,
                                      staticClass: "mr-2",
                                      attrs: {
                                        small: !_vm.$vuetify.breakpoint
                                          .mdAndDown,
                                        "x-small":
                                          _vm.$vuetify.breakpoint.mdAndDown,
                                        dark: "",
                                        outlined: "",
                                        color: "accent"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(f) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c("v-img", {
                              staticStyle: {
                                position: "absolute",
                                top: "0",
                                right: "0"
                              },
                              attrs: {
                                id: _vm.$vuetify.breakpoint.mdAndDown
                                  ? "img-mobile"
                                  : "img-hover",
                                src: _vm.frame(l.FrameID).DefaultImage,
                                "max-height": "100%",
                                position:
                                  "top " +
                                  _vm.frame(l.FrameID).YPosition +
                                  "% left 20vw"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-expansion-panel-content",
                          [_c("cc-license-panel", { attrs: { license: l } })],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c("v-divider", { staticClass: "mt-5 mb-0" })
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }