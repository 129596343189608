var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "pb-0 ma-1",
      attrs: { outlined: "", dense: "", color: _vm.action.Color }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mt-n2",
                  attrs: { large: "", dark: "", color: "stark" }
                },
                [_vm._v(_vm._s(_vm.action.Icon))]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  class:
                    "heading " +
                    (_vm.$vuetify.breakpoint.smAndDown ? "h3" : "h2") +
                    " text--text"
                },
                [_vm._v("\n        " + _vm._s(_vm.action.Name) + "\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto pl-5", attrs: { cols: "auto" } },
            [
              _vm.action.Frequency.ToString() !== "Unlimited"
                ? _c(
                    "v-chip",
                    {
                      staticClass: "stat-text mr-2",
                      attrs: {
                        slot: "title-items",
                        color: "stark",
                        small: !_vm.$vuetify.breakpoint.smAndDown,
                        "x-small": _vm.$vuetify.breakpoint.smAndDown,
                        outlined: "",
                        label: ""
                      },
                      slot: "title-items"
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.action.Frequency.ToString()) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-chip",
                {
                  attrs: {
                    small: !_vm.$vuetify.breakpoint.smAndDown,
                    "x-small": _vm.$vuetify.breakpoint.smAndDown,
                    label: "",
                    dark: "",
                    color: "action--" + _vm.action.Activation.toLowerCase()
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.action.Activation.toUpperCase()) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("action-base", {
        staticClass: "mt-n6 mb-n2",
        attrs: { action: _vm.action }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }