






















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Pilot, Tag } from '@/class'

@Component({ name: 'cc-tag' })
export default class CCTag extends Vue {
  @Prop({ type: Boolean, required: false })
  readonly small?: boolean
  @Prop({ type: Boolean, required: false })
  readonly dense?: boolean
  @Prop({ type: Boolean, required: false })
  readonly outlined?: boolean

  @Prop({ type: String, required: false, default: 'primary' })
  readonly color: string

  @Prop({ type: Object, required: true })
  readonly tag!: Tag

  @Prop({ type: Object, required: false, default: null })
  readonly pilot?: Pilot

  @Prop({ type: Number, required: false, default: 0 })
  readonly bonus?: number
}
