var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "edit-button",
    {
      attrs: { dark: _vm.dark },
      on: {
        save: function($event) {
          return _vm.save()
        }
      }
    },
    [
      _c("v-text-field", {
        attrs: {
          label: _vm.label,
          placeholder: _vm.placeholder,
          outlined: "",
          "hide-details": "",
          autofocus: ""
        },
        on: {
          focus: function($event) {
            return $event.target.select()
          }
        },
        model: {
          value: _vm.newString,
          callback: function($$v) {
            _vm.newString = $$v
          },
          expression: "newString"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }