var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.component
    ? _c(_vm.component, {
        tag: "component",
        attrs: { item: _vm.item, active: _vm.active },
        on: {
          "remove-feature": function($event) {
            return _vm.$emit("remove-feature", $event)
          },
          "add-reaction": function($event) {
            return _vm.$emit("add-reaction", $event)
          },
          recalc: function($event) {
            return _vm.$emit("recalc")
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }