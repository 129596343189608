var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-click": false,
        "close-on-content-click": false,
        right: "",
        "offset-x": "",
        "nudge-bottom": "-200px"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.menu = true
                      }
                    }
                  },
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-dice-multiple")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticStyle: { "overflow-y": "scroll" },
          attrs: { "min-width": "400px", height: "400px" }
        },
        [
          _vm.title
            ? _c(
                "v-toolbar",
                {
                  staticClass: "white--text heading h3",
                  attrs: {
                    tile: "",
                    dense: "",
                    flat: "",
                    color: _vm.critical ? "exotic" : "primary"
                  }
                },
                [
                  _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
                  _vm.critical
                    ? _c(
                        "span",
                        {
                          staticClass: "flavor-text white--text text--secondary"
                        },
                        [_vm._v("// CRITICAL")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.overkill
                    ? _c(
                        "span",
                        {
                          staticClass: "flavor-text white--text text--secondary"
                        },
                        [_vm._v("// OVERKILL")]
                      )
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "", align: "center", justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticStyle: {
                            border: "1px solid var(--v-primary-base)",
                            "border-radius": "2px"
                          },
                          attrs: { dense: "", justify: "center" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.moreDice,
                                  expression: "moreDice"
                                }
                              ],
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "cc-tooltip",
                                { attrs: { content: "Add coin flip (d2)" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "accent" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addDice(2)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-numeric-2-circle-outline")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.moreDice,
                                  expression: "moreDice"
                                }
                              ],
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "cc-tooltip",
                                { attrs: { content: "Add d4 roll" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "accent" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addDice(4)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-dice-d4-outline")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tooltip",
                                { attrs: { content: "Add d6 roll" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "accent" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addDice(6)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-dice-d6-outline")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.moreDice,
                                  expression: "moreDice"
                                }
                              ],
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "cc-tooltip",
                                { attrs: { content: "Add d8 roll" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "accent" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addDice(8)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-dice-d8-outline")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.moreDice,
                                  expression: "moreDice"
                                }
                              ],
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "cc-tooltip",
                                { attrs: { content: "Add d10 roll" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "accent" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addDice(10)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-dice-d10-outline")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.moreDice,
                                  expression: "moreDice"
                                }
                              ],
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "cc-tooltip",
                                { attrs: { content: "Add d12 roll" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "accent" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addDice(12)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-dice-d12-outline")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tooltip",
                                { attrs: { content: "Add d20 roll" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "accent" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addDice(20)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-dice-d20-outline")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "fadeSelect",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.moreDice = !_vm.moreDice
                                    }
                                  }
                                },
                                [
                                  _vm.moreDice
                                    ? _c("v-icon", [
                                        _vm._v("mdi-chevron-double-left")
                                      ])
                                    : _c("v-icon", [
                                        _vm._v("mdi-chevron-double-right")
                                      ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tooltip",
                                { attrs: { content: "Add Accuracy" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "accent" },
                                      on: {
                                        click: function($event) {
                                          _vm.accuracy++
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("cci-accuracy")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tooltip",
                                { attrs: { content: "Add Difficulty" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", color: "accent" },
                                      on: {
                                        click: function($event) {
                                          _vm.accuracy--
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("cci-difficulty")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          attrs: {
                            dense: "",
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              !_vm.dice.length
                                ? _c(
                                    "v-chip",
                                    {
                                      staticStyle: { opacity: "0.5" },
                                      attrs: { outlined: "" }
                                    },
                                    [_vm._v("No Roll")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.dice, function(d, i) {
                            return _c(
                              "v-col",
                              {
                                key: i + "_dice_" + d.sides,
                                attrs: { cols: "auto" }
                              },
                              [
                                _c(
                                  "v-chip",
                                  {
                                    staticClass: "mx-1",
                                    attrs: {
                                      outlined: "",
                                      close: "",
                                      "close-icon": "mdi-close"
                                    },
                                    on: {
                                      "click:close": function($event) {
                                        return _vm.removeDice(d.sides)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(d.count) +
                                        "d" +
                                        _vm._s(d.sides) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("v-icon", [_vm._v("mdi-plus")])
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("v-text-field", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  dense: "",
                                  "hide-details": "",
                                  outlined: "",
                                  "prepend-icon": !_vm.dice.length
                                    ? "mdi-plus"
                                    : "",
                                  type: "number"
                                },
                                model: {
                                  value: _vm.flat,
                                  callback: function($$v) {
                                    _vm.flat = $$v
                                  },
                                  expression: "flat"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "v-slide-y-reverse-transition",
                        [
                          _vm.accuracy
                            ? _c(
                                "v-row",
                                {
                                  attrs: {
                                    dense: "",
                                    justify: "center",
                                    align: "center"
                                  }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _vm.accuracy
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                                small: "",
                                                close: "",
                                                "close-icon": "mdi-close"
                                              },
                                              on: {
                                                "click:close": function(
                                                  $event
                                                ) {
                                                  return _vm.removeMod()
                                                }
                                              }
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.accString
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-3",
                          attrs: {
                            block: "",
                            outlined: "",
                            color: "secondary"
                          },
                          on: { click: _vm.roll }
                        },
                        [_vm._v("Roll")]
                      ),
                      _vm._v(" "),
                      _vm.result ? _c("v-divider") : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "min-height": "20px" } }, [
                        _vm.result
                          ? _c(
                              "div",
                              [
                                _vm._l(_vm.result, function(r, j) {
                                  return _c(
                                    "div",
                                    { key: j + "_res_" + r.sides },
                                    [
                                      _c("div", { staticClass: "caption" }, [
                                        _vm._v(
                                          "ROLLING " +
                                            _vm._s(r.rolls.length) +
                                            "D" +
                                            _vm._s(r.sides)
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _vm._l(r.rolls, function(val, i) {
                                            return _c(
                                              "v-col",
                                              {
                                                key:
                                                  "roll_" +
                                                  r.sides +
                                                  "_" +
                                                  i +
                                                  "_" +
                                                  val,
                                                attrs: { cols: "auto" }
                                              },
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    style:
                                                      r.class[i] === "low"
                                                        ? "opacity: 0.4"
                                                        : "",
                                                    attrs: {
                                                      "x-small": "",
                                                      label: "",
                                                      color:
                                                        r.class[i] ===
                                                        "overkill"
                                                          ? "heat"
                                                          : ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(val) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                i + 1 < r.rolls.length
                                                  ? _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("mdi-plus")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "ml-auto stark--text",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  "\n                      =\n                      " +
                                                    _vm._s(
                                                      r.rolls
                                                        .map(function(x, i) {
                                                          return r.class[i] ===
                                                            "high"
                                                            ? x
                                                            : 0
                                                        })
                                                        .reduce(function(a, b) {
                                                          return a + b
                                                        }, 0)
                                                    ) +
                                                    "\n                    "
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                _vm._v(" "),
                                _vm.flat
                                  ? _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "caption" }, [
                                          _vm._v("FLAT MODIFIER")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      label: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(_vm.flat) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "ml-auto stark--text",
                                                attrs: { cols: "auto" }
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    "= " + _vm._s(_vm.flat)
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.accuracy
                                  ? _c(
                                      "div",
                                      [
                                        _c("div", { staticClass: "caption" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.accuracy > 0
                                                ? "ACCURACY"
                                                : "DIFFICULTY"
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _vm._l(_vm.accRolls, function(
                                              a,
                                              i
                                            ) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: "acc_" + a + "_" + i,
                                                  attrs: { cols: "auto" }
                                                },
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      attrs: {
                                                        "x-small": "",
                                                        label: "",
                                                        color:
                                                          a ===
                                                          Math.abs(_vm.accTotal)
                                                            ? "primary"
                                                            : "grey",
                                                        dark: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(a) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  i + 1 < _vm.accRolls.length
                                                    ? _c("cc-slashes", {
                                                        attrs: { small: "" }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "ml-auto stark--text",
                                                attrs: { cols: "auto" }
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.accuracy > 0
                                                        ? "+"
                                                        : "-"
                                                    ) +
                                                      _vm._s(
                                                        Math.abs(_vm.accTotal)
                                                      )
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "pa-1 ma-1",
                                    staticStyle: {
                                      border:
                                        "1px solid var(--v-secondary-base)",
                                      "border-radius": "2px"
                                    },
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "ml-auto stark--text text-right",
                                        attrs: { cols: "auto" }
                                      },
                                      [
                                        _c("div", { staticClass: "caption" }, [
                                          _vm._v("TOTAL")
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "heading h2" },
                                          [_vm._v(_vm._s(_vm.total))]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.overkill && _vm.overkillRolls
                                  ? _c(
                                      "v-row",
                                      {
                                        staticClass: "pa-1 ma-1",
                                        staticStyle: {
                                          border:
                                            "1px solid var(--v-heat-base)",
                                          "border-radius": "2px"
                                        },
                                        attrs: { "no-gutters": "" }
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "ml-auto stark--text text-right",
                                            attrs: { cols: "auto" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "caption" },
                                              [_vm._v("// OVERKILL //")]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(_vm.overkillRolls, function(
                                              n
                                            ) {
                                              return _c(
                                                "v-chip",
                                                {
                                                  key: "overkill_" + n,
                                                  attrs: {
                                                    "x-small": "",
                                                    color: "heat"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("cci-heat")]
                                                  )
                                                ],
                                                1
                                              )
                                            })
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-3",
                              attrs: { small: "", text: "" },
                              on: {
                                click: function($event) {
                                  _vm.menu = false
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                outlined: "",
                                color: "accent"
                              },
                              on: { click: _vm.clear }
                            },
                            [_vm._v("Clear All")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                outlined: "",
                                color: "accent"
                              },
                              on: { click: _vm.reset }
                            },
                            [_vm._v("Reset All")]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-3",
                              attrs: {
                                small: "",
                                color: "secondary",
                                disabled: !_vm.result && !_vm.flat
                              },
                              on: { click: _vm.commit }
                            },
                            [
                              _vm._v(
                                "\n              Commit Result\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }