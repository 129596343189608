








































































































































































































































































































































































import Vue from 'vue'
import NpcChip from './NpcChip.vue'
import NpcSelector from './NpcSelector.vue'
import { getModule } from 'vuex-module-decorators'
import { EncounterStore, CompendiumStore } from '@/store'
import { Npc, EncounterSide } from '@/class'

export default Vue.extend({
  name: 'encounter-card',
  components: { NpcSelector, NpcChip },
  props: {
    encounter: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    selRep: 'Standard Combat',
    ctest: ['a', 'b', 'c'],
  }),
  computed: {
    labels() {
      const store = getModule(EncounterStore, this.$store)
      return store.Encounters.flatMap(x => x.Labels).filter(x => x)
    },
    campaigns() {
      const store = getModule(EncounterStore, this.$store)
      return store.Encounters.map(x => x.Campaign).filter(x => x)
    },
    environmentData() {
      return getModule(CompendiumStore, this.$store).Environments
    },
    environments() {
      return ['Nominal'].concat(this.environmentData.map(x => x.name))
    },
    sitreps() {
      return getModule(CompendiumStore, this.$store).Sitreps
    },
    forces() {
      return {
        enemy: this.encounter.Npcs('Enemy'),
        allied: this.encounter.Npcs('Ally'),
        neutral: this.encounter.Npcs('Neutral'),
      }
    },
    reinforcements() {
      return {
        enemy: this.encounter.Reinforcements('Enemy'),
        allied: this.encounter.Reinforcements('Ally'),
        neutral: this.encounter.Reinforcements('Neutral'),
      }
    },
  },
  methods: {
    setEnvironment() {
      if (this.encounter.Environment === 'Nominal') this.encounter.EnvironmentDetails = ''
      else if (this.environmentData.some(x => x.name === this.encounter.Environment))
        this.encounter.EnvironmentDetails = this.environmentData.find(
          x => x.name === this.encounter.Environment
        ).description
    },
    addNpc(event: { npc: Npc; side: EncounterSide }) {
      this.encounter.AddNpc(event.npc, event.side)
      this.$refs.npcDialog.hide()
    },
    addReinforcement(event: { npc: Npc; side: EncounterSide }) {
      this.encounter.AddReinforcement(event.npc, event.side)
      this.$refs.reinforcementDialog.hide()
    },
  },
})
