var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-title",
        {
          staticStyle: { "margin-left": "-60px!important" },
          attrs: { small: "", color: _vm.color }
        },
        [
          _c("section-edit-icon", {
            attrs: { label: "Edit Operator Notes" },
            on: {
              "open-selector": function($event) {
                return _vm.show()
              }
            }
          }),
          _vm._v("\n    Operator Notes\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "my-2" },
        [
          _vm.mech.Notes
            ? _c("p", {
                staticClass: "flavor-text text--text mx-2 preserve-linebreaks",
                domProps: { innerHTML: _vm._s(_vm.mech.Notes) }
              })
            : _c("no-data-block")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            icon: "mdi-circle-edit-outline",
            color: "primary",
            large: "",
            title: "Operator Notes"
          },
          on: {
            confirm: function($event) {
              _vm.mech.Notes = _vm.notes
            }
          }
        },
        [
          _c("tiptap-vuetify", {
            staticClass: "mt-4",
            attrs: {
              extensions: _vm.extensions,
              "card-props": { flat: true, tile: true, elevation: 0 },
              "toolbar-attributes": _vm.$vuetify.theme.dark
                ? { color: "black", dark: true }
                : {}
            },
            model: {
              value: _vm.notes,
              callback: function($$v) {
                _vm.notes = $$v
              },
              expression: "notes"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }