var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "div",
        { staticClass: "border" },
        [
          _vm.pilot.Portrait
            ? _c("v-img", {
                key: _vm.pilot.Image,
                attrs: { src: _vm.pilot.Portrait, "max-width": "100%" }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "fadeSelect",
          attrs: { outlined: "", color: "secondary", small: "", block: "" },
          on: {
            click: function($event) {
              return _vm.$refs.imageSelector.open()
            }
          }
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v("mdi-circle-edit-outline")
          ]),
          _vm._v("\n    Set Pilot Portrait\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-image-selector-web", {
        ref: "imageSelector",
        attrs: { item: _vm.pilot, type: "pilot" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }