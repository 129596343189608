var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "", align: "start" } },
        [
          _c("single", {
            attrs: { cols: "8", label: "MECH NAME", height: "60" }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("single", { attrs: { cols: "1", label: "SIZE", height: "60" } }),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mt-n1", attrs: { cols: "2" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("single", {
                    attrs: { cols: "6", label: "HULL", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    attrs: { cols: "6", label: "AGI", height: "20" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n2", attrs: { dense: "" } },
                [
                  _c("single", {
                    attrs: { cols: "6", label: "SYS", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    attrs: { cols: "6", label: "ENG", height: "20" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-n3", attrs: { dense: "", align: "start" } },
        [
          _c("single", {
            attrs: { cols: "2", label: "MANUFACTURER", height: "20" }
          }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "4", label: "FRAME", height: "20" } }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "2", label: "STATUS", height: "20" } }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("single", { attrs: { cols: "3", label: "PILOT", height: "20" } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            [
              _c("div", { staticClass: "overline mb-n1" }, [
                _vm._v("STRUCTURE")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "60", color: "grey lighten-3" }
                        },
                        [_vm._v("cci-structure")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-6 mr-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "subtle--text text--lighten-1 display-2",
                          staticStyle: { "line-height": "0" }
                        },
                        [_vm._v("/")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mb-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "50", color: "grey lighten-3" }
                        },
                        [_vm._v("mdi-hexagon-outline")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "overline mb-n1" }, [_vm._v("ARMOR")]),
              _vm._v(" "),
              _c("v-icon", { attrs: { size: "60", color: "grey lighten-3" } }, [
                _vm._v("mdi-shield-outline")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("div", { staticClass: "overline mb-n1" }, [_vm._v("HP")]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "60", color: "grey lighten-3" }
                        },
                        [_vm._v("mdi-hexagon-outline")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-6 mr-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "subtle--text text--lighten-1 display-2",
                          staticStyle: { "line-height": "0" }
                        },
                        [_vm._v("/")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mb-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "50", color: "grey lighten-3" }
                        },
                        [_vm._v("mdi-hexagon-outline")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("div", { staticClass: "overline mb-n1" }, [_vm._v("STRESS")]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "60", color: "grey lighten-3" }
                        },
                        [_vm._v("cci-reactor")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-6 mr-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "subtle--text text--lighten-1 display-2",
                          staticStyle: { "line-height": "0" }
                        },
                        [_vm._v("/")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mb-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "50", color: "grey lighten-3" }
                        },
                        [_vm._v("mdi-hexagon-outline")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("div", { staticClass: "overline mb-n1" }, [_vm._v("HEAT")]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "60", color: "grey lighten-3" }
                        },
                        [_vm._v("cci-burn")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-6 mr-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "subtle--text text--lighten-1 display-2",
                          staticStyle: { "line-height": "0" }
                        },
                        [_vm._v("/")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mb-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "50", color: "grey lighten-3" }
                        },
                        [_vm._v("mdi-hexagon-outline")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("div", { staticClass: "overline mb-n1" }, [
                _vm._v("REPAIR CAPACITY")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "60", color: "grey lighten-3" }
                        },
                        [_vm._v("cci-repair")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-6 mr-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "subtle--text text--lighten-1 display-2",
                          staticStyle: { "line-height": "0" }
                        },
                        [_vm._v("/")]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mb-n2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-n1",
                          attrs: { size: "50", color: "grey lighten-3" }
                        },
                        [_vm._v("mdi-hexagon-outline")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "overline mb-n1 mt-n1" }, [
        _vm._v("OVERCHARGE")
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "text-center",
          attrs: { "no-gutters": "", justify: "space-around" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "grey lighten-3 mx-2 oc-border",
              attrs: { cols: "auto" }
            },
            [
              _vm._v("\n      +1\n      "),
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-fire")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "grey lighten-3 mx-2 oc-border",
              attrs: { cols: "auto" }
            },
            [
              _vm._v("\n      +1d3\n      "),
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-fire")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "grey lighten-3 mx-2 oc-border",
              attrs: { cols: "auto" }
            },
            [
              _vm._v("\n      +1d6\n      "),
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-fire")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "grey lighten-3 mx-2 oc-border",
              attrs: { cols: "auto" }
            },
            [
              _vm._v("\n      +1d6+4\n      "),
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-fire")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-n2", attrs: { align: "start" } },
        [
          _c("single", {
            attrs: { cols: "2", label: "ATK BONUS", height: "40" }
          }),
          _vm._v(" "),
          _c("single", {
            attrs: { cols: "2", label: "TECH ATK", height: "40" }
          }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "2", label: "SAVE", height: "40" } }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "2", label: "SPEED", height: "40" } }),
          _vm._v(" "),
          _c("single", {
            attrs: { cols: "2", label: "E-DEFENSE", height: "40" }
          }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "2", label: "EVASION", height: "40" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("span", { staticClass: "overline" }, [_vm._v("FRAME TRAITS")]),
      _vm._v(" "),
      _vm._l(3, function(n) {
        return _c(
          "v-row",
          { key: "trait" + n, staticClass: "mt-n2", attrs: { dense: "" } },
          [
            _c("single", {
              attrs: { cols: "7", label: "TRAIT", height: "20" }
            }),
            _vm._v(" "),
            _c("single", {
              staticClass: "mt-n2",
              attrs: { cols: "12", label: "EFFECT", height: "75" }
            })
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("span", { staticClass: "overline" }, [_vm._v("CORE SYSTEM")]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-n2", attrs: { dense: "" } },
        [
          _c("single", {
            attrs: { cols: "7", label: "CORE POWER", height: "20" }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("single", { attrs: { cols: "1", label: "USED", height: "20" } }),
          _vm._v(" "),
          _c("single", {
            staticClass: "mt-n2",
            attrs: { cols: "12", label: "PASSIVE ABILITY", height: "50" }
          }),
          _vm._v(" "),
          _c("single", {
            staticClass: "mt-n2",
            attrs: { cols: "12", label: "ACTIVE ABILITY", height: "100" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { "page-break-before": "always" } }),
      _vm._v(" "),
      _c("span", { staticClass: "overline" }, [_vm._v("ARMAMENT")]),
      _vm._v(" "),
      _vm._l(2, function(n) {
        return _c(
          "v-row",
          { key: "mr_" + n },
          _vm._l(2, function(x) {
            return _c("v-col", { key: "mc_" + n + "_" + x }, [
              _c(
                "fieldset",
                [
                  _c("legend", [
                    _c(
                      "div",
                      {
                        staticClass: "d-inline-block text-right",
                        staticStyle: {
                          height: "35px",
                          "min-width": "300px",
                          border: "2px solid grey",
                          "border-radius": "3px"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "d-inline-block flavor-text",
                            staticStyle: { "font-size": "20px" }
                          },
                          [_vm._v("\n              MOUNT \n            ")]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(2, function(y) {
                    return _c(
                      "v-row",
                      {
                        key: "mc_" + n + "_" + x + "_" + y,
                        staticClass: "mt-n1",
                        attrs: { dense: "" }
                      },
                      [
                        _c("single", {
                          attrs: { cols: "9", label: "WEAPON", height: "20" }
                        }),
                        _vm._v(" "),
                        _c("single", {
                          attrs: { cols: "3", label: "TYPE", height: "20" }
                        }),
                        _vm._v(" "),
                        _c("single", {
                          staticClass: "mt-n2",
                          attrs: { cols: "6", label: "DAMAGE", height: "20" }
                        }),
                        _vm._v(" "),
                        _c("single", {
                          staticClass: "mt-n2",
                          attrs: { cols: "6", label: "RANGE", height: "20" }
                        }),
                        _vm._v(" "),
                        _c("single", {
                          staticClass: "mt-n2",
                          attrs: { cols: "12", label: "EFFECT", height: "50" }
                        }),
                        _vm._v(" "),
                        y === 1
                          ? _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [_c("v-divider", { staticClass: "my-1" })],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ])
          }),
          1
        )
      }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-1" }),
      _vm._v(" "),
      _c("span", { staticClass: "overline" }, [_vm._v("SYSTEMS")]),
      _vm._v(" "),
      _vm._l(5, function(n) {
        return _c(
          "v-row",
          { key: "ms_" + n, staticClass: "mt-n3", attrs: { dense: "" } },
          _vm._l(2, function(x) {
            return _c(
              "v-col",
              { key: "ms_" + n + "_" + x, attrs: { cols: "6" } },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c("single", {
                      attrs: { cols: "10", label: "SYSTEM", height: "20" }
                    }),
                    _vm._v(" "),
                    _c("single", {
                      attrs: { cols: "2", label: "SP", height: "20" }
                    }),
                    _vm._v(" "),
                    _c("single", {
                      staticClass: "mt-n2",
                      attrs: { cols: "12", label: "EFFECT", height: "150" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          1
        )
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("single", {
            staticClass: "ml-auto mt-n3",
            attrs: { cols: "1", label: "USED SP", height: "20" }
          }),
          _vm._v(" "),
          _c("single", {
            staticClass: "mt-n3",
            attrs: { cols: "1", label: "MAX SP", height: "20" }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }