var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "my-1", attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        [
          _c(
            "v-btn",
            {
              attrs: {
                "x-large": "",
                block: "",
                dark: "",
                tile: "",
                color: _vm.btnColor,
                disabled: _vm.disabled
              },
              on: {
                click: function($event) {
                  return _vm.$emit("click")
                }
              }
            },
            [
              _c(
                "span",
                { staticStyle: { position: "absolute", left: "0" } },
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v(_vm._s(_vm.item.Icon))
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.item.Name) + "\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticStyle: { position: "absolute", right: "0" } },
                [
                  _vm.item.Damage
                    ? _c("cc-damage-element", {
                        staticClass: "d-inline",
                        attrs: { damage: _vm.item.Damage, small: "" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.Range
                    ? _c("cc-range-element", {
                        staticClass: "d-inline",
                        attrs: { range: _vm.item.Range, small: "" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.Frequency
                    ? _c("v-chip", {
                        directives: [
                          {
                            name: "html-safe",
                            rawName: "v-html-safe",
                            value: _vm.item.Frequency.ToString(),
                            expression: "item.Frequency.ToString()"
                          }
                        ],
                        attrs: { small: "", outlined: "" }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }