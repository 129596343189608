var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-x": "", left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      {
                        staticClass: "fadeSelect",
                        attrs: { icon: "", dark: "" }
                      },
                      on
                    ),
                    [_vm._v("mdi-settings")]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item",
                {
                  attrs: { disabled: _vm.item.Tier === 3 },
                  on: {
                    click: function($event) {
                      return _vm.upgradeTier()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-3" },
                    [_c("v-icon", [_vm._v("mdi-arrow-up-thick")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Upgrade Tier")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  attrs: { disabled: _vm.item.Tier === 1 },
                  on: {
                    click: function($event) {
                      return _vm.downgradeTier()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-2" },
                    [_c("v-icon", [_vm._v("mdi-arrow-down-thick")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Downgrade Tier")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.cName.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-2" },
                    [_c("v-icon", [_vm._v("mdi-circle-edit-outline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [_c("v-list-item-title", [_vm._v("Set Custom Name")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$refs.cDesc.show()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "ma-0 mr-2 mt-2" },
                    [_c("v-icon", [_vm._v("mdi-circle-edit-outline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v("Set Custom Description")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$emit("remove-feature", $event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "ma-0 mr-2 mt-2" },
                        [
                          _c("v-icon", { attrs: { color: "error" } }, [
                            _vm._v("mdi-delete")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              "Remove " + _vm._s(_vm.item.Feature.FeatureType)
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-string-edit-dialog", {
        ref: "cName",
        attrs: {
          placeholder: _vm.item.Feature.Name,
          label: "Custom Item Name"
        },
        on: {
          save: function($event) {
            _vm.item.Name = $event
          },
          reset: function($event) {
            _vm.item.Name = _vm.item.Feature.Name
          }
        }
      }),
      _vm._v(" "),
      _c("cc-string-edit-dialog", {
        ref: "cDesc",
        attrs: {
          placeholder: _vm.item.Feature.Description,
          label: "Custom Item Description"
        },
        on: {
          save: function($event) {
            _vm.item.Description = $event
          },
          reset: function($event) {
            _vm.item.Description = ""
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }