var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-major-btn",
    {
      attrs: {
        icon: "mdi-database",
        color: "damage--energy",
        name: "Vault Import",
        small: "",
        disabled: _vm.disabled
      },
      on: {
        clicked: function($event) {
          _vm.dialog = true
        }
      }
    },
    [
      _c(
        "import-dialog",
        {
          attrs: {
            pilot: _vm.importPilot,
            error: _vm.error,
            loading: _vm.cloudLoading
          },
          on: { cancel: _vm.cancelImport, confirm: _vm.confirmImport },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              dark: "",
              autofocus: "",
              label: "UND IDENT ID",
              placeholder: "Input Pilot Share ID",
              outlined: "",
              "append-outer-icon": "mdi-cloud-search",
              loading: _vm.cloudLoading
            },
            on: {
              "click:append-outer": _vm.cloudImport,
              keypress: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.cloudImport($event)
              }
            },
            model: {
              value: _vm.importID,
              callback: function($$v) {
                _vm.importID = $$v
              },
              expression: "importID"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.missingContentWarning,
            callback: function($$v) {
              _vm.missingContentWarning = $$v
            },
            expression: "missingContentWarning"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "text-center" }, [
                _c("br"),
                _vm._v(" "),
                _c("p", { staticClass: "heading h3 accent--text" }, [
                  _vm._v(
                    "\n          WARNING: The imported Pilot requires the following content packs that are not currently\n          installed:\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", {
                  staticClass: "effect-text text-center",
                  domProps: { innerHTML: _vm._s(_vm.missingContent) }
                }),
                _vm._v(" "),
                _c("p", { staticClass: "text--text" }, [
                  _vm._v(
                    "\n          This Pilot cannot be imported until the missing content packs are installed and\n          activated.\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "primary" },
                      on: { click: _vm.cancelImport }
                    },
                    [_vm._v("Abort Import")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }