var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      !_vm.encounter
        ? _c(
            "v-row",
            {
              staticStyle: { width: "100%", height: "100%" },
              attrs: { align: "center", justify: "center" }
            },
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c(
                  "span",
                  { staticClass: "heading h1 subtle--text text--lighten-2" },
                  [_vm._v("no encounter selected")]
                )
              ])
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "mt-n6", attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c(
                      "span",
                      { staticClass: "heading mech" },
                      [
                        _c(
                          "cc-short-string-editor",
                          {
                            attrs: { large: "" },
                            on: {
                              set: function($event) {
                                _vm.encounter.Name = $event
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "max-width": "90%",
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                  "text-overflow": "ellipsis"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.encounter.Name) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto text-center mt-n1",
                      attrs: { cols: "2" }
                    },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            title: "Power Rating",
                            content:
                              "The Power Rating is an attempt to calculate the relative strength of an NPC (or encounters’ worth of NPCs) based on tier and applied templates, compared to mission’s Pilot and their current level. It should, generally, produce results more or less in line with the Balancing Combat section on pp. 283 of the LANCER Core Book.<br> That said, this is an experimental feature that is still very heavily in development, and does not (yet) always produce reliable results. Moreover, this tool doesn’t consider NPC or player team composition, synergies, strengths, and weaknesses. Nor does this tool consider map layout, mission objectives, or reinforcement schedules.<br>While we will continue to work on this tool to produce more accurate, actionable results, please use it only as a general indicator of relative NPC strength."
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "active--text heading h1" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.encounter.Power.toString().padStart(
                                      4,
                                      "0"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "overline mt-n3" }, [
                            _vm._v("TOTAL POWER RATING")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-combobox", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          multiple: "",
                          label: "User Labels",
                          items: _vm.labels
                        },
                        model: {
                          value: _vm.encounter.Labels,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter, "Labels", $$v)
                          },
                          expression: "encounter.Labels"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-combobox", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Campaign",
                          items: _vm.campaigns
                        },
                        model: {
                          value: _vm.encounter.Campaign,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter, "Campaign", $$v)
                          },
                          expression: "encounter.Campaign"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "cc-title",
                { staticClass: "mb-3", attrs: { small: "" } },
                [
                  _vm._v("\n      Narrative Description\n      "),
                  _c("cc-text-editor", {
                    attrs: {
                      label: "Edit Narrative Description",
                      original: _vm.encounter.NarrativeNotes
                    },
                    on: {
                      save: function($event) {
                        _vm.encounter.NarrativeNotes = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.encounter.NarrativeNotes,
                    expression: "encounter.NarrativeNotes"
                  }
                ]
              }),
              _vm._v(" "),
              _c(
                "cc-title",
                { staticClass: "mb-3", attrs: { small: "" } },
                [
                  _vm._v("\n      Location\n      "),
                  _c("cc-text-editor", {
                    attrs: {
                      label: "Edit Location Description",
                      original: _vm.encounter.Location
                    },
                    on: {
                      save: function($event) {
                        _vm.encounter.Location = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "mt-n2", attrs: { dense: "", align: "start" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c("p", {
                        directives: [
                          {
                            name: "html-safe",
                            rawName: "v-html-safe",
                            value: _vm.encounter.Location,
                            expression: "encounter.Location"
                          }
                        ]
                      }),
                      _vm._v(" "),
                      _c("v-divider", { staticClass: "my-3" }),
                      _vm._v(" "),
                      _c("v-combobox", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: "Environment",
                          items: _vm.environments
                        },
                        on: {
                          change: function($event) {
                            return _vm.setEnvironment()
                          }
                        },
                        model: {
                          value: _vm.encounter.Environment,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter, "Environment", $$v)
                          },
                          expression: "encounter.Environment"
                        }
                      }),
                      _vm._v(" "),
                      _vm.encounter.Environment !== "Nominal"
                        ? _c("v-textarea", {
                            staticClass: "mt-n4",
                            attrs: {
                              filled: "",
                              label: "Environment Detail",
                              "auto-grow": "",
                              "hide-details": "",
                              rows: "2"
                            },
                            model: {
                              value: _vm.encounter.EnvironmentDetails,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.encounter,
                                  "EnvironmentDetails",
                                  $$v
                                )
                              },
                              expression: "encounter.EnvironmentDetails"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", outlined: "" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-1" },
                            [
                              _vm.encounter.Map
                                ? _c("v-img", {
                                    key: _vm.encounter.Map,
                                    attrs: {
                                      src: _vm.encounter.Map,
                                      "aspect-ratio": "1"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    outlined: "",
                                    small: "",
                                    block: "",
                                    color: "secondary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$refs.imageSelector.open()
                                    }
                                  }
                                },
                                [
                                  !_vm.encounter.Map
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-plus")]
                                          ),
                                          _vm._v(
                                            "\n                Add Encounter Map\n              "
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-circle-edit-outline")]
                                          ),
                                          _vm._v(
                                            "\n                Edit Encounter Map\n              "
                                          )
                                        ],
                                        1
                                      )
                                ]
                              ),
                              _vm._v(" "),
                              _c("cc-image-selector-web", {
                                ref: "imageSelector",
                                attrs: { item: _vm.encounter, type: "map" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("cc-title", { staticClass: "mb-3", attrs: { small: "" } }, [
                _vm._v("\n      SITREP\n    ")
              ]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  "item-text": "name",
                  outlined: "",
                  dense: "",
                  label: "Engagement Type",
                  items: _vm.sitreps
                },
                on: {
                  change: function($event) {
                    _vm.encounter.Sitrep = _vm.sitreps.find(function(x) {
                      return x.name === _vm.selRep
                    })
                  }
                },
                model: {
                  value: _vm.selRep,
                  callback: function($$v) {
                    _vm.selRep = $$v
                  },
                  expression: "selRep"
                }
              }),
              _vm._v(" "),
              _c("v-textarea", {
                staticClass: "mt-n4",
                attrs: {
                  filled: "",
                  label: "Description",
                  "auto-grow": "",
                  "hide-details": "",
                  rows: "1"
                },
                model: {
                  value: _vm.encounter.Sitrep.description,
                  callback: function($$v) {
                    _vm.$set(_vm.encounter.Sitrep, "description", $$v)
                  },
                  expression: "encounter.Sitrep.description"
                }
              }),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-2" }),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("PC victory")
                      ]),
                      _vm._v(" "),
                      _c("v-textarea", {
                        staticClass: "mt-n1 mb-2",
                        attrs: {
                          filled: "",
                          dense: "",
                          label: "Victory Conditions",
                          "auto-grow": "",
                          "hide-details": "",
                          rows: "2"
                        },
                        model: {
                          value: _vm.encounter.Sitrep.pcVictory,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter.Sitrep, "pcVictory", $$v)
                          },
                          expression: "encounter.Sitrep.pcVictory"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "mx-2",
                    attrs: { vertical: "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("enemy victory")
                      ]),
                      _vm._v(" "),
                      _c("v-textarea", {
                        staticClass: "mt-n1 mb-2",
                        attrs: {
                          filled: "",
                          dense: "",
                          label: "Victory Conditions",
                          "auto-grow": "",
                          "hide-details": "",
                          rows: "2"
                        },
                        model: {
                          value: _vm.encounter.Sitrep.enemyVictory,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter.Sitrep, "enemyVictory", $$v)
                          },
                          expression: "encounter.Sitrep.enemyVictory"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "mx-2",
                    attrs: { vertical: "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("no victor")
                      ]),
                      _vm._v(" "),
                      _c("v-textarea", {
                        staticClass: "mt-n1 mb-2",
                        attrs: {
                          filled: "",
                          dense: "",
                          label: "Stalemate Conditions",
                          "auto-grow": "",
                          "hide-details": "",
                          rows: "2"
                        },
                        model: {
                          value: _vm.encounter.Sitrep.noVictory,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter.Sitrep, "noVictory", $$v)
                          },
                          expression: "encounter.Sitrep.noVictory"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("Deployment")
                      ]),
                      _vm._v(" "),
                      _c("v-textarea", {
                        staticClass: "mt-n1 mb-2 hide-label",
                        attrs: {
                          filled: "",
                          dense: "",
                          "auto-grow": "",
                          "hide-details": "",
                          rows: "2"
                        },
                        model: {
                          value: _vm.encounter.Sitrep.deployment,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter.Sitrep, "deployment", $$v)
                          },
                          expression: "encounter.Sitrep.deployment"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "mx-2",
                    attrs: { vertical: "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("Extraction")
                      ]),
                      _vm._v(" "),
                      _c("v-textarea", {
                        staticClass: "mt-n1 mb-2 hide-label",
                        attrs: {
                          filled: "",
                          dense: "",
                          "auto-grow": "",
                          "hide-details": "",
                          rows: "2"
                        },
                        model: {
                          value: _vm.encounter.Sitrep.extraction,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter.Sitrep, "extraction", $$v)
                          },
                          expression: "encounter.Sitrep.extraction"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("Control Zones")
                      ]),
                      _vm._v(" "),
                      _c("v-textarea", {
                        staticClass: "mt-n1 hide-label",
                        attrs: {
                          filled: "",
                          dense: "",
                          "auto-grow": "",
                          "hide-details": "",
                          rows: "2"
                        },
                        model: {
                          value: _vm.encounter.Sitrep.controlZone,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter.Sitrep, "controlZone", $$v)
                          },
                          expression: "encounter.Sitrep.controlZone"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    staticClass: "mx-2",
                    attrs: { vertical: "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("span", { staticClass: "heading h3 accent--text" }, [
                        _vm._v("Objective")
                      ]),
                      _vm._v(" "),
                      _c("v-textarea", {
                        staticClass: "mt-n1 hide-label",
                        attrs: {
                          filled: "",
                          dense: "",
                          "auto-grow": "",
                          "hide-details": "",
                          rows: "2"
                        },
                        model: {
                          value: _vm.encounter.Sitrep.objective,
                          callback: function($$v) {
                            _vm.$set(_vm.encounter.Sitrep, "objective", $$v)
                          },
                          expression: "encounter.Sitrep.objective"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("cc-title", { staticClass: "mt-3", attrs: { small: "" } }, [
                _vm._v("\n      COMBATANTS\n    ")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _c(
                      "fieldset",
                      [
                        _c(
                          "legend",
                          { staticClass: "heading h3 accent--text mx-2" },
                          [_vm._v("FORCES")]
                        ),
                        _vm._v(" "),
                        _vm.forces.enemy.length
                          ? _c("div", { staticClass: "caption ml-2" }, [
                              _vm._v("ENEMY")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.forces.enemy.length ? _c("v-divider") : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.forces.enemy, function(n, i) {
                          return _c("npc-chip", {
                            key: "fe_" + n.ID + "_" + i,
                            attrs: { npc: n },
                            on: {
                              remove: function($event) {
                                return _vm.encounter.RemoveNpc(n, "Enemy")
                              },
                              clone: function($event) {
                                return _vm.encounter.AddNpc(n, "Enemy")
                              }
                            }
                          })
                        }),
                        _vm._v(" "),
                        _vm.forces.allied.length
                          ? _c("div", { staticClass: "caption ml-2" }, [
                              _vm._v("ALLIED")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.forces.allied.length ? _c("v-divider") : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.forces.allied, function(n, i) {
                          return _c("npc-chip", {
                            key: "fa_" + n.ID + "_" + i,
                            attrs: { npc: n },
                            on: {
                              remove: function($event) {
                                return _vm.encounter.RemoveNpc(n, "Ally")
                              },
                              clone: function($event) {
                                return _vm.encounter.AddNpc(n, "Ally")
                              }
                            }
                          })
                        }),
                        _vm._v(" "),
                        _vm.forces.neutral.length
                          ? _c("div", { staticClass: "caption ml-2" }, [
                              _vm._v("NEUTRAL")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.forces.neutral.length ? _c("v-divider") : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.forces.neutral, function(n, i) {
                          return _c("npc-chip", {
                            key: "fn_" + n.ID + "_" + i,
                            attrs: { npc: n },
                            on: {
                              remove: function($event) {
                                return _vm.encounter.RemoveNpc(n, "Neutral")
                              },
                              clone: function($event) {
                                return _vm.encounter.AddNpc(n, "Neutral")
                              }
                            }
                          })
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mx-6" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "my-2",
                                attrs: {
                                  color: "accent",
                                  block: "",
                                  small: "",
                                  outlined: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.npcDialog.show()
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("mdi-plus")
                                ]),
                                _vm._v("\n              Add NPC\n            ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "4" } }, [
                    _c(
                      "fieldset",
                      [
                        _c(
                          "legend",
                          { staticClass: "heading h3 accent--text mx-2" },
                          [_vm._v("REINFORCEMENTS")]
                        ),
                        _vm._v(" "),
                        _vm.reinforcements.enemy.length
                          ? _c("div", { staticClass: "caption ml-2" }, [
                              _vm._v("ENEMY")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.forces.enemy.length ? _c("v-divider") : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.reinforcements.enemy, function(n, i) {
                          return _c("npc-chip", {
                            key: "re_" + n.ID + "_" + i,
                            attrs: { npc: n },
                            on: {
                              remove: function($event) {
                                return _vm.encounter.RemoveReinforcement(
                                  n,
                                  "Enemy"
                                )
                              },
                              clone: function($event) {
                                return _vm.encounter.AddReinforcement(
                                  n,
                                  "Enemy"
                                )
                              }
                            }
                          })
                        }),
                        _vm._v(" "),
                        _vm.reinforcements.allied.length
                          ? _c("div", { staticClass: "caption ml-2" }, [
                              _vm._v("ALLIED")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.forces.allied.length ? _c("v-divider") : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.reinforcements.allied, function(n, i) {
                          return _c("npc-chip", {
                            key: "ra_" + n.ID + "_" + i,
                            attrs: { npc: n },
                            on: {
                              remove: function($event) {
                                return _vm.encounter.RemoveReinforcement(
                                  n,
                                  "Ally"
                                )
                              },
                              clone: function($event) {
                                return _vm.encounter.AddReinforcement(n, "Ally")
                              }
                            }
                          })
                        }),
                        _vm._v(" "),
                        _vm.reinforcements.neutral.length
                          ? _c("div", { staticClass: "caption ml-2" }, [
                              _vm._v("NEUTRAL")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.forces.neutral.length ? _c("v-divider") : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.reinforcements.neutral, function(n, i) {
                          return _c("npc-chip", {
                            key: "rn_" + n.ID + "_" + i,
                            attrs: { npc: n },
                            on: {
                              remove: function($event) {
                                return _vm.encounter.RemoveReinforcement(
                                  n,
                                  "Neutral"
                                )
                              },
                              clone: function($event) {
                                return _vm.encounter.AddReinforcement(
                                  n,
                                  "Neutral"
                                )
                              }
                            }
                          })
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mx-6" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "my-2",
                                attrs: {
                                  color: "accent",
                                  block: "",
                                  small: "",
                                  outlined: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$refs.reinforcementDialog.show()
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("mdi-plus")
                                ]),
                                _vm._v("\n              Add NPC\n            ")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-3" }),
              _vm._v(" "),
              _c(
                "cc-title",
                { staticClass: "mb-3", attrs: { small: "" } },
                [
                  _vm._v("\n      GM Notes\n      "),
                  _c("cc-text-editor", {
                    attrs: {
                      label: "Edit GM Notes",
                      original: _vm.encounter.Note
                    },
                    on: {
                      save: function($event) {
                        _vm.encounter.Note = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.encounter.Note,
                    expression: "encounter.Note"
                  }
                ],
                key: _vm.encounter.Note.length
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "cc-solo-dialog",
                {
                  ref: "npcDialog",
                  attrs: {
                    "no-confirm": "",
                    title: "ADD NPC",
                    fullscreen: "",
                    "no-pad": ""
                  }
                },
                [
                  _c("npc-selector", {
                    on: {
                      select: function($event) {
                        return _vm.addNpc($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "cc-solo-dialog",
                {
                  ref: "reinforcementDialog",
                  attrs: {
                    "no-confirm": "",
                    title: "ADD NPC REINFORCEMENT",
                    fullscreen: "",
                    "no-pad": ""
                  }
                },
                [
                  _c("npc-selector", {
                    on: {
                      select: function($event) {
                        return _vm.addReinforcement($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }