var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-col",
            { staticClass: "pr-0", attrs: { cols: "12", md: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "pa-0",
                  attrs: {
                    tile: "",
                    block: "",
                    outlined: "",
                    "min-height": "calc(100% - 8px)",
                    color: _vm.color,
                    disabled: !_vm.isSelected && !_vm.isSelectable
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        _vm.isSelected ? "remove" : "add",
                        _vm.bonus
                      )
                    }
                  }
                },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        simple: "",
                        inline: "",
                        content: _vm.ttContent()
                      }
                    },
                    [
                      _vm.isSelected
                        ? _c(
                            "v-icon",
                            { attrs: { size: "80", color: "error" } },
                            [_vm._v("cci-difficulty")]
                          )
                        : _vm.isSelectable
                        ? _c(
                            "v-icon",
                            { attrs: { size: "80", color: "secondary" } },
                            [_vm._v("cci-accuracy")]
                          )
                        : _c(
                            "v-icon",
                            { attrs: { size: "80", color: "grey" } },
                            [_vm._v("mdi-lock")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "pl-0", attrs: { cols: "12", md: "" } },
        [_c("cc-core-bonus-item", { attrs: { bonus: _vm.bonus } })],
        1
      ),
      _vm._v(" "),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-col",
            { staticClass: "mb-4 mt-n2", attrs: { cols: "12", md: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    tile: "",
                    block: "",
                    dark: _vm.isSelectable || _vm.isSelected,
                    color: _vm.color,
                    disabled: !_vm.isSelected && !_vm.isSelectable
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit(
                        _vm.isSelected ? "remove" : "add",
                        _vm.bonus
                      )
                    }
                  }
                },
                [
                  _c("span", [
                    _vm.isSelected
                      ? _c(
                          "span",
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("cci-difficulty")
                            ]),
                            _vm._v("\n          Remove Bonus\n        ")
                          ],
                          1
                        )
                      : _vm.isSelectable
                      ? _c(
                          "span",
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("cci-accuracy")
                            ]),
                            _vm._v("\n          Add Bonus\n        ")
                          ],
                          1
                        )
                      : _c(
                          "span",
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("mdi-lock")
                            ]),
                            _vm._v("\n          Unavailable\n        ")
                          ],
                          1
                        )
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }