var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "7" } },
        [
          _c(
            "cc-titled-panel",
            {
              attrs: {
                title: "New Organization",
                icon: "mdi-account-group",
                color: "reserve--organization darken-2"
              }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          color: "accent",
                          label: "Name",
                          outlined: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.orgName,
                          callback: function($$v) {
                            _vm.orgName = $$v
                          },
                          expression: "orgName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          label: "Type",
                          color: "accent",
                          items: _vm.orgTypes,
                          outlined: "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.orgType,
                          callback: function($$v) {
                            _vm.orgType = $$v
                          },
                          expression: "orgType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-textarea", {
                attrs: {
                  label: "Purpose, goal, and organization details",
                  color: "accent",
                  "auto-grow": "",
                  rows: "2",
                  filled: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.orgDetails,
                  callback: function($$v) {
                    _vm.orgDetails = $$v
                  },
                  expression: "orgDetails"
                }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "heading h3" }, [
                _vm._v("Start with:")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                {
                  staticClass: "mx-4",
                  attrs: { dense: "", justify: "center" }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", md: "" }
                    },
                    [
                      !_vm.orgStart
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                block: "",
                                outlined: "",
                                color: "secondary"
                              },
                              on: {
                                click: function($event) {
                                  _vm.orgStart = "efficiency"
                                }
                              }
                            },
                            [
                              _vm._v("\n            Efficiency\n            "),
                              _c(
                                "cc-tooltip",
                                {
                                  attrs: {
                                    simple: "",
                                    inline: "",
                                    content:
                                      "How directly effective your organization is at what it does (a military\n              organization with high efficiency would be good at combat, for example).\n              <br />Efficiency can be used to perform activities related to your organization’s\n              purpose (science, military, etc). You can use these advantages as\n              <strong>reserves.</strong>"
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", right: "" } },
                                    [_vm._v("mdi-help-circle-outline")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c("div", [
                            _c("span", { staticClass: "heading h3" }, [
                              _vm._v(
                                "+ " +
                                  _vm._s(
                                    _vm.orgStart === "efficiency" ? "2" : "0"
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [_vm._v("Organization Efficiency")])
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$vuetify.breakpoint.mdAndUp,
                        expression: "$vuetify.breakpoint.mdAndUp"
                      }
                    ],
                    staticClass: "mx-5",
                    attrs: { vertical: "" }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", md: "" }
                    },
                    [
                      !_vm.orgStart
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                block: "",
                                outlined: "",
                                color: "secondary"
                              },
                              on: {
                                click: function($event) {
                                  _vm.orgStart = "influence"
                                }
                              }
                            },
                            [
                              _vm._v("\n            Influence\n            "),
                              _c(
                                "cc-tooltip",
                                {
                                  attrs: {
                                    simple: "",
                                    inline: "",
                                    content:
                                      "Influence is your organization’s size, reach, wealth, and reputation.\n              Influence be used to acquire assets, create opportunities, or sway public\n              opinion."
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", right: "" } },
                                    [_vm._v("mdi-help-circle-outline")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c("div", [
                            _c("span", { staticClass: "heading h3" }, [
                              _vm._v(
                                "+ " +
                                  _vm._s(
                                    _vm.orgStart === "influence" ? "2" : "0"
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", [_vm._v("Organization Influence")])
                          ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2 mt-n2",
                  attrs: {
                    block: "",
                    tile: "",
                    large: "",
                    color: "primary",
                    disabled: !_vm.orgName || !_vm.orgType || !_vm.orgStart
                  },
                  on: {
                    click: function($event) {
                      return _vm.add()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("cci-accuracy")
                  ]),
                  _vm._v("\n        Add Organization\n      ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }