var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: { text: "", color: "white", tile: "", to: "/pilot_management" }
        },
        [_vm._v("Pilot Roster")]
      ),
      _vm._v(" "),
      _vm.pilot
        ? _c("v-divider", {
            staticClass: "ml-2 mr-2",
            attrs: { vertical: "", dark: "" }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.pilot
        ? _c(
            "v-menu",
            {
              attrs: { "nudge-bottom": "35px", "open-on-hover": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                light: "",
                                tile: "",
                                color: "white",
                                elevation: "0"
                              }
                            },
                            on
                          ),
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.pilot.Callsign) +
                                "\n        "
                            ),
                            _c("v-icon", { attrs: { light: "" } }, [
                              _vm._v("arrow_drop_down")
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                1726253880
              )
            },
            [
              _vm._v(" "),
              _c(
                "v-list",
                { attrs: { "two-line": "", subheader: "" } },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: "/active/" + _vm.pilot.ID } },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "ma-0 mr-2 mt-3" },
                        [
                          _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v("cci-activate")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v("Active Mode")]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              "\n            Gameplay manager for running a pilot in LANCER sessions\n          "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.pilotSheet()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "ma-0 mr-2 mt-3" },
                        [
                          _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v("cci-pilot")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v("Pilot Sheet")]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              "\n            View, edit, and update this pilot's information\n          "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.mechHangar()
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        { staticClass: "ma-0 mr-2 mt-3" },
                        [
                          _c("v-icon", { attrs: { large: "" } }, [
                            _vm._v("cci-frame")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v("Mech Hangar")]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              "\n            Build mechs and manage this pilot's library of mech configurations\n          "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }