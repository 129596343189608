var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-rating", {
    staticClass: "d-inline",
    attrs: {
      hover: "",
      "x-large": "",
      dense: _vm.dense,
      length: _vm.max,
      readonly: "",
      color: _vm.color,
      "empty-icon": "mdi-hexagon-outline",
      "full-icon": "mdi-hexagon-slice-6",
      "background-color": "panel"
    },
    model: {
      value: _vm.model,
      callback: function($$v) {
        _vm.model = $$v
      },
      expression: "model"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }