var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-divider"),
      _vm._v(" "),
      _c("cc-title", { staticClass: "mb-2" }, [_vm._v("dice menu")]),
      _vm._v(" "),
      _c("cc-dice-menu"),
      _vm._v(" "),
      _c("cc-dice-menu", { attrs: { preset: "2d6+1", title: "preset 1" } }),
      _vm._v(" "),
      _c("cc-dice-menu", {
        attrs: {
          preset: "2d6+1d20+3d8-9",
          "preset-accuracy": -2,
          title: "preset 2"
        }
      }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("cc-title", { staticClass: "mb-2" }, [_vm._v("new talent ui")]),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("cc-talent", {
            attrs: {
              talent: _vm.exampleTalents[0],
              micro: "",
              rank: "2",
              selectable: ""
            }
          }),
          _vm._v(" "),
          _c("cc-talent", {
            attrs: { talent: _vm.exampleTalents[1], micro: "", rank: "1" }
          }),
          _vm._v(" "),
          _c("cc-talent", {
            attrs: { talent: _vm.exampleTalents[2], micro: "" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("cc-talent", {
            attrs: {
              talent: _vm.exampleTalents[0],
              small: "",
              rank: "2",
              selectable: ""
            }
          }),
          _vm._v(" "),
          _c("cc-talent", {
            attrs: { talent: _vm.exampleTalents[1], small: "", rank: "1" }
          }),
          _vm._v(" "),
          _c("cc-talent", {
            attrs: { talent: _vm.exampleTalents[2], small: "" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-talent", {
        attrs: {
          talent: _vm.exampleTalents[0],
          terse: "",
          rank: "1",
          selectable: ""
        }
      }),
      _vm._v(" "),
      _c("cc-talent", {
        attrs: { talent: _vm.exampleTalents[1], terse: "", rank: "3" }
      }),
      _vm._v(" "),
      _c("cc-talent", { attrs: { talent: _vm.exampleTalents[2], terse: "" } }),
      _vm._v(" "),
      _c("cc-talent", {
        attrs: { talent: _vm.exampleTalents[3], rank: "2", selectable: "" }
      }),
      _vm._v(" "),
      _c("cc-talent", { attrs: { talent: _vm.exampleTalents[0], rank: "1" } }),
      _vm._v(" "),
      _c("cc-talent", { attrs: { talent: _vm.exampleTalents[1] } }),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("cc-title", [_vm._v("typography")]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-5" },
        [
          _c("v-col", [
            _c("h1", { staticClass: "heading" }, [_vm._v("Heading 1")]),
            _vm._v(" "),
            _c("h2", { staticClass: "heading" }, [_vm._v("Heading 2")]),
            _vm._v(" "),
            _c("h3", { staticClass: "heading" }, [_vm._v("Heading 3")]),
            _vm._v(" "),
            _c("p", { staticClass: "heading table" }, [
              _vm._v("Table Heading")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "heading mech" }, [_vm._v("Mech Heading")]),
            _vm._v(" "),
            _c("p", { staticClass: "heading sub" }, [_vm._v("Subheading")])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("p", { staticClass: "body-text" }, [_vm._v("Body Text")]),
            _vm._v(" "),
            _c("ol", { staticClass: "body-text" }, [
              _c("li", [_vm._v("Ordered List Text")])
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("ul", { staticClass: "body-text" }, [
              _c("li", [_vm._v("Unordered List Text")])
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("p", { staticClass: "effect-text" }, [_vm._v("Effect Text")]),
            _vm._v(" "),
            _c("p", { staticClass: "fluff-text" }, [_vm._v("Fluff Text")]),
            _vm._v(" "),
            _c("p", { staticClass: "flavor-text" }, [_vm._v("Flavor Text")]),
            _vm._v(" "),
            _c("p", { staticClass: "stat-text" }, [_vm._v("Stat Text")]),
            _vm._v(" "),
            _c("p", { staticClass: "caption" }, [_vm._v("Caption")]),
            _vm._v(" "),
            _c("p", { staticClass: "overline" }, [_vm._v("Overline")])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("p", { staticClass: "ra-quiet" }, [_vm._v("RA Subtle Text")]),
            _vm._v(" "),
            _c("p", [_c("code", [_vm._v("Code Text")])]),
            _vm._v(" "),
            _c("p", [_c("code", { staticClass: "horus" }, [_vm._v("RA Text")])])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "ma-4" }),
      _vm._v(" "),
      _c("cc-title", [_vm._v("glyphs")]),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { "grid-list-xs": "" } },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            _vm._l(_vm.allIcons(), function(i) {
              return _c(
                "v-col",
                {
                  key: i,
                  staticStyle: { "min-width": "200px" },
                  attrs: { dense: "" }
                },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c("v-icon", { attrs: { size: "70px" } }, [
                            _vm._v("cci-" + _vm._s(i))
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "caption" }, [
                            _vm._v(_vm._s(i))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "ma-4" }),
      _vm._v(" "),
      _c("cc-title", [_vm._v("titles")]),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c("cc-title", { attrs: { small: "" } }, [
            _vm._v("cc-title element - small")
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("cc-title", [_vm._v("cc-title element")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("cc-title", { attrs: { large: "" } }, [
            _vm._v("cc-title element - large")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "ma-4" }),
      _vm._v(" "),
      _c("cc-title", [_vm._v("buttons")]),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c(
            "v-row",
            { staticClass: "my-2" },
            [
              _c(
                "v-col",
                [
                  _c("cc-btn", { attrs: { small: "", color: "primary" } }, [
                    _vm._v("small button")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("cc-btn", { attrs: { color: "secondary" } }, [
                    _vm._v("regular button")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("cc-btn", { attrs: { large: "", color: "info" } }, [
                    _vm._v("large button")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("cc-btn", { attrs: { "x-large": "", color: "success" } }, [
                    _vm._v("x-large button")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { staticClass: "my-2" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    { attrs: { block: "", color: "primary", tile: "" } },
                    [_vm._v("block button")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        color: "primary",
                        tile: "",
                        outlined: ""
                      }
                    },
                    [_vm._v("outlined block button")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    { attrs: { block: "", color: "primary", text: "" } },
                    [_vm._v("text block button")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("cc-title", [_vm._v("dialogs")]),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "text-center" },
        [
          _c(
            "v-row",
            { staticClass: "my-2" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "cc-dialog",
                    {
                      attrs: { small: "" },
                      on: {
                        confirm: function($event) {
                          return _vm.dialog1Confirm()
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button",
                          fn: function() {
                            return [_vm._v("small dialog")]
                          },
                          proxy: true
                        },
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v("Small Dialog Box (v-slot:title)")]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _vm._v("\n          Dialog contents\n        ")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "cc-dialog",
                    {
                      on: {
                        confirm: function($event) {
                          return _vm.dialog1Confirm()
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button",
                          fn: function() {
                            return [_vm._v("dialog")]
                          },
                          proxy: true
                        },
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v("Dialog Box (v-slot:title)")]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _vm._v("\n          Dialog contents\n        ")
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "cc-dialog",
                    {
                      attrs: { large: "" },
                      on: {
                        confirm: function($event) {
                          return _vm.dialog1Confirm()
                        }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "button",
                          fn: function() {
                            return [_vm._v("large dialog")]
                          },
                          proxy: true
                        },
                        {
                          key: "title",
                          fn: function() {
                            return [_vm._v("Large Dialog Box (v-slot:title)")]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _vm._v(" "),
                      _vm._v("\n          Dialog contents\n        ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-row", { staticClass: "my-2" }, [_c("v-col")], 1)
        ],
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("cc-title", [_vm._v("Global Notifier")]),
      _vm._v(" "),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-text-field", {
                    staticClass: "pt-0 mt-0",
                    model: {
                      value: _vm.notificationText,
                      callback: function($$v) {
                        _vm.notificationText = $$v
                      },
                      expression: "notificationText"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "3" } },
                [
                  _c("v-select", {
                    staticClass: "pt-0 mt-0",
                    attrs: { items: _vm.notificationTypes },
                    model: {
                      value: _vm.notificationType,
                      callback: function($$v) {
                        _vm.notificationType = $$v
                      },
                      expression: "notificationType"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.notificationText },
                      on: { click: _vm.doNotify }
                    },
                    [_vm._v("Notify")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-btn", { attrs: { text: "", "x-large": "", to: "/" } }, [
        _vm._v("back")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }