var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c(
        "cc-solo-dialog",
        {
          ref: "loginModal",
          attrs: { large: "", "no-confirm": "", title: "CLOUD ACCOUNT" }
        },
        [_c("sign-in")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "optionsModal",
          attrs: {
            large: "",
            "no-confirm": "",
            "no-pad": "",
            "no-title-clip": "",
            title: "Options & User Profile"
          }
        },
        [_c("options-page")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "aboutModal",
          attrs: { large: "", "no-confirm": "", title: "About" }
        },
        [_c("about-page")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "creditsModal",
          attrs: { large: "", "no-confirm": "", title: "Credits" }
        },
        [_c("credits-page")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "helpModal",
          attrs: { large: "", "no-confirm": "", title: "Help" }
        },
        [_c("help-page")],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "contentModal",
          attrs: {
            "no-title-clip": "",
            "no-pad": "",
            large: "",
            "no-confirm": "",
            title: "Manage Content Packs"
          }
        },
        [_c("content-page")],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "div",
            { staticClass: "mt-n5" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "resize-text",
                      rawName: "v-resize-text",
                      value: { ratio: 0.75 },
                      expression: "{ ratio: 0.75 }"
                    }
                  ],
                  staticClass: "heading mech mt-n5 text-center",
                  staticStyle: { "letter-spacing": "8px" }
                },
                [_vm._v("\n        COMP/CON\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "fadeSelect mt-n2",
                  attrs: {
                    target: "_blank",
                    href: "https://www.netlify.com",
                    "x-small": "",
                    outlined: "",
                    block: ""
                  }
                },
                [_vm._v("\n        deploys by netlify\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "my-1 px-2 primary py-2",
              attrs: { dense: "", align: "center" }
            },
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("div", { staticClass: "flavor-text white--text" }, [
                  _c("span", [_vm._v("v." + _vm._s(_vm.$appVersion))])
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto ml-auto" } },
                [_c("update-checker", { attrs: { small: "" } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "", justify: "space-around" } },
            [
              _c("mobile-btn", {
                attrs: {
                  icon: "mdi-book",
                  title: "Compendium",
                  text: "Equipment Database",
                  to: "/compendium"
                }
              }),
              _vm._v(" "),
              _c("mobile-btn", {
                attrs: {
                  icon: "cci-pilot",
                  title: "Roster",
                  text: "Manage Pilots",
                  to: "/pilot_management"
                }
              }),
              _vm._v(" "),
              _c("mobile-btn", {
                attrs: {
                  icon: "mdi-flask-empty-plus-outline",
                  title: "Content Packs",
                  text: "Manage Content"
                },
                on: {
                  clicked: function($event) {
                    return _vm.$refs.contentModal.show()
                  }
                }
              }),
              _vm._v(" "),
              _c("mobile-btn", {
                attrs: {
                  icon: _vm.userstore.IsLoggedIn
                    ? "mdi-account-check"
                    : "mdi-account-off-outline",
                  title: _vm.userstore.IsLoggedIn ? "Connected" : "Log In",
                  text: "COMP/CON Account"
                },
                on: {
                  clicked: function($event) {
                    return _vm.$refs.loginModal.show()
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { height: "40px" } }),
          _vm._v(" "),
          _c(
            "v-footer",
            { attrs: { color: "primary", fixed: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { "x-small": "", dark: "", outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.optionsModal.show()
                    }
                  }
                },
                [_vm._v("Options")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { "x-small": "", dark: "", outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.aboutModal.show()
                    }
                  }
                },
                [_vm._v("About")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { "x-small": "", dark: "", outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.creditsModal.show()
                    }
                  }
                },
                [_vm._v("Credits")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { "x-small": "", dark: "", outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.helpModal.show()
                    }
                  }
                },
                [_vm._v("Help")]
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    target: "_blank",
                    href: "https://www.patreon.com/compcon",
                    color: "warning",
                    "x-small": "",
                    dark: "",
                    outlined: ""
                  }
                },
                [_vm._v("\n        Support\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }