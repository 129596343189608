var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { staticClass: "heading h2 text--text" }, [
        _vm._v(_vm._s(_vm.cs.Name))
      ]),
      _vm._v(" "),
      _c("p", {
        directives: [
          {
            name: "html-safe",
            rawName: "v-html-safe",
            value: _vm.cs.Description,
            expression: "cs.Description"
          }
        ],
        staticClass: "flavor-text px-3"
      }),
      _vm._v(" "),
      _vm.cs.PassiveName
        ? _c("div", [
            _c("span", { staticClass: "heading sub" }, [
              _vm._v(
                "PASSIVE " +
                  _vm._s(_vm.cs.PassiveName ? " - " + _vm.cs.PassiveName : "")
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "light-panel pa-2 clipped mb-2 mx-3" },
              [
                _vm.cs.PassiveEffect
                  ? _c("p", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: _vm.cs.PassiveEffect,
                          expression: "cs.PassiveEffect"
                        }
                      ],
                      staticClass: "body-text mb-1 px-3"
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "", justify: "center" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      _vm._l(_vm.cs.PassiveActions, function(a, i) {
                        return _c("cc-action", {
                          key: _vm.cs.Name + "_action_" + i,
                          attrs: {
                            action: a,
                            panel: _vm.$vuetify.breakpoint.lgAndUp
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("span", { staticClass: "heading sub" }, [
              _vm._v(
                "ACTIVE " +
                  _vm._s(_vm.cs.ActiveName ? " - " + _vm.cs.ActiveName : "")
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _vm.cs.Duration !== "Mission" ||
              _vm.cs.Duration !== "Unlimited" ||
              _vm.cs.Duration !== "Mission"
                ? _c(
                    "v-chip",
                    { attrs: { small: "", label: "", outlined: "" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.cs.Duration.toUpperCase()) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-chip",
                {
                  attrs: {
                    small: "",
                    label: "",
                    dark: "",
                    color: "action--" + _vm.cs.Activation.toLowerCase()
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.cs.Activation.toUpperCase()) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "light-panel pa-2 clipped mb-2 mx-3 mt-1" },
        [
          _c("p", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.cs.ActiveEffect,
                expression: "cs.ActiveEffect"
              }
            ],
            staticClass: "body-text mb-1 px-3"
          }),
          _vm._v(" "),
          _vm._l(_vm.cs.ActiveActions, function(a, i) {
            return _c("cc-action", {
              key: _vm.cs.Name + "_action_" + i,
              staticClass: "ma-2",
              attrs: { action: a, panel: _vm.$vuetify.breakpoint.lgAndUp }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.cs.IntegratedEquipment.length || _vm.cs.Deployables.length
        ? _c("span", { staticClass: "heading sub" }, [
            _vm._v("\n    CORE INTEGRATED EQUIPMENT\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.cs.IntegratedEquipment.length
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            _vm._l(_vm.cs.IntegratedEquipment, function(x, i) {
              return _c(
                "v-col",
                {
                  key: _vm.cs.Name + "_integrated_" + i,
                  attrs: { cols: "auto" }
                },
                [
                  _c("cc-integrated-info", {
                    attrs: { item: x, panel: _vm.$vuetify.breakpoint.lgAndUp }
                  })
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.cs.Deployables.length
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            _vm._l(_vm.cs.Deployables, function(d, i) {
              return _c(
                "v-col",
                {
                  key: _vm.cs.Name + "_deployable_" + i,
                  attrs: { cols: "auto" }
                },
                [
                  _c("cc-deployable-info", {
                    staticClass: "ma-2",
                    attrs: {
                      deployable: d,
                      panel: _vm.$vuetify.breakpoint.lgAndUp
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("cc-tags", { attrs: { tags: _vm.cs.Tags } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }