var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { dense: _vm.readonly } },
        [
          _c("pilot-armor-card", {
            attrs: {
              item: _vm.pilot.Loadout.Armor[0],
              readonly: _vm.readonly,
              exotics: _vm.exotics("PilotArmor")
            },
            on: {
              equip: function($event) {
                return _vm.setArmor($event)
              },
              remove: function($event) {
                return _vm.setArmor(null)
              }
            }
          }),
          _vm._v(" "),
          _c("v-divider", {
            staticClass: "mx-4 my-1",
            attrs: { vertical: _vm.$vuetify.breakpoint.mdAndUp }
          }),
          _vm._v(" "),
          _vm._l(_vm.weapons, function(w, i) {
            return _c("pilot-weapon-card", {
              key: "pgwi_" + i,
              attrs: {
                item: w,
                readonly: _vm.readonly,
                exotics: _vm.exotics("PilotWeapon")
              },
              on: {
                equip: function($event) {
                  return _vm.setWeapon($event, i)
                },
                remove: function($event) {
                  return _vm.setWeapon(null, i)
                }
              }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.extendedWeapons, function(w, i) {
            return _c("pilot-weapon-card", {
              key: "pgewi_" + i,
              attrs: {
                item: w,
                readonly: _vm.readonly,
                extended: true,
                exotics: _vm.exotics("PilotWeapon")
              },
              on: {
                equip: function($event) {
                  return _vm.setWeapon($event, i, true)
                },
                remove: function($event) {
                  return _vm.setWeapon(null, i, true)
                }
              }
            })
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _vm._l(_vm.gear, function(g, i) {
            return _c("pilot-gear-card", {
              key: "pgi_" + i,
              attrs: {
                item: g,
                readonly: _vm.readonly,
                exotics: _vm.exotics("PilotGear")
              },
              on: {
                equip: function($event) {
                  return _vm.setGear($event, i)
                },
                remove: function($event) {
                  return _vm.setGear(null, i)
                }
              }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.extendedGear, function(g, i) {
            return _c("pilot-gear-card", {
              key: "pegi_" + i,
              attrs: {
                item: g,
                readonly: _vm.readonly,
                extended: true,
                exotics: _vm.exotics("PilotGear")
              },
              on: {
                equip: function($event) {
                  return _vm.setGear($event, i, true)
                },
                remove: function($event) {
                  return _vm.setGear(null, i, true)
                }
              }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }