



















































import Vue from 'vue'

export default Vue.extend({
  name: 'cb-mount-menu',
  props: {
    mech: {
      type: Object,
      required: true,
    },
    mount: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasEffect(): boolean {
      return this.mount.Bonuses.length
    },
    color(): string {
      return this.mech.Frame.Manufacturer.GetColor(this.$vuetify.theme.dark)
    },
    visible(): boolean {
      return this.mech.AvailableBonuses.length || this.mount.Bonuses.length
    },
  },
})
