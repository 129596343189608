var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav-body elevation-10" },
    [
      _c("div", { attrs: { id: "cap" } }),
      _vm._v(" "),
      _vm.actor.Activations > 0
        ? _c(
            "cc-nav-item",
            {
              on: {
                clicked: function($event) {
                  _vm.actor.Activations -= 1
                }
              }
            },
            [
              _c(
                "cc-tooltip",
                { attrs: { simple: "", content: "End Turn" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "end-turn mt-n2 pl-n2",
                      attrs: { size: "50", color: "white" }
                    },
                    [_vm._v("\n        cci-activate\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actor.Activations > 0
        ? _c("div", { attrs: { id: "divider-2" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "cc-nav-item",
        {
          on: {
            clicked: function($event) {
              _vm.noteSheet = true
            }
          }
        },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "GM Notepad" } },
            [_c("v-icon", [_vm._v("mdi-note")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-nav-item",
        {
          on: {
            clicked: function($event) {
              return _vm.$refs.infoDialog.show()
            }
          }
        },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Encounter Information" } },
            [_c("v-icon", [_vm._v("mdi-map")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-nav-item",
        {
          on: {
            clicked: function($event) {
              return _vm.$refs.reinforcementDialog.show()
            }
          }
        },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Reinforcements" } },
            [_c("v-icon", [_vm._v("mdi-plus-circle-outline")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "divider" } }),
      _vm._v(" "),
      _c(
        "cc-nav-item",
        [
          _vm.actor
            ? _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                { attrs: { large: "", color: "white" } },
                                on
                              ),
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.actor.Frame
                                        ? "cci-pilot"
                                        : _vm.actor.Class.RoleIcon
                                    ) +
                                    "\n        "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2643460345
                  )
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    [
                      !_vm.actor.Defeated
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.reactionDialog = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "ma-0 mr-2 mt-2" },
                                [_c("v-icon", [_vm._v("cci-reaction")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Stage Custom Reaction")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.actor.Defeated
                        ? _c(
                            "v-list-item",
                            {
                              on: {
                                click: function($event) {
                                  _vm.removeDialog = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                { staticClass: "ma-0 mr-2 mt-2" },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Remove from Battle")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.repairDialog = true
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-2" },
                            [_c("v-icon", [_vm._v("cci-repair")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Full Repair")])],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.deleteDialog = true
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            { staticClass: "ma-0 mr-2 mt-2" },
                            [
                              _c("v-icon", { attrs: { color: "error" } }, [
                                _vm._v("mdi-delete")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Delete from Encounter")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "50%" },
              model: {
                value: _vm.reactionDialog,
                callback: function($$v) {
                  _vm.reactionDialog = $$v
                },
                expression: "reactionDialog"
              }
            },
            [
              _c(
                "cc-titled-panel",
                { attrs: { title: "Stage Reaction" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Reaction",
                      outlined: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.reaction,
                      callback: function($$v) {
                        _vm.reaction = $$v
                      },
                      expression: "reaction"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        large: "",
                        tile: "",
                        color: "secondary",
                        disabled: !_vm.reaction
                      },
                      on: {
                        click: function($event) {
                          return _vm.react()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          Stage Reaction: " +
                          _vm._s(_vm.reaction) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "50%" },
              model: {
                value: _vm.removeDialog,
                callback: function($$v) {
                  _vm.removeDialog = $$v
                },
                expression: "removeDialog"
              }
            },
            [
              _c(
                "cc-titled-panel",
                { attrs: { title: "Remove Actor from Battlefield" } },
                [
                  _c("v-combobox", {
                    attrs: {
                      label: "Reason",
                      items: _vm.remove,
                      outlined: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.reason,
                      callback: function($$v) {
                        _vm.reason = $$v
                      },
                      expression: "reason"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        large: "",
                        tile: "",
                        color: "secondary",
                        disabled: !_vm.reason
                      },
                      on: {
                        click: function($event) {
                          return _vm.removeActor()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          Remove from Battle (" +
                          _vm._s(_vm.reason ? _vm.reason : " . . . ") +
                          ")\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "50%" },
              model: {
                value: _vm.repairDialog,
                callback: function($$v) {
                  _vm.repairDialog = $$v
                },
                expression: "repairDialog"
              }
            },
            [
              _c(
                "cc-titled-panel",
                { attrs: { title: "WARNING: Full Repair" } },
                [
                  _vm._v(
                    "\n        This will restore the selected actor to a fully-repaired and refreshed state. Any current\n        stat or damage tracking will be lost. This will also return the actor to the battlefield,\n        if it had been removed. Are you sure you want to continue?\n        "
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        large: "",
                        tile: "",
                        color: "success darken-1"
                      },
                      on: {
                        click: function($event) {
                          return _vm.repair()
                        }
                      }
                    },
                    [_vm._v("\n          Confirm Full Repair\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { width: "50%" },
              model: {
                value: _vm.deleteDialog,
                callback: function($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog"
              }
            },
            [
              _c(
                "cc-titled-panel",
                { attrs: { title: "WARNING: Delete Actor" } },
                [
                  _vm._v(
                    "\n        Deleting this actor will remove it permanently from this mission. Any stat or record\n        tracking for this entity will be lost. Are you sure you want to continue?\n        "
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-2",
                      attrs: { block: "", large: "", tile: "", color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.deleteActor()
                        }
                      }
                    },
                    [_vm._v("\n          Confirm Deletion\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "infoDialog",
          attrs: {
            icon: "mdi-map",
            "no-confirm": "",
            title: "Mission Information",
            large: ""
          }
        },
        [
          _c("info-modal", {
            attrs: {
              mission: _vm.mission.Mission,
              encounter: _vm.encounter,
              step: _vm.mission.Step
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-bottom-sheet",
        {
          model: {
            value: _vm.noteSheet,
            callback: function($$v) {
              _vm.noteSheet = $$v
            },
            expression: "noteSheet"
          }
        },
        [
          _c(
            "v-sheet",
            { staticClass: "text-center", attrs: { height: "35vh" } },
            [
              _c("div", { staticClass: "primary white--text heading h3" }, [
                _vm._v("GM NOTES")
              ]),
              _vm._v(" "),
              _c("v-textarea", {
                staticClass: "mx-2",
                attrs: {
                  filled: "",
                  dense: "",
                  autofocus: "",
                  "no-resize": "",
                  rows: "13"
                },
                model: {
                  value: _vm.mission.Note,
                  callback: function($$v) {
                    _vm.$set(_vm.mission, "Note", $$v)
                  },
                  expression: "mission.Note"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "reinforcementDialog",
          attrs: {
            icon: "mdi-plus-circle-outline",
            "no-confirm": "",
            title: "Encounter Reinforcements",
            large: ""
          }
        },
        [
          _c("reinforcements-modal", {
            attrs: { mission: _vm.mission, encounter: _vm.encounter }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }