var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-solo-dialog",
    {
      ref: "dialog",
      attrs: {
        icon: "mdi-text-subject",
        large: "",
        "no-confirm": "",
        title: "Pilot Statblock"
      }
    },
    [
      _c(
        "v-card-text",
        [
          _c("v-select", {
            attrs: {
              items: _vm.pilot.Mechs,
              placeholder: "N/A",
              "item-text": "Name",
              "item-value": "ID",
              label: "Include Mech (optional)",
              outlined: "",
              clearable: "",
              "hide-details": ""
            },
            model: {
              value: _vm.mechSelect,
              callback: function($$v) {
                _vm.mechSelect = $$v
              },
              expression: "mechSelect"
            }
          }),
          _vm._v(" "),
          !!_vm.mechSelect
            ? _c(
                "div",
                [
                  _c("v-checkbox", {
                    attrs: { label: "Compact / Build Summary" },
                    model: {
                      value: _vm.buildSummary,
                      callback: function($$v) {
                        _vm.buildSummary = $$v
                      },
                      expression: "buildSummary"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-checkbox", {
            attrs: { label: "Enhance with Pilot NET Discord Emoji" },
            model: {
              value: _vm.discordEmoji,
              callback: function($$v) {
                _vm.discordEmoji = $$v
              },
              expression: "discordEmoji"
            }
          }),
          _vm._v(" "),
          _c("v-textarea", {
            staticClass: "flavor-text",
            attrs: {
              value: _vm.statblock,
              "auto-grow": "",
              readonly: "",
              outlined: "",
              filled: ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }