var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "", align: "start" } },
    [
      _c(
        "v-col",
        _vm._l(_vm.state.Log, function(l, i) {
          return _c(
            "v-row",
            { key: l.id + "_" + i, attrs: { dense: "" } },
            [
              _c("v-col", [
                _c("div", { staticClass: "caption subtle--text mb-n1" }, [
                  _vm._v(
                    "\n          MISSION " +
                      _vm._s(l.mission) +
                      " // ENCOUNTER " +
                      _vm._s(l.encounter) +
                      " // ROUND " +
                      _vm._s(l.round) +
                      " ::\n          " +
                      _vm._s(l.timestamp) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "flavor-text ma-0 ml-3 mb-2" }, [
                  _vm._v("\n          >//[\n          "),
                  _c("span", { staticClass: "accent--text" }, [
                    _vm._v(_vm._s(l.event))
                  ]),
                  _vm._v("\n          ]\n          "),
                  _c("span", { staticClass: "stark--text" }, [
                    _vm._v(_vm._s(l.detail))
                  ])
                ])
              ]),
              _vm._v(" "),
              l.undoAction
                ? _c(
                    "v-col",
                    {
                      staticClass: "ml-auto",
                      attrs: { cols: "auto", "align-self": "center" }
                    },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            content:
                              "Undo this action, refunding any cost it may have had"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              attrs: { "x-small": "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.undo(l.undoAction)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "", left: "" } }, [
                                _vm._v("mdi-reload")
                              ]),
                              _vm._v("\n            UNDO\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("v-col", { staticClass: "ml-6", attrs: { cols: "auto" } }, [
        _c("fieldset", [
          _c("legend", { staticClass: "heading h3 accent--text ml-1 px-2" }, [
            _vm._v("MISSION STATS")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pl-2 pr-4" }, [
            _c("div", { staticClass: "flavor-text" }, [
              _vm._v("\n          MOVES:\n          "),
              _c("b", { staticClass: "stark--text" }, [
                _vm._v(_vm._s(_vm.state.Stats.moves))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flavor-text" }, [
              _vm._v("\n          DAMAGE DEALT:\n          "),
              _c("b", { staticClass: "stark--text" }, [
                _vm._v(_vm._s(_vm.state.Stats.damage))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flavor-text" }, [
              _vm._v("\n          ENEMIES DESTROYED:\n          "),
              _c("b", { staticClass: "stark--text" }, [
                _vm._v(_vm._s(_vm.state.Stats.kills))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flavor-text" }, [
              _vm._v("\n          DAMAGE TAKEN:\n          "),
              _c("b", { staticClass: "stark--text" }, [
                _vm._v(_vm._s(_vm.state.Stats.hp_damage))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flavor-text" }, [
              _vm._v("\n          STRUCTURE LOST:\n          "),
              _c("b", { staticClass: "stark--text" }, [
                _vm._v(_vm._s(_vm.state.Stats.structure_damage))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flavor-text" }, [
              _vm._v("\n          HEAT TAKEN:\n          "),
              _c("b", { staticClass: "stark--text" }, [
                _vm._v(_vm._s(_vm.state.Stats.heat_damage))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flavor-text" }, [
              _vm._v("\n          REACTOR STRESS:\n          "),
              _c("b", { staticClass: "stark--text" }, [
                _vm._v(_vm._s(_vm.state.Stats.reactor_damage))
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }