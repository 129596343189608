var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticStyle: {
            height: "calc(100vh - 185px)",
            "overflow-y": "scroll"
          },
          attrs: { id: "scroll", dense: "" }
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { flat: "", outlined: "", height: "100%" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _vm._l(_vm.players, function(p) {
                        return _c("player-card", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: p === _vm.selected,
                              expression: "p === selected"
                            }
                          ],
                          key: p.ID,
                          attrs: { mech: p }
                        })
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.npcs, function(n, i) {
                        return _c("npc-card", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: n === _vm.selected,
                              expression: "n === selected"
                            }
                          ],
                          key: n.ID + i,
                          attrs: { npc: n }
                        })
                      }),
                      _vm._v(" "),
                      _vm.selected
                        ? _c("encounter-nav", {
                            attrs: {
                              mission: _vm.activeMission,
                              encounter: _vm.activeMission.Encounter,
                              actor: _vm.selected
                            },
                            on: {
                              "delete-actor": function($event) {
                                return _vm.deleteActor()
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.actors
        ? _c(
            "v-row",
            { staticStyle: { "min-height": "100px" }, attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "11" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "", outlined: "", height: "100%" } },
                    [
                      _c(
                        "v-slide-group",
                        {
                          staticStyle: { height: "calc(100% - 8px)" },
                          attrs: { mandatory: "", "center-active": "" },
                          model: {
                            value: _vm.selectedActor,
                            callback: function($$v) {
                              _vm.selectedActor = $$v
                            },
                            expression: "selectedActor"
                          }
                        },
                        [
                          _c("div", { staticStyle: { position: "relative" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "overline font-weight-bold",
                                staticStyle: {
                                  position: "absolute",
                                  top: "-2px"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              TURN PENDING\n            "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.initiative, function(a, i) {
                            return _c("slide-item", {
                              key: "i_" + a.ID + "_" + i,
                              attrs: { actor: a }
                            })
                          }),
                          _vm._v(" "),
                          _vm.finished.length
                            ? _c("v-divider", {
                                staticClass: "mx-4",
                                staticStyle: { "border-color": "darkgrey" },
                                attrs: { vertical: "" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticStyle: { position: "relative" } }, [
                            _vm.finished.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "overline font-weight-bold",
                                    staticStyle: {
                                      position: "absolute",
                                      top: "-2px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              TURN COMPLETE\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.finished, function(a, i) {
                            return _c("slide-item", {
                              key: "f_" + a.ID + "_" + i,
                              attrs: { actor: a, complete: "" }
                            })
                          }),
                          _vm._v(" "),
                          _vm.defeated.length
                            ? _c("v-divider", {
                                staticClass: "mx-4",
                                staticStyle: { "border-color": "darkgrey" },
                                attrs: { vertical: "" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticStyle: { position: "relative" } }, [
                            _vm.defeated.length
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "overline font-weight-bold",
                                    staticStyle: {
                                      position: "absolute",
                                      top: "-2px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              DEFEATED\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.defeated, function(a, i) {
                            return _c("slide-item", {
                              key: "d_" + a.ID + "_" + i,
                              attrs: { actor: a, defeated: "" }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "text-center", attrs: { cols: "1" } },
                [
                  _c("div", { staticClass: "heading h3" }, [
                    _vm._v("\n        ROUND\n        "),
                    _c("b", { staticClass: "accent--text" }, [
                      _vm._v(_vm._s(_vm.activeMission.Round))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "", tile: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.stageRoundEnd()
                        }
                      }
                    },
                    [_vm._v("End Round")]
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "my-2" }),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-x": "", left: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        block: "",
                                        small: "",
                                        color: "accent",
                                        outlined: ""
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n            Complete\n            "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n            Encounter\n          "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1453211727
                      )
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "text-center font-weight-bold" },
                            [
                              _vm._v(
                                "\n            This will conclude the encounter and progress the mission. This can not be undone. Are\n            you sure you want to continue?\n            "
                              ),
                              _c("v-divider", { staticClass: "my-2" }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { small: "", text: "" } },
                                    [_vm._v("CANCEL")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-auto",
                                      attrs: { small: "", color: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("finish")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                CONFIRM\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "endConfirmDialog",
          attrs: { title: "Confirm End Round" },
          on: {
            confirm: function($event) {
              return _vm.commitRoundEnd()
            }
          }
        },
        [
          _c("div", { staticClass: "flavor-text my-2" }, [
            _vm._v("The following actors still have activations remaining:")
          ]),
          _vm._v(" "),
          _vm._l(_vm.initiative, function(a) {
            return _c(
              "div",
              { key: "diag_" + a.ID, staticClass: "heading h3 text--text" },
              [
                _c("v-icon", { attrs: { left: "" } }, [_vm._v(_vm._s(a.Icon))]),
                _vm._v("\n      " + _vm._s(a.EncounterName) + "\n    ")
              ],
              1
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flavor-text my-2" }, [
            _vm._v(
              "\n      Ending this round will cause these actors to lose their unspent activations. Continue?\n    "
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }