var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-titled-panel",
    { attrs: { title: _vm.encounter.Name } },
    [
      _c(
        "v-row",
        { staticClass: "mt-n1", attrs: { dense: "" } },
        [
          _vm.encounter.Campaign
            ? _c("v-col", { attrs: { cols: "auto" } }, [
                _c("div", { staticClass: "overline" }, [_vm._v("CAMPAIGN")]),
                _vm._v("\n      " + _vm._s(_vm.encounter.Campaign) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.encounter.Labels.length
            ? _c(
                "v-col",
                [
                  _c("div", { staticClass: "overline" }, [_vm._v("LABELS")]),
                  _vm._v(" "),
                  _vm._l(_vm.encounter.Labels, function(l) {
                    return _c(
                      "v-chip",
                      { key: l, staticClass: "mx-1", attrs: { small: "" } },
                      [_vm._v(_vm._s(l))]
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "cc-tooltip",
                {
                  attrs: {
                    title: "Power Rating",
                    content:
                      "The Power Rating is an attempt to calculate the relative strength of an NPC (or encounters’ worth of NPCs) based on tier and applied templates, compared to mission’s Pilot and their current level. It should, generally, produce results more or less in line with the Balancing Combat section on pp. 283 of the LANCER Core Book.<br> That said, this is an experimental feature that is still very heavily in development, and does not (yet) always produce reliable results. Moreover, this tool doesn’t consider NPC or player team composition, synergies, strengths, and weaknesses. Nor does this tool consider map layout, mission objectives, or reinforcement schedules.<br>While we will continue to work on this tool to produce more accurate, actionable results, please use it only as a general indicator of relative NPC strength."
                  }
                },
                [
                  _c("div", [
                    _c("b", { staticClass: "caption" }, [
                      _vm._v("TOTAL POWER RATING")
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "heading h2 accent--text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.encounter.Power.toString().padStart(4, "0")
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.encounter.Note
        ? _c("p", {
            staticClass: "flavor-text mb-0",
            domProps: { innerHTML: _vm._s(_vm.encounter.Note) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "my-2", attrs: { dense: "", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [_c("v-divider", { staticClass: "mt-3" })],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "heading h3 accent--text px-2" }, [
            _vm._v("COMBATANTS")
          ]),
          _vm._v(" "),
          _c("v-col", [_c("v-divider", { staticClass: "mt-3" })], 1)
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mr-2 mt-n1 ml-2", attrs: { dense: "" } },
        [
          _vm.forces.enemy.length
            ? _c(
                "v-col",
                { staticClass: "caption ml-2" },
                [
                  _c("div", { staticClass: "stat-text red--text" }, [
                    _vm._v("ENEMY")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.forces.enemy, function(n, i) {
                    return _c("npc-chip", {
                      key: "fe_" + n.ID + "_" + i,
                      attrs: { npc: n, readonly: "" }
                    })
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.forces.allied.length
            ? _c("v-divider", { attrs: { vertical: "" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.forces.allied.length
            ? _c(
                "v-col",
                { staticClass: "caption ml-2" },
                [
                  _c("div", { staticClass: "stat-text blue--text" }, [
                    _vm._v("ALLIED")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.forces.allied, function(n, i) {
                    return _c("npc-chip", {
                      key: "fa_" + n.ID + "_" + i,
                      attrs: { npc: n, readonly: "" }
                    })
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.forces.allied.length || _vm.forces.enemy.length
            ? _c("v-divider", { attrs: { vertical: "" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.forces.neutral.length
            ? _c(
                "v-col",
                { staticClass: "caption ml-2" },
                [
                  _c(
                    "div",
                    { staticClass: "stat-text subtle--text text--darken-1" },
                    [_vm._v("NEUTRAL")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.forces.neutral, function(n, i) {
                    return _c("npc-chip", {
                      key: "fn_" + n.ID + "_" + i,
                      attrs: { npc: n, readonly: "" }
                    })
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "my-2", attrs: { dense: "", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [_c("v-divider", { staticClass: "mt-3" })],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "heading h3 accent--text px-2" }, [
            _vm._v("REINFORCEMENTS")
          ]),
          _vm._v(" "),
          _c("v-col", [_c("v-divider", { staticClass: "mt-3" })], 1)
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mr-2 mt-n1 ml-2", attrs: { dense: "" } },
        [
          _vm.reinforcements.enemy.length
            ? _c(
                "v-col",
                { staticClass: "caption ml-2" },
                [
                  _c("div", { staticClass: "stat-text red--text" }, [
                    _vm._v("ENEMY")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.reinforcements.enemy, function(n, i) {
                    return _c("npc-chip", {
                      key: "re_" + n.ID + "_" + i,
                      attrs: { npc: n, readonly: "" }
                    })
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.reinforcements.allied.length
            ? _c("v-divider", { attrs: { vertical: "" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.reinforcements.allied.length
            ? _c(
                "v-col",
                { staticClass: "caption ml-2" },
                [
                  _c("div", { staticClass: "stat-text blue--text" }, [
                    _vm._v("ALLIED")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.reinforcements.allied, function(n, i) {
                    return _c("npc-chip", {
                      key: "ra_" + n.ID + "_" + i,
                      attrs: { npc: n, readonly: "" }
                    })
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.reinforcements.allied.length || _vm.reinforcements.enemy.length
            ? _c("v-divider", { attrs: { vertical: "" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.reinforcements.neutral.length
            ? _c(
                "v-col",
                { staticClass: "caption ml-2" },
                [
                  _c(
                    "div",
                    { staticClass: "stat-text subtle--text text--darken-1" },
                    [_vm._v("NEUTRAL")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.reinforcements.neutral, function(n, i) {
                    return _c("npc-chip", {
                      key: "rn_" + n.ID + "_" + i,
                      attrs: { npc: n, readonly: "" }
                    })
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.readonly
        ? _c(
            "div",
            [
              _c("v-divider", { staticClass: "my-3" }),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { large: "", block: "", color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("select", _vm.encounter)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v("\n      Add Encounter\n    ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }