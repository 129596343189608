





















































































































import Vue from 'vue'
import { DiceRoller } from '@/class'

export default Vue.extend({
  name: 'tech-attack',
  props: {
    used: { type: Boolean },
    mech: { type: Object, required: true },
    action: { type: Object, required: true },
  },
  data: () => ({
    accuracy: 0,
    difficulty: 0,
    attackRoll: '',
    succeeded: false,
    failed: false,
  }),
  watch: {
    used: {
      immediate: true,
      deep: true,
      handler: function() {
        this.init()
      },
    },
  },
  methods: {
    init() {
      this.activated = false
      this.accuracy = 0
      this.difficulty = 0
      this.freeAction = false
      this.succeeded = false
      this.failed = false
      this.attackRoll = ''
    },
    registerTechRoll(roll) {
      Vue.set(this, 'attackRoll', roll)
      Vue.nextTick().then(() => this.$forceUpdate())
    },
    complete(success) {
      this.succeeded = success
      this.failed = !success
      this.$emit('techAttackComplete', this.succeeded)
    },
  },
})
