var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-dialog",
    { attrs: { "no-dismiss": "", color: _vm.action.Color, large: "" } },
    [
      _c(
        "span",
        {
          staticClass: "white--text",
          staticStyle: { width: "100%" },
          attrs: { slot: "button" },
          slot: "button"
        },
        [
          _c("v-icon", { attrs: { dark: "" } }, [
            _vm._v(_vm._s(_vm.action.Icon))
          ]),
          _vm._v("\n    " + _vm._s(_vm.action.Name) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        { attrs: { slot: "title" }, slot: "title" },
        [
          _c("v-icon", { attrs: { left: "", large: "", dark: "" } }, [
            _vm._v(_vm._s(_vm.action.Icon))
          ]),
          _vm._v("\n    " + _vm._s(_vm.action.Name) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-chip",
        {
          staticClass: "stat-text mt-4 mr-6",
          attrs: {
            slot: "title-items",
            color: "white",
            outlined: "",
            label: ""
          },
          slot: "title-items"
        },
        [_vm._v("\n    " + _vm._s(_vm.action.Frequency.ToString()) + "\n  ")]
      ),
      _vm._v(" "),
      _c("action-base", { attrs: { action: _vm.action } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }