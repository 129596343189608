var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mount
        ? _c(
            "div",
            _vm._l(_vm.mount.Bonuses, function(b) {
              return _c("cb-card", {
                key: _vm.mount.ID + "_bonus-" + b.ID,
                staticClass: "my-1",
                attrs: { bonus: b }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", [_vm._t("default")], 2),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _vm.item.ProfileTags
                ? _c("cc-tags", { attrs: { tags: _vm.item.ProfileTags } })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _vm.item.Mod && _vm.item.Mod.AddedTags
                ? _c("cc-tags", {
                    attrs: {
                      tags: _vm.item.Mod.AddedTags,
                      color: "mod darken-2"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-2", attrs: { "no-gutters": "" } },
        [
          _vm.item.Profiles && _vm.item.Profiles.length > 1
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("div", { staticClass: "overline" }, [
                    _vm._v("WEAPON PROFILES")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-tabs",
                    {
                      attrs: { grow: "", height: "30px" },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    _vm._l(_vm.item.Profiles, function(p) {
                      return _c("v-tab", { key: p.ID }, [
                        _c(
                          "span",
                          { staticClass: "accent--text font-weight-bold" },
                          [_vm._v(_vm._s(p.Name))]
                        )
                      ])
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { md: "12", lg: "10" } },
            [
              _vm.item.ProfileEffect
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { dense: "", outlined: "", color: "active" }
                    },
                    [
                      _c("div", { staticClass: "mb-n2 mt-n2" }, [
                        _c(
                          "div",
                          { staticClass: "overline stark--text my-n2" },
                          [_vm._v("EFFECT")]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: _vm.item.ProfileEffect,
                              expression: "item.ProfileEffect"
                            }
                          ],
                          staticClass: "text--text body-text mb-1 mr-2 ml-3"
                        })
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.ProfileOnAttack
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mt-2",
                      style: "opacity: " + (!_vm.attackRoll ? "0.4" : "1"),
                      attrs: { dense: "", outlined: "", color: "active" }
                    },
                    [
                      _c("div", { staticClass: "my-n2" }, [
                        _c(
                          "div",
                          { staticClass: "overline stark--text my-n2" },
                          [_vm._v("ON ATTACK")]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: _vm.item.ProfileOnAttack,
                              expression: "item.ProfileOnAttack"
                            }
                          ],
                          staticClass: "text--text body-text mb-1"
                        })
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.Mod
                ? _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    [
                      _c("active-mod-inset", {
                        attrs: {
                          mod: _vm.item.Mod,
                          mech: _vm.mech,
                          color: "mod",
                          action: ""
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("ammo-case-inset", {
                attrs: {
                  level: _vm.armoryLevel,
                  uses: _vm.state.AvailableAmmoUses
                },
                on: {
                  "set-cost": function($event) {
                    return _vm.setAmmoCost($event)
                  },
                  "set-damage": function($event) {
                    return _vm.setAmmoDamage($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.item.NoBonuses
        ? _c("cc-active-synergy", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.improv,
                expression: "!improv"
              }
            ],
            attrs: {
              locations: _vm.improv ? "improvised_attack" : "weapon",
              mech: _vm.mech,
              item: _vm.improv ? null : _vm.item
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { md: "12", lg: "10" } },
            [
              _vm.item.ProfileOnHit
                ? _c(
                    "v-alert",
                    {
                      style: "opacity: " + (!_vm.hit ? "0.4" : "1"),
                      attrs: {
                        dense: "",
                        outlined: "",
                        color: _vm.hit ? "accent" : "subtle"
                      }
                    },
                    [
                      _c("div", { staticClass: "mb-n2" }, [
                        _c(
                          "div",
                          { staticClass: "overline stark--text my-n2" },
                          [_vm._v("ON HIT")]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: _vm.item.ProfileOnHit,
                              expression: "item.ProfileOnHit"
                            }
                          ],
                          staticClass: "text--text body-text mb-1"
                        })
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.ProfileOnCrit
                ? _c(
                    "v-alert",
                    {
                      style: "opacity: " + (_vm.crit && _vm.hit ? "1" : "0.4"),
                      attrs: {
                        dense: "",
                        outlined: "",
                        text: "",
                        color: _vm.crit ? "secondary" : "subtle"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-n2 mr-2",
                          attrs: {
                            slot: "prepend",
                            color: _vm.crit ? "secondary" : "subtle",
                            large: ""
                          },
                          slot: "prepend"
                        },
                        [_vm._v("\n            cci-mech-weapon\n          ")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mb-n2" }, [
                        _c(
                          "div",
                          { staticClass: "overline stark--text my-n2" },
                          [_vm._v("ON CRITICAL HIT")]
                        ),
                        _vm._v(" "),
                        _c("p", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: _vm.item.ProfileOnCrit,
                              expression: "item.ProfileOnCrit"
                            }
                          ],
                          staticClass: "text--text body-text mb-1"
                        })
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-y-reverse-transition",
        [
          !_vm.confirmed
            ? _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-n5",
                          attrs: { lg: "auto", md: "12" }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "text-center mb-n3",
                              attrs: {
                                dense: "",
                                justify: "start",
                                align: "start"
                              }
                            },
                            [
                              _vm.item.Range
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "mr-8",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c("div", { staticClass: "overline" }, [
                                        _vm._v("Range")
                                      ]),
                                      _vm._v(" "),
                                      _c("cc-range-element", {
                                        staticClass: "d-inline",
                                        attrs: { range: _vm.getRange }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mx-8",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c("div", { staticClass: "overline mb-n2" }, [
                                    _vm._v("Attack Roll")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "heading text--text",
                                      staticStyle: { "font-size": "24pt" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-n1",
                                          attrs: { "x-large": "" }
                                        },
                                        [_vm._v("mdi-dice-d20-outline")]
                                      ),
                                      _vm._v(
                                        "\n                  + " +
                                          _vm._s(_vm.mech.AttackBonus) +
                                          "\n                "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mx-8",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c("div", { staticClass: "overline mb-n3" }, [
                                    _vm._v("vs. Target")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", {
                                    attrs: { "x-large": "" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.isSmart ? "cci-edef" : "cci-evasion"
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass:
                                      "overline font-weight-bold mt-n2",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.isSmart ? "E-Defense" : "Evasion"
                                      )
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.noDamageItem
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "ml-8",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c("div", { staticClass: "overline" }, [
                                        _vm._v("Damage")
                                      ]),
                                      _vm._v(" "),
                                      _c("cc-damage-element", {
                                        staticClass: "d-inline",
                                        attrs: {
                                          damage: _vm.getDamage,
                                          "type-override": _vm.ammoDamage
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "ml-auto",
                          attrs: { lg: "auto", md: "12" }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "", justify: "end" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "ml-auto px-12 mr-n10 panel dual-sliced",
                                  staticStyle: { height: "70px" },
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c("div", { staticClass: "overline pl-1" }, [
                                    _vm._v("Accuracy")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    staticClass: "hide-input-spinners",
                                    staticStyle: { width: "115px" },
                                    attrs: {
                                      type: "number",
                                      "append-outer-icon":
                                        "mdi-plus-circle-outline",
                                      "append-icon": "cci-accuracy",
                                      "prepend-icon":
                                        "mdi-minus-circle-outline",
                                      color: "accent",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    on: {
                                      "click:append-outer": function($event) {
                                        _vm.accuracy < 99
                                          ? (_vm.accuracy += 1)
                                          : ""
                                      },
                                      "click:prepend": function($event) {
                                        _vm.accuracy > _vm.minAccuracy
                                          ? (_vm.accuracy -= 1)
                                          : ""
                                      },
                                      change: function($event) {
                                        _vm.accuracy = parseInt($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.accuracy,
                                      callback: function($$v) {
                                        _vm.accuracy = $$v
                                      },
                                      expression: "accuracy"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-12 mr-n10 panel dual-sliced",
                                  staticStyle: { height: "70px" },
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c("div", { staticClass: "overline pl-1" }, [
                                    _vm._v("Difficulty")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    staticClass: "hide-input-spinners",
                                    staticStyle: { width: "115px" },
                                    attrs: {
                                      type: "number",
                                      "append-outer-icon":
                                        "mdi-plus-circle-outline",
                                      "append-icon": "cci-difficulty",
                                      "prepend-icon":
                                        "mdi-minus-circle-outline",
                                      color: "accent",
                                      dense: "",
                                      "hide-details": ""
                                    },
                                    on: {
                                      "click:append-outer": function($event) {
                                        _vm.difficulty < 99
                                          ? (_vm.difficulty += 1)
                                          : ""
                                      },
                                      "click:prepend": function($event) {
                                        _vm.difficulty > _vm.minDifficulty
                                          ? (_vm.difficulty -= 1)
                                          : ""
                                      },
                                      change: function($event) {
                                        _vm.difficulty = parseInt($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.difficulty,
                                      callback: function($$v) {
                                        _vm.difficulty = $$v
                                      },
                                      expression: "difficulty"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-12 panel dual-sliced",
                                  staticStyle: { height: "70px" },
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c("div", { staticClass: "overline pl-1" }, [
                                    _vm._v("Attack Roll")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "mr-n2 ml-n2" },
                                        [
                                          _c("cc-dice-menu", {
                                            attrs: {
                                              preset:
                                                "1d20+" + _vm.mech.AttackBonus,
                                              "preset-accuracy":
                                                _vm.accuracy - _vm.difficulty,
                                              title: "ATTACK ROLL"
                                            },
                                            on: {
                                              commit: function($event) {
                                                _vm.attackRoll = $event.total
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        [
                                          _c("v-text-field", {
                                            class:
                                              "hide-input-spinners ml-n3 " +
                                              (_vm.crit
                                                ? "font-weight-bold"
                                                : ""),
                                            staticStyle: {
                                              "max-width": "60px",
                                              "margin-top": "-0.5px"
                                            },
                                            attrs: {
                                              type: "number",
                                              color: _vm.crit
                                                ? "secondary"
                                                : "accent",
                                              dense: "",
                                              "hide-details": ""
                                            },
                                            model: {
                                              value: _vm.attackRoll,
                                              callback: function($$v) {
                                                _vm.attackRoll = $$v
                                              },
                                              expression: "attackRoll"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.crit
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "caption secondary--text font-weight-bold ml-8 my-n1"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  CRITICAL\n                "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.aux
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "auto",
                                        "align-self": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            large: "",
                                            tile: "",
                                            block: "",
                                            disabled: !_vm.attackRoll,
                                            color:
                                              (_vm.crit
                                                ? "secondary"
                                                : "action--free") +
                                              " " +
                                              (_vm.attackFree
                                                ? "lighten-1"
                                                : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.attackFree = !_vm.attackFree
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("cci-free-action")]
                                          ),
                                          _vm._v(
                                            "\n                  Attack\n                "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm.overwatch
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "auto",
                                        "align-self": "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            large: "",
                                            tile: "",
                                            block: "",
                                            disabled: !_vm.attackRoll,
                                            color:
                                              (_vm.crit
                                                ? "secondary"
                                                : "action--reaction") +
                                              " " +
                                              (_vm.attackFree
                                                ? "lighten-1"
                                                : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.attackFree = !_vm.attackFree
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("cci-reaction")]
                                          ),
                                          _vm._v(
                                            "\n                  Attack\n                "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-col",
                                    {
                                      staticClass: "ml-2 mt-n1",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "white--text",
                                          attrs: {
                                            large: "",
                                            tile: "",
                                            block: "",
                                            disabled:
                                              _vm.attackFree ||
                                              !_vm.attackRoll ||
                                              (!_vm.improv &&
                                                !_vm.state.IsSkirmishAvailable),
                                            color:
                                              (_vm.crit
                                                ? "secondary"
                                                : _vm.improv
                                                ? "action--full"
                                                : "action--quick") +
                                              " " +
                                              (_vm.attackQuick
                                                ? "lighten-1"
                                                : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.attackQuick = !_vm.attackQuick
                                            }
                                          }
                                        },
                                        [
                                          _vm.improv
                                            ? _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("mdi-hexagon-slice-6")]
                                              )
                                            : _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("mdi-hexagon-slice-3")]
                                              ),
                                          _vm._v(
                                            "\n                  Attack\n                "
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "white--text",
                                          attrs: {
                                            small: "",
                                            tile: "",
                                            block: "",
                                            disabled:
                                              _vm.attackQuick ||
                                              !_vm.attackRoll,
                                            color:
                                              "action--free " +
                                              (_vm.attackFree
                                                ? "lighten-1"
                                                : "")
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.attackFree = !_vm.attackFree
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "", small: "" } },
                                            [_vm._v("cci-free-action")]
                                          ),
                                          _vm._v(
                                            "\n                  Free Action\n                  "
                                          ),
                                          _c(
                                            "cc-tooltip",
                                            {
                                              attrs: {
                                                inline: "",
                                                content:
                                                  "Special rules or equipment may allow you to Skirmish as a Free Action. Using this button will commit the attack without spending a Quick Action this turn"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "fadeSelect",
                                                  attrs: {
                                                    right: "",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.item.ProfileHeatCost
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "overline error--text text-center"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  ALERT: This action will incur " +
                                                  _vm._s(
                                                    _vm.item.ProfileHeatCost
                                                  ) +
                                                  " heat\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-slide-x-reverse-transition",
                    [
                      _vm.attacked
                        ? _c(
                            "v-row",
                            { staticClass: "mt-n2" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "ml-auto",
                                  attrs: { md: "6", lg: "3", xl: "2" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "primary",
                                      attrs: {
                                        tile: "",
                                        block: "",
                                        color:
                                          (_vm.crit ? "secondary" : "primary") +
                                          " " +
                                          (_vm.hit ? "lighten-1" : ""),
                                        disabled: _vm.missed
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.hit = !_vm.hit
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                HIT\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { md: "6", lg: "3", xl: "2" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        tile: "",
                                        block: "",
                                        disabled: _vm.hit,
                                        color: _vm.missed ? "error" : ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.missed = !_vm.missed
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                MISSED\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "v-slide-x-reverse-transition",
                    [
                      _vm.hit || _vm.missed
                        ? _c(
                            "v-row",
                            { staticClass: "mt-1", attrs: { align: "center" } },
                            [
                              _c("v-col", {
                                staticClass: "ml-auto",
                                attrs: { cols: "auto" }
                              }),
                              _vm._v(" "),
                              _vm.hit && _vm.crit
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "cc-tooltip",
                                        {
                                          attrs: {
                                            content:
                                              "On a critical hit, all damage dice are rolled twice\n(including bonus damage) and the highest result from\neach source of damage is used."
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                "x-large": "",
                                                color: "secondary"
                                              }
                                            },
                                            [_vm._v("mdi-progress-alert")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "secondary--text" },
                                            [_vm._v("CRITICAL HIT")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hit && !_vm.noDamageItem
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "px-12 mr-n10 panel dual-sliced mt-n2",
                                      staticStyle: { height: "70px" },
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "overline mt-n2 pl-1" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.getDamage.length > 1
                                                  ? "Damage Rolls"
                                                  : "Damage Roll"
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        _vm._l(_vm.getDamage, function(d, i) {
                                          return _c(
                                            "v-col",
                                            {
                                              key: "rolled_damage_" + i,
                                              staticClass: "mr-n2 ml-n2"
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("cc-dice-menu", {
                                                        attrs: {
                                                          preset: d.Value,
                                                          title:
                                                            d.Type +
                                                            " DAMAGE ROLL",
                                                          overkill:
                                                            _vm.overkill,
                                                          critical: _vm.crit
                                                        },
                                                        on: {
                                                          commit: function(
                                                            $event
                                                          ) {
                                                            return _vm.setDamage(
                                                              i,
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c("v-text-field", {
                                                        class:
                                                          "hide-input-spinners ml-n3 " +
                                                          (_vm.crit
                                                            ? "font-weight-bold"
                                                            : ""),
                                                        staticStyle: {
                                                          "max-width": "60px",
                                                          "margin-top": "-0.5px"
                                                        },
                                                        attrs: {
                                                          type: "number",
                                                          color: _vm.crit
                                                            ? "secondary"
                                                            : "accent",
                                                          dense: "",
                                                          hint: d.Type,
                                                          "persistent-hint": ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.damageRolls[i],
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.damageRolls,
                                                              i,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "damageRolls[i]"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hit && !_vm.aux && !_vm.noDamageItem
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "px-12 mr-n10 panel dual-sliced mt-n2",
                                      staticStyle: { height: "70px" },
                                      attrs: { cols: "auto" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "overline mt-n2 pl-1" },
                                        [_vm._v("Bonus Damage")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c("cc-dice-menu", {
                                                attrs: {
                                                  title: "BONUS DAMAGE",
                                                  overkill: _vm.overkill,
                                                  critical: _vm.crit
                                                },
                                                on: {
                                                  commit: function($event) {
                                                    return _vm.setBonusDamage(
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "ml-n2",
                                              attrs: { cols: "auto" }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass:
                                                  "hide-input-spinners mt-2 mb-n2 ml-4",
                                                staticStyle: { width: "60px" },
                                                attrs: {
                                                  type: "number",
                                                  color: "accent",
                                                  dense: "",
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    _vm.bonusDamage = parseInt(
                                                      _vm.total
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.bonusDamage,
                                                  callback: function($$v) {
                                                    _vm.bonusDamage = $$v
                                                  },
                                                  expression: "bonusDamage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-slide-x-reverse-transition",
                                [
                                  _vm.hit && !_vm.noDamageItem
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "px-12 panel dual-sliced mt-n2",
                                          staticStyle: { height: "70px" },
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "overline mt-n2 pl-1"
                                            },
                                            [_vm._v("Total Damage")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                "no-gutters": "",
                                                justify: "end"
                                              }
                                            },
                                            [
                                              _vm._l(_vm.getDamage, function(
                                                d,
                                                i
                                              ) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: "dm_result_" + i,
                                                    attrs: { cols: "auto" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "heading h2 stark--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.damageRolls[i]
                                                                ? _vm
                                                                    .damageRolls[
                                                                    i
                                                                  ]
                                                                : "--"
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                        _c(
                                                          "cc-tooltip",
                                                          {
                                                            attrs: {
                                                              inline: "",
                                                              content: d.Type
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "ml-n3",
                                                                attrs: {
                                                                  large: "",
                                                                  color: _vm.ammoDamage
                                                                    ? "damage--" +
                                                                      _vm.ammoDamage
                                                                    : d.Color
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.ammoDamage
                                                                        ? "cci-" +
                                                                            _vm.ammoDamage
                                                                        : d.Icon
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              }),
                                              _vm._v(" "),
                                              _vm.bonusDamage
                                                ? _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "ml-n1",
                                                      attrs: { cols: "auto" }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "heading h2 stark--text",
                                                          staticStyle: {
                                                            "margin-top": "4px"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      +" +
                                                              _vm._s(
                                                                _vm.bonusDamage
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _vm.summedDamage
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "overline my-n3 pr-1 subtle--text text-right"
                                                },
                                                [
                                                  !!_vm.reliable &&
                                                  _vm.summedDamage <
                                                    _vm.reliable
                                                    ? _c(
                                                        "b",
                                                        {
                                                          staticClass:
                                                            "accent--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    (" +
                                                              _vm._s(
                                                                _vm.reliable
                                                              ) +
                                                              ")\n                  "
                                                          )
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              _vm.summedDamage
                                                            ) +
                                                            ")"
                                                        )
                                                      ])
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-slide-x-reverse-transition",
                                [
                                  (_vm.missed && !!_vm.reliable) ||
                                  (_vm.summedDamage &&
                                    !!_vm.reliable &&
                                    _vm.summedDamage < _vm.reliable)
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-center mt-1 ml-n5 mr-6",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c(
                                            "cc-tooltip",
                                            {
                                              attrs: {
                                                content:
                                                  "This attack deals a minimum of " +
                                                  _vm.reliable +
                                                  " damage"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { "x-large": "" } },
                                                [_vm._v("mdi-progress-alert")]
                                              ),
                                              _vm._v(" "),
                                              _c("div", [
                                                _vm._v(
                                                  "RELIABLE " +
                                                    _vm._s(_vm.reliable)
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-slide-x-reverse-transition",
                                [
                                  _vm.overkill
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-right overline stark--text mt-n2"
                                            },
                                            [_c("b", [_vm._v("OVERKILL")])]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              attrs: {
                                                "no-gutters": "",
                                                justify: "end",
                                                align: "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c(
                                                    "cc-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "When rolling for damage with this weapon, any damage dice that land on a 1 cause the attacker to take 1 Heat, and are then rerolled. Additional 1s continue to trigger this effect. " +
                                                          _vm.autoOverkillString
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            "x-large": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-progress-alert"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm._l(_vm.overkillHeat, function(
                                                n
                                              ) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: "ovkr_" + n,
                                                    staticClass:
                                                      "px-12 mx-n2 panel dual-sliced text-center mt-n1",
                                                    staticStyle: {
                                                      height: "60px"
                                                    },
                                                    attrs: { cols: "auto" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          large: "",
                                                          color: "dangerzone"
                                                        }
                                                      },
                                                      [_vm._v("mdi-fire")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "overline my-n2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      +1 HEAT\n                      "
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "fadeSelect",
                                                            attrs: {
                                                              small: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.overkillHeat--
                                                              }
                                                            }
                                                          },
                                                          [_vm._v("mdi-close")]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "auto" } },
                                                [
                                                  _c(
                                                    "cc-tooltip",
                                                    {
                                                      attrs: {
                                                        content:
                                                          "Add Overkill Heat"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            large: "",
                                                            icon: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.overkillHeat++
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                large: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-plus-circle-outline"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _vm.overkillHeat
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "overline error--text text-right"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  ALERT: This action will incur an additional " +
                                                      _vm._s(_vm.overkillHeat) +
                                                      " heat\n                "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.overkill
                                ? _c("v-col", { staticClass: "ml-auto" })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-slide-x-reverse-transition",
                                [
                                  _vm.hit || _vm.missed
                                    ? _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-center mt-n2 mb-n5 ml-n4",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { "no-gutters": "" }
                                            },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "ml-auto",
                                                  attrs: { cols: "auto" }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        large: "",
                                                        tile: "",
                                                        color:
                                                          "success darken-2",
                                                        disabled:
                                                          _vm.hit &&
                                                          !_vm.summedDamage &&
                                                          !_vm.noDamageItem
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.confirm()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [_vm._v("mdi-check")]
                                                      ),
                                                      _vm._v(
                                                        "\n                      Confirm\n                    "
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.hit ||
                                          (_vm.missed && !!_vm.reliable)
                                            ? _c(
                                                "v-row",
                                                {
                                                  staticClass: "mt-n4",
                                                  attrs: { "no-gutters": "" }
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass: "ml-auto",
                                                      attrs: { cols: "auto" }
                                                    },
                                                    [
                                                      !_vm.noDamageItem
                                                        ? _c(
                                                            "v-checkbox",
                                                            {
                                                              attrs: {
                                                                color: "accent",
                                                                dense: "",
                                                                disabled:
                                                                  _vm.hit &&
                                                                  !_vm.summedDamage
                                                              },
                                                              model: {
                                                                value: _vm.kill,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.kill = $$v
                                                                },
                                                                expression:
                                                                  "kill"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "caption",
                                                                  attrs: {
                                                                    slot:
                                                                      "label"
                                                                  },
                                                                  slot: "label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "TARGET DESTROYED"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-x-reverse-transition",
        [
          (_vm.hit || _vm.missed) && !_vm.noDamageItem
            ? _c(
                "v-row",
                { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c("p", { staticClass: "flavor-text stark--text ma-0" }, [
                        _vm._v("\n            >//[\n            "),
                        _c("span", { staticClass: "accent--text" }, [
                          _vm._v("COMP/CON")
                        ]),
                        _vm._v("\n            ] :\n            "),
                        _vm.missed
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.improv
                                      ? "Improvised attack"
                                      : "Weapon activation"
                                  ) +
                                  " registered. " +
                                  _vm._s(_vm.missText) +
                                  ".\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hit
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.improv
                                      ? "Improvised attack"
                                      : "Weapon activation"
                                  ) +
                                  " registered.\n              " +
                                  _vm._s(_vm.crit ? "Direct hit" : "Hit") +
                                  " confirmed.\n            "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.kill
                          ? _c("span", [_vm._v("Target destroyed.")])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.confirmed
                        ? _c(
                            "p",
                            { staticClass: "flavor-text stark--text ma-0" },
                            [
                              _vm._v("\n            >//[\n            "),
                              _c("span", { staticClass: "accent--text" }, [
                                _vm._v("COMP/CON::COMBAT TELEMETRY LOG")
                              ]),
                              _vm._v("\n            ] :\n            "),
                              _c("span", [
                                _vm._v("ATK " + _vm._s(_vm.attackRoll))
                              ]),
                              _vm._v(" "),
                              _c("cc-slashes"),
                              _vm._v(" "),
                              _vm.hit && !_vm.crit
                                ? _c("span", [_vm._v("HIT")])
                                : _vm.crit
                                ? _c("span", [_vm._v("CRITICAL HIT")])
                                : _c("span", [_vm._v("MISS")]),
                              _vm._v(" "),
                              _c("cc-slashes"),
                              _vm._v(" "),
                              _vm.finalDamage
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.finalDamage) + " DMG")
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.kill
                                ? _c("span", [_vm._v("KILL CONFIRM")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.item.ProfileHeatCost || _vm.overkillHeat
                                ? _c("span", [
                                    _c("br"),
                                    _vm._v(
                                      "\n              ALERT: REACTOR HEAT LEVELS INCREASING\n            "
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.improv
                                ? _c(
                                    "cc-tooltip",
                                    {
                                      attrs: {
                                        inline: "",
                                        content:
                                          "Undo this attack, refunding any actions it may have cost"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "fadeSelect",
                                          attrs: {
                                            "x-small": "",
                                            color: "primary"
                                          },
                                          on: { click: _vm.reset }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "", left: "" } },
                                            [_vm._v("mdi-reload")]
                                          ),
                                          _vm._v(
                                            "\n                UNDO\n              "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }