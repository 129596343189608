var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { staticClass: "pa-1", attrs: { cols: _vm.cols } },
    [
      _c(
        "v-card",
        {
          staticStyle: { transition: "all 0.2s" },
          attrs: {
            tile: "",
            color: "panel clipped",
            height: _vm.collapsed ? "" : "100%"
          }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0 pl-2 pr-2", class: _vm.color },
            [
              _vm.collapsible
                ? _c(
                    "v-icon",
                    {
                      staticClass: "fadeSelect",
                      attrs: { left: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.collapsed = !_vm.collapsed
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.collapsed
                              ? "mdi-chevron-up"
                              : "mdi-chevron-down"
                          ) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  class:
                    "heading " +
                    (_vm.$vuetify.breakpoint.lgAndUp ? "h3" : "h2") +
                    " white--text"
                },
                [_vm._v("\n        " + _vm._s(_vm.header) + "\n      ")]
              ),
              _vm._v(" "),
              _vm.content && _vm.prominent && _vm.$vuetify.breakpoint.lgAndUp
                ? _c("v-divider", {
                    staticClass: "mx-3 subtle",
                    staticStyle: { opacity: "0.5" }
                  })
                : _c("v-spacer"),
              _vm._v(" "),
              _c("span", { staticClass: "overline white--text mr-2" }, [
                _vm._v(_vm._s(_vm.subheader))
              ]),
              _vm._v(" "),
              _vm.content && _vm.prominent
                ? _c(
                    "span",
                    {
                      class:
                        "heading " +
                        (_vm.$vuetify.breakpoint.lgAndup ? "h1" : "h2") +
                        " pt-1 pb-3 pr-3 white--text"
                    },
                    [_vm._v("\n        " + _vm._s(_vm.content) + "\n      ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-scroll-y-transition",
            { attrs: { "leave-absolute": "" } },
            [
              !_vm.collapsed && !_vm.prominent
                ? _c(
                    "v-card-text",
                    { staticClass: "pa-1 pl-2 ma-0 text--text body-text" },
                    [
                      _vm.content && !_vm.prominent
                        ? _c("div", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.content) +
                                "\n        "
                            )
                          ])
                        : _vm._t("default")
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }