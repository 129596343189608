var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: { "margin-top": "50px", "min-height": "calc(100vh - 50px)" }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("div", { staticClass: "overline" }, [_vm._v("//MISSION")]),
              _vm._v(" "),
              _c("div", { staticClass: "heading mech mt-n6" }, [
                _vm._v(_vm._s(_vm.mission.Name))
              ]),
              _vm._v(" "),
              _vm.mission.Note
                ? _c("p", {
                    staticClass: "flavor-text panel text-center",
                    domProps: { innerHTML: _vm._s(_vm.mission.Note) }
                  })
                : _c(
                    "div",
                    { staticClass: "flavor-text subtle--text text-center" },
                    [_vm._v("// NO BRIEFING DATA //")]
                  ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(_vm.mission.Steps, function(s, i) {
                  return _c(
                    "v-col",
                    { key: "step_" + i, attrs: { cols: s.Name ? 3 : 1 } },
                    [
                      _c(
                        "v-card",
                        { attrs: { outlined: "", height: "100%" } },
                        [
                          _c(
                            "div",
                            {
                              class:
                                "caption white--text " +
                                (s.Name ? "primary" : "secondary")
                            },
                            [
                              _vm._v(
                                "\n               //" +
                                  _vm._s(i + 1) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-card-text", { staticClass: "flavor-text" }, [
                            s.Name
                              ? _c(
                                  "div",
                                  [
                                    _c("span", { staticClass: "heading h3" }, [
                                      _vm._v(_vm._s(s.Name))
                                    ]),
                                    _vm._v(" "),
                                    _c("v-divider"),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "PR: " +
                                          _vm._s(s.Power) +
                                          " // COMBATANTS: " +
                                          _vm._s(s.Npcs("Enemy").length)
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("ENV: " + _vm._s(s.Environment))
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("SITREP: " + _vm._s(s.Sitrep.name))
                                    ])
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "text-center",
                                    staticStyle: {
                                      "margin-top": "25%",
                                      "margin-bottom": "25%"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "secondary", large: "" }
                                      },
                                      [_vm._v("mdi-restore")]
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "overline" }, [
                                      _vm._v(
                                        _vm._s(s.IsLong ? "Full" : "Short") +
                                          " Rest"
                                      )
                                    ])
                                  ],
                                  1
                                )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-2 " }),
              _vm._v(" "),
              _c(
                "fieldset",
                {
                  staticClass: "px-3",
                  staticStyle: { "border-radius": "5px" }
                },
                [
                  _c("legend", [
                    _c(
                      "span",
                      { staticClass: "px-2 heading h3 accent--text" },
                      [_vm._v("PILOTS")]
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.pilots.length
                    ? _c(
                        "v-card",
                        { attrs: { color: "panel", flat: "", tile: "" } },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "text-center subtle--text text--darken-2"
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v("// WARNING: NO ASSIGNED PILOTS //")
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.pilots, function(p) {
                    return _c(
                      "v-card",
                      {
                        key: p.ID,
                        staticClass: "my-1",
                        attrs: { cols: "12", outlined: "" }
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "pa-1" },
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "", align: "center" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mr-3",
                                    attrs: { cols: "auto" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "heading h3 accent--text"
                                      },
                                      [_vm._v(_vm._s(p.Callsign))]
                                    ),
                                    _vm._v(" "),
                                    _c("cc-slashes"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "flavor-text" }, [
                                      _vm._v(
                                        _vm._s(p.Name) +
                                          " // LL " +
                                          _vm._s(p.Level)
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-divider", {
                                  staticClass: "mx-2",
                                  attrs: { vertical: "" }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "heading h3 accent--text"
                                      },
                                      [_vm._v(_vm._s(p.ActiveMech.Name))]
                                    ),
                                    _vm._v(" "),
                                    _c("cc-slashes"),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "flavor-text" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(p.ActiveMech.Frame.Source) +
                                          " " +
                                          _vm._s(p.ActiveMech.Frame.Name) +
                                          "\n                "
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "ml-auto mr-2",
                                    attrs: { cols: "auto" }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "fadeSelect",
                                        attrs: { icon: "", color: "error" },
                                        on: {
                                          click: function($event) {
                                            return _vm.removePilot(p)
                                          }
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("mdi-close")])],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "my-2 mx-4" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            tile: "",
                            outlined: "",
                            color: "accent",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.pilotDialog.show()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus")
                          ]),
                          _vm._v("\n            Add Pilot\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _vm.pilots.length
                ? _c(
                    "div",
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            title: "Power Rating",
                            content:
                              "The Power Rating is an attempt to calculate the relative strength of an NPC (or encounters’ worth of NPCs) based on tier and applied templates, compared to mission’s Pilot and their current level. It should, generally, produce results more or less in line with the Balancing Combat section on pp. 283 of the LANCER Core Book.<br> That said, this is an experimental feature that is still very heavily in development, and does not (yet) always produce reliable results. Moreover, this tool doesn’t consider NPC or player team composition, synergies, strengths, and weaknesses. Nor does this tool consider map layout, mission objectives, or reinforcement schedules.<br>While we will continue to work on this tool to produce more accurate, actionable results, please use it only as a general indicator of relative NPC strength."
                          }
                        },
                        [
                          _c("div", { staticClass: "overline mt-2 mb-n4" }, [
                            _vm._v("//POWER RATING")
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "flavor-text text-center",
                              attrs: { dense: "" }
                            },
                            [
                              _c("v-col", [
                                _vm._v(
                                  "\n              TOTAL PILOT:\n              "
                                ),
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v(_vm._s(_vm.pilotPower))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(
                                  "\n              AVERAGE ENCOUNTER:\n              "
                                ),
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v(_vm._s(_vm.avgPower))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(
                                  "\n              MAXIMUM ENCOUNTER:\n              "
                                ),
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v(_vm._s(_vm.maxPower))
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "heading h3 text-center",
                              attrs: { dense: "" }
                            },
                            [
                              _c("v-col", [
                                _vm._v(
                                  "\n              CALCULATED MISSION RATING:\n              "
                                ),
                                _c(
                                  "span",
                                  { class: _vm.diffColor + "--text" },
                                  [_vm._v(_vm._s(_vm.difficulty))]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { "x-large": "", block: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.startMission()
                            }
                          }
                        },
                        [_vm._v("\n            start\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "pilotDialog",
          attrs: {
            "no-confirm": "",
            title: "ADD PILOT",
            fullscreen: "",
            "no-pad": ""
          }
        },
        [
          _c("pilot-selector", {
            attrs: { "selected-pilots": _vm.pilots },
            on: {
              select: function($event) {
                return _vm.addPilot($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }