var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mb-1", attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "cc-tooltip",
            {
              attrs: {
                simple: "",
                inline: "",
                content: "Feature In Development"
              }
            },
            [
              _c("v-icon", { staticClass: "mt-2", attrs: { color: "panel" } }, [
                _vm._v("mdi-dice-multiple")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "pr-6", attrs: { cols: "11" } },
        [
          _c(
            "v-btn",
            {
              attrs: { outlined: "", large: "", block: "", color: "secondary" },
              on: {
                click: function($event) {
                  return _vm.$refs.dialog.show()
                }
              }
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            icon: "mdi-circle-edit-outline",
            color: "primary",
            large: "",
            title: _vm.label
          },
          on: { confirm: _vm.save }
        },
        [
          _c("tiptap-vuetify", {
            staticClass: "mt-4",
            attrs: {
              extensions: _vm.extensions,
              "card-props": { flat: true, tile: true, elevation: 0 },
              "toolbar-attributes": _vm.$vuetify.theme.dark
                ? { color: "black", dark: true }
                : {}
            },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }