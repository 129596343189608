
















































































































































































import Vue from 'vue'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'

export default Vue.extend({
  name: 'skill-check-dialog',
  components: { ActionDetailExpander },
  props: {
    used: { type: Boolean },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    accuracy: 0,
    difficulty: 0,
    succeeded: false,
    failed: false,
    complete: false,
    actionCost: false,
    actionFree: false,
    timer: 0,
    finished: false,
  }),
  watch: {
    used: {
      immediate: true,
      deep: true,
      handler: function (newval) {
        if (!newval) this.reset()
      },
    },
  },
  methods: {
    registerSkillRoll(roll) {
      Vue.set(this, 'skillRoll', roll)
      Vue.nextTick().then(() => this.$forceUpdate())
    },
    reset() {
      this.accuracy = 0
      this.difficulty = 0
      this.skillRoll = ''
      this.succeeded = false
      this.failed = false
      this.complete = false
      this.actionCost = false
      this.actionFree = false
      this.timer = 0
      this.finished = false
    },
  },
})
