











































































































































































































import Vue from 'vue'
import SearchBar from '../SearchBar.vue'
import CompendiumPageButton from '../components/CompendiumPageButton.vue'

export default Vue.extend({
  name: 'compendium-home',
  components: { SearchBar, CompendiumPageButton },
})
