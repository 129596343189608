var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-loadout-panel",
        {
          attrs: {
            loadouts: _vm.mech.Loadouts,
            "active-loadout": _vm.mech.ActiveLoadout,
            color: _vm.color,
            readonly: _vm.readonly
          },
          on: {
            "set-active": function($event) {
              _vm.mech.ActiveLoadout = $event
            },
            "add-loadout": function($event) {
              return _vm.mech.AddLoadout()
            },
            "clone-loadout": function($event) {
              return _vm.mech.CloneLoadout()
            },
            "remove-loadout": function($event) {
              return _vm.mech.RemoveLoadout()
            }
          }
        },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _vm._l(_vm.mech.ActiveLoadout.IntegratedMounts, function(im, j) {
                return _c("mount-block", {
                  key: "int_" + j,
                  attrs: {
                    integrated: "",
                    mount: im,
                    mech: _vm.mech,
                    color: _vm.color
                  }
                })
              }),
              _vm._v(" "),
              _vm.mech.Pilot.has("CoreBonus", "cb_integrated_weapon")
                ? _c("mount-block", {
                    attrs: {
                      "int-weapon": "",
                      readonly: _vm.readonly,
                      mount: _vm.mech.ActiveLoadout.IntegratedWeaponMount,
                      mech: _vm.mech,
                      color: _vm.color
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.mech.Pilot.has("CoreBonus", "cb_improved_armament") &&
              _vm.mech.ActiveLoadout.EquippableMounts.length < 3
                ? _c("mount-block", {
                    attrs: {
                      "imp-arm": "",
                      readonly: _vm.readonly,
                      mount: _vm.mech.ActiveLoadout.ImprovedArmamentMount,
                      mech: _vm.mech,
                      color: _vm.color
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.mech.ActiveLoadout.EquippableMounts, function(m, k) {
                return _c("mount-block", {
                  key: "m_" + k,
                  attrs: {
                    readonly: _vm.readonly,
                    mount: m,
                    mech: _vm.mech,
                    color: _vm.color
                  }
                })
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2" }),
          _vm._v(" "),
          _c("systems-block", {
            attrs: { mech: _vm.mech, color: _vm.color, readonly: _vm.readonly }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }