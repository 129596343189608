var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      class: { "clipped-large": _vm.clipped },
      style: _vm.fixed ? "position: fixed; width: 100%; z-index:99" : "",
      attrs: { color: _vm.getColor(), dense: _vm.$vuetify.breakpoint.mdAndDown }
    },
    [
      _c(
        "v-toolbar-title",
        {
          class:
            "heading " +
            (_vm.$vuetify.breakpoint.mdAndDown ? "h3" : "h2") +
            " " +
            (_vm.dark ? "white--text" : "stark--text")
        },
        [
          _vm.icon
            ? _c(
                "v-icon",
                {
                  staticClass: "mt-n1",
                  attrs: { "x-large": "", dark: _vm.dark, left: "" }
                },
                [_vm._v(_vm._s(_vm.icon))]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c("v-toolbar-items", [_vm._t("items")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }