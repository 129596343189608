var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-navigation",
    {
      staticClass: "no-print",
      attrs: { fixed: "", grow: "", horizontal: "", color: "primary" }
    },
    [
      _c(
        "v-btn",
        {
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [
          _c("span", [_vm._v("Close Preview")]),
          _vm._v(" "),
          _c("v-icon", [_vm._v("mdi-close")])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          on: {
            click: function($event) {
              return _vm.$refs.options.show()
            }
          }
        },
        [
          _c("span", [_vm._v("Options")]),
          _vm._v(" "),
          _c("v-icon", [_vm._v("mdi-settings")])
        ],
        1
      ),
      _vm._v(" "),
      _c("options-dialog", {
        ref: "options",
        on: {
          set: function($event) {
            return _vm.setOptions($event)
          }
        }
      }),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          on: {
            click: function($event) {
              return _vm.print()
            }
          }
        },
        [
          _c("span", [_vm._v("Print")]),
          _vm._v(" "),
          _c("v-icon", [_vm._v("print")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }