var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("action-detail-expander", { attrs: { action: _vm.action } }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticStyle: { "max-width": "800px" } },
        _vm._l(_vm.mech.ActiveLoadout.Mounts, function(m, i) {
          return _c(
            "div",
            { key: "bar_" + i },
            _vm._l(
              m.Weapons.filter(function(x) {
                return x.Size !== "Superheavy" && !x.Destroyed && !x.NoAttack
              }),
              function(w, j) {
                return _c("item-selector-row", {
                  key: "weap_" + j,
                  attrs: {
                    item: w,
                    color: "action--quick",
                    disabled: _vm.disableSkirmish(w)
                  },
                  on: {
                    click: function($event) {
                      return _vm.skirmish(w, m)
                    }
                  }
                })
              }
            ),
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("w-skirmish-dialog", {
        ref: "s_dialog",
        attrs: { mech: _vm.mech, item: _vm.selected, mount: _vm.selectedMount },
        on: {
          confirm: function($event) {
            return _vm.confirmSkirmish($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }