var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    ref: "input",
    staticClass: "search-field",
    attrs: {
      "prepend-icon": _vm.$vuetify.breakpoint.lgAndUp ? "search" : "",
      solo: "",
      "hide-details": "",
      "single-line": "",
      placeholder: "Search the Compendium"
    },
    on: {
      keyup: function($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        ) {
          return null
        }
        return _vm.search($event)
      }
    },
    model: {
      value: _vm.searchText,
      callback: function($$v) {
        _vm.searchText = $$v
      },
      expression: "searchText"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }