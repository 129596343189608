var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-n3" },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-select", {
            staticClass: "my-2",
            attrs: {
              outlined: "",
              dense: "",
              "hide-details": "",
              items: _vm.items,
              "item-text": function(item) {
                return "" + (item.Source ? item.Source + " " : "") + item.Name
              },
              "return-object": ""
            },
            model: {
              value: _vm.selectedItem,
              callback: function($$v) {
                _vm.selectedItem = $$v
              },
              expression: "selectedItem"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", [
            !_vm.selectedItem
              ? _c(
                  "div",
                  {
                    staticClass: "heading h2 light-panel--text text-center",
                    staticStyle: { "margin-top": "calc(50vh - 150px)" }
                  },
                  [_vm._v("\n        NO SELECTION\n      ")]
                )
              : _c(
                  "div",
                  { staticClass: "side-fixed" },
                  [
                    _c("div", { staticClass: "heading h2 stark--text" }, [
                      _vm._v(_vm._s(_vm.selectedItem.Name))
                    ]),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "mt-4 mb-1" }),
                    _vm._v(" "),
                    _c("cc-item-card", { attrs: { item: _vm.selectedItem } }),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "my-3" }),
                    _vm._v(" "),
                    _vm.equipmentAdd
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "secondary",
                              large: "",
                              tile: "",
                              block: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("add", _vm.selectedItem)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          Add " +
                                _vm._s(_vm.selectedItem.Name) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "secondary",
                          large: "",
                          tile: "",
                          block: "",
                          disabled:
                            _vm.spDisable &&
                            _vm.selectedItem.SP > _vm.sp &&
                            !_vm.spIgnore
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("equip", _vm.selectedItem)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          Equip " +
                            _vm._s(_vm.selectedItem.Name) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }