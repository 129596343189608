var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      style: _vm.$vuetify.breakpoint.mdAndDown ? "x-overflow: hidden" : "",
      attrs: { fullscreen: _vm.$vuetify.breakpoint.mdAndDown, width: "90vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { tile: "" } },
        [
          _c(
            "cc-titlebar",
            { attrs: { large: "", color: "action--full" } },
            [
              _c("v-icon", { attrs: { "x-large": "" } }, [
                _vm._v("mdi-hexagon-slice-6")
              ]),
              _vm._v("\n      Barrage\n      "),
              _c(
                "v-btn",
                {
                  attrs: { slot: "items", dark: "", icon: "" },
                  on: { click: _vm.hide },
                  slot: "items"
                },
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("close")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.mdAndDown
            ? _c("v-spacer", { staticClass: "titlebar-margin" })
            : _vm._e(),
          _vm._v(" "),
          _vm.item
            ? _c(
                "v-card-text",
                { staticClass: "mb-0 pb-2" },
                [
                  _c(
                    "weapon-attack",
                    {
                      ref: "main",
                      attrs: {
                        item: _vm.item,
                        mech: _vm.mech,
                        mount: _vm.mount,
                        barrage: ""
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.$emit("confirm")
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "heading h2 mt-3 mb-n3" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mt-n2 mr-n1",
                              attrs: { "x-large": "" }
                            },
                            [_vm._v("cci-mech-weapon")]
                          ),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.item.Name) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }