var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        position: "relative",
        "max-width": "fit-content",
        display: "inline-block"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "wings",
          class: _vm.disabled ? "disabled" : "",
          style: "background:" + _vm.bgColor
        },
        [
          _c(
            "v-btn",
            _vm._b(
              {
                staticClass: "clipped-btn",
                staticStyle: { visibility: "visible" },
                style:
                  "visibility: visible; background-color: " +
                  _vm.bgColor +
                  " !important",
                attrs: { tile: "" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("click")
                  }
                }
              },
              "v-btn",
              _vm.$props,
              false
            ),
            [
              _c(
                "span",
                {
                  class: !_vm.xLarge ? "" : "heading h3 align",
                  staticStyle: { display: "contents" }
                },
                [
                  _vm._v("\n         \n        "),
                  _vm._t("default"),
                  _vm._v("\n          \n      ")
                ],
                2
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }