var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("action-dialog-base", {
    ref: "base",
    attrs: {
      used: _vm.used,
      action: _vm.action,
      mech: _vm.mech,
      fulltech: _vm.fulltech
    },
    on: {
      use: function($event) {
        return _vm.$emit("use", $event)
      },
      undo: function($event) {
        return _vm.$emit("undo")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }