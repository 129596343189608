var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.updateFound
    ? _c(
        "v-btn",
        {
          staticClass: "glow-anim",
          staticStyle: { "z-index": "999!important" },
          attrs: { small: "", dark: "", color: "secondary" },
          on: { click: _vm.updateClick }
        },
        [
          _c("v-icon", { attrs: { left: "", small: "" } }, [
            _vm._v("\n    cloud_download\n  ")
          ]),
          _vm._v("\n  " + _vm._s(_vm.updateText) + "\n")
        ],
        1
      )
    : _c(
        "v-btn",
        {
          attrs: { dark: "", outlined: "", small: "", disabled: _vm.checking },
          on: { click: _vm.checkUpdates }
        },
        [
          _c(
            "v-icon",
            {
              class: { "spin-anim": _vm.checking },
              attrs: { left: "", small: "" }
            },
            [_vm._v("\n    sync\n  ")]
          ),
          _vm._v(
            "\n  " +
              _vm._s(
                _vm.checking ? "Checking for Updates..." : "Check for Updates"
              ) +
              "\n"
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }