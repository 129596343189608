var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        [
          _c("p", {
            staticClass: "text-center body-text",
            domProps: {
              innerHTML: _vm._s(
                "You focus on increasing your own skills, training, and self-improvement. You might practice, learn, meditate, or call on a teacher."
              )
            }
          }),
          _vm._v(" "),
          _c("v-divider", { staticClass: "ma-2" }),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              attrs: {
                color: "secondary",
                prominent: "",
                icon: "mdi-information-outline",
                dense: "",
                outlined: "",
                border: "left"
              }
            },
            [
              _vm._v(
                '\n      Completing this Downtime Action will add a "Get Focused" item to this pilot\'s Downtime\n      Reserves. Each "Get Focused" Reserve adds a bonus Skill Trigger selection, which can be made\n      in the\n      '
              ),
              _c("b", { staticClass: "accent--text" }, [
                _vm._v("Pilot Sheet's Skill Trigger selection screen.")
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("cancel")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { large: "", tile: "", color: "primary" },
              on: {
                click: function($event) {
                  return _vm.addSkill()
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.tabs === 0 ? "Add Skill" : "Improve Skill") +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }