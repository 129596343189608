var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-titled-panel",
    {
      attrs: {
        icon: _vm.npc.Class.RoleIcon,
        title:
          _vm.npc.Name +
          "  —  " +
          (typeof _vm.npc.Tier === "number" ? "T" + _vm.npc.Tier : "Custom") +
          " " +
          _vm.npc.Class.Name +
          " " +
          _vm.npc.Templates.map(function(t) {
            return t.Name
          }).join(" ") +
          " " +
          _vm.npc.Tag,
        color: _vm.npc.Class.Color,
        dense: ""
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "mt-n1", attrs: { dense: "" } },
        [
          _vm.npc.Campaign
            ? _c("v-col", { attrs: { cols: "auto" } }, [
                _c("div", { staticClass: "overline" }, [_vm._v("CAMPAIGN")]),
                _vm._v("\n      " + _vm._s(_vm.npc.Campaign) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.npc.Labels.length
            ? _c(
                "v-col",
                [
                  _c("div", { staticClass: "overline" }, [_vm._v("LABELS")]),
                  _vm._v(" "),
                  _vm._l(_vm.npc.Labels, function(l) {
                    return _c(
                      "v-chip",
                      { key: l, staticClass: "mx-1", attrs: { small: "" } },
                      [_vm._v(_vm._s(l))]
                    )
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _vm.sideSelect
                ? _c("v-select", {
                    attrs: {
                      label: "Side",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                      items: ["Enemy", "Ally", "Neutral"]
                    },
                    model: {
                      value: _vm.npc.Side,
                      callback: function($$v) {
                        _vm.$set(_vm.npc, "Side", $$v)
                      },
                      expression: "npc.Side"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.sideSelect
        ? _c("v-textarea", {
            staticClass: "mt-3",
            attrs: {
              outlined: "",
              label: "GM Notes",
              "auto-grow": "",
              rows: "2"
            },
            model: {
              value: _vm.npc.Note,
              callback: function($$v) {
                _vm.$set(_vm.npc, "Note", $$v)
              },
              expression: "npc.Note"
            }
          })
        : _vm.npc.Note
        ? _c("p", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.npc.Note,
                expression: "npc.Note"
              }
            ],
            staticClass: "flavor-text mb-0"
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "text-center", attrs: { dense: "", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "7" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  _c("simple-attribute", {
                    attrs: { attr: "HULL", val: _vm.npc.Stats.Hull }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "AGI", val: _vm.npc.Stats.Agility }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "SYS", val: _vm.npc.Stats.Systems }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "ENG", val: _vm.npc.Stats.Engineering }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-2" }),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  _c("simple-attribute", {
                    attrs: { attr: "STRUCT", val: _vm.npc.Stats.Structure }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "ARMOR", val: _vm.npc.Stats.Armor }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "HP", val: _vm.npc.Stats.HP }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "REACTOR", val: _vm.npc.Stats.Stress }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: {
                      attr: "HEAT CAP.",
                      val: _vm.npc.Stats.HeatCapacity,
                      color: _vm.npc.Class.Color
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-2" }),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  _c("simple-attribute", {
                    attrs: { attr: "SPEED", val: _vm.npc.Stats.Speed }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "SAVE", val: _vm.npc.Stats.Save }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "EVADE", val: _vm.npc.Stats.Evade }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "E-DEF", val: _vm.npc.Stats.EDefense }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "SENSOR", val: _vm.npc.Stats.Sensor }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-2" }),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "" } },
                [
                  _c("simple-attribute", {
                    attrs: {
                      attr: "ACTIVATIONS",
                      val: _vm.npc.Stats.Activations,
                      color: _vm.npc.Class.Color
                    }
                  }),
                  _vm._v(" "),
                  _c("simple-attribute", {
                    attrs: { attr: "SIZE", val: _vm.npc.Stats.Size }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "div",
                [
                  _vm.npc.Tier === "custom"
                    ? _c(
                        "v-icon",
                        { attrs: { size: "40", color: _vm.npc.Class.Color } },
                        [
                          _vm._v(
                            "\n          mdi-star-circle-outline\n        "
                          )
                        ]
                      )
                    : _c(
                        "v-icon",
                        { attrs: { size: "40", color: _vm.npc.Class.Color } },
                        [_vm._v("cci-rank-" + _vm._s(_vm.npc.Tier))]
                      ),
                  _vm._v(" "),
                  _vm.npc.Tier === "custom"
                    ? _c("div", { staticClass: "overline mt-n1" }, [
                        _vm._v("CUSTOM")
                      ])
                    : _c("div", { staticClass: "overline mt-n1" }, [
                        _vm._v("TIER " + _vm._s(_vm.npc.Tier))
                      ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-1" }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    { attrs: { size: "40", color: _vm.npc.Class.Color } },
                    [_vm._v(_vm._s(_vm.npc.Class.RoleIcon))]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "overline mt-n1" }, [
                    _vm._v(_vm._s(_vm.npc.Class.Role))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-1" }),
              _vm._v(" "),
              _c(
                "cc-tooltip",
                {
                  attrs: {
                    title: "Power Rating",
                    content:
                      "The Power Rating is an attempt to calculate the relative strength of an NPC (or encounters’ worth of NPCs) based on tier and applied templates, compared to mission’s Pilot and their current level. It should, generally, produce results more or less in line with the Balancing Combat section on pp. 283 of the LANCER Core Book.<br> That said, this is an experimental feature that is still very heavily in development, and does not (yet) always produce reliable results. Moreover, this tool doesn’t consider NPC or player team composition, synergies, strengths, and weaknesses. Nor does this tool consider map layout, mission objectives, or reinforcement schedules.<br>While we will continue to work on this tool to produce more accurate, actionable results, please use it only as a general indicator of relative NPC strength."
                  }
                },
                [
                  _c("div", [
                    _c("b", { staticClass: "caption" }, [
                      _vm._v("POWER RATING")
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "heading h2 accent--text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.npc.Power.toString().padStart(4, "0")) +
                          "\n          "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _vm.npc.Image
                ? _c("v-img", {
                    key: _vm.npc.Image,
                    attrs: { src: _vm.npc.Image, "aspect-ratio": "1" }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "my-2", attrs: { dense: "", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [_c("v-divider", { staticClass: "mt-3" })],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "heading h3 accent--text px-2" }, [
            _vm._v("FEATURES")
          ]),
          _vm._v(" "),
          _c("v-col", [_c("v-divider", { staticClass: "mt-3" })], 1)
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mr-2 mt-n1 ml-2", attrs: { dense: "" } },
        _vm._l(_vm.npc.Items, function(item, j) {
          return _c(
            "v-col",
            {
              key: _vm.npc.ID + "_" + item.Name + "_" + j,
              attrs: { cols: "auto" }
            },
            [
              _c(
                "cc-dialog",
                {
                  attrs: {
                    "no-confirm": "",
                    "small-btn": "",
                    color: item.Feature.Color,
                    large: ""
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "white--text",
                      staticStyle: { width: "100%" },
                      attrs: { slot: "button" },
                      slot: "button"
                    },
                    [
                      _c("v-icon", { attrs: { left: "", dark: "" } }, [
                        _vm._v(_vm._s(item.Feature.Icon))
                      ]),
                      _vm._v("\n          " + _vm._s(item.Name) + "\n        ")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { attrs: { slot: "title" }, slot: "title" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { left: "", large: "", dark: "" } },
                        [_vm._v(_vm._s(item.Feature.Icon))]
                      ),
                      _vm._v("\n          " + _vm._s(item.Name) + "\n        ")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-chip",
                    {
                      staticClass: "stat-text mt-4 mr-6",
                      attrs: {
                        slot: "title-items",
                        color: "white",
                        outlined: "",
                        label: ""
                      },
                      slot: "title-items"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(item.Feature.FeatureType) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("cc-item-card", { attrs: { item: item.Feature } }, [
                    _c("p", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: item.Description,
                          expression: "item.Description"
                        }
                      ],
                      attrs: { slot: "statblock" },
                      slot: "statblock"
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }