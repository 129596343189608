var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "", top: "" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    {
                      staticClass: "mx-1",
                      attrs: {
                        small: "",
                        dark: "",
                        fab: _vm.$vuetify.breakpoint.lgAndUp,
                        elevation: "0",
                        color:
                          _vm.available &&
                          (_vm.baseActions.length || _vm.itemActions.length)
                            ? _vm.color
                            : "grey darken-2"
                      }
                    },
                    on
                  ),
                  [
                    _vm._t("icon"),
                    _vm._v(" "),
                    _vm.$vuetify.breakpoint.mdAndDown
                      ? _c("span", {
                          staticClass: "pl-2",
                          domProps: {
                            innerHTML: _vm._s(_vm.title.replace(" ACTIONS", ""))
                          }
                        })
                      : _vm._e()
                  ],
                  2
                )
              ]
            }
          }
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "heading h3",
              staticStyle: { "min-width": "80px" },
              attrs: { dense: "", flat: "" }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { small: "", icon: "", color: "accent" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("open-menu")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list",
            { staticClass: "px-2 py-3", attrs: { dense: "", color: "panel" } },
            [
              _vm._l(_vm.baseActions, function(a, i) {
                return _c(
                  "v-list-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.allowed.includes(a.ID),
                        expression: "allowed.includes(a.ID)"
                      }
                    ],
                    key:
                      _vm.title + "_action" + i + "_" + a.Name + "_" + a.Used,
                    on: {
                      click: function($event) {
                        return _vm.$emit("open-dialog", a)
                      }
                    }
                  },
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "text-button" },
                      [
                        _c(
                          "v-icon",
                          {
                            style:
                              !_vm.available && !a.Used ? "opacity:0.3" : "",
                            attrs: { left: "" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(a.Used ? "mdi-check-circle" : a.Icon) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v("\n          " + _vm._s(a.Name) + "\n        ")
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.baseActions.length > 0 && _vm.itemActions.length > 0
                ? _c("v-divider")
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.itemActions, function(a, i) {
                return _c(
                  "v-list-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.allowed.includes(a.ID),
                        expression: "allowed.includes(a.ID)"
                      }
                    ],
                    key:
                      _vm.title + "_action" + i + "_" + a.Name + "_" + a.Used,
                    on: {
                      click: function($event) {
                        return _vm.$emit("open-dialog", a)
                      }
                    }
                  },
                  [
                    _c(
                      "v-list-item-title",
                      { staticClass: "text-button" },
                      [
                        _c(
                          "v-icon",
                          {
                            style:
                              !_vm.available && !a.Used ? "opacity:0.3" : "",
                            attrs: { left: "" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(a.Used ? "mdi-check-circle" : a.Icon) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v("\n          " + _vm._s(a.Name) + "\n        ")
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }