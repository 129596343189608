
















import Vue from 'vue'
import CounterComponent from './_Counter.vue'
import NewCounter from './_NewCounter.vue'
import { Counter } from '@/class'

export default Vue.extend({
  name: 'cc-counter-set',
  components: { CounterComponent, NewCounter },
  props: {
    actor: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    actors() {
      if (Array.isArray(this.actor)) return this.actor
      return [this.actor]
    },
  },
  methods: {
    onCustomCounterCreate(name: string) {
      this.actors[0].createCustomCounter(name)
    },
    updateCounter(val: Counter) {
      this.actors[0].saveCounter(val.Serialize())
    },
    deleteCustom(id: string) {
      this.actors[0].deleteCustomCounter(id)
    },
  },
})
