var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-view",
    { ref: "view" },
    [
      _c("cc-gm-header", {
        attrs: { slot: "title", title: "ENCOUNTERS" },
        slot: "title"
      }),
      _vm._v(" "),
      _c(
        "template",
        { slot: "left" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("encounter-group", {
                    on: {
                      set: function($event) {
                        _vm.grouping = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2 " }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-data-table", {
                staticClass: "transparent",
                staticStyle: { "min-width": "100%" },
                attrs: {
                  dense: "",
                  items: _vm.encounters,
                  headers: _vm.headers,
                  "group-by": _vm.grouping,
                  search: _vm.search,
                  "no-results-text": "No Encounters Found",
                  "no-data-text": "No Saved Encounters",
                  "disable-pagination": "",
                  "hide-default-footer": "",
                  "calculate-widths": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "group.header",
                    fn: function(h) {
                      return [
                        _c(
                          "div",
                          { staticClass: "primary sliced" },
                          [
                            _c("v-icon", { attrs: { dark: "", left: "" } }, [
                              _vm._v("mdi-chevron-right")
                            ]),
                            _vm._v(" "),
                            h.group && h.group !== "null"
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "heading white--text text-uppercase"
                                  },
                                  [
                                    Array.isArray(h.group)
                                      ? _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              h.group.join(", ")
                                            )
                                          }
                                        })
                                      : _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(h.group)
                                          }
                                        })
                                  ]
                                )
                              : _c("span", [_vm._v("NONE")])
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.Name",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "accent--text heading clickable ml-n2",
                            on: {
                              click: function($event) {
                                _vm.selectedEncounter = item
                              }
                            }
                          },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-x": "", left: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mt-n1 mr-n2",
                                                attrs: { icon: "", small: "" },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "fadeSelect",
                                                  attrs: { small: "" }
                                                },
                                                [_vm._v("mdi-settings")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-list",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.copyEncounter(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          { staticClass: "ma-0 mr-2 mt-2" },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-content-copy")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "Copy " + _vm._s(item.Name)
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item",
                                      { attrs: { disabled: "" } },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          { staticClass: "ma-0 mr-2 mt-2" },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-printer")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v("Print Encounter Sheets")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider"),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteEncounter(item)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          { staticClass: "ma-0 mr-2 mt-2" },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "error" } },
                                              [_vm._v("mdi-delete")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c(
                                              "v-list-item-title",
                                              { staticClass: "error--text" },
                                              [
                                                _vm._v(
                                                  "\n                      Delete " +
                                                    _vm._s(item.Name) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(item.Name) +
                                "\n          "
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-scroll-x-transition",
                          { attrs: { "leave-absolute": "" } },
                          [
                            _vm.selectedEncounter === item
                              ? _c(
                                  "v-icon",
                                  { attrs: { right: "", color: "primary" } },
                                  [
                                    _vm._v(
                                      "\n              mdi-chevron-triple-right\n            "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.Power",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("span", { staticClass: "caption text-uppercase" }, [
                          _vm._v(_vm._s(item.Power))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2 " }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center", dense: "", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        tile: "",
                        color: "primary",
                        large: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.addNew()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v("\n          Add New Encounter\n        ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "right" },
        [
          _vm.selectedEncounter
            ? _c("encounter-card", {
                key: _vm.selectedEncounter.ID,
                attrs: { encounter: _vm.selectedEncounter }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }