var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !!_vm.authedUser && !!_vm.authedUser.attributes
      ? _c(
          "div",
          [
            _vm.userProfile
              ? _c(
                  "div",
                  { staticClass: "text-center heading h3 mt-3 mb-2" },
                  [
                    _vm._v("\n      CONNECTED\n      "),
                    _c("cc-slashes"),
                    _vm._v(" "),
                    _c("b", { staticClass: "accent--text" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.userProfile.Username) +
                          "\n      "
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "panel",
                attrs: { dense: "", justify: "center", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticStyle: { "letter-spacing": "5px" },
                    attrs: { cols: "auto" }
                  },
                  [_vm._v("\n        ACCOUNT INFORMATION\n      ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              {
                attrs: { dense: "", justify: "space-between", align: "center" }
              },
              [
                _c("v-col", [
                  _c(
                    "div",
                    { staticClass: "overline font-weight-bold my-0" },
                    [
                      _vm._v("\n          VAULT CONTENTS\n          "),
                      _c("cc-slashes"),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.authedUser.attributes.sub) +
                          "\n        "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.userProfile
                    ? _c(
                        "p",
                        { staticClass: "body-text ml-3" },
                        [
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v(_vm._s(_vm.userProfile.Pilots.length))
                          ]),
                          _vm._v("\n          Pilots\n          "),
                          _c("cc-slashes"),
                          _vm._v(" "),
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v(_vm._s(_vm.userProfile.Npcs.length))
                          ]),
                          _vm._v("\n          NPCs\n          "),
                          _c("cc-slashes"),
                          _vm._v(" "),
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v(_vm._s(_vm.userProfile.Encounters.length))
                          ]),
                          _vm._v("\n          Encounters\n          "),
                          _c("cc-slashes"),
                          _vm._v(" "),
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v(_vm._s(_vm.userProfile.Missions.length))
                          ]),
                          _vm._v("\n          Missions\n          "),
                          _c("cc-slashes"),
                          _vm._v(" "),
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v(
                              _vm._s(_vm.userProfile.ActiveMissions.length)
                            )
                          ]),
                          _vm._v("\n          Active Missions\n          "),
                          _c("br"),
                          _vm._v(
                            "\n          Last Sync: " +
                              _vm._s(_vm.userProfile.LastSync) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "mr-6", attrs: { cols: "auto" } },
                  [
                    _c(
                      "cc-tooltip",
                      { attrs: { content: "Manual Sync" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              fab: "",
                              large: "",
                              elevation: "0",
                              color: "accent",
                              dark: "",
                              loading: _vm.loading
                            },
                            on: {
                              click: function($event) {
                                return _vm.sync()
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { "x-large": "" } }, [
                              _vm._v("mdi-cloud-sync-outline")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "panel",
                attrs: { dense: "", justify: "center", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticStyle: { "letter-spacing": "5px" },
                    attrs: { cols: "auto" }
                  },
                  [_vm._v("\n        ACCOUNT OPTIONS\n      ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-card",
              { staticClass: "my-2", attrs: { tile: "", outlined: "" } },
              [
                _c(
                  "v-toolbar",
                  {
                    attrs: {
                      dense: "",
                      flat: "",
                      tile: "",
                      color: "light-panel"
                    }
                  },
                  [
                    _c("div", { staticClass: "heading h3" }, [
                      _vm._v("SYNC FREQUENCY")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "v-row",
                  {
                    staticClass: "pa-2",
                    attrs: { dense: "", align: "center" }
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { md: "auto", cols: "12" } },
                      [
                        _c("v-switch", {
                          class: _vm.$vuetify.breakpoint.mdAndUp ? "px-6" : "",
                          attrs: {
                            "input-value": _vm.isManualOnly,
                            "hide-details": "",
                            color: "accent",
                            label: "Manual Sync Only"
                          },
                          on: {
                            change: function($event) {
                              return _vm.setManualOnly($event)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.$vuetify.breakpoint.mdAndUp
                      ? _c("v-divider", {
                          staticClass: "mx-3",
                          attrs: { vertical: "" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      [
                        _vm.userProfile
                          ? _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On App Load"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onAppLoad,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onAppLoad",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onAppLoad"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On User Sign In"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onSignIn,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onSignIn",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onSignIn"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Pilot Level Up"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onPilotLevel,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onPilotLevel",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onPilotLevel"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Pilot Creation"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onPilotCreate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onPilotCreate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onPilotCreate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Pilot Deletion"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onPilotDelete,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onPilotDelete",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onPilotDelete"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Mech Creation"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onMechCreate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onMechCreate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onMechCreate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Mech Deletion"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onMechDelete,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onMechDelete",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onMechDelete"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On NPC Creation"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onNpcCreate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onNpcCreate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onNpcCreate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On NPC Deletion"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onNpcDelete,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onNpcDelete",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onNpcDelete"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Encounter Creation"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onEncounterCreate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onEncounterCreate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onEncounterCreate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Encounter Deletion"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onEncounterDelete,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onEncounterDelete",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onEncounterDelete"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Mission Creation"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onMissionCreate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onMissionCreate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onMissionCreate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Mission Deletion"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onMissionDelete,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onMissionDelete",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onMissionDelete"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Mission Start"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onMissionStart,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onMissionStart",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onMissionStart"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-col",
                                  { attrs: { lg: "4", cols: "6" } },
                                  [
                                    _c("v-switch", {
                                      attrs: {
                                        "hide-details": "",
                                        color: "accent",
                                        label: "On Turn End"
                                      },
                                      model: {
                                        value:
                                          _vm.userProfile.SyncFrequency
                                            .onTurnEnd,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.userProfile.SyncFrequency,
                                            "onTurnEnd",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "userProfile.SyncFrequency.onTurnEnd"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          text: "",
                          color: "accent",
                          loading: _vm.loading
                        },
                        on: {
                          click: function($event) {
                            return _vm.sync()
                          }
                        }
                      },
                      [_vm._v("Save")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "panel",
                attrs: { dense: "", justify: "center", align: "center" }
              },
              [
                _c(
                  "v-col",
                  {
                    staticStyle: { "letter-spacing": "5px" },
                    attrs: { cols: "auto" }
                  },
                  [_vm._v("\n        CHANGE PASSWORD\n      ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              { attrs: { dense: "" } },
              [
                _c(
                  "v-col",
                  { attrs: { lg: "6", cols: "12" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outlined: "",
                        dense: "",
                        label: "Old Password",
                        type: _vm.showOld ? "text" : "password",
                        "append-icon": _vm.showOld ? "mdi-eye" : "mdi-eye-off"
                      },
                      on: {
                        "click:append": function($event) {
                          _vm.showOld = !_vm.showOld
                        }
                      },
                      model: {
                        value: _vm.oldpass,
                        callback: function($$v) {
                          _vm.oldpass = $$v
                        },
                        expression: "oldpass"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { lg: "6", cols: "12" } },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outlined: "",
                        dense: "",
                        label: "New Password",
                        rules: [_vm.rules.passLength, _vm.passMatch],
                        type: _vm.showNew ? "text" : "password",
                        "append-icon": _vm.showNew ? "mdi-eye" : "mdi-eye-off"
                      },
                      on: {
                        "click:append": function($event) {
                          _vm.showNew = !_vm.showNew
                        }
                      },
                      model: {
                        value: _vm.newpass,
                        callback: function($$v) {
                          _vm.newpass = $$v
                        },
                        expression: "newpass"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              {
                staticClass: "mt-n3",
                attrs: { "no-gutters": "", justify: "end" }
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          text: "",
                          color: "accent",
                          disabled:
                            !_vm.oldpass ||
                            !_vm.newpass ||
                            _vm.oldpass === _vm.newpass,
                          loading: _vm.loading
                        },
                        on: { click: _vm.changePass }
                      },
                      [_vm._v("\n          Submit\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-scroll-y-transition",
              { attrs: { "leave-absolute": "", "hide-on-leave": "" } },
              [
                _vm.error
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          color: "error darken-1",
                          dark: "",
                          dense: "",
                          icon: "mdi-alert",
                          dismissible: ""
                        },
                        model: {
                          value: _vm.showError,
                          callback: function($$v) {
                            _vm.showError = $$v
                          },
                          expression: "showError"
                        }
                      },
                      [
                        _c("div", { staticClass: "font-weight-bold" }, [
                          _vm._v("ERROR")
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.error) }
                        })
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("v-divider", { staticClass: "my-2" }),
            _vm._v(" "),
            _c(
              "v-row",
              { attrs: { justify: "center", align: "center" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          "x-large": "",
                          tile: "",
                          color: "warning darken-1",
                          loading: _vm.loading
                        },
                        on: { click: _vm.signOut }
                      },
                      [_vm._v("\n          Sign Out\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "v-row",
              {
                staticStyle: { height: "100%" },
                attrs: { "no-gutters": "", justify: "center", align: "center" }
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c("v-progress-circular", {
                      attrs: { intermediate: "", size: "60", color: "primary" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }