var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      style: _vm.$vuetify.breakpoint.mdAndDown ? "x-overflow: hidden" : "",
      attrs: { fullscreen: _vm.$vuetify.breakpoint.mdAndDown, width: "90vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { tile: "" } },
        [
          _vm.overwatch
            ? _c(
                "cc-titlebar",
                { attrs: { large: "", color: "action--reaction" } },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("cci-reaction")
                  ]),
                  _vm._v("\n      Overwatch\n      "),
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "items", dark: "", icon: "" },
                      on: { click: _vm.hide },
                      slot: "items"
                    },
                    [
                      _c("v-icon", { attrs: { large: "", left: "" } }, [
                        _vm._v("close")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "cc-titlebar",
                { attrs: { large: "", color: "action--quick" } },
                [
                  _c("v-icon", { attrs: { "x-large": "", color: "white" } }, [
                    _vm._v("mdi-hexagon-slice-3")
                  ]),
                  _vm._v("\n      Skirmish\n      "),
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "items", dark: "", icon: "" },
                      on: { click: _vm.hide },
                      slot: "items"
                    },
                    [
                      _c("v-icon", { attrs: { large: "", left: "" } }, [
                        _vm._v("close")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.mdAndDown
            ? _c("v-spacer", { staticClass: "titlebar-margin" })
            : _vm._e(),
          _vm._v(" "),
          _vm.item && _vm.mount
            ? _c(
                "v-card-text",
                { staticClass: "mb-0 pb-2" },
                [
                  _c(
                    "weapon-attack",
                    {
                      ref: "main",
                      attrs: {
                        item: _vm.item,
                        mech: _vm.mech,
                        mount: _vm.mount,
                        overwatch: _vm.overwatch
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.confirmAttack($event)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "heading h2 mt-3 mb-n3" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mt-n2 mr-n1",
                              attrs: { "x-large": "" }
                            },
                            [_vm._v("cci-mech-weapon")]
                          ),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.item.Name) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-container", [
                    _vm.hasAux(_vm.mount, _vm.item)
                      ? _c(
                          "div",
                          { staticClass: "my-3" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "body-text text-center font-weight-bold"
                              },
                              [
                                _vm._v(
                                  "\n            You may make an additional attack with the following mounted Auxiliary weapon:\n            "
                                ),
                                _c(
                                  "div",
                                  { staticClass: "text-center overline my-n2" },
                                  [
                                    _vm._v(
                                      "This weapon cannot deal bonus damage."
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-alert",
                              {
                                staticClass: "my-1",
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "colored-border": "",
                                  color: "primary"
                                }
                              },
                              [
                                _c(
                                  "weapon-attack",
                                  {
                                    ref: "aux",
                                    staticClass: "mt-n3",
                                    attrs: {
                                      item: _vm.hasAux(_vm.mount, _vm.item),
                                      mech: _vm.mech,
                                      mount: _vm.mount,
                                      aux: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "heading h3 mt-3 mb-n3" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mt-n2 mr-n1",
                                            attrs: { large: "" }
                                          },
                                          [_vm._v("cci-mech-weapon")]
                                        ),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.hasAux(_vm.mount, _vm.item)
                                                .Name
                                            ) +
                                            "\n              "
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }