var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "4" } },
    [
      _c("v-lazy", [
        _c(
          "div",
          [
            _c("v-hover", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var hover = ref.hover
                    return [
                      _c(
                        "v-card",
                        {
                          staticClass: "clipped-large",
                          attrs: {
                            color: hover ? "panel lighten-1" : "panel",
                            tile: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.dialog.show()
                            }
                          }
                        },
                        [
                          _vm.item.ItemType === "Frame"
                            ? _c("frame-card-content", {
                                attrs: {
                                  item: _vm.item,
                                  hover: hover,
                                  small: _vm.small,
                                  "equipment-add": _vm.equipmentAdd
                                },
                                on: {
                                  add: function($event) {
                                    return _vm.$emit("add", $event)
                                  },
                                  equip: function($event) {
                                    return _vm.$emit("equip", $event)
                                  }
                                }
                              })
                            : _vm.item.ItemType === "MechWeapon"
                            ? _c("weapon-card-content", {
                                attrs: {
                                  item: _vm.item,
                                  hover: hover,
                                  small: _vm.small,
                                  "equipment-add": _vm.equipmentAdd
                                },
                                on: {
                                  add: function($event) {
                                    return _vm.$emit("add", $event)
                                  },
                                  equip: function($event) {
                                    return _vm.$emit("equip", $event)
                                  }
                                }
                              })
                            : _vm.item.ItemType === "MechSystem"
                            ? _c("system-card-content", {
                                attrs: {
                                  item: _vm.item,
                                  hover: hover,
                                  small: _vm.small,
                                  "equipment-add": _vm.equipmentAdd
                                },
                                on: {
                                  add: function($event) {
                                    return _vm.$emit("add", $event)
                                  },
                                  equip: function($event) {
                                    return _vm.$emit("equip", $event)
                                  }
                                }
                              })
                            : _c("gear-card-content", {
                                attrs: {
                                  item: _vm.item,
                                  hover: hover,
                                  small: _vm.small
                                },
                                on: {
                                  equip: function($event) {
                                    return _vm.$emit(
                                      _vm.equipmentAdd ? "add" : "equip",
                                      $event
                                    )
                                  }
                                }
                              })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: { title: _vm.item.Source + " " + _vm.item.Name, large: "" }
        },
        [_c("cc-item-card", { attrs: { item: _vm.item } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }