var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mb-4", attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { staticClass: "mr-2", attrs: { cols: "auto" } },
        [
          _c("v-img", {
            attrs: {
              src: _vm.frameImage,
              "max-width": "152px",
              "max-height": "206px",
              contain: ""
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        [
          _c(
            "v-row",
            {
              class:
                "pl-2 " + (_vm.isSelected ? "selected-gradient" : "gradient"),
              attrs: { dense: "" }
            },
            [
              _c(
                "v-col",
                { staticClass: "white--text", attrs: { cols: "auto" } },
                [
                  _c("div", { staticClass: "overline mt-n1" }, [
                    _vm._v(_vm._s(_vm.template.code))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "heading h1 mt-n6 mb-0 pb-0" }, [
                    _vm._v(_vm._s(_vm.template.name))
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "ml-auto", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-1",
                      attrs: {
                        tile: "",
                        outlined: "",
                        color: _vm.isSelected ? "accent" : "secondary"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("select")
                        }
                      }
                    },
                    [
                      !_vm.isSelected
                        ? _c("span", [
                            _vm._v(
                              "SELECT " +
                                _vm._s(_vm.template.name) +
                                " TEMPLATE"
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              _vm._s(_vm.template.name) + " TEMPLATE SELECTED"
                            )
                          ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.template.description,
                expression: "template.description"
              }
            ],
            staticClass: "mt-1 py-1 pl-2"
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "sidebar-box ml-2",
              style: "max-height:" + (_vm.expanded ? "100%" : "80px;")
            },
            [
              _c("div", { staticClass: "panel clipped py-1 px-2" }, [
                _c("div", { staticClass: "caption accent--text mt-1" }, [
                  _c("b", [_vm._v("TACTICS")])
                ]),
                _vm._v(" "),
                _c("p", {
                  directives: [
                    {
                      name: "html-safe",
                      rawName: "v-html-safe",
                      value: _vm.template.tactics,
                      expression: "template.tactics"
                    }
                  ],
                  staticClass: "pb-1 mb-0"
                })
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel clipped py-1 px-2 my-2" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "div",
                            { staticClass: "caption accent--text mt-1" },
                            [
                              _vm._v("\n              PILOT//\n              "),
                              _c("b", [_vm._v("SKILLS")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "px-2",
                              attrs: { dense: "", justify: "center" }
                            },
                            _vm._l(_vm.template.build.skills, function(s) {
                              return _c(
                                "v-col",
                                {
                                  key: _vm.template.name + s,
                                  staticClass: "mx-1",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c(
                                    "cc-tooltip",
                                    {
                                      attrs: {
                                        delayed: "",
                                        title:
                                          _vm.item("Skills", s).Name + " (+2)",
                                        content: _vm.item("Skills", s).Detail
                                      }
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "info",
                                            label: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.item("Skills", s).Name)
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "div",
                            { staticClass: "caption accent--text mt-1" },
                            [
                              _vm._v("\n              PILOT//\n              "),
                              _c("b", [_vm._v("TALENTS")])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "px-2",
                              attrs: { dense: "", justify: "center" }
                            },
                            _vm._l(_vm.template.build.talents, function(t) {
                              return _c(
                                "v-col",
                                {
                                  key: _vm.template.name + t,
                                  staticClass: "mx-1",
                                  attrs: { cols: "auto" }
                                },
                                [
                                  _c(
                                    "cc-tooltip",
                                    {
                                      attrs: {
                                        delayed: "",
                                        title:
                                          _vm.item("Talents", t).Name +
                                          " I: " +
                                          _vm.item("Talents", t).Rank(1).Name,
                                        content: _vm.item("Talents", t).Rank(1)
                                          .Description
                                      }
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "accent",
                                            label: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("cci-rank-1")]
                                          ),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.item("Talents", t).Name
                                              ) +
                                              "\n                  "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel clipped py-1 px-2 my-2" },
                [
                  _c("div", { staticClass: "caption accent--text mt-1" }, [
                    _vm._v("\n          GMS EVEREST//\n          "),
                    _c("b", [_vm._v("LOADOUT")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "space-around" } },
                    _vm._l(_vm.template.build.mech.mounts, function(m) {
                      return _c(
                        "v-col",
                        { key: _vm.template.name + m.mount_type },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "flavor-text stark--text text-center"
                            },
                            [_vm._v(_vm._s(m.mount_type) + " Mount")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            _vm._l(m.slots, function(w, i) {
                              return _c(
                                "v-col",
                                {
                                  key: _vm.template.name + w + i,
                                  staticClass: "text-center"
                                },
                                [
                                  _c("cc-item-modal", {
                                    staticClass: "mx-1",
                                    attrs: { item: _vm.item("MechWeapons", w) }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "flavor-text stark--text text-center mt-1" },
                    [_vm._v("Systems")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    _vm._l(_vm.template.build.mech.systems, function(s) {
                      return _c(
                        "v-col",
                        {
                          key: _vm.template.name + s,
                          staticClass: "text-center mx-2",
                          attrs: { cols: "auto" }
                        },
                        [
                          _c("cc-item-modal", {
                            attrs: { item: _vm.item("MechSystems", s) }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "read-more" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-n3",
                      staticStyle: {
                        "background-color": "var(--v-stark-panel-base)"
                      },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.expanded = !_vm.expanded
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { large: "", color: "accent" } }, [
                        _vm._v(
                          "mdi-chevron-double-" +
                            _vm._s(_vm.expanded ? "up" : "down")
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.expanded
                ? _c("div", { staticStyle: { "min-height": "40px" } })
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text-center" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }