var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-n3", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "", "no-gutters": "" } },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                {
                  staticClass: "pt-2",
                  staticStyle: { width: "12vw", position: "fixed" }
                },
                [
                  _vm._t("alt"),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    {
                      staticClass: "side-fixed",
                      style: _vm.popup
                        ? "top: 65px; padding-bottom: 65px;"
                        : "top: 125px; padding-bottom: 65px;",
                      attrs: { dense: "" }
                    },
                    [_vm._t("sidebar")],
                    2
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-7 mr-7" : "",
              style: _vm.$vuetify.breakpoint.mdAndUp ? "margin-left: 12vw" : ""
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }