var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("cloud-manager", {
        ref: "cloud",
        attrs: { pilot: _vm.pilot },
        on: {
          "start-sync": function($event) {
            _vm.syncing = true
          },
          "end-sync": function($event) {
            _vm.syncing = false
          }
        }
      }),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            icon: "mdi-cloud-outline",
            large: "",
            "no-confirm": "",
            title: "Cloud Download Settings"
          }
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    prominent: "",
                    dense: "",
                    icon: "mdi-alert",
                    "colored-border": "",
                    color: "panel"
                  }
                },
                [
                  _c("div", { staticClass: "heading h3" }, [
                    _c("b", [_vm._v("Cloud Service Update")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "body-text" }, [
                    _vm._v(
                      "\n          These tools are exclusively for the old, public cloud save service. Users with COMP/CON\n          accounts have their cloud exports managed automatically, synced to their local instance\n          of the app. Both systems are compatible, but\n          "
                    ),
                    _c("b", { staticClass: "accent--text" }, [
                      _vm._v(
                        "\n            public cloud data made prior to v. 2.2.6 must be re-synced\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n          If you have a COMP/CON account and want to sync pilot data with users who do not have\n          accounts, you can use this service to do so.\n          "
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n          Cloud Download will be deprecated and, eventually, removed after COMP/CON account\n          creation exits the Patreon-only phase and Vault Import becomes available to everyone.\n        "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c("v-slide-x-transition", {
                attrs: { group: "", mode: "in-out" }
              }),
              _vm._v(" "),
              !_vm.syncing
                ? _c("div", { key: "nosync", staticClass: "flavor-text" }, [
                    _c("span", { staticClass: "heading h3 accent--text" }, [
                      _vm._v("Pilot status: ")
                    ]),
                    _vm._v(" "),
                    !_vm.pilot.GistCode
                      ? _c("span", [_vm._v("No public data found")])
                      : _vm.pilot.IsUserOwned
                      ? _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.pilot.Callsign) +
                              "'s data is synced to\n          "
                          ),
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v("your")
                          ]),
                          _vm._v("\n          local data\n        ")
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.pilot.Callsign) +
                              "'s data is synced to\n          "
                          ),
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v("someone else's")
                          ]),
                          _vm._v("\n          data\n        ")
                        ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "heading h3 accent--text" }, [
                      _vm._v("Pilot Share Code: ")
                    ]),
                    _vm._v(" "),
                    !_vm.pilot.GistCode
                      ? _c("span", [_vm._v("No public data found")])
                      : _c(
                          "span",
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.pilot.GistCode) +
                                "\n          "
                            ),
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  simple: "",
                                  inline: "",
                                  content: "Copy Share Code to clipboard"
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: _vm.copyConfirm
                                        ? "success"
                                        : "grey"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.copyCode()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.copyConfirm
                                            ? "mdi-check-outline"
                                            : "mdi-clipboard-text-outline"
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-fade-transition", [
                              _vm.copyConfirm
                                ? _c("span", { staticClass: "subtle--text" }, [
                                    _vm._v("Copied!")
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "heading h3 accent--text" }, [
                      _vm._v("Last Update: ")
                    ]),
                    _vm._v(" "),
                    !_vm.pilot.GistCode
                      ? _c("span", [_vm._v("Never")])
                      : !_vm.pilot.IsLocallyOwned
                      ? _c("span", [
                          _vm._v(
                            "Last updated at " + _vm._s(_vm.pilot.LastSync)
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "Local data updated at " +
                              _vm._s(_vm.pilot.LastSync)
                          )
                        ])
                  ])
                : _c(
                    "div",
                    { key: "syncing" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-progress-circular", {
                            staticClass: "ml-auto mr-auto",
                            attrs: {
                              size: 70,
                              width: 7,
                              color: "secondary",
                              indeterminate: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _vm._v(" "),
              _c("v-divider", { staticClass: "my-2" }),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            block: "",
                            tile: "",
                            outlined: "",
                            color: "accent",
                            disabled:
                              !_vm.pilot.IsUserOwned || !_vm.pilot.GistCode
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.cloud.save()
                            }
                          }
                        },
                        [_vm._v("\n            Publish pilot data\n          ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-n1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            content:
                              "Saves local pilot data to the Cloud, <b class='accent--text'>overwriting</b> any cloud data that currently exists. " +
                              (!_vm.pilot.IsUserOwned
                                ? "Because this pilot did not originate from you, you are unable to overwrite its cloud data. Using the 'Create New Record' option will allow you to create a unique Cloud copy of this pilot you can save over."
                                : "")
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mt-1 ml-n3" }, [
                            _vm._v("mdi-information-outline")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            block: "",
                            tile: "",
                            outlined: "",
                            color: "accent",
                            disabled: !_vm.pilot.GistCode
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.cloud.load()
                            }
                          }
                        },
                        [_vm._v("\n            Load Pilot Data\n          ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-n1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            content:
                              "Loads local pilot data from the Cloud, <b class='accent--text'>overwriting any local changes that don't exist on the cloud save</b>"
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mt-1 ml-n3" }, [
                            _vm._v("mdi-information-outline")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            large: "",
                            block: "",
                            tile: "",
                            outlined: "",
                            color: "accent"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.cloud.new()
                            }
                          }
                        },
                        [_vm._v("\n            Create new record\n          ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-n1", attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            simple: "",
                            inline: "",
                            content:
                              "This option creates a new, unique pilot cloud record and links that record to this pilot data on your system. " +
                              (_vm.pilot.GistCode
                                ? "Selecting this option will <b class='accent--text'>break the link</b> between this pilot and the current record it is linked with"
                                : "")
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "mt-1 ml-n4" }, [
                            _vm._v("mdi-information-outline")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }