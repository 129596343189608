var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pl-card-base",
    {
      ref: "base",
      attrs: {
        title: "PILOT GEAR",
        extended: _vm.extended,
        item: _vm.item,
        readonly: _vm.readonly
      },
      on: {
        remove: function($event) {
          return _vm.$emit("remove")
        }
      }
    },
    [
      _vm.item
        ? _c(
            "div",
            {
              staticClass: "text-left",
              staticStyle: { cursor: "pointer!important", height: "100%" },
              on: {
                click: function($event) {
                  return _vm.$refs.base.openDetail()
                }
              }
            },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", [
                    _c(
                      "span",
                      {
                        key: _vm.item.Name,
                        staticClass: "h2 heading text--text",
                        staticStyle: { "line-height": "35px" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.item.Name) +
                            "\n          "
                        ),
                        _vm.item.Note
                          ? _c(
                              "cc-tooltip",
                              {
                                key: _vm.item.Note.length,
                                attrs: {
                                  simple: "",
                                  inline: "",
                                  content: _vm.item.Note
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "active" } },
                                  [_vm._v("mdi-note")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto text-right mt-n2 mb-n2",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("div", { staticClass: "overline" }, [
                        _vm._v("ITEM USES")
                      ]),
                      _vm._v(" "),
                      !_vm.item.MaxUses
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "mt-n3 mr-2",
                              attrs: { color: "secondary" }
                            },
                            [_vm._v("mdi-infinity")]
                          )
                        : _c("cc-item-uses", {
                            staticClass: "mt-n3",
                            attrs: { item: _vm.item, color: "secondary" }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              !_vm.readonly
                ? _c(
                    "v-row",
                    {
                      staticClass: "mt-2",
                      style:
                        "max-height: " +
                        (_vm.$vuetify.breakpoint.smAndDown ? "125" : "200") +
                        "px; overflow-y: scroll",
                      attrs: { dense: "" }
                    },
                    [
                      _c("p", {
                        directives: [
                          {
                            name: "html-safe",
                            rawName: "v-html-safe",
                            value: _vm.item.Description,
                            expression: "item.Description"
                          }
                        ],
                        staticClass: "text--text"
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto mr-4 mt-n2",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-tags", {
                        attrs: {
                          small: "",
                          tags: _vm.item.Tags,
                          color: "secondary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-card-text",
        { attrs: { slot: "selector" }, slot: "selector" },
        [
          _c(
            "cc-selector-table",
            {
              attrs: {
                "no-filter": "",
                "no-extra": "",
                items: _vm.getGear(),
                headers: _vm.headers
              },
              on: {
                equip: function($event) {
                  return _vm.equip($event)
                }
              }
            },
            [
              _vm.item
                ? _c("div", [
                    _c("span", { staticClass: "overline" }, [
                      _vm._v(
                        "\n          GMS EQUIPMENT CATALOG PRINTID: " +
                          _vm._s(_vm.fID("ANN-NNN-NNN::AA//AA")) +
                          " —\n          "
                      ),
                      _c(
                        "span",
                        { staticClass: "success--text text--darken-1" },
                        [
                          _vm._v(
                            "\n            [ PILOT EQUIPMENT REGISTRATION VERIFIED ]\n          "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "heading h1 accent--text",
                        staticStyle: { "line-height": "20px" }
                      },
                      [_vm._v(_vm._s(_vm.item.Name))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "flavor-text overline mt-n1",
                        staticStyle: { display: "block" }
                      },
                      [_vm._v("CURRENTLY EQUIPPED")]
                    )
                  ])
                : _c("div", [
                    _c("span", { staticClass: "overline" }, [
                      _vm._v(
                        "GMS EQUIPMENT AUTHORIZATION: PILOT/ADDITIONAL GEAR (ANY)"
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "heading h1 subtle--text text--lighten-1",
                        staticStyle: { "line-height": "20px" }
                      },
                      [_vm._v("\n          NO SELECTION\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "flavor-text overline mt-n1 error--text",
                        staticStyle: { display: "block" }
                      },
                      [
                        _vm._v(
                          "\n          [ EQUIPMENT ID INVALID OR MISSING ]\n        "
                        )
                      ]
                    )
                  ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }