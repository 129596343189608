var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.pilot.Status === "KIA" || _vm.pilot.IsDead
        ? _c(
            "v-alert",
            {
              attrs: { prominent: "", dense: "", outlined: "", color: "error" }
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-2",
                  attrs: { slot: "prepend", size: "80" },
                  slot: "prepend"
                },
                [_vm._v("mdi-skull")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "heading h1 pb-2 text-center" }, [
                _vm._v("KILLED IN ACTION")
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { position: "relative" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      bottom: "-3px",
                      right: "-3px"
                    }
                  },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "", "offset-x": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: "secondary",
                                          "x-small": "",
                                          outlined: ""
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n              Flash Clone Pilot\n            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1785822695
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("cc-confirmation", {
                          attrs: {
                            content:
                              "This will clone the selected pilot. Cloned characters can’t join a mission in progress, and cloned characters receive a random quirk. Additional cloning and subjectivity imprinting adds further quirks."
                          },
                          on: { confirm: _vm.setQuirk }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "", "offset-x": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "fadeSelect ml-3",
                                        attrs: {
                                          color: "pimary",
                                          "x-small": ""
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", left: "" } },
                                        [_vm._v("mdi-reload")]
                                      ),
                                      _vm._v(
                                        "\n              Revert\n            "
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3477047339
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("cc-confirmation", {
                          attrs: {
                            content:
                              "This will restore the selected pilot and clear the KIA and Down and Out statuses."
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.pilot.Restore()
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pilot.Quirks.length && !_vm.hideQuirks
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "flavor-text font-weight-bold stark--text" },
                [_vm._v("CLONE QUIRKS")]
              ),
              _vm._v(" "),
              _vm._l(_vm.pilot.Quirks, function(q, i) {
                return _c(
                  "v-row",
                  {
                    key: "clone_quirk_" + i,
                    attrs: { dense: "", align: "start" }
                  },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              icon: "mdi-dna",
                              prominent: "",
                              dense: "",
                              color: "primary",
                              outlined: ""
                            }
                          },
                          [
                            _c("v-textarea", {
                              staticClass: "body-text",
                              attrs: {
                                value: q,
                                dense: "",
                                "hide-details": "",
                                rows: "1",
                                "auto-grow": "",
                                readonly: _vm.readonly
                              },
                              on: {
                                change: function($event) {
                                  return _vm.updateQuirk(i, $event)
                                },
                                blur: function($event) {
                                  return _vm.pilot.save()
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.readonly
                      ? _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-menu", {
                              attrs: { "offset-y": "", "offset-x": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      return [
                                        _c(
                                          "cc-tooltip",
                                          {
                                            attrs: {
                                              content: "Remove Clone Quirk"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "fadeSelect",
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.pilot.RemoveQuirk(
                                                        i
                                                      )
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { large: "" } },
                                                  [_vm._v("close")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }