var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "my-2",
          attrs: { "x-large": "", block: "", color: "accent" },
          on: {
            click: function($event) {
              return _vm.$refs.dialog.show()
            }
          }
        },
        [
          _c("v-icon", { attrs: { left: "", large: "" } }, [
            _vm._v("cci-accuracy")
          ]),
          _vm._v("\n    Add New Pilot\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            icon: "cci-pilot",
            "no-confirm": "",
            large: "",
            title: "Register Pilot"
          }
        },
        [
          _c(
            "v-container",
            [
              _c("cc-major-btn", {
                attrs: { icon: "cci-pilot", name: "Create New Pilot" },
                on: {
                  clicked: function($event) {
                    return _vm.$router.push("new")
                  }
                }
              }),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("file-import", {
                    on: {
                      done: function($event) {
                        return _vm.$refs.dialog.hide()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("cloud-import", {
                    attrs: { disabled: !_vm.currentAuthedUser },
                    on: {
                      done: function($event) {
                        return _vm.$refs.dialog.hide()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("cloud-import-old", {
                    on: {
                      done: function($event) {
                        return _vm.$refs.dialog.hide()
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-alert",
                {
                  attrs: {
                    prominent: "",
                    dense: "",
                    icon: "mdi-alert",
                    "colored-border": "",
                    color: "panel"
                  }
                },
                [
                  _c("div", { staticClass: "heading h3" }, [
                    _c("b", [_vm._v("Cloud Service Update")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "body-text" }, [
                    _vm._v(
                      "\n          Vault Import allows for seamless, automatic syncing of remote pilots and is only\n          available for users with COMP/CON accounts. Cloud Importing/Exporting pilots can still\n          be done the old, manual, way via Cloud Download. Both systems are compatible, but\n          "
                    ),
                    _c("b", { staticClass: "accent--text" }, [
                      _vm._v(
                        "\n            public cloud data made prior to v. 2.2.6 must be re-synced\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n          Cloud Download will be deprecated and, eventually, removed after COMP/CON account\n          creation exits the Patreon-only phase and Vault Import becomes available to everyone.\n        "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }