import { render, staticRenderFns } from "./_SlotCardBase.vue?vue&type=template&id=2934a3c4&scoped=true&"
import script from "./_SlotCardBase.vue?vue&type=script&lang=ts&"
export * from "./_SlotCardBase.vue?vue&type=script&lang=ts&"
import style0 from "./_SlotCardBase.vue?vue&type=style&index=0&id=2934a3c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2934a3c4",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2934a3c4')) {
      api.createRecord('2934a3c4', component.options)
    } else {
      api.reload('2934a3c4', component.options)
    }
    module.hot.accept("./_SlotCardBase.vue?vue&type=template&id=2934a3c4&scoped=true&", function () {
      api.rerender('2934a3c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/mech_loadout/components/_SlotCardBase.vue"
export default component.exports