





















































































































import Vue from 'vue'

import StatblockDialog from '../../../components/StatblockDialog.vue'
import PrintDialog from '../../../components/PrintDialog.vue'

export default Vue.extend({
  name: 'mech-nav',
  components: { StatblockDialog, PrintDialog },
  props: {
    pilot: {
      type: Object,
      required: true,
    },
    selected: {
      type: Number,
      required: true,
    },
  },
  methods: {
    toPilotSheet() {
      this.$router.push({ name: 'pilot_sheet' })
    },
  },
})
