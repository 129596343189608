var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "", align: "start" } },
        [
          _c("single", {
            attrs: { cols: "5", label: "CALLSIGN", height: "60" }
          }),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "1", label: "HULL", height: "60" } }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "1", label: "AGI", height: "60" } }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "1", label: "SYS", height: "60" } }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "1", label: "ENG", height: "60" } }),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mx-2", attrs: { vertical: "" } }),
          _vm._v(" "),
          _c("single", {
            attrs: { cols: "auto", label: "LEVEL", height: "60" }
          }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "1", label: "GRIT", height: "60" } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", align: "start" } },
        [
          _c("single", { attrs: { cols: "3", label: "NAME", height: "20" } }),
          _vm._v(" "),
          _c("single", {
            attrs: { cols: "3", label: "BACKGROUND", height: "20" }
          }),
          _vm._v(" "),
          _c("single", { attrs: { cols: "2", label: "STATUS", height: "20" } }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("single", { attrs: { cols: "3", label: "PLAYER", height: "20" } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "", justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("div", { staticClass: "overline mb-n1" }, [
                        _vm._v("HP")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        {
                          attrs: {
                            dense: "",
                            "no-gutters": "",
                            align: "center"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-n1",
                                  attrs: { size: "60", color: "grey lighten-3" }
                                },
                                [_vm._v("mdi-hexagon-outline")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "mt-6 mr-n2",
                              attrs: { cols: "auto" }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "subtle--text text--lighten-1 display-2",
                                  staticStyle: { "line-height": "0" }
                                },
                                [_vm._v("/")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "mb-n2", attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-n1",
                                  attrs: { size: "50", color: "grey lighten-3" }
                                },
                                [_vm._v("mdi-hexagon-outline")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("div", { staticClass: "overline mb-n1" }, [
                        _vm._v("ARMOR")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        { attrs: { size: "60", color: "grey lighten-3" } },
                        [_vm._v("mdi-shield-outline")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("div", { staticClass: "overline mb-n1" }, [
                        _vm._v("EDEF")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        { attrs: { size: "60", color: "grey lighten-3" } },
                        [_vm._v("cci-marker")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("div", { staticClass: "overline mb-n1" }, [
                        _vm._v("EVASION")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        { attrs: { size: "60", color: "grey lighten-3" } },
                        [_vm._v("cci-evasion")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("div", { staticClass: "overline mb-n1" }, [
                        _vm._v("SPEED")
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        { attrs: { size: "60", color: "grey lighten-3" } },
                        [_vm._v("$vuetify.icons.move")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { align: "start" } },
        [
          _c(
            "v-col",
            [
              _c("span", { staticClass: "overline" }, [
                _vm._v("LICENSES ACQUIRED")
              ]),
              _vm._v(" "),
              _vm._l(6, function(n) {
                return _c(
                  "v-row",
                  {
                    key: "license_" + n,
                    attrs: { dense: "", "no-gutters": "" }
                  },
                  [
                    _c("single", {
                      attrs: { cols: "11", label: "LICENSE", height: "25" }
                    }),
                    _vm._v(" "),
                    _c("single", {
                      attrs: { cols: "1", label: "RANK", height: "25" }
                    })
                  ],
                  1
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("span", { staticClass: "overline" }, [
                _vm._v("SKILL TRIGGERS")
              ]),
              _vm._v(" "),
              _vm._l(6, function(n) {
                return _c(
                  "v-row",
                  { key: "skill_" + n, attrs: { dense: "", "no-gutters": "" } },
                  [
                    _c("single", {
                      attrs: { cols: "10", label: "SKILL", height: "25" }
                    }),
                    _vm._v(" "),
                    _c("single", {
                      attrs: { cols: "2", label: "BONUS", height: "25" }
                    })
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c("span", { staticClass: "overline" }, [_vm._v("TALENTS")]),
      _vm._v(" "),
      _vm._l(2, function(n) {
        return _c(
          "v-row",
          {
            key: "talent_" + n,
            attrs: { dense: "", justify: "space-between", "no-gutters": "" }
          },
          _vm._l(2, function(x) {
            return _c(
              "v-col",
              { key: "talent_" + n + "_" + x },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c("single", {
                      attrs: { cols: "7", label: "TALENT", height: "20" }
                    }),
                    _vm._v(" "),
                    _c("single", {
                      attrs: { cols: "1", label: "RANK", height: "20" }
                    }),
                    _vm._v(" "),
                    _c("single", {
                      staticClass: "mt-n2",
                      attrs: { cols: "12", label: "DESCRIPTION", height: "150" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          1
        )
      }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-2" }),
      _vm._v(" "),
      _c("div", { staticStyle: { "page-break-before": "always" } }),
      _vm._v(" "),
      _c("span", { staticClass: "overline" }, [_vm._v("PILOT EQUIPMENT")]),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-n2",
          attrs: { dense: "", justify: "space-between" }
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("single", {
                    staticClass: "mb-n1",
                    attrs: { cols: "12", label: "ARMOR", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    attrs: { cols: "3", label: "HP+", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    attrs: { cols: "3", label: "EDEF", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    attrs: { cols: "3", label: "EVADE", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    attrs: { cols: "3", label: "SPD", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    staticClass: "mt-n2",
                    attrs: { cols: "12", label: "EFFECTS", height: "100" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(2, function(n) {
            return _c(
              "v-col",
              { key: "weapon_" + n },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _c("single", {
                      staticClass: "mb-n1",
                      attrs: { cols: "12", label: "WEAPON", height: "20" }
                    }),
                    _vm._v(" "),
                    _c("single", {
                      attrs: { cols: "4", label: "RANGE", height: "20" }
                    }),
                    _vm._v(" "),
                    _c("single", {
                      attrs: { cols: "8", label: "DAMAGE//TYPE", height: "20" }
                    }),
                    _vm._v(" "),
                    _c("single", {
                      staticClass: "mt-n2",
                      attrs: { cols: "12", label: "EFFECTS", height: "100" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "space-between" } },
        _vm._l(3, function(n) {
          return _c(
            "v-col",
            { key: "gear_" + n },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("single", {
                    staticClass: "mb-n1",
                    attrs: { cols: "9", label: "GEAR", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    attrs: { cols: "2", label: "USES", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    staticClass: "mt-n2",
                    attrs: { cols: "12", label: "EFFECTS", height: "100" }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-2" }),
      _vm._v(" "),
      _c("span", { staticClass: "overline" }, [_vm._v("RESERVES")]),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "space-between" } },
        _vm._l(4, function(n) {
          return _c(
            "v-col",
            { key: "gear_" + n, attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("single", {
                    staticClass: "mb-n1",
                    attrs: { cols: "10", label: "RESOURCE", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    attrs: { cols: "1", label: "USED", height: "20" }
                  }),
                  _vm._v(" "),
                  _c("single", {
                    staticClass: "mt-n2",
                    attrs: { cols: "12", label: "DESCRIPTION", height: "175" }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { "page-break-before": "always" } }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("single", {
            staticClass: "mt-n2",
            attrs: { cols: "12", label: "NOTES", height: "225" }
          }),
          _vm._v(" "),
          _c("single", {
            attrs: { cols: "12", label: "RESOURCES", height: "225" }
          }),
          _vm._v(" "),
          _c("single", {
            attrs: { cols: "12", label: "CONTACTS", height: "225" }
          }),
          _vm._v(" "),
          _c("single", {
            attrs: { cols: "12", label: "MISSION LOG", height: "225" }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }