













































































































import Vue from 'vue'
import PanelView from '../../components/PanelView.vue'
import { getModule } from 'vuex-module-decorators'
import { MissionStore } from '@/store'
import { Mission } from '@/class'

export default Vue.extend({
  name: 'mission-manager',
  components: { PanelView },
  data: () => ({
    search: '',
    selectedMission: null,
    grouping: null,
    headers: [{ text: 'Name', value: 'Name', align: 'left' }],
    missions: [],
  }),
  computed: {
    labels() {
      return this.Missions.flatMap(x => x.Labels).filter(x => x != null && x != '')
    },
    campaigns() {
      return this.Missions.map(x => x.Campaign).filter(x => x != null && x != '')
    },
  },
  watch: {
    selectedMission() {
      this.$refs.view.resetScroll()
    },
  },
  created() {
    const store = getModule(MissionStore, this.$store)
    this.missions = store.Missions
  },
  methods: {
    toMission(id: string) {
      this.$router.push({ name: 'edit-mission', params: { id } })
    },
    deleteMission(Mission: Mission) {
      const store = getModule(MissionStore, this.$store)
      store.deleteMission(Mission)
      this.$store.dispatch('cloudSync', { callback: null, condition: 'missionDelete' })
    },
    copyMission(Mission: Mission) {
      const store = getModule(MissionStore, this.$store)
      store.cloneMission(Mission)
      this.$store.dispatch('cloudSync', { callback: null, condition: 'missionCreate' })
    },
    addNew() {
      const store = getModule(MissionStore, this.$store)
      store.addMission(new Mission())
      this.$store.dispatch('cloudSync', { callback: null, condition: 'missionCreate' })
      const m = this.missions[this.missions.length - 1].ID
      this.$router.push({ name: 'edit-mission', params: { id: m } })
    },
  },
})
