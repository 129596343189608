var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "", "close-on-content-click": false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-icon",
                _vm._g({ staticClass: "fadeSelect", attrs: { small: "" } }, on),
                [_vm._v("mdi-circle-edit-outline")]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            [
              _c("v-combobox", {
                attrs: {
                  autofocus: "",
                  "background-color": "stark-panel",
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  items: _vm.items,
                  "item-value": "value"
                },
                model: {
                  value: _vm.sel,
                  callback: function($$v) {
                    _vm.sel = $$v
                  },
                  expression: "sel"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }