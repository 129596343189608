var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    {
      staticStyle: {
        "padding-bottom": "2px",
        "border-top": "2px solid var(--v-primary-base)"
      },
      attrs: { fixed: "" }
    },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "80vw" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "mr-5",
                        staticStyle: {
                          "border-color": "var(--v-warning-base)"
                        },
                        attrs: {
                          outlined: "",
                          small: "",
                          disabled: _vm.pilot.IsDead || _vm.pilot.IsDownAndOut
                        }
                      },
                      on
                    ),
                    [_vm._v("\n        START MISSION\n      ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.scDialog,
            callback: function($$v) {
              _vm.scDialog = $$v
            },
            expression: "scDialog"
          }
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    dense: "",
                    dark: "",
                    flat: "",
                    tile: "",
                    color: "warning darken-3 heading h2"
                  }
                },
                [_vm._v("\n        START MISSION\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("p", { staticClass: "flavor-text mt-2 mb-0 mx-6" }, [
                    _vm._v("\n          >//[\n          "),
                    _c("span", { staticClass: "accent--text" }, [
                      _vm._v("COMP/CON")
                    ]),
                    _vm._v("\n          :\n          "),
                    _c("span", { staticClass: "stark--text" }, [
                      _vm._v("Active Protocols Standing By")
                    ]),
                    _vm._v(
                      "\n          ] Pilot, proceeding will engage COMP/CON ACTIVE MODE, which will assist with running\n          LANCER Missions and Encounters.\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-alert",
                    {
                      staticClass: "mt-4",
                      attrs: {
                        dense: "",
                        outlined: "",
                        color: _vm.pilot.ActiveMech
                          ? _vm.pilot.ActiveMech.Frame.Manufacturer.Color
                          : "primary"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { justify: "start", "no-gutters": "" } },
                        [
                          _c("v-col", [
                            _c("div", { staticClass: "overline text--text" }, [
                              _vm._v("ACTIVE MECH")
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "heading h1" },
                              [
                                _vm.pilot.ActiveMech
                                  ? _c("cc-logo", {
                                      staticClass: "mb-n3 mt-n3 mr-n2",
                                      attrs: {
                                        source:
                                          _vm.pilot.ActiveMech.Frame
                                            .Manufacturer,
                                        size: "xLarge"
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.pilot.ActiveMech
                                  ? _c(
                                      "span",
                                      {
                                        class: _vm.pilot.ActiveMech.Destroyed
                                          ? "text-decoration-line-through"
                                          : ""
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.pilot.ActiveMech.Name) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  : _c("div", { staticClass: "pb-3" }, [
                                      _vm._v("NO ACTIVE MECH")
                                    ])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.pilot.ActiveMech
                              ? _c(
                                  "div",
                                  { staticClass: "overline text--text ml-4" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.pilot.ActiveMech.Frame.Source
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.pilot.ActiveMech.Frame.Name
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _vm.pilot.Mechs.length
                                ? _c("mech-select-button", {
                                    attrs: { mechs: _vm.pilot.Mechs },
                                    on: {
                                      select: function($event) {
                                        _vm.pilot.State.ActiveMech = $event
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.pilot.ActiveMech
                    ? _c(
                        "div",
                        [
                          _vm.pilot.ActiveMech.StatusString.length
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "flavor-text stark--text mt-2 mx-6 mb-0"
                                },
                                [
                                  _vm._v("\n            >//[\n            "),
                                  _c("span", { staticClass: "accent--text" }, [
                                    _vm._v("COMP/CON")
                                  ]),
                                  _vm._v("\n            :\n            "),
                                  _c("span", { staticClass: "stark--text" }, [
                                    _vm._v("Frame Issues Detected")
                                  ]),
                                  _vm._v(
                                    "\n            ] Pilot, COMP/CON has detected one ore more issues with the selected mech. If these\n            issues are not addressed, your mech may operate at reduced combat efficacy. Caution is\n            advised.\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "", justify: "center" } },
                            _vm._l(_vm.pilot.ActiveMech.StatusString, function(
                              s
                            ) {
                              return _c(
                                "v-col",
                                { key: "status-" + s, attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "px-10" },
                                    [
                                      _c("cc-mech-status-alert", {
                                        attrs: { type: s, readonly: "" },
                                        on: {
                                          reprint: function($event) {
                                            return _vm.pilot.ActiveMech.FullRepair()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-2",
                              attrs: { justify: "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-large": "",
                                        tile: "",
                                        color: "primary",
                                        disabled: _vm.startDisabled
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.scDialog = false
                                          _vm.pilot.State.StartMission()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { large: "", left: "" } },
                                        [_vm._v("cci-activate")]
                                      ),
                                      _vm._v(
                                        "\n                 Engage Combat Mode\n              "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("span", { staticClass: "flavor-text" }, [
        _vm._v("\n    >//[\n    "),
        _c("span", { staticClass: "accent--text" }, [_vm._v("COMP/CON")]),
        _vm._v("\n    :\n    "),
        _c("span", { staticClass: "stark-text--text" }, [
          _vm._v("Downtime Mode Active")
        ]),
        _vm._v("\n    ]\n  ")
      ]),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "cc-tooltip",
        { attrs: { inline: "", content: "Downtime Actions", delayed: "" } },
        [
          _c(
            "downtime-menu-button",
            {
              attrs: {
                actions: _vm.pilot.State.DowntimeActions,
                pilot: _vm.pilot,
                available: "",
                color: "action--downtime",
                title: "DOWNTIME ACTIONS"
              },
              on: {
                "open-menu": function($event) {
                  return _vm.$refs.dialog.show()
                },
                "open-dialog": function($event) {
                  return _vm.openDialog($event)
                }
              }
            },
            [
              _c(
                "v-icon",
                {
                  attrs: { slot: "icon", color: "white", size: "35" },
                  slot: "icon"
                },
                [_vm._v("cci-downtime")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.pilot.State.DowntimeActions, function(a) {
        return _c("cc-downtime-dialog", {
          key: "fa_" + a.ID,
          ref: "dialog_" + a.ID,
          refInFor: true,
          attrs: { action: a, pilot: _vm.pilot }
        })
      }),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            title: "Downtime Actions",
            icon: "cci-downtime",
            color: "action--downtime",
            "no-actions": "",
            fullscreen: ""
          }
        },
        [
          _c("downtime-menu", {
            on: {
              close: function($event) {
                return _vm.$refs.dialog.hide()
              }
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }