var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "px-2 pt-2 pb-1 ml-n3 clipped" + _vm.size,
      style: "width: max-content; background-color: " + _vm.getColor() + ";"
    },
    [
      _c(
        "span",
        {
          staticClass: "pl-5 pr-5 pb-1",
          class:
            "ctitle" +
            _vm.size +
            " " +
            (_vm.dark ? "white--text" : "stark--text")
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }