var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    [
      _c(
        "div",
        { staticClass: "ml-n3" },
        [
          _c(
            "cc-title",
            {
              staticClass: "pl-3 ml-n6",
              attrs: { small: "", color: _vm.color }
            },
            [_vm._v("\n      Mech Attributes\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "mr-3", attrs: { cols: "12", md: "1" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "space-between" } },
                [
                  _c("hase-pips", {
                    attrs: {
                      mech: _vm.mech,
                      attr: "hull",
                      val: _vm.pilot.MechSkills.Hull,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c("hase-pips", {
                    attrs: {
                      mech: _vm.mech,
                      attr: _vm.small ? "agi" : "agility",
                      val: _vm.pilot.MechSkills.Agi,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c("hase-pips", {
                    attrs: {
                      mech: _vm.mech,
                      attr: _vm.small ? "sys" : "systems",
                      val: _vm.pilot.MechSkills.Sys,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c("hase-pips", {
                    attrs: {
                      mech: _vm.mech,
                      attr: _vm.small ? "eng" : "engineering",
                      val: _vm.pilot.MechSkills.Eng,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto", md: "12" } },
                    [
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c("v-divider", { staticClass: "mt-2" })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$vuetify.breakpoint.lgAndUp
                        ? _c("span", { staticClass: "overline no-height" }, [
                            _vm._v("System Points")
                          ])
                        : _c("span", { staticClass: "overline no-height" }, [
                            _vm._v("SP")
                          ]),
                      _vm._v(" "),
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            title: _vm.mech.MaxSP + " System Points",
                            content: _vm.mech.SPContributors.join("<br />")
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3 no-height" }, [
                            _vm._v(" " + _vm._s(_vm.mech.MaxSP))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "", align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("statblock-item", {
                            attrs: {
                              attr: _vm.small ? "Struct" : "Structure",
                              val: _vm.mech.MaxStructure,
                              contributors: _vm.mech.StructureContributors,
                              color: _vm.color
                            }
                          }),
                          _vm._v(" "),
                          _c("statblock-item", {
                            attrs: {
                              attr: "HP",
                              val: _vm.mech.MaxHP,
                              contributors: _vm.mech.HPContributors,
                              color: _vm.color
                            }
                          }),
                          _vm._v(" "),
                          _c("statblock-item", {
                            attrs: {
                              attr: "Armor",
                              val: _vm.mech.Armor,
                              contributors: _vm.mech.ArmorContributors,
                              color: _vm.color
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("statblock-item", {
                            attrs: {
                              attr: "Stress",
                              val: _vm.mech.MaxStress,
                              contributors: _vm.mech.StressContributors,
                              color: _vm.color
                            }
                          }),
                          _vm._v(" "),
                          _c("statblock-item", {
                            attrs: {
                              attr: _vm.small
                                ? "heatcap"
                                : _vm.$vuetify.breakpoint.lgAndUp
                                ? "Heat Capacity"
                                : "Heat Cap",
                              val: _vm.mech.HeatCapacity,
                              contributors: _vm.mech.HeatCapContributors,
                              color: _vm.color
                            }
                          }),
                          _vm._v(" "),
                          _c("statblock-item", {
                            attrs: {
                              attr: _vm.small
                                ? "repcap"
                                : _vm.$vuetify.breakpoint.lgAndUp
                                ? "Repair Capacity"
                                : "Repair Cap",
                              val: _vm.mech.RepairCapacity,
                              contributors: _vm.mech.RepCapContributors,
                              color: _vm.color
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.small
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "px-4 mt-n2",
                              attrs: { size: "120", color: _vm.color }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.mech.SizeIcon) +
                                  "\n          "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("statblock-item", {
                    attrs: {
                      attr: _vm.$vuetify.breakpoint.lgAndUp
                        ? "Attack Bonus"
                        : "Atk Bonus",
                      signed: "",
                      val: _vm.mech.AttackBonus,
                      contributors: _vm.mech.AttackBonusContributors,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c("statblock-item", {
                    attrs: {
                      attr: _vm.$vuetify.breakpoint.lgAndUp
                        ? "Tech Attack"
                        : "Tech Atk",
                      signed: "",
                      val: _vm.mech.TechAttack,
                      contributors: _vm.mech.TechAttackContributors,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c("statblock-item", {
                    attrs: {
                      attr: _vm.$vuetify.breakpoint.lgAndUp
                        ? "Limited System Bonus"
                        : "Limited Bonus",
                      signed: "",
                      val: _vm.mech.LimitedBonus,
                      contributors: _vm.mech.LimitedContributors,
                      color: _vm.color
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("statblock-item", {
                    attrs: {
                      attr: "Speed",
                      val: _vm.mech.Speed,
                      contributors: _vm.mech.SpeedContributors,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c("statblock-item", {
                    attrs: {
                      attr: _vm.small ? "evade" : "Evasion",
                      val: _vm.mech.Evasion,
                      contributors: _vm.mech.EvasionContributors,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c("statblock-item", {
                    attrs: {
                      attr: _vm.$vuetify.breakpoint.lgAndUp
                        ? "E-Defense"
                        : "E-Def",
                      val: _vm.mech.EDefense,
                      contributors: _vm.mech.EDefenseContributors,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c("statblock-item", {
                    attrs: {
                      attr: _vm.small
                        ? "sensor"
                        : _vm.$vuetify.breakpoint.lgAndUp
                        ? "Sensor Range"
                        : "Sensors",
                      val: _vm.mech.SensorRange,
                      contributors: _vm.mech.SensorRangeContributors,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _c("statblock-item", {
                    attrs: {
                      attr: _vm.$vuetify.breakpoint.lgAndUp
                        ? "Save Target"
                        : "Save",
                      val: _vm.mech.SaveTarget,
                      contributors: _vm.mech.SaveTargetContributors,
                      color: _vm.color
                    }
                  }),
                  _vm._v(" "),
                  _vm.small
                    ? _c("statblock-item", {
                        attrs: {
                          attr: "Size",
                          val: _vm.mech.Size,
                          contributors: _vm.mech.SizeContributors,
                          color: _vm.color
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }