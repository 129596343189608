var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", md: "7" } },
        [
          _c(
            "cc-titled-panel",
            {
              attrs: {
                title: "Custom Reserve",
                icon: "cci-reserve-" + _vm.customType.toLowerCase(),
                color: "reserve--" + _vm.customType.toLowerCase()
              }
            },
            [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      attrs: {
                        tile: "",
                        mandatory: "",
                        group: _vm.$vuetify.breakpoint.mdAndUp,
                        color: "secondary"
                      },
                      model: {
                        value: _vm.customType,
                        callback: function($$v) {
                          _vm.customType = $$v
                        },
                        expression: "customType"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: _vm.$vuetify.breakpoint.smAndDown,
                            value: "Resource"
                          }
                        },
                        [_vm._v("\n            Resource\n          ")]
                      ),
                      _vm._v(" "),
                      _c("v-divider", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$vuetify.breakpoint.mdAndUp,
                            expression: "$vuetify.breakpoint.mdAndUp"
                          }
                        ],
                        staticClass: "mx-2",
                        attrs: { vertical: "" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: _vm.$vuetify.breakpoint.smAndDown,
                            value: "Mech"
                          }
                        },
                        [_vm._v("Mech")]
                      ),
                      _vm._v(" "),
                      _c("v-divider", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$vuetify.breakpoint.mdAndUp,
                            expression: "$vuetify.breakpoint.mdAndUp"
                          }
                        ],
                        staticClass: "mx-2",
                        attrs: { vertical: "" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: _vm.$vuetify.breakpoint.smAndDown,
                            value: "Tactical"
                          }
                        },
                        [_vm._v("\n            Tactical\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mx-4 my-2" },
                [
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: "Resource Name",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.customName,
                      callback: function($$v) {
                        _vm.customName = $$v
                      },
                      expression: "customName"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-textarea", {
                    staticClass: "my-3",
                    attrs: {
                      "auto-grow": "",
                      rows: "2",
                      label: "Details",
                      filled: "",
                      "hide-details": ""
                    },
                    model: {
                      value: _vm.details,
                      callback: function($$v) {
                        _vm.details = $$v
                      },
                      expression: "details"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "my-3",
                  attrs: {
                    block: "",
                    tile: "",
                    large: "",
                    color: "primary",
                    disabled: !_vm.customType || !_vm.customName
                  },
                  on: {
                    click: function($event) {
                      return _vm.add()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("cci-accuracy")
                  ]),
                  _vm._v("\n        Add Reserve\n      ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }