import { render, staticRenderFns } from "./_WeaponCard.vue?vue&type=template&id=1ceb885e&"
import script from "./_WeaponCard.vue?vue&type=script&lang=ts&"
export * from "./_WeaponCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VDivider,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ceb885e')) {
      api.createRecord('1ceb885e', component.options)
    } else {
      api.reload('1ceb885e', component.options)
    }
    module.hot.accept("./_WeaponCard.vue?vue&type=template&id=1ceb885e&", function () {
      api.rerender('1ceb885e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/cards/npc/cards/_WeaponCard.vue"
export default component.exports