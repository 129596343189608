var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    {
      staticStyle: { "min-height": "640px" },
      attrs: { dark: "", "background-color": "primary" },
      model: {
        value: _vm.tab,
        callback: function($$v) {
          _vm.tab = $$v
        },
        expression: "tab"
      }
    },
    [
      _c("v-tab", [_vm._v("Settings")]),
      _vm._v(" "),
      _c("v-tab", [_vm._v("Log")]),
      _vm._v(" "),
      _c("v-tab-item", [_c("v-card-text", [_c("settings")], 1)], 1),
      _vm._v(" "),
      _c("v-tab-item", [_c("v-card-text", [_c("log")], 1)], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }