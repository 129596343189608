var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-n2" },
    _vm._l(_vm.max, function(n) {
      return _c(
        "v-btn",
        {
          key: "chk_" + _vm.item.ID + "-" + n + "_of_" + _vm.item.MaxUses,
          staticClass: "d-inline my-0 mx-n1 pa-0",
          attrs: {
            icon: "",
            small: _vm.small,
            large: _vm.large,
            color: _vm.color
          },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.set(n)
            }
          }
        },
        [
          _c("v-icon", {
            domProps: {
              innerHTML: _vm._s(n <= _vm.current ? _vm.fullIcon : _vm.emptyIcon)
            }
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }