var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      style: _vm.$vuetify.breakpoint.mdAndDown ? "x-overflow: hidden" : "",
      attrs: { fullscreen: _vm.$vuetify.breakpoint.mdAndDown, width: "90vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "background", attrs: { tile: "" } },
        [
          _c("action-titlebar", {
            attrs: { action: _vm.action, mech: _vm.mech },
            on: {
              hide: function($event) {
                return _vm.hide()
              }
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pt-4" },
            [
              _c("cc-active-synergy", {
                staticClass: "mb-n4",
                attrs: {
                  locations: _vm.action.SynergyLocations,
                  mech: _vm.mech
                }
              }),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("action-detail-expander", {
                        attrs: { action: _vm.action }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("action-activation-buttons", {
                    attrs: {
                      fulltech: _vm.fulltech,
                      used: _vm.action.Used,
                      action: _vm.action,
                      mech: _vm.mech
                    },
                    on: {
                      use: function($event) {
                        return _vm.use($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("tech-attack", {
                attrs: {
                  used: _vm.actionFree || _vm.actionCost,
                  action: _vm.action,
                  mech: _vm.mech
                },
                on: {
                  techAttackComplete: function($event) {
                    return _vm.techAttackComplete($event)
                  }
                }
              }),
              _vm._v(" "),
              _c("v-slide-x-reverse-transition", [
                _vm.succeeded
                  ? _c(
                      "div",
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "mt-4 mb-n2",
                            attrs: { "no-gutters": "", justify: "center" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "ml-auto",
                                staticStyle: { "max-width": "800px" },
                                attrs: { cols: "auto", align: "end" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "body-text stark--text text-left"
                                  },
                                  [
                                    _c("b", [_vm._v("Invasion Success")]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "\n                  Your target takes\n                  "
                                      ),
                                      _c("b", [_vm._v("2 Heat")]),
                                      _vm._v(
                                        "\n                  , and you choose one of the following Invasion Options:\n                "
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-row",
                          { attrs: { dense: "", justify: "center" } },
                          _vm._l(_vm.actions, function(a, j) {
                            return _c(
                              "v-col",
                              {
                                key: "action_" + j,
                                staticStyle: { "min-width": "400px" },
                                attrs: { cols: "auto" }
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    class:
                                      _vm.selected === a
                                        ? "optionSelect"
                                        : "optionFade",
                                    attrs: { tile: "", outlined: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.select(a)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-toolbar",
                                      {
                                        attrs: {
                                          color:
                                            _vm.selected === a
                                              ? "secondary"
                                              : "",
                                          dense: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-toolbar-title",
                                          { staticClass: "px-n2 heading h3" },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(a.Name) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-card-text", [
                                      _c("div", {
                                        directives: [
                                          {
                                            name: "html-safe",
                                            rawName: "v-html-safe",
                                            value: a.Terse,
                                            expression: "a.Terse"
                                          }
                                        ],
                                        staticClass: "body-text text--text"
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        directives: [
                                          {
                                            name: "html-safe",
                                            rawName: "v-html-safe",
                                            value: a.Detail,
                                            expression: "a.Detail"
                                          }
                                        ],
                                        staticClass: "flavor-text mt-1 mx-3"
                                      })
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "v-slide-x-reverse-transition",
                [
                  _vm.failed || (_vm.succeeded && _vm.selected)
                    ? _c(
                        "v-row",
                        { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "ml-auto",
                              attrs: { cols: "auto", align: "end" }
                            },
                            _vm._l(_vm.skLog, function(s, i) {
                              return _c(
                                "v-fade-transition",
                                { key: "skLog_" + i },
                                [
                                  _vm.timer > 10 * i
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "flavor-text stark--text ma-0"
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n                  >//[\n                  "
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "accent--text" },
                                              [_vm._v("COMP/CON:")]
                                            ),
                                            _vm._v(
                                              "\n                  ] :\n                  "
                                            ),
                                            _c("span", [_vm._v(_vm._s(s))])
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-slide-x-reverse-transition",
                [
                  _vm.finished
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "ml-auto", attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "Undo this action, refunding any cost it may have had"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "fadeSelect",
                                      attrs: {
                                        "x-small": "",
                                        color: "primary"
                                      },
                                      on: { click: _vm.reset }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", left: "" } },
                                        [_vm._v("mdi-reload")]
                                      ),
                                      _vm._v(
                                        "\n                UNDO\n              "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-slide-y-reverse-transition", [
            _vm.complete
              ? _c(
                  "div",
                  [
                    _c("v-divider"),
                    _vm._v(" "),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", tile: "" },
                            on: {
                              click: function($event) {
                                _vm.dialog = false
                              }
                            }
                          },
                          [_vm._v("DISMISS")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }