var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "packInstaller", staticStyle: { "min-height": "300px" } },
    [
      _c(
        "v-col",
        {
          staticClass: "px-3 py-4",
          staticStyle: { height: "100%" },
          attrs: { cols: "12", md: "3" }
        },
        [
          _c("v-file-input", {
            attrs: {
              placeholder: "Select an .LCP file",
              outlined: "",
              mr2: "",
              accept: ".lcp",
              "prepend-inner-icon": "mdi-package",
              "prepend-icon": ""
            },
            on: {
              change: function($event) {
                return _vm.fileChange($event)
              }
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "mb-2",
              attrs: {
                block: "",
                type: "flat",
                disabled: !_vm.contentPack || _vm.installing,
                color: _vm.done ? "success" : "primary"
              },
              on: { click: _vm.install }
            },
            [
              _c("v-fade-transition", { attrs: { mode: "out-in" } }, [
                _c(
                  "svg",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.done,
                        expression: "done"
                      }
                    ],
                    staticClass: "check",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "32px",
                      height: "32px",
                      viewBox: "0 0 154 154"
                    }
                  },
                  [
                    _c("polyline", {
                      staticStyle: {
                        stroke: "white",
                        fill: "none",
                        "stroke-width": "12px"
                      },
                      attrs: { points: "43.5,77.8 63.7,97.9 112.2,49.4 " }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.done,
                      expression: "!done"
                    }
                  ]
                },
                [
                  _vm._v(
                    _vm._s(_vm.packAlreadyInstalled ? "Replace" : "Install")
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.error
            ? _c("p", { staticStyle: { color: "red" } }, [
                _vm._v(_vm._s(_vm.error))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "transition-swing",
              attrs: {
                value:
                  _vm.packAlreadyInstalled && !(_vm.installing || _vm.done),
                type: "info",
                transition: "slide-y-reverse-transition"
              }
            },
            [
              _vm._v(
                "\n      A pack with this same name and author is already installed. It will be replaced by this\n      copy.\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mx-3", attrs: { vertical: "" } }),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "px-3 py-4" },
        [
          _c("v-fade-transition", { attrs: { mode: "out-in" } }, [
            _vm.contentPack
              ? _c(
                  "div",
                  { key: "pack" },
                  [_c("pack-info", { attrs: { pack: _vm.contentPack } })],
                  1
                )
              : _c(
                  "div",
                  {
                    key: "nopack",
                    staticStyle: {
                      display: "col",
                      width: "100%",
                      height: "200px",
                      "align-items": "center",
                      "justify-content": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "heading h3 font-italic subtle--text text--darken-1"
                      },
                      [
                        _vm._v(
                          "\n          No content pack selected.\n        "
                        )
                      ]
                    )
                  ]
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }