var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    directives: [
      {
        name: "resize",
        rawName: "v-resize",
        value: _vm.onResize,
        expression: "onResize"
      }
    ],
    staticClass: "elevation-0 flavor-text background",
    attrs: {
      headers: _vm.shownHeaders,
      items: _vm.items,
      "item-key": "ID",
      height: _vm.tableHeight,
      "hide-default-footer": "",
      "disable-pagination": "",
      "calculate-widths": "",
      "fixed-header": "",
      "multi-sort": "",
      "show-select": "",
      "single-select": ""
    },
    scopedSlots: _vm._u(
      [
        {
          key: "item.data-table-select",
          fn: function(ref) {
            var item = ref.item
            return [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c("v-hover", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var hover = ref.hover
                            return [
                              _c(
                                "div",
                                {
                                  class:
                                    "font-weight-bold " +
                                    (hover ? "accent--text" : ""),
                                  attrs: { block: "", dark: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.$refs["modal_" + item.ID].show()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n        " +
                                      _vm._s(item.Name) +
                                      "\n      "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                : _c(
                    "v-btn",
                    {
                      attrs: {
                        "x-small": "",
                        fab: "",
                        color: "primary",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.$refs["modal_" + item.ID].show()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                    1
                  ),
              _vm._v(" "),
              _c(
                "cc-solo-dialog",
                {
                  ref: "modal_" + item.ID,
                  attrs: { title: item.Source + " " + item.Name, large: "" }
                },
                [_c("cc-item-card", { attrs: { item: item } })],
                1
              )
            ]
          }
        },
        {
          key: "item.Name",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("span", { staticClass: "stat-text" }, [
                _vm._v(_vm._s(item.Name))
              ])
            ]
          }
        },
        {
          key: "item.SizeInt",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("span", { staticClass: "stat-text" }, [
                _vm._v(_vm._s(item.Size))
              ])
            ]
          }
        },
        {
          key: "item.Damage[0].Max",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("cc-damage-element", {
                attrs: { small: "", damage: item.Damage }
              })
            ]
          }
        },
        {
          key: "item.Range[0].Max",
          fn: function(ref) {
            var item = ref.item
            return [
              _c("cc-range-element", {
                attrs: { small: "", range: item.Range }
              })
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }