var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "v-alert",
        {
          staticClass: "ma-0",
          attrs: { color: _vm.color, dense: "", dark: "" }
        },
        [
          _c(
            "v-icon",
            {
              staticClass: "ml-n2 mr-2",
              attrs: { slot: "prepend", "x-large": "", color: "white" },
              slot: "prepend"
            },
            [_vm._v(_vm._s(_vm.icon))]
          ),
          _vm._v(" "),
          _c("div", { class: "heading " + (_vm.small ? "h3" : "h2") }, [
            _vm.type === "destroyed"
              ? _c("span", [_vm._v("MECH DESTROYED")])
              : _vm.type === "cascading"
              ? _c("span", [_vm._v("DANGER: UNSHACKLED NHP")])
              : _vm.type === "overSP"
              ? _c("span", [_vm._v("ALERT: SYSTEM CAPACITY EXCEEDED")])
              : _vm.type === "unfinished"
              ? _c("span", [_vm._v("WARNING: EMPTY MOUNTS DETECTED")])
              : _vm.type === "underSP"
              ? _c("span", [_vm._v("WARNING: SYSTEM CAPACITY REMAINING")])
              : _vm.type === "unlicensed"
              ? _c("span", [_vm._v("WARNING: UNLICENSED EQUIPMENT DETECTED")])
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.small && !_vm.hideClear
            ? _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _vm.type === "destroyed"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            block: "",
                            small: "",
                            outlined: "",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("reprint")
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("cci-mech")
                          ]),
                          _vm._v("\n      Reprint Mech\n    ")
                        ],
                        1
                      )
                    : _vm.type === "cascading"
                    ? _c("span", { staticClass: "white--text flavor-text" }, [
                        _vm._v(
                          "\n      UNSHACKLED NHP REPRESENT AN IMMININENT THREAT TO ANY PERSONS OR PROPERTY WITHIN THE MAXIMUM\n      OPERATIONAL AREA OF THE FRAME. FAILURE TO HARDCYCLE CONSTITUTES CONTRIBUTORY NEGLIGENCE\n      UNDER UNION LAW.\n    "
                        )
                      ])
                    : _vm.type === "overSP"
                    ? _c("span", { staticClass: "white--text flavor-text" }, [
                        _vm._v(
                          "\n      Loadout configuration exceeds available Frame System Capacity\n    "
                        )
                      ])
                    : _vm.type === "unfinished" || _vm.type === "underSP"
                    ? _c("span", { staticClass: "white--text flavor-text" }, [
                        _vm._v(
                          "\n      Operational capacity significantly impaired\n    "
                        )
                      ])
                    : _vm.type === "unlicensed"
                    ? _c("span", { staticClass: "white--text flavor-text" }, [
                        _vm._v(
                          "\n      Pilot is missing one or more licenses required to legally print or operate this\n      configuration\n    "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }