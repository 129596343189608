var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("action-detail-expander", { attrs: { action: _vm.action } }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-3" }),
      _vm._v(" "),
      _c(
        "v-container",
        { staticStyle: { "max-width": "800px" } },
        _vm._l(_vm.mech.Pilot.Loadout.Weapons, function(w, j) {
          return _c("item-selector-row", {
            key: "weap_" + j,
            attrs: { item: w, color: "action--full" },
            on: {
              click: function($event) {
                return _vm.fight(w)
              }
            }
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("sel-fight-dialog", {
        ref: "f_dialog",
        attrs: { pilot: _vm.mech.Pilot, item: _vm.selected },
        on: {
          close: function($event) {
            return _vm.completeFight()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }