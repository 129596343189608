var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "", align: "center" } },
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("div", { staticClass: "centered text-left pl-3" }, [
          _c(
            "span",
            { staticClass: "stark--text flavor-text font-weight-bold" },
            [_vm._v("NEW CUSTOM TRIGGER")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("v-col", { attrs: { cols: "10", md: "" } }, [
        _c(
          "div",
          { class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-12 mt-n4" : "mt-n4" },
          [
            _c("v-text-field", {
              attrs: {
                outlined: "",
                dense: "",
                "hide-details": "",
                label: "New Skill Trigger"
              },
              model: {
                value: _vm.newSkill,
                callback: function($$v) {
                  _vm.newSkill = $$v
                },
                expression: "newSkill"
              }
            }),
            _vm._v(" "),
            _c("v-textarea", {
              staticClass: "pl-4 mt-1",
              attrs: {
                outlined: "",
                dense: "",
                "hide-details": "",
                rows: "1",
                "auto-grow": "",
                label: "Description"
              },
              model: {
                value: _vm.newDesc,
                callback: function($$v) {
                  _vm.newDesc = $$v
                },
                expression: "newDesc"
              }
            }),
            _vm._v(" "),
            _c("v-textarea", {
              staticClass: "pl-4 mt-1",
              attrs: {
                outlined: "",
                dense: "",
                "hide-details": "",
                rows: "3",
                "auto-grow": "",
                label: "Detail"
              },
              model: {
                value: _vm.newDetail,
                callback: function($$v) {
                  _vm.newDetail = $$v
                },
                expression: "newDetail"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "text-center", attrs: { cols: "auto", md: "1" } },
        [
          _c(
            "div",
            { staticClass: "mt-2 ml-auto mr-auto" },
            [
              _c(
                "cc-tooltip",
                { attrs: { simple: "", content: "Add Skill" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: _vm.$vuetify.breakpoint.mdAndUp,
                        small: _vm.$vuetify.breakpoint.smAndDown,
                        icon: "",
                        color: "secondary",
                        disabled: _vm.newSkill === ""
                      },
                      on: { click: _vm.addSkill }
                    },
                    [
                      _c("v-icon", { attrs: { "x-large": "" } }, [
                        _vm._v("cci-accuracy")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }