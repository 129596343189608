import { render, staticRenderFns } from "./CoreItem.vue?vue&type=template&id=8efa4cc4&"
import script from "./CoreItem.vue?vue&type=script&lang=ts&"
export * from "./CoreItem.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8efa4cc4')) {
      api.createRecord('8efa4cc4', component.options)
    } else {
      api.reload('8efa4cc4', component.options)
    }
    module.hot.accept("./CoreItem.vue?vue&type=template&id=8efa4cc4&", function () {
      api.rerender('8efa4cc4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/pilot_management/PilotSheet/sections/mech/components/CoreItem.vue"
export default component.exports