import { render, staticRenderFns } from "./CompendiumCard.vue?vue&type=template&id=04547ba5&"
import script from "./CompendiumCard.vue?vue&type=script&lang=ts&"
export * from "./CompendiumCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VCard,VCol,VHover})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('04547ba5')) {
      api.createRecord('04547ba5', component.options)
    } else {
      api.reload('04547ba5', component.options)
    }
    module.hot.accept("./CompendiumCard.vue?vue&type=template&id=04547ba5&", function () {
      api.rerender('04547ba5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/compendium/components/views/components/CompendiumCard.vue"
export default component.exports