import { render, staticRenderFns } from "./CCSynergyDisplay.vue?vue&type=template&id=249e2554&"
import script from "./CCSynergyDisplay.vue?vue&type=script&lang=ts&"
export * from "./CCSynergyDisplay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('249e2554')) {
      api.createRecord('249e2554', component.options)
    } else {
      api.reload('249e2554', component.options)
    }
    module.hot.accept("./CCSynergyDisplay.vue?vue&type=template&id=249e2554&", function () {
      api.rerender('249e2554', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/panels/loadout/mech_loadout/CCSynergyDisplay.vue"
export default component.exports