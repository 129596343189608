var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "60vw", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "title-bg clipped-large", dark: "", flat: "" } },
            [
              _c("v-toolbar-title", { staticClass: "heading h1" }, [
                _vm._v("OVERHEATING")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-window",
            {
              model: {
                value: _vm.window,
                callback: function($$v) {
                  _vm.window = $$v
                },
                expression: "window"
              }
            },
            [
              _c(
                "v-window-item",
                [
                  _c(
                    "v-card-text",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "span",
                        { staticClass: "flavor-text" },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                prominent: "",
                                dark: "",
                                dense: "",
                                icon: "cci-reactor",
                                color: "error",
                                border: "left",
                                tile: ""
                              }
                            },
                            [
                              _c("b", { staticClass: "heading h2" }, [
                                _vm._v("REACTOR STRESS CRITICAL")
                              ])
                            ]
                          ),
                          _vm._v(
                            "\n            Roll 1d6 per point of reactor stress\n          "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "overline" }, [
                        _c("b", [
                          _vm._v(_vm._s(_vm.totalRolls - _vm.rolls.length))
                        ]),
                        _vm._v("\n            rolls remaining\n          ")
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm._l(_vm.rolls.length, function(n) {
                        return _c(
                          "div",
                          { key: "rr" + n, staticClass: "d-inline" },
                          [
                            _c(
                              "cc-tooltip",
                              {
                                attrs: {
                                  simple: "",
                                  inline: "",
                                  content: "Click to re-roll"
                                }
                              },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.rolls.splice(n - 1, 1)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", {
                                      attrs: {
                                        "x-large": "",
                                        color:
                                          _vm.rolls[n - 1] === 1
                                            ? "error"
                                            : "stark"
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          "mdi-dice-" + _vm.rolls[n - 1]
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.totalRolls - _vm.rolls.length, function(n) {
                        return _c(
                          "div",
                          { key: "er" + n, staticClass: "d-inline" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", "x-large": "", disabled: "" }
                              },
                              [
                                _c("v-icon", {
                                  attrs: { "x-large": "" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      "mdi-checkbox-blank-outline"
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "v-scroll-y-transition",
                        { attrs: { group: "", "leave-absolute": "" } },
                        [
                          _vm.rolls.length < _vm.totalRolls
                            ? _c(
                                "div",
                                { key: "tr01", staticClass: "d-inline" },
                                [
                                  _c(
                                    "cc-tooltip",
                                    {
                                      attrs: { inline: "", content: "Roll Die" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mt-n1 mb-1",
                                          attrs: {
                                            icon: "",
                                            color: "accent",
                                            disabled:
                                              _vm.rolls.length ===
                                              _vm.totalRolls
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.rolls.push(_vm.rollRandom())
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { large: "" } },
                                            [_vm._v("mdi-dice-multiple")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider", {
                                    staticClass: "mr-3",
                                    attrs: { vertical: "" }
                                  }),
                                  _vm._v(" "),
                                  _vm._l(6, function(n) {
                                    return _c(
                                      "v-btn",
                                      {
                                        key: "rb" + n,
                                        staticClass: "mt-0 mb-4",
                                        attrs: {
                                          ripple: false,
                                          "x-large": "",
                                          color: "primary",
                                          icon: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.rolls.push(n)
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", {
                                          staticClass: "die-hover",
                                          attrs: { size: "55px" },
                                          domProps: {
                                            innerHTML: _vm._s("mdi-dice-" + n)
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _c(
                                "div",
                                { key: "tr02" },
                                [
                                  _c(
                                    "v-scroll-y-transition",
                                    { attrs: { group: "" } },
                                    [
                                      _vm.rolls.filter(function(x) {
                                        return x === 1
                                      }).length > 1
                                        ? _c(
                                            "span",
                                            {
                                              key: "t01",
                                              staticClass:
                                                "heading h3 error--text"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  // REACTOR INTEGRITY FAILING //\n                "
                                              )
                                            ]
                                          )
                                        : _vm.rolls.length
                                        ? _c(
                                            "span",
                                            {
                                              key: "t02",
                                              staticClass: "heading h3"
                                            },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.results[
                                                      Math.min.apply(
                                                        Math,
                                                        _vm.rolls
                                                      ) - 1
                                                    ]
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "i",
                                                { staticClass: "overline" },
                                                [
                                                  _vm._v(
                                                    "(" +
                                                      _vm._s(
                                                        Math.min.apply(
                                                          Math,
                                                          _vm.rolls
                                                        )
                                                      ) +
                                                      ")"
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _vm.rolls.length === _vm.totalRolls
                            ? _c(
                                "div",
                                { key: "undo_1", staticClass: "text-right" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "fadeSelect",
                                      attrs: {
                                        "x-small": "",
                                        color: "primary"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.rolls.splice(
                                            0,
                                            _vm.rolls.length
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", left: "" } },
                                        [_vm._v("mdi-reload")]
                                      ),
                                      _vm._v(
                                        "\n                UNDO\n              "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "warning", text: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v("dismiss")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            large: "",
                            disabled: _vm.totalRolls - _vm.rolls.length > 0
                          },
                          on: {
                            click: function($event) {
                              _vm.window = _vm.resultWindow
                            }
                          }
                        },
                        [_vm._v("\n            continue\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "table-window-item",
                {
                  attrs: {
                    title: _vm.resultData[0].name,
                    content: _vm.resultData[0].description
                  },
                  on: {
                    dismiss: function($event) {
                      return _vm.close()
                    },
                    previous: function($event) {
                      _vm.window = 0
                    },
                    confirm: function($event) {
                      return _vm.applyES()
                    }
                  }
                },
                [_c("cascade-check", { attrs: { mech: _vm.mech } })],
                1
              ),
              _vm._v(" "),
              _c(
                "table-window-item",
                {
                  attrs: {
                    title: _vm.resultData[1].name,
                    content: _vm.resultData[1].description
                  },
                  on: {
                    dismiss: function($event) {
                      return _vm.close()
                    },
                    previous: function($event) {
                      _vm.window = 0
                    },
                    confirm: function($event) {
                      return _vm.applyPPD()
                    }
                  }
                },
                [_c("cascade-check", { attrs: { mech: _vm.mech } })],
                1
              ),
              _vm._v(" "),
              _c(
                "table-window-item",
                {
                  attrs: { title: _vm.resultData[2].name, "other-btn": "" },
                  on: {
                    dismiss: function($event) {
                      return _vm.close()
                    },
                    previous: function($event) {
                      _vm.window = 0
                    },
                    confirm: function($event) {
                      return _vm.applyPPD()
                    }
                  }
                },
                [
                  _c("p", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.mech.CurrentStress >= 3
                          ? "Your mech is <b>exposed</b> until you take action to remove the condition."
                          : "Your mech must pass a engineering check or suffer a reactor meltdown at the end of 1d6 turns after this one (rolled by the GM). You can reverse it by taking a full action and repeating this check. Even on a successful check, your mech suffers from the <b>exposed</b> condition until you take action to remove it."
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      attrs: { slot: "confirm-button" },
                      slot: "confirm-button"
                    },
                    [
                      _vm.mech.CurrentStress >= 3
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "success", large: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.applyPPD()
                                    }
                                  }
                                },
                                [_vm._v("confirm")]
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "error", large: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.window = 4
                                    }
                                  }
                                },
                                [_vm._v("fail check")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "success", large: "" },
                                  on: { click: _vm.applyPPD }
                                },
                                [_vm._v("succeed check")]
                              )
                            ],
                            1
                          )
                    ]
                  ),
                  _vm._v(" "),
                  _c("cascade-check", { attrs: { mech: _vm.mech } })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "table-window-item",
                {
                  attrs: {
                    title: _vm.resultData[3].name,
                    content: _vm.resultData[3].description,
                    "hide-previous": _vm.mech.CurrentStress <= 1
                  },
                  on: {
                    dismiss: function($event) {
                      return _vm.close()
                    },
                    previous: function($event) {
                      _vm.window = 0
                    },
                    confirm: function($event) {
                      return _vm.applyMeltdown()
                    }
                  }
                },
                [_c("cascade-check", { attrs: { mech: _vm.mech } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }