var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-n4" },
    [
      _c(
        "cc-title",
        {
          staticClass: "ml-n10 pl-3 mb-2",
          attrs: { large: "", color: "pilot" }
        },
        [_vm._v("Hangar ")]
      ),
      _vm._v(" "),
      _c(
        "v-btn-toggle",
        {
          attrs: {
            id: "viewtoggle",
            value: _vm.profile.GetView("hangar"),
            mandatory: ""
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { small: "", icon: "", value: "cards" },
              on: {
                click: function($event) {
                  return _vm.profile.SetView("hangar", "cards")
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "accent" } }, [
                _vm._v("mdi-view-grid")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { small: "", icon: "", value: "list" },
              on: {
                click: function($event) {
                  return _vm.profile.SetView("hangar", "list")
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "accent" } }, [
                _vm._v("mdi-view-list")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { small: "", icon: "", value: "table" },
              on: {
                click: function($event) {
                  return _vm.profile.SetView("hangar", "table")
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "accent" } }, [
                _vm._v("mdi-format-align-justify")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.profile.GetView("hangar") === "cards"
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(_vm.pilot.Mechs, function(m) {
                  return _c("mech-card", {
                    key: m.ID + "_mc",
                    attrs: { mech: m },
                    on: {
                      delete: function($event) {
                        return _vm.pilot.RemoveMech($event)
                      },
                      copy: function($event) {
                        return _vm.pilot.CloneMech($event)
                      },
                      go: function($event) {
                        return _vm.toMechSheet($event)
                      }
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm.profile.GetView("hangar") === "list"
        ? _c(
            "v-container",
            { staticClass: "mt-2 px-0", attrs: { fluid: "" } },
            _vm._l(_vm.pilot.Mechs, function(m) {
              return _c("mech-list-item", {
                key: m.ID + "_mc",
                attrs: { mech: m },
                on: {
                  delete: function($event) {
                    return _vm.pilot.RemoveMech($event)
                  },
                  copy: function($event) {
                    return _vm.pilot.CloneMech($event)
                  },
                  go: function($event) {
                    return _vm.toMechSheet($event)
                  }
                }
              })
            }),
            1
          )
        : _c(
            "v-container",
            { staticClass: "px-0" },
            [
              _c("mech-table", {
                attrs: { mechs: _vm.pilot.Mechs },
                on: {
                  go: function($event) {
                    return _vm.toMechSheet($event)
                  }
                }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "cc-btn",
                {
                  staticClass: "ml-auto mr-auto",
                  attrs: { "x-large": "" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.dialog.show()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "", large: "" } }, [
                    _vm._v("cci-accuracy")
                  ]),
                  _vm._v("\n         Add New Mech\n      ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "dialog",
          attrs: {
            icon: "cci-frame",
            "no-confirm": "",
            title: "Add New Mech",
            fullscreen: ""
          }
        },
        [
          _c("new-mech-menu", {
            attrs: { pilot: _vm.pilot },
            on: {
              close: function($event) {
                return _vm.$refs.dialog.hide()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }