

























import Vue from 'vue'
import IdentBlock from './components/IdentBlock.vue'
import HistoryBlock from './components/HistoryBlock.vue'
import AppearanceBlock from './components/AppearanceBlock.vue'
import ImageBlock from './components/ImageBlock.vue'
import NotesBlock from './components/NotesBlock.vue'
import CloneBlock from './components/CloneBlock.vue'

export default Vue.extend({
  name: 'info-view',
  components: { IdentBlock, HistoryBlock, AppearanceBlock, ImageBlock, NotesBlock, CloneBlock },
})
