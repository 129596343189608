var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.level > 0,
          expression: "level > 0"
        }
      ],
      staticClass: "light-panel clipped",
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "px-2 mr-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-select", {
                staticClass: "mb-1",
                attrs: {
                  color: "accent",
                  "item-color": "accent",
                  "item-disabled": function(item) {
                    return !_vm.readonly && item.cost > _vm.uses
                  },
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  items: _vm.ammoItems,
                  "item-text": "name",
                  "return-object": ""
                },
                on: {
                  change: function($event) {
                    return _vm.setSelection($event)
                  }
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.selected.cost
            ? _c("v-col", { staticClass: "ml-auto pl-4 pr-3 text-left" }, [
                _c("div", { staticClass: "overline my-n2" }, [
                  _vm._v("COST::AMMO CASE")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.selected.cost, function(n) {
                    return _c(
                      "v-icon",
                      { key: _vm.selected.name + "_ammo_" + n },
                      [_vm._v("\n          mdi-hexagon-slice-6\n        ")]
                    )
                  }),
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.selected.effect
        ? _c("div", { staticClass: "mb-1 py-1" }, [
            _c("div", { staticClass: "caption px-2 font-weight-bold" }, [
              _vm._v("EFFECT")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "body-text px-4" }, [
              _vm._v(_vm._s(_vm.selected.effect))
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "caption stark--text px-2 py-1" }, [
      _vm._v("\n    WALKING ARMORY//\n    "),
      _c("b", [_vm._v("SELECTED AMMUNITION")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }