var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("license-block", { staticClass: "mb-4", attrs: { pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("special-block", { staticClass: "mb-4", attrs: { pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("mech-skills-block", {
        staticClass: "my-4",
        attrs: { pilot: _vm.pilot }
      }),
      _vm._v(" "),
      _c("core-bonus-block", {
        staticClass: "my-4",
        attrs: { pilot: _vm.pilot }
      }),
      _vm._v(" "),
      _c("talent-block", { staticClass: "mt-4", attrs: { pilot: _vm.pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }