var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "panel",
          attrs: { dense: "", justify: "center", align: "center" }
        },
        [
          _c(
            "v-col",
            {
              staticStyle: { "letter-spacing": "5px" },
              attrs: { cols: "auto" }
            },
            [_vm._v("VERIFY E-MAIL ADDRESS")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "body-text text-center mt-2" }, [
        _vm._v("\n    An authorization code has been sent to\n    "),
        _c("b", [_vm._v(_vm._s(_vm.email))]),
        _vm._v("\n    . Input the code below to finalize your account\n  ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "v-btn",
            {
              staticStyle: {},
              attrs: { small: "", text: "", color: "accent" },
              on: {
                click: function($event) {
                  return _vm.resend()
                }
              }
            },
            [_vm._v("\n      Re-send Verification Code\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-2", attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { lg: "6", cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Verification Code",
                  dense: "",
                  outlined: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.verify,
                  callback: function($$v) {
                    _vm.verify = $$v
                  },
                  expression: "verify"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-n2 text-center",
          attrs: { dense: "", justify: "center", align: "start" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    color: "success",
                    loading: _vm.loading,
                    disabled: !_vm.verify
                  },
                  on: { click: _vm.confirm }
                },
                [_vm._v("\n        Confirm Verification Code\n      ")]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { text: "", color: "accent" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("set-state", "sign-in")
                    }
                  }
                },
                [_vm._v("\n        Cancel\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-scroll-y-transition",
        { attrs: { "leave-absolute": "", "hide-on-leave": "" } },
        [
          _vm.error
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-2",
                  attrs: {
                    color: "error darken-1",
                    dense: "",
                    icon: "mdi-alert",
                    dismissible: ""
                  },
                  model: {
                    value: _vm.showError,
                    callback: function($$v) {
                      _vm.showError = $$v
                    },
                    expression: "showError"
                  }
                },
                [
                  _c("div", { staticClass: "font-weight-bold" }, [
                    _vm._v("ERROR")
                  ]),
                  _vm._v(" "),
                  _c("div", { domProps: { innerHTML: _vm._s(_vm.error) } })
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }