var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        [
          _c("p", {
            staticClass: "text-center body-text",
            domProps: {
              innerHTML: _vm._s(
                "Start, run, or improve an organization, business, or other venture."
              )
            }
          }),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mb-2" }),
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    color: "white",
                    "background-color": "primary",
                    "slider-color": "white",
                    grow: ""
                  },
                  model: {
                    value: _vm.tabs,
                    callback: function($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs"
                  }
                },
                [
                  _c("v-tab", [_vm._v("Establish Organization")]),
                  _vm._v(" "),
                  _c(
                    "v-tab",
                    { attrs: { disabled: !_vm.organizations.length } },
                    [_vm._v("Improve Organization")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { staticClass: "ma-3", attrs: { flat: "", tile: "" } },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                dark: "",
                                flat: "",
                                tile: "",
                                dense: "",
                                color: "action--downtime"
                              }
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                { staticClass: "heading h2" },
                                [_vm._v("New Organization")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-4" },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Organization Type",
                                          items: _vm.orgTypes,
                                          outlined: "",
                                          dense: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.type,
                                          callback: function($$v) {
                                            _vm.type = $$v
                                          },
                                          expression: "type"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          "hide-details": "",
                                          label: "Organization Name"
                                        },
                                        model: {
                                          value: _vm.name,
                                          callback: function($$v) {
                                            _vm.name = $$v
                                          },
                                          expression: "name"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { dense: "", justify: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "10" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          "auto-grow": "",
                                          rows: "1",
                                          label:
                                            "Purpose, goal, and organization details"
                                        },
                                        model: {
                                          value: _vm.details,
                                          callback: function($$v) {
                                            _vm.details = $$v
                                          },
                                          expression: "details"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "heading h2 text-center mb-2" },
                                [_vm._v("Start with:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  attrs: { dense: "", justify: "space-around" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "5" } },
                                    [
                                      _c(
                                        "cc-tooltip",
                                        {
                                          attrs: {
                                            simple: "",
                                            inline: "",
                                            content:
                                              "How directly effective your organization is at what it does (a military organization with high efficiency would be good at combat, for example). <br /> Efficiency can be used to perform activities related to your organization’s purpose (science, military, etc). You can use these advantages as <strong>reserves.</strong>"
                                          }
                                        },
                                        [
                                          !_vm.start
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    large: "",
                                                    block: "",
                                                    tile: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.start = "efficiency"
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Efficiency\n                    "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-center flavor-text"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "heading h2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "+ " +
                                                          _vm._s(
                                                            _vm.start ===
                                                              "efficiency"
                                                              ? "2"
                                                              : "0"
                                                          )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Organization Efficiency"
                                                    )
                                                  ])
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "5" } },
                                    [
                                      _c(
                                        "cc-tooltip",
                                        {
                                          attrs: {
                                            simple: "",
                                            inline: "",
                                            content:
                                              "Influence is your organization’s size, reach, wealth, and reputation.<br>Influence be used to acquire assets, create opportunities, or sway public opinion."
                                          }
                                        },
                                        [
                                          !_vm.start
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    large: "",
                                                    block: "",
                                                    tile: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.start = "influence"
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Influence\n                    "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-center flavor-text"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "heading h2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "+ " +
                                                          _vm._s(
                                                            _vm.start ===
                                                              "influence"
                                                              ? "2"
                                                              : "0"
                                                          )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Organization Influence"
                                                    )
                                                  ])
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card-text",
                        [
                          !_vm.selected
                            ? _c(
                                "div",
                                { staticClass: "mx-3" },
                                _vm._l(_vm.organizations, function(o) {
                                  return _c(
                                    "v-btn",
                                    {
                                      key: o.Name,
                                      staticClass: "my-3",
                                      attrs: {
                                        tile: "",
                                        block: "",
                                        dark: "",
                                        color: "action--downtime"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.selected = o
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(o.Name) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-slide-x-transition", [
                            _vm.selected
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { small: "", text: "" },
                                        on: {
                                          click: function($event) {
                                            _vm.selected = null
                                          }
                                        }
                                      },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("mdi-chevron-left")
                                        ]),
                                        _vm._v(
                                          "\n                  Select another organization\n                "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-card",
                                      { attrs: { tile: "", flat: "" } },
                                      [
                                        _c(
                                          "v-toolbar",
                                          {
                                            attrs: {
                                              dark: "",
                                              flat: "",
                                              dense: "",
                                              color: "action--downtime"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-toolbar-title",
                                              { staticClass: "heading h2" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(_vm.selected.Name) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-card-text",
                                          { staticClass: "mx-3" },
                                          [
                                            _c("v-textarea", {
                                              attrs: {
                                                "auto-grow": "",
                                                rows: "1",
                                                label:
                                                  "Purpose, goal, and organization details"
                                              },
                                              model: {
                                                value: _vm.selected.Description,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.selected,
                                                    "Description",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selected.Description"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "v-row",
                                              { attrs: { justify: "center" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "5" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        type: "number",
                                                        label:
                                                          "Organization Management Roll Result",
                                                        outlined: "",
                                                        dense: "",
                                                        "hide-details": "",
                                                        "append-outer-icon":
                                                          "mdi-plus-circle-outline",
                                                        "prepend-icon":
                                                          "mdi-minus-circle-outline"
                                                      },
                                                      on: {
                                                        "click:append-outer": function(
                                                          $event
                                                        ) {
                                                          _vm.improveRoll++
                                                        },
                                                        "click:prepend": function(
                                                          $event
                                                        ) {
                                                          _vm.improveRoll > 1
                                                            ? _vm.improveRoll--
                                                            : ""
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.improveRoll,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.improveRoll = $$v
                                                        },
                                                        expression:
                                                          "improveRoll"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-slide-y-transition",
                                              [
                                                _vm.improveRoll
                                                  ? _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "text-center flavor-text",
                                                        attrs: { dense: "" }
                                                      },
                                                      [
                                                        _vm.improveRoll < 10
                                                          ? _c(
                                                              "v-col",
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    attrs: {
                                                                      dense: "",
                                                                      justify:
                                                                        "center"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "pr-2",
                                                                        attrs: {
                                                                          cols:
                                                                            "auto"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "This organization"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "mt-n5",
                                                                        attrs: {
                                                                          cols:
                                                                            "auto"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-radio-group",
                                                                          {
                                                                            staticStyle: {
                                                                              display:
                                                                                "inline-block"
                                                                            },
                                                                            attrs: {
                                                                              row:
                                                                                "",
                                                                              dense:
                                                                                ""
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.badChoice,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.badChoice = $$v
                                                                              },
                                                                              expression:
                                                                                "badChoice"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-radio",
                                                                              {
                                                                                attrs: {
                                                                                  label:
                                                                                    "Folds Immediately",
                                                                                  value:
                                                                                    "fold",
                                                                                  color:
                                                                                    "error"
                                                                                }
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-radio",
                                                                              {
                                                                                attrs: {
                                                                                  label:
                                                                                    "Loses Efficiency (" +
                                                                                    _vm
                                                                                      .selected
                                                                                      .Efficiency +
                                                                                    " available)",
                                                                                  disabled:
                                                                                    _vm
                                                                                      .selected
                                                                                      .Efficiency ===
                                                                                    0,
                                                                                  value:
                                                                                    "efficiency",
                                                                                  color:
                                                                                    "warning"
                                                                                }
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-radio",
                                                                              {
                                                                                attrs: {
                                                                                  label:
                                                                                    "Loses Influence (" +
                                                                                    _vm
                                                                                      .selected
                                                                                      .Influence +
                                                                                    " available)",
                                                                                  disabled:
                                                                                    _vm
                                                                                      .selected
                                                                                      .Influence ===
                                                                                    0,
                                                                                  value:
                                                                                    "influence",
                                                                                  color:
                                                                                    "warning"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "v-slide-x-reverse-transition",
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "show",
                                                                            rawName:
                                                                              "v-show",
                                                                            value:
                                                                              _vm.badChoice !==
                                                                                "" &&
                                                                              _vm.badChoice !==
                                                                                "fold",
                                                                            expression:
                                                                              "badChoice !== '' && badChoice !== 'fold'"
                                                                          }
                                                                        ]
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "br"
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                Additionally, the organization takes one of the following actions:\n                              "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-btn-toggle",
                                                                          {
                                                                            attrs: {
                                                                              mandatory:
                                                                                ""
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.action,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.action = $$v
                                                                              },
                                                                              expression:
                                                                                "action"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  text:
                                                                                    "",
                                                                                  large:
                                                                                    "",
                                                                                  value:
                                                                                    "Pay Debts"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Pay Debts"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  text:
                                                                                    "",
                                                                                  large:
                                                                                    "",
                                                                                  value:
                                                                                    "Prove Worthiness"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                  Prove Worthiness\n                                "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  text:
                                                                                    "",
                                                                                  large:
                                                                                    "",
                                                                                  value:
                                                                                    "Get Bailed Out"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                  Get Bailed Out\n                                "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  text:
                                                                                    "",
                                                                                  large:
                                                                                    "",
                                                                                  value:
                                                                                    "Make an Aggressive Move"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                  Make an Aggressive Move\n                                "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm.improveRoll <=
                                                            20
                                                          ? _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                parseInt(
                                                                  _vm.selected
                                                                    .Influence
                                                                ) === 6 &&
                                                                parseInt(
                                                                  _vm.selected
                                                                    .Efficiency
                                                                ) === 6
                                                                  ? _c(
                                                                      "v-row",
                                                                      {
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "text-center"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "heading h3 subtle--text"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                Organization is operating at maximum capacity\n                              "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "v-row",
                                                                      [
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            staticClass:
                                                                              "text-center",
                                                                            attrs: {
                                                                              cols:
                                                                                "12"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "heading h3"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Improve:"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "5"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  top:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    attrs: {
                                                                                      slot:
                                                                                        "activator"
                                                                                    },
                                                                                    slot:
                                                                                      "activator"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs: {
                                                                                          large:
                                                                                            "",
                                                                                          block:
                                                                                            "",
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            _vm
                                                                                              .selected
                                                                                              .Efficiency ===
                                                                                              6 ||
                                                                                            _vm.improvement ===
                                                                                              "efficiency"
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.improvement =
                                                                                              "efficiency"
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                    Efficiency\n                                  "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "heading h3"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                      +\n                                      " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .selected
                                                                                                    .Efficiency +
                                                                                                    (_vm.improvement ===
                                                                                                    "efficiency"
                                                                                                      ? 2
                                                                                                      : 0)
                                                                                                ) +
                                                                                                "\n                                      Efficiency\n                                    "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "br"
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Organization Efficiency"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                  How directly effective your organization is at what it does (a\n                                  military organization with high efficiency would be good at\n                                  combat, for example).\n                                  "
                                                                                    ),
                                                                                    _c(
                                                                                      "br"
                                                                                    ),
                                                                                    _vm._v(
                                                                                      "\n                                  Efficiency can be used to perform activities related to your\n                                  organization’s purpose (science, military, etc). You can use\n                                  these advantages as\n                                  "
                                                                                    ),
                                                                                    _c(
                                                                                      "strong",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "reserves."
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-spacer"
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "v-col",
                                                                          {
                                                                            attrs: {
                                                                              cols:
                                                                                "5"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs: {
                                                                                  top:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    attrs: {
                                                                                      slot:
                                                                                        "activator"
                                                                                    },
                                                                                    slot:
                                                                                      "activator"
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs: {
                                                                                          large:
                                                                                            "",
                                                                                          block:
                                                                                            "",
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            _vm
                                                                                              .selected
                                                                                              .Influence ===
                                                                                              6 ||
                                                                                            _vm.improvement ===
                                                                                              "influence"
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.improvement =
                                                                                              "influence"
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                    Influence\n                                  "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "heading h3"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                      +\n                                      " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .selected
                                                                                                    .Influence +
                                                                                                    (_vm.improvement ===
                                                                                                    "influence"
                                                                                                      ? 2
                                                                                                      : 0)
                                                                                                ) +
                                                                                                "\n                                      Influence\n                                    "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "br"
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Organization Influence"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                  Influence is your organization’s size, reach, wealth, and\n                                  reputation. Influence be used to acquire assets, create\n                                  opportunities, or sway public opinion.\n                                "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                parseInt(
                                                                  _vm.selected
                                                                    .Influence
                                                                ) < 6 &&
                                                                parseInt(
                                                                  _vm.selected
                                                                    .Efficiency
                                                                ) < 6
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "heading h3 accent--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            Organization Influence and Efficiency increased by +2\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : parseInt(
                                                                      _vm
                                                                        .selected
                                                                        .Influence
                                                                    ) < 6
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "heading h3 accent--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            Organization Influence increased by +2\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : parseInt(
                                                                      _vm
                                                                        .selected
                                                                        .Efficiency
                                                                    ) < 6
                                                                  ? _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "heading h3 accent--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            Organization Efficiency increased by +2\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "heading h3 subtle--text"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            Organization is operating at maximum capacity\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                              ]
                                                            )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("cancel")]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                large: "",
                tile: "",
                color: _vm.badChoice === "fold" ? "error" : "primary",
                disabled: _vm.confirmDisabled
              },
              on: {
                click: function($event) {
                  _vm.tabs === 0 ? _vm.addOrg() : _vm.improveOrg()
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.confirmName()) + "\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }