var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          "z-index": "2",
          height: "43px",
          left: "0",
          right: "0",
          top: "0",
          bottom: "20px",
          "background-color": "var(--v-primary-base)",
          "text-align": "right"
        }
      },
      [
        _c("update-checker", {
          staticClass: "mt-2 mr-2",
          on: {
            mouseenter: function($event) {
              return _vm.$emit("logupdate")
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "inline-block",
              position: "relative",
              "z-index": "999!important"
            }
          },
          [
            _c(
              "v-btn",
              {
                staticClass: "mt-2 mr-2",
                staticStyle: { "z-index": "999!important" },
                attrs: {
                  target: "_blank",
                  href:
                    "https://github.com/massif-press/compcon/blob/master/CHANGELOG.md",
                  color: "white",
                  outlined: "",
                  small: ""
                },
                on: {
                  mouseenter: function($event) {
                    return _vm.$emit("logupdate")
                  }
                }
              },
              [_vm._v("\n        View Changelog\n      ")]
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "clipped px-2 pt-1" }, [
      _c("span", { attrs: { id: "title" } }, [_vm._v("COMP/CON")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { attrs: { id: "subtitle" } }, [
        _c("span", [_vm._v("VERSION: " + _vm._s(_vm.$appVersion))])
      ])
    ]),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "fadeSelect",
        staticStyle: { position: "relative", top: "1px", left: "5px" },
        attrs: { href: "https://www.netlify.com" }
      },
      [
        _c("img", {
          attrs: {
            src: "https://www.netlify.com/img/global/badges/netlify-light.svg"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "hover-parent",
        staticStyle: {
          position: "absolute",
          left: "calc(70% - 40px)",
          top: "50px",
          display: "inline-flex",
          "z-index": "3"
        }
      },
      [
        _c("div", {
          staticStyle: {
            width: "40px",
            height: "40px",
            "background-color": "var(--v-primary-base)",
            "clip-path": "polygon(100% 0, 0% 100%, 100% 100%)"
          }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "hover-expand",
          staticStyle: {
            width: "10px",
            height: "40px",
            "background-color": "var(--v-primary-base)",
            "margin-left": "-1px"
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }