var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-col", { attrs: { cols: "auto" } }, [
        _c("span", { staticClass: "subtle--text" }, [
          _vm._v("// MISSING DATA //")
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", text: "", color: "error" },
              on: {
                click: function($event) {
                  return _vm.$emit("remove")
                }
              }
            },
            [_c("v-icon", [_vm._v("delete")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }