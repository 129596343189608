var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "suppress-horiz",
      style: _vm.$vuetify.breakpoint.mdAndDown ? "x-overflow: hidden" : "",
      attrs: { fullscreen: _vm.$vuetify.breakpoint.mdAndDown, width: "85vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "background suppress-horiz",
          staticStyle: { "min-height": "175px" },
          attrs: { tile: "" }
        },
        [
          _c("action-titlebar", {
            attrs: {
              used: _vm.action.Used,
              "no-action": _vm.noAction,
              action: _vm.action,
              mech: _vm.mech
            },
            on: {
              hide: function($event) {
                return _vm.hide()
              }
            }
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pt-1 pb-0" },
            [
              _c("cc-active-synergy", {
                staticClass: "mb-n4",
                attrs: {
                  locations: _vm.action.SynergyLocations,
                  mech: _vm.mech
                }
              }),
              _vm._v(" "),
              _vm.component
                ? _c(_vm.component, {
                    ref: "c",
                    tag: "component",
                    attrs: {
                      fulltech: _vm.fulltech,
                      used: _vm.action.Used,
                      mech: _vm.mech,
                      action: _vm.action
                    },
                    on: {
                      use: function($event) {
                        return _vm.use($event)
                      },
                      hide: function($event) {
                        return _vm.hide()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.action.IsTechAttack
            ? _c("tech-attack", {
                attrs: {
                  used: _vm.techAttack,
                  action: _vm.action,
                  mech: _vm.mech
                },
                on: {
                  techAttackComplete: function($event) {
                    return _vm.techAttackComplete($event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("action-confirm-log", {
            ref: "log",
            attrs: {
              used: _vm.displayLog,
              action: _vm.action,
              mech: _vm.mech,
              logOverride: _vm.logOverride,
              "hide-log": _vm.action && _vm.action.ID === "act_self_destruct"
            },
            on: {
              undo: function($event) {
                return _vm.undo()
              },
              hide: function($event) {
                return _vm.hide()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }