import { render, staticRenderFns } from "./CCLog.vue?vue&type=template&id=90806df2&scoped=true&"
import script from "./CCLog.vue?vue&type=script&lang=ts&"
export * from "./CCLog.vue?vue&type=script&lang=ts&"
import style0 from "./CCLog.vue?vue&type=style&index=0&id=90806df2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90806df2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('90806df2')) {
      api.createRecord('90806df2', component.options)
    } else {
      api.reload('90806df2', component.options)
    }
    module.hot.accept("./CCLog.vue?vue&type=template&id=90806df2&scoped=true&", function () {
      api.rerender('90806df2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/main_menu/_components/CCLog.vue"
export default component.exports