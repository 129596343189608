var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        {
          staticClass: "mx-2 mt-n2 mb-2",
          attrs: { "no-gutters": "", align: "center" }
        },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c(
              "h1",
              { staticClass: "ml-n2 heading accent--text" },
              [_vm._t("default")],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-4 mr-2", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn-toggle",
                {
                  attrs: {
                    value: _vm.profile.GetView("selector"),
                    mandatory: ""
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.lockView,
                          expression: "!lockView"
                        }
                      ],
                      attrs: { small: "", icon: "", value: "split" },
                      on: {
                        click: function($event) {
                          return _vm.profile.SetView("selector", "split")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-split-vertical")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", value: "list" },
                      on: {
                        click: function($event) {
                          return _vm.profile.SetView("selector", "list")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-list")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", value: "cards" },
                      on: {
                        click: function($event) {
                          return _vm.profile.SetView("selector", "cards")
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "accent" } }, [
                        _vm._v("mdi-view-grid")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto mr-5", attrs: { cols: "12", md: "3" } },
            [
              _c("v-text-field", {
                staticClass: "search-field",
                attrs: {
                  "prepend-icon": "search",
                  flat: "",
                  "hide-actions": "",
                  "single-line": "",
                  dense: "",
                  placeholder: "Search",
                  clearable: "",
                  "persistent-hint": "",
                  hint: _vm.fItems.length + " Items"
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("cc-filter-panel", {
            attrs: { "item-type": _vm.itemType },
            on: { "set-filters": _vm.setFilters }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.profile.GetView("selector") === "split" || _vm.lockView
            ? _c(
                "div",
                [
                  _vm.$vuetify.breakpoint.smAndDown
                    ? _c("compendium-mobile-view", {
                        attrs: { items: _vm.fItems }
                      })
                    : _c("compendium-split-view", {
                        attrs: { items: _vm.fItems }
                      })
                ],
                1
              )
            : _vm.profile.GetView("selector") === "list"
            ? _c("compendium-table-view", {
                attrs: { items: _vm.fItems, headers: _vm.headers }
              })
            : _vm.profile.GetView("selector") === "cards"
            ? _c("compendium-cards-view", { attrs: { items: _vm.fItems } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }