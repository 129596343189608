var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols } },
    [
      _c("v-hover", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var hover = ref.hover
              return [
                _c(
                  "v-card",
                  {
                    class: "clipped-x-large " + (hover ? "on-hover" : ""),
                    attrs: {
                      id: "underline-parent",
                      height: _vm.small ? "8em" : "10em",
                      color: _vm.disabled ? "grey" : _vm.color,
                      tile: "",
                      outlined: "",
                      disabled: _vm.disabled
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("clicked")
                      }
                    }
                  },
                  [
                    _vm.icon
                      ? _c(
                          "v-icon",
                          {
                            class:
                              "pa-0 ma-0 " + (_vm.small ? "mb-2 ml-2" : ""),
                            staticStyle: {
                              position: "absolute",
                              bottom: "-5px",
                              left: "0"
                            },
                            attrs: { size: _vm.small ? 56 : 100, dark: "" }
                          },
                          [_vm._v("\n        " + _vm._s(_vm.icon) + "\n      ")]
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              position: "absolute",
                              bottom: "-5px",
                              left: "0"
                            }
                          },
                          [_vm._t("content")],
                          2
                        ),
                    _vm._v(" "),
                    _c(
                      "v-card-actions",
                      {
                        staticClass: "text-right",
                        style:
                          "display: inline-block; position: absolute; width: 100%; top: 0; left: 0;",
                        attrs: { id: "card-actions" }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "underline-slide px-4 heading h2",
                            staticStyle: {
                              position: "absolute",
                              top: "0",
                              right: "0",
                              display: "contents",
                              "font-size": "22pt"
                            }
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(_vm.name) + "\n        "
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }