




































































































































































import Vue from 'vue'
import allThemes from '@/ui/style/themes'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'
import { exportAll, importAll, exportV1Pilots, clearAllData } from '@/io/BulkData'
import { saveFile } from '@/io/Dialog'

export default Vue.extend({
  name: 'options-settings',
  data: () => ({
    theme: 'gms',
    themes: [],
    importDialog: false,
    fileValue: null,
    deleteDialog: false,
  }),
  computed: {
    user() {
      const store = getModule(UserStore, this.$store)
      return store.UserProfile
    },
    userViewExotics: {
      get: function () {
        return this.user.GetView('showExotics')
      },
      set: function (newval) {
        this.user.SetView('showExotics', newval)
      },
    },
    userID() {
      return this.user.id
    },
    userTheme() {
      return this.user.Theme
    },
  },
  created() {
    this.theme = this.userTheme
    for (const k in allThemes) {
      if (allThemes.hasOwnProperty(k)) {
        const e = allThemes[k]
        this.themes.push({ name: e.name, value: e.id })
      }
    }
  },
  methods: {
    reload() {
      location.reload(true)
    },
    setTheme() {
      const profile = getModule(UserStore, this.$store).UserProfile
      Vue.set(profile, 'Theme', this.theme)
      const isDark = allThemes[this.theme].type === 'dark'

      if (isDark) {
        this.$vuetify.theme.themes.dark = allThemes[this.theme].colors
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.themes.light = allThemes[this.theme].colors
        this.$vuetify.theme.dark = false
      }
    },
    showMessage() {
      const store = getModule(UserStore, this.$store)
      store.UserProfile.WelcomeHash = ''
      localStorage.removeItem('cc-welcome-hash')
      this.reload()
    },
    async bulkExport() {
      const result = await exportAll()
      await saveFile(
        `CC_${new Date().toISOString().slice(0, 10)}.compcon`,
        JSON.stringify(result),
        'Save COMP/CON Archive'
      )
    },
    async bulkImport(file) {
      await importAll(file)
        .then(() => this.$notify('Data import successful', 'confirmation'))
        .catch(err => this.$notify(`ERROR: Unable to import: ${err}`, 'error'))
      this.importDialog = false
    },
    async deleteAll() {
      await clearAllData()
      this.deleteDialog = false
    },
  },
})
