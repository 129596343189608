var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mb-n3", attrs: { align: "start" } },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "heading mech",
                staticStyle: { "line-height": "5px" }
              },
              [_vm._v(_vm._s(_vm.pilot.Callsign))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flavor-text subtle--text" }, [
              _vm._v(_vm._s(_vm.pilot.Name))
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "ml-auto text-right mr-2 mt-n2",
              attrs: { cols: "auto" }
            },
            [
              _c("span", { staticClass: "heading h3 accent--text" }, [
                _vm._v("HP")
              ]),
              _vm._v(" "),
              _c(
                "b",
                [
                  _c("cc-tick-bar", {
                    attrs: {
                      small: "",
                      "no-pips": "",
                      "flip-input": "",
                      current: _vm.pilot.CurrentHP,
                      max: _vm.pilot.MaxHP
                    },
                    on: {
                      update: function($event) {
                        _vm.pilot.CurrentHP = $event
                      }
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right mx-2 mt-n2", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "heading h3 accent--text" }, [
                _vm._v("Armor")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.pilot.Armor))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right mx-2 mt-n2", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "heading h3 accent--text" }, [
                _vm._v("E-Defense")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.pilot.EDefense))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right mx-2 mt-n2", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "heading h3 accent--text" }, [
                _vm._v("Evasion")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.pilot.Evasion))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right mx-2 mt-n2", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "heading h3 accent--text" }, [
                _vm._v("Grit")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v("+" + _vm._s(_vm.pilot.Grit))
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.pilot.State.Stage === "Narrative"
        ? _c("clone-block", { attrs: { "hide-quirks": "" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.pilot.ActiveMech && _vm.pilot.ActiveMech.Destroyed
        ? _c("destroyed-alert", {
            attrs: { mech: _vm.pilot.ActiveMech },
            on: {
              restore: function($event) {
                return _vm.pilot.ActiveMech.BasicRepair($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.pilot.State.SelfDestructCounter > 0
        ? _c(
            "v-row",
            {
              staticClass: "text-center",
              attrs: { dense: "", justify: "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        dense: "",
                        outlined: "",
                        color: "error",
                        prominent: ""
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            slot: "prepend",
                            color: "error",
                            size: "90"
                          },
                          slot: "prepend"
                        },
                        [_vm._v("\n          cci-reactor\n        ")]
                      ),
                      _vm._v(" "),
                      _vm.pilot.State.SelfDestructCounter > 1
                        ? _c("span", { staticClass: "heading h1" }, [
                            _vm._v(
                              "\n          MECH WILL SELF DESTRUCT IN " +
                                _vm._s(_vm.pilot.State.SelfDestructCounter) +
                                " ROUNDS\n        "
                            )
                          ])
                        : _c("span", { staticClass: "heading h1" }, [
                            _vm._v("MECH SELF DESTRUCTION IMMINENT")
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "heading mt-n4 subtle--text" }, [
                        _vm._v(
                          "\n          FRAME.PRIORITY.ALERT::REACTOR CRITICALITY EVENT\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "px-5 my-1" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", block: "", color: "error" },
                              on: {
                                click: function($event) {
                                  return _vm.pilot.State.SelfDestruct()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-skull")
                              ]),
                              _vm._v(
                                "\n            DETONATE REACTOR\n            "
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("mdi-skull")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-right mt-1" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              attrs: { "x-small": "", color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.pilot.State.CancelSelfDestruct()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "", left: "" } }, [
                                _vm._v("mdi-reload")
                              ]),
                              _vm._v("\n            UNDO\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.pilot.IsDownAndOut
        ? _c(
            "v-alert",
            {
              attrs: {
                prominent: "",
                dense: "",
                color: "warning",
                outlined: "",
                icon: "mdi-account-alert"
              }
            },
            [
              _c("div", { staticClass: "heading h2" }, [
                _vm._v("DOWN AND OUT")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "body-text text--text" }, [
                _vm._v(
                  "\n      This Pilot is unconscious and STUNNED – if they take any more damage, they die. They'll\n      regain consciousness and half of their HP when they rest.\n    "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "fadeSelect",
                      staticStyle: { bottom: "0", right: "0" },
                      attrs: { small: "", absolute: "", color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.pilot.Kill()
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-skull")]),
                      _vm._v("\n        Mark as Killed\n      ")
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          style:
            _vm.pilot.IsDead || _vm.pilot.IsDownAndOut ? "opacity: 0.5" : ""
        },
        [
          _c("span", { staticClass: "overline" }, [_vm._v("PILOT LOADOUT")]),
          _vm._v(" "),
          _c("cc-pilot-loadout", { attrs: { pilot: _vm.pilot, readonly: "" } }),
          _vm._v(" "),
          _vm.pilot.State.Stage === "Narrative"
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "auto" } }, [
                        _c("span", { staticClass: "overline" }, [
                          _vm._v("SKILL TRIGGERS")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "ml-auto", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              attrs: { "x-small": "", outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.expandAll(
                                    _vm.pilot.Skills.length,
                                    "sk_",
                                    true
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "", left: "" } }, [
                                _vm._v("mdi-chevron-up")
                              ]),
                              _vm._v("\n            All\n          ")
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              attrs: { "x-small": "", outlined: "" },
                              on: {
                                click: function($event) {
                                  return _vm.expandAll(
                                    _vm.pilot.Skills.length,
                                    "sk_",
                                    false
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { small: "", left: "" } }, [
                                _vm._v("mdi-chevron-down")
                              ]),
                              _vm._v("\n            All\n          ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { dense: "", justify: "center" } },
                    _vm._l(_vm.pilot.Skills, function(s, i) {
                      return _c("cc-active-card", {
                        key: "sk_" + i,
                        ref: "sk_" + i,
                        refInFor: true,
                        attrs: {
                          cols: _vm.$vuetify.breakpoint.lgAndUp ? 4 : 6,
                          color: "secondary",
                          collapsible: "",
                          "start-closed": "",
                          header: s.Skill.Name + " (+" + s.Bonus + ")",
                          content: s.Skill.Detail
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.pilot.Reserves || _vm.pilot.Organizations
            ? _c(
                "span",
                { staticClass: "overline" },
                [
                  _vm._v("\n      RESERVES AND RESOURCES\n      "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "fadeSelect",
                      attrs: { small: "", right: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.showReserves = !_vm.showReserves
                        }
                      }
                    },
                    [
                      _c("v-icon", {
                        attrs: { small: "" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.showReserves
                              ? "mdi-eye-outline"
                              : "mdi-eye-off-outline"
                          )
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-scroll-y-reverse-transition",
            { attrs: { mode: "out-in" } },
            [
              _vm.showReserves &&
              (_vm.pilot.Reserves || _vm.pilot.Organizations)
                ? _c(
                    "v-row",
                    { staticClass: "mt-n3" },
                    [
                      _vm._l(
                        _vm.pilot.Reserves.filter(function(r) {
                          return r.Type !== "Bonus"
                        }),
                        function(r, i) {
                          return _c("cc-reserve-item", {
                            key: "r_" + i,
                            attrs: { reserve: r },
                            on: {
                              remove: function($event) {
                                return _vm.pilot.RemoveReserve(i)
                              }
                            }
                          })
                        }
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.pilot.Organizations, function(o, i) {
                        return _c("cc-org-item", {
                          key: "o_" + i,
                          attrs: { org: o },
                          on: {
                            remove: function($event) {
                              return _vm.pilot.RemoveOrganization(i)
                            }
                          }
                        })
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }