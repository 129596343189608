



























































































import Vue from 'vue'
import { Reserve } from '@/class'

export default Vue.extend({
  name: 'buy-time',
  props: {
    pilot: { type: Object, required: true },
  },
  data: () => ({
    skillRoll: '',
    details: '',
  }),
  methods: {
    addReserve() {
      const nr = new Reserve({
        id: 'reserve_boughttime',
        type: 'Resource',
        name: 'Bought Time',
        label: '',
        description: 'More time and breathing room for you and your group to act',
        resource_note: this.details,
        resource_cost: '',
        resource_name: '',
        used: false,
        consumable: true,
      })
      if (this.skillRoll < 10)
        nr.ResourceCost = 'Only a little time, and only if drastic measures are taken right now'
      else if (this.skillRoll < 20)
        nr.ResourceCost = 'The situation becomes precarious or desperate'
      this.pilot.Reserves.push(nr)
      this.close()
    },
    close() {
      this.skillRoll = ''
      this.details = ''
      this.$emit('close')
    },
  },
})
