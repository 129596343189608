var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.readonly ? "mt-n1" : "", staticStyle: { height: "100%" } },
    [
      _c(
        "slot-card-base",
        {
          ref: "base",
          staticStyle: { height: "100%" },
          attrs: { item: _vm.mod, mech: _vm.mech, readonly: _vm.readonly }
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _vm.mod
              ? _c(
                  "span",
                  [
                    _c("equipment-options", {
                      attrs: {
                        item: _vm.mod,
                        readonly: _vm.readonly,
                        active: _vm.readonly
                      }
                    }),
                    _vm._v(" "),
                    !_vm.mod.Destroyed
                      ? _c("span", { staticClass: "ml-n2" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mod.Name) +
                              "\n          "
                          ),
                          _vm.mod.FlavorName
                            ? _c(
                                "span",
                                { staticClass: "caption ml-2 my-n1" },
                                [_vm._v("//" + _vm._s(_vm.mod.TrueName))]
                              )
                            : _vm._e()
                        ])
                      : _c(
                          "span",
                          {
                            staticClass: "py-1 error",
                            staticStyle: { "letter-spacing": "3px" }
                          },
                          [
                            _vm._v(
                              "\n           / / " +
                                _vm._s(_vm.mod.Name) +
                                " DESTROYED / / \n        "
                            )
                          ]
                        )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-right",
              attrs: { slot: "header-items" },
              slot: "header-items"
            },
            [
              _c("div", { staticStyle: { display: "inline-block" } }, [
                _c("span", { staticClass: "heading h2" }, [
                  _vm._v(_vm._s(_vm.mod.SP))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "heading h3" }, [_vm._v("SP")])
              ]),
              _vm._v(" "),
              !_vm.readonly
                ? _c(
                    "div",
                    {
                      staticClass: "d-inline pl-3 ml-3",
                      staticStyle: { "border-left": "1px solid #616161" }
                    },
                    [
                      _vm.mod
                        ? _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("remove")
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "fadeSelect mt-n1" },
                                [_vm._v("delete")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "fadeSelect",
                      attrs: { right: "", icon: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.hide = !_vm.hide
                        }
                      }
                    },
                    [
                      _c("v-icon", {
                        attrs: { small: "" },
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.hide ? "mdi-eye-outline" : "mdi-eye-off-outline"
                          )
                        }
                      })
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-slide-y-transition",
            [
              !_vm.hide
                ? _c(
                    "v-row",
                    {
                      staticStyle: { height: "100%" },
                      attrs: { dense: "", "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _vm.mod.IsCascading
                            ? _c(
                                "v-alert",
                                {
                                  staticClass:
                                    "text-center white--text stat-text",
                                  staticStyle: { "letter-spacing": "3px" },
                                  attrs: { dense: "", tile: "", color: "error" }
                                },
                                [
                                  _vm._v(
                                    "\n            / / AI IN CASCADE / /\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "overline mt-n1" }, [
                            _vm._v("APPLIED TO")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "heading h3 subtle--text ml-3 mt-n2 mb-1"
                            },
                            [_vm._v(_vm._s(_vm.weapon.Name))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }