import { render, staticRenderFns } from "./encounter.vue?vue&type=template&id=35a042a2&"
import script from "./encounter.vue?vue&type=script&lang=ts&"
export * from "./encounter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtn,VDivider})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('35a042a2')) {
      api.createRecord('35a042a2', component.options)
    } else {
      api.reload('35a042a2', component.options)
    }
    module.hot.accept("./encounter.vue?vue&type=template&id=35a042a2&", function () {
      api.rerender('35a042a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/nav/modes/encounter.vue"
export default component.exports