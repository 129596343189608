






































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/indent */
import Vue from 'vue'
import PilotTalent from '@/classes/pilot/PilotTalent'
import { ActivationType, Damage, DiceRoller, Range, WeaponSize, WeaponType } from '@/class'

export default Vue.extend({
  name: 'weapon-attack',
  props: {
    item: {
      type: Object,
      required: true,
    },
    pilot: {
      type: Object,
      required: true,
    },
    mount: {
      type: Object,
      required: false,
      default: null,
    },
    overwatch: { type: Boolean },
  },
  data: () => ({
    tab: 0,
    ammoCost: 0,
    overkillHeat: 0,
    autoOverkillString: '',
    ammoDamage: '',
    accuracy: 0,
    difficulty: 0,
    attackRoll: null,
    rollResultString: null,
    rollAccuracyResults: [],
    attackQuick: false,
    attackFree: false,
    hit: false,
    missed: false,
    damageRolls: [],
    damageResultString: null,
    bonusDamage: null,
    kill: false,
    confirmed: false,
  }),
  computed: {
    state() {
      return this.pilot.State
    },
    noDamageItem() {
      return !this.item.Damage.length
    },
    missText() {
      if (this.reliable) return 'Glancing hit'
      switch (this.item.WeaponType) {
        case 'Rifle':
        case 'Cannon':
          return 'Shot wide'
        default:
          return 'No effect'
      }
    },
    overkill() {
      return this.item.Tags.some(x => x.IsOverkill)
    },
    crit() {
      return this.attackRoll && this.attackRoll >= 20
    },
    attacked() {
      return this.attackQuick || this.attackFree
    },
    getRange() {
      if (!this.item) return []
      return this.item.Range
    },
    getDamage() {
      if (!this.item) return []
      return this.item.Damage
    },
    isSmart() {
      if (this.item.Tags.some(x => x.IsSmart)) return true
      if (this.item.Mod && this.item.Mod.AddedTags.some(x => x.IsSmart)) return true
      return false
    },
    reliable() {
      const r = this.item.Tags.find(x => x.ID === 'tg_reliable')
      return r ? r.Value : 0
    },
    minAccuracy() {
      let bonus = 0
      if (this.item.Tags.some(x => x.ID === 'tg_accurate')) bonus += 1
      if (this.item.Mod && this.item.Mod.AddedTags.some(x => x.ID === 'tg_accurate')) bonus += 1
      if (this.hardpoints) bonus += 1
      return bonus
    },
    minDifficulty() {
      if (this.item.Tags.some(x => x.ID === 'tg_inaccurate')) return 1
      if (this.item.Mod && this.item.Mod.AddedTags.some(x => x.ID === 'tg_inaccurate')) return 1
      return 0
    },
    summedDamage() {
      let dmg = 0
      this.damageRolls.forEach(v => {
        dmg += parseInt(v)
      })
      if (this.bonusDamage) dmg += parseInt(this.bonusDamage)
      return dmg
    },
    finalDamage() {
      return this.reliable > this.summedDamage ? this.reliable : this.summedDamage
    },
  },
  watch: {
    tab(newval: number) {
      this.item.SetProfileSelection(newval, true)
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    setDamage(index, damage) {
      Vue.set(this.damageRolls, index, damage.total)
      this.overkillHeat = damage.overkill
    },
    setBonusDamage(damage) {
      this.bonusDamage = damage.total
      this.overkillHeat += damage.overkill
    },
    confirm(): void {
      this.confirmed = true
      const actionObj = {
        accuracy: this.accuracy - this.difficulty,
        hit: this.hit,
        damage: this.summedDamage,
        kill: this.kill,
        activation: this.attackQuick ? ActivationType.Full : ActivationType.Free,
      }
      let cost = 1
      cost = this.item.Cost
      this.item.Use(cost, actionObj.activation === ActivationType.Free)
      if (this.ammoCost) this.state.SpendAmmoCost(this.ammoCost)
      this.pilot.State.LogAttackAction('ATTACK', this.item.Name, this.summedDamage, this.kill)
      this.$emit('confirm', actionObj.activation === ActivationType.Free)
    },
    reset() {
      this.pilot.State.UndoLogAttackAction('ATTACK', this.item.Name, this.summedDamage, this.kill)
      if (this.ammoCost) this.state.RefundAmmoCost(this.ammoCost)
      this.init()
      this.$emit('reset')
    },
    init(): void {
      this.accuracy = this.minAccuracy
      this.difficulty = this.minDifficulty
      this.attackRoll = null
      this.rollResultString = null
      this.rollAccuracyResults = []
      this.attackQuick = false
      this.attackFree = false
      this.hit = false
      this.missed = false
      this.damageRolls = []
      this.damageResultString = null
      this.bonusDamage = null
      this.kill = false
      this.confirmed = false
      this.overkillHeat = 0
    },
  },
})
