var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.panel ? "bonusPanel" : "bonusPopup", {
    tag: "component",
    attrs: { bonus: _vm.bonus }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }