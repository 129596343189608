var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.item.NoAttack,
          expression: "!item.NoAttack"
        }
      ],
      staticClass: "mb-1"
    },
    [
      _vm.item.Size === "Superheavy"
        ? _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        tile: "",
                        block: "",
                        dark: "",
                        disabled: _vm.mech.IsStunned,
                        color: _vm.soloBarrageDisabled
                          ? "grey darken-2"
                          : "action--full"
                      },
                      on: {
                        click: function($event) {
                          return _vm.setBarrage(_vm.item, _vm.mount)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-hexagon-slice-6")
                      ]),
                      _vm._v("\n        barrage\n        "),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", "max-width": "700px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              right: "0"
                                            },
                                            attrs: { icon: "" }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "fadeSelect" },
                                          [
                                            _vm._v(
                                              "\n                mdi-information-outline\n              "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3155038433
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-card",
                            [
                              _c(
                                "div",
                                { staticClass: "heading h3 ma-1 pl-3" },
                                [_vm._v("BARRAGE — FULL ACTION")]
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c("v-card-text", {
                                staticClass: "body-text text--text mt-0 pt-1",
                                domProps: { innerHTML: _vm._s(_vm.barrageHelp) }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.item.Actions, function(a, i) {
                return _c(
                  "v-col",
                  { key: _vm.item.Name + "_action_" + i },
                  [_c("cc-action", { attrs: { action: a, active: "" } })],
                  1
                )
              })
            ],
            2
          )
        : _c(
            "v-row",
            { attrs: { dense: "", justify: "center" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        tile: "",
                        block: "",
                        dark: "",
                        disabled: _vm.mech.IsStunned,
                        color: _vm.canSkirmish
                          ? "action--quick"
                          : "grey darken-2"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.sk_dialog.show()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-hexagon-slice-3")
                      ]),
                      _vm._v("\n        skirmish\n        "),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", "max-width": "700px" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0"
                                          },
                                          attrs: { icon: "" }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "fadeSelect" },
                                        [
                                          _vm._v(
                                            "\n                mdi-information-outline\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-card",
                            [
                              _c(
                                "div",
                                { staticClass: "heading h3 ma-1 pl-3" },
                                [_vm._v("SKIRMISH — QUICK ACTION")]
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c("v-card-text", {
                                staticClass: "body-text text--text mt-0 pt-1",
                                domProps: {
                                  innerHTML: _vm._s(_vm.skirmishHelp)
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("skirmish-dialog", {
                    ref: "sk_dialog",
                    attrs: { item: _vm.item, mech: _vm.mech, mount: _vm.mount },
                    on: {
                      confirm: function($event) {
                        return _vm.completeSkirmish($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        tile: "",
                        block: "",
                        dark: "",
                        color: _vm.barrageToggle ? "secondary" : "action--full",
                        disabled: _vm.barrageDisabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.setBarrage(_vm.item, _vm.mount)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-hexagon-slice-6")
                      ]),
                      _vm._v("\n        barrage\n        "),
                      _vm.barrageToggle
                        ? _c("span", [
                            _vm._v("(" + _vm._s(_vm.barrageCount) + "/2)")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", "max-width": "700px" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0"
                                          },
                                          attrs: { icon: "" }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "fadeSelect" },
                                        [
                                          _vm._v(
                                            "\n                mdi-information-outline\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-card",
                            [
                              _c(
                                "div",
                                { staticClass: "heading h3 ma-1 pl-3" },
                                [_vm._v("BARRAGE — FULL ACTION")]
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c("v-card-text", {
                                staticClass: "body-text text--text mt-0 pt-1",
                                domProps: { innerHTML: _vm._s(_vm.barrageHelp) }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.item.Actions, function(a, i) {
                return _c(
                  "v-col",
                  { key: _vm.item.Name + "_action_" + i },
                  [_c("cc-action", { attrs: { action: a, active: "" } })],
                  1
                )
              }),
              _vm._v(" "),
              _c("barrage-dialog", {
                ref: "b_dialog",
                attrs: {
                  items: _vm.state.BarrageSelections,
                  mech: _vm.mech,
                  mounts: _vm.state.BarrageMounts
                },
                on: {
                  confirm: function($event) {
                    return _vm.regularConfirm()
                  }
                }
              })
            ],
            2
          ),
      _vm._v(" "),
      _c("sh-barrage-dialog", {
        ref: "sh_b_dialog",
        attrs: { mech: _vm.mech, cached: _vm.item },
        on: {
          confirm: function($event) {
            return _vm.shConfirm($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }