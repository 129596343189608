var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { md: "12", lg: "7" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "", align: "start" } },
                [
                  _c("v-col", [
                    _c("span", { staticClass: "heading h2 text--text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.item.Source) +
                          " " +
                          _vm._s(_vm.item.MechTypeString) +
                          " Frame\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tooltip",
                        {
                          attrs: {
                            title:
                              "Size " +
                              (_vm.item.Size === 0.5 ? "½" : _vm.item.Size),
                            content: _vm.glossary("size")
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mt-n2",
                              staticStyle: { "line-height": "40px" },
                              attrs: { size: "60", color: "accent" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.item.SizeIcon) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.item.Description
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "overline ml-n2 my-1 text--text" },
                      [_vm._v("COMPENDIUM ENTRY")]
                    ),
                    _vm._v(" "),
                    _c("p", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: _vm.item.Description,
                          expression: "item.Description"
                        }
                      ],
                      staticClass: "flavor-text"
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("span", { staticClass: "overline ml-n2 text--text" }, [
                  _vm._v("COMBAT PROFILE")
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass:
                      "heading h3 subtle--text light-panel text-center py-10"
                  },
                  [
                    _c("v-icon", { attrs: { large: "", color: "grey" } }, [
                      _vm._v("mdi-lock")
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n          FEATURE IN DEVELOPMENT\n        ")
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.lgAndUp
            ? _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("v-img", {
                    attrs: { src: _vm.item.DefaultImage, "max-width": "35vw" }
                  }),
                  _vm._v(" "),
                  _c(
                    "cc-tooltip",
                    {
                      attrs: { simple: "", content: "Feature In Development" }
                    },
                    [_c("frame-gallery-modal", { attrs: { frame: _vm.item } })],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("span", { staticClass: "overline ml-n2 mb-n2 text--text" }, [
        _vm._v("BASE FRAME ATTRIBUTES")
      ]),
      _vm._v(" "),
      _c("frame-statblock", { attrs: { frame: _vm.item } }),
      _vm._v(" "),
      _c("span", { staticClass: "overline ml-n2 text--text" }, [
        _vm._v("FRAME TRAITS")
      ]),
      _vm._v(" "),
      _vm._l(_vm.item.Traits, function(t, i) {
        return _c("cc-trait-item", {
          key: "trait_" + i,
          attrs: {
            trait: t,
            color: _vm.item.Manufacturer.GetColor(_vm.$vuetify.theme.dark)
          }
        })
      }),
      _vm._v(" "),
      _c("span", { staticClass: "overline ml-n2 text--text" }, [
        _vm._v("AVAILABLE WEAPON MOUNTS")
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-3", attrs: { justify: "space-around" } },
        _vm._l(_vm.item.Mounts, function(m, i) {
          return _c(
            "v-col",
            { key: m + i, attrs: { cols: "auto" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "clipped",
                  attrs: { color: "primary", dark: "" }
                },
                [
                  _c("v-card-text", { staticClass: "heading h3 px-8" }, [
                    _vm._v(_vm._s(m) + " Mount")
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("span", { staticClass: "overline ml-n2 text--text" }, [
        _vm._v("ONBOARD CORE SYSTEM")
      ]),
      _vm._v(" "),
      _c("frame-core-system-panel", { attrs: { cs: _vm.item.CoreSystem } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }