import { render, staticRenderFns } from "./PrintFooter.vue?vue&type=template&id=f403d4ac&"
import script from "./PrintFooter.vue?vue&type=script&lang=ts&"
export * from "./PrintFooter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBottomNavigation,VBtn,VIcon,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f403d4ac')) {
      api.createRecord('f403d4ac', component.options)
    } else {
      api.reload('f403d4ac', component.options)
    }
    module.hot.accept("./PrintFooter.vue?vue&type=template&id=f403d4ac&", function () {
      api.rerender('f403d4ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/features/pilot_management/Print/PrintFooter.vue"
export default component.exports