var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    { attrs: { outlined: "", dense: "", color: _vm.bonus.Color } },
    [_c("bonus-base", { attrs: { bonus: _vm.bonus } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }