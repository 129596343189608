var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "clipped-large text-center",
      staticStyle: { position: "relative" },
      attrs: {
        tile: "",
        color: "primary",
        elevation: _vm.creating ? 12 : 0,
        width: "215px",
        height: "118px"
      }
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.creating
            ? _c(
                "v-card-text",
                {
                  staticClass: "background",
                  staticStyle: {
                    height: "100%",
                    display: "flex",
                    "flex-direction": "column"
                  }
                },
                [
                  _c("v-text-field", {
                    ref: "nameField",
                    attrs: { placeholder: "Counter name", dense: "" },
                    on: {
                      keypress: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape"
                            ])
                          ) {
                            return null
                          }
                          return _vm.cancel($event)
                        },
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.create($event)
                        }
                      ]
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { staticClass: "mt-auto" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { height: "100%" },
                              attrs: {
                                color: "secondary",
                                block: "",
                                tile: "",
                                elevation: "0"
                              },
                              on: { click: _vm.cancel }
                            },
                            [_vm._v("\n            CANCEL\n          ")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { height: "100%" },
                              attrs: {
                                color: "primary",
                                block: "",
                                tile: "",
                                elevation: "0",
                                disabled: !_vm.name
                              },
                              on: { click: _vm.create }
                            },
                            [_vm._v("\n            Add\n          ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-btn",
                {
                  staticClass: "ml-n1",
                  staticStyle: { height: "100%" },
                  attrs: { block: "", tile: "", color: "primary" },
                  on: { click: _vm.startCreating }
                },
                [
                  _c(
                    "div",
                    [
                      _c("div", [_vm._v("Add Custom Counter")]),
                      _vm._v(" "),
                      _c("v-icon", { staticClass: "mt-2" }, [_vm._v("add")])
                    ],
                    1
                  )
                ]
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }