




























import Vue from 'vue'
export default Vue.extend({
  name: 'roster-group',
  data: () => ({
    groups: [
      { name: 'None', val: null, icon: 'mdi-close' },
      { name: 'Role', val: 'Class.Role', icon: 'cci-role-striker' },
      { name: 'Class', val: 'Class.Name', icon: 'mdi-rhombus-outline' },
      { name: 'Labels', val: 'LabelString', icon: 'mdi-label-outline' },
      { name: 'Campaign', val: 'Campaign', icon: 'mdi-map' },
      { name: 'Tier', val: 'Tier', icon: 'cci-rank-3' },
      { name: 'Power Rating', val: 'PowerTier', icon: 'mdi-battery-80' },
    ],
  }),
  methods: {
    flatten(val) {
      if (!val.length) return ''
      if (Array.isArray(val)) return val.join(' ,')
      return val
    },
  },
})
