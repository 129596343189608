var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          staticClass: "panel",
          attrs: { dense: "", justify: "center", align: "center" }
        },
        [
          _c(
            "v-col",
            {
              staticStyle: { "letter-spacing": "5px" },
              attrs: { cols: "auto" }
            },
            [_vm._v("SIGN IN")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c(
            "v-col",
            { attrs: { lg: "6", cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "E-Mail Address",
                  dense: "",
                  outlined: "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("v-fade-transition", [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.$emit("reverify", _vm.email)
                          }
                        }
                      },
                      [
                        _vm.email
                          ? _c("a", { staticClass: "fadeSelect" }, [
                              _c("i", [
                                _vm._v("Have a validation code? Click here.")
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { lg: "6", cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Password",
                  dense: "",
                  outlined: "",
                  "hide-details": "",
                  type: _vm.show ? "text" : "password",
                  "append-icon": _vm.show ? "mdi-eye" : "mdi-eye-off"
                },
                on: {
                  "click:append": function($event) {
                    _vm.show = !_vm.show
                  }
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text-right",
                  on: {
                    click: function($event) {
                      return _vm.$emit("set-state", "reset")
                    }
                  }
                },
                [
                  _c("a", { staticClass: "fadeSelect" }, [
                    _c("i", [_vm._v("Forgot Password?")])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-n2 text-center",
          attrs: { "no-gutters": "", justify: "center", align: "start" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    color: "secondary",
                    loading: _vm.loading,
                    disabled: !_vm.email || !_vm.password
                  },
                  on: {
                    click: function($event) {
                      return _vm.signIn()
                    }
                  }
                },
                [_vm._v("\n        Sign In\n      ")]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  attrs: { small: "", color: "accent", outlined: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("set-state", "sign-up")
                    }
                  }
                },
                [_vm._v("\n        Create Account\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-scroll-y-transition",
        { attrs: { "leave-absolute": "", "hide-on-leave": "" } },
        [
          _vm.error
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-2",
                  attrs: {
                    color: "error darken-1",
                    dark: "",
                    dense: "",
                    icon: "mdi-alert",
                    dismissible: ""
                  },
                  model: {
                    value: _vm.showError,
                    callback: function($$v) {
                      _vm.showError = $$v
                    },
                    expression: "showError"
                  }
                },
                [
                  _c("div", { staticClass: "font-weight-bold" }, [
                    _vm._v("ERROR")
                  ]),
                  _vm._v(" "),
                  _c("div", { domProps: { innerHTML: _vm._s(_vm.error) } })
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }