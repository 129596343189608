var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: _vm.cols } },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-1" },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mr-2 ml-2", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { color: "orange", size: "80px" } },
                        [
                          _vm.info.image
                            ? _c("v-img", { attrs: { src: _vm.info.image } })
                            : _c(
                                "span",
                                { staticClass: "white--text headline" },
                                [_vm._v(_vm._s(_vm.info.name.substring(0, 1)))]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", [
                    _c("div", { staticClass: "heading h2 mb-2 text--text" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.info.name) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.info.website
                      ? _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "" + _vm.info.website
                            }
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("mdi-web")
                            ]),
                            _vm._v(" "),
                            _c("span", [_vm._v("Website")])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.info.website
                      ? _c("span", { staticClass: "mx-3" }, [_vm._v("|")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.info.twitter
                      ? _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "https://twitter.com/" + _vm.info.twitter
                            }
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("mdi-twitter")
                            ]),
                            _vm._v(" "),
                            _c("span", [_vm._v("@" + _vm._s(_vm.info.twitter))])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.info.github
                      ? _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "https://github.com/" + _vm.info.github
                            }
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("mdi-github-circle")
                            ]),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.info.github))])
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }