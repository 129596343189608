var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "mt-7 px-0", attrs: { fluid: "" } },
    [
      _vm.pilot
        ? [
            _c("pilot-header"),
            _vm._v(" "),
            _c("router-view", { staticClass: "mt-5" }),
            _vm._v(" "),
            _c("v-spacer", { staticStyle: { "min-height": "80px" } })
          ]
        : [
            _c(
              "h1",
              { staticStyle: { "margin-top": "50px", "text-align": "center" } },
              [_vm._v("ERROR // INVALID PILOT")]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }