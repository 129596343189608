var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "90vw" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      "x-large": _vm.$vuetify.breakpoint.lgAndUp,
                      outlined: "",
                      color: _vm.pilotLicense.License.Manufacturer.GetColor(
                        _vm.$vuetify.theme.dark
                      ),
                      block: ""
                    }
                  },
                  on
                ),
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        large: "",
                        color: _vm.pilotLicense.License.Manufacturer.GetColor(
                          _vm.$vuetify.theme.dark
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n        cci-rank-" +
                          _vm._s(_vm.pilotLicense.Rank) +
                          "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.pilotLicense.License.Source) +
                      "\n      " +
                      _vm._s(_vm.pilotLicense.License.Name) +
                      "\n      " +
                      _vm._s("I".repeat(_vm.pilotLicense.Rank)) +
                      "\n      "
                  ),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("cc-logo", {
                    attrs: {
                      size: "large",
                      source: _vm.pilotLicense.License.Manufacturer
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c("cc-license-panel", {
                attrs: {
                  license: _vm.pilotLicense.License,
                  ranked: "",
                  rank: _vm.pilotLicense.Rank
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }