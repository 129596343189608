var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "", "open-on-hover": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "" } }, on),
                [_c("v-icon", [_vm._v("mdi-select-group")])],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        [
          _c(
            "v-subheader",
            { staticClass: "heading h2 white--text primary py-0 px-2 mt-n3" },
            [_vm._v("\n      Group NPCs By\n    ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.groups, function(g) {
            return _c(
              "v-list-item",
              {
                key: g.name,
                staticClass: "flavor-text",
                on: {
                  click: function($event) {
                    return _vm.$emit("set", g.val)
                  }
                }
              },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "ma-0 mr-2 mt-3" },
                  [_c("v-icon", [_vm._v(_vm._s(g.icon))])],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-list-item-content",
                  [_c("v-list-item-title", [_vm._v(_vm._s(g.name))])],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }