var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.componentLoader
    ? _c(_vm.componentLoader, {
        tag: "component",
        attrs: { item: _vm.item, notes: _vm.notes }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }