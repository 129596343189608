var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "90vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "cc-titlebar",
            {
              attrs: {
                icon: "cci-" + _vm.$_.kebabCase(_vm.item.ItemType),
                color: _vm.$_.kebabCase(_vm.item.ItemType)
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.item.Source) +
                  " " +
                  _vm._s(_vm.item.Name) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _vm.item.ItemType === "NPC Class"
                ? _c("class-card", {
                    ref: "modal_" + _vm.item.ID,
                    attrs: { npcc: _vm.item, "no-confirm": "" }
                  })
                : _c("cc-item-card", { attrs: { item: _vm.item } })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("dismiss")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }