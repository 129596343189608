var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "text-center",
      attrs: { ripple: "", to: _vm.to, outlined: "", tile: "" }
    },
    [
      _c(
        "div",
        { staticClass: "primary fade pt-10 pb-10" },
        [
          _c("v-icon", { attrs: { dark: "", size: "180" } }, [
            _vm._v("\n      " + _vm._s(_vm.icon) + "\n    ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("h2", { staticClass: "heading mt-1" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  ")
      ]),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-2" }),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "flavor-text px-2",
          staticStyle: { "min-height": "50px" }
        },
        [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }