var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.actions, function(a) {
      return _c("div", { key: "action_" + a.ID }, [
        _c(
          "div",
          { staticClass: "my-n1" },
          [
            _c("v-icon", {
              attrs: { small: "" },
              domProps: { innerHTML: _vm._s(a.Icon) }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "caption" }, [
              _c("b", [_vm._v(_vm._s(a.Name))]),
              _vm._v("\n        (" + _vm._s(a.Activation) + ")\n      ")
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-3" }, [
          a.Init
            ? _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: a.Init,
                    expression: "a.Init"
                  }
                ],
                staticClass: "caption"
              })
            : _vm._e(),
          _vm._v(" "),
          a.Trigger
            ? _c("div", [
                _c("div", { staticClass: " overline my-n2" }, [
                  _vm._v("Trigger")
                ]),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "html-safe",
                      rawName: "v-html-safe",
                      value: a.Trigger,
                      expression: "a.Trigger"
                    }
                  ],
                  staticClass: "caption "
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          a.Detail
            ? _c("div", [
                _c("div", { staticClass: "overline my-n2" }, [
                  _vm._v("Effect")
                ]),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "html-safe",
                      rawName: "v-html-safe",
                      value: a.Detail,
                      expression: "a.Detail"
                    }
                  ],
                  staticClass: "caption "
                })
              ])
            : _vm._e()
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }