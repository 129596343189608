var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      attrs: {
        color: _vm.color,
        icon: _vm.icon,
        outlined: "",
        prominent: "",
        dense: "",
        border: "left"
      }
    },
    [
      _c(
        "v-row",
        { attrs: { dense: "", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", disabled: _vm.index === 0 },
                  on: {
                    click: function($event) {
                      return _vm.$emit("move-up")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-arrow-up")])],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: "",
                    disabled: _vm.index === _vm.missionLength
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("move-down")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-arrow-down")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "mt-n2", attrs: { dense: "" } },
                [
                  _c("v-col", [
                    _c(
                      "span",
                      { staticClass: "heading h3" },
                      [_vm._t("title")],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto mr-3", attrs: { cols: "auto" } },
                    [_vm._t("items")],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm._t("default"),
              _vm._v(" "),
              _c("v-textarea", {
                attrs: {
                  label: "Notes",
                  dense: "",
                  "hide-details": "",
                  filled: "",
                  "auto-grow": "",
                  rows: "1"
                },
                model: {
                  value: _vm.step.Note,
                  callback: function($$v) {
                    _vm.$set(_vm.step, "Note", $$v)
                  },
                  expression: "step.Note"
                }
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", color: "error" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("remove")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-delete")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }