var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c("v-img", {
        ref: "img",
        staticClass: "pa-2",
        class: _vm.white
          ? "white-emblem"
          : _vm.$vuetify.theme.dark
          ? "white-emblem"
          : "black-emblem",
        attrs: {
          src: _vm.src,
          width: _vm.size,
          "min-height": "100%",
          contain: ""
        },
        on: {
          error: function($event) {
            return _vm.imageLoadFailed()
          }
        }
      }),
      _vm._v(" "),
      _vm.backup
        ? _c(
            "div",
            {
              class: "banner " + (_vm.small ? "overline" : "caption"),
              style: "width: " + _vm.size
            },
            [_vm._v("\n    " + _vm._s(_vm.backup) + "\n  ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }