var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-1 mb-3 mx-2" },
    _vm._l(_vm.deployables, function(d) {
      return _c(
        "div",
        { key: "deployable" + d.ID, staticStyle: { border: "1px solid grey" } },
        [
          _c("div", { staticClass: "text-center caption font-weight-bold" }, [
            _vm._v(_vm._s(d.name))
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass: "text-center mt-n1",
              attrs: { justify: "center", dense: "" }
            },
            [
              d.size
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption font-weight-bold",
                      domProps: {
                        innerHTML: _vm._s(
                          "Size " + (d.size === 0.5 ? "½" : d.size)
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.armor
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s("<b>Armor</b>: " + d.armor)
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.hp || d.size
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s(
                          "<b>HP</b>: " +
                            (d.hp
                              ? d.hp.toString().replace(/[{}]/gim, "")
                              : parseFloat(d.size || 0.5) * 10)
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.size
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s(
                          "<b>Evasion:</b> " + (d.evasion || 10)
                        )
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.edef
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s("<b>E-Defense:</b> " + d.edef)
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.heatcap
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s("<b>Heat Capacity:</b> " + d.heatcap)
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.sensor
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s("<b>Sensor Range:</b> " + d.sensor)
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.techattack
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s("<b>Tech Attack:</b> " + d.techattack)
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.repcap
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s("<b>Repair Capacity:</b> " + d.repcap)
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.save
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s("<b>Save Target:</b> " + d.save)
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              d.speed
                ? _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", {
                      staticClass: "caption",
                      domProps: {
                        innerHTML: _vm._s("<b>Speed:</b> " + d.speed)
                      }
                    })
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("p", {
              staticClass: "caption mb-0 px-2",
              domProps: { innerHTML: _vm._s(d.detail) }
            })
          ]),
          _vm._v(" "),
          _vm.actions(d).length
            ? _c(
                "div",
                [_c("print-action", { attrs: { actions: _vm.actions(d) } })],
                1
              )
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }