var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel-view",
    { ref: "view" },
    [
      _c("cc-gm-header", {
        attrs: { slot: "title", title: "NPC ROSTER" },
        slot: "title"
      }),
      _vm._v(" "),
      _c(
        "template",
        { slot: "left" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      "prepend-inner-icon": "mdi-magnify",
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                      clearable: ""
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("roster-group", {
                    on: {
                      set: function($event) {
                        _vm.grouping = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2 " }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-data-table", {
                staticClass: "transparent",
                staticStyle: { "min-width": "100%" },
                attrs: {
                  dense: "",
                  items: _vm.npcs,
                  headers: _vm.headers,
                  "group-by": _vm.grouping,
                  search: _vm.search,
                  "no-results-text": "No NPCs Found",
                  "no-data-text": "No Saved NPCs",
                  "hide-default-footer": "",
                  "calculate-widths": "",
                  "disable-pagination": ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "group.header",
                      fn: function(h) {
                        return [
                          _c(
                            "div",
                            { staticClass: "primary sliced" },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("mdi-chevron-right")
                              ]),
                              _vm._v(" "),
                              h.group && h.group !== "null"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "heading white--text text-uppercase"
                                    },
                                    [
                                      Array.isArray(h.group)
                                        ? _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                h.group.join(", ")
                                              )
                                            }
                                          })
                                        : _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(h.group)
                                            }
                                          })
                                    ]
                                  )
                                : _c("span", [_vm._v("NONE")])
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.Name",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "span",
                            {
                              staticClass:
                                "accent--text heading clickable ml-n2",
                              on: {
                                click: function($event) {
                                  _vm.selectedNpc = item
                                }
                              }
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-x": "", left: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mt-n1 mr-n2",
                                                  attrs: {
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                    }
                                                  }
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "fadeSelect",
                                                    attrs: { small: "" }
                                                  },
                                                  [_vm._v("mdi-settings")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.copyNpc(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            { staticClass: "ma-0 mr-2 mt-2" },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-content-copy")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  "Copy " + _vm._s(item.Name)
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.exportNpc(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            { staticClass: "ma-0 mr-2 mt-2" },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-export-variant")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  "Export " + _vm._s(item.Name)
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        { attrs: { disabled: "" } },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            { staticClass: "ma-0 mr-2 mt-2" },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-printer")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v("Print NPC Sheet")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.setStatblock(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            { staticClass: "ma-0 mr-2 mt-2" },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-file-document-box")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v("Generate NPC Statblock")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider"),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.deleteNpc(item)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-list-item-icon",
                                            { staticClass: "ma-0 mr-2 mt-2" },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "error" } },
                                                [_vm._v("mdi-delete")]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                { staticClass: "error--text" },
                                                [
                                                  _vm._v(
                                                    "\n                      Delete " +
                                                      _vm._s(item.Name) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.Name) +
                                  "\n          "
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-scroll-x-transition",
                            { attrs: { "leave-absolute": "" } },
                            [
                              _vm.selectedNpc === item
                                ? _c(
                                    "v-icon",
                                    { attrs: { right: "", color: "accent" } },
                                    [
                                      _vm._v(
                                        "\n              mdi-chevron-triple-right\n            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.Class",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "span",
                            { staticClass: "caption text-uppercase" },
                            [_vm._v(_vm._s(item.Class.Name))]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.Role",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                simple: "",
                                content: item.Class.Role.toUpperCase()
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { large: "", color: item.Class.Color }
                                },
                                [_vm._v(_vm._s(item.Class.RoleIcon))]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "item.Tier",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "cc-tooltip",
                            {
                              attrs: {
                                simple: "",
                                content: "TIER " + item.Tier + " NPC"
                              }
                            },
                            [
                              item.Tier === "custom"
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        large: "",
                                        color: "grey darken-2"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              mdi-star-circle-outline\n            "
                                      )
                                    ]
                                  )
                                : _c(
                                    "v-icon",
                                    {
                                      style:
                                        "opacity: " +
                                        (item.Tier === 1
                                          ? "0.55"
                                          : item.Tier === 2
                                          ? "0.75"
                                          : "1"),
                                      attrs: { large: "", color: "accent" }
                                    },
                                    [
                                      _vm._v(
                                        "\n              cci-rank-" +
                                          _vm._s(item.Tier) +
                                          "\n            "
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2 " }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center", dense: "", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        tile: "",
                        color: "primary",
                        large: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "new-npc" })
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus")
                      ]),
                      _vm._v("\n          Add New NPC\n        ")
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "50%", persistent: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "mt-1",
                                    attrs: {
                                      small: "",
                                      outlined: "",
                                      block: "",
                                      tile: "",
                                      color: "accent"
                                    }
                                  },
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-application-import")
                                  ]),
                                  _vm._v(
                                    "\n              Import NPC\n            "
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.importDialog,
                        callback: function($$v) {
                          _vm.importDialog = $$v
                        },
                        expression: "importDialog"
                      }
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { attrs: { flat: "", tile: "" } },
                        [
                          _c("v-card-title", [_vm._v("Select File to Import")]),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("v-file-input", {
                                attrs: {
                                  counter: "",
                                  label: "NPC .JSON File",
                                  outlined: "",
                                  dense: ""
                                },
                                model: {
                                  value: _vm.npcImportFile,
                                  callback: function($$v) {
                                    _vm.npcImportFile = $$v
                                  },
                                  expression: "npcImportFile"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.importDialog = false
                                    }
                                  }
                                },
                                [_vm._v("Dismiss")]
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    tile: "",
                                    color: "secondary",
                                    disabled: !_vm.importNpc
                                  },
                                  on: { click: _vm.confirmImport }
                                },
                                [
                                  _vm._v(
                                    "\n                Confirm\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "50%" },
                      model: {
                        value: _vm.statblockDialog,
                        callback: function($$v) {
                          _vm.statblockDialog = $$v
                        },
                        expression: "statblockDialog"
                      }
                    },
                    [
                      _c(
                        "cc-titled-panel",
                        { attrs: { title: "NPC Statblock" } },
                        [
                          _vm.statblockNpc
                            ? _c("v-textarea", {
                                staticClass: "flavor-text",
                                attrs: {
                                  value: _vm.statblock(),
                                  "auto-grow": "",
                                  readonly: "",
                                  outlined: "",
                                  filled: ""
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "right" },
        [_c("npc-card", { attrs: { npc: _vm.selectedNpc } })],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }