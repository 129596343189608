var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-slide-x-transition",
        { attrs: { group: "", "leave-absolute": "" } },
        [
          _vm.state === "sign-in"
            ? _c("sign-in", {
                key: "auth-signin",
                on: {
                  "set-state": function($event) {
                    _vm.state = $event
                  },
                  reverify: function($event) {
                    return _vm.verifyFlow($event)
                  }
                }
              })
            : _vm.state === "reset"
            ? _c("password-reset", {
                key: "auth-reset",
                on: {
                  "set-state": function($event) {
                    _vm.state = $event
                  }
                }
              })
            : _vm.state === "sign-up"
            ? _c("sign-up", {
                key: "auth-signup",
                attrs: { "oauth-code": _vm.oauthCode },
                on: {
                  "set-state": function($event) {
                    _vm.state = $event
                  },
                  success: function($event) {
                    return _vm.verifyFlow($event)
                  }
                }
              })
            : _vm.state === "verify"
            ? _c("verify", {
                key: "auth-verify",
                attrs: { email: _vm.email },
                on: {
                  "set-state": function($event) {
                    _vm.state = $event
                  }
                }
              })
            : _vm.state === "signed-in"
            ? _c("signed-in", {
                key: "auth-signedin",
                attrs: { email: _vm.email },
                on: {
                  "set-state": function($event) {
                    _vm.state = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }