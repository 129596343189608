var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "mt-9",
      staticStyle: { height: "100%" },
      attrs: { fluid: "" }
    },
    [
      _vm._t("title"),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "mt-n4",
              staticStyle: {
                "min-height": "87vh",
                "max-height": "87vh",
                "overflow-y": "scroll"
              },
              attrs: { cols: "4" }
            },
            [_vm._t("left")],
            2
          ),
          _vm._v(" "),
          _c("v-divider", { attrs: { vertical: "" } }),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "bordered-view", attrs: { id: "scrollTarget" } },
            [_vm._t("right")],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }