var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    { staticClass: "ma-1", attrs: { outlined: "", label: "" } },
    [_vm._v(_vm._s(_vm.name))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }