var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mt-n6 mb-n6", attrs: { dense: "", align: "center" } },
        [
          _c("v-col", [_c("ident-block")], 1),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c("v-col", { attrs: { cols: "1" } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { attrs: { cols: "12" } }, [_c("image-block")], 1)
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8", dense: "" } },
            [
              _c("clone-block"),
              _vm._v(" "),
              _c("history-block"),
              _vm._v(" "),
              _c("appearance-block"),
              _vm._v(" "),
              _c("notes-block")
            ],
            1
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                { attrs: { cols: "4", dense: "" } },
                [_c("image-block")],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }