var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.type, {
    tag: "component",
    attrs: {
      selectable: _vm.selectable,
      rank: _vm.rank,
      "can-add": _vm.canAdd,
      "hide-locked": _vm.hideLocked,
      talent: _vm.talent
    },
    on: {
      expand: function($event) {
        _vm.expand = $event
      },
      clicked: function($event) {
        return _vm.$emit("clicked")
      },
      add: function($event) {
        return _vm.$emit("add")
      },
      remove: function($event) {
        return _vm.$emit("remove")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }