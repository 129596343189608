var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.tag.IsHidden,
          expression: "!tag.IsHidden"
        }
      ],
      staticClass: "mb-1",
      attrs: {
        outlined: "",
        dense: "",
        color: _vm.tag.isExotic ? "exotic" : _vm.color,
        border: "left"
      }
    },
    [
      _vm.$vuetify.breakpoint.lgAndUp
        ? _c(
            "v-icon",
            {
              staticClass: "ml-n2 mr-1",
              attrs: {
                slot: "prepend",
                "x-large": "",
                color: _vm.tag.isExotic ? "exotic" : _vm.color
              },
              slot: "prepend"
            },
            [_vm._v("\n    label\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.tag.err
        ? _c("span", [_vm._v("ERR: MISSING DATA")])
        : _c("div", [
            _c(
              "h3",
              {
                class:
                  "heading " +
                  (_vm.tag.IsExotic ? "exotic" : "accent") +
                  "--text"
              },
              [
                _vm.$vuetify.breakpoint.mdAndDown
                  ? _c(
                      "v-icon",
                      {
                        staticClass: "mt-n1",
                        attrs: {
                          color: _vm.tag.isExotic ? "exotic" : _vm.color
                        }
                      },
                      [_vm._v("\n        label\n      ")]
                    )
                  : _vm._e(),
                _vm._v(
                  "\n      " + _vm._s(_vm.tag.GetName(_vm.bonus)) + "\n    "
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("p", {
              staticClass: "text--text body-text pb-0 mb-0",
              domProps: { innerHTML: _vm._s(_vm.tag.GetDescription(_vm.bonus)) }
            })
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }