var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "75vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "title-bg clipped-large", dark: "", flat: "" } },
            [
              _c("v-toolbar-title", { staticClass: "heading h1" }, [
                _vm._v("OVERHEATING")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "11" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Roll")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Result")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Effect")
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-5")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-minus")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-6")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Emergency Shunt")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Cooling systems recover and manage to contain the peaking heat levels. However,\n                  your mech is\n                  "
                                    ),
                                    _c("b", [_vm._v("impaired")]),
                                    _vm._v(
                                      "\n                  until the end of your next turn.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-2")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-minus")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-4")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Power Plant Destabilize")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Your mech’s power plant becomes unstable, ejecting jets of plasma. Your mech\n                  suffers from the\n                  "
                                    ),
                                    _c("b", [_vm._v("exposed")]),
                                    _vm._v(
                                      "\n                  status (taking double damage) until it takes action to remove it.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Meltdown")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  This result has different outcomes depending on how much reactor stress your\n                  mech has remaining.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  3+ - Your mech immediately suffers from the\n                  "
                                    ),
                                    _c("b", [_vm._v("exposed")]),
                                    _vm._v(
                                      "\n                  condition until it takes action to remove it.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  2 - Your mech must pass a engineering check or suffer a reactor meltdown at the\n                  end of 1d6 turns after this one (rolled by the GM). You can reverse it by taking\n                  a full action and repeating this check. Even on a successful check, your mech\n                  suffers from the\n                  "
                                    ),
                                    _c("b", [_vm._v("exposed")]),
                                    _vm._v(
                                      "\n                  condition until it takes action to remove it.\n                  "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                  1 or lower - Your mech suffers a reactor meltdown at the end of your next turn.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-icon", { attrs: { large: "" } }, [
                                    _vm._v("mdi-dice-1")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c("span", { staticClass: "heading h3" }, [
                                  _vm._v("Irreversible Meltdown")
                                ])
                              ]),
                              _vm._v(" "),
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "span",
                                  { staticClass: "body-text text--text" },
                                  [
                                    _vm._v(
                                      "\n                  Your reactor goes critical. Your mech will suffer a reactor meltdown at the end\n                  of your next turn.\n                "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "ma-2" }),
                          _vm._v(" "),
                          _c("p", { staticClass: "ma-3 flavor-text" }, [
                            _vm._v(
                              "\n              Certain overheating results can cause a reactor meltdown. This can be immediate, or\n              involve a countdown (in which case update the countdown at the start of your turn.\n              The meltdown triggers when specified). When a mech suffers a reactor meltdown, any\n              pilot inside immediately dies, the mech is immediately vaporized in a catastrophic\n              eruption, annihilating the wreck, and any characters inside a burst 2 area centered\n              on the mech must pass an agility save or take 4d6 explosive damage, and half on a\n              successful save\n            "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("Dismiss")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }