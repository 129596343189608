var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-sidebar-view",
    [
      _vm._l(_vm.templates, function(e, i) {
        return _c(
          "v-list-item",
          {
            key: i + "_sidebar'",
            attrs: {
              slot: "sidebar",
              link: "",
              disabled: !_vm.availableTemplates.some(function(x) {
                return x.ID === e.ID
              })
            },
            on: {
              click: function($event) {
                return _vm.$vuetify.goTo("#e_" + e.ID, {
                  duration: 150,
                  easing: "easeInOutQuad",
                  offset: 25,
                  container: ".v-dialog--active"
                })
              }
            },
            slot: "sidebar"
          },
          [
            _c("v-list-item-title", { staticClass: "heading h2 ml-2" }, [
              _vm._v(_vm._s(e.Name))
            ])
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      !_vm.availableTemplates.length
        ? _c("div", { staticClass: "subtle--text heading h2 text-center" }, [
            _vm._v("\n    // NO TEMPLATES AVAILABLE //\n  ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.availableTemplates, function(e, i) {
        return _c(
          "v-row",
          { key: e.ID + "_" + i, attrs: { id: "e_" + e.ID } },
          [
            _c(
              "v-col",
              [
                _c(
                  "cc-titled-panel",
                  {
                    attrs: {
                      dense: "",
                      icon: "cci-trait",
                      title: e.Name,
                      color: "primary"
                    }
                  },
                  [
                    _c("p", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: e.Description,
                          expression: "e.Description"
                        }
                      ],
                      staticClass: "flavor-text mb-0"
                    }),
                    _vm._v(" "),
                    _c("v-divider", { staticClass: "my-2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "heading" }, [
                      _c("b", { staticClass: "accent--text" }, [
                        _vm._v("Base")
                      ]),
                      _vm._v("\n          Features\n        ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "mr-2 mt-n1", attrs: { dense: "" } },
                      _vm._l(e.BaseFeatures, function(f) {
                        return _c(
                          "v-col",
                          { key: f.ID, attrs: { cols: "auto" } },
                          [
                            _c("cc-item-modal", {
                              attrs: { item: f, "small-btn": "" }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    e.OptionalFeatures.length
                      ? _c("span", { staticClass: "heading" }, [
                          _c("b", { staticClass: "accent--text" }, [
                            _vm._v("Optional")
                          ]),
                          _vm._v("\n          Features\n        ")
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "mr-2 mt-n1 pb-2", attrs: { dense: "" } },
                      _vm._l(e.OptionalFeatures, function(f) {
                        return _c(
                          "v-col",
                          { key: f.ID, attrs: { cols: "auto" } },
                          [
                            _c("cc-item-modal", {
                              attrs: { item: f, "small-btn": "" }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { dense: "", justify: "center" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "10" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  block: "",
                                  tile: "",
                                  outlined: "",
                                  color: "secondary"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("select", e)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("mdi-plus")
                                ]),
                                _vm._v(
                                  "\n              Assign " +
                                    _vm._s(e.Name) +
                                    " Template\n            "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }