var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "containerCard", attrs: { flat: "", tile: "" } },
    [
      _c(
        "v-tabs",
        {
          attrs: {
            "background-color": "panel",
            color: "accent",
            "icons-and-text": ""
          },
          model: {
            value: _vm.tabs,
            callback: function($$v) {
              _vm.tabs = $$v
            },
            expression: "tabs"
          }
        },
        [
          _c(
            "v-tab",
            [
              _vm._v("\n      Content Packs\n      "),
              _c("v-icon", [_vm._v("list_alt")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            [
              _vm._v("\n      Install Content\n      "),
              _c("v-icon", [_vm._v("open_in_browser")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            [
              _vm._v("\n      LCP Directory\n      "),
              _c("v-icon", [_vm._v("mdi-format-list-text")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tabs,
            callback: function($$v) {
              _vm.tabs = $$v
            },
            expression: "tabs"
          }
        },
        [
          _c("v-tab-item", [_c("v-container", [_c("packs-list")], 1)], 1),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-container",
                [_c("pack-install", { on: { installed: _vm.onInstalled } })],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-tab-item", [_c("v-container", [_c("packs-directory")], 1)], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }