































































import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class NewCounter extends Vue {
  creating = false

  name = ''

  async startCreating(): Promise<void> {
    this.creating = true
    await Vue.nextTick()
    ;(this.$refs.nameField as any).focus()
  }

  cancel(): void {
    this.name = ''
    this.creating = false
  }

  create(): void {
    if (!this.name) return
    this.$emit('create', this.name)
    this.name = ''
    this.creating = false
  }
}
