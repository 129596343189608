var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-sidebar-view",
    [
      _vm._l(_vm.array, function(e, i) {
        return _c(
          "v-list-item",
          {
            key: i + "_sidebar'",
            attrs: { slot: "sidebar", link: "" },
            on: {
              click: function($event) {
                _vm.$vuetify.goTo("#e_" + e[_vm.nameKey].replace(/\W/g, ""), {
                  duration: 150,
                  easing: "easeInOutQuad",
                  offset: 25
                })
              }
            },
            slot: "sidebar"
          },
          [
            _c("v-list-item-title", { staticClass: "heading h3 ml-2" }, [
              _vm._v(_vm._s(e[_vm.nameKey]))
            ])
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("h1", { staticClass: "heading mb-3 mt-2" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _vm._v(" "),
      _vm._l(_vm.array, function(e, i) {
        return _c(
          "cc-titled-panel",
          {
            key: e[_vm.nameKey].replace(/\W/g, "") + "_" + i,
            staticClass: "my-6",
            attrs: {
              id: "e_" + e[_vm.nameKey].replace(/\W/g, ""),
              icon: _vm.icon || "",
              title: e[_vm.nameKey],
              dense: ""
            }
          },
          [
            _vm.subKey
              ? _c("h3", {
                  directives: [
                    {
                      name: "html-safe",
                      rawName: "v-html-safe",
                      value: e[_vm.subKey],
                      expression: "e[subKey]"
                    }
                  ],
                  staticClass: "heading mb-2"
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: e[_vm.descriptionKey],
                  expression: "e[descriptionKey]"
                }
              ],
              staticClass: "body-text mb-1"
            })
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }