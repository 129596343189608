var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        { staticClass: "text-center flavor-text" },
        [
          _c("span", { staticClass: "overline" }, [
            _vm._v("// PROCESS INTERRUPT: AUTHORIZATION REQUIRED //")
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          !_vm.noCc ? _c("span", [_vm._v("//[COMP/CON:")]) : _vm._e(),
          _vm._v(" "),
          _c("b", {
            staticClass: "stark--text",
            domProps: { innerHTML: _vm._s(_vm.content) }
          }),
          _vm._v(" "),
          !_vm.noCc ? _c("span", [_vm._v("]")]) : _vm._e(),
          _vm._v(" "),
          _c("v-divider", { staticClass: "my-2" }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "cc-btn",
                {
                  staticClass: "ml-auto",
                  attrs: { small: "", color: "error" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm")
                    }
                  }
                },
                [_vm._v("\n        CONFIRM\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }