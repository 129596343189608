var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticStyle: { opacity: "0.4" },
      attrs: { tile: "", outlined: "", color: "panel darken-1" }
    },
    [
      _c("v-card-text", { staticClass: "text-center" }, [
        _c(
          "h2",
          {
            staticClass: "heading flavor-text subtle--text text--darken-1",
            staticStyle: { opacity: "0.6" }
          },
          [_vm._v("\n      // NO DATA //\n    ")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }