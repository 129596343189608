var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    { attrs: { outlined: "", dense: "" } },
    [_c("synergy-base", { attrs: { synergy: _vm.synergy } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }