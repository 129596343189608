var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "", align: "start" } },
        [
          _c("v-col", { staticClass: "mr-4", attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "overline mt-n2 mb-n4" }, [
              _vm._v("CALLSIGN")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "heading h2 my-n2" }, [
              _vm._v("\n        " + _vm._s(_vm.pilot.Callsign) + "\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "my-n2" }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.pilot.Name) + ", LL " + _vm._s(_vm.pilot.Level)
                )
              ]),
              _vm._v(" "),
              _vm.pilot.Background
                ? _c("div", { staticClass: "caption my-n1 grey--text" }, [
                    _vm._v(_vm._s(_vm.pilot.Background))
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto mr-auto" },
            [
              _c(
                "v-row",
                { attrs: { dense: "", justify: "space-between" } },
                [
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", { staticClass: "overline mt-n2 mb-n3" }, [
                      _vm._v("HP")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-n1",
                            attrs: { size: "50", color: "grey lighten-3" }
                          },
                          [_vm._v("mdi-hexagon-outline")]
                        ),
                        _vm._v(" "),
                        _c("b", {
                          staticClass: "flavor-text pt-3",
                          domProps: { innerHTML: _vm._s("/" + _vm.pilot.MaxHP) }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", { staticClass: "overline mt-n2 mb-n3 ml-n7" }, [
                      _vm._v("ARMOR")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "relative",
                          width: "max-content"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "50", color: "grey lighten-3" } },
                          [_vm._v("mdi-shield-outline")]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "heading p-stat icon-overlap",
                          domProps: { innerHTML: _vm._s(_vm.pilot.Armor) }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", { staticClass: "overline mt-n2 mb-n3 ml-n6" }, [
                      _vm._v("E-DEF")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "relative",
                          width: "max-content"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "50", color: "grey lighten-3" } },
                          [_vm._v("cci-marker")]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "heading p-stat icon-overlap",
                          domProps: { innerHTML: _vm._s(_vm.pilot.EDefense) }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", { staticClass: "overline mt-n2 mb-n3 ml-n8" }, [
                      _vm._v("EVASION")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "relative",
                          width: "max-content"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "50", color: "grey lighten-3" } },
                          [_vm._v("cci-evasion")]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "heading p-stat icon-overlap",
                          domProps: { innerHTML: _vm._s(_vm.pilot.Evasion) }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("div", { staticClass: "overline mt-n2 mb-n3 ml-n6" }, [
                      _vm._v("SPEED")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "relative",
                          width: "max-content"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "50", color: "grey lighten-3" } },
                          [_vm._v("$vuetify.icons.move")]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "heading p-stat icon-overlap",
                          domProps: { innerHTML: _vm._s(_vm.pilot.Speed) }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right mt-n1 ml-4", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "overline  mr-9" }, [_vm._v("GRIT")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "heading mt-n5",
                  staticStyle: { "font-size": "65px", "line-height": "60px" }
                },
                [_vm._v("\n        +" + _vm._s(_vm.pilot.Grit) + "\n      ")]
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", align: "start", justify: "space-between" } },
        [
          _c("v-col", [
            _c("div", { staticClass: "overline" }, [_vm._v("SKILL TRIGGERS")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-left" },
              _vm._l(_vm.pilot.Skills, function(s, i) {
                return _c(
                  "v-chip",
                  {
                    key: "psk_" + i,
                    staticClass: "mx-1 mb-1",
                    attrs: { label: "", outlined: "", small: "" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "stat-text ml-n2 mr-2",
                        staticStyle: {
                          "background-color": "var(--v-gray-lighten2)"
                        }
                      },
                      [
                        _vm._v(
                          "\n            +" + _vm._s(s.Bonus) + "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(s.Skill.Trigger))])
                  ]
                )
              }),
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "mt-n5 pl-3",
                  attrs: { dense: "", justify: "space-between" }
                },
                [
                  _c("v-col", [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold overline  pr-4" },
                      [_vm._v("HULL")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ml-3 mt-n3",
                        staticStyle: {
                          position: "relative",
                          width: "max-content"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticStyle: { "margin-right": "-3px!important" },
                            attrs: { "x-large": "", color: "grey lighten-1" }
                          },
                          [
                            _vm._v(
                              "\n              mdi-hexagon-outline\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "heading h2 icon-overlap mt-1",
                          domProps: {
                            innerHTML: _vm._s(_vm.pilot.MechSkills.Hull)
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-col", [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold overline  pr-3" },
                      [_vm._v("AGI")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ml-3 mt-n3",
                        staticStyle: {
                          position: "relative",
                          width: "max-content"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticStyle: { "margin-right": "-3px!important" },
                            attrs: { "x-large": "", color: "grey lighten-1" }
                          },
                          [
                            _vm._v(
                              "\n              mdi-hexagon-outline\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "heading h2 icon-overlap mt-1",
                          domProps: {
                            innerHTML: _vm._s(_vm.pilot.MechSkills.Agi)
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-col", [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold overline  pr-3" },
                      [_vm._v("SYS")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ml-3 mt-n3",
                        staticStyle: {
                          position: "relative",
                          width: "max-content"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticStyle: { "margin-right": "-3px!important" },
                            attrs: { "x-large": "", color: "grey lighten-1" }
                          },
                          [
                            _vm._v(
                              "\n              mdi-hexagon-outline\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "heading h2 icon-overlap mt-1",
                          domProps: {
                            innerHTML: _vm._s(_vm.pilot.MechSkills.Sys)
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-col", [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold overline  pr-3" },
                      [_vm._v("ENG")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ml-3 mt-n3",
                        staticStyle: {
                          position: "relative",
                          width: "max-content"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticStyle: { "margin-right": "-3px!important" },
                            attrs: { "x-large": "", color: "grey lighten-1" }
                          },
                          [
                            _vm._v(
                              "\n              mdi-hexagon-outline\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "heading h2 icon-overlap mt-1",
                          domProps: {
                            innerHTML: _vm._s(_vm.pilot.MechSkills.Eng)
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.pilot.Licenses.length
        ? _c(
            "v-row",
            {
              staticClass: "mt-n2",
              attrs: { dense: "", align: "start", justify: "space-between" }
            },
            [
              _c("v-col", [
                _c("div", { staticClass: "overline mb-n2 mt-n1" }, [
                  _vm._v("LICENSES")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-left" },
                  _vm._l(_vm.pilot.Licenses, function(l, i) {
                    return _c(
                      "v-chip",
                      { key: "plr_" + i, attrs: { small: "", outlined: "" } },
                      [
                        _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v("cci-rank-" + _vm._s(l.Rank))
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "flavor-text black--text",
                          domProps: {
                            innerHTML: _vm._s(
                              l.License.Source +
                                " " +
                                l.License.Name +
                                "\n          " +
                                "I".repeat(l.rank)
                            )
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "overline mb-n3 mt-n1" }, [_vm._v("TALENTS")]),
      _vm._v(" "),
      _vm._l(_vm.pilot.Talents, function(t, i) {
        return _c(
          "v-row",
          {
            key: "pt_" + i,
            staticClass: "mt-n1 caption",
            staticStyle: { position: "relative", "page-break-inside": "avoid" },
            attrs: { dense: "", justify: "space-between" }
          },
          [
            _c("v-col", [
              _c(
                "fieldset",
                [
                  _c("legend", { staticClass: "heading ml-1 px-2" }, [
                    _vm._v(_vm._s(t.Talent.Name))
                  ]),
                  _vm._v(" "),
                  _vm._l(t.Rank, function(n) {
                    return _c(
                      "v-row",
                      {
                        key: "ptr_" + i + "_" + n,
                        attrs: { align: "center", dense: "", "no-gutters": "" }
                      },
                      [
                        _c(
                          "v-col",
                          { staticClass: "mr-1", attrs: { cols: "auto" } },
                          [_c("v-icon", [_vm._v("cci-rank-" + _vm._s(n))])],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", [
                          _c("span", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: t.Talent.Ranks[n - 1].Description,
                                expression: "t.Talent.Ranks[n - 1].Description"
                              }
                            ]
                          })
                        ])
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ])
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.pilot.CoreBonuses.length
        ? _c("div", { staticClass: "overline mb-n3 mt-n1" }, [
            _vm._v("CORE BONUSES")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.pilot.CoreBonuses, function(b, i) {
        return _c(
          "v-row",
          {
            key: "pb_" + i,
            staticClass: "mt-n1 caption",
            attrs: { dense: "", justify: "space-between" }
          },
          [
            _c("v-col", [
              _c("fieldset", [
                _c("legend", { staticClass: "heading ml-1 px-2" }, [
                  _vm._v(_vm._s(b.Name))
                ]),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "html-safe",
                      rawName: "v-html-safe",
                      value: b.Effect,
                      expression: "b.Effect"
                    }
                  ]
                })
              ])
            ])
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "overline mb-n3 mt-n1" }, [
        _vm._v("PILOT LOADOUT")
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-n1 caption",
          attrs: { dense: "", justify: "space-between" }
        },
        [
          _vm._l(
            _vm.pilot.Loadout.Armor.filter(function(x) {
              return x
            }),
            function(a, i) {
              return _c(
                "v-col",
                {
                  key: "pla_" + i,
                  staticStyle: {
                    position: "relative",
                    "page-break-inside": "avoid"
                  }
                },
                [
                  a
                    ? _c("fieldset", [
                        _c("legend", { staticClass: "heading ml-1 px-2" }, [
                          _vm._v(
                            "\n          " + _vm._s(a.Name) + "\n          "
                          ),
                          _c("span", { staticClass: "overline flavor-text" }, [
                            _vm._v("//ARMOR")
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "pa-1 mt-n2",
                          domProps: {
                            innerHTML: _vm._s(
                              "+" +
                                (a.Armor || 0) +
                                " Armor / E-Def: " +
                                (a.EDefense || "N/A") +
                                " / Evasion: " +
                                (a.Evasion || "N/A") +
                                (a.HPBonus ? " HP Bonus: +" + a.HPBonus : "") +
                                (a.Speed ? " Speed: " + a.Speed : "") +
                                (a.SpeedBonus
                                  ? " Speed Bonus: +" + a.SpeedBonus
                                  : "")
                            )
                          }
                        })
                      ])
                    : _vm._e()
                ]
              )
            }
          ),
          _vm._v(" "),
          _vm._l(
            _vm.pilot.Loadout.Weapons.filter(function(x) {
              return x
            }),
            function(w, i) {
              return _c("v-col", { key: "plw_" + i }, [
                w
                  ? _c("fieldset", [
                      _c("legend", { staticClass: "heading ml-1 px-2" }, [
                        _vm._v(
                          "\n          " + _vm._s(w.Name) + "\n          "
                        ),
                        _c("span", { staticClass: "overline flavor-text" }, [
                          _vm._v("//WEAPON")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "pa-1 mt-n2" },
                        [
                          _vm._l(w.Range, function(r, j) {
                            return _c("b", { key: "plwr_" + i + "_" + j }, [
                              _vm._v(_vm._s(r.Text))
                            ])
                          }),
                          _vm._v("\n          |\n          "),
                          _vm._l(w.Damage, function(d, j) {
                            return _c("b", { key: "plwd_" + i + "_" + j }, [
                              _vm._v(_vm._s(d.Text))
                            ])
                          }),
                          _vm._v(" "),
                          w.Effect
                            ? _c("div", {
                                directives: [
                                  {
                                    name: "html-safe",
                                    rawName: "v-html-safe",
                                    value: w.Effect,
                                    expression: "w.Effect"
                                  }
                                ]
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "text-right" },
                            _vm._l(i.Tags, function(t, j) {
                              return _c(
                                "span",
                                {
                                  key: "plwt_" + i + "_" + j,
                                  staticClass: "mx-1"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(t.Name()) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ])
            }
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-n1 caption",
          attrs: { dense: "", justify: "space-between" }
        },
        _vm._l(
          _vm.pilot.Loadout.Gear.filter(function(x) {
            return x
          }),
          function(g, i) {
            return _c("v-col", { key: "plg_" + i }, [
              g
                ? _c("fieldset", [
                    _c("legend", { staticClass: "heading ml-1 px-2" }, [
                      _vm._v("\n          " + _vm._s(g.Name) + "\n          "),
                      _c("span", { staticClass: "overline flavor-text" }, [
                        _vm._v("//GEAR")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "pa-1 my-n2" }, [
                      g.Description
                        ? _c("div", {
                            directives: [
                              {
                                name: "html-safe",
                                rawName: "v-html-safe",
                                value: g.Description,
                                expression: "g.Description"
                              }
                            ]
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "text-right" },
                        _vm._l(i.Tags, function(t, j) {
                          return _c(
                            "span",
                            { key: "plgt_" + i + "_" + j, staticClass: "mx-1" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(t.Name()) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                : _vm._e()
            ])
          }
        ),
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }