var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-text",
        { staticClass: "my-0 pt-0 pb-1" },
        [
          !_vm.hideLog
            ? _c("v-slide-x-reverse-transition", [
                _vm.finished
                  ? _c(
                      "div",
                      { staticClass: "text-right" },
                      _vm._l(_vm.finalLog, function(s, i) {
                        return _c("v-fade-transition", { key: "log_" + i }, [
                          _vm.timer > 10 * i
                            ? _c(
                                "p",
                                { staticClass: "flavor-text stark--text ma-0" },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n              >//[\n              "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "accent--text" },
                                      [
                                        _vm._v(
                                          "\n                COMP/CON:\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      "\n              ] :\n              "
                                    ),
                                    _c("span", [_vm._v(_vm._s(s))])
                                  ])
                                ]
                              )
                            : _vm._e()
                        ])
                      }),
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("v-slide-x-reverse-transition", [
            _vm.finished
              ? _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "fadeSelect",
                        attrs: { "x-small": "", color: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.undo()
                          }
                        }
                      },
                      [
                        _c(
                          "cc-tooltip",
                          {
                            attrs: {
                              content:
                                "Undo this action, refunding any cost it may have had"
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "", left: "" } }, [
                              _vm._v("mdi-reload")
                            ]),
                            _vm._v("\n            UNDO\n          ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-slide-y-reverse-transition", [
        _vm.finished
          ? _c(
              "div",
              [
                _c("v-divider"),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", tile: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("hide")
                          }
                        }
                      },
                      [_vm._v("DISMISS")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }