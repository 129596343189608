






























































import Vue from 'vue'
import { Auth } from '@aws-amplify/auth'

export default Vue.extend({
  name: 'auth-password-reset',
  data: () => ({
    loading: false,
    sent: false,
    email: null,
    show: false,
    oldpass: null,
    newpass: null,
    resetpass: null,
    code: null,
    rules: {
      passLength: v => (v && v.length >= 6) || 'Minimum 6 characters',
    },
  }),
  methods: {
    reset() {
      this.loading = true
      Auth.forgotPassword(this.email)
        .then(data => {
          console.log(data)
          this.loading = false
          this.sent = true
        })
        .catch(err => {
          console.error(err)
          this.loading = false
          this.sent = false
          this.$notify(`Unable to send reset e-mail: ${err.message}`, 'error')
        })
    },
    setNewPassword() {
      this.loading = true
      Auth.forgotPasswordSubmit(this.email, this.code, this.newpass)
        .then(data => {
          console.log(data)
          this.loading = false
          this.$notify('Password changed successfully. Please log in again')
          this.$emit('set-state', 'sign-in')
        })
        .catch(err => {
          console.log(err)
          this.$notify(`Unable to change password: ${err.message}`, 'error')
          this.loading = false
        })
    },
  },
})
