var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "packsList", staticStyle: { "min-height": "300px" } },
    [
      _c("div", { staticClass: "heading h2 stark--text mt-3 px-2" }, [
        _vm._v("Official Massif LANCER Content")
      ]),
      _vm._v(" "),
      _c("directory-table", {
        attrs: {
          items: _vm.massifPacks,
          loading: _vm.massifLoading,
          "no-author": ""
        }
      }),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-6" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "heading h2 stark--text mt-3 px-2" },
        [
          _vm._v("\n    LANCER Community Content\n    "),
          _c(
            "v-menu",
            {
              attrs: { bottom: "", "open-on-hover": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c("v-icon", _vm._g({ staticClass: "fadeSelect" }, on), [
                        _vm._v("mdi-information-outline")
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c(
                "v-card",
                { attrs: { "max-width": "500px" } },
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n          LANCER Community Content Packs\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", [
                    _vm.communityPacks.length
                      ? _c("span", [
                          _vm._v(
                            "\n            COMP/CON is proud to collborate with the LANCER community in making these unofficial\n            content packs available. They are offered as-is at the discretion of the author.\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n          If you are interested in creating your own homebrew LANCER content, or submitting your\n          content to be featured in this directory, please\n          "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://github.com/massif-press/lancer-data#lancer-community-content-packs",
                          target: "_blank"
                        }
                      },
                      [_vm._v("\n            click here\n          ")]
                    ),
                    _vm._v("\n          .\n        ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("directory-table", {
        attrs: { items: _vm.communityPacks, loading: _vm.communityLoading }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }