



































import Vue from 'vue'
import FileImport from './add_panels/FileImport.vue'
import CloudImport from './add_panels/CloudImport.vue'
import CloudImportOld from './add_panels/CloudImportOld.vue'
import { Auth } from 'aws-amplify'

export default Vue.extend({
  name: 'add-pilot',
  components: { FileImport, CloudImport, CloudImportOld },
  data: () => ({
    currentAuthedUser: null,
  }),
  async mounted() {
    await Auth.currentAuthenticatedUser().then(res => {
      this.currentAuthedUser = res.username
    })
  },
})
