var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          style:
            "max-height: " +
            (_vm.small ? "150px" : "200px") +
            "; min-height: " +
            (_vm.small ? "75px" : "200px"),
          attrs: { "no-gutters": "", align: "center", justify: "center" }
        },
        [
          _c("v-col", [
            _c(
              "div",
              [
                _vm._t("top"),
                _vm._v(" "),
                _vm.item.Tags && _vm.item.Tags.length
                  ? _c(
                      "div",
                      {
                        staticClass: "text-center pb-2 pt-2 my-1",
                        attrs: { cols: "12" }
                      },
                      [
                        _c("cc-tags", {
                          attrs: {
                            tags: _vm.item.Tags,
                            small: "",
                            dense: "",
                            outlined: "",
                            color: "accent"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-toolbar",
        {
          attrs: {
            dense: "",
            color: _vm.hover ? "primary lighten-1" : "primary",
            dark: ""
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "overline mb-n2" },
              [
                _vm.item.Source
                  ? _c("span", [_vm._v(_vm._s(_vm.item.Source))])
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("overline")
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                class: _vm.small ? "font-weight-bold" : "heading h3",
                style:
                  "overflow: hidden; width: " +
                  (_vm.small ? "73vw" : "25vw") +
                  "; text-overflow: ellipsis; white-space: nowrap;"
              },
              [_vm._v("\n        " + _vm._s(_vm.item.Name) + "\n      ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }