var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.pilot.IsDead
        ? _c("pilot-block", { attrs: { pilot: _vm.pilot } })
        : _c("clone-block", { attrs: { "hide-quirks": "", readonly: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }