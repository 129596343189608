var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "open-on-hover": "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-chip",
                _vm._g(
                  {
                    staticClass: "ma-1",
                    attrs: {
                      slot: "activator",
                      label: "",
                      outlined: !_vm.license.missing,
                      dark: _vm.license.missing,
                      color: _vm.license.missing
                        ? "deep-orange darken-4"
                        : "success darken-2"
                    },
                    slot: "activator"
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("cci-rank-" + _vm._s(_vm.license.rank))
                  ]),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.license.source) +
                      " " +
                      _vm._s(_vm.license.name) +
                      " " +
                      _vm._s("I".repeat(_vm.license.rank)) +
                      "\n    "
                  )
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _vm.license.missing
            ? _c(
                "v-card-title",
                {
                  staticClass: "error white--text font-weight-bolder py-1 my-0"
                },
                [_vm._v("\n      WARNING: LICENSE MISSING \n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "pa-2 text--text" }, [
            _c("b", [
              _vm._v(
                _vm._s(_vm.license.name) + " RANK " + _vm._s(_vm.license.rank)
              )
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("i", [_vm._v("Required for: ")]),
            _vm._v("\n      " + _vm._s(_vm.license.items.join(", ")) + "\n    ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }