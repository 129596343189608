var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", { staticClass: "heading" }, [_vm._v("PILOT EQUIPMENT")]),
      _vm._v(" "),
      _c(
        "v-tabs",
        {
          staticStyle: { "min-height": "80vh" },
          attrs: {
            vertical: _vm.$vuetify.breakpoint.mdAndUp,
            "background-color": "primary",
            "slider-size": 12,
            "slider-color": "active"
          }
        },
        [
          _c("v-tab", { attrs: { ripple: "" } }, [_vm._v("ARMOR")]),
          _vm._v(" "),
          _c("v-tab", { attrs: { ripple: "" } }, [_vm._v("WEAPONS")]),
          _vm._v(" "),
          _c("v-tab", { attrs: { ripple: "" } }, [_vm._v("GEAR")]),
          _vm._v(" "),
          _c(
            "v-tab-item",
            { class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-4" : "" },
            [
              _c("compendium-browser", {
                attrs: {
                  "no-filter": "",
                  headers: _vm.armor_headers,
                  items: _vm.armor
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            { class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-4" : "" },
            [
              _c("compendium-browser", {
                attrs: {
                  "no-filter": "",
                  headers: _vm.weapon_headers,
                  items: _vm.weapons
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            { class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-4" : "" },
            [
              _c("compendium-browser", {
                attrs: {
                  "no-filter": "",
                  headers: _vm.gear_headers,
                  items: _vm.gear
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }