var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "cc-stepper-content",
    {
      attrs: {
        complete: _vm.canContinue,
        exit: "/pilot/" + _vm.pilot.ID,
        back: ""
      },
      on: {
        back: function($event) {
          return _vm.$emit("back")
        },
        complete: function($event) {
          return _vm.$emit("next")
        }
      }
    },
    [
      _c("cc-title", { attrs: { large: "" } }, [_vm._v("Improve Talents ")]),
      _vm._v(" "),
      _c(
        "h2",
        { staticClass: "heading" },
        [
          _vm._v("\n    MV-2 License Acquisition Request\n    "),
          _c("cc-slashes"),
          _vm._v("\n     MV-2//b COA Resource Requisition\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "absolute", right: "16px", top: "16px" } },
        [
          _c(
            "cc-tooltip",
            { attrs: { simple: "", content: "Feature In Development" } },
            [
              _c(
                "v-btn",
                { attrs: { small: "", outlined: "", disabled: "" } },
                [_vm._v("Suggest Talents")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "flavor-text", staticStyle: { "font-size": "14px" } },
        [
          _c("span", [
            _vm._v(
              "\n      The Combat Operation Ability Requisition, on the approval of a NHP-guided automated request\n      approval system, confers UNI simulator privileges, holofield and cortical sensorium training\n      materials, battlefield telemetry data at classifications below GREY SIERRA SHARP, and (in\n      specific cases) GMS MM-pattern materiel licenses.\n    "
            )
          ]),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "mt-2",
              attrs: {
                color: "accent",
                outlined: "",
                dense: "",
                border: "left"
              }
            },
            [
              _c("b", [_vm._v("Add or Upgrade a Talent.")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("span", { staticClass: "overline" }, [
                _vm._v(
                  "\n        By submitting this form you acknowledge your responsibility to prevent unauthorized\n        disclosure by ensuring any and all training materials, field report data, and print\n        templates are always stored and transmitted under NDL-DISCORDANT//BREATH or higher\n        NDL-DISCORDANT-class UNI encryption protocols. The reproduction, extraction, and\n        transmission of these materials, in whole or in part, is forbidden without the express\n        permission of Union Naval Intelligence.\n      "
                )
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cc-talent-selector", { attrs: { "level-up": "", pilot: _vm.pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }