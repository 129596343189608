



















































import Vue from 'vue'
import UpdateChecker from './UpdateChecker.vue'

export default Vue.extend({
  name: 'cci-banner',
  components: { UpdateChecker },
})
