
























































































































































































































































import PilotRegistrationCard from './PilotRegistrationCard.vue'
import LevelEditDialog from './LevelEditDialog.vue'
import activePilot from '@/features/pilot_management/mixins/activePilot'

import vueMixins from '@/util/vueMixins'
import { Mech } from '@/class'

export default vueMixins(activePilot).extend({
  name: 'pilot-header',
  components: { LevelEditDialog, PilotRegistrationCard },

  computed: {
    isLevelingUp(): boolean {
      return this.$route.name === 'pilot-level-wizard'
    },
    mech(): Mech {
      if (this.$route.name === 'mech-sheet')
        return this.pilot.Mechs.find((m: Mech) => m.ID === this.$route.params.mechID)
      return null
    },
  },
})
