var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ml-4 my-2" },
    [
      _c(
        "v-row",
        [
          _c("div", { staticClass: "flavor-text" }, [
            _vm._v("\n      COMP/CON OMNINET ARCHIVE ::\n      "),
            _c("b", { staticClass: "stark--text" }, [_vm._v("LANCERS")])
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { align: "center" } },
            _vm._l(_vm.pilotArt, function(a) {
              return _c("v-col", { key: a.img, attrs: { cols: "2" } }, [
                _c(
                  "div",
                  {
                    class:
                      _vm.selected === _vm.imgPath(a.tag, a.img)
                        ? "selected-img"
                        : "unselected-img",
                    on: {
                      click: function($event) {
                        return _vm.selectImg(a)
                      }
                    }
                  },
                  [
                    _c("v-img", {
                      attrs: {
                        src: _vm.imgPath(a.tag, a.img),
                        contain: "",
                        "max-width": "20vw"
                      }
                    })
                  ],
                  1
                )
              ])
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.selected && _vm.artist
        ? _c(
            "v-alert",
            {
              staticClass: "my-2",
              attrs: { outlined: "", dense: "", color: "primary" }
            },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("div", [
                      _c("div", { staticClass: "heading h3 accent--text" }, [
                        _vm._v(_vm._s(_vm.artist.imgName))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "flavor-text ml-3" }, [
                        _vm._v("by " + _vm._s(_vm.artist.name))
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.artist.website
                      ? _c(
                          "a",
                          {
                            staticClass: "ml-3",
                            staticStyle: { "text-decoration": "none" },
                            attrs: {
                              target: "_blank",
                              href: "" + _vm.artist.website
                            }
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("mdi-web")
                            ]),
                            _vm._v(" "),
                            _c("span", [_vm._v("Website")])
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.artist.website && _vm.artist.twitter
                      ? _c("span", { staticClass: "ml-4 mr-2" }, [_vm._v("|")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.artist.twitter
                      ? _c(
                          "a",
                          {
                            key: _vm.artist.twitter,
                            staticClass: "ml-3",
                            staticStyle: { "text-decoration": "none" },
                            attrs: {
                              target: "_blank",
                              href: "https://twitter.com/" + _vm.artist.twitter
                            }
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("mdi-twitter")
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v("@" + _vm._s(_vm.artist.twitter))
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.artist.logo
                    ? _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-img", {
                            staticClass: "logo",
                            attrs: {
                              target: "_blank",
                              href: "artist.website",
                              src: _vm.artist.logo,
                              contain: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }