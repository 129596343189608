



















































import Vue from 'vue'
export default Vue.extend({
  name: 'add-custom-skill',
  data: () => ({
    newSkill: '',
    newDesc: '',
    newDetail: '',
  }),
  methods: {
    addSkill() {
      this.$emit('add-custom', {
        skill: this.newSkill,
        description: this.newDesc,
        detail: this.newDetail,
      })
      this.newSkill = ''
      this.newDesc = ''
      this.newDetail = ''
    },
  },
})
