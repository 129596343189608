var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.state.SelfDestructCounter > 0
        ? _c(
            "v-row",
            {
              staticClass: "text-center",
              attrs: { dense: "", justify: "center" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-alert",
                    {
                      attrs: {
                        dense: "",
                        outlined: "",
                        color: "error",
                        prominent: ""
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-3",
                          attrs: {
                            slot: "prepend",
                            color: "error",
                            size: "90"
                          },
                          slot: "prepend"
                        },
                        [_vm._v("\n          cci-reactor\n        ")]
                      ),
                      _vm._v(" "),
                      _vm.state.SelfDestructCounter > 1
                        ? _c("span", { staticClass: "heading h1 pt-2" }, [
                            _vm._v(
                              "\n          SELF DESTRUCT IN " +
                                _vm._s(_vm.state.SelfDestructCounter) +
                                " ROUNDS\n        "
                            )
                          ])
                        : _c("span", { staticClass: "heading h1" }, [
                            _vm._v("SELF DESTRUCT IMMINENT")
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "heading subtle--text" }, [
                        _vm._v(
                          "\n          FRAME.PRIORITY.ALERT::REACTOR CRITICALITY EVENT\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "px-5 my-1" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", block: "", color: "error" },
                              on: {
                                click: function($event) {
                                  return _vm.selfDestruct()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-skull")
                              ]),
                              _vm._v(
                                "\n            DETONATE REACTOR\n            "
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("mdi-skull")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text-right mt-1" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect",
                          attrs: { "x-small": "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.reset()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "", left: "" } }, [
                            _vm._v("mdi-reload")
                          ]),
                          _vm._v("\n          UNDO\n        ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c("div", { staticClass: "marquee" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s("WARNING // ".repeat(400)) }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "text-center mx-12" }, [
                _c("div", { staticClass: "body-text stark--text" }, [
                  _c("b", [
                    _vm._v(
                      "\n          CONFIRMING THIS ACTION INITIATES A CRITICALITY EXCURSION EVENT THAT\n          "
                    ),
                    _c("u", [_vm._v("WILL")]),
                    _vm._v(
                      "\n          RESULT IN CATASTROPIC REACTOR FAILURE AND EXPLOSIVE MELTDOWN.\n        "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flavor-text" }, [
                  _vm._v(
                    "\n        Occupant survival probability (monte_carlo//cc-n2@latest):\n        "
                  ),
                  _c("b", { staticClass: "error--text" }, [_vm._v("0.000%")])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "marquee" }, [
                _c("span", {
                  domProps: { innerHTML: _vm._s("WARNING // ".repeat(400)) }
                })
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pt-3" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", align: "center" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("action-detail-expander", {
                            attrs: { action: _vm.action }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                large: "",
                                tile: "",
                                block: "",
                                dark: "",
                                disabled: _vm.actionFree,
                                color:
                                  _vm.action.Color +
                                  " " +
                                  (_vm.actionCost ? "lighten-1" : "")
                              },
                              on: {
                                click: function($event) {
                                  _vm.actionCost = _vm.select(_vm.actionCost)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(_vm._s(_vm.action.Icon))
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.action.Name) +
                                  "\n          "
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.action.Activation !== "Free"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    small: "",
                                    tile: "",
                                    block: "",
                                    dark: "",
                                    disabled: _vm.actionCost,
                                    color:
                                      "action--free " +
                                      (_vm.actionFree ? "lighten-1" : "")
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.actionFree = _vm.select(
                                        _vm.actionFree
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { left: "", small: "" } },
                                    [_vm._v("cci-free-action")]
                                  ),
                                  _vm._v(
                                    "\n            Free Action\n            "
                                  ),
                                  _c(
                                    "cc-tooltip",
                                    {
                                      attrs: {
                                        inline: "",
                                        content:
                                          "Special rules or equipment may allow you to " +
                                          _vm.action.Name +
                                          " as a Free Action. Using this button will commit the action without spending a " +
                                          _vm.action.Activation +
                                          " Action this turn"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "fadeSelect",
                                          attrs: { right: "", small: "" }
                                        },
                                        [_vm._v("mdi-information-outline")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-slide-x-reverse-transition",
                [
                  _vm.actionCost || _vm.actionFree
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "flavor-text stark--text ma-0 text-center"
                            },
                            [
                              _c("span", [
                                _vm._v("\n            >//[\n            "),
                                _c("span", { staticClass: "accent--text" }, [
                                  _vm._v(
                                    "\n              COMP/CON:\n            "
                                  )
                                ]),
                                _vm._v("\n            ] :\n            "),
                                _c("span", [_vm._v("MANUAL OVERRIDE REQUIRED")])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { justify: "space-around" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-btn", {
                                    attrs: {
                                      large: "",
                                      tile: "",
                                      block: "",
                                      color: _vm.or1 ? "error" : "primary"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.or1
                                          ? "REACTOR SAFETY PROTOCOLS DISABLED"
                                          : "DISABLE REACTOR SAFETY PROTOCOLS"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.or1 = true
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-btn", {
                                    attrs: {
                                      large: "",
                                      tile: "",
                                      block: "",
                                      disabled: !_vm.or1,
                                      color: _vm.or2 ? "error" : "primary"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.or2
                                          ? "CORE EMERGENCY VENTS LOCKED"
                                          : "LOCK CORE EMERGENCY VENTS"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.or2 = true
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("v-btn", {
                                    attrs: {
                                      large: "",
                                      tile: "",
                                      block: "",
                                      disabled: !_vm.or2,
                                      color: _vm.or3 ? "error" : "primary"
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.or3
                                          ? "COOLANT RESERVOIR EMPTY"
                                          : "DISCHARGE COOLANT RESERVOIR"
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.or3 = true
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "", justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "10" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        "x-large": "",
                                        block: "",
                                        disabled: !_vm.or3,
                                        color: _vm.finished
                                          ? "error"
                                          : "error darken-2"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.start()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            left: "0"
                                          },
                                          attrs: { large: "" }
                                        },
                                        [_vm._v("mdi-alert-rhombus-outline")]
                                      ),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.finished
                                              ? "CRITICAL ALERT: REACTOR MELTDOWN IMMINENT"
                                              : "OVERLOAD REACTOR"
                                          ) +
                                          "\n              "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "0"
                                          },
                                          attrs: { large: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                mdi-alert-rhombus-outline\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-slide-x-reverse-transition",
                [
                  _vm.finished
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "ml-auto", attrs: { cols: "auto" } },
                            [
                              _c(
                                "cc-tooltip",
                                {
                                  attrs: {
                                    content:
                                      "Undo this action, refunding any cost it may have had"
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "fadeSelect",
                                      attrs: {
                                        "x-small": "",
                                        color: "primary"
                                      },
                                      on: { click: _vm.reset }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { small: "", left: "" } },
                                        [_vm._v("mdi-reload")]
                                      ),
                                      _vm._v(
                                        "\n              UNDO\n            "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-slide-y-reverse-transition", [
                _vm.finished
                  ? _c(
                      "div",
                      [
                        _c("v-divider"),
                        _vm._v(" "),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", tile: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("hide")
                                  }
                                }
                              },
                              [_vm._v("DISMISS")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }