var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "packsList", staticStyle: { "min-height": "300px" } },
    [
      _c("v-data-table", {
        attrs: {
          "hide-default-footer": "",
          "disable-pagination": "",
          "no-data-text": "No content packs installed.",
          headers: _vm.headers,
          items: _vm.contentPacks,
          "show-expand": "",
          "item-key": "Key"
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.toggleActive",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("v-switch", {
                    attrs: { "input-value": item.Active, color: "primary" },
                    on: {
                      change: function($event) {
                        return _vm.toggleActive(item.ID, $event)
                      }
                    }
                  })
                ]
              }
            },
            {
              key: "item.name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "span",
                    {
                      staticClass: "title",
                      class: item.Active
                        ? "accent--text"
                        : "subtle--text font-italic"
                    },
                    [_vm._v("\n        " + _vm._s(item.Name) + "\n      ")]
                  )
                ]
              }
            },
            {
              key: "item.version",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("span", { staticClass: "packVersion" }, [
                    _vm._v("\n        " + _vm._s(item.Version) + "\n      ")
                  ])
                ]
              }
            },
            {
              key: "item.deleteAction",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "offset-x": "",
                        top: "",
                        "nudge-left": "30px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "fadeSelect",
                                      attrs: { icon: "", color: "primary" }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        "\n              delete\n            "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "text-center body-text" },
                            [
                              _c("p", [
                                _vm._v(
                                  "\n              This will remove this pack and all of its contents from COMP/CON. User data that\n              relies on this content will be unavailable and may cause errors. Are you sure you\n              want to continue?\n            "
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-divider", { staticClass: "my-2" }),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    { attrs: { small: "", text: "" } },
                                    [_vm._v("CANCEL")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-auto",
                                      attrs: { small: "", color: "error" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deletePack(item.ID)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                CONFIRM\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "expanded-item",
              fn: function(ref) {
                var item = ref.item
                var headers = ref.headers
                return [
                  _c(
                    "td",
                    {
                      staticClass: "py-4 px-6 w-100 light-panel",
                      attrs: { colspan: headers.length }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              { staticClass: "body-text text--text pa-2 mb-1" },
                              [
                                item.Description
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(item.Description) +
                                          "\n              "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                No description given.\n              "
                                      )
                                    ])
                              ]
                            ),
                            _vm._v(" "),
                            item.Website
                              ? _c(
                                  "div",
                                  { staticClass: "mt-2" },
                                  [
                                    _c("v-divider", { staticClass: "ma-1" }),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href: item.Website,
                                          text: "",
                                          color: "secondary"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-1",
                                            attrs: { prepend: "" }
                                          },
                                          [_vm._v("open_in_new")]
                                        ),
                                        _vm._v(
                                          "\n                 Website\n              "
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: item.ImageURL,
                                  alt: "Pack image",
                                  "max-width": "200px",
                                  "max-height": "300px"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }