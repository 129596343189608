var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "auto" } },
    [
      _c(
        "v-card",
        {
          staticClass: "text-center",
          style: _vm.inline
            ? "min-width: 15vw; max-width: 20vw"
            : "min-width: 20vw",
          attrs: {
            flat: "",
            tile: "",
            color: "light-panel",
            height: "100%",
            width: "100%"
          }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "heading h3 primary px-3 py-0 ma-0 white--text" },
            [
              !_vm.inline && _vm.$vuetify.breakpoint.mdAndDown
                ? _c("v-spacer")
                : _vm._e(),
              _vm._v(" "),
              _c(
                "cc-tooltip",
                {
                  attrs: {
                    inline: "",
                    title: _vm.name,
                    content: _vm.glossary(_vm.name)
                  }
                },
                [
                  _c("v-icon", { attrs: { large: "", color: "white" } }, [
                    _vm._v(_vm._s(_vm.icon))
                  ]),
                  _vm._v(" "),
                  _vm.inline || _vm.$vuetify.breakpoint.lgAndUp
                    ? _c("span", [_vm._v(_vm._s(_vm.name))])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.inline
                ? _c("span", { staticClass: "pl-2" }, [
                    _vm._v(_vm._s(_vm.value))
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          !_vm.inline
            ? _c(
                "v-card-text",
                {
                  staticClass: "heading stark--text py-3",
                  staticStyle: { "font-size": "32px" }
                },
                [_c("span", [_vm._v(_vm._s(_vm.value))])]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }