var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-col",
            [_c("action-detail-expander", { attrs: { action: _vm.action } })],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    tile: "",
                    dark: "",
                    block: "",
                    disabled: _vm.used || _vm.actionFree,
                    color:
                      _vm.action.Color +
                      " " +
                      (_vm.actionCost ? "lighten-1" : "")
                  },
                  on: {
                    click: function($event) {
                      _vm.actionCost = !_vm.actionCost
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(_vm._s(_vm.action.Icon))
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.action.Name) + "\n      ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    tile: "",
                    dark: "",
                    block: "",
                    disabled: _vm.actionCost,
                    color: "action--free " + (_vm.actionFree ? "lighten-1" : "")
                  },
                  on: {
                    click: function($event) {
                      _vm.actionFree = !_vm.actionFree
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "", small: "" } }, [
                    _vm._v("cci-free-action")
                  ]),
                  _vm._v("\n        Free Action\n        "),
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        inline: "",
                        content:
                          "Special rules or equipment may allow you to " +
                          _vm.action.Name +
                          " as a Free Action. Using this button will commit the action without spending a " +
                          _vm.action.Activation +
                          " Action this turn"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "fadeSelect",
                          attrs: { right: "", small: "" }
                        },
                        [_vm._v("mdi-information-outline")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-x-reverse-transition",
        [
          _vm.actionFree || _vm.actionCost
            ? _c(
                "v-row",
                { attrs: { justify: "center", align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-n5", attrs: { lg: "auto", md: "12" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-center mb-n3",
                          attrs: { dense: "", justify: "start", align: "start" }
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "mx-8", attrs: { cols: "auto" } },
                            [
                              _c("div", { staticClass: "overline mb-n2" }, [
                                _vm._v("Attack Roll")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "heading text--text",
                                  staticStyle: { "font-size": "24pt" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-n1",
                                      attrs: { "x-large": "" }
                                    },
                                    [_vm._v("mdi-dice-d20-outline")]
                                  ),
                                  _vm._v(
                                    "\n              + " +
                                      _vm._s(_vm.mech.AttackBonus) +
                                      "\n            "
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "mx-8", attrs: { cols: "auto" } },
                            [
                              _c("div", { staticClass: "overline mb-n3" }, [
                                _vm._v("vs. Target")
                              ]),
                              _vm._v(" "),
                              _c("v-icon", {
                                attrs: { "x-large": "" },
                                domProps: { innerHTML: _vm._s("cci-evasion") }
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "overline font-weight-bold mt-n2",
                                domProps: { innerHTML: _vm._s("Evasion") }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "ml-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "", justify: "end" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "ml-auto px-12 mr-n10 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", { staticClass: "overline pl-1" }, [
                                _vm._v("Accuracy")
                              ]),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "hide-input-spinners",
                                staticStyle: { width: "115px" },
                                attrs: {
                                  type: "number",
                                  "append-outer-icon":
                                    "mdi-plus-circle-outline",
                                  "append-icon": "cci-accuracy",
                                  "prepend-icon": "mdi-minus-circle-outline",
                                  color: "accent",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  "click:append-outer": function($event) {
                                    _vm.accuracy += 1
                                  },
                                  "click:prepend": function($event) {
                                    _vm.accuracy -= 1
                                  },
                                  change: function($event) {
                                    _vm.accuracy = parseInt($event)
                                  }
                                },
                                model: {
                                  value: _vm.accuracy,
                                  callback: function($$v) {
                                    _vm.accuracy = $$v
                                  },
                                  expression: "accuracy"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-12 mr-n10 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", { staticClass: "overline pl-1" }, [
                                _vm._v("Difficulty")
                              ]),
                              _vm._v(" "),
                              _c("v-text-field", {
                                staticClass: "hide-input-spinners",
                                staticStyle: { width: "115px" },
                                attrs: {
                                  type: "number",
                                  "append-outer-icon":
                                    "mdi-plus-circle-outline",
                                  "append-icon": "cci-difficulty",
                                  "prepend-icon": "mdi-minus-circle-outline",
                                  color: "accent",
                                  dense: "",
                                  "hide-details": ""
                                },
                                on: {
                                  "click:append-outer": function($event) {
                                    _vm.difficulty += 1
                                  },
                                  "click:prepend": function($event) {
                                    _vm.difficulty -= 1
                                  },
                                  change: function($event) {
                                    _vm.difficulty = parseInt($event)
                                  }
                                },
                                model: {
                                  value: _vm.difficulty,
                                  callback: function($$v) {
                                    _vm.difficulty = $$v
                                  },
                                  expression: "difficulty"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "px-12 panel dual-sliced",
                              staticStyle: { height: "70px" },
                              attrs: { cols: "auto" }
                            },
                            [
                              _c("div", { staticClass: "overline pl-1" }, [
                                _vm._v("Melee Attack Roll")
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "mr-n2 ml-n2" },
                                    [
                                      _c("cc-dice-menu", {
                                        attrs: {
                                          preset:
                                            "1d20+" + _vm.mech.AttackBonus,
                                          "preset-accuracy":
                                            _vm.accuracy - _vm.difficulty,
                                          title: "SKILL CHECK"
                                        },
                                        on: {
                                          commit: function($event) {
                                            return _vm.registerAttackRoll(
                                              $event.total
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-text-field", {
                                        key: "input_" + _vm.attackRoll,
                                        staticClass:
                                          "hide-input-spinners ml-n3",
                                        staticStyle: {
                                          "max-width": "60px",
                                          "margin-top": "-0.5px"
                                        },
                                        attrs: {
                                          type: "number",
                                          color: "accent",
                                          dense: "",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.attackRoll,
                                          callback: function($$v) {
                                            _vm.attackRoll = $$v
                                          },
                                          expression: "attackRoll"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-x-reverse-transition",
        [
          _vm.attackRoll
            ? _c(
                "v-row",
                { staticClass: "mt-n2", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto",
                      attrs: { md: "6", lg: "3", xl: "2" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: {
                            tile: "",
                            block: "",
                            color:
                              "primary " + (_vm.succeeded ? "lighten-1" : ""),
                            disabled: _vm.failed
                          },
                          on: {
                            click: function($event) {
                              _vm.succeeded = _vm.select(_vm.succeeded)
                            }
                          }
                        },
                        [_vm._v("\n          SUCCESS\n        ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { md: "6", lg: "3", xl: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            tile: "",
                            block: "",
                            disabled: _vm.succeeded,
                            color: _vm.failed ? "error" : ""
                          },
                          on: {
                            click: function($event) {
                              _vm.failed = _vm.select(_vm.failed)
                            }
                          }
                        },
                        [_vm._v("\n          FAILURE\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-slide-x-reverse-transition",
        [
          _vm.succeeded
            ? _c(
                "v-row",
                { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto",
                      attrs: { cols: "auto", align: "end" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "body-text stark--text font-weight-bold"
                        },
                        [
                          _vm._v(
                            "\n          Your target is knocked PRONE and you may also choose to knock them back by one space,\n          directly away from you\n        "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }