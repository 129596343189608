var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "start", dense: "", justify: "space-between" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "cc-tick-bar",
                {
                  key: _vm.mech.CurrentMove,
                  attrs: {
                    current: _vm.mech.CurrentMove,
                    max: _vm.mech.MaxMove,
                    large: "",
                    color: "action--move",
                    "full-icon": "mdi-arrow-right-bold-hexagon-outline",
                    "number-only": _vm.$vuetify.breakpoint.mdAndDown,
                    clearable: ""
                  },
                  on: {
                    update: function($event) {
                      return _vm.state.SetMove($event)
                    }
                  }
                },
                [
                  _c("span", { staticClass: "heading h3" }, [
                    _vm._v("\n          Movement\n        ")
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-fade-transition",
                [
                  _vm.mech.Boost
                    ? _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.Boost,
                          attrs: {
                            current: _vm.mech.Boost,
                            max: _vm.mech.MaxMove,
                            large: "",
                            color: "secondary",
                            "full-icon": "mdi-arrow-right-bold-hexagon-outline",
                            "number-only": _vm.$vuetify.breakpoint.mdAndDown,
                            clearable: "",
                            "no-input": ""
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetBoost($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("\n            Boost\n          ")
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto", "align-self": "end" } },
            [
              _c("cc-synergy-display", {
                staticClass: "d-inline",
                attrs: { large: "", location: "move", mech: _vm.mech }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "ml-auto text-center",
              attrs: { cols: "auto", "align-self": "center" }
            },
            [
              _c(
                "fieldset",
                {
                  staticClass: "ma-0 py-0 px-3",
                  staticStyle: { height: "100%" }
                },
                [
                  _c(
                    "legend",
                    {
                      staticClass: "overline px-1 mb-n1",
                      staticStyle: { "line-height": "0" }
                    },
                    [_vm._v("\n          Active Effects\n        ")]
                  ),
                  _vm._v(" "),
                  _c("cc-synergy-display", {
                    attrs: {
                      large: "",
                      location: "active_effects",
                      mech: _vm.mech,
                      "show-none": ""
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto", "align-self": "center" } },
            [_vm._t("repair")],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-n3", attrs: { align: "start" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: { cols: "auto", "align-self": "end" }
                    },
                    [
                      _c("cc-synergy-display", {
                        staticClass: "d-inline",
                        attrs: {
                          large: "",
                          location: "structure",
                          mech: _vm.mech
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentStructure,
                          class: { rolledOver: _vm.structRollover },
                          attrs: {
                            current: _vm.mech.CurrentStructure,
                            max: _vm.mech.MaxStructure,
                            large: "",
                            color: "structure",
                            "full-icon": "cci-structure"
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetStructure($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("\n              Structure\n            ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "justify-space-around", attrs: { dense: "" } },
                [
                  _vm.mech.Armor
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "ma-0 pa-0",
                              attrs: { cols: "auto", "align-self": "end" }
                            },
                            [
                              _c("cc-synergy-display", {
                                staticClass: "d-inline",
                                attrs: {
                                  large: "",
                                  location: "armor",
                                  mech: _vm.mech
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "cc-tick-bar",
                            {
                              key: _vm.mech.Armor,
                              attrs: {
                                current: _vm.mech.Armor,
                                max: _vm.mech.Armor,
                                large: "",
                                color: "armor",
                                "full-icon": "mdi-shield",
                                "number-only": "",
                                "hide-values": "",
                                readonly: "",
                                justify: "center"
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v("Armor: " + _vm._s(_vm.mech.Armor))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: { cols: "auto", "align-self": "end" }
                    },
                    [
                      _c("cc-synergy-display", {
                        staticClass: "d-inline",
                        attrs: { large: "", location: "hp", mech: _vm.mech }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentHP,
                          attrs: {
                            current: _vm.mech.CurrentHP,
                            max: _vm.mech.MaxHP,
                            large: "",
                            color: "hp",
                            "full-icon": _vm.hpResistance
                              ? "mdi-octagram"
                              : "mdi-hexagon",
                            "max-length": _vm.$vuetify.breakpoint.xl ? 35 : 20,
                            "number-only": _vm.$vuetify.breakpoint.mdAndDown,
                            justify: "start"
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetHp($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("HP")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: { cols: "auto", "align-self": "end" }
                        },
                        [
                          _c("cc-synergy-display", {
                            staticClass: "d-inline",
                            attrs: {
                              large: "",
                              location: "overshield",
                              mech: _vm.mech
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.Overshield,
                          attrs: {
                            current: _vm.mech.Overshield,
                            max: _vm.mech.Overshield,
                            large: "",
                            color: "stark",
                            "full-icon": "mdi-octagram",
                            justify: "center",
                            "max-length": "6",
                            "hide-max": "",
                            clearable: ""
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetOvershield($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("OVERSHIELD: " + _vm._s(_vm.mech.Overshield))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-n3",
          attrs: { align: "start", justify: "space-between" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: { cols: "auto", "align-self": "end" }
                    },
                    [
                      _c("cc-synergy-display", {
                        staticClass: "d-inline",
                        attrs: { large: "", location: "stress", mech: _vm.mech }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentStress,
                          class: { rolledOver: _vm.stressRollover },
                          attrs: {
                            current: _vm.mech.CurrentStress,
                            max: _vm.mech.MaxStress,
                            large: "",
                            color: "stress",
                            "full-icon": "cci-reactor"
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetStress($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("Stress")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "justify-space-around", attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: { cols: "auto", "align-self": "end" }
                        },
                        [
                          _c("cc-synergy-display", {
                            staticClass: "d-inline",
                            attrs: {
                              large: "",
                              location: "heat",
                              mech: _vm.mech
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentHeat,
                          attrs: {
                            current: _vm.mech.CurrentHeat,
                            max: _vm.mech.HeatCapacity,
                            large: "",
                            color: _vm.mech.IsInDangerZone
                              ? "dangerzone"
                              : "heatcap",
                            "full-icon": _vm.mech.IsInDangerZone
                              ? "mdi-fire"
                              : "mdi-circle",
                            "number-only": _vm.$vuetify.breakpoint.mdAndDown,
                            clearable: ""
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetHeat($event)
                            }
                          }
                        },
                        [
                          _vm.mech.IsInDangerZone
                            ? _c(
                                "span",
                                { staticClass: "dangerzone--text heading h3" },
                                [_vm._v("\n              HEAT\n            ")]
                              )
                            : _c("span", { staticClass: "heading h3" }, [
                                _vm._v("\n              HEAT\n            ")
                              ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.mech.IsInDangerZone
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "caption font-weight-bold dangerzone--text text-center"
                            },
                            [
                              _vm._v(
                                "\n            // HEAT::DANGER ZONE //\n          "
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "caption subtle--text text-center" },
                            [
                              _vm._v(
                                "\n            HEAT LEVELS NOMINAL\n          "
                              )
                            ]
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: { cols: "auto", "align-self": "end" }
                        },
                        [
                          _c("cc-synergy-display", {
                            staticClass: "d-inline",
                            attrs: {
                              large: "",
                              location: "repairs",
                              mech: _vm.mech
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentRepairs,
                          attrs: {
                            current: _vm.mech.CurrentRepairs,
                            max: _vm.mech.RepairCapacity,
                            large: "",
                            color: "repcap",
                            "number-only": _vm.$vuetify.breakpoint.mdAndDown,
                            "full-icon": "cci-repair"
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetRepCap($event)
                            }
                          }
                        },
                        [
                          _vm.$vuetify.breakpoint.mdAndDown
                            ? _c("span", { staticClass: "heading h3" }, [
                                _vm._v("\n              REPAIR\n            ")
                              ])
                            : _c("span", { staticClass: "heading h3" }, [
                                _vm._v(
                                  "\n              REPAIR CAPACITY\n            "
                                )
                              ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: { cols: "auto", "align-self": "end" }
                        },
                        [
                          _c("cc-synergy-display", {
                            staticClass: "d-inline",
                            attrs: {
                              large: "",
                              location: "overcharge",
                              mech: _vm.mech
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentOvercharge,
                          attrs: {
                            current: _vm.mech.CurrentOvercharge,
                            max: 3,
                            large: "",
                            "no-input": "",
                            clearable: "",
                            color: "overcharge",
                            "full-icon": "mdi-alert-decagram",
                            justify: "center",
                            "hide-values": ""
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetOvercharge($event)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "heading h3" }, [
                            _vm._v("\n              Overcharge\n            ")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-center caption overcharge--text font-weight-bold"
                        },
                        [
                          _vm._v(
                            "\n            +" +
                              _vm._s(
                                _vm.mech.OverchargeTrack[
                                  _vm.mech.CurrentOvercharge
                                ]
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0",
                          attrs: { cols: "auto", "align-self": "end" }
                        },
                        [
                          _c("cc-synergy-display", {
                            staticClass: "d-inline",
                            attrs: {
                              large: "",
                              location: "core_energy",
                              mech: _vm.mech
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "cc-tick-bar",
                        {
                          key: _vm.mech.CurrentCoreEnergy,
                          attrs: {
                            current: _vm.mech.CurrentCoreEnergy,
                            max: 1,
                            large: "",
                            "no-input": "",
                            clearable: "",
                            color: "corepower",
                            justify: "center",
                            "empty-icon": "mdi-battery-10",
                            "full-icon": "mdi-battery",
                            "hide-values": "",
                            "hide-buttons": ""
                          },
                          on: {
                            update: function($event) {
                              return _vm.state.SetCorePower($event)
                            }
                          }
                        },
                        [
                          _vm.$vuetify.breakpoint.mdAndDown
                            ? _c("span", { staticClass: "heading h3" }, [
                                _vm._v("\n              CP\n            ")
                              ])
                            : _c("span", { staticClass: "heading h3" }, [
                                _vm._v(
                                  "\n              CORE POWER\n            "
                                )
                              ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.mech.CurrentCoreEnergy
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "text-center caption font-weight-bold corepower--text"
                            },
                            [_vm._v("\n            AVAILABLE\n          ")]
                          )
                        : _c(
                            "div",
                            { staticClass: "text-center caption subtle--text" },
                            [_vm._v("\n            EXHAUSTED\n          ")]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }