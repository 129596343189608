var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-bottom-sheet",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              return [
                _vm.flat
                  ? _c(
                      "v-btn",
                      _vm._g({ attrs: { text: "" } }, on),
                      [_vm._t("button")],
                      2
                    )
                  : _c("cc-btn", _vm._g({}, on), [_vm._t("button")], 2)
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm._v(" "),
      _c(
        "v-sheet",
        [
          _c(
            "cc-titlebar",
            {
              scopedSlots: _vm._u([
                {
                  key: "items",
                  fn: function() {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", dark: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { "x-large": "" } }, [
                            _vm._v("close")
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [_vm._t("title")],
            2
          ),
          _vm._v(" "),
          _c("v-card-text", [_vm._t("default")], 2)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }