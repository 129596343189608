


































































































































































import MechSelectButton from '../../components/MechSelectButton.vue'
import activePilot from '@/features/pilot_management/mixins/activePilot'
import vueMixins from '@/util/vueMixins'
import DowntimeMenuButton from '../../components/DowntimeMenuButton.vue'
import DowntimeMenu from '../../components/DowntimeMenu.vue'

export default vueMixins(activePilot).extend({
  name: 'narrative-footer',
  components: {
    DowntimeMenuButton,
    MechSelectButton,
    DowntimeMenu,
  },
  data: () => ({ scDialog: false }),
  computed: {
    startDisabled() {
      const m = this.pilot.ActiveMech
      return m.Destroyed || m.ReactorDestroyed
    },
  },
  methods: {
    openDialog(action) {
      const r = this.$refs[`dialog_${action.ID}`]
      if (r && r[0]) r[0].show()
    },
  },
})
