var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-col",
            [_c("action-detail-expander", { attrs: { action: _vm.action } })],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-center heading h3 pb-0 mt-4 mb-2" }, [
        _vm._v("\n    Overcharging will incur\n    "),
        _c("span", { staticClass: "red--text text--darken-2" }, [
          _vm._v(
            "\n      +" +
              _vm._s(_vm.mech.OverchargeTrack[_vm.mech.CurrentOvercharge]) +
              " Heat\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "cc-tooltip",
                { attrs: { content: "Roll Overcharge" } },
                [
                  _c("cc-dice-menu", {
                    attrs: {
                      preset:
                        _vm.mech.OverchargeTrack[_vm.mech.CurrentOvercharge],
                      title: "OVERCHARGE"
                    },
                    on: {
                      commit: function($event) {
                        return _vm.registerOverchargeRoll($event.total)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                staticClass: "hide-spinners",
                attrs: {
                  type: "number",
                  label: "Heat Roll Result",
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  disabled: _vm.used,
                  "append-outer-icon": "mdi-plus-circle-outline",
                  "prepend-icon": "mdi-minus-circle-outline"
                },
                on: {
                  "click:append-outer": function($event) {
                    _vm.overcharge_heat++
                  },
                  "click:prepend": function($event) {
                    _vm.overcharge_heat > 0 ? _vm.overcharge_heat-- : ""
                  }
                },
                model: {
                  value: _vm.overcharge_heat,
                  callback: function($$v) {
                    _vm.overcharge_heat = $$v
                  },
                  expression: "overcharge_heat"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    dark: "",
                    color: "overcharge",
                    block: "",
                    disabled: _vm.used || !_vm.overcharge_heat
                  },
                  on: {
                    click: function($event) {
                      return _vm.select()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("cci-overcharge")
                  ]),
                  _vm._v("\n        Confirm\n      ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }