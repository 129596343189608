var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: _vm.unlocked ? "" : "opacity: 0.6" }, [
    _c("div", {
      directives: [
        {
          name: "html-safe",
          rawName: "v-html-safe",
          value: _vm.talentRank.Description,
          expression: "talentRank.Description"
        }
      ],
      staticClass: "text--text"
    }),
    _vm._v(" "),
    _vm.talentRank.Actions.length
      ? _c(
          "div",
          [
            _c(
              "v-row",
              { attrs: { dense: "", justify: "center" } },
              _vm._l(_vm.talentRank.Actions, function(a, i) {
                return _c(
                  "v-col",
                  {
                    key: _vm.talentRank.Name + "_action_" + i,
                    attrs: { cols: "auto" }
                  },
                  [
                    _c("cc-action", {
                      staticClass: "ma-2",
                      attrs: { action: a, hover: "" }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.talentRank.Deployables.length
      ? _c(
          "div",
          [
            _c(
              "v-row",
              { attrs: { dense: "", justify: "center" } },
              _vm._l(_vm.talentRank.Deployables, function(d, i) {
                return _c(
                  "v-col",
                  {
                    key: _vm.talentRank.Name + "_deployable_" + i,
                    attrs: { cols: "auto" }
                  },
                  [
                    _c("cc-deployable-info", {
                      staticClass: "ma-2",
                      attrs: {
                        deployable: d,
                        hover: "",
                        "name-override": _vm.talentRank.Name
                      }
                    })
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.talentRank.IntegratedEquipment.length
      ? _c(
          "div",
          [
            _c(
              "v-row",
              { attrs: { dense: "", justify: "center" } },
              _vm._l(_vm.talentRank.IntegratedEquipment, function(x, i) {
                return _c(
                  "v-col",
                  {
                    key: _vm.talentRank.Name + "_integrated_" + i,
                    attrs: { cols: "auto" }
                  },
                  [_c("cc-integrated-info", { attrs: { item: x, hover: "" } })],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.talentRank.SpecialEquipment.length
      ? _c(
          "div",
          [
            _c(
              "v-row",
              { attrs: { dense: "", justify: "center" } },
              _vm._l(_vm.talentRank.SpecialEquipment, function(x, i) {
                return _c(
                  "v-col",
                  {
                    key: _vm.talentRank.Name + "_special_" + i,
                    attrs: { cols: "auto" }
                  },
                  [_c("cc-integrated-info", { attrs: { item: x, hover: "" } })],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }