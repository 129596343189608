var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _vm.big
                ? _c(
                    "v-btn",
                    _vm._g(
                      { attrs: { large: "", block: "", color: "primary" } },
                      on
                    ),
                    [_vm._v("Change Mech")]
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "fadeSelect",
                        attrs: { outlined: "", small: "" }
                      },
                      on
                    ),
                    [_vm._v("Change Mech")]
                  )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { "two-line": "", subheader: "" } },
        [
          _c(
            "v-subheader",
            { staticClass: "heading h2 white--text primary py-0 px-2" },
            [_vm._v("\n      Available Mechs\n    ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.mechs, function(mech) {
            return _c(
              "v-list-item",
              {
                key: "mech-select-" + mech.ID,
                on: {
                  click: function($event) {
                    return _vm.$emit("select", mech)
                  }
                }
              },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "ma-0 mr-2 mt-3" },
                  [
                    _c("cc-logo", {
                      attrs: { size: "large", source: mech.Frame.Manufacturer }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _c(
                        "span",
                        {
                          class: mech.Destroyed
                            ? "text-decoration-line-through text--disabled"
                            : ""
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(mech.Name) +
                              "\n          "
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("v-list-item-subtitle", [
                      _vm._v(
                        _vm._s(mech.Frame.Source) +
                          " " +
                          _vm._s(mech.Frame.Name)
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }