var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "50vw" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "" } },
        [
          _c(
            "cc-titlebar",
            {
              attrs: { large: "", icon: "cci-role-striker", color: "primary" }
            },
            [
              _vm._v("\n      Select Bracing Mount\n      "),
              _c(
                "v-btn",
                {
                  attrs: { slot: "items", dark: "", icon: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  },
                  slot: "items"
                },
                [
                  _c("v-icon", { attrs: { large: "", left: "" } }, [
                    _vm._v("close")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "text-center flavor-text mt-2" },
            [
              _c("span", { staticClass: "overline" }, [
                _vm._v("// PROCESS INTERRUPT: PILOT INPUT REQUIRED //")
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v("\n      //[COMP/CON:\n      "),
              _c("b", { staticClass: "stark--text" }, [
                _vm._v(
                  "\n        Lancer, Superheavy-class armament requires two mounts. Please select a\n        "
                ),
                _c("span", { staticClass: "accent--text" }, [
                  _vm._v("bracing mount.")
                ]),
                _vm._v(
                  "\n        This bracing mount will be not be able to field armament until the Superheavy weapon is\n        removed.\n      "
                )
              ]),
              _vm._v("\n      ]\n      "),
              _c("br"),
              _vm._v(" "),
              _vm._l(_vm.availableMounts, function(m) {
                return _c(
                  "v-btn",
                  {
                    key: m.Name,
                    staticClass: "my-2",
                    attrs: {
                      "x-large": "",
                      block: "",
                      outlined: "",
                      color: "accent"
                    },
                    on: {
                      click: function($event) {
                        return _vm.$emit("select", m)
                      }
                    }
                  },
                  [_vm._v("\n        " + _vm._s(m.Name) + "\n      ")]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("dismiss")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }