



















































import Vue from 'vue'
import { Pilot } from '@/class'
import { importData } from '@/io/Data'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { IPilotData } from '@/interface'
import ImportDialog from './ImportDialog.vue'

export default Vue.extend({
  name: 'file-import',
  components: { ImportDialog },
  data: () => ({
    dialog: false,
    // fileValue is just used to clear the file input
    fileValue: null,
    importPilot: null,
    error: null,
    missingContentWarning: false,
    missingContent: '',
  }),
  watch: {
    dialog(dialogOpen) {
      if (!dialogOpen) this.reset()
    },
  },
  methods: {
    reset() {
      this.fileValue = null
      this.importPilot = null
      this.error = null
      this.missingContentWarning = false
    },
    async importFile(file) {
      this.reset()
      if (!file) return
      try {
        const pilotData = await importData<IPilotData>(file)
        if (!pilotData.brews) pilotData.brews = []
        const installedPacks = getModule(CompendiumStore, this.$store).ContentPacks.map(
          x => `${x.Name} @ ${x.Version}`
        )
        const missingPacks = this.$_.pullAll(pilotData.brews, installedPacks)
        if (missingPacks.length) {
          this.missingContent = missingPacks.join('<br />')
          this.missingContentWarning = true
        }
        this.importPilot = Pilot.Deserialize(pilotData)
        this.importPilot.brews = pilotData.brews
        this.importPilot.RenewID()
      } catch (e) {
        this.error = e.message
        return
      }
    },
    stageImport() {
      const installedPacks = getModule(CompendiumStore, this.$store).ContentPacks.map(x => x.Name)
      const missingPacks = this.$_.without(this.importPilot.brews, installedPacks)
      if (!missingPacks.length) this.confirmImport()
      else {
        this.missingContent = missingPacks.join('<br />')
        this.missingContentWarning = true
      }
    },
    confirmImport() {
      this.importPilot.RenewID()
      this.$store.dispatch('addPilot', { pilot: this.importPilot, update: true })
      this.reset()
      this.dialog = false
      this.$emit('done')
    },
    cancelImport() {
      this.reset()
      this.dialog = false
    },
  },
})
