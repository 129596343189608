var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("cc-selector-table", {
        attrs: {
          items: _vm.availableItems,
          headers: _vm.headers,
          "no-filter": "",
          "sp-ignore": "",
          "equipment-add": ""
        },
        on: {
          equip: function($event) {
            return _vm.$emit("select", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }