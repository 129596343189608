var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c("v-col", { attrs: { cols: _vm.flip ? "" : 1 } }, [
        _c("hr", { staticClass: "mt-3" })
      ]),
      _vm._v(" "),
      _c("v-col", { staticClass: "mt-n2", attrs: { cols: "auto" } }, [
        _c("span", { class: "heading h2 " + _vm.color + "--text" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      ]),
      _vm._v(" "),
      _c("v-col", { attrs: { cols: _vm.flip ? 1 : "" } }, [
        _c("hr", { staticClass: "mt-3" })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }