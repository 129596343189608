
















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'cc-rating' })
export default class CCRating extends Vue {
  @Prop({ type: Number, required: true })
  readonly model!: number
  @Prop({ type: Number, required: false, default: 6 })
  readonly max: number

  @Prop({ type: Boolean, required: false })
  readonly dense?: boolean
  @Prop({ type: String, required: false, default: 'accent' })
  readonly color: string
}

export { CCRating as CCRanking }
