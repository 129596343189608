














import PilotHeader from './components/PilotHeader.vue'
import activePilot from '@/features/pilot_management/mixins/activePilot'

import vueMixins from '@/util/vueMixins'

export default vueMixins(activePilot).extend({
  name: 'pilot-sheet',
  components: { PilotHeader },
  props: {
    pilotID: {
      type: String,
      required: true,
    },
  },
})
