var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "", align: "top" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("div", { staticClass: "overline my-n2 grey--text" }, [
              _vm._v(
                _vm._s(_vm.mech.Frame.Source) +
                  " " +
                  _vm._s(_vm.mech.Frame.Name)
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "heading h2 mt-n4 font-weight-bolder" }, [
              _vm._v(_vm._s(_vm.mech.Name))
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "ml-auto text-center caption",
              attrs: { cols: "auto" }
            },
            [
              _c("div", { staticClass: "overline mt-n2" }, [
                _vm._v("OVERCHARGE")
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mx-1 oc-border" },
                [
                  _vm._v("\n         +1\n        "),
                  _c("v-icon", { staticClass: "ml-n1", attrs: { small: "" } }, [
                    _vm._v("mdi-fire")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mx-1 oc-border" },
                [
                  _vm._v("\n         +1d3\n        "),
                  _c("v-icon", { staticClass: "ml-n1", attrs: { small: "" } }, [
                    _vm._v("mdi-fire")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mx-1 oc-border" },
                [
                  _vm._v("\n         +1d6\n        "),
                  _c("v-icon", { staticClass: "ml-n1", attrs: { small: "" } }, [
                    _vm._v("mdi-fire")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mx-1 oc-border" },
                [
                  _vm._v("\n         +1d6+4\n        "),
                  _c("v-icon", { staticClass: "ml-n1", attrs: { small: "" } }, [
                    _vm._v("mdi-fire")
                  ])
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-n5 mb-1",
          attrs: { dense: "", align: "center", justify: "space-around" }
        },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("span", { staticClass: "font-weight-bold overline pr-3" }, [
              _vm._v("HULL")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-4 mt-n3",
                staticStyle: { position: "relative", width: "max-content" }
              },
              [
                _c(
                  "v-icon",
                  {
                    staticStyle: { "margin-right": "-3px !important" },
                    attrs: { "x-large": "" }
                  },
                  [_vm._v("mdi-hexagon-outline")]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "heading h2 icon-overlap",
                  domProps: { innerHTML: _vm._s(_vm.mech.Hull) }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("span", { staticClass: "font-weight-bold overline pr-3" }, [
              _vm._v("AGI")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-4 mt-n3",
                staticStyle: { position: "relative", width: "max-content" }
              },
              [
                _c(
                  "v-icon",
                  {
                    staticStyle: { "margin-right": "-3px !important" },
                    attrs: { "x-large": "" }
                  },
                  [_vm._v("mdi-hexagon-outline")]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "heading h2 icon-overlap",
                  domProps: { innerHTML: _vm._s(_vm.mech.Agi) }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("span", { staticClass: "font-weight-bold overline pr-3" }, [
              _vm._v("SYS")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-4 mt-n3",
                staticStyle: { position: "relative", width: "max-content" }
              },
              [
                _c(
                  "v-icon",
                  {
                    staticStyle: { "margin-right": "-3px !important" },
                    attrs: { "x-large": "" }
                  },
                  [_vm._v("mdi-hexagon-outline")]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "heading h2 icon-overlap",
                  domProps: { innerHTML: _vm._s(_vm.mech.Sys) }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("span", { staticClass: "font-weight-bold overline pr-3" }, [
              _vm._v("ENG")
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ml-4 mt-n3",
                staticStyle: { position: "relative", width: "max-content" }
              },
              [
                _c(
                  "v-icon",
                  {
                    staticStyle: { "margin-right": "-3px !important" },
                    attrs: { "x-large": "" }
                  },
                  [_vm._v("mdi-hexagon-outline")]
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "heading h2 icon-overlap",
                  domProps: { innerHTML: _vm._s(_vm.mech.Eng) }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "mt-4 ml-4", attrs: { cols: "auto" } },
            [
              _c("v-icon", { attrs: { size: "40" } }, [
                _vm._v(_vm._s(_vm.mech.SizeIcon))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", [_c("v-divider", { attrs: { vertical: "" } })], 1),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-center" },
            [
              _c(
                "div",
                {
                  staticClass: "overline",
                  staticStyle: { "line-height": "30px" }
                },
                [_vm._v("CORE POWER")]
              ),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-n1 mt-n3",
                  attrs: { size: "30", color: "grey lighten-2" }
                },
                [_vm._v("mdi-battery-outline")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-inline-block flavor-text font-weight-bold mb-n2"
                },
                [_vm._v("/1")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-center" },
            [
              _c(
                "div",
                {
                  staticClass: "overline",
                  staticStyle: { "line-height": "30px" }
                },
                [_vm._v("REPAIR CAPACITY")]
              ),
              _vm._v(" "),
              _c(
                "v-icon",
                {
                  staticClass: "mr-n1 mt-n3",
                  attrs: { size: "30", color: "grey lighten-2" }
                },
                [_vm._v("cci-repair")]
              ),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "d-inline-block flavor-text font-weight-bold mb-n2",
                domProps: { innerHTML: _vm._s("/" + _vm.mech.RepairCapacity) }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "mt-n4",
          attrs: { dense: "", justify: "space-between", align: "start" }
        },
        [
          _c("v-col", { staticClass: "text-center" }, [
            _c(
              "div",
              {
                staticClass: "overline mb-4",
                staticStyle: { "line-height": "0" }
              },
              [_vm._v("STRUCTURE")]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-n3 mt-n6",
                    attrs: { size: "60", color: "grey lighten-3" }
                  },
                  [_vm._v("cci-structure")]
                ),
                _vm._v(" "),
                _c("b", {
                  staticClass:
                    "d-inline-block flavor-text font-weight-bold mb-n2",
                  domProps: { innerHTML: _vm._s("/" + _vm.mech.MaxStructure) }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "", "no-gutters": "", justify: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "auto" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "overline mb-4 mr-6",
                          staticStyle: { "line-height": "0" }
                        },
                        [_vm._v("HP")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-n3 mt-n6",
                              attrs: { size: "60", color: "grey lighten-3" }
                            },
                            [
                              _vm._v(
                                "\n              mdi-hexagon-outline\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("b", {
                            staticClass:
                              "d-inline-block flavor-text font-weight-bold mb-n2",
                            domProps: {
                              innerHTML: _vm._s("/" + _vm.mech.MaxHP)
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.mech.Armor
                    ? _c(
                        "v-col",
                        {
                          staticClass: "text-center mb-1",
                          attrs: { cols: "auto", "align-self": "end" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "overline mb-4 ml-2",
                              staticStyle: { "line-height": "0" }
                            },
                            [_vm._v("ARMOR")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "heading h2 mt-n4 mr-n2" },
                            [
                              _c("v-icon", { staticClass: "mt-n1 mr-n1" }, [
                                _vm._v("mdi-shield")
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.mech.Armor) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-col", { staticClass: "text-center" }, [
            _c(
              "div",
              {
                staticClass: "overline mb-4 mr-2",
                staticStyle: { "line-height": "0" }
              },
              [_vm._v("STRESS")]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-n3 mt-n6",
                    attrs: { size: "60", color: "grey lighten-3" }
                  },
                  [_vm._v("cci-reactor")]
                ),
                _vm._v(" "),
                _c("b", {
                  staticClass:
                    "d-inline-block flavor-text font-weight-bold mb-n2",
                  domProps: { innerHTML: _vm._s("/" + _vm.mech.MaxStress) }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("v-col", { staticClass: "text-center" }, [
            _c(
              "div",
              {
                staticClass: "overline mb-4 mr-6",
                staticStyle: { "line-height": "0" }
              },
              [_vm._v("HEAT")]
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "mr-n3 mt-n6",
                    attrs: { size: "60", color: "grey lighten-3" }
                  },
                  [_vm._v("mdi-fire")]
                ),
                _vm._v(" "),
                _c("b", {
                  staticClass:
                    "d-inline-block flavor-text font-weight-bold mb-n2",
                  domProps: { innerHTML: _vm._s("/" + _vm.mech.HeatCapacity) }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", [
            _c("fieldset", [
              _c("legend", { staticClass: "caption font-weight-bold px-1" }, [
                _vm._v("ATK")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heading h2 text-center mt-n2" }, [
                _vm._v(_vm._s(_vm.signed(_vm.mech.AttackBonus)))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("fieldset", [
              _c("legend", { staticClass: "caption font-weight-bold px-1" }, [
                _vm._v("TECH ATK")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heading h2 text-center mt-n2" }, [
                _vm._v(_vm._s(_vm.signed(_vm.mech.TechAttack)))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("fieldset", [
              _c("legend", { staticClass: "caption font-weight-bold px-1" }, [
                _vm._v("SAVE")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heading h2 text-center mt-n2" }, [
                _vm._v(_vm._s(_vm.mech.SaveTarget))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("fieldset", [
              _c("legend", { staticClass: "caption font-weight-bold px-1" }, [
                _vm._v("SPEED")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heading h2 text-center mt-n2" }, [
                _vm._v(_vm._s(_vm.mech.Speed))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("fieldset", [
              _c("legend", { staticClass: "caption font-weight-bold px-1" }, [
                _vm._v("E-DEF")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heading h2 text-center mt-n2" }, [
                _vm._v(_vm._s(_vm.mech.EDefense))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("fieldset", [
              _c("legend", { staticClass: "caption font-weight-bold px-1" }, [
                _vm._v("EVASION")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heading h2 text-center mt-n2" }, [
                _vm._v(_vm._s(_vm.mech.Evasion))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("fieldset", [
              _c("legend", { staticClass: "caption font-weight-bold px-1" }, [
                _vm._v("SENSORS")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heading h2 text-center mt-n2" }, [
                _vm._v(_vm._s(_vm.mech.SensorRange))
              ])
            ])
          ]),
          _vm._v(" "),
          _c("v-col", [
            _c("fieldset", [
              _c("legend", { staticClass: "caption font-weight-bold px-1" }, [
                _vm._v("LTD SYS")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "heading h2 text-center mt-n2" }, [
                _vm._v(_vm._s(_vm.signed(_vm.mech.LimitedBonus)))
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "overline mb-n2" }, [_vm._v("FRAME TRAITS")]),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "caption mt-n1",
          attrs: { dense: "", justify: "space-between" }
        },
        _vm._l(_vm.mech.Frame.Traits, function(t, i) {
          return _c("v-col", { key: "mt_" + i, staticClass: "mt-n1" }, [
            _c("fieldset", [
              _c("legend", { staticClass: "heading ml-1 px-2" }, [
                _vm._v(_vm._s(t.Name))
              ]),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: t.Description,
                    expression: "t.Description"
                  }
                ],
                staticClass: "ml-6 mb-0"
              })
            ])
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "overline mb-n3" }, [_vm._v("CORE SYSTEM")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "mt-n2" }, [
        _c("legend", { staticClass: "heading h3 ml-1 px-2" }, [
          _vm._v(_vm._s(_vm.mech.Frame.CoreSystem.Name))
        ]),
        _vm._v(" "),
        _vm.mech.Frame.CoreSystem.PassiveEffect
          ? _c("div", [
              _c("span", { staticClass: "heading ml-4" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.mech.Frame.CoreSystem.PassiveName
                        ? _vm.mech.Frame.CoreSystem.PassiveName + " (PASSIVE)"
                        : "CORE PASSIVE"
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.mech.Frame.CoreSystem.PassiveEffect,
                    expression: "mech.Frame.CoreSystem.PassiveEffect"
                  }
                ],
                staticClass: "caption ml-6 mb-1"
              })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.mech.Frame.CoreSystem.PassiveEffect
          ? _c("div", { staticClass: "heading ml-4" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.mech.Frame.CoreSystem.ActiveName
                      ? _vm.mech.Frame.CoreSystem.ActiveName + " (ACTIVE)"
                      : "CORE ACTIVE"
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", {
          directives: [
            {
              name: "html-safe",
              rawName: "v-html-safe",
              value: _vm.mech.Frame.CoreSystem.ActiveEffect,
              expression: "mech.Frame.CoreSystem.ActiveEffect"
            }
          ],
          staticClass: "caption ml-6 mb-1"
        }),
        _vm._v(" "),
        _vm.mech.Frame.CoreSystem.Tag
          ? _c(
              "div",
              { staticClass: "text-right" },
              _vm._l(_vm.mech.Frame.CoreSystem.Tags, function(t, i) {
                return _c("span", { key: "mcst_" + i, staticClass: "mx-1" }, [
                  _vm._v("\n        " + _vm._s(t.Name()) + "\n      ")
                ])
              }),
              0
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-n3", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [_c("v-divider", { staticClass: "mt-3" })],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("span", { staticClass: "heading h3" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.mech.ActiveLoadout.Name) +
                  "\n        "
              ),
              _c("span", { staticClass: "overline" }, [_vm._v("//LOADOUT")])
            ])
          ]),
          _vm._v(" "),
          _c("v-col", [_c("v-divider", { staticClass: "mt-3" })], 1)
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.mounts, function(m, i) {
        return _c(
          "fieldset",
          {
            key: "mmt_" + i,
            staticStyle: { position: "relative", "page-break-inside": "avoid" }
          },
          [
            _c("legend", { staticClass: "heading ml-1 px-2 mb-n2" }, [
              _vm._v(_vm._s(m.Name))
            ]),
            _vm._v(" "),
            m.IsLocked
              ? _c("div", { staticClass: "text-center flavor-text" }, [
                  _vm._v("\n      MOUNT LOCKED\n      "),
                  _c("br"),
                  _vm._v(" "),
                  _c("span", { staticClass: "overline" }, [
                    _vm._v("// SUPERHEAVY WEAPON BRACING //")
                  ])
                ])
              : _vm._l(m.Weapons, function(w, j) {
                  return _c(
                    "div",
                    { key: "mmtw_" + i + "_" + j, staticClass: "px-1" },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "stat-text",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _vm._v("\n        " + _vm._s(w.Name) + "\n        "),
                          _c(
                            "div",
                            {
                              staticClass: "d-inline-block overline ml-2 my-n1"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(w.Source) +
                                  " " +
                                  _vm._s(w.Size) +
                                  " " +
                                  _vm._s(w.Type) +
                                  "\n        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          w.Uses
                            ? _c(
                                "span",
                                _vm._l(
                                  w.MaxUses + _vm.mech.Pilot.LimitedBonus,
                                  function(n) {
                                    return _c(
                                      "v-icon",
                                      {
                                        key: "use_" + w.ID + "_" + n,
                                        attrs: { small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n            mdi-hexagon-outline\n          "
                                        )
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(w.Profiles, function(p) {
                        return _c("div", { key: w.ID + "_" + p.Name }, [
                          _c(
                            "div",
                            { staticClass: "caption" },
                            [
                              _vm._l(p.Range, function(r, k) {
                                return _c(
                                  "b",
                                  { key: "mmwr_" + i + "_" + j + "_" + k },
                                  [_vm._v(_vm._s(r.Text) + " ")]
                                )
                              }),
                              _vm._v(" "),
                              p.Damage && p.Damage.length
                                ? _c("span", [_vm._v("|")])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(p.Damage, function(d, k) {
                                return _c(
                                  "b",
                                  { key: "mmwd_" + i + "_" + j + "_" + k },
                                  [_vm._v(_vm._s(d.Text) + " ")]
                                )
                              }),
                              _vm._v(" "),
                              p.Effect
                                ? _c("p", {
                                    attrs: {
                                      "v-html-safe": p.Effect,
                                      print: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              p.OnAttack
                                ? _c("p", {
                                    attrs: {
                                      "v-html-safe":
                                        "<b>ON ATTACK:</b> " + p.OnAttack,
                                      print: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              p.OnHit
                                ? _c("p", {
                                    attrs: {
                                      "v-html-safe":
                                        "<b>ON HIT:</b> " + p.OnHit,
                                      print: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              p.OnCrit
                                ? _c("p", {
                                    attrs: {
                                      "v-html-safe":
                                        "<b>ON CRIT:</b> " + p.OnCrit,
                                      print: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("print-action", {
                                attrs: { actions: p.Actions }
                              }),
                              _vm._v(" "),
                              _c("print-deployable", {
                                attrs: { deployables: p.Deployables }
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "text-right",
                              staticStyle: {
                                position: "absolute",
                                bottom: "0",
                                left: "0",
                                right: "0"
                              }
                            },
                            [
                              _c("cc-tags", {
                                attrs: {
                                  tags: p.Tags,
                                  bonus: _vm.mech.Pilot.LimitedBonus,
                                  print: ""
                                }
                              })
                            ],
                            1
                          )
                        ])
                      }),
                      _vm._v(" "),
                      w.Mod
                        ? _c("div", { staticClass: "px-2" }, [
                            _c("span", { staticClass: "heading" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(w.Mod.Name) +
                                  "\n        "
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "overline" }, [
                              _vm._v(" //APPLIED MOD")
                            ]),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            w.Mod.Effect
                              ? _c("p", {
                                  attrs: { "v-html": w.Mod.Effect, print: "" }
                                })
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                })
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "fieldset",
        [
          _c("legend", { staticClass: "heading ml-1 px-2" }, [
            _vm._v("Systems")
          ]),
          _vm._v(" "),
          _vm._l(_vm.mech.ActiveLoadout.Systems, function(s, i) {
            return _c(
              "div",
              { key: "mms_" + i, staticClass: "bordered-block" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "stat-text mt-n1",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _vm._v("\n        " + _vm._s(s.Name) + "\n        "),
                    _c(
                      "div",
                      { staticClass: "d-inline-block overline ml-2 my-n1" },
                      [_vm._v(_vm._s(s.Source) + " " + _vm._s(s.Type))]
                    ),
                    _vm._v(" "),
                    _c("v-spacer"),
                    _vm._v(" "),
                    s.Uses
                      ? _c(
                          "span",
                          _vm._l(
                            s.MaxUses + _vm.mech.Pilot.LimitedBonus,
                            function(n) {
                              return _c(
                                "v-icon",
                                {
                                  key: "use_" + s.ID + "_" + n,
                                  attrs: { small: "" }
                                },
                                [
                                  _vm._v(
                                    "\n            mdi-hexagon-outline\n          "
                                  )
                                ]
                              )
                            }
                          ),
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                s.Effect
                  ? _c("p", {
                      staticClass: "caption mb-n1",
                      domProps: { innerHTML: _vm._s(s.Effect) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("print-action", { attrs: { actions: s.Actions } }),
                _vm._v(" "),
                _c("print-deployable", {
                  attrs: { deployables: s.Deployables }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "text-right",
                    staticStyle: {
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      right: "0"
                    }
                  },
                  [
                    _c("cc-tags", {
                      attrs: {
                        tags: s.Tags,
                        bonus: _vm.mech.Pilot.LimitedBonus,
                        print: ""
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }