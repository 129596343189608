var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("info-view", { attrs: { id: "section-0", pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("narrative-view", { attrs: { id: "section-1", pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("tactical-view", { attrs: { id: "section-2", pilot: _vm.pilot } }),
      _vm._v(" "),
      _c("mech-hangar-view", { attrs: { id: "section-3", pilot: _vm.pilot } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }