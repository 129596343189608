



















































import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import { CompendiumStore } from '@/store'
import { Manufacturer } from '@/class'

@Component
export default class Manufacturers extends Vue {
  public tabModel = 0

  private compendiumStore = getModule(CompendiumStore, this.$store)
  get manufacturers(): Manufacturer[] {
    return this.compendiumStore.Manufacturers.filter(x => !x.IsHidden)
  }
}
