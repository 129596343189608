var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "open-on-hover": "", bottom: "", "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-chip",
                _vm._g({ attrs: { color: _vm.deployable.Color } }, on),
                [
                  _c(
                    "span",
                    { staticClass: "white--text" },
                    [
                      _c("v-icon", { attrs: { left: "", dark: "" } }, [
                        _vm._v(_vm._s(_vm.deployable.Icon))
                      ]),
                      _vm._v(
                        "\n        " + _vm._s(_vm.deployable.Name) + "\n      "
                      )
                    ],
                    1
                  )
                ]
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { attrs: { flat: "", tile: "" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "white--text",
              attrs: { dense: "", flat: "", color: _vm.deployable.Color }
            },
            [
              _c("v-icon", { attrs: { left: "", large: "", dark: "" } }, [
                _vm._v(_vm._s(_vm.deployable.Icon))
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "heading h3" }, [
                _vm._v(_vm._s(_vm.deployable.Name))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "mt-n3" },
            [_c("deployable-info-base", { attrs: { action: _vm.action } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }