var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-height": "550px", "overflow-y": "scroll" } },
    [
      _c("h2", { staticClass: "heading accent--text mb-3" }, [
        _vm._v("Recent Errors")
      ]),
      _vm._v(" "),
      _c(
        "v-expansion-panels",
        _vm._l(_vm.errors, function(error, i) {
          return _c(
            "v-expansion-panel",
            { key: i },
            [
              _c("v-expansion-panel-header", [
                _c(
                  "div",
                  { staticClass: "flavor-text font-small text--text" },
                  [
                    _c(
                      "span",
                      { staticClass: "flavor-text error--text font-big" },
                      [_vm._v(_vm._s(error.message))]
                    ),
                    _vm._v(
                      "\n          - " +
                        _vm._s(_vm.dateFormat(error.time)) +
                        "\n          "
                    ),
                    error.component
                      ? _c("span", [
                          _vm._v("\n            at\n            "),
                          _c("span", { staticClass: "secondary--text" }, [
                            _vm._v("[" + _vm._s(error.component) + "]")
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _c("v-expansion-panel-content", [
                _c("h5", { staticClass: "error--text" }, [
                  _vm._v("STACK TRACE")
                ]),
                _vm._v(" "),
                _c(
                  "pre",
                  {
                    staticClass: "flavor-text error--text stack",
                    on: {
                      copy: function($event) {
                        return _vm.onCopy($event, error)
                      }
                    }
                  },
                  [_vm._v(_vm._s(error.stack))]
                )
              ])
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }