var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mb-n3", attrs: { align: "start" } },
        [
          _c("v-col", [
            _c(
              "span",
              {
                staticClass: "heading mech",
                staticStyle: { "line-height": "5px" }
              },
              [_vm._v(_vm._s(_vm.pilot.Callsign))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flavor-text subtle--text" }, [
              _vm._v(_vm._s(_vm.pilot.Name))
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "ml-auto text-right mr-2 mt-n2",
              attrs: { cols: "auto" }
            },
            [
              _c("span", { staticClass: "heading h3 accent--text" }, [
                _vm._v("HP")
              ]),
              _vm._v(" "),
              _c(
                "b",
                [
                  _c("cc-tick-bar", {
                    attrs: {
                      small: "",
                      "no-pips": "",
                      "flip-input": "",
                      current: _vm.pilot.CurrentHP,
                      max: _vm.pilot.MaxHP
                    },
                    on: {
                      update: function($event) {
                        _vm.pilot.CurrentHP = $event
                      }
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right mx-2 mt-n2", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "heading h3 accent--text" }, [
                _vm._v("Armor")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.pilot.Armor))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right mx-2 mt-n2", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "heading h3 accent--text" }, [
                _vm._v("E-Defense")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.pilot.EDefense))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right mx-2 mt-n2", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "heading h3 accent--text" }, [
                _vm._v("Evasion")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.pilot.Evasion))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "text-right mx-2 mt-n2", attrs: { cols: "auto" } },
            [
              _c("div", { staticClass: "heading h3 accent--text" }, [
                _vm._v("Grit")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v("+" + _vm._s(_vm.pilot.Grit))
              ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.mech.Destroyed || _vm.mech.ReactorDestroyed
        ? _c(
            "div",
            [
              _vm.mech.Destroyed
                ? _c(
                    "v-row",
                    {
                      staticClass: "text-center mb-n5",
                      attrs: { dense: "", justify: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                dense: "",
                                outlined: "",
                                color: "error",
                                prominent: ""
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-3",
                                  attrs: {
                                    slot: "prepend",
                                    color: "error",
                                    size: "70"
                                  },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n            cci-eclipse\n          "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "heading h1" }, [
                                _vm._v("MECH DESTROYED")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "heading mt-n4 subtle--text" },
                                [
                                  _vm._v(
                                    "\n            FRAME.CRITICAL//: CATASTROPHIC DAMAGE\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.mech.ReactorDestroyed
                ? _c("div", [
                    _c(
                      "p",
                      {
                        staticClass: "heading h2 stark--text text-center pt-4"
                      },
                      [
                        _vm._v(
                          "\n        This mech cannot be repaired and must be reprinted.\n      "
                        )
                      ]
                    )
                  ])
                : _c(
                    "div",
                    {
                      staticClass: "ma-3 panel clipped pt-3 px-6 text-center "
                    },
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            color: "warning",
                            outlined: "",
                            border: "bottom"
                          }
                        },
                        [
                          _c("div", { staticClass: "body-text text--text" }, [
                            _vm._v(
                              "\n          This mech can be repaired to working order by spending\n          "
                            ),
                            _c("b", { staticClass: "accent--text" }, [
                              _vm._v("4")
                            ]),
                            _vm._v(
                              "\n          repair points. These repairs can be spent from this mech’s own pool or the pools of any\n          pilots that wish to contribute, in any combination.\n        "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "subtle--text mt-2" }, [
                        _vm._v(
                          "\n        REPAIR CAPACITY REMAINING:\n        "
                        ),
                        _c("b", { staticClass: "accent--text" }, [
                          _vm._v(_vm._s(_vm.mech.CurrentRepairs))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _vm._l(_vm.mech.CurrentRepairs, function(n) {
                            return _c(
                              "v-icon",
                              { key: "rep_" + n, attrs: { large: "" } },
                              [_vm._v("\n          control_point\n        ")]
                            )
                          }),
                          _vm._v(" "),
                          _vm._l(
                            _vm.mech.RepairCapacity - _vm.mech.CurrentRepairs,
                            function(n) {
                              return _c(
                                "v-icon",
                                {
                                  key: "repcap_" + n,
                                  attrs: { large: "", color: "grey darken-1" }
                                },
                                [
                                  _vm._v(
                                    "\n          mdi-circle-outline\n        "
                                  )
                                ]
                              )
                            }
                          )
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "heading h3 mt-2 text-left" }, [
                        _vm._v("REPAIR POINTS")
                      ]),
                      _vm._v(" "),
                      _c("v-slider", {
                        attrs: {
                          max: Math.min(_vm.mech.CurrentRepairs, 4),
                          step: "1",
                          "thumb-label": "",
                          ticks: "always",
                          "tick-size": "6",
                          "track-color": "active",
                          "track-fill-color": "success"
                        },
                        model: {
                          value: _vm.selfRepair,
                          callback: function($$v) {
                            _vm.selfRepair = $$v
                          },
                          expression: "selfRepair"
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "heading h3 mt-2 text-left" }, [
                        _vm._v("ALLY REPAIR POINTS")
                      ]),
                      _vm._v(" "),
                      _c("v-slider", {
                        attrs: {
                          step: "1",
                          max: 4 - _vm.selfRepair,
                          ticks: "always",
                          "thumb-label": "",
                          "tick-size": "6",
                          "track-color": "active",
                          "track-fill-color": "success"
                        },
                        model: {
                          value: _vm.allyRepair,
                          callback: function($$v) {
                            _vm.allyRepair = $$v
                          },
                          expression: "allyRepair"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-4 mt-n1",
                          attrs: {
                            tile: "",
                            color: "success darken-1",
                            "x-large": "",
                            dark: "",
                            disabled: _vm.selfRepair + _vm.allyRepair < 4
                          },
                          on: {
                            click: function($event) {
                              return _vm.pilot.State.RepairDestroyed(
                                _vm.selfRepair
                              )
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { "x-large": "", left: "" } }, [
                            _vm._v("cci-repair")
                          ]),
                          _vm._v("\n          Repair Mech\n      ")
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.mech.ReactorDestroyed
        ? _c(
            "div",
            [
              _c("v-divider", { staticClass: "my-4" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c("cc-active-synergy", {
                    attrs: { locations: ["rest"], mech: _vm.mech }
                  })
                ],
                1
              ),
              _vm._v(" "),
              !_vm.mech.Destroyed && !_vm.mech.ReactorDestroyed
                ? _c(
                    "span",
                    { staticClass: "overline" },
                    [
                      _vm._v("\n      FIELD REPAIR INTERFACE\n      "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect",
                          attrs: { small: "", right: "", icon: "" },
                          on: {
                            click: function($event) {
                              _vm.showRepair = !_vm.showRepair
                            }
                          }
                        },
                        [
                          _c("v-icon", {
                            attrs: { small: "" },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.showRepair
                                  ? "mdi-eye-outline"
                                  : "mdi-eye-off-outline"
                              )
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.mech.Destroyed &&
              !_vm.mech.ReactorDestroyed &&
              _vm.showRepair
                ? _c(
                    "v-container",
                    { staticClass: "panel clipped mb-6 mt-n1" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-3", attrs: { dense: "" } },
                        [
                          _c("v-col", [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "caption flavor-text subtle--text pb-0 mb-0"
                              },
                              [
                                _vm._v(
                                  "\n            //[COMP/CON: COMBAT OPERATIONS COMPLETE\n            "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n            DISCONNECTING PILOT SENSORIUM ... done\n            "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n            UPLOADING BATTLEFIELD TELEMETRY ... done\n            "
                                ),
                                _c("br"),
                                _vm._v(
                                  "\n            RUNNING FRAME DIAGNOSTIC SUITE ... done ]\n            "
                                ),
                                _c("br"),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "overline stark--text" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.mech.Frame.Source) +
                                        " " +
                                        _vm._s(_vm.mech.Frame.Name) +
                                        " DIAGNOSTICS COMPLETE\n            "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "flavor-text subtle--text mb-0" },
                              [
                                _vm._v("\n            >//[\n            "),
                                _c("span", { staticClass: "accent--text" }, [
                                  _vm._v("COMP/CON")
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "text--text" }, [
                                  _vm._v(
                                    "\n              Lancer, I have detected\n              "
                                  ),
                                  _c("b", { staticClass: "accent--text" }, [
                                    _vm._v(_vm._s(_vm.issues))
                                  ]),
                                  _vm._v(
                                    "\n              issue" +
                                      _vm._s(_vm.issues === 1 ? "" : "s") +
                                      " requiring your attention:\n            "
                                  )
                                ]),
                                _vm._v("\n            ]\n          ")
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-col", [
                            _c("div", { staticClass: "text-center mb-1" }, [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v(
                                  "\n              REPAIR CAPACITY REMAINING:\n              "
                                ),
                                _c(
                                  "b",
                                  { staticClass: "accent--text heading h2" },
                                  [_vm._v(_vm._s(_vm.mech.CurrentRepairs))]
                                )
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _vm._l(_vm.mech.CurrentRepairs, function(n) {
                                    return _c(
                                      "v-icon",
                                      { key: "rep_" + n, attrs: { large: "" } },
                                      [_vm._v("cci-repair")]
                                    )
                                  }),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.mech.RepairCapacity -
                                      _vm.mech.CurrentRepairs,
                                    function(n) {
                                      return _c(
                                        "v-icon",
                                        {
                                          key: "repcap_" + n,
                                          staticStyle: { opacity: "0.4" },
                                          attrs: { large: "", color: "grey" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                mdi-hexagon-outline\n              "
                                          )
                                        ]
                                      )
                                    }
                                  )
                                ],
                                2
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "span",
                                  { staticClass: "overline" },
                                  [
                                    _c("cc-slashes"),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.mech.Frame.Source) +
                                        " " +
                                        _vm._s(_vm.mech.Frame.Name) +
                                        ' "' +
                                        _vm._s(_vm.mech.Name) +
                                        '"\n            '
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-progress-linear", {
                                  staticClass: "pa-0 mt-0 mb-2",
                                  attrs: {
                                    value: _vm.progress,
                                    color: "blue darken-3",
                                    "background-color": "red darken-4",
                                    height: "10"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "px-3", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center flavor-text background"
                            },
                            [
                              _vm.mech.CurrentHP === _vm.mech.MaxHP
                                ? _c("span", { staticClass: "stark--text" }, [
                                    _vm._v(
                                      "\n            > NO DAMAGE DETECTED\n          "
                                    )
                                  ])
                                : _c("b", { staticClass: "warning--text" }, [
                                    _vm._v("WARNING: DAMAGE DETECTED")
                                  ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _vm.mech.CurrentHP !== _vm.mech.MaxHP
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        dark: "",
                                        tile: "",
                                        outlined: "",
                                        color: "secondary",
                                        disabled: !_vm.mech.CurrentRepairs
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.pilot.State.RepairHP()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            Repair\n            "
                                      ),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v("control_point")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center flavor-text background"
                            },
                            [
                              _vm.mech.CurrentStructure ===
                              _vm.mech.MaxStructure
                                ? _c("span", { staticClass: "stark--text" }, [
                                    _vm._v(
                                      "\n            > STRUCTURAL INTEGRITY NOMINAL\n          "
                                    )
                                  ])
                                : _c("b", { staticClass: "error--text" }, [
                                    _vm._v("CRITICAL: STRUCTURE COMPROMISED")
                                  ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _vm.mech.CurrentStructure !==
                              _vm.mech.MaxStructure
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        dark: "",
                                        tile: "",
                                        outlined: "",
                                        color: "secondary",
                                        disabled: _vm.cheapStruct
                                          ? !_vm.mech.CurrentRepairs
                                          : _vm.mech.CurrentRepairs < 2
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.pilot.State.RepairStructure()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            Repair\n            "
                                      ),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v("control_point")
                                      ]),
                                      _vm._v(" "),
                                      !_vm.cheapStruct
                                        ? _c(
                                            "v-icon",
                                            { attrs: { right: "" } },
                                            [_vm._v("control_point")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center flavor-text background"
                            },
                            [
                              _vm.mech.CurrentStress === _vm.mech.MaxStress
                                ? _c("span", { staticClass: "stark--text" }, [
                                    _vm._v(
                                      "\n            > REACTOR INTEGRITY NOMINAL\n          "
                                    )
                                  ])
                                : _c("b", { staticClass: "error--text" }, [
                                    _vm._v("CRITICAL: REACTOR COMPROMISED")
                                  ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _vm.mech.CurrentStress !== _vm.mech.MaxStress
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        dark: "",
                                        tile: "",
                                        outlined: "",
                                        color: "secondary",
                                        disabled: _vm.cheapStress
                                          ? !_vm.mech.CurrentRepairs
                                          : _vm.mech.CurrentRepairs < 2
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.pilot.State.RepairStress()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            Repair\n            "
                                      ),
                                      !_vm.cheapStress
                                        ? _c(
                                            "v-icon",
                                            { attrs: { right: "" } },
                                            [_vm._v("control_point")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v("control_point")
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "px-5" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center flavor-text background"
                            },
                            [
                              !_vm.destroyedWeapons.length
                                ? _c("span", { staticClass: "stark--text" }, [
                                    _vm._v(
                                      "\n            > ARMAMENT NOMINAL\n          "
                                    )
                                  ])
                                : _c("b", { staticClass: "warning--text" }, [
                                    _vm._v("WARNING: ARMAMENT DAMAGED")
                                  ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _vm._l(_vm.destroyedWeapons, function(w) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: w.ID,
                                    staticClass: "my-0",
                                    attrs: {
                                      small: "",
                                      dark: "",
                                      tile: "",
                                      outlined: "",
                                      color: "secondary",
                                      disabled: !_vm.mech.CurrentRepairs
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.pilot.State.RepairSystem(w)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            Repair " +
                                        _vm._s(w.Name) +
                                        "\n            "
                                    ),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("control_point")
                                    ])
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-center flavor-text background"
                            },
                            [
                              !_vm.destroyedSystems.length
                                ? _c("span", { staticClass: "stark--text" }, [
                                    _vm._v(
                                      "\n            > SYSTEMS NOMINAL\n          "
                                    )
                                  ])
                                : _c("b", { staticClass: "warning--text" }, [
                                    _vm._v("WARNING: SYSTEMS DAMAGED")
                                  ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _vm._l(_vm.destroyedSystems, function(s) {
                                return _c(
                                  "v-btn",
                                  {
                                    key: s.ID,
                                    staticClass: "my-0",
                                    attrs: {
                                      small: "",
                                      dark: "",
                                      tile: "",
                                      outlined: "",
                                      color: "secondary",
                                      disabled: !_vm.mech.CurrentRepairs
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.pilot.State.RepairSystem(s)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            Repair " +
                                        _vm._s(s.Name) +
                                        "\n            "
                                    ),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("control_point")
                                    ])
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-n3 mx-4",
                      attrs: {
                        dense: "",
                        align: "center",
                        justify: "space-between"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tick-bar",
                            {
                              key: _vm.mech.CurrentStructure,
                              attrs: {
                                current: _vm.mech.CurrentStructure,
                                max: _vm.mech.MaxStructure,
                                large: "",
                                color: "structure",
                                "full-icon": "cci-structure"
                              },
                              on: {
                                update: function($event) {
                                  _vm.mech.CurrentStructure = $event
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v(
                                  "\n              Structure\n            "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tick-bar",
                            {
                              key: _vm.mech.CurrentStress,
                              attrs: {
                                current: _vm.mech.CurrentStress,
                                max: _vm.mech.MaxStress,
                                large: "",
                                color: "stress",
                                "full-icon": "cci-reactor"
                              },
                              on: {
                                update: function($event) {
                                  _vm.mech.CurrentStress = $event
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v("Stress")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tick-bar",
                            {
                              key: _vm.mech.CurrentHP,
                              attrs: {
                                current: _vm.mech.CurrentHP,
                                max: _vm.mech.MaxHP,
                                large: "",
                                color: "hp",
                                "full-icon": "mdi-hexagon",
                                "max-length": "25"
                              },
                              on: {
                                update: function($event) {
                                  _vm.mech.CurrentHP = $event
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v("HP")
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "cc-tick-bar",
                            {
                              key: _vm.mech.CurrentRepairs,
                              attrs: {
                                current: _vm.mech.CurrentRepairs,
                                max: _vm.mech.RepairCapacity,
                                large: "",
                                color: "repcap",
                                "full-icon": "cci-repair"
                              },
                              on: {
                                update: function($event) {
                                  _vm.mech.CurrentRepairs = $event
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "heading h3" }, [
                                _vm._v(
                                  "\n              REPAIR CAPACITY\n            "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("active-mode-loadout", {
                attrs: { mech: _vm.mech, rest: "" }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.pilot.State.IsMounted,
                      expression: "pilot.State.IsMounted"
                    }
                  ]
                },
                [
                  _c("v-divider", { staticClass: "my-5" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      style:
                        _vm.pilot.IsDead || _vm.pilot.IsDownAndOut
                          ? "opacity: 0.5"
                          : ""
                    },
                    [
                      _c("span", { staticClass: "overline" }, [
                        _vm._v("PILOT LOADOUT")
                      ]),
                      _vm._v(" "),
                      _c("cc-pilot-loadout", {
                        attrs: { pilot: _vm.pilot, readonly: "" }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _c("span", { staticClass: "overline" }, [
                              _vm._v("SKILL TRIGGERS")
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "ml-auto", attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "fadeSelect",
                                  attrs: { "x-small": "", outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.expandAll(
                                        _vm.pilot.Skills.length,
                                        "sk_",
                                        true
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", left: "" } },
                                    [_vm._v("mdi-chevron-up")]
                                  ),
                                  _vm._v("\n              All\n            ")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "fadeSelect",
                                  attrs: { "x-small": "", outlined: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.expandAll(
                                        _vm.pilot.Skills.length,
                                        "sk_",
                                        false
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { small: "", left: "" } },
                                    [_vm._v("mdi-chevron-down")]
                                  ),
                                  _vm._v("\n              All\n            ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "", justify: "center" } },
                        _vm._l(_vm.pilot.Skills, function(s, i) {
                          return _c("cc-active-card", {
                            key: "sk_" + i,
                            ref: "sk_" + i,
                            refInFor: true,
                            attrs: {
                              cols: _vm.$vuetify.breakpoint.lgAndUp ? 4 : 6,
                              color: "secondary",
                              collapsible: "",
                              "start-closed": "",
                              header: s.Skill.Name + " (+" + s.Bonus + ")",
                              content: s.Skill.Detail
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.pilot.Reserves || _vm.pilot.Organizations
                    ? _c(
                        "span",
                        { staticClass: "overline" },
                        [
                          _vm._v("\n        RESERVES AND RESOURCES\n        "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "fadeSelect",
                              attrs: { small: "", right: "", icon: "" },
                              on: {
                                click: function($event) {
                                  _vm.showReserves = !_vm.showReserves
                                }
                              }
                            },
                            [
                              _c("v-icon", {
                                attrs: { small: "" },
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.showReserves
                                      ? "mdi-eye-outline"
                                      : "mdi-eye-off-outline"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-scroll-y-reverse-transition",
                    { attrs: { mode: "out-in" } },
                    [
                      _vm.showReserves &&
                      (_vm.pilot.Reserves || _vm.pilot.Organizations)
                        ? _c(
                            "v-row",
                            { staticClass: "mt-n3" },
                            [
                              _vm._l(
                                _vm.pilot.Reserves.filter(function(r) {
                                  return r.Type !== "Bonus"
                                }),
                                function(r, i) {
                                  return _c("cc-reserve-item", {
                                    key: "r_" + i,
                                    attrs: { reserve: r },
                                    on: {
                                      remove: function($event) {
                                        return _vm.pilot.RemoveReserve(i)
                                      }
                                    }
                                  })
                                }
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.pilot.Organizations, function(o, i) {
                                return _c("cc-org-item", {
                                  key: "o_" + i,
                                  attrs: { org: o },
                                  on: {
                                    remove: function($event) {
                                      return _vm.pilot.RemoveOrganization(i)
                                    }
                                  }
                                })
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }