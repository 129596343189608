import { render, staticRenderFns } from "./_deployableInfoPanel.vue?vue&type=template&id=b0f6d022&"
import script from "./_deployableInfoPanel.vue?vue&type=script&lang=ts&"
export * from "./_deployableInfoPanel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VChip,VCol,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b0f6d022')) {
      api.createRecord('b0f6d022', component.options)
    } else {
      api.reload('b0f6d022', component.options)
    }
    module.hot.accept("./_deployableInfoPanel.vue?vue&type=template&id=b0f6d022&", function () {
      api.rerender('b0f6d022', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ui/components/items/features/deployables/_deployableInfoPanel.vue"
export default component.exports