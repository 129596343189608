var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("selector", {
    attrs: {
      title: "Pilot Talents",
      height: "60vh",
      success: !_vm.pilot.IsMissingTalents && _vm.enoughSelections
    },
    scopedSlots: _vm._u([
      {
        key: "left-column",
        fn: function() {
          return [
            _vm._l(_vm.pilot.Talents, function(pTalent, i) {
              return _c(
                "v-row",
                {
                  key: "summary_" + pTalent.Talent.ID + "_" + i,
                  staticClass: "my-2"
                },
                [
                  pTalent.Talent.err
                    ? _c("missing-item", {
                        on: {
                          remove: function($event) {
                            return _vm.remove(pTalent)
                          }
                        }
                      })
                    : _c(
                        "span",
                        [
                          _c("v-icon", { attrs: { color: "accent" } }, [
                            _vm._v("cci-rank-" + _vm._s(pTalent.Rank))
                          ]),
                          _vm._v(" "),
                          _c("strong", [_vm._v(_vm._s(pTalent.Talent.Name))]),
                          _vm._v(" "),
                          _c(
                            "v-icon",
                            {
                              staticClass: "fadeSelect",
                              attrs: { right: "" },
                              on: {
                                click: function($event) {
                                  return _vm.scroll(pTalent.Talent.ID)
                                }
                              }
                            },
                            [_vm._v("\n          mdi-chevron-right\n        ")]
                          )
                        ],
                        1
                      )
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm.pilot.Talents.length
              ? _c("v-divider", { staticClass: "ma-2 ml-4 mr-4" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c(
                      "v-alert",
                      {
                        staticClass: "stat-text",
                        staticStyle: { width: "95%" },
                        attrs: {
                          outlined: "",
                          prominent: "",
                          dense: "",
                          border: "left",
                          color: "success",
                          icon: "check_circle",
                          value:
                            !_vm.pilot.IsMissingTalents && _vm.enoughSelections
                        }
                      },
                      [
                        _vm._v(
                          "\n          Talent Selection Complete\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-alert",
                      {
                        staticClass: "stat-text",
                        staticStyle: { width: "95%" },
                        attrs: {
                          outlined: "",
                          prominent: "",
                          dense: "",
                          border: "left",
                          color: "accent",
                          icon: "warning",
                          value:
                            _vm.pilot.MaxTalentPoints >
                            _vm.pilot.CurrentTalentPoints
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.pilot.MaxTalentPoints -
                                _vm.pilot.CurrentTalentPoints
                            ) +
                            " Talent selections remaining\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-alert",
                      {
                        staticClass: "stat-text",
                        staticStyle: { width: "95%" },
                        attrs: {
                          outlined: "",
                          prominent: "",
                          dense: "",
                          border: "left",
                          color: "accent",
                          icon: "warning",
                          value: !_vm.enoughSelections && _vm.newPilot
                        }
                      },
                      [
                        _vm._v(
                          "\n          Must select a minimum of " +
                            _vm._s(_vm.selectedMin) +
                            " talents\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "my-2" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              block: "",
                              text: "",
                              small: "",
                              disabled: !_vm.talents.length
                            },
                            on: {
                              click: function($event) {
                                return _vm.pilot.ClearTalents()
                              }
                            }
                          },
                          [_vm._v("\n            Reset\n          ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "right-column",
        fn: function() {
          return [
            _c(
              "v-row",
              { attrs: { dense: "", align: "center" } },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "10", lg: "5" } },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-2",
                      attrs: {
                        "prepend-icon": "mdi-magnify",
                        color: "accent",
                        label: "Search Talents",
                        dense: "",
                        "hide-details": "",
                        outlined: "",
                        clearable: ""
                      },
                      model: {
                        value: _vm.search,
                        callback: function($$v) {
                          _vm.search = $$v
                        },
                        expression: "search"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { staticClass: "ml-auto", attrs: { cols: "auto" } },
                  [
                    _c(
                      "v-btn-toggle",
                      {
                        attrs: { mandatory: "" },
                        model: {
                          value: _vm.ctype,
                          callback: function($$v) {
                            _vm.ctype = $$v
                          },
                          expression: "ctype"
                        }
                      },
                      [
                        _c(
                          "v-btn",
                          { attrs: { value: "full" } },
                          [_c("v-icon", [_vm._v("mdi-view-stream")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          { attrs: { value: "terse" } },
                          [_c("v-icon", [_vm._v("mdi-view-list")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          { attrs: { value: "small" } },
                          [_c("v-icon", [_vm._v("mdi-view-comfy")])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              { attrs: { id: "talent-list", dense: "", justify: "center" } },
              _vm._l(_vm.talents, function(t, i) {
                return _c("cc-talent", {
                  key: "t_" + i,
                  attrs: {
                    id: "e_" + t.ID,
                    talent: t,
                    rank: _vm.pilot.getTalentRank(t.ID),
                    terse: _vm.ctype === "terse",
                    small: _vm.ctype === "small",
                    "can-add": _vm.canAdd(t.ID),
                    selectable: ""
                  },
                  on: {
                    add: function($event) {
                      return _vm.pilot.AddTalent(t)
                    },
                    remove: function($event) {
                      return _vm.pilot.RemoveTalent(t)
                    }
                  }
                })
              }),
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }