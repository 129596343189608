var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card-text",
    { staticClass: "mb-0 pb-0" },
    [
      _c(
        "v-row",
        { staticClass: "flavor-text", attrs: { "no-gutters": "" } },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.item.LicenseString
        ? _c(
            "v-row",
            { staticClass: "mb-2 mt-n1" },
            [
              _c("v-divider", { staticClass: "mt-3" }),
              _vm._v(" "),
              _c("v-icon", { attrs: { color: "panel-border" } }, [
                _vm._v("cci-" + _vm._s(_vm.$_.kebabCase(_vm.item.ItemType)))
              ]),
              _vm._v(" "),
              _c("v-divider", { staticClass: "mt-3" })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("statblock"),
      _vm._v(" "),
      _vm.item.Effect
        ? _c("div", [
            _c("div", { staticClass: "overline ml-n2 mt-2 subtle--text" }, [
              _vm._v("EQUIPMENT EFFECT")
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.item.Effect,
                  expression: "item.Effect"
                }
              ],
              staticClass: "text--text body-text mb-1"
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("other_effects"),
      _vm._v(" "),
      _vm.item.Actions && _vm.item.Actions.length
        ? _c(
            "div",
            [
              _c("div", { staticClass: "overline ml-n2 subtle--text" }, [
                _vm._v("EQUIPMENT ACTIONS")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                _vm._l(_vm.item.Actions, function(a, i) {
                  return _c(
                    "v-col",
                    {
                      key: _vm.item.Name + "_action_" + i,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-action", {
                        staticClass: "ma-2",
                        attrs: {
                          action: a,
                          panel: _vm.$vuetify.breakpoint.lgAndUp
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item && _vm.item.Ammo && _vm.item.Ammo.length
        ? _c(
            "div",
            _vm._l(_vm.item.Ammo, function(a, i) {
              return _c(
                "div",
                { key: _vm.item.Name + "_ammo_" + i, staticClass: "body-text" },
                [
                  _c("b", [_vm._v(_vm._s(a.name))]),
                  _vm._v("\n      :\n      "),
                  _c("span", {
                    directives: [
                      {
                        name: "html-safe",
                        rawName: "v-html-safe",
                        value: a.detail,
                        expression: "a.detail"
                      }
                    ]
                  })
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.Deployables && _vm.item.Deployables.length
        ? _c(
            "div",
            [
              _c("div", { staticClass: "overline ml-n2 subtle--text" }, [
                _vm._v("EQUIPMENT DEPLOYABLES")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                _vm._l(_vm.item.Deployables, function(d, i) {
                  return _c(
                    "v-col",
                    {
                      key: _vm.item.Name + "_deployable_" + i,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-deployable-info", {
                        staticClass: "ma-2",
                        attrs: {
                          deployable: d,
                          panel: _vm.$vuetify.breakpoint.lgAndUp,
                          "name-override": _vm.item.Name
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.IntegratedEquipment && _vm.item.IntegratedEquipment.length
        ? _c(
            "div",
            [
              _c("div", { staticClass: "overline ml-n2 subtle--text" }, [
                _vm._v("EQUIPMENT INTEGRATIONS")
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "", justify: "center" } },
                _vm._l(_vm.item.IntegratedEquipment, function(x, i) {
                  return _c(
                    "v-col",
                    {
                      key: _vm.item.Name + "_integrated_" + i,
                      attrs: { cols: "auto" }
                    },
                    [
                      _c("cc-integrated-info", {
                        attrs: {
                          item: x,
                          panel: _vm.$vuetify.breakpoint.lgAndUp
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("profile"),
      _vm._v(" "),
      _vm.item.Tags && _vm.item.Tags.length
        ? _c(
            "div",
            [
              _c("div", { staticClass: "overline ml-n2 mb-n1 subtle--text" }, [
                _vm._v("EQUIPMENT TAGS")
              ]),
              _vm._v(" "),
              _c("cc-tags", { attrs: { tags: _vm.item.Tags, extended: "" } })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.Description
        ? _c("div", { staticClass: "mt-2" }, [
            _c("div", { staticClass: "overline ml-n2" }, [
              _vm._v("COMPENDIUM ENTRY")
            ]),
            _vm._v(" "),
            _c("p", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.item.Description,
                  expression: "item.Description"
                }
              ],
              staticClass: "flavor-text mb-1"
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.notes
        ? _c(
            "div",
            [
              _c("v-textarea", {
                staticClass: "mt-2",
                attrs: {
                  outlined: "",
                  "auto-grow": "",
                  rows: "2",
                  filled: "",
                  dense: "",
                  "hide-details": "",
                  "prepend-icon": "mdi-note",
                  label: "Equipment Notes"
                },
                model: {
                  value: _vm.item.Note,
                  callback: function($$v) {
                    _vm.$set(_vm.item, "Note", $$v)
                  },
                  expression: "item.Note"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }