var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.counter
    ? _c(
        "v-card",
        {
          attrs: {
            tile: "",
            outlined: "",
            color: "primary",
            width: "225px",
            "min-width": "225px",
            height: "100%"
          }
        },
        [
          _c(
            "v-card-title",
            { staticClass: "primary white--text py-2" },
            [
              _c("span", { staticClass: "text-button" }, [
                _vm._v(_vm._s(_vm.counter.Name))
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "cc-tooltip",
                { attrs: { simple: "", content: "Reset Counter" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "fadeSelect",
                      attrs: { dark: "", icon: "", "x-small": "" },
                      on: {
                        click: function($event) {
                          return _vm.counter.Reset()
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-reload")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.counterData.custom
                ? _c(
                    "cc-tooltip",
                    { attrs: { simple: "", content: "Delete Counter" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect ml-1",
                          attrs: { dark: "", icon: "", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("delete")
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("delete")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "background pb-0" },
            [
              _c(
                "v-row",
                {
                  staticClass: "counterContent",
                  attrs: { justify: "center", align: "center" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            icon: "",
                            color: "accent",
                            disabled: _vm.counter.Value <= _vm.counter.Min
                          },
                          on: {
                            click: function($event) {
                              return _vm.counter.Decrement()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("remove")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "counterValue",
                        class: {
                          dirty: _vm.dirty
                        },
                        attrs: {
                          type: "number",
                          outlined: "",
                          dense: "",
                          value: _vm.counter.Value
                        },
                        on: {
                          blur: function($event) {
                            return _vm.onInputEnterOrLeave($event)
                          },
                          keypress: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.onInputEnterOrLeave($event)
                          },
                          input: _vm.onInput
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            icon: "",
                            elevation: "0",
                            color: "accent",
                            disabled: _vm.counter.Value >= _vm.counter.Max
                          },
                          on: {
                            click: function($event) {
                              return _vm.counter.Increment()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }