var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("v-divider", { staticClass: "ma-3" }),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-3", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-btn", { attrs: { large: "", text: "", to: _vm.exit } }, [
                _vm._v("EXIT")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "ml-auto", attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-auto mr-2",
                  attrs: {
                    color: "primary",
                    disabled: !_vm.back,
                    large: "",
                    text: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("back")
                    }
                  }
                },
                [_vm._v("\n        BACK\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "auto" } }, [_vm._t("other")], 2),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              !_vm.noConfirm
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "success",
                        disabled: !_vm.complete,
                        large: "",
                        tile: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("complete")
                        }
                      }
                    },
                    [_vm._v("\n        CONTINUE\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }