var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "equipment-card-base",
    { attrs: { item: _vm.item } },
    [
      _c(
        "v-col",
        { staticClass: "text-center", attrs: { cols: "auto" } },
        [
          _c(
            "v-icon",
            {
              staticClass: "mt-n3 mb-n2",
              attrs: { size: "56px", color: "stark" }
            },
            [_vm._v("cci-weapon-mod")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "overline mb-n1" }, [
            _vm._v("\n      WEAPON\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "overline" }, [
            _vm._v("\n      MODIFICATION\n    ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } }),
      _vm._v(" "),
      _vm.item.AddedRange && _vm.item.AddedRange.length
        ? _c(
            "v-col",
            { attrs: { cols: "auto", "align-self": "center" } },
            [
              _c("cc-range-element", {
                attrs: { range: _vm.item.AddedRange, added: "" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.AddedRange && _vm.item.AddedRange.length
        ? _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.AddedDamage && _vm.item.AddedDamage.length
        ? _c(
            "v-col",
            { attrs: { cols: "auto", "align-self": "center" } },
            [
              _c("cc-damage-element", {
                attrs: { damage: _vm.item.AddedDamage, added: "" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.item.AddedDamage && _vm.item.AddedDamage.length
        ? _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.SP
        ? _c("v-col", { staticClass: "text-center", attrs: { cols: "auto" } }, [
            _c(
              "div",
              { staticClass: "panel clipped" },
              _vm._l(_vm.item.SP, function(n) {
                return _c(
                  "v-icon",
                  { key: _vm.item.ID + "_sp-" + n, attrs: { "x-large": "" } },
                  [_vm._v("cci-system-point")]
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "overline" }, [
              _c("b", [_vm._v(_vm._s(_vm.item.SP))]),
              _vm._v(
                "\n      SYSTEM POINT" +
                  _vm._s(_vm.item.SP > 1 ? "S" : "") +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "ml-auto text-right", attrs: { cols: "auto" } },
        [
          _c("span", { staticClass: "flavor-text subtle--text" }, [
            _vm._v("// " + _vm._s(_vm.item.LicenseString))
          ]),
          _vm._v(" "),
          _vm.item.Restricted
            ? _c("div", [
                _c("span", { staticClass: "stat-text error--text" }, [
                  _vm._v(
                    "\n        RESTRICTED: " +
                      _vm._s(_vm.item.Restricted.join("/").toUpperCase()) +
                      " MOUNTS\n      "
                  )
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "statblock" }, slot: "statblock" },
        [
          _c("div", { staticClass: "overline mb-n3" }, [
            _vm._v("CAN BE APPLIED TO")
          ]),
          _vm._v(" "),
          _c(
            "v-chip-group",
            [
              _vm._l(_vm.item.PossibleTypes, function(a) {
                return _c(
                  "v-chip",
                  {
                    key: _vm.item.ID + "_allowedtype_" + a,
                    staticClass: "text-uppercase",
                    attrs: { small: "", label: "", outlined: "" }
                  },
                  [_vm._v("\n        " + _vm._s(a) + "\n      ")]
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.item.PossibleSizes, function(a) {
                return _c(
                  "v-chip",
                  {
                    key: _vm.item.ID + "_allowedsize_" + a,
                    staticClass: "text-uppercase",
                    attrs: { small: "", label: "" }
                  },
                  [_vm._v("\n        " + _vm._s(a) + "\n      ")]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm.item.RestrictedTypes.length || _vm.item.RestrictedSizes.length
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "overline mb-n3" }, [
                    _vm._v("RESTRICTED")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-chip-group",
                    [
                      _vm._l(_vm.item.RestrictedTypes, function(a) {
                        return _c(
                          "v-chip",
                          {
                            key: _vm.item.ID + "_restrictedtype_" + a,
                            staticClass: "text-uppercase",
                            attrs: {
                              small: "",
                              label: "",
                              outlined: "",
                              color: "error"
                            }
                          },
                          [_vm._v("\n          " + _vm._s(a) + "\n        ")]
                        )
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.item.RestrictedSizes, function(a) {
                        return _c(
                          "v-chip",
                          {
                            key: _vm.item.ID + "_restrictedsize_" + a,
                            staticClass: "text-uppercase",
                            attrs: { small: "", label: "", color: "error" }
                          },
                          [_vm._v("\n          " + _vm._s(a) + "\n        ")]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }