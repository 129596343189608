var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", md: "auto" } },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            width: _vm.$vuetify.breakpoint.mdAndDown ? "100vw" : "60vw"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "px-4",
                        attrs: {
                          large: _vm.$vuetify.breakpoint.lgAndUp,
                          outlined: "",
                          color: "stark",
                          block: ""
                        }
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { left: "", color: "stark" } }, [
                        _vm._v(_vm._s(_vm.reserve.Icon))
                      ]),
                      _vm._v(" "),
                      _vm.reserve.Used
                        ? _c("s", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.reserve.Name) +
                                "\n        "
                            )
                          ])
                        : _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.reserve.Name) +
                                "\n        "
                            )
                          ])
                    ],
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm._v(" "),
          _c(
            "cc-titled-panel",
            {
              staticStyle: { height: "100%" },
              attrs: {
                title: _vm.reserve.Name,
                icon: _vm.reserve.Icon,
                color: _vm.reserve.Color
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "items" }, slot: "items" },
                [
                  _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        simple: "",
                        inline: "",
                        content: "Delete Reserve"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", color: "error" },
                          on: {
                            click: function($event) {
                              return _vm.remove()
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.reserve.Description,
                    expression: "reserve.Description"
                  }
                ],
                staticClass: "flavor-text mx-2 my-1"
              }),
              _vm._v(" "),
              _vm.reserve.ID != "reserve_skill"
                ? _c(
                    "v-card",
                    { staticClass: "px-5", attrs: { flat: "", outlined: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "9" } }, [
                                _vm.reserve.Type === "Resources"
                                  ? _c(
                                      "div",
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.reserve.ResourceLabel,
                                            outlined: ""
                                          },
                                          model: {
                                            value: _vm.reserve.ResourceName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.reserve,
                                                "ResourceName",
                                                $$v
                                              )
                                            },
                                            expression: "reserve.ResourceName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm.reserve.Type === "Mech"
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "effect-text" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.reserve.Description)
                                          )
                                        ]
                                      )
                                    ])
                                  : _c("div", [
                                      _vm.reserve.ID ===
                                        "reserve_bombardment" ||
                                      _vm.reserve.ID ===
                                        "reserve_extendedharness"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "effect-text" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.reserve.Description
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-spacer", {
                                                staticClass: "pb-4"
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label:
                                                    _vm.reserve.ResourceLabel
                                                },
                                                model: {
                                                  value:
                                                    _vm.reserve.ResourceName,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.reserve,
                                                      "ResourceName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "reserve.ResourceName"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                    ])
                              ]),
                              _vm._v(" "),
                              _vm.reserve.Type !== "Project"
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { cols: "3" }
                                    },
                                    [
                                      _c(
                                        "v-switch",
                                        {
                                          attrs: {
                                            dense: "",
                                            inset: "",
                                            "hide-details": "",
                                            color: "secondary"
                                          },
                                          model: {
                                            value: _vm.reserve.Used,
                                            callback: function($$v) {
                                              _vm.$set(_vm.reserve, "Used", $$v)
                                            },
                                            expression: "reserve.Used"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "stat-text text--text",
                                              attrs: { slot: "label" },
                                              slot: "label"
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Used\n                  "
                                              ),
                                              _c(
                                                "cc-tooltip",
                                                {
                                                  attrs: {
                                                    simple: "",
                                                    inline: "",
                                                    content:
                                                      "Mark this resource as used or unavailable (but not consumed, destroyed or lost)"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-help-circle-outline"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-textarea", {
                            attrs: {
                              "auto-grow": "",
                              filled: "",
                              rows: "2",
                              label: "Notes"
                            },
                            model: {
                              value: _vm.reserve.Note,
                              callback: function($$v) {
                                _vm.$set(_vm.reserve, "Note", $$v)
                              },
                              expression: "reserve.Note"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-textarea", {
                            attrs: {
                              "auto-grow": "",
                              filled: "",
                              rows: "2",
                              label: "Cost/Complications",
                              clearable: ""
                            },
                            model: {
                              value: _vm.reserve.ResourceCost,
                              callback: function($$v) {
                                _vm.$set(_vm.reserve, "ResourceCost", $$v)
                              },
                              expression: "reserve.ResourceCost"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("Dismiss")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }