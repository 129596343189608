var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-card-base",
    {
      attrs: { item: _vm.item, small: _vm.small, hover: _vm.hover },
      on: {
        equip: function($event) {
          return _vm.$emit("equip", $event)
        }
      }
    },
    [
      _c(
        "v-row",
        {
          attrs: {
            slot: "top",
            "no-gutters": "",
            justify: "space-around",
            align: "center"
          },
          slot: "top"
        },
        [
          _vm.item.Damage && _vm.item.Damage[0]
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("cc-damage-element", {
                    attrs: { damage: _vm.item.Damage }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.Range
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [_c("cc-range-element", { attrs: { range: _vm.item.Range } })],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("span", { attrs: { slot: "overline" }, slot: "overline" }, [
        _vm._v(_vm._s(_vm.item.Size) + " " + _vm._s(_vm.item.WeaponType))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }