






















































































































































import { ActivationType } from '@/class'
import Vue from 'vue'
import ActionDetailExpander from '../../components/_ActionDetailExpander.vue'

export default Vue.extend({
  name: 'search-dialog',
  components: { ActionDetailExpander },
  props: {
    used: { type: Boolean },
    mech: {
      type: Object,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    sys: '',
    accuracy: 0,
    difficulty: 0,
    sysRoll: '',
    actionCost: false,
    actionFree: false,
    timer: 0,
    finished: false,
  }),
  methods: {
    complete() {
      this.$emit('use', this.actionFree ? ActivationType.Free : ActivationType.Quick)
    },
    registerSysRoll(roll) {
      Vue.set(this, 'sysRoll', roll)
      Vue.nextTick().then(() => this.$forceUpdate())
    },
    reset() {
      this.mech.Pilot.State.UndoAction(
        this.action,
        this.actionFree ? ActivationType.Free : ActivationType.Quick
      )
      this.init()
    },
    init() {
      this.accuracy = 0
      this.difficulty = 0
      this.sys = ''
      this.roll = ''
      this.rollString = ''
      this.rollResultString = ''
      this.rollAccuracyResults = '[]'
      this.actionCost = false
      this.actionFree = false
      this.timer = 0
      this.finished = false
    },
    show(): void {
      this.dialog = true
    },
    hide(): void {
      this.dialog = false
    },
  },
})
