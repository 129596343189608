var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "cc-title",
        {
          staticClass: "pl-3",
          staticStyle: { "margin-left": "-70px!important" },
          attrs: { small: "", color: "pilot" }
        },
        [
          _c("span", { staticClass: "ml-9" }, [_vm._v(" ")]),
          _vm._v("\n    Player Notes\n  ")
        ]
      ),
      _vm._v(" "),
      _c("v-textarea", {
        staticClass: "mt-2",
        attrs: { outlined: "", "auto-grow": "" },
        model: {
          value: _vm.pilot.Notes,
          callback: function($$v) {
            _vm.$set(_vm.pilot, "Notes", $$v)
          },
          expression: "pilot.Notes"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }