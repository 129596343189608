var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "equipment-card-base",
    { attrs: { item: _vm.item, notes: _vm.notes } },
    [
      _vm._v("\n  " + _vm._s(_vm.item.OnAttack) + "\n  "),
      _vm.item.Profiles && _vm.item.Profiles.length > 1
        ? _c(
            "v-col",
            { staticClass: "mt-n4 mb-4", attrs: { cols: "12" } },
            [
              _c("div", { staticClass: "overline mb-1" }, [
                _vm._v("WEAPON PROFILES")
              ]),
              _vm._v(" "),
              _c(
                "v-tabs",
                {
                  attrs: { grow: "", height: "30px" },
                  model: {
                    value: _vm.tab,
                    callback: function($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                _vm._l(_vm.item.Profiles, function(p) {
                  return _c("v-tab", { key: p.ID }, [
                    _c(
                      "span",
                      { staticClass: "accent--text font-weight-bold" },
                      [_vm._v(_vm._s(p.Name))]
                    )
                  ])
                }),
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { attrs: { slot: "other_effects" }, slot: "other_effects" },
            [
              _vm.item.ProfileOnAttack
                ? _c(
                    "div",
                    { staticClass: "panel clipped pa-2" },
                    [
                      _c("v-icon", [_vm._v("cci-weapon")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "overline stark--text" }, [
                        _vm._v("ON ATTACK")
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        directives: [
                          {
                            name: "html-safe",
                            rawName: "v-html-safe",
                            value: _vm.item.ProfileOnAttack,
                            expression: "item.ProfileOnAttack"
                          }
                        ],
                        staticClass: "text--text body-text mb-1 mx-3"
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.ProfileOnHit
                ? _c(
                    "div",
                    { staticClass: "panel clipped pa-2" },
                    [
                      _c("v-icon", [_vm._v("cci-weapon")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "overline stark--text" }, [
                        _vm._v("ON HIT")
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        directives: [
                          {
                            name: "html-safe",
                            rawName: "v-html-safe",
                            value: _vm.item.ProfileOnHit,
                            expression: "item.ProfileOnHit"
                          }
                        ],
                        staticClass: "text--text body-text mb-1 mx-3"
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.ProfileOnCrit
                ? _c(
                    "div",
                    { staticClass: "panel clipped pa-2" },
                    [
                      _c("v-icon", [_vm._v("cci-weapon")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "overline stark--text" }, [
                        _vm._v("ON CRITICAL HIT")
                      ]),
                      _vm._v(" "),
                      _c("p", {
                        directives: [
                          {
                            name: "html-safe",
                            rawName: "v-html-safe",
                            value: _vm.item.ProfileOnCrit,
                            expression: "item.ProfileOnCrit"
                          }
                        ],
                        staticClass: "text--text body-text mb-1 mx-3"
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [_c("cc-range-element", { attrs: { range: _vm.item.Range } })],
        1
      ),
      _vm._v(" "),
      _vm.item.Range.length && _vm.item.Damage.length
        ? _c("v-divider", { staticClass: "mx-4 my-2", attrs: { vertical: "" } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c("cc-damage-element", {
            attrs: {
              damage: _vm.item.Damage,
              "type-override": _vm.item.DamageTypeOverride
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.item.SP
        ? _c("v-divider", { staticClass: "mx-4", attrs: { vertical: "" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.SP
        ? _c("v-col", { staticClass: "text-center", attrs: { cols: "auto" } }, [
            _c(
              "div",
              { staticClass: "panel clipped" },
              _vm._l(_vm.item.SP, function(n) {
                return _c(
                  "v-icon",
                  { key: _vm.item.ID + "_sp-" + n, attrs: { "x-large": "" } },
                  [_vm._v("cci-system-point")]
                )
              }),
              1
            ),
            _vm._v(" "),
            _c("span", { staticClass: "overline" }, [
              _c("b", [_vm._v(_vm._s(_vm.item.SP))]),
              _vm._v(
                "\n      SYSTEM POINT" +
                  _vm._s(_vm.item.SP > 1 ? "S" : "") +
                  "\n    "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "ml-auto text-right", attrs: { cols: "auto" } },
        [
          _c("div", { staticClass: "heading h2" }, [
            _vm._v(_vm._s(_vm.item.Size) + " " + _vm._s(_vm.item.WeaponType))
          ]),
          _vm._v(" "),
          _vm.item.Source
            ? _c("span", { staticClass: "flavor-text subtle--text" }, [
                _vm._v("// " + _vm._s(_vm.item.LicenseString))
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.item.Profiles && _vm.item.Profiles.length > 1
        ? _c("v-col", { attrs: { cols: "12" } }, [
            _vm.item.ProfileEffect
              ? _c(
                  "div",
                  { staticClass: "panel clipped pa-2" },
                  [
                    _c("v-icon", [_vm._v("cci-weapon")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "overline" }, [
                      _c("span", { staticClass: "stark--text" }, [
                        _vm._v("EQUIPMENT EFFECT")
                      ]),
                      _vm._v(
                        "\n        ::" +
                          _vm._s(_vm.item.SelectedProfile.Name) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: _vm.item.ProfileEffect,
                          expression: "item.ProfileEffect"
                        }
                      ],
                      staticClass: "text--text body-text mb-1 mx-3"
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.item.ProfileOnAttack
              ? _c(
                  "div",
                  { staticClass: "panel clipped pa-2" },
                  [
                    _c("v-icon", [_vm._v("cci-weapon")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "overline" }, [
                      _c("span", { staticClass: "stark--text" }, [
                        _vm._v("ON ATTACK")
                      ]),
                      _vm._v(
                        "\n        ::" +
                          _vm._s(_vm.item.SelectedProfile.Name) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: _vm.item.ProfileOnAttack,
                          expression: "item.ProfileOnAttack"
                        }
                      ],
                      staticClass: "text--text body-text mb-1 mx-3"
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.item.ProfileOnHit
              ? _c(
                  "div",
                  { staticClass: "panel clipped pa-2" },
                  [
                    _c("v-icon", [_vm._v("cci-weapon")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "overline" }, [
                      _c("span", { staticClass: "stark--text" }, [
                        _vm._v("ON HIT")
                      ]),
                      _vm._v(
                        "\n        ::" +
                          _vm._s(_vm.item.SelectedProfile.Name) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: _vm.item.ProfileOnHit,
                          expression: "item.ProfileOnHit"
                        }
                      ],
                      staticClass: "text--text body-text mb-1 mx-3"
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.item.ProfileOnCrit
              ? _c(
                  "div",
                  { staticClass: "panel clipped pa-2" },
                  [
                    _c("v-icon", [_vm._v("cci-weapon")]),
                    _vm._v(" "),
                    _c("span", { staticClass: "overline" }, [
                      _c("span", { staticClass: "stark--text" }, [
                        _vm._v("ON CRITICAL HIT")
                      ]),
                      _vm._v(
                        "\n        ::" +
                          _vm._s(_vm.item.SelectedProfile.Name) +
                          "\n      "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: _vm.item.ProfileOnCrit,
                          expression: "item.ProfileOnCrit"
                        }
                      ],
                      staticClass: "text--text body-text mb-1 mx-3"
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "profile" }, slot: "profile" }, [
        _vm.item.Profiles.length > 1 && _vm.item.ProfileActions.length
          ? _c(
              "div",
              [
                _c("div", { staticClass: "overline ml-n2 subtle--text" }, [
                  _vm._v("PROFILE ACTIONS")
                ]),
                _vm._v(" "),
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "", justify: "center" } },
                  _vm._l(_vm.item.ProfileActions, function(a, i) {
                    return _c(
                      "v-col",
                      {
                        key: _vm.item.Name + "_action_" + i,
                        attrs: { cols: "auto" }
                      },
                      [
                        _c("cc-action", {
                          staticClass: "ma-2",
                          attrs: {
                            action: a,
                            panel: _vm.$vuetify.breakpoint.lgAndUp
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.item.Profiles.length > 1 && _vm.item.ProfileDeployables.length
          ? _c(
              "div",
              [
                _c("div", { staticClass: "overline ml-n2 subtle--text" }, [
                  _vm._v("PROFILE DEPLOYABLES")
                ]),
                _vm._v(" "),
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "", justify: "center" } },
                  _vm._l(_vm.item.ProfileDeployables, function(d, i) {
                    return _c(
                      "v-col",
                      {
                        key: _vm.item.Name + "_deployable_" + i,
                        attrs: { cols: "auto" }
                      },
                      [
                        _c("cc-deployable-info", {
                          staticClass: "ma-2",
                          attrs: {
                            deployable: d,
                            panel: _vm.$vuetify.breakpoint.lgAndUp,
                            "name-override": _vm.item.Name
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.item.Profiles.length > 1 &&
        _vm.item.ProfileTags &&
        _vm.item.ProfileTags.length
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "overline ml-n2 mb-n1 subtle--text" },
                  [_vm._v("PROFILE TAGS")]
                ),
                _vm._v(" "),
                _c("cc-tags", {
                  attrs: { tags: _vm.item.ProfileTags, extended: "" }
                })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }