var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("info-view", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page === "0",
            expression: "page === '0'"
          }
        ],
        key: "v0",
        attrs: { pilot: _vm.pilot }
      }),
      _vm._v(" "),
      _c("narrative-view", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page === "1",
            expression: "page === '1'"
          }
        ],
        key: "v1",
        attrs: { pilot: _vm.pilot }
      }),
      _vm._v(" "),
      _c("tactical-view", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page === "2",
            expression: "page === '2'"
          }
        ],
        key: "v2",
        attrs: { pilot: _vm.pilot }
      }),
      _vm._v(" "),
      _c("mech-hangar-view", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.page === "3",
            expression: "page === '3'"
          }
        ],
        key: "v3",
        attrs: { pilot: _vm.pilot }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }