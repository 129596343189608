var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "printable",
      staticStyle: { "margin-left": "auto", "margin-right": "auto" },
      attrs: { tile: "", flat: "", light: "" }
    },
    [
      _vm.blank
        ? _c("blank-pilot-print")
        : _vm.pilot
        ? _c("pilot-print", { attrs: { pilot: _vm.pilot } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticStyle: { "page-break-before": "always" } }),
      _vm._v(" "),
      _vm.blank
        ? _c("blank-mech-print")
        : _vm.mech
        ? _c("mech-print", { attrs: { mech: _vm.mech } })
        : _vm._e(),
      _vm._v(" "),
      _c("print-footer"),
      _vm._v(" "),
      _c("div", {
        staticClass: "no-print",
        staticStyle: { "min-height": "60px!important" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }