var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "mx-4", attrs: { justify: "space-around", dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-select", {
            staticClass: "px-2",
            attrs: {
              dense: "",
              "hide-details": "",
              "prepend-icon": "mdi-factory",
              outlined: "",
              label: "From Manufacturer",
              items: _vm.manufacturers,
              chips: "",
              "deletable-chips": "",
              "small-chips": ""
            },
            on: {
              change: function($event) {
                return _vm.updateFilters()
              }
            },
            model: {
              value: _vm.sourceFilter,
              callback: function($$v) {
                _vm.sourceFilter = $$v
              },
              expression: "sourceFilter"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "12", md: "4" } },
        [
          _c("v-select", {
            staticClass: "px-2",
            attrs: {
              "hide-details": "",
              dense: "",
              "prepend-icon": "cci-compendium",
              chips: "",
              "deletable-chips": "",
              outlined: "",
              label: "From Content Pack",
              items: _vm.lcps,
              multiple: "",
              "small-chips": ""
            },
            on: {
              change: function($event) {
                return _vm.updateFilters()
              }
            },
            model: {
              value: _vm.lcpFilter,
              callback: function($$v) {
                _vm.lcpFilter = $$v
              },
              expression: "lcpFilter"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-select", {
            staticClass: "px-2",
            attrs: {
              dense: "",
              "hide-details": "",
              "prepend-icon": "mdi-tag",
              chips: "",
              "deletable-chips": "",
              "small-chips": "",
              outlined: "",
              label: "Tags",
              items: _vm.tags,
              "item-value": "ID",
              multiple: "",
              "item-text": "Name"
            },
            on: {
              change: function($event) {
                return _vm.updateFilters()
              }
            },
            model: {
              value: _vm.tagFilter,
              callback: function($$v) {
                _vm.tagFilter = $$v
              },
              expression: "tagFilter"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }