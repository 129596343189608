var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mech.Destroyed
    ? _c(
        "v-alert",
        {
          staticClass: "text-center",
          attrs: { prominent: "", dense: "", color: "error", outlined: "" }
        },
        [
          _c(
            "v-icon",
            {
              staticClass: "mr-3",
              attrs: { slot: "prepend", color: "error", size: "70" },
              slot: "prepend"
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.mech.ReactorDestroyed ? "mdi-nuke" : "cci-eclipse"
                  ) +
                  "\n  "
              )
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "heading h1" }, [
            _vm._v("\n    MECH DESTROYED\n    "),
            _vm.mech.ReactorDestroyed
              ? _c(
                  "span",
                  [_c("cc-slashes"), _vm._v("\n      REACTOR DESTROYED\n    ")],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "heading mt-n4 subtle--text" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.mech.ReactorDestroyed
                    ? "ERR ERR ERR ERR ERR ERR ERR"
                    : "FRAME.CRITICAL//: CATASTROPHIC DAMAGE"
                ) +
                "\n  "
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flavor-text text--text" },
            [
              _vm._v(
                "\n    The currently active mech (" +
                  _vm._s(_vm.mech.Name) +
                  "\n    "
              ),
              _c("cc-slashes"),
              _vm._v(
                "\n    " +
                  _vm._s(_vm.mech.Frame.Source) +
                  " " +
                  _vm._s(_vm.mech.Frame.Name) +
                  ") has been destroyed.\n    "
              ),
              !_vm.mech.ReactorDestroyed ? _c("span") : _vm._e(),
              _vm._v(" "),
              _vm.mech.ReactorDestroyed
                ? _c("span", [
                    _vm._v(
                      "\n      The reactor has gone critical and the frame is unrecoverable. This mech must be reprinted.\n    "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { position: "relative" } },
            [
              !_vm.mech.ReactorDestroyed
                ? _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        content:
                          "Certain actions and equipment allow for battlefield repairs. Clicking here will restore your mech to 1 Structure Point and 1 HP."
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect",
                          attrs: { "x-small": "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("restore", false)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "", left: "" } }, [
                            _vm._v("cci-repair")
                          ]),
                          _vm._v("\n        REPAIR\n      ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.mech.ReactorDestroyed
                ? _c(
                    "cc-tooltip",
                    {
                      attrs: {
                        content:
                          "Revert this state and restore your mech to 1 Structure Point and 1 HP."
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "fadeSelect",
                          attrs: { "x-small": "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("restore", true)
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { small: "", left: "" } }, [
                            _vm._v("mdi-reload")
                          ]),
                          _vm._v("\n        RESTORE\n      ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }