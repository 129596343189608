







































































































































































































































































































































import Vue from 'vue'
import { Auth } from '@aws-amplify/auth'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/store'

export default Vue.extend({
  name: 'auth-signed-in',
  data: () => ({
    loading: false,
    showError: false,
    error: '',
    oldpass: null,
    showOld: false,
    newpass: null,
    showNew: false,
    rules: {
      passLength: v => (v && v.length >= 6) || 'Minimum 6 characters',
    },
    authedUser: null,
  }),
  computed: {
    passMatch() {
      return () =>
        (this.oldpass && this.newpass && this.oldpass !== this.newpass) ||
        'Password must be different'
    },
    userProfile() {
      return getModule(UserStore, this.$store).UserProfile
    },
    isManualOnly() {
      return !Object.values(this.userProfile.SyncFrequency).some((x: boolean) => x === true)
    },
  },
  mounted() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        console.log(user)
        this.authedUser = user
      })
      .catch(() => {
        this.$emit('set-state', 'sign-in')
      })
  },
  methods: {
    setManualOnly(toggle) {
      if (toggle) {
        for (const k in this.userProfile.SyncFrequency) {
          if (Object.prototype.hasOwnProperty.call(this.userProfile.SyncFrequency, k)) {
            Vue.set(this.userProfile.SyncFrequency, k, false)
          }
        }
      }
    },
    sync() {
      this.loading = true
      const userstore = getModule(UserStore, this.$store)
      userstore
        .cloudSync({
          callback: (status: string, message: string) => this.$notify(message, status),
          condition: null,
        })
        .then(() => {
          this.loading = false
          this.$notify('Sync Complete', 'success')
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },
    changePass() {
      this.loading = true
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, this.oldpass, this.newpass)
        })
        .then(() => {
          this.loading = false
          this.showError = false
          this.$notify('Password Changed')
          this.oldpass = null
          this.newpass = null
        })
        .catch(err => {
          this.loading = false
          this.showError = true
          this.error = `${err.message}<br><div class='text-right'>${err.name}</div>`
        })
    },
    signOut() {
      Auth.signOut()
        .then(() => {
          this.$notify('Sign Out Complete')
          const store = getModule(UserStore, this.$store)
          store.setLoggedIn(false)
          this.$emit('set-state', 'sign-in')
        })
        .catch(err => {
          console.error(err)
        })
    },
  },
})
