var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "7" } }, [
            _c(
              "fieldset",
              [
                _c("legend", { staticClass: "heading h3 accent--text mx-2" }, [
                  _vm._v("COMBATANTS")
                ]),
                _vm._v(" "),
                _vm._l(
                  _vm.mission.ActiveNpcs.filter(function(x) {
                    return x.Side === "Enemy"
                  }),
                  function(n, i) {
                    return _c("npc-chip", {
                      key: "cmbt_e_" + n.ID + "_" + i,
                      attrs: { npc: n, readonly: "", color: "red darken-1" }
                    })
                  }
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.mission.ActiveNpcs.filter(function(x) {
                    return x.Side === "Ally"
                  }),
                  function(n, i) {
                    return _c("npc-chip", {
                      key: "cmbt_a_" + n.ID + "_" + i,
                      attrs: { npc: n, readonly: "", color: "blue darken-1" }
                    })
                  }
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.mission.ActiveNpcs.filter(function(x) {
                    return x.Side === "Neutral"
                  }),
                  function(n, i) {
                    return _c("npc-chip", {
                      key: "cmbt_n_" + n.ID + "_" + i,
                      attrs: { npc: n, readonly: "", color: "grey darken-1" }
                    })
                  }
                )
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "fieldset",
              [
                _c("legend", { staticClass: "heading h3 accent--text mx-2" }, [
                  _vm._v("PILOTS")
                ]),
                _vm._v(" "),
                _vm._l(_vm.mission.Pilots, function(p) {
                  return _c(
                    "v-chip",
                    {
                      key: "pilot_" + p.ID,
                      staticClass: "ma-2",
                      attrs: { large: "", label: "", color: "primary" }
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { large: "", dark: "", left: "" } },
                        [_vm._v("cci-pilot")]
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "heading h3" }, [
                        _vm._v(_vm._s(p.Callsign))
                      ])
                    ],
                    1
                  )
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "5" } }, [
            _c(
              "fieldset",
              [
                _c("legend", { staticClass: "heading h3 accent--text mx-2" }, [
                  _vm._v("REINFORCEMENTS")
                ]),
                _vm._v(" "),
                !_vm.mission.ActiveReinforcements.length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "text-center subtle--text heading h3 mt-3 mb-4"
                      },
                      [
                        _vm._v(
                          "\n          // NO REINFORCEMENTS REMAINING //\n        "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(
                  _vm.mission.ActiveReinforcements.filter(function(x) {
                    return x.Side === "Enemy"
                  }),
                  function(n, i) {
                    return _c("npc-chip", {
                      key: "rein_e_" + n.ID + "_" + i,
                      attrs: {
                        npc: n,
                        readonly: "",
                        reinforce: "",
                        color: "red darken-1"
                      },
                      on: {
                        move: function($event) {
                          return _vm.mission.MoveReinforcement(n)
                        }
                      }
                    })
                  }
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.mission.ActiveReinforcements.filter(function(x) {
                    return x.Side === "Ally"
                  }),
                  function(n, i) {
                    return _c("npc-chip", {
                      key: "rein_a_" + n.ID + "_" + i,
                      attrs: {
                        npc: n,
                        readonly: "",
                        reinforce: "",
                        color: "blue darken-1"
                      },
                      on: {
                        move: function($event) {
                          return _vm.mission.MoveReinforcement(n)
                        }
                      }
                    })
                  }
                ),
                _vm._v(" "),
                _vm._l(
                  _vm.mission.ActiveReinforcements.filter(function(x) {
                    return x.Side === "Neutral"
                  }),
                  function(n, i) {
                    return _c("npc-chip", {
                      key: "rein_n_" + n.ID + "_" + i,
                      attrs: {
                        npc: n,
                        readonly: "",
                        reinforce: "",
                        color: "grey darken-1"
                      },
                      on: {
                        move: function($event) {
                          return _vm.mission.MoveReinforcement(n)
                        }
                      }
                    })
                  }
                )
              ],
              2
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "7" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { block: "", color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.pilotDialog.show()
                    }
                  }
                },
                [_vm._v("\n        add pilot to mission\n      ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { block: "", color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.npcDialog.show()
                    }
                  }
                },
                [_vm._v("\n        add NPC to reinforcement pool\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "pilotDialog",
          attrs: {
            "no-confirm": "",
            title: "ADD PILOT",
            fullscreen: "",
            "no-pad": ""
          }
        },
        [
          _c("pilot-selector", {
            attrs: { "selected-pilots": _vm.mission.Pilots },
            on: {
              select: function($event) {
                return _vm.addPilot($event)
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "cc-solo-dialog",
        {
          ref: "npcDialog",
          attrs: {
            "no-confirm": "",
            title: "ADD NPC",
            fullscreen: "",
            "no-pad": ""
          }
        },
        [
          _c("npc-selector", {
            on: {
              select: function($event) {
                return _vm.addNpc($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }